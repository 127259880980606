import { observable } from "mobx";

export enum ThirdPartySource {
  SOURCE_UNKNOWN = 0,
  SOURCE_WECHAT_WORK = 1,
  SOURCE_EMAIL = 2,
}

export default class ThirdPartyUser {
  @observable
  name: string = "";

  @observable
  alias: string = "";

  @observable
  department: string = "";

  @observable
  session: string = ""

  source: ThirdPartySource = ThirdPartySource.SOURCE_UNKNOWN

  constructor(source: ThirdPartySource, name: string, alias: string, department: string, session: string) {
    this.source = source;
    this.name = name;
    this.alias = alias;
    this.department = department;
    this.session = session;
  }
}
