import { observer } from "mobx-react";
import React from "react";
import { useCommManagerStore } from "../../stores/hooks";
import QualityFeedbackModal from "./QualityFeedbackModal";


const QualityFeedbackOutsideContainer = observer(() => {
  const commManager = useCommManagerStore()

  const handleCloseModal = () => {
    commManager.isOpenQualityFeedback = false
  }

  return (
    <div>
      { commManager.isOpenQualityFeedback &&
      <QualityFeedbackModal setClose={handleCloseModal}></QualityFeedbackModal>
      }
    </div>
  )
})

export default QualityFeedbackOutsideContainer
