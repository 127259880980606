import React, { useState } from "react";
import { Box, Tooltip, IconButton, useTheme } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { useCommManagerStore, useRtcCommLayerStore, useUIStore } from "../../stores/hooks";
import { observer } from "mobx-react";
import { color } from "../../style/color";
import { tooltipStyle } from './styles';
import { CommUser } from "../../types/commUser";
import { JsxElement } from "typescript";
interface IUserNameTooltip {
  user: CommUser,
  placement?:
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top',
  children?: any;
}
export const UserNameTooltip = observer((props: IUserNameTooltip) => {
  const classNames = tooltipStyle()
  const [t] = useTranslation()
  const uiStore = useUIStore()
  const { user, placement = "right" } = props;
  return (<Tooltip
    title={uiStore.isRoomModeNormal ?
      user.userFullName && <Box className={classNames.tooltipContainer}>
        <div > {t('Nickname')}: <span  className="notranslate">{user.userFullName}</span></div>
      </Box> :
      user.userFullName && (user.isLoggedIn ?
        <Box className={classNames.tooltipContainer}>
          <div >{t('ShowName')}: <span className={`${classNames.fontWeight} notranslate`} >{user.userFullName}</span></div>
          {user.thirdpartyDepartment && <div className={classNames.showName}> {t('Department')}: <span className={classNames.fontWeight}>{user.thirdpartyDepartment}</span></div>}
        </Box> :
        <Box className={classNames.tooltipContainer}>
          <div >
            {/* {t('ShowName')}: <span className="notranslate">{user.userFullName}({t('TipOutsider')})</span> */}
            {t('ShowName')}: <span className="notranslate">{user.userFullName}</span>
          </div>
          <div className={classNames.showName}>
            {t('Inviter')}: <span className="notranslate">{user.inviteBy}</span>
          </div>
        </Box>)
    } placement={placement}
  // open={true}
  >
    {props.children}

  </Tooltip>)

})
