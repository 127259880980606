import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useCommManagerStore, useUserManagerStore, useUIStore, useRtcCommLayerStore } from "../../stores/hooks";
import { useStyles } from "./styles";
import { ReactComponent as IconSmile } from "../../assets/icon_smile.svg";
import VideoPlayer from "../VideoPlayer";
import VideoPreview from "../VideoPreview";
import { useTheme } from "@material-ui/core";
import SpokesmanName from "./SpokesmanName";
import { colorCommon } from "../../style/color"

interface IBigStream {
  isPreviewVideo?: boolean
}
const BigStream = observer((props:IBigStream) => {
  const [t] = useTranslation()
  const userManager = useUserManagerStore()
  const commManager = useCommManagerStore()
  const useRtcComm = useRtcCommLayerStore()
  const styles = useStyles()
  const theme = useTheme()
  const isDark = theme.palette.type === 'dark'
  const { isPreviewVideo = false } = props;

  const videoContent = () => {

    if (userManager.majorUser?.videoState || (commManager.hasPreviewVideo && isPreviewVideo)) {
      return <VideoPlayer streamId={userManager.majorUser!.streamId} isFit={false}
        isNeedWatermark={userManager.majorUser?.isNeedWatermark}
        isShareVideo={userManager.majorUser?.parentStreamId ? true : false} />
    }

    return <IconSmile width={'80px'} height={'80px'} className={styles.iconSmile} />
  }

  const BigView = () => {
    if (!userManager.majorUser) return null
    return (
      <div className={isPreviewVideo ? styles.preview : styles.mainPlayer} id="bigStream" >
        <div className={styles.mainPlayer}
          style={userManager.majorUser.videoState && isDark ? { background: colorCommon.colorBlack } : {}}
          key={userManager.majorUser.streamId}>
            {isPreviewVideo && <span className={styles.connectText}>{t('Connecting')}<span className={styles.loading}/></span>}
            {videoContent()}
        </div>
        {isPreviewVideo ? null : <SpokesmanName />}
      </div>
    )
  }
  return (
    <div className={styles.container}>
      {BigView()}
    </div>
  )
})

export default BigStream