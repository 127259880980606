import { action, observable } from "mobx";
import { AlertInfo, NotificationInfo } from "../types/notification";
import { log, LOG_MODULE, LOG_TYPE } from "../utils/Log";
import cloneDeep from "lodash.clonedeep";
import {
  APPLY_ASSISTANT_LANG,
  ASSISTANT_REASON
} from "../utils/constants";
import { BizUser } from "../types/bizUser";
import { CommUser } from "../types/commUser";
import { DeviceInfo } from "agora-rtc-sdk-ng";
import { RootStore } from "./rootStore";
import i18n from "i18next"
import { AssistantInfo } from "../types/assistantInfo";
interface INotificationQueue {
  type: 'manualClose' | 'toast' | 'dialog';
  content: string,
  contentParams?: { [key: string]: string },
  key?: string,
  onConfirm?: any,
  confirmText?: string;
  cancelText?: string;
  onCancel?: any;
  keyword?: string
  duration?:number
}
export class NotificationStore {
  @observable
  public queue: NotificationInfo[] = []

  @observable
  public newQueue: INotificationQueue[] = []

  @observable
  public alert?: AlertInfo
  @observable
  public showAlert: boolean = false

  private rootStore: RootStore
  private currentNotificationDeviceId: string = ""

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }
  @action
  public close(info: NotificationInfo) {
    const index = this.queue.indexOf(info)
    this.queue.splice(index, 1)
  }

  @action onNoticeUserInterrupt(name: string) {
    this.addNewNotification({
      type: 'toast',
      key: "UserInterrupt",
      contentParams: { name },
      content: 'UserInterrupt',
    })
  }
  @action onNoticeMuteAudio(name: string) {
    this.addNewNotification({
      type: 'toast',
      key: "audioRequest",
      contentParams: { name },
      content: 'Turn off your microphone',
    })
  }

  @action onNoticeMuteVideo(name: string) {
    this.addNewNotification({
      type: 'toast',
      content: 'Turn off your camera',
      contentParams: { name },
      key: 'videoRequest'
    })
  }

  @action onNoticeRejectRequest(name: string, isAudio: boolean) {
    this.addNewNotification({
      type: 'toast',
      content: isAudio ? 'Refuse to turn on microphone' : 'Refuse to turn on camera',
      contentParams: { name },
      key: 'RejectRequest',
    })

  }

  @action onNoticeResponseWaitTimeout(name: string, isAudio: boolean, isOpenOperations: boolean) {
    const microphoneContent = isOpenOperations ? "NotRespondMicrophone" : "TimeoutRequestMicrophone";
    const cameraContent = isOpenOperations ? "NotRespondCamera" : "TimeoutRequestCamera";
    this.addNewNotification({
      type: 'toast',
      key: "RejectRequest",
      content: isAudio ? microphoneContent : cameraContent,
      contentParams: { name }
    })
  }

  @action onNoticeRemoteSharing(sharing: boolean, isStartShare: boolean, name: string) {
    if (sharing) {
      isStartShare && this.addNewNotification({
        key: 'shareExists',
        type: 'toast',
        content: 'sharingScreen',
        contentParams: { name }
      })
      return
    }
    this.onNoticeStopShare(name)

  }
  @action onScreenSharingInterrupted() {
    this.addNewNotification({
      type: 'toast',
      content: 'ScreenSharingInterrupted',
      key: 'shareExists'
    })
  }

  @action onNoticeCLocalSharing(name: string) {
    this.addNewNotification({
      type: 'toast',
      content: 'sharingScreen',
      contentParams: { name },
      key: 'shareExists',
    })
  }

  @action onNoticeCameraChanged(info: DeviceInfo) {
    if (info.state === "ACTIVE" &&
      this.rootStore.rtcCommLayer.mineVideoState &&
      this.rootStore.commManger.roomState !== 0) {
      this.currentNotificationDeviceId = info.device.deviceId
      this.addNewNotification({
        type: "dialog",
        content: "NewCameraConnected",
        contentParams: { deviceName: info.device.label },
        key: "videoRequest",
        confirmText: 'Switch',
        keyword: 'NewCameraConnected',
        onConfirm: () => {
          this.rootStore.user.setCameraId(info.device.deviceId)
        }
      })
    } else if (info.state === "INACTIVE") {
      this.currentNotificationDeviceId = ""
      const targetIndex = this.newQueue.findIndex(item => item.keyword === "NewCameraConnected")
      this.remove(targetIndex)
    }
  }

  @action onNoticeStopShare(name: string) {
    this.addNewNotification({
      type: 'toast',
      content: 'stoppedScreenSharing',
      contentParams: { name },
      key: 'shareExists',
    })
  }

  @action onNoticeMicrophoneChanged(info: DeviceInfo) {
    if (info.state === "ACTIVE" &&
      this.rootStore.rtcCommLayer.mineAudioState &&
      this.rootStore.commManger.roomState !== 0) {

      this.addNewNotification({
        type: "manualClose",
        content: "NewMicrophoneConnected",
        contentParams: { deviceName: info.device.label },
        key: "audioRequest",
        keyword: 'NewMicrophoneConnected'
      })
      this.rootStore.user.setMicrophoneId(info.device.deviceId)
    } else if (info.state === "INACTIVE") {
      const targetIndex = this.newQueue.findIndex(item => item.keyword === "NewMicrophoneConnected")
      this.remove(targetIndex)
    }
  }

  @action onNoticeSetCameraId(cameraId: string) {
    if (cameraId === this.currentNotificationDeviceId) {
      const targetIndex = this.newQueue.findIndex(item => item.keyword === "NewCameraConnected")
      this.remove(targetIndex)
    }

  }

  @action
  public addNewNotification(info: INotificationQueue) {
    const newArr = this.newQueue;
    if (info.key) {
      const findIndex = newArr.findIndex(item => item.key === info.key)
      if (findIndex > -1) {
        newArr[findIndex] = info
      } else {
        newArr.push(info)
      }
    } else {
      newArr.push(info)
    }
    /* Use deep copy to change the reference address of the object,
    so that it can listen for the change of value */
    this.newQueue = cloneDeep(newArr);
  }

  @action onNoticeResolutionHasTurnDown(label: string) {
    const labelEn = {
      Low: 'Low',
      Medium: 'Medium',
      High: 'High',
    }
    const labelZn = {
      Low: '低',
      Medium: '中',
      High: '高',
    }
    const value = i18n.language !== 'zh-CN' ? labelEn[label] : labelZn[label]
    this.addNewNotification({
      type: 'toast',
      content: 'ResolutionHasTurnDown',
      contentParams: { value, },
      key: 'qualityTip',
    })
  }

  @action
  public onApplyAssistantRefuse() {
    this.addNewNotification({
      type:'manualClose',
      key:'meetingAssistant',
      content:'Assistant_apply_fail'
    })
  }

  @action
  public onApplyAssistantSuccess(reason?: string) {
    if (reason !== 'ACCEPT_BY_SERVER') {
    this.addNewNotification({
      type: 'manualClose',
      key: 'meetingAssistant',
      content: 'Assistant_apply_success'
    })
    }
  }

  @action
  public onApplyAssistantTimeout() {
    this.addNewNotification({
      type: 'manualClose',
      key:'meetingAssistant',
      content:'Assistant_apply_time_out'
    })
  }

  @action
  public onUserApplyAssistant(requestInfo:AssistantInfo, requestId: string, duration: number) {
    const contentText = requestInfo.language === APPLY_ASSISTANT_LANG.EN ? 'Assistant_ask_host_en' : 'Assistant_ask_host_cn'
    const index = this.newQueue.findIndex(item => item.key === 'meetingAssistant')
    this.remove(index);
    this.addNewNotification({
      type: 'dialog',
      key: 'meetingAssistant',
      duration,
      content: contentText,
      contentParams: { name: requestInfo.getAssistantName() },
      onConfirm: () => {
        this.rootStore.commManger.assentAssistantApply(requestId)
      },
      onCancel: () => {
        this.rootStore.commManger.rejectAssistantApply(requestId)
      }
    })

  }

  @action
  public onCancelAssistant() {
    this.addNewNotification({
      type:'manualClose',
      key:'meetingAssistant',
      content:'Assistant_leave_out'
    })
  }

  @action onNoticeAssistantLeave() {
    this.addNewNotification({
      type: 'manualClose',
      key:'meetingAssistant',
      content: 'Assistant_leave_out',
    })
  }

  @action onNoticeAssistantInterrupt() {
    this.addNewNotification({
      type: 'manualClose',
      key:'meetingAssistant',
      content: 'Assistant_leave_temp',
    })
  }

  @action onNoticeAssistantBack() {
    this.addNewNotification({
      type: 'manualClose',
      key:'meetingAssistant',
      content: 'Assistant_back',
    })
  }

  @action
  public remove(index: number) {
    const newArr = this.newQueue;
    newArr.splice(index, 1)
    this.newQueue = cloneDeep(newArr);
  }

  @action
  public resetNewNotificationQueue() {
    this.newQueue = cloneDeep([]);
  }

  @action
  public clean(): void {
    this.queue.splice(0, this.queue.length)
  }

  @action
  public toast(content: string, paramKey?: string, paramValue?: string) {
    if (paramKey && paramValue) {
      this.toastWithParams(content, new Map([[paramKey, paramValue]]))
    } else {
      this.queue.push(new NotificationInfo('info', content))
    }
  }

  @action
  public toastWithParams(content: string, params?: Map<string, string>) {
    log(`toast ${content} ${params}`, LOG_TYPE.INFO, LOG_MODULE.UI)

    this.queue.push(new NotificationInfo('info', content, params))
  }

  @action
  public toastError(content: string, paramKey?: string, paramValue?: string) {
    if (paramKey && paramValue) {
      this.toastErrorWithParams(content, new Map([[paramKey, paramValue]]))
    } else {
      this.queue.push(new NotificationInfo('error', content))
    }
  }

  @action
  public toastErrorWithParams(content: string, params?: Map<string, string>) {
    log(`toast error ${content} ${params}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)

    this.queue.push(new NotificationInfo('error', content, params))
  }

  @action
  public addAlert(isErrorAlert: boolean, content: string, paramsKey?: string, paramsValue?: string, title: string = "Notice", confirmText: string = "OK") {
    log(`add alert ${content} ${paramsValue}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    if (paramsKey && paramsValue) {
      this.alert = new AlertInfo(isErrorAlert, isErrorAlert ? "Error" : title, content, confirmText, new Map([[paramsKey, paramsValue]]))
    } else {
      this.alert = new AlertInfo(isErrorAlert, isErrorAlert ? "Error" : title, content, confirmText)
    }
    this.showAlert = true
  }
}
