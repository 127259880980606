
import { makeStyles, createStyles, Theme } from "@material-ui/core"

export const useStyles = makeStyles((theme: Theme) => {
  const { customColor: { gdprBorderColor, componentsBackGround } } = theme
  return createStyles({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      overflow: 'hidden',
      padding: '1.25rem',
      boxSizing: 'border-box',
      position: 'relative',
      '&::-webkit-scrollbar ': {
        /*隐藏滚轮*/
        display: 'none',
      }
    },
    main: {
      /* height: 100%; */
      width: '100%',
      position: 'relative',
      flex: 1,
      transition: '.3s padding ease',
      overflow: 'none',
      marginTop: '0.75rem',
      '&:-webkit-scrollbar': {
        overflow: '-moz-scrollbars-none',
      }
    },
    mainShiftRight: {
      paddingRight: '13rem',
    },
    toggleCount: {
      display: 'flex',
      alignItems: 'center',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0.4rem 0.8rem',
      borderRadius: '2rem',
      marginTop: '0.5rem',
      marginLeft: '0.5rem',
      fontSize: '0.7rem',
      position: 'absolute',
      left: 0,
      top: 0,
      transition: ',3s all ease',
      zIndex: 400,
      cursor: 'pointer',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    recordingList: {
      position: 'absolute',
      marginTop: '1rem',
    },
    connectContent: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 9999,
      padding: '1.25rem',
    },

  })
})