import React from "react";
import { MobXProviderContext } from "mobx-react";
import { AppStore } from "../types/store";
import { UserStore } from "./userStore";
import { RtcCommLayerStore } from "./rtc/rtcCommLayer"
import { CommManagerStore } from "./commManager"
import { RtmCommLayerStore } from "./rtm/rtmCommLayer"
import { RtmEngineLayerStore } from "./rtm/rtmEngineLayer"
import { RtcEngineLayerStore } from "./rtc/rtcEngineLayer"
import { NotificationStore } from "./notification";
import ShareManagerStore from "./shareManager";
import UIStateStore from "./uiStateStore";
import { UserManagerStore } from "./userManager";

export function useUserStore(): UserStore {
  const context = React.useContext<{ store: AppStore }>(MobXProviderContext);
  return context.store.user;
}

export function useRtcEngineStore(): RtcEngineLayerStore {
  const context = React.useContext<{ store: AppStore }>(MobXProviderContext);
  return context.store.rtcEngineLayer;
}

export function useRtcCommLayerStore(): RtcCommLayerStore {
  const context = React.useContext<{ store: AppStore }>(MobXProviderContext);
  return context.store.rtcCommLayer;
}

export function useCommManagerStore(): CommManagerStore {
  const context = React.useContext<{ store: AppStore }>(MobXProviderContext);
  return context.store.commManger;
}

export function useRtmCommLayerStore(): RtmCommLayerStore {
  const context = React.useContext<{ store: AppStore }>(MobXProviderContext);
  return context.store.rtmCommLayer;
}

export function useRtmEngineLayerStore(): RtmEngineLayerStore {
  const context = React.useContext<{ store: AppStore }>(MobXProviderContext);
  return context.store.rtmEngineLayer;
}

export function useNotificationStore(): NotificationStore {
  const context = React.useContext<{ store: AppStore }>(MobXProviderContext);
  return context.store.notification;
}

export function useShareManagerStore(): ShareManagerStore {
  const context = React.useContext<{ store: AppStore }>(MobXProviderContext);
  return context.store.shareManager;
}

export function useUIStore(): UIStateStore {
  const context = React.useContext<{ store: AppStore }>(MobXProviderContext);
  return context.store.uiStore;
}

export function useUserManagerStore(): UserManagerStore {
  const context = React.useContext<{ store: AppStore }>(MobXProviderContext);
  return context.store.userManager;
}