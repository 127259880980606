import { Box, Tooltip, Typography } from "@material-ui/core";
import i18n from 'i18next';
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconAndroid } from "../../assets/icon_android.svg";
import { ReactComponent as IconMac } from "../../assets/icon_apple.svg";
import { ReactComponent as IconDownload } from "../../assets/icon_download.svg";
import { ReactComponent as IconDownloadModeAgora } from "../../assets/icon_download_mode_agora.svg";
import { ReactComponent as IconiOS } from "../../assets/icon_ios.svg";
import { ReactComponent as IconWindows } from "../../assets/icon_windows.svg";
import { useUIStore } from "../../stores/hooks";
import { color } from "../../style/color";
import { useStyles } from "./style"
import { isAndroid, isWindows } from "../../utils/userAgentDetector"
import { isChinese } from "../../utils/helper"

const DownloadLine = () => {
  const styles = useStyles()
  const [t] = useTranslation()
  const uiStore = useUIStore()
  const [macHover, setMacHover] = useState(false)
  const [windowsHover, setWindowsHover] = useState(false)

  const openLink = (platform: string) => {
    if (platform === 'macos') {
      if (isWindows() || isAndroid()) {
        if (isChinese()) {
          window.open('https://apps.apple.com/cn/app/agora-video-call/id1080303824')
        } else {
          window.open('https://apps.apple.com/us/app/agora-video-call/id1080303824')
        }
      }
    }
    const platformMapper = {
      macos: 'itms-apps://itunes.apple.com/app/apple-store/id1112106913',
      windows: 'https://download.agora.io/avc/release/AgoraVideoCall_Setup_Latest.exe',
    }
    window.open(platformMapper[platform])
  }

  return (
    <Box className={styles.downloadContent}>
      <Typography className={styles.clientDownload} variant="subtitle2">{t('ClientDownload')}</Typography>
      <Box className={styles.downLoadLine}>
        <Box className={styles.item} onClick={() => openLink('macos')}>
          <Box className={styles.iconContainer}>{
            macHover ?
              (
                uiStore.isHomeModeNormal
                ?
                <IconDownload className={`${styles.iconDownload}`} onMouseLeave={() => setMacHover(false)} />
                :
                <IconDownloadModeAgora className={`${styles.iconDownload} ${styles.iconMac}`} onMouseLeave={() => setMacHover(false)} />)
                :
                <IconMac className={`${styles.icon}`} fill={uiStore.isHomeModeNormal ? "#ACB6BE" : color.colorWhite} onMouseEnter={() => setMacHover(true)} />
          }
          </Box>
          <Typography className={styles.donwloadText} variant="subtitle2">macOS</Typography>
        </Box>
        <Box className={styles.item} onClick={() => openLink('windows')}>
          <Box className={styles.iconContainer}>{
            windowsHover ?
              (uiStore.isHomeModeNormal ? <IconDownload className={`${styles.iconDownload}`} onMouseLeave={() => setWindowsHover(false)} /> : <IconDownloadModeAgora className={`${styles.iconDownload}`} onMouseLeave={() => setWindowsHover(false)} />) : <IconWindows className={`${styles.icon}`} fill={uiStore.isHomeModeNormal ? "#46ABE9" : color.colorWhite} onMouseEnter={() => setWindowsHover(true)} />
          }
          </Box>
          <Typography className={styles.donwloadText}  variant="subtitle2">Windows</Typography>
        </Box>
        <Tooltip
          classes={{tooltipPlacementBottom: styles.tooltipPlacementBottom}}
          placement="bottom"
          title={<div className={styles.iosCode} />}
        >
          <Box className={`${styles.item}`}>
            <Box className={styles.iconContainer}>
              <IconiOS className={styles.icon} />
            </Box>
            <Typography className={styles.donwloadText} variant="subtitle2">iOS</Typography>
          </Box>
        </Tooltip>
        <Tooltip
          classes={{ tooltipPlacementBottom: styles.tooltipPlacementBottom}}
          placement="bottom"
          title={<div className={i18n.language === 'zh-CN' ? `${styles.androidCodeLocal} androidCode` : `${styles.androidCodeExternal} androidCode`} />}
        >
          <Box className={`${styles.item} ${styles.androidItem}`}>
            <Box className={styles.iconContainer}>
              <IconAndroid className={styles.icon} fill={"#34d18a"} />
            </Box>
            <Typography className={styles.donwloadText} variant="subtitle2">Android</Typography>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default DownloadLine
