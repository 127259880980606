import React from "react";
import { useTheme, } from '@material-ui/core'
import { ReactComponent as IconAudioActive } from "../../assets/icon_stream_unmute.svg";
import { ReactComponent as IconAudioActiveEffect } from "../../assets/icon_stream_unmute_effect.svg";
import { ReactComponent as IconAudioInactive } from "../../assets/icon_stream_mute.svg";
import { ReactComponent as IconAudionSpeaking } from "../../assets/icon_stream_speaking.svg";
import { useStyleVoiceVolume as useStyles } from './styles'

interface VoiceVolumeProp {
  audioState: boolean;
  isSpeaking: boolean;
  isMe: boolean;
  volumeLevel?: number;
  isBigStream: boolean;
}

const VoiceVolumeIcon = (props: VoiceVolumeProp) => {
  const theme = useTheme()
  const styleKey = useStyles()
  const micBox = props.isBigStream ? styleKey.micBoxBigStream : styleKey.micBox
  const micShade = props.isBigStream ? styleKey.micShadeBigStream : styleKey.micShade
  
  return (
    props.audioState ? (
      props.isSpeaking ? <IconAudionSpeaking style={{ borderRadius: '50%' }} fill={theme.palette.primary.main} /> :
      <>
          { props.isMe ?
            <>
              <div className={micBox}>
                <div className={micShade} style={{ height: `${props.volumeLevel}%` }} />
              </div>
              <IconAudioActiveEffect style={{ borderRadius: '50%' }} fill={theme.palette.primary.main} />
            </>
            : 
            <IconAudioActive style={{ borderRadius: '50%' }}  fill={theme.palette.primary.main} />
        }
      </>
      )
      : <IconAudioInactive style={{ borderRadius: '50%' }} />
  )
}

export default VoiceVolumeIcon