import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { NICK_NAME_MAX_LENGTH } from "../utils/constants";

export interface InputNicknameDialogProps {
  open: boolean
  onCancel: () => void
  onClose: () => void
  onConfirm: (nickName: string) => void
}

const useStyles = makeStyles({
  paper: {
    padding: '0.5rem 0.8rem 0.8rem 0.8rem',
  },
  title: {
    '& .MuiTypography-root': {
      fontSize: '0.9rem',
    }
  },
  content: {
    marginBottom: '1rem',
  },
  inputRoot: {
    width: '15rem',
    height: "2rem",
    fontSize: '0.6rem',
  },
  input: {
    fontSize: "0.6rem",
  },
})

const InputNicknameDialog = observer((props: InputNicknameDialogProps) => {
  const styles = useStyles()
  const [t] = useTranslation()
  const [nickname, setNickname] = useState('')

  return (
    <Dialog
      classes={{ paper: styles.paper }}
      maxWidth='xs'
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle classes={{ root: styles.title }}>{t('Prompt')}</DialogTitle>
      <DialogContent classes={{ root: styles.content }}>
        <Box fontSize='0.7rem' marginBottom="0.5rem">{t('InputNicknameDialogContent')}</Box>
        <TextField
          variant="outlined"
          placeholder={t('Enter Your Nickname')}
          autoComplete={"off"}
          margin="dense"
          autoFocus
          onChange={e => {
            setNickname(e.target.value)
          }}
          InputProps={{
            classes: {
              root: styles.inputRoot,
            },
          }}
          inputProps={{
            maxLength: NICK_NAME_MAX_LENGTH,
          }}
          FormHelperTextProps={{ className: styles.input }}
          size="small" />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.onCancel}>{t('CANCEL')}</Button>
        <Button disabled={nickname.length === 0} variant="contained" color='primary' onClick={() => {
          props.onConfirm(nickname)
        }}>{t('OK')}</Button>
      </DialogActions>
    </Dialog>
  )
})

export default InputNicknameDialog