import { Box, IconButton, Modal, Typography, useTheme } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconAccount } from "../../assets/icon_account.svg"
import { ReactComponent as IconAboutUs } from '../../assets/icon_about_us.svg'
import { ReactComponent as IconMedia } from '../../assets/icon_media.svg'
import { ReactComponent as IconUploadLog } from '../../assets/icon_uploadlog.svg'
import { ReactComponent as IconDeveloper } from '../../assets/icon_developer.svg'
import { ReactComponent as IconMeeting } from '../../assets/icon_meeting.svg'
import { ReactComponent as IconBack } from "../../assets/icon_back.svg";
import { useUserStore } from "../../stores/hooks";
import { colorDefault } from "../../style/color";
import { log, LOG_MODULE, LOG_TYPE } from "../../utils/Log";
import AccountView from './AccountView'
import MediaView from './MediaView'
import UploadLogView from "./UploadLogView";
import MeetingView from "./MeetingView";
import AboutUsView from "./AboutUsView";
import DeveloperView from "./DeveloperView";
import useStyle from './style'

interface UserSettingProp {
  roomState: boolean,
  open: boolean,
  setOpen: (open: boolean) => void,
  onChangeMode?: (mode: boolean) => void
}

enum ROOM_TAB {
  ACCOUNT = "account",
  MEETING = "meeting",
  MEDIA = "media",
  UPLOAD_LOGS = "upload_logs",
  ABOUT_US = "about_us",
  DEVELOPER = "developer"
}
let timer: any;

const UserSettingView = observer((props: UserSettingProp) => {
  const [t] = useTranslation()
  const user = useUserStore()
  const styles = useStyle()
  const theme = useTheme()
  const [tabSelected, setTabSelected] = useState<string>(ROOM_TAB.ACCOUNT)
  const [debugClick, setDebugClick] = useState(0)
  const [isListenMediaInterSection, setListenMediaInterSection] = useState(true)
  const [isShowVideoPreview, setShowVideoPreview] = useState(true)
  const [lastIsVideoIntersecting, setLastIsVideoIntersecting] = useState(false)
  const [lastIsAudioIntersecting, setLastIsAudioIntersecting] = useState(false)

  const accountRef = useRef<HTMLDivElement>(null)
  const meetingRef = useRef<HTMLDivElement>(null)
  const mediaRef = useRef<HTMLDivElement>(null)
  const uploadRef = useRef<HTMLDivElement>(null)
  const developerRef = useRef<HTMLDivElement>(null)
  const aboutUsRef = useRef<HTMLDivElement>(null)

  const computeSelected = (select: ROOM_TAB) => tabSelected === select ? styles.tabBoxSelected : ""
  const setAVPreView = (scrollTop: number) => {
    if (user.isThirdPartyLoggedIn) {
      if (scrollTop > 55 && scrollTop < 646) {
        !lastIsAudioIntersecting && user.refreshAudioDevices()
        setLastIsAudioIntersecting(true)
      }
      if (scrollTop > 224) {
        setShowVideoPreview(false)
      } else {
        setShowVideoPreview(true)
      }
    } else {
      if (scrollTop > 328 && scrollTop < 525) {
        setShowVideoPreview(true);
        !lastIsVideoIntersecting && user.refreshVideoDevices()
        setLastIsVideoIntersecting(true)
        setLastIsAudioIntersecting(false)
      } else if (scrollTop > 525 && scrollTop < 584) {
        setShowVideoPreview(true)
        !lastIsAudioIntersecting && user.refreshAudioDevices()
        setLastIsAudioIntersecting(true)
        setLastIsVideoIntersecting(true)
      } else if (scrollTop >= 584 && scrollTop < 689) {
        setShowVideoPreview(true)
        !lastIsVideoIntersecting && user.refreshVideoDevices()
        !lastIsAudioIntersecting && user.refreshAudioDevices()
        setLastIsAudioIntersecting(true)
        setLastIsVideoIntersecting(true)
      } else if (scrollTop >= 690 && scrollTop < 930) {
        setShowVideoPreview(false)
        setLastIsVideoIntersecting(false)
      } else if (scrollTop >= 930 && scrollTop < 1150) {
        !lastIsAudioIntersecting && user.refreshAudioDevices()
        setShowVideoPreview(false)
        setLastIsAudioIntersecting(true)
        setLastIsVideoIntersecting(false)

      } else {
        setLastIsAudioIntersecting(false)
        setLastIsVideoIntersecting(false)
        setShowVideoPreview(false)
      }
    }
  }

  const handleScroll = (e: any) => {

    const scrollTop = e.target!.scrollTop
    if (props.roomState) {
      if (scrollTop >= 0 && scrollTop < 241) {
        setTabSelected(ROOM_TAB.MEETING)
      } else if (scrollTop >= 241 && scrollTop < 589) {
        setTabSelected(ROOM_TAB.MEDIA)
      } else if (scrollTop >= 589 && scrollTop < 674) {
        setTabSelected(ROOM_TAB.UPLOAD_LOGS)
      } else if (scrollTop >= 674) {
        setTabSelected(ROOM_TAB.DEVELOPER)
      }
    } else {
      // if (timer !== null) {
      //   mediaRef.current && interSection.unobserve(mediaRef.current)
      //   interSection.disconnect()
      //   clearTimeout(timer);
      // }
      // timer = setTimeout(() => {
      //   mediaRef.current && interSection.observe(mediaRef.current)
      //   setListenMediaInterSection(false)
      // }, 300);

      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        setAVPreView(scrollTop)
      }, 200);
      if (user.isThirdPartyLoggedIn) {
        if (scrollTop >= 0 && scrollTop < 113) {
          setTabSelected(ROOM_TAB.ACCOUNT)
        } else if (scrollTop >= 113 && scrollTop < 679) {
          setTabSelected(ROOM_TAB.MEDIA)
        } else if (scrollTop >= 679 && scrollTop < 763) {
          setTabSelected(ROOM_TAB.UPLOAD_LOGS)
        } else if (scrollTop >= 763 && scrollTop < 895) {
          setTabSelected(ROOM_TAB.ABOUT_US)
        } else if (scrollTop >= 895) {
          setTabSelected(ROOM_TAB.DEVELOPER)
        }
      } else {
        if (scrollTop >= 0 && scrollTop < 584) {
          setTabSelected(ROOM_TAB.ACCOUNT)
        } else if (scrollTop >= 584 && scrollTop < 1150) {
          setTabSelected(ROOM_TAB.MEDIA)
        } else if (scrollTop >= 1150 && scrollTop < 1234) {
          setTabSelected(ROOM_TAB.UPLOAD_LOGS)
        } else if (scrollTop >= 1234 && scrollTop < 1366) {
          setTabSelected(ROOM_TAB.ABOUT_US)
        } else if (scrollTop >= 1366) {
          setTabSelected(ROOM_TAB.DEVELOPER)
        }
      }
    }
  }

  // const interSection = new IntersectionObserver(entry => {
  //   mediaRef.current && interSection.unobserve(mediaRef.current)
  //   const intersectionRatio = parseFloat(entry[0].intersectionRatio.toFixed(2))
  //   console.log(123456, intersectionRatio, entry[0].isIntersecting, tabSelected)
  //   if (intersectionRatio < 0.1 || tabSelected != ROOM_TAB.MEDIA) {
  //     setShowVideoPreview(false)
  //     setLastIsIntersecting(false)
  //   }
  //   if (tabSelected === ROOM_TAB.MEDIA) {
  //     user.refreshAudioDevices()
  //   }
  //   if (entry[0].isIntersecting) {
  //     setShowVideoPreview(true)
  //     setLastIsIntersecting(true)
  //     if (intersectionRatio > 0.55 && intersectionRatio < 0.81) {
  //       !lastIsIntersecting && user.refreshVideoDevices()
  //       return
  //     }
  //     if (intersectionRatio > 0.81 || intersectionRatio === 1) {
  //       !lastIsIntersecting && user.refreshVideoDevices()
  //       !lastIsIntersecting && user.refreshAudioDevices()
  //       return
  //     }
  //   } else {
  //     setShowVideoPreview(false)
  //     setLastIsIntersecting(false)
  //   }
  // }, {
  //   threshold: [0, 0.1, 0.56, 1],
  // })

  const tabSwitch = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current?.id !== ROOM_TAB.MEDIA) {
      setShowVideoPreview(false)
    }
    ref.current!.scrollIntoView({
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    // setListenMediaInterSection(true)
    if (props.open) {
      log(`User Setting enter`, LOG_TYPE.COMM, LOG_MODULE.UI)
      if (props.roomState) {
        setLastIsVideoIntersecting(true)
        setLastIsAudioIntersecting(true)
        user.refreshVideoDevices()
        user.refreshAudioDevices()
        setTabSelected(ROOM_TAB.MEETING)
      }
      if (user.isThirdPartyLoggedIn) {
        setLastIsVideoIntersecting(true)
        user.refreshVideoDevices()
      } else {
        setLastIsVideoIntersecting(false)
        setLastIsAudioIntersecting(false)
      }
    } else {
      log(`User Setting exit`, LOG_TYPE.COMM, LOG_MODULE.UI)
      setTabSelected(ROOM_TAB.ACCOUNT)
    }
    // return (() => {
    //   interSection.disconnect()
    // })
  }, [props.open])// eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <Modal className={styles.modal} open={props.open} hideBackdrop={true}>
        <Box className={styles.box}>
          <Box className={styles.leftSide}>
            <Box className={styles.setting}>
              <IconButton id="user-setting-back" size="small" className={styles.settingIcon} onClick={() => props.setOpen(false)}>
                <IconBack fill={`${theme.palette.type === 'dark' ? '#D8D8D9' : '#000'}`} />
              </IconButton>
              <Typography variant="h2" className={styles.h3LineHeight}>{t('setting')}</Typography>
            </Box>
            {props.roomState && (
              <Box id="user-setting-meeting" className={`${styles.tabBox} ${computeSelected(ROOM_TAB.MEETING)}`} onClick={() => tabSwitch(meetingRef)}>
                <IconMeeting fill={tabSelected === ROOM_TAB.MEETING ? theme.palette.primary.main : colorDefault.colorText} />
                <Typography className={tabSelected === ROOM_TAB.MEETING ? '' : styles.tabTextSelectedColor} variant="h3">{t('Meeting')}</Typography>
              </Box>
            )}
            {!props.roomState && (
              <Box id="user-setting-account" className={`${styles.tabBox} ${computeSelected(ROOM_TAB.ACCOUNT)}`} onClick={() => tabSwitch(accountRef)}>
                <IconAccount fill={tabSelected === ROOM_TAB.ACCOUNT ? theme.palette.primary.main : colorDefault.colorText} />
                <Typography className={tabSelected === ROOM_TAB.ACCOUNT ? '' : styles.tabTextSelectedColor} variant="h3">{t('Account')}</Typography>
              </Box>
            )}
            <Box id="user-setting-media" className={`${styles.tabBox} ${computeSelected(ROOM_TAB.MEDIA)}`} onClick={() => tabSwitch(mediaRef)}>
              <IconMedia fill={tabSelected === ROOM_TAB.MEDIA ? theme.palette.primary.main : colorDefault.colorText} />
              <Typography className={tabSelected === ROOM_TAB.MEDIA ? '' : styles.tabTextSelectedColor} variant="h3">{t('Media')}</Typography>
            </Box>
            <Box id="user-setting-upload-log" className={`${styles.tabBox} ${computeSelected(ROOM_TAB.UPLOAD_LOGS)}`} onClick={() => tabSwitch(uploadRef)}>
              <IconUploadLog fill={tabSelected === ROOM_TAB.UPLOAD_LOGS ? theme.palette.primary.main : colorDefault.colorText} />
              <Typography className={tabSelected === ROOM_TAB.UPLOAD_LOGS ? '' : styles.tabTextSelectedColor} variant="h3">{t('Upload log')}</Typography>
            </Box>
            {!props.roomState && (
              <Box id="user-setting-about-us" className={`${styles.tabBox} ${computeSelected(ROOM_TAB.ABOUT_US)}`} onClick={() => tabSwitch(aboutUsRef)}>
                <IconAboutUs fill={tabSelected === ROOM_TAB.ABOUT_US ? theme.palette.primary.main : colorDefault.colorText} />
                <Typography className={tabSelected === ROOM_TAB.ABOUT_US ? '' : styles.tabTextSelectedColor} variant="h3">{t('About us')}</Typography>
              </Box>
            )}
            {(debugClick >= 5) && (
              <Box id="user-setting-developer" className={`${styles.tabBox} ${computeSelected(ROOM_TAB.DEVELOPER)}`} onClick={() => tabSwitch(developerRef)}>
                <IconDeveloper fill={tabSelected === ROOM_TAB.DEVELOPER ? theme.palette.primary.main : colorDefault.colorText} />
                <Typography className={tabSelected === ROOM_TAB.DEVELOPER ? '' : styles.tabTextSelectedColor} variant="h3">{t('Developer')}</Typography>
              </Box>
            )}
          </Box>
          <div className={styles.rightSide} onScroll={handleScroll} id="right-side-scroll-view">
            {props.roomState && <MeetingView ref={meetingRef} />}
            {!props.roomState && <AccountView ref={accountRef} onChangeMode={props.onChangeMode} />}
            <MediaView isInRoom={props.roomState} ref={mediaRef} isShowVideoPreview={isShowVideoPreview} />
            <UploadLogView debugClick={() => setDebugClick(previousClick => previousClick + 1)} ref={uploadRef} />
            {!props.roomState && <AboutUsView ref={aboutUsRef} />}
            {(debugClick >= 5) && <DeveloperView ref={developerRef}  onChangeMode={props.onChangeMode}  />}
            {props.roomState ? (
              <div style={{ height: debugClick >= 5 ? '24rem' : '31.25rem' }} />
            ) : (
              <div style={{ height: debugClick >= 5 ? '24rem' : '28.25rem' }} />
            )}
          </div>

        </Box>
      </Modal>
    </Box>
  )
})

export default UserSettingView
