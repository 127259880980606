import React, { CSSProperties, HtmlHTMLAttributes } from 'react';
import Notification from 'rc-notification';
import { Box } from "@material-ui/core";
import classNames from './style.module.css'
import { Error, Close } from "@material-ui/icons";

export type NotificationType = 'manualClose' | 'toast' | 'dialog';
const waitingQueue: INotificationView[] = [];
let showQueue: INotificationView[] = [];
// let currentCount = 0;
export interface INotificationView {
  // duration :Duration of interface display，In seconds
  duration?: number | null;
  message?: string;
  key?: string;
  linkColor?: string;
  onClose?: (key?: any) => any;
  type?: NotificationType;
  icon?: React.ReactNode;
  linkText?: string | null;
  handleLinkAction?: (event?: any) => void;
  description?: () => React.ReactNode | React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
  className?: string;
  onCancel?: (key?: string | number) => void;
  onConfirm?: (key?: string | number) => void;
  title?: string;
  confirmButtonColor?: string;
  confirmText?: string;
  cancelText?: string;
}
export interface NotificationInstance {
  toast(args: INotificationView): void;
  manualClose(args: INotificationView): void;
  info(args: INotificationView): void;
  dialog(args: INotificationView): void;
  open(args: INotificationView): void;
  close(key: string): void;
  destroy(): void
}

const MAX_COUNT = 5;
const NotificationType = ['toast', 'manualClose', 'dialog']
const defaultDuration = 3;
const prefixCls = 'rc-notification';
function getPlacementStyle() {
  return {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    bottom: 'auto',
  } as CSSProperties;

}

let notification: any = null
let notificationContainer:any = null

function getNotificationInstance(args: INotificationView) {

  const argClassName = args.className || "topLeft";
  Notification.newInstance(
    {
      maxCount: MAX_COUNT,
      prefixCls: prefixCls,
      className: argClassName,
      style: getPlacementStyle(),
      getContainer: () => {
        const dom = document.getElementById('bigStream') || document.body;
        notificationContainer = dom
        return dom
      },
    },
    (n: any) => {
      notification = n
    });
  return true
};
const findNotificationInQueue = (arg: INotificationView | string) => {
  if (typeof arg === "string") {
    return showQueue.findIndex(item => item.key === arg)
  }
  return showQueue.findIndex(item => item.key === arg.key)
}

const showWaitingQueue = () => {
  if (waitingQueue.length) {
    const pendingItem = waitingQueue.shift()
    setTimeout(() => {
      pendingItem && notification.notice(getRCNoticeProps(pendingItem))
    }, 300)
  }
}
const notice = async (args: INotificationView) => {
  if (!notification) {
    await getNotificationInstance(args);
    showQueue = [];
  }
  if (showQueue.length <= MAX_COUNT && findNotificationInQueue(args) === -1) {
    showQueue.push(args);
    notification.notice(getRCNoticeProps(args))
  } else {
    if (findNotificationInQueue(args) > -1) {
      showQueue.push(args);
      notification.notice(getRCNoticeProps(args))
      return
    }
    waitingQueue.push(args)
  }
}

const onNotificationClose = async (removeItem: INotificationView, callBack?: any) => {
  // currentCount--;
  showQueue.splice(findNotificationInQueue(removeItem), 1);
  if (removeItem.onClose) {
    removeItem.onClose();
  }
  if (callBack) {
    callBack();
  }
  showWaitingQueue()
}

export const initNotification = () => {
  notification = null;
  getNotificationInstance({})
}

function getRCNoticeProps(args: INotificationView) {
  const { key = 'random' + Math.floor(Math.random() * 10000), type = "toast", className = '' } = args
  const duration = args.duration || (type !== 'toast' ? 0 : defaultDuration)
  const closeNotification = () => {

    if (args.onCancel) {
      args.onCancel()
      return

    }
    notificationApi.close(key)
  }
  const { onCancel = closeNotification, onConfirm } = args
  const onDialogConfirm = () => {

    if (onConfirm) {
      onConfirm();
      return
    }
    notificationApi.close(key)
  }
  const onDialogCancel = () => {
    if (onCancel) {
      onCancel();
      return
    }
    notificationApi.close(key)

  }
  let iconNode: React.ReactNode = null;
  if (args.icon) {
    iconNode = <span className={`${classNames}.${prefixCls}-icon`}>{args.icon}</span>;
  }
  return {
    content: (
      <div className={className} role="alert" style={{ ...args.style }}>
        {type === 'manualClose' && <ManualClose {...args} message={args.message || ''} onClose={closeNotification} key={key} />}
        {type === 'dialog' && <Dialog  {...args} message={args.message || ''}
          onCancel={onDialogCancel}
          onConfirm={onDialogConfirm}
          color={args.confirmButtonColor} dialogKey={key} />}
        {type === 'toast' && <Toast {...args} message={args.message || ''} />}
      </div>
    ),
    duration,
    closable: args.type !== 'toast',
    onClose: () => onNotificationClose(args),
    onClick: args.onClick,
    key: key,
    style: args.style || {},
    className: args.className,
  };
}
const notificationApi: any = {
  open: notice,
  close(key: string) {
    if (notification) {
      notification.removeNotice(key);
      // currentCount--;
      showQueue.splice(findNotificationInQueue(key), 1);
      showWaitingQueue()
    }
  },
  destroy() {
    if (notification) {
      notification.destroy();
      notification = null
    }
  }
}
NotificationType.forEach((type: string) => {
  notificationApi[type] = (args: INotificationView) =>
    notificationApi.open({
      ...args,
      type,
    });
});

const ManualClose = (props: {
  message: string,
  linkText?: string | null,
  onClose: () => void,
  handleLinkAction?: () => void,
  linkColor?: string,
  title?:string,
}) => {
  return (
    <Box className={classNames.box}>
      <Error style={{ fontSize: '1.2006rem', color: '#ED455F' }} />
      <div className={classNames.message} style={{ marginLeft: '0.75rem' }}>
        {props.title && <Box className={classNames.title} id="notification-manual-close-title">{props.title}</Box>}
        <div id="notification-manual-close-message">{props.message}{' '}
          {props.linkText && <span className={classNames.link} style={{ color: props.linkColor || "#0087FF" }} onClick={props.handleLinkAction}>
            {props.linkText}
          </span>}
        </div>
      </div>
      <Close onClick={props.onClose} className={classNames.close}
        id="notification-manual-close-button"
        style={{ fontSize: '0.875rem', color: '#B7B7B7FF' }}
      />
    </Box>
  )
}
const Toast = (props: { message: string }) => {
  return (
    <Box className={classNames.box}>
      <div className={classNames.message} id="notification-toast-message">{props.message}</div>
    </Box>
  )
}
interface IDialog {
  message: string,
  dialogKey: string | number
  onCancel: (key?: string | number) => void;
  onConfirm: (key?: string | number) => void;
  color?: string,
  confirmText?: string;
  cancelText?: string;
}
const Dialog = (props: IDialog) => {
  const { message, dialogKey, onCancel, onConfirm, color = "#0087FFFF", confirmText = "Yes", cancelText = "No" } = props;
  return (
    <Box className={classNames.dialogContent}>
      <div className={classNames.dialogMessage}  id="notification-dialog-message">{message}</div>
      <div className={classNames.buttons}>
        <button className={classNames.cancelButton} 
        id="notification-dialog-cancel"
       onClick={() => onCancel(dialogKey)} >{cancelText}</button>
        <button className={classNames.confirmButton}
         id="notification-dialog-confirm"
          style={{ color: color }}
          onClick={() => onConfirm(dialogKey)}>{confirmText}</button>
      </div>
    </Box>
  )
}

export default notificationApi as NotificationInstance