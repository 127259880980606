
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import CoverHover from "../../assets/cover_hover.svg";

export const useStyles = makeStyles((theme: Theme) => {
  const { customColor: { tabBackground, commonModelBackground, homepageBackgroundColor } } = theme;
  const isDark = theme.palette.type === 'dark';
  return createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100%',
      position: 'relative',
      height: '100%',
      overflow: 'hidden',
      flexDirection: 'column',
      backgroundColor: homepageBackgroundColor,
    },
    gridContainer: {
      // height: '100%',
      flexWrap: 'nowrap',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'flex-end',
      justifyContent: 'center',
      borderBottom: '1px solid #000',
    },
    bannerLeft: {
      width: '12.5rem',
    },
    bannerRight: {
      display:'flex',
      flexDirection:'column',
      alignContent: 'center',
    },
    bannerRightImg: {
      width: '22.5rem'
    },

    contentContainer: {
      maxWidth: 'unset !important',
      minWidth: '36.25rem',
      flexBasis: 'unset !important',
    },
    doorBackground:{
      position:'absolute',
      top:0,
      left:0,
      width:'100%',
      height:'100%'
    },
    doorRoomContent:{
      width: '33.5rem',
      position:'relative',
      margin:'0 1.875rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    joinRoom: {
      display: 'flex',
      flexDirection: 'column',
      // height: '42.875rem',
      // width:'26.5rem',
      width: '32.5rem',
      // width: '24.25rem',
      overflow: 'hidden',
      flexShrink: 0,
      backgroundPosition: 'center',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      // padding:'1rem 3rem  3.0625rem',
      padding: '1.3rem 3.3rem',
      willChange:'auto',
      // position:'relative',
      zIndex:1,
    },
    joinRoomContent: {
      width: '26.5rem',
      padding: '0',
      paddingBottom: '3.0625rem',
      backgroundColor: commonModelBackground,
      display:'flex',
      alignContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },

    footer: {
      height: '6.25rem',
    },
    product: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '1.125rem 3rem 3.75rem',
      marginBottom: '-4.6875rem',
    },
    logoView:{
      display: 'flex',
      width:'18.75rem'
    },

    productTitle: {
      paddingLeft: '1.0075rem',
      fontSize:'0.875rem',
    },

    noticeTitle:  {
      paddingTop: '1rem',
      color: '#ff0000',
    },

    noticeGroup: {
      marginTop: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      alignItems: 'left',
    },

    logo: {
      width: '2.1875rem',
      height: '1.1875rem',
    },

    formContainer: {
      position: 'relative',
      width: '100%',
      padding: '0 2.875rem 3.4375rem ',
    },
    defaultFormContainer:{
      position: 'relative',
      width: '100%',
      padding: '0 2.875rem 3.4375rem ',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    numberPlateOccupied:{
      width:  '100%',
      height: '5rem',
      // position: 'relative',

    },
    modeSwitchAction: {
      backgroundColor: 'transparent',
      width: isDark ? '5.125rem' : '5.5rem',
      // height: '4.5rem',
      height: isDark ? '4.5rem' : '4.625rem',
      position:'absolute',
      right: isDark ? '5.125rem' : '4.75rem',
      cursor: 'pointer',
      top: '9.625rem',
      userSelect:'none',
      '&:hover':{
        backgroundColor:'#ffffff12'
      },
      '&:ative':{
        backgroundColor: 'transparent',
      }
    },
    agoraAction: {
      width:'5rem',
      height:'4.4375rem',
      position:'absolute',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      userSelect:'none',
      top: '9.625rem',
      right:isDark ? '4.75rem':'74px',
      '&:hover':{
        backgroundColor:'transparent',
        backgroundImage:`url(${CoverHover})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        transform: isDark ? 'scale(1.03)' : 'scale(1.08)',
        transformOrigin: 'top',
      }
    },
    handle: {
      position: 'absolute',
      width: '1.9375rem',
      height: '6.1875rem',
      left: 'calc(100% - 6.3rem )',
      top: '17.3rem',
      cursor: 'pointer',
      zIndex: 100,
    },
    agoraHandle:{
      position: 'absolute',
      width: '1.9375rem',
      height: '6.1875rem',
      left: 'calc(100% - 6.3rem )',
      top: '16.6rem',
      cursor: 'pointer',
      zIndex: 100,
    },
    modeSwitch: {
      position: 'absolute',
      marginTop: '-0.6rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '&> *': {
        marginBottom: '1rem',
      }
    },

    backgroundContainer: {
      flex: '1 !important',
      maxWidth: 'unset !important',
    },
    coverContainer: {
      display: 'flex',
      /* width: 50rem; */
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },

    coverImage: {
      flex: 1,
      /* width: 50rem; */
      height: '100%',
      backgroundImage: 'url(//assets/bizhijpeg)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },

    line: {
      width: '82%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // padding: isDark ? '1.84rem 0.75rem 0rem 1rem' : '1.84rem 0.75rem 0.258rem 1rem',
      padding:'0 0.75rem 0rem 1rem',
      marginTop: '0.9rem',
    },
    settingContent:{
      width:'18.75rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // padding: isDark ? '1.84rem 0.75rem 0rem 1rem' : '1.84rem 0.75rem 0.258rem 1rem',
      margin: '1.25rem auto 0',

    },

    subLine: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    subLinLoggedIn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    settingButton: {
      boxShadow: 'none !important',
      backgroundColor: 'transparent',
    },

    settingButtonDark: {
      // backgroundColor: '#2B2B2C !important',
      backgroundColor:'transparent',
      boxShadow: 'none',
    },

    subLinLoggedInContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      /* width: 725rem; */
      height: '1.875rem',
    },

    userText: {
      backgroundColor: 'rgba(184, 184, 203, 0.1)',
      borderRadius: '1rem',
      fontWeight: 600,
    },
    agoraName: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      paddingTop: '0.0625rem',
      paddingRight: '0.8rem',
      maxWidth: '15.5rem',
    },
    icon: {
      cursor: 'pointer',
      backgroundColor:'transparent'
    }
  })
})