import AgoraRTC from "agora-rtc-sdk-ng"
import AgoraRTM from 'agora-rtm-sdk'
import { useEffect, useCallback, useRef } from "react";
import { log, LOG_MODULE, LOG_TYPE } from "./Log";

export const VersionContents = [
  `Version: ${process.env.REACT_APP_APP_VERSION}`,
  `  RTC  : ${AgoraRTC.VERSION}`,
  `  RTM  : ${AgoraRTM.VERSION}`
]

export const versionMessage = {
  app: `${process.env.REACT_APP_APP_VERSION}`,
  rtc: `${AgoraRTC.VERSION}`,
  rtm: `${AgoraRTM.VERSION}`,
}

export function useInterval(callback: () => void, interval: number): void {
  useEffect(() => {
    const intervalID = window.setInterval(callback, interval);
    return () => clearInterval(intervalID);
  // eslint-disable-next-line
  }, []);
}

export const toMediaStatus = (video: boolean, audio: boolean, share?: boolean) => {
  return (audio ? 0 : 0b01) | (video ? 0 : 0b10) | (share ? 0b100 : 0);
}

export const formatDuration = (ms: number) => {
  const hour = Math.floor(ms / 3600000)
  const minute = Math.floor(ms / 60000) % 60
  const second = Math.floor(ms / 1000) % 60

  const formatHour = hour >= 10 ? hour : `0${hour}`
  const formatMinute = minute >= 10 ? minute : `0${minute}`
  const formatSecond = second >= 10 ? second : `0${second}`

  return hour > 0 ? `${formatHour}:${formatMinute}:${formatSecond}` : `${formatMinute}:${formatSecond}`
}

export const execCopy = (copyValue: string, successLog: string) => {
  const copyDOM = document.createElement('div')
  document.body.appendChild(copyDOM)
  copyDOM.innerText = copyValue
  const range = document.createRange()
  window.getSelection()?.removeAllRanges()
  range.selectNode(copyDOM)
  window.getSelection()?.addRange(range)
  if (document.execCommand('copy')) {
    log(`${successLog} ${copyValue}`, LOG_TYPE.INFO, LOG_MODULE.DEFAULT)
  }
  document.body.removeChild(copyDOM);
}

export const isChinese = () => {
  return navigator.language.toLowerCase().includes('zh')
}