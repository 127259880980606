import React, { forwardRef, Ref, useState } from "react";
import { observer } from 'mobx-react'
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { useTranslation } from "react-i18next";
import { useCommManagerStore, useUIStore, useUserStore } from "../../stores/hooks";
import { log, LOG_MODULE, LOG_TYPE } from "../../utils/Log";
import { CustomAlert } from '../CustomPopup/customAlert'
import useStyle from './style';
import { ReactComponent as IconLoading } from "../../assets/icon_loading.svg";
import { APPLY_ASSISTANT_LANG, APPLY_ASSISTANT_RESULT } from "../../utils/constants";

const MeetingView = observer(forwardRef((props, ref: Ref<HTMLDivElement>) => {
  const [t] = useTranslation()
  const styles = useStyle()
  const commManager = useCommManagerStore()
  const user = useUserStore()
  const uiStore = useUIStore()
  const roomInfo = commManager.roomInfo;
  const assistantInfo = commManager.assistantInfo;
  const [isShowAlert, setShowAlert] = useState(false)
  const [isShowAssistantAlert, setShowAssistantAlert] = useState(false)

  const roomOpDisabled = (roomInfo.hasHost && !roomInfo.isMeHost)

  const handleChangeRoomVideo = (event: any) => {
    if (!commManager.bizConnected) {
      setShowAlert(true)
      return
    }
    setShowAlert(false)
    commManager.enableRoomVideo(event.target.checked)
    log(`user ${event.target.checked ? 'enable' : 'disable'} room audio`, LOG_TYPE.COMM, LOG_MODULE.UI)
  }

  const handleChangeRoomAudio = (event: any) => {
    if (!commManager.bizConnected) {
      setShowAlert(true)
      return
    }
    setShowAlert(false)
    commManager.enableRoomAudio(event.target.checked)
    log(`user ${event.target.checked ? 'enable' : 'disable'} room audio`, LOG_TYPE.COMM, LOG_MODULE.UI)
  }

  const handleEnableHost = (enable: boolean) => {
    if (!commManager.bizConnected) {
      setShowAlert(true)
      return
    }
    setShowAlert(false)
    commManager.enableRoomHost(enable)
    log(`user ${enable ? 'apply' : 'give up'} room host`, LOG_TYPE.COMM, LOG_MODULE.UI)
  }

  const showAssistantAlert = () => {
    if (!commManager.bizConnected) {
      setShowAlert(true)
      return
    }
    setShowAlert(false)
    setShowAssistantAlert(true)

  }

  const handleEnableAssistant = (enable: boolean, language?: APPLY_ASSISTANT_LANG) => {
    if (!commManager.bizConnected) {
      setShowAssistantAlert(false)
      setShowAlert(true)
      return
    }

    setShowAssistantAlert(false)
    if (enable && language) {
      commManager.applyAssistant(language)
    } else {
      commManager.cancelAssistant()
    }

    log(`user ${enable ? 'apply' : 'give up'} room assistant`, LOG_TYPE.COMM, LOG_MODULE.UI)
  }

  return (
    <div className={styles.contentBox} ref={ref} id="meeting">
      <Box>
        <Typography variant="h3">{t('Meeting')}</Typography>
        <Box className={styles.commContainer}>
          <Box className={styles.hostInfo}>
            <Typography variant="subtitle1">{t('Host')}</Typography>
            <Typography id="meeting-host" variant="subtitle1" className={`${styles.hostName} notranslate`}>
              {roomInfo.hasHost ? (`${roomInfo.hostName}`) : (t('No Host'))}
            </Typography>
          </Box>
          {roomInfo.isMeHost &&
            <Button className={styles.safariButton} id="meeting-host-cancel" variant="outlined" color="secondary" onClick={() => handleEnableHost(false)}>{t('Cancel Application')}</Button>
          }
          {(!roomInfo.hasHost || (roomInfo.hasHost && !roomInfo.isMeHost)) &&
            <Button className={styles.safariButton} id="meeting-host-apply" variant="outlined" color="secondary" disabled={roomInfo.hasHost || (!user.isThirdPartyLoggedIn && !uiStore.isRoomModeNormal)}
              onClick={() => handleEnableHost(true)}>{t('Apply')}</Button>
          }
        </Box>
        <Typography variant="subtitle1" className={styles.meetingText}>{t('Video input')}</Typography>
        <FormControlLabel
          id="meeting-allow-camera"
          classes={{ root: styles.meetingLabelControl, label: styles.meetingLabel }}
          control={
            <Checkbox
              checked={roomInfo.videoState}
              onChange={handleChangeRoomVideo}
              name="enableVideo"
              color="primary"
              size="small"
              disabled={roomOpDisabled}
              icon={<span className={`${styles.checkBox}`} />}
              checkedIcon={<span className={`${roomOpDisabled ? styles.meetingCheckBoxDisabled : styles.checkedBox}`} />}
            />
          }
          label={<Typography className={`${roomOpDisabled ? styles.meetingDisabledText : ''}`} variant="subtitle1">{t('Allow participants to turn on the camera when entering room')}</Typography>}
        />
        <Typography variant="subtitle1" className={styles.meetingText}>{t('Audio input')}</Typography>
        <FormControlLabel
          id="meeting-allow-microphone"
          classes={{ root: styles.meetingLabelControl, label: styles.meetingLabel }}
          control={
            <Checkbox
              checked={roomInfo.audioState}
              onChange={handleChangeRoomAudio}
              name="enableAudio"
              color="primary"
              size="small"
              disabled={roomOpDisabled}
              icon={<span className={styles.checkBox} />}
              checkedIcon={<span className={`${roomOpDisabled ? styles.meetingCheckBoxDisabled : styles.checkedBox}`} />}
            />
          }
          label={<Typography className={`${roomOpDisabled ? styles.meetingDisabledText : ''}`} variant="subtitle1">{t('Allow participants to turn on the microphone when entering room')}</Typography>}
        />
        {commManager.isRoomAgora &&  
          <Box className={`${styles.commContainer} ${styles.assistantText}`}>
            <Box className={styles.hostInfo}>
              <Typography variant="subtitle1">{t('as_name')}</Typography>
              <Typography id="meeting-assistant" variant="subtitle1" className={`${styles.hostName} notranslate`}>
                {assistantInfo.assistantName ? (`${assistantInfo.assistantName}`) : (t('No Host'))}
              </Typography>
            </Box>
            {<div>
              {
              roomInfo.hasHost && roomInfo.isMeHost && assistantInfo.hasAssistant() ? null :
              // success: applyAssistantResult doesn't store. 
              ((!assistantInfo.hasAssistant() && (commManager.applyAssistantResult === APPLY_ASSISTANT_RESULT.DEFAULT || commManager.applyAssistantResult === APPLY_ASSISTANT_RESULT.SUCCESS)) || (assistantInfo.hasAssistant() && !assistantInfo.isMeAssistant() && commManager.applyAssistantResult === APPLY_ASSISTANT_RESULT.DEFAULT)) &&
                (<Button className={styles.safariButton} id="meeting-assistant-apply" variant="outlined" color="secondary"
                  disabled={assistantInfo.hasAssistant()}
                  onClick={() => showAssistantAlert()}>{t('as_setting_apply')}</Button>)
              }

              {/* 申请中 loading */}
              {!assistantInfo.hasAssistant() && commManager.applyAssistantResult === APPLY_ASSISTANT_RESULT.WAIT &&
                (<Button className={styles.safariButton} id="meeting-assistant-apply" variant="outlined" color="secondary"
                  disabled><IconLoading className={styles.loading} /></Button>)
              }

              {/* 取消申请 */}
              {
              roomInfo.hasHost && roomInfo.isMeHost && assistantInfo.hasAssistant() ? 
              // 主持人视角
              (<Button className={styles.safariButton} id="meeting-assistant-cancel" variant="outlined" color="secondary" onClick={() => handleEnableAssistant(false)}>{t('as_setting_cancel_host')}</Button>)
              :
              assistantInfo.hasAssistant() && assistantInfo.isMeAssistant() &&
              // 会议助手视角
                (<Button className={styles.safariButton} id="meeting-assistant-cancel" variant="outlined" color="secondary" onClick={() => handleEnableAssistant(false)}>{t('as_setting_cancel')}</Button>)
              }

              {/* 申请失败 */}
              {commManager.applyAssistantResult === APPLY_ASSISTANT_RESULT.REFUSE &&
                (<Button className={`${styles.buttonFailed} ${styles.safariButton}`} id="meeting-assistant-apply" variant="outlined" color="secondary"
                disabled>{t('as_setting_apply_fail')}</Button>)
              }
            </div>}
          </Box>
        }
        {commManager.isRoomAgora && <Typography variant="body1" className={`${styles.assistantTips}`}>{t('as_setting_info_all')}</Typography>}
      </Box>
      <CustomAlert
        isOpen={isShowAlert || isShowAssistantAlert}
        content={
          isShowAlert ? <Typography variant="subtitle2">{t('Biz disconnected')}</Typography> : <Typography variant="subtitle2">{t('as_setting_title')}</Typography>
        }
        style={styles.customDialog}
        isNeedPosition={false}
        isAlert={true}
        buttonGroups={
          isShowAlert ? 
          <Box className={styles.customButton}>
            <Button variant="contained" color="primary" className={styles.logoutButton} onClick={() => setShowAlert(false)}>
              <Typography variant="h4">{t('OK')}</Typography>
            </Button>
          </Box>
          :
          <Box className={styles.assistantButtonBox}>
              <Button variant="contained" color="primary" id="translation-to-english" className={styles.assistantButton}
                onClick={() => handleEnableAssistant(true, APPLY_ASSISTANT_LANG.EN)}>
                <Typography variant="h4">{t('as_setting_choice_en')}</Typography>
              </Button>
              <Button variant="contained" color="primary" id="translation-to-chinese"
                className={styles.assistantButton} onClick={() => handleEnableAssistant(true, APPLY_ASSISTANT_LANG.CH)}>
                <Typography variant="h4">{t('as_setting_choice_cn')}</Typography>
            </Button>
            <Button variant="contained" className={styles.assistantButton} id="translation-cancel" onClick={() => setShowAssistantAlert(false)}>
              <Typography variant="h4">{t('Cancel')}</Typography>
            </Button>
          </Box>
        }
      />
    </div>
  )
}))

export default MeetingView
