import { createStyles, makeStyles, Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) => {
  const itemWidth = '17rem';
  const { customColor: { componentsBackGround, boxShadow ,timerColor,operationButtonBackground, roomHeaderButtonColor} } = theme;
  const backgroundColor = componentsBackGround;
  return createStyles({
    triangleTop: {
      position: 'absolute',
      fill: theme.palette.background.paper,
      transform: 'translate(-50%,-50%)',
      top: '-0.4375rem',
      left: '50%',
      marginRight: '1.375rem',
    },
    root: {
      display: 'flex',
      alignItems: 'stretch',
      // zIndex: 3,
    },
    listViewControl: {
      backgroundColor,
      width: '8.75rem',
      borderRadius: '0.75rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '3.75rem',
      marginRight: '.75rem',
    },
    listViewIconButton: {
      width: '1.5rem',
      height: '1.5rem',
      marginLeft: '.5rem',
      boxShadow: boxShadow as string,
      backgroundColor:operationButtonBackground,
      borderRadius:'0.3125rem'
    },
    listViewIcon: {
    },
    roomHeader: {
      height: '3.75rem',
      flex: 1,
      display: 'flex',
      backgroundColor,
      borderRadius: '0.75rem',
      width: '52rem',
      padding: '0.94rem 1rem 0.94rem 1.88rem',
      justifyContent: 'space-between',

      alignItems: 'center'
    },

    logoContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      width: '3.31rem',
      height: '1.88rem',
      marginRight: '2rem',
    },
    roomInfo: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    roomNameContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center'

    },
    duration: {
      fontSize: '0.6rem',
      color:timerColor,
    },
    setting: {
      // center ROOM_NAME when chat panel opens
      // minWidth: itemWidth,
      flexWrap: 'nowrap',
      marginRight: '-0.5rem',
      justifyContent: 'flex-end',
      '& .item': {
        width: '1.2rem',
        height: '1.2rem',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }
    },
    iconButton: {
      marginLeft: '1rem',
      boxShadow: boxShadow as string,
    },
    popoverContainer: {
      // pointerEvents: "none",
      // zIndex: 1000,
      '& > .MuiPopover-paper': {
        overflowX: 'revert',
        overflowY: 'revert',
      },
    },
    popover: {
      pointerEvents: "auto",
      padding: '0.75rem 1rem',
      borderRadius: '0.87rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: '15.25rem',
      wordBreak: 'break-all',
    },
    popoverEn: {
      pointerEvents: "auto",
      padding: '0.75rem 1rem',
      borderRadius: '0.87rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: '15.25rem',
      wordBreak: 'break-all',
      marginLeft:'0.125rem'
    },
    popoverNormalLink: {
      padding: '0.75rem 1rem',
      borderRadius: '0.87rem',
    },

    popoverContentItem: {
      fontSize: '0.75rem',
      fontWeight: 400,
      '& > span': {
        fontWeight: 600,
      },
    },
    popoverCopyText: {
      display: 'flex',
      justifyContent: 'center',
    },
    popoverSecondItem: {
      paddingTop: '0.5rem',
    },
    popoverTipItem: {
      fontSize: '0.75rem',
      paddingTop: '0.5rem',
    },
    copyLink: {
      fontWeight: 600,
      cursor: 'pointer',
      color: theme.palette.primary.main,
      paddingTop: '0.5rem',
    },
    buttonBox: {
      display: 'flex',
      marginTop: '1.125rem',
      justifyContent: 'space-between',
      width: '13.25rem',
    },
    popoverButton: {
      fontSize: '0.75rem',
      width: '6.14rem',
      height: '1.88rem',
    },
    popverCancelColor: {
      background: roomHeaderButtonColor,
    }
  })
})
