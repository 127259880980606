import { makeStyles, createStyles, Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) => {
  const { customColor: { issueDialogTitleBorder, boxShadow } } = theme;
  return createStyles({
    paper:{
      overflow: 'inherit',
      borderRadius: '0.75rem',
      fontSize: '0.75rem',
      marginTop: '1rem',
      marginLeft:'.5rem'
    },
    tooltip: {
      padding: 0
    },
    container: {
      margin: '0.75rem',
    },
    signalView: {
      borderRadius:'0.75rem'
    },
    serverStatus: {
      padding: '0.75rem',
      maxWidth:'15.25rem'
    },
    signalStatus: {
      paddingTop: '0.5rem',
      paddingBottom: '0.75rem',
      textAlign: "center",
      borderTop: `1px solid ${issueDialogTitleBorder}`
    },
    triangle: {
      transform: 'translate(-50%,-50%)',
      position: 'absolute',
      left: '50%',
      top: '-0.4375rem',
      fill: theme.customColor.issueFeedBackResult,
    },
    iconButton: {
      marginLeft: '1rem',
      boxShadow: boxShadow as string,
    },
    button: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyItems: 'center',
      alignContent: 'center'
    },
  })
})