import { Badge, Box, ClickAwayListener, IconButton, Tooltip, } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconAudioActive } from "../../assets/icon_control_audio_active_effect.svg";
import { ReactComponent as IconAudioInactive } from "../../assets/icon_control_audio_inactive.svg";
import { ReactComponent as IconHangup } from "../../assets/icon_control_hangup.svg";
import { ReactComponent as IconVideoActive } from "../../assets/icon_control_video_active.svg";
import { ReactComponent as IconVideoInactive } from "../../assets/icon_control_video_inactive.svg";
import { ReactComponent as IconChatInactive } from "../../assets/icon_control_chat.svg";
import { ReactComponent as IconShareInactive } from "../../assets/icon_control_screen_share.svg";
import { ReactComponent as IconParticipants } from "../../assets/icon_control_participants.svg";
import { useCommManagerStore, useRtcCommLayerStore, useRtmCommLayerStore, useShareManagerStore, useUserManagerStore, useRtcEngineStore, } from "../../stores/hooks";
import { ReactComponent as IconScreenShare } from "../../assets/icon_screen_share.svg";
import CloudRecordingView from "../CloudRecording/CloudRecordingView";
import { isChrome, isFirefox, isSafari, exceedSafari, } from "../../utils/userAgentDetector";
import ShareInitView from "./ShareInitView";
import CustomDialog from "../Alert/CustomDialog";
import { CustomPop } from "../CustomPopup/customPop";
import styles from "./style.module.css";
import { useStyles } from "./style";
import { execCopy } from '../../utils/helper'
import { ATLAS_RECORDING_URL } from '../../utils/constants'
import ShareWatermarkRequestView from '../ShareWatermarkRequest/ShareWatermarkRequest'

export interface RoomControlViewProps {
  toggleChatPanel: () => void,
  toggleParticipant: () => void,
  hangup: () => void,
  style?: React.CSSProperties,
  isParticipantsActive: boolean,
  isChatActive: boolean
}


const isScreenShareSupport = () => {
  return isChrome() || isFirefox() || exceedSafari(13)
}

const RoomControlView = observer((props: RoomControlViewProps) => {
  const [t] = useTranslation()
  const rtcComm = useRtcCommLayerStore()
  const rtmComm = useRtmCommLayerStore()
  const commManager = useCommManagerStore()
  const userManager = useUserManagerStore()
  const shareManager = useShareManagerStore()
  const rtcStore = useRtcEngineStore()
  const classes = useStyles()
  const [isShowPop, setShowPop] = useState(shareManager.isShowErrorMessage)
  const [customPopContent, setCustomPopContent] = useState('')
  const [isShowPopAudio, setShowPopAudio] = useState(false)
  const [isShowPopButtonAudio, setShowPopButtonAudio] = useState(false)
  const [isShowShareRequest, setShowShareRequest] = useState(false)

  const anchorElShare = useRef<HTMLDivElement>(null)
  const anchorElAudio = useRef<HTMLDivElement>(null)

  const [recordingLeave, setRecordingLeave] = React.useState(false)
  const handleLeave = () => {
    if (commManager.localCloudRecordingIconState) {
      setRecordingLeave(true)
      execCopy(ATLAS_RECORDING_URL, 'on atlas recording search url copy success')
    } else {
      props.hangup()
    }
  }
  useEffect(() => {
    if (shareManager.isShowErrorMessage) {
      setShowPop(true)
      setCustomPopContent(t("Screen share going already"))
    } else {
      setShowPop(false)
    }

  }, [shareManager.isShowErrorMessage])

  const handleScreenShare = async() => {
    if (!isScreenShareSupport()) return
    if (shareManager.isSharing) {
      shareManager.toggleShare()
      return
    }
    if (shareManager.isRemoteSharing) {
      setShowPop(true)
      setCustomPopContent(t('sharingScreen', { name: shareManager.remoteShareOwner?.showName }))
      return;
    }

    if (commManager.isRoomAgora && commManager.isOpenWatermark) {
      setShowShareRequest(true)
    } else {
      shareManager.toggleShare()
    }
  }

  const handleTooltipMediaClose = () => {
    rtcComm.resetMediaDisabledDefault()
  }

  const onCustomPop = () => {
    setShowPop(false)
    shareManager.resetShowErrorMessage()
  }

  const handleLocalAudio = () => {
    // if participants more than 50, add a pop for double check
    if (rtcComm.mineAudioState === false && userManager.userCount >= 50) {
      setShowPopAudio(true)
      setShowPopButtonAudio(true)
      setCustomPopContent(t("microphone_multiplayer_request"))
    } else {
      commManager.setLocalAudio(!rtcComm.mineAudioState)
    }
  }

  const onCloseAudioPop = () => {
    setShowPopAudio(false)
  }

  const onConfirmAudio = () => {
    setShowPopAudio(false)
    setShowPopButtonAudio(false)
    commManager.setLocalAudio(!rtcComm.mineAudioState)
  }

  const handleCloseShareRequest = () => {
    setShowShareRequest(false)
  }

  return (
    <Box className={classes.footer} display="flex" alignItems="center" justifyContent="center" style={{ ...props.style }}>
      <div className={classes.container}>
        <div className={styles.left}>
          <ClickAwayListener onClickAway={() => handleTooltipMediaClose()}>
            <Tooltip
              open={rtcComm.mediaAutoMutedTip}
              onClose={() => handleTooltipMediaClose()}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              arrow
              title={t("MicrophoneAndCameraDisabledDefault")}
            >
              <div className={styles.mediaItem}>
                <ClickAwayListener onClickAway={() => handleTooltipMediaClose()}>
                  <Tooltip
                    open={rtcComm.audioAuteMutedTip }
                    onClose={() => handleTooltipMediaClose()}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                    title={t("MicrophoneDisabledDefault")}
                  >
                    <div>
                      <Tooltip title={<Box>{t("Audio input")}</Box>} placement="top"  disableFocusListener disableTouchListener >
                        <div className={styles.item} ref={anchorElAudio}>
                        <IconButton className={`${classes.buttonAudio}`} id="toggle-audio" onClick={() => handleLocalAudio()}>
                            {
                              rtcComm.mineAudioState ?
                              <>
                                <div className={classes.micBoxControl}>
                                  <div className={classes.micShadeControl} style={{ height: `${Math.ceil(rtcStore.volumeLevel * 100) }%` }}/>
                                </div>
                                <IconAudioActive className={classes.iconButton} /> 
                              </>
                              :
                              <IconAudioInactive />
                            }
                          </IconButton>
                        </div>
                      </Tooltip>
                      </div>
                  </Tooltip>
                </ClickAwayListener>

                <ClickAwayListener onClickAway={() => handleTooltipMediaClose()}>
                  <Tooltip
                    open={rtcComm.videoAutoMutedTip }
                    onClose={() => handleTooltipMediaClose()}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                    title={t("CameraDisabledDefault")}
                  >
                    <div>
                      <Tooltip title={<Box>{t("Video input")}</Box>} placement="top" disableFocusListener disableTouchListener>
                      <div className={styles.item} onClick={() => commManager.setLocalVideo(!rtcComm.mineVideoState)}>
                          <IconButton id="toggle-video">
                            {rtcComm.mineVideoState ? <IconVideoActive className={classes.iconButton} /> : <IconVideoInactive />}
                          </IconButton>
                        </div>
                      </Tooltip>
                    </div>
                  </Tooltip>
                </ClickAwayListener>
              </div>
            </Tooltip>
          </ClickAwayListener>
          {isScreenShareSupport() ?
            <Tooltip title={<Box>{isSafari() ? t('ShareScreenLimit') : t("ShareScreen")}</Box>} placement="top" disableFocusListener disableTouchListener>
              <div className={styles.item} ref={anchorElShare}>
                <IconButton id="toggle-share" className={`${shareManager.isSharing ? classes.iconBackgroundActive : ""} ${classes.shareButton}`} onClick={handleScreenShare}>
                  {
                    shareManager.isInitializing ?
                      <ShareInitView />
                      :
                      (<IconScreenShare className={classes.iconButton} />)
                  }
                </IconButton>
              </div>
            </Tooltip>
            :
            <Tooltip disableFocusListener title={t('ShareScreenLimit')}  disableTouchListener>
              <div className={styles.item}>
                <IconButton disabled>
                  <IconShareInactive  className={classes.iconButton} />
                </IconButton>
              </div>
            </Tooltip>
          }
          {isShowShareRequest && <ShareWatermarkRequestView setClose={handleCloseShareRequest} /> }
          <CloudRecordingView />
          {/* <Tooltip title={<Box>{t('CloudRecording')}</Box>}>
            <div className={styles.item}>
              <IconButton id="cloud-record">
                <IconCloudRecord className={classes.iconButton} />
              </IconButton>
            </div>
          </Tooltip> */}
          <Tooltip title={<Box>{t('Participants')}</Box>} disableFocusListener disableTouchListener>
            <div className={styles.item}>
              <IconButton id="toggle-Participants"
                className={props.isParticipantsActive ? classes.iconBackgroundActive : classes.iconButton}
                onClick={() => props.toggleParticipant()}>
                <Badge className="notranslate" badgeContent={userManager.userCount} overlap="circle" max={999}
                  classes={{ badge: props.isParticipantsActive ? classes.badgeActive : classes.badge }}>
                  <IconParticipants className={classes.iconButton} />
                </Badge>
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title={<Box>{t('Chat')}</Box>} disableFocusListener disableTouchListener>
            <div className={styles.item}>
              <IconButton id="toggle-chat" className={props.isChatActive ? classes.iconBackgroundActive : classes.chatIconBack} onClick={() => props.toggleChatPanel()}>
                <Badge variant="dot" color="error" overlap="circle" invisible={rtmComm.unreadMsgCount === 0} >
                  <IconChatInactive className={classes.chatButton} />
                </Badge>
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title={t('Leave')} disableFocusListener disableTouchListener>
            <div className={styles.item}>
              <IconButton className={classes.hangupIconBack} onClick={handleLeave} id="end-meeting">
                <IconHangup />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </div>
      {
        <CustomDialog
          open={recordingLeave}
          title={'ConfirmCloudRecordingLeave'}
          confirmText={commManager.localCloudRecordingIconState ? "LeavingRoom" : ""}
          onOK={props.hangup}
          onCancel={() => setRecordingLeave(false)}
          onClose={() => setRecordingLeave(false)}
          content={"CopyCloudRecordingLink"}
          isComfirmType={true}
        />
      }
      <CustomPop
        isOpen={isShowPop || isShowPopAudio}
        anchorEl={isShowPop ? anchorElShare.current : anchorElAudio.current}
        isNeedTriangle={true}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        trianglePosition="bottom"
        transformOrigin={{
          vertical: "bottom",
          horizontal: 'center',
        }}
        classes={classes.paper}
        isShowButton={isShowPopButtonAudio}
        content={customPopContent}
        onClose={isShowPop ? onCustomPop : onCloseAudioPop}
        onConfirm={isShowPop ? undefined : onConfirmAudio}
        onCancel={isShowPop ? onCustomPop : onCloseAudioPop}
      />

    </Box>
  )
})

export default RoomControlView