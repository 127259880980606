import { Box, Button, MenuItem, Select, Typography, useTheme } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { forwardRef, Fragment, Ref, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as IconSelectDown } from '../../assets/icon_select_down.svg'
import { useRtcEngineStore, useUserStore, useShareManagerStore } from '../../stores/hooks'
import { ReactComponent as IconOccupations } from "../../assets/icon_occupations.svg";
import VideoPreview from '../VideoPreview'
import useStyle from "./style"
import { codecMap } from '../../stores/rtc/rtcEngineLayer'

const resolutionMap = [
  {
    value: 0,
    resolution: '480p',
  },
  {
    value: 50,
    resolution: '720p',
  },
  {
    value: 100,
    resolution: '1080p',
  }
]

interface MediaViewProp {
  isInRoom: boolean,
  isShowVideoPreview: boolean,
}

const volumes: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

const MediaView = observer(forwardRef((props: MediaViewProp, ref: Ref<HTMLDivElement>) => {
  const [t] = useTranslation()
  const styles = useStyle()
  const userStore = useUserStore()
  const rtcStore = useRtcEngineStore()
  const shareStore = useShareManagerStore()
  const [showVolume, setShowVolume] = useState(false)
  const theme = useTheme()

  useEffect(() => {
    return () => {
      if (!props.isInRoom) {
        rtcStore.stopTestMicrophone()
      }
    }
  }, [])

  useEffect(() => {
    if (!rtcStore.audioTrack && showVolume) {
      setShowVolume(false)
    }
  }, [rtcStore.audioTrack])

  const toggleVolumeTest = () => {
    if (showVolume) {
      rtcStore.stopTestMicrophone()
      setShowVolume(false)
    } else {
      rtcStore.startTestMicrophone().then(() => {
        setShowVolume(true)
      })
    }
  }

  return (
    <div className={`${styles.contentBox} ${props.isInRoom ? styles.roomMediaView : styles.mediaView}`} ref={ref} id="media">
      <Typography variant="h3">{t('Media')}</Typography>
      <Box >
        <Typography variant="subtitle1" className={`${styles.videoText}`}>{t('Video input')}</Typography>
        {!props.isInRoom && (<Box className={styles.videoPreview}>
          {userStore.IsCameraReady && props.isShowVideoPreview ? <VideoPreview /> :
            <IconOccupations fill={theme.customColor.occupationsIconColor} />
          }</Box>)}
        <Select classes={{ selectMenu: styles.selectTextColor }}
          IconComponent={IconSelectDown} variant="outlined" value={userStore.cameraId} onChange={(event: any) => { userStore.setCameraId(event.target.value as string) }}>
          {rtcStore.cameraDeviceList.map(device => (
            <MenuItem className={styles.menuItem} value={device.deviceId} key={device.deviceId}>{device.label}</MenuItem>
          ))}
        </Select>
      </Box>
      <Box className={styles.commonBox}>
        <Box className={styles.resolutionText}>
          <Typography variant="subtitle1">{t('Resolution')}</Typography>
          <Typography variant="body1" className={styles.resolutionTips}>{t('The higher resolution and others will see you clearer')}</Typography>
        </Box>
        <Select classes={{ selectMenu: styles.selectTextColor }}
          IconComponent={IconSelectDown} variant="outlined" value={userStore.videoProfileValue} onChange={(event: any) => userStore.setVideoProfile(event.target.value as number)}>
          {resolutionMap.map(resolution => (
            <MenuItem id={`resolution-${resolution.resolution}`} className={styles.menuItem} value={resolution.value} key={resolution.resolution}>{resolution.resolution}</MenuItem>
          ))}
        </Select>
      </Box>
      <Box className={styles.commonBox}>
        <Typography variant="subtitle1">{t('Audio input')}</Typography>
        <Box className={styles.micBox}>
          <Select classes={{ selectMenu: styles.selectTextColor }}
            IconComponent={IconSelectDown} variant="outlined" value={userStore.micId} onChange={(event: any) => { userStore.setMicrophoneId(event.target.value as string) }}>
            {rtcStore.micDeviceList.map(device => (
              <MenuItem className={styles.menuItem} value={device.deviceId} key={device.deviceId}>{device.label}</MenuItem>
            ))}
          </Select>
          {!props.isInRoom && userStore.IsMicrophoneReady
            && <Button variant="outlined" color="secondary" onClick={toggleVolumeTest}>{showVolume ? t('Finish') : t('Test')}</Button>}
        </Box>
        {!props.isInRoom && showVolume && (
          <Box className={styles.volumeBox}>
            {volumes.map((value, index) => {
              return <div className={(index + 1) <= rtcStore.volumeLevel * 20 ? styles.volumeIndicator : styles.volumeNone} key={index} />
            })}
          </Box>
        )}
      </Box>
      {!props.isInRoom &&
        <Fragment>
          <Box className={styles.commonBox}>
            <Typography variant="subtitle1">{t('CameraCodec')}</Typography>
            <Select classes={{ selectMenu: styles.selectTextColor }}
              IconComponent={IconSelectDown} variant="outlined" value={rtcStore.getCodec()} onChange={(event: any) => rtcStore.setCodec(event.target.value as string)}>
              {codecMap.map(item => (
                <MenuItem className={styles.menuItem} value={item} key={item}>{item}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box className={styles.commonBox}>
            <Typography variant="subtitle1">{t('ScreenShareCodec')}</Typography>
            <Select classes={{ selectMenu: styles.selectTextColor }}
              IconComponent={IconSelectDown} variant="outlined" value={rtcStore.getScreenShareCodec()}
              onChange={(event: any) => rtcStore.setScreenShareCodec(event.target.value as string)}>
              {codecMap.map(item => (
                <MenuItem className={styles.menuItem} value={item} key={item}>{item}</MenuItem>
              ))}
            </Select>
          </Box>
        </Fragment>
      }

    </div>
  )
}))

export default MediaView
