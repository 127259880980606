import AgoraRTC, {
  ConnectionDisconnectedReason,
  ConnectionState,
  DeviceInfo,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  IBufferSourceAudioTrack,
  ICameraVideoTrack,
  ILocalAudioTrack,
  ILocalTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
  NetworkQuality,
  SDK_CODEC,
  SDK_MODE,
  UID,
  VideoEncoderConfigurationPreset
} from "agora-rtc-sdk-ng";
import { action, computed, observable, runInAction } from "mobx";
import { Biz, Control, Metadata } from "../../types/metadata";
import { APPID, BI_REPORT_EVENT, ENCRYPTION_MODE, SDK_ENCRYPTION_MODE, VOLUME } from "../../utils/constants";
import { log, logException, logIf, LOG_MODULE, LOG_TYPE } from "../../utils/Log";
import "../../utils/protobuf/RTCMetadata";
import { reporter } from "../../utils/Reporter";
import { Aes128GcmDecrypt, Aes128GcmEncrypt, GenerateAes128GcmCryptoKey } from "../../utils/tools";
import { showQualityLog } from "../model/NetworkEval";
import { NotificationStore } from "../notification";
import { RootStore } from "../rootStore";

// tslint:disable-next-line: no-var-requires
const protobuf = require("protobufjs/minimal").roots["default"].protobuf
// tslint:disable-next-line: no-var-requires
const rtcReconnected = require("../../assets/rtc_reconnected.mp3")
// tslint:disable-next-line: no-var-requires
const rtcDisconnected = require('../../assets/rtc_disconnected.mp3')

export const CODEC: SDK_CODEC = "vp8" // h264
export const CHANNL_MODE: SDK_MODE = "rtc" // live
export const METADATA_ENABLED = (CODEC === ('h264' as SDK_CODEC))
export const METADATA_INTERVAL = 2000 // sdk colleague warning: not too frequent, it will encode I frame(costs a lot)
export const DATA_STREAM_ENABLED = true

export enum LOCAL_AUDIO_STATE {
  CLOSED = 0,
  DISABLE = 1,
  ENABLE = 2,
}

export enum LOCAL_VIDEO_STATE {
  DISABLE = 0,
  PREVIEW = 1,
  ENABLE = 2,
}

// "DISCONNECTED" | "CONNECTING" | "RECONNECTING" | "CONNECTED" | "DISCONNECTING"
export enum RTCConnectState {
  DISCONNECTED = 0,
  CONNECTING = 1,
  CONNECTED = 2,
  RECONNECTING = 3,
  DISCONNECTING = 4,
  UNKNOWN = 5,
}

// metadata type, "metadata" | "dataStream"

export enum MetadataType {
  metadata = 'metadata',
  dataStream = 'dataStream',
}

const AES_GCM_IV_LEN = 12
const AES_GCM_TAG_LEN = 16
const AES_GCM_KEY_LEN = 16
const AES_GCM_DECRYPTED_MIN_LEN = AES_GCM_IV_LEN + AES_GCM_TAG_LEN

const RESUBSCRIBE_INTERVAL = 3000

class WaitingSubscribe {
  constructor(remote: IAgoraRTCRemoteUser, type: "video" | "audio") {
    this.user = remote
    this.mediaType = type
  }

  public user: IAgoraRTCRemoteUser
  public mediaType: "video" | "audio"
}

export const codecMap = [
  "h264", "vp8", "vp9", "av1"
]

export class RtcEngineLayerStore {

  private client!: IAgoraRTCClient

  public selfStreamId: number = 0
  public channelName: string = ''

  public audioTrack?: ILocalAudioTrack
  private audioTrackOperating = false
  private expectedAudio: LOCAL_AUDIO_STATE = LOCAL_AUDIO_STATE.CLOSED

  public videoTrack?: ILocalVideoTrack
  private videoTrackOperating = false
  private expectedVideo: LOCAL_VIDEO_STATE = LOCAL_VIDEO_STATE.DISABLE
  private localVideoCanvas?: HTMLElement

  @observable
  public channelConnState: RTCConnectState = RTCConnectState.DISCONNECTED

  private selfShareStreamId: number = 0
  private isEncrypted = false
  private encryptionKey: string = ""
  private channelCryptoKey?: CryptoKey

  private notification: NotificationStore
  private rootStore: RootStore

  private soundEffectsMap: Map<string, IBufferSourceAudioTrack> = new Map()

  private waitingSubscribeList: WaitingSubscribe[] = []
  private resubscribeTimer?: number

  @observable
  public volumeLevel = 0

  @observable
  public unsubscribeAudioStreamId: Set<number> = new Set()

  @observable
  public unsubscribeVideoStreamId: Set<number> = new Set()

  private volumeTimer?: any

  @observable
  private cameraCodec = "vp9";

  @observable
  private screenShareCodec = "av1";

  public constructor(rootStore: RootStore, notification: NotificationStore) {
    this.rootStore = rootStore
    this.notification = notification;

    (AgoraRTC as any).setParameter("PING_PONG_TIME_OUT", 3)

    AgoraRTC.onCameraChanged = (info: any) => {
      log(`rtc video device state changed, type: camera, device name: ${info.device.label}, device id: ${info.device.deviceId},
       device state: ${info.state}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

      this.handleCameraChanged(info)
    }

    AgoraRTC.onMicrophoneChanged = (info: any) => {
      log(`rtc audio device state changed, type: microphone, device name: ${info.device.label}, device id: ${info.device.deviceId},
       device state: ${info.state}`, LOG_TYPE.COMM, LOG_MODULE.RTC)
      this.handleMicrophoneChanged(info)
    }

    AgoraRTC.onAudioAutoplayFailed = () => {
      log(`rtc audio autoplay failed`, LOG_TYPE.ERROR, LOG_MODULE.RTC)

      this.notification.addAlert(false, 'AutoplayFailed')
    }

    AgoraRTC.enableLogUpload()

    this.createClient(this.cameraCodec)

    // cache rtc disconnect sound after initing client
    this.cachePlaySoundEffect(rtcDisconnected)
  }

  private createClient(codec: string) {
    this.client = AgoraRTC.createClient({
      codec: (codec as any), mode: CHANNL_MODE,
    })

    log(`rtc create client ${codec} ${CHANNL_MODE}, metadata enabled: ${METADATA_ENABLED}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    this.client.enableDualStream().catch((error: any) => {
      logException(`enable dual stream failure `, error, LOG_MODULE.RTC)
    })

    this.initClientEvents()
  }

  @action
  public setCodec(codec: string) {
    if (this.cameraCodec !== codec) {
      this.cameraCodec = codec

      this.createClient(this.cameraCodec)
    }
  }

  public getCodec(): string {
    return this.cameraCodec
  }

  @action
  public setScreenShareCodec(codec: string) {
    this.screenShareCodec = codec
  }

  public getScreenShareCodec() {
    return this.screenShareCodec
  }

  public async joinChannel(channelName: string, rtcEncryption: boolean, rtcSecretKey: string, streamId: number, token: string, name: string,encryptionMode:ENCRYPTION_MODE,salt:Uint8Array | undefined) {
    log(`rtc join channel, channel: ${channelName}, encryption: ${rtcEncryption} stream id: ${streamId}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

    this.clearWaitingSubscribeList()

    this.channelName = channelName
    this.selfStreamId = streamId
    this.isEncrypted = rtcEncryption
    this.encryptionKey = rtcSecretKey
    if (rtcSecretKey) {
      this.channelCryptoKey = await GenerateAes128GcmCryptoKey(rtcSecretKey)
    }

    this.client.enableAudioVolumeIndicator()

    this.prepareMetadata(name)

    this.setVideoResolution(this.rootStore.user.user.videoProfile)

    if (this.isEncrypted) {
      if (encryptionMode !== ENCRYPTION_MODE.AES_128_GCM) {
        throw new Error(`Unsupported encryption type:${encryptionMode}`)
      }

      const mode = ENCRYPTION_MODE[encryptionMode]
      const sdkMode = SDK_ENCRYPTION_MODE[mode]
      log(`rtc login sdkMode:${sdkMode}`)
      this.client.setEncryptionConfig(sdkMode, this.encryptionKey)
    } else {
      this.client.setEncryptionConfig(SDK_ENCRYPTION_MODE.NONE, this.encryptionKey)
    }

    await reporter.promiseDecorate(this.client.join(APPID, channelName, token, streamId), BI_REPORT_EVENT.RTC_JOIN_CHANNEL)

    log(`rtc join over`, LOG_TYPE.INFO, LOG_MODULE.RTC)
  }

  public async leaveChannel(): Promise<void> {
    log('rtc leave channel', LOG_TYPE.COMM, LOG_MODULE.RTC)

    try {
      // not care anyone throw exception
      this.client.leave()

      this.clearChannelState()
    } catch (error) {
      logException(`leave channel exception`, error, LOG_MODULE.RTC)
    }
  }

  public clearChannelState() {
    this.closeLocalAudio()
    this.disableLocalVideo()

    this.stopSendMetadata()
    this.metadataUint8Array = undefined

    this.selfShareStreamId = 0

    this.clearWaitingSubscribeList()
    this.isEncrypted = false

    this.unsubscribeVideoStreamId.clear()
    this.unsubscribeAudioStreamId.clear()
  }

  private initClientEvents(): void {
    this.client.on("connection-state-change", (curState, revState, reason) => {
      log(`rtc event: connection-state-change ${curState} reason: ${reason}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

      this.onConnectionStateChange(curState, reason)
    })

    this.client.on("user-joined", (user: IAgoraRTCRemoteUser) => {
      log(`rtc event: user-joined stream id: ${user.uid} `, LOG_TYPE.COMM, LOG_MODULE.RTC)

      if (user.uid === this.selfShareStreamId) {
        log(`ignore self screen share stream join, stream id: ${user.uid}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
        return
      }
      const userUid = Number(user.uid)
      if (this.unsubscribeAudioStreamId.has(userUid)) {
        this.client.unsubscribe(user, 'audio')
      }
      if (this.unsubscribeVideoStreamId.has(userUid)) {
        this.client.unsubscribe(user, 'video')
      }
      this.rootStore.rtcCommLayer.onStreamUserJoin(userUid, user.hasAudio && user.audioTrack !== undefined, user.hasVideo && user.audioTrack !== undefined,
       user.audioTrack, user.videoTrack)
    })

    this.client.on("user-published", async (user, mediaType) => {
      log(`rtc event: user-published stream id: ${user.uid}, media type: ${mediaType}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

      if (user.uid === this.selfShareStreamId) {
        log(`ignore self screen share stream publish, stream id: ${user.uid}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
        return
      }

      if (!this.isInChannel) {
        log(`ignore user-published, rtc not in channel anymore ${RTCConnectState[this.channelConnState]}`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
        return
      }

      try {
        const unsubscribeUser = mediaType === 'audio' ? this.unsubscribeAudioStreamId : this.unsubscribeVideoStreamId
        if (!unsubscribeUser.has(Number(user.uid))) {
          await this.subscribe(user, mediaType);
        }

      } catch (e) {
        logException(`subscribe remote track exception ${user.uid} ${mediaType}`, e, LOG_MODULE.RTC)

        if (this.checkRemoteUserMediaValid(user, mediaType)) {
          this.addWaitingSubscribe(user, mediaType)
        }

        // subscribe exception, do not change user meida status
        return
      }

      this.handleRemoteMediaStateChange(user, mediaType)
    })

    this.client.on("user-unpublished", (user, mediaType) => {
      log(`rtc event: user-unpublished stream id: ${user.uid}, media type: ${mediaType} hasAudio: ${user.hasAudio} hasVideo: ${user.hasAudio}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

      if (user.uid === this.selfShareStreamId) {
        log(`ignore self screen share stream unpublish ${user.uid}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
        return
      }

      this.checkAndRemoveWaitingSubscribe(user, mediaType)

      this.handleRemoteMediaStateChange(user, mediaType)
    })

    this.client.on("user-left", (user: any) => {
      log(`rtc event: user-left stream id: ${user.uid}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

      if (user.uid === this.selfShareStreamId) {// Check this.selfShareUid reset situation
        log(`ignore self screen share stream left, stream id: ${user.uid}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
        return
      }

      this.checkAndRemoveWaitingSubscribe(user)

      this.rootStore.rtcCommLayer.onStreamUserLeave(Number(user.uid))
    })

    this.client.on("volume-indicator", (results: any) => {
      // log(`volume-indicator ~~~~~~~~~~~~~~~~`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      // results.forEach((item, index) => {
      //   log(`${index} ${item.uid} ${item.level}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      // })
      // const index = results.findIndex((item:any) => this.unsubscribeAudioStreamId.has(item.uid))
      // results.splice(index, 1)
      this.rootStore.rtcCommLayer.onVolumesIndicate(results)
    })

    this.client.on("network-quality", this.onLocalNetworkQuality.bind(this))

    this.client.on("token-privilege-will-expire", this.handleTokenExpire.bind(this))

    this.client.on("receive-metadata", this.onRemoteMetadataReceived.bind(this))

    // data stream
    this.client.on("stream-message", this.onRemoteDataStreamReceived.bind(this))
  }

  private onConnectionStateChange(state: ConnectionState, reason?: ConnectionDisconnectedReason) {
    const newState = this.getChannelStateByRtc(state)
    if (newState === RTCConnectState.UNKNOWN) return

    this.playRtcConnectionStatusEffect(newState, this.channelConnState)

    runInAction(() => {
      this.channelConnState = newState

      log(`rtc connection state changed, state: ${RTCConnectState[this.channelConnState]}, reason: ${reason}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

      if (this.channelConnState === RTCConnectState.CONNECTED) {
        this.checkAndResubscribe()
      }

      this.rootStore.rtcCommLayer.onChannelStateChanged(this.channelConnState, reason)
    })
  }

  private handleRemoteMediaStateChange(user: IAgoraRTCRemoteUser, mediaType?: "video" | "audio") {
    log(`handleRemoteMediaStateChange mediaType:${mediaType} videoState :${user.hasVideo && user.videoTrack !== undefined},audioState:${user.hasAudio && user.audioTrack !== undefined},uid:${user.uid}`)
    if (mediaType === "audio") {
      this.rootStore.rtcCommLayer.onChannelStreamAudioState(Number(user.uid), user.hasAudio && user.audioTrack !== undefined, user.audioTrack)

    } else if (mediaType === "video") {

      this.rootStore.rtcCommLayer.onChannelStreamVideoState(Number(user.uid), user.hasVideo && user.videoTrack !== undefined, user.videoTrack)
    }
  }

  public async subscribe(user: IAgoraRTCRemoteUser, mediaType: "video" | "audio"): Promise<void> {

    await this.client.subscribe(user, mediaType)
    // auto play audio
    if (user.audioTrack && mediaType === "audio") {
      user.audioTrack.play()
    }
  }

  private async handleTokenExpire() {
    try {
      log('rtc token will expire', LOG_TYPE.COMM, LOG_MODULE.RTC)

      const token = await this.rootStore.rtcCommLayer.getToken(this.channelName, this.selfStreamId)
      await this.client.renewToken(token)
    } catch (e) {
      logException(`token expire, get token exception`, e, LOG_MODULE.RTC)

      this.rootStore.rtcCommLayer.onChannelDisconnected()
    }
  }

  public async createLocalAudioTrack() {
    log('createLocalAudioTrack', LOG_TYPE.INFO, LOG_MODULE.RTC)

    try {
      const track = await AgoraRTC.createMicrophoneAudioTrack({
        microphoneId: this.getSelectedMicrophoneId()
      })

      track.on("track-ended", () => {
        this.onLocalMicrophoneTrackEnded()
      })

      return track
    } catch (e) {
      logException(`create local audio exception: `, e, LOG_MODULE.RTC)

      this.notification.addAlert(true, "CreateLocalMicError")

      throw e
    }
  }

  private onLocalMicrophoneTrackEnded() {
    log(`local microphone track end`, LOG_TYPE.ERROR, LOG_MODULE.RTC)

    this.closeLocalAudio()

    if (this.isInChannel) {
      this.notification.addAlert(true, "CurrentMicrophoneLost")
    }
  }

  public async createLocalVideoTrack() {
    log('createLocalVideoTrack', LOG_TYPE.INFO, LOG_MODULE.RTC)

    try {
      const track = await AgoraRTC.createCameraVideoTrack({
        encoderConfig: this.rootStore.user.tempVideoProfile,
        cameraId: this.getSelectedCameraId(),
        // https://jira.agoralab.co/browse/APP-3796: vp9 av1 config
        scalabiltyMode: '3SL3TL',
      })

      track.on("track-ended", () => {
        this.onLocalCameraTrackEnded()
      })

      return track
    } catch (e) {
      logException(`create local video exception`, e, LOG_MODULE.RTC)

      this.notification.addAlert(true, "CreateLocalCameraError")

      throw e
    }
  }

  private onLocalCameraTrackEnded() {
    log(`local camera track end`, LOG_TYPE.ERROR, LOG_MODULE.RTC)

    this.disableLocalVideo()

    if (this.isInChannel) {
      this.notification.addAlert(true, "CurrentCameraLost")
    }

    this.setLocalCameraEndState()
  }

  public async publishLocalTrack(track: ILocalTrack) {
    log(`publish local track ${track.trackMediaType} track id: ${track.getTrackId()}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    if (this.client.localTracks.indexOf(track) !== -1) {
      log(`track already published, return`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
      return
    }

    try {
      await this.client.publish(track)
    } catch (e) {
      logException(`publish local track error `, e, LOG_MODULE.RTC)

      if (this.isInChannel) {
        this.notification.addAlert(true, "PublishError")
      }

      throw e
    }
  }

  public async unpublishLocalTrack(track: ILocalTrack) {
    log(`unpublish local track ${track.trackMediaType} track id: ${track.getTrackId()}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    if (this.client.localTracks.indexOf(track) === -1) {
      log(`track no published, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      return
    }

    try {
      await this.client.unpublish(track);
    } catch (e) {
      log(`unpublish local track error ${e.toString()}`, LOG_TYPE.ERROR, LOG_MODULE.RTC)

      throw e
    }
  }

  private async closeLocalTrack(track: ILocalTrack) {
    log(`closeTrack ${track.trackMediaType} track id: ${track.getTrackId()}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    try {
      await this.unpublishLocalTrack(track)
    } catch (e) {
      logException(`unpublish local track error `, e, LOG_MODULE.RTC)
    } finally {
      track.close()
    }

    log(`closeTrack ${track.trackMediaType} finished`, LOG_TYPE.INFO, LOG_MODULE.RTC)
  }

  public async setRemoteStreamType(streamId: number, isHigh: boolean) {
    log(`rtc set remote video type: ${isHigh}`, LOG_TYPE.COMM, LOG_MODULE.RTC)
    try {
      await this.client.setRemoteVideoStreamType(streamId, isHigh ? 0 : 1)
    } catch (error) {
      logException(`setRemoteStreamType ${streamId} ${isHigh ? 'high' : 'low'} exception`, error, LOG_MODULE.RTC)
      throw error
    }
  }

  public setSelfShareStreamId(streamId: number) {
    this.selfShareStreamId = streamId
  }

  /********************* Metadata & Data stream *********************/

  // metadata
  private metadataIntervalID?: number
  private metadataUint8Array: Uint8Array | undefined
  private metadata: Metadata = new Metadata()
  private metadataCtrlSeq: number = 0

  private prepareMetadata(name: string) {
    const user = new protobuf.User({
      name
    })

    this.metadata.setAllocatedUser(user)
    log(`rtc set user info, name: ${user.name}, parent stream id: ${user.parentStreamId}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

    this.metadata.clearBiz()
    this.metadata.clearCtrl()
    log('rtc clear control info', LOG_TYPE.COMM, LOG_MODULE.RTC)

    this.setMetaData(this.metadata)
  }

  private setMetaData(data: any) {
    if (!METADATA_ENABLED && !DATA_STREAM_ENABLED) return

    log(`update metadata: ${JSON.stringify(data)}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    this.metadataUint8Array = protobuf.Metadata.encode(data).finish()
  }

  public onReadyToSendMetadata(metadata: Metadata) {
    if (!this.metadataUint8Array) {
      return false
    }
  }

  private startSendMetadata() {
    if (!this.isInChannel || !this.currentVideoState()) return

    if (!this.metadataIntervalID && METADATA_ENABLED) {
      log('startSendMetadata', LOG_TYPE.INFO, LOG_MODULE.RTC)

      this.metadataIntervalID = window.setInterval(() => { this.sendMetadata() }, METADATA_INTERVAL)
    }
  }

  private stopSendMetadata() {
    if (this.metadataIntervalID) {
      log('stopSendMetadata', LOG_TYPE.INFO, LOG_MODULE.RTC)

      clearInterval(this.metadataIntervalID)
      this.metadataIntervalID = undefined
    }
  }

  public async sendMetadata() {
    if (!this.metadataUint8Array) return

    if (!this.client || this.channelConnState !== RTCConnectState.CONNECTED || !this.isMetadataOn()) return

    try {
      await (this.client as any).sendMetadata(this.metadataUint8Array)
    } catch (error) {
      logException(`sendMetadata exception`, error, LOG_MODULE.RTC)
    }
  }

  // send and stop data stream
  public async sendDataStream() {
    if (!this.metadataUint8Array) return

    if (!this.client || this.channelConnState !== RTCConnectState.CONNECTED || !DATA_STREAM_ENABLED) return

    try {
      const data = await this.encrypteDataStreamData(this.metadataUint8Array)
      await (this.client as any).sendStreamMessage(data, false)
    } catch (error) {
      logException(`sendDataStream exception`, error, LOG_MODULE.RTC)
    }
  }

  public setMetadataRoomInfo(roomInfo: Biz) {
    const biz = new protobuf.Biz({
      hostUid: roomInfo.hostUid,
      roomAudio: roomInfo.roomAudio,
      roomVideo: roomInfo.roomVideo,
      serverPeerId: roomInfo.rtmServerPeerId,
      timestamp: roomInfo.timestamp
    })

    log(`rtc set room info, room audio: ${biz.roomAudio ? 'true' : 'false'}, room video: ${biz.roomVideo ? 'true' : 'false'},
    host: ${biz.hostUid}, ts: ${biz.timestamp}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

    this.metadata.setAllocatedBiz(biz)

    this.setMetaData(this.metadata)

    this.sendDataStream()
  }

  public setMediaControl(control: Control) {
    const ctrl = new protobuf.Control({
      sequenceId: control.sequenceId,
      targetStreamId: control.targetStreamId,
      requestType: control.requestType,
    })

    this.metadata.setAllocatedCtrl(ctrl)

    log(`rtc set control info, target: ${ctrl.targetStreamId}, type: ${ctrl.requestType}, seq: ${ctrl.sequenceId}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

    this.setMetaData(this.metadata)

    const seq = ++this.metadataCtrlSeq

    setTimeout(() => {
      this.clearMediaControl(seq, MetadataType.metadata)
    }, 2000)

    this.sendDataStream()
  }

  public clearMediaControl(ctrlCount: number, type: MetadataType) {
    if (ctrlCount !== this.metadataCtrlSeq) return

    log(`clearMediaControl type: ${type} ctrlCount: ${ctrlCount}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    this.metadata.clearCtrl()

    this.setMetaData(this.metadata)
  }

  // receive remote metadata and data stream
  private onRemoteMetadataReceived(uid: UID, metadata: Uint8Array) {
    if (!metadata) {
      return
    }

    try {
      // avoid log overflow
      // log(`rtc event: receive-metadata stream id: ${uid}, metadata: ${metadata}`, LOG_TYPE.COMM, LOG_MODULE.RTC)
      if (metadata[0] !== '{'.charCodeAt(0)) {
        const data = protobuf.Metadata.decode(metadata)

        // log(`data metadata: ${JSON.stringify(data)}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

        this.rootStore.rtcCommLayer.onStreamMetadata(Number(uid), data.user.name, data.user.parentStreamId, data.biz, data.ctrl, data.user.status)
      } else {
        const data = JSON.parse(new TextDecoder("utf-8").decode(metadata))
        this.rootStore.rtcCommLayer.onStreamMetadata(Number(uid), data.usr.unm, data.usr.pid, undefined, undefined, data.usr.status)
      }
    } catch (e) {
      // avoid remote metadata parse error, cause log overflow
      // logException(`onRemoteMetadataReceived from: ${uid}`, e, LOG_MODULE.RTC)
    }
  }

  private onRemoteDataStreamReceived(uid: UID, metadata: Uint8Array) {
    if (!metadata) {
      return
    }

    this.handleRemoteDataStreamData(uid, metadata)
  }

  private async handleRemoteDataStreamData(uid: UID, metadata: Uint8Array) {
    try {
      const decryptedData = await this.decryptedDataStreamData(metadata)

      if (decryptedData[0] !== '{'.charCodeAt(0)) {
        const data = protobuf.Metadata.decode(decryptedData)

        // log(`protobuf data stream: ${JSON.stringify(data)}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

        this.rootStore.rtcCommLayer.onStreamMetadata(Number(uid), data.user.name, data.user.parentStreamId, data.biz, data.ctrl, data.user.status)
      } else {
        log(`json data stream: ${decryptedData}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

        const data = JSON.parse(new TextDecoder("utf-8").decode(decryptedData))
        this.rootStore.rtcCommLayer.onStreamMetadata(Number(uid), data.usr.unm, data.usr.pid, undefined, undefined, data.usr.status)
      }
    } catch (error) {
      logException(`handleRemoteDataStreamData failure: `, error, LOG_MODULE.RTC)
    }
  }

  private async decryptedDataStreamData(data: Uint8Array) {
    if (!this.isEncrypted) {
      return data
    }

    if (!this.channelCryptoKey) {
      log(`decrypteDataStreamData channelCryptoKey null`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
      return data
    }

    return Aes128GcmDecrypt(data, this.channelCryptoKey)
  }

  private async encrypteDataStreamData(data: Uint8Array) {
    if (!this.isEncrypted) {
      return data
    }

    if (!this.channelCryptoKey) {
      log(`encrypteDataStreamData channelCryptoKey null`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
      return data
    }

    return Aes128GcmEncrypt(data, this.channelCryptoKey)
  }

  private onLocalNetworkQuality(res: NetworkQuality) {
    logIf(showQualityLog(), `rtc network-quality up: ${res.uplinkNetworkQuality} down: ${res.downlinkNetworkQuality}`, LOG_TYPE.INFO, LOG_MODULE.RTC)// remote log todo 4.3.0

    this.rootStore.rtcCommLayer.onEvalNetworkQuality(0, res.uplinkNetworkQuality, res.downlinkNetworkQuality)
  }

  public isMetadataOn() {
    return METADATA_ENABLED && this.currentVideoState() === LOCAL_VIDEO_STATE.ENABLE
  }

  public isDataStreamOn() {
    return DATA_STREAM_ENABLED
  }

  /************************ Audio/Video Devices ************************/
  // public isDeviceListInited = false
  @observable
  public isMicrophoneListInited = false
  @observable
  public isCameraListInited = false
  @observable
  public cameraDeviceList: MediaDeviceInfo[] = []
  @observable
  public micDeviceList: MediaDeviceInfo[] = []

  @action
  public async refreshMicrophoneDevices() {
    log(`refresh microphone devices~~~~~~~~~~~~~~`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    try {
      const deviceList = await AgoraRTC.getMicrophones()
      deviceList.forEach((item, index) => {
        log(`${index} ${item.kind} ${item.label} ${item.deviceId}`, LOG_TYPE.COMM, LOG_MODULE.RTC)
      })

      this.micDeviceList = deviceList
      this.isMicrophoneListInited = true
    } catch (error) {
      this.notification.addAlert(true, "RefreshMicrophoneException")
    }
  }

  @action
  public async refreshCameraDevices() {
    log(`refresh camera devices~~~~~~~~~~~~~~`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    try {
      const deviceList = await AgoraRTC.getCameras()
      deviceList.forEach((item, index) => {
        log(`${index} ${item.kind} ${item.label} ${item.deviceId}`, LOG_TYPE.COMM, LOG_MODULE.RTC)
      })

      this.cameraDeviceList = deviceList
      this.isCameraListInited = true
    } catch (error) {
      this.notification.addAlert(true, "RefreshCameraException")
    }
  }

  public refreshAudioDevices() {
    log(`refresh Audio devices`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    this.refreshMicrophoneDevices()
    this.refreshMicrophoneTrackLabel()
  }

  public refreshVideoDevices() {
    log(`refresh Video devices`, LOG_TYPE.INFO, LOG_MODULE.RTC)
    this.refreshCameraDevices()
    this.refreshCameraTrackLabel()
  }

  // @action
  // public async refreshAVDevices() {
  //   const deviceList = await AgoraRTC.getDevices()

  //   log(`refresh devices~~~~~~~~~~~~~~`, LOG_TYPE.INFO, LOG_MODULE.RTC)
  //   deviceList.forEach((item, index) => {
  //     log(`${index} ${item.kind} ${item.label} ${item.deviceId}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
  //   })

  //   this.cameraDeviceList = deviceList.filter(d => d.kind === "videoinput")
  //   this.micDeviceList = deviceList.filter(d => d.kind === "audioinput")

  //   this.isDeviceListInited = true
  // }

  @action
  private handleCameraChanged(info: DeviceInfo) {
    this.notification.onNoticeCameraChanged(info)

    // with no camera list, any modification may cause unexpected UI effect
    if (!this.isCameraListInited) return

    // not use refreshCameraDevices, because sometimes it will trigger camera light on even not in room, (seen once but not everytime)
    const index = this.cameraDeviceList.findIndex((device: any) => {
      return device.deviceId === info.device.deviceId
    })

    if (info.state === 'INACTIVE') {
      if (index === -1) return

      this.cameraDeviceList.splice(index, 1)
    } else {
      if (index !== -1) return

      this.cameraDeviceList.push(info.device)
    }
  }

  @action
  private handleMicrophoneChanged(info: DeviceInfo) {
    this.notification.onNoticeMicrophoneChanged(info);

    if (!this.isMicrophoneListInited) return

    const index = this.micDeviceList.findIndex((device: any) => {
      return device.deviceId === info.device.deviceId
    })

    if (info.state === 'INACTIVE') {
      if (index === -1) return

      this.micDeviceList.splice(index, 1);
    } else {
      if (index !== -1) return

      this.micDeviceList.push(info.device)
    }
  }

  public async setCameraId(cameraId: string) {
    if (!this.videoTrack) return false

    const device = this.cameraDeviceList.find((item: any) => {
      return item.deviceId === cameraId
    })

    // if not filter, video may flash when open RoomSetting
    if (this.videoTrack.getTrackLabel() === device?.label) {
      log(`set camera id the same as default`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      return true
    }

    try {
      await (this.videoTrack as ICameraVideoTrack).setDevice(cameraId)

      log(`set camera id: ${cameraId}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

      this.refreshCameraTrackLabel()

      this.notification.onNoticeSetCameraId(cameraId)

      return true
    } catch (error) {
      logException(`set camera id exception `, error, LOG_MODULE.RTC)

      this.notification.addAlert(true, 'SetCameraError')
    }
  }

  public async setMicrophoneId(microphoneId: string) {
    if (!this.audioTrack) return

    try {
      await (this.audioTrack as IMicrophoneAudioTrack).setDevice(microphoneId)

      log(`set microphone id: ${microphoneId}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

      this.refreshMicrophoneTrackLabel()
    } catch (error) {
      logException(`set microphone id exception `, error, LOG_MODULE.RTC)

      this.notification.addAlert(true, 'SetMicrophoneError')
    }
  }

  public getLocalAudioState() {
    return this.expectedAudio
  }

  public initLocalAudio() {
    log(`initLocalAudio`, LOG_TYPE.INFO, LOG_MODULE.RTC)
    if (this.expectedAudio >= LOCAL_AUDIO_STATE.DISABLE) {
      log(`initLocalAudio current expect ${LOCAL_AUDIO_STATE[this.expectedAudio]}, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      this.rootStore.rtcCommLayer.onStreamMineAudioState(this.expectedAudio)
      return
    }

    this.expectedAudio = LOCAL_AUDIO_STATE.DISABLE

    // feedback
    this.rootStore.rtcCommLayer.onStreamMineAudioState(this.expectedAudio)

    this.doInitLocalAudio()
  }

  // 先创建 AudioTrack，避免会议中创建，远端爆音，各端行为音频行为都一致
  public async doInitLocalAudio() {
    log(`doInitLocalAudio`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    if (this.audioTrackOperating) {
      log(`doInitLocalAudio operating, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      return
    }

    this.audioTrackOperating = true

    try {
      if (!this.audioTrack) {
        this.audioTrack = await this.createLocalAudioTrack()
      }
    } catch (error) {
      if (this.audioTrack) {
        await this.closeLocalTrack(this.audioTrack)
        this.audioTrack = undefined
      }
      // exception happen, reset expectedAudio, avoid endless loop
      this.expectedAudio = LOCAL_AUDIO_STATE.CLOSED
    } finally {
      log(`doInitLocalAudio over`, LOG_TYPE.INFO, LOG_MODULE.RTC)

      this.refreshMicrophoneTrackLabel()

      this.audioTrackOperating = false

      this.checkAudioState()
    }
  }

  public enableLocalAudio() {
    log('rtc enable local audio', LOG_TYPE.COMM, LOG_MODULE.RTC)

    if (this.expectedAudio === LOCAL_AUDIO_STATE.ENABLE) {
      log(`enableLocalAudio current expect ${LOCAL_AUDIO_STATE[this.expectedAudio]}, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)

      this.rootStore.rtcCommLayer.onStreamMineAudioState(this.expectedAudio)
      return
    }

    this.expectedAudio = LOCAL_AUDIO_STATE.ENABLE

    // Feedback immediately
    this.rootStore.rtcCommLayer.onStreamMineAudioState(this.expectedAudio)

    this.doEnableLocalAudio()
  }

  private async doEnableLocalAudio() {
    log(`doEnableLocalAudio`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    if (this.audioTrackOperating) {
      log(`doEnableLocalAudio operating, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      return
    }

    this.audioTrackOperating = true

    try {
      if (!this.audioTrack) {
        this.audioTrack = await this.createLocalAudioTrack()
      }

      // user may already leave channel
      if (this.isInChannel) {
        await this.publishLocalTrack(this.audioTrack)
      } else {
        log(`doEnableLocalAudio check not in channel`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
        this.expectedAudio = LOCAL_AUDIO_STATE.DISABLE
      }

      const success = this.audioTrack !== undefined

      if (success) {
        this.volumeTimer = setInterval(() => {
          this.volumeLevel = this.audioTrack?.getVolumeLevel() || 0
        }, 200)
      }

      log(`rtc user audio local changed, stream id: ${this.selfStreamId}, enable`, LOG_TYPE.COMM, LOG_MODULE.RTC)
    } catch (error) {
      if (this.audioTrack) {
        await this.closeLocalTrack(this.audioTrack)
        this.audioTrack = undefined
      }

      // exception happen, reset expectedAudio, avoid endless loop
      this.expectedAudio = LOCAL_AUDIO_STATE.CLOSED
    } finally {
      log(`doEnableLocalAudio over`, LOG_TYPE.INFO, LOG_MODULE.RTC)

      this.refreshMicrophoneTrackLabel()

      this.audioTrackOperating = false

      this.checkAudioState()
    }
  }

  private checkAudioState() {
    const current = this.currentAudioState()
    if (current !== this.expectedAudio) {
      log(`checkAudioState current: ${LOCAL_AUDIO_STATE[current]} expect: ${LOCAL_AUDIO_STATE[this.expectedAudio]}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

      switch (this.expectedAudio) {
        case LOCAL_AUDIO_STATE.CLOSED:
          this.doCloseLocalAudio()
          break;
        case LOCAL_AUDIO_STATE.DISABLE:
          this.doDisableLocalAudio()
          break;
        case LOCAL_AUDIO_STATE.ENABLE:
          this.doEnableLocalAudio()
          break;
      }
    } else {
      this.rootStore.rtcCommLayer.onStreamMineAudioState(this.expectedAudio)
    }
  }

  public disableLocalAudio() {
    log('rtc disable local audio', LOG_TYPE.COMM, LOG_MODULE.RTC)

    if (this.expectedAudio <= LOCAL_AUDIO_STATE.DISABLE) {
      log(`disableLocalAudio current expect ${LOCAL_AUDIO_STATE[this.expectedAudio]}, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)

      this.rootStore.rtcCommLayer.onStreamMineAudioState(this.expectedAudio)
      return
    }

    this.expectedAudio = LOCAL_AUDIO_STATE.DISABLE

    // feedback immediately
    this.rootStore.rtcCommLayer.onStreamMineAudioState(this.expectedAudio)

    this.doDisableLocalAudio()
  }

  private async doDisableLocalAudio() {
    log(`doDisableLocalAudio`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    if (this.audioTrackOperating) {
      log(`doDisableLocalAudio operating, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      return
    }

    this.audioTrackOperating = true

    try {
      if (this.audioTrack) {
        await this.unpublishLocalTrack(this.audioTrack)
        clearInterval(this.volumeTimer)
        this.volumeLevel = 0
      }
    } catch (error) {
      if (this.audioTrack) {
        await this.closeLocalTrack(this.audioTrack)
        this.audioTrack = undefined
      }

      // exception happen, reset expectedAudio, avoid endless loop
      this.expectedAudio = LOCAL_AUDIO_STATE.CLOSED
      clearInterval(this.volumeTimer)
      this.volumeLevel = 0

      log(`rtc user audio local changed, stream id: ${this.selfStreamId}, disable`, LOG_TYPE.COMM, LOG_MODULE.RTC)
    } finally {
      log(`doDisableLocalAudio over`, LOG_TYPE.INFO, LOG_MODULE.RTC)

      this.refreshMicrophoneTrackLabel()

      this.audioTrackOperating = false

      this.checkAudioState()
    }
  }

  private closeLocalAudio() {
    log(`closeLocalAudio`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    if (this.expectedAudio === LOCAL_AUDIO_STATE.CLOSED) {
      log(`closeLocalAudio current expect ${LOCAL_AUDIO_STATE[this.expectedAudio]}, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      return
    }

    this.expectedAudio = LOCAL_AUDIO_STATE.CLOSED

    this.doCloseLocalAudio()
  }

  private async doCloseLocalAudio() {
    log(`doCloseLocalAudio`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    if (this.audioTrackOperating) {
      log(`doCloseLocalAudio operating, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      return
    }

    this.audioTrackOperating = true

    try {
      if (this.audioTrack) {
        await this.closeLocalTrack(this.audioTrack)

        this.audioTrack = undefined
      }
    } catch (error) {
      this.audioTrack = undefined

      this.expectedAudio = LOCAL_AUDIO_STATE.CLOSED
    } finally {
      log(`doCloseLocalAudio over`, LOG_TYPE.INFO, LOG_MODULE.RTC)

      this.refreshMicrophoneTrackLabel()

      this.audioTrackOperating = false

      this.checkAudioState()
    }
  }

  private currentAudioState() {
    if (!this.audioTrack) {
      return LOCAL_AUDIO_STATE.CLOSED
    }
    if (this.client.localTracks.indexOf(this.audioTrack) !== -1) {
      return LOCAL_AUDIO_STATE.ENABLE
    }
    return LOCAL_AUDIO_STATE.DISABLE
  }

  public async startTestMicrophone() {
    log('rtc start test audio', LOG_TYPE.COMM, LOG_MODULE.RTC)

    this.expectedAudio = LOCAL_AUDIO_STATE.DISABLE

    this.rootStore.rtcCommLayer.onStreamMineAudioState(this.expectedAudio)

    await this.doStartTestMicrophone()
  }

  private async doStartTestMicrophone() {
    log(`startTestMicrophone`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    await this.doInitLocalAudio()

    const success = this.audioTrack !== undefined

    if (success) {
      this.volumeTimer = setInterval(() => {
        this.volumeLevel = this.audioTrack?.getVolumeLevel() || 0
      }, 200)
    }

    return success
  }

  public stopTestMicrophone() {
    log('stopTestMicrophone', LOG_TYPE.INFO, LOG_MODULE.RTC)

    if (this.expectedAudio !== LOCAL_AUDIO_STATE.CLOSED) {
      clearInterval(this.volumeTimer)

      this.volumeLevel = 0

      this.closeLocalAudio()
    }
  }

  @action
  public startPreview() {
    log(`rtc start preview`, LOG_TYPE.COMM, LOG_MODULE.RTC)

    if (this.expectedVideo >= LOCAL_VIDEO_STATE.PREVIEW) {
      log(`startPreview current expect ${LOCAL_VIDEO_STATE[this.expectedVideo]}, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      this.rootStore.rtcCommLayer.onStreamMineVideoState(this.expectedVideo)
      return
    }

    this.expectedVideo = LOCAL_VIDEO_STATE.PREVIEW

    // feedback immediately
    this.rootStore.rtcCommLayer.onStreamMineVideoState(this.expectedVideo)

    this.doStartPreview()
  }

  private async doStartPreview() {
    log(`doStartPreview`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    if (this.videoTrackOperating) {
      log(`doStartPreview operating, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      return
    }

    this.videoTrackOperating = true

    try {
      if (!this.videoTrack) {
        this.videoTrack = await this.createLocalVideoTrack()
      }

    } catch (error) {
      if (this.videoTrack) {
        await this.closeLocalTrack(this.videoTrack)
        this.videoTrack = undefined
      }

      this.expectedVideo = LOCAL_VIDEO_STATE.DISABLE
    } finally {
      log(`doStartPreview over`, LOG_TYPE.INFO, LOG_MODULE.RTC)

      this.refreshCameraTrackLabel()

      this.videoTrackOperating = false

      this.checkVideoState()
    }
  }

  private checkVideoState() {
    const current = this.currentVideoState()
    if (current !== this.expectedVideo) {
      log(`checkVideoState current: ${LOCAL_VIDEO_STATE[current]} expect: ${LOCAL_VIDEO_STATE[this.expectedVideo]}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

      switch (this.expectedVideo) {
        case LOCAL_VIDEO_STATE.DISABLE:
          this.doDisableLocalVideo()
          break;
        case LOCAL_VIDEO_STATE.PREVIEW:
          this.doStartPreview()
          break;
        case LOCAL_VIDEO_STATE.ENABLE:
          this.doEnableLocalVideo()
          break;
      }
    } else {
      if (this.expectedVideo === LOCAL_VIDEO_STATE.PREVIEW || this.expectedVideo === LOCAL_VIDEO_STATE.ENABLE) {
        try {
          // in case this canvas is not valid
          if (this.localVideoCanvas) {
            this.videoTrack?.play(this.localVideoCanvas, { fit: "cover" })
          }
        } catch (error) {
          logException('play local video track error', error, LOG_MODULE.RTC)
        }
      }

      this.rootStore.rtcCommLayer.onStreamMineVideoState(this.expectedVideo)
    }
  }

  public enableLocalVideo() {
    log(`rtc enable local video`, LOG_TYPE.COMM, LOG_MODULE.RTC)

    if (this.expectedVideo === LOCAL_VIDEO_STATE.ENABLE) {
      log(`enableLocalVideo current expect ${LOCAL_VIDEO_STATE[this.expectedVideo]}, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      this.rootStore.rtcCommLayer.onStreamMineVideoState(this.expectedVideo)
      return
    }

    this.expectedVideo = LOCAL_VIDEO_STATE.ENABLE

    this.rootStore.rtcCommLayer.onStreamMineVideoState(this.expectedVideo)

    this.doEnableLocalVideo()
  }

  private async doEnableLocalVideo() {
    log(`doEnableLocalVideo`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    if (this.videoTrackOperating) {
      log(`doEnableLocalVideo operating, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      return
    }

    this.videoTrackOperating = true

    try {
      if (!this.videoTrack) {
        this.videoTrack = await this.createLocalVideoTrack()
      }

      // user may already leave channel
      if (this.isInChannel) {
        await this.publishLocalTrack(this.videoTrack)

        this.startSendMetadata()
      } else {
        log(`doEnableLocalVideo check not in channel`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
        this.expectedVideo = LOCAL_VIDEO_STATE.DISABLE
      }

      log(`rtc user video local changed, stream id: ${this.selfStreamId}, enable`, LOG_TYPE.COMM, LOG_MODULE.RTC)
    } catch (error) {
      if (this.videoTrack) {
        await this.closeLocalTrack(this.videoTrack)
        this.videoTrack = undefined
      }

      this.expectedVideo = LOCAL_VIDEO_STATE.DISABLE
    } finally {
      log(`doEnableLocalVideo over`, LOG_TYPE.INFO, LOG_MODULE.RTC)

      this.refreshCameraTrackLabel()

      this.videoTrackOperating = false

      this.checkVideoState()
    }
  }

  public disableLocalVideo() {
    log(`rtc disable local video`, LOG_TYPE.COMM, LOG_MODULE.RTC)

    if (this.expectedVideo === LOCAL_VIDEO_STATE.DISABLE) {
      log(`disableLocalVideo current expect ${LOCAL_VIDEO_STATE[this.expectedVideo]}, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      this.rootStore.rtcCommLayer.onStreamMineVideoState(this.expectedVideo)
      return
    }

    this.expectedVideo = LOCAL_VIDEO_STATE.DISABLE

    this.rootStore.rtcCommLayer.onStreamMineVideoState(this.expectedVideo)

    this.doDisableLocalVideo()
  }

  private async doDisableLocalVideo() {
    log(`doDisableLocalVideo`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    if (this.videoTrackOperating) {
      log(`doDisableLocalVideo operating, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      return
    }

    this.videoTrackOperating = true

    try {
      if (this.videoTrack) {
        if (this.videoTrack.isPlaying) {
          this.videoTrack.stop()
        }

        await this.closeLocalTrack(this.videoTrack)
        this.videoTrack = undefined
      }

      this.stopSendMetadata()

      log(`rtc user video local changed, stream id: ${this.selfStreamId}, disable`, LOG_TYPE.COMM, LOG_MODULE.RTC)
    } catch (error) {
      // do nothing
    } finally {
      log(`doDisableLocalVideo over`, LOG_TYPE.INFO, LOG_MODULE.RTC)

      this.refreshCameraTrackLabel()

      this.videoTrackOperating = false

      this.checkVideoState()
    }
  }

  @action
  public async stopPreview() {
    log(`rtc stop preview`, LOG_TYPE.COMM, LOG_MODULE.RTC)

    if (this.expectedVideo === LOCAL_VIDEO_STATE.DISABLE) {
      log(`stopPreview current expect ${LOCAL_VIDEO_STATE[this.expectedVideo]}, return`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      this.rootStore.rtcCommLayer.onStreamMineVideoState(this.expectedVideo)
      return
    }

    this.expectedVideo = LOCAL_VIDEO_STATE.DISABLE

    this.rootStore.rtcCommLayer.onStreamMineVideoState(this.expectedVideo)

    this.doDisableLocalVideo()
  }

  private currentVideoState() {
    if (!this.videoTrack) {
      return LOCAL_VIDEO_STATE.DISABLE
    }
    if (this.client.localTracks.indexOf(this.videoTrack) !== -1) {
      return LOCAL_VIDEO_STATE.ENABLE
    }
    return LOCAL_VIDEO_STATE.PREVIEW
  }

  public startPlayLocalVideo(canvas: HTMLElement) {
    this.localVideoCanvas = canvas

    this.videoTrack?.play(canvas, { fit: "cover" })
  }

  public stopPlayLocalVideo() {
    this.localVideoCanvas = undefined

    this.videoTrack?.stop()
  }

  public async playSoundEffect(effect: string) {
    let track: IBufferSourceAudioTrack = this.soundEffectsMap[effect]
    if (track === undefined) {
      track = await this.cachePlaySoundEffect(effect)
    }

    track.startProcessAudioBuffer()
    track.play()
  }

  public async cachePlaySoundEffect(effect: string) {
    const track = await AgoraRTC.createBufferSourceAudioTrack({
      source: effect,
      cacheOnlineFile: true,
    })
    this.soundEffectsMap.set(effect, track)
    return track
  }

  private playRtcConnectionStatusEffect(newState: number, previousState: number) {
    if (newState === RTCConnectState.RECONNECTING && previousState === RTCConnectState.CONNECTED) {
      this.playSoundEffect(rtcDisconnected)
    }
    if (newState === RTCConnectState.CONNECTED && previousState === RTCConnectState.RECONNECTING) {
      this.playSoundEffect(rtcReconnected)
    }
  }

  public async setVideoResolution(resolution: VideoEncoderConfigurationPreset) {
    log(`rtc set video encode config, resolution: ${resolution}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

    if (this.videoTrack) {
      try {
        await (this.videoTrack as ICameraVideoTrack).setEncoderConfiguration(resolution);
      } catch (error) {
        logException(`setVideoResolution exception `, error, LOG_MODULE.RTC)
      }
    }
  }

  private async checkAndResubscribe() {
    if (this.channelConnState !== RTCConnectState.CONNECTED) {
      log(`channel not connected ${RTCConnectState[this.channelConnState]}, resubscrbe abort`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
      return
    }

    for (let i = this.waitingSubscribeList.length - 1; i >= 0; i--) {
      const item = this.waitingSubscribeList[i]

      if (!this.checkRemoteUserMediaValid(item.user, item.mediaType)) {
        this.waitingSubscribeList.splice(i, 1)
        continue
      }

      log(`check and resubscribe user: ${item.user.uid}, type: ${item.mediaType}`)

      try {
        const unsubscribeStreamId = item.mediaType === 'audio' ? this.unsubscribeAudioStreamId : this.unsubscribeVideoStreamId
        if (!unsubscribeStreamId.has(Number(item.user.uid))) {
          await this.subscribe(item.user, item.mediaType)
        }

        this.waitingSubscribeList.splice(i, 1)

        this.handleRemoteMediaStateChange(item.user, item.mediaType)
      } catch (e) {
        logException(`re-subscribe remote track exception ${item.user.uid} ${item.mediaType}`, e, LOG_MODULE.RTC)

        this.waitingSubscribeList.splice(i, 1)// 已经重试了一次了，还是不行，不再做重试，因为可能以后一直失败，一直toast提示。

        // Has no way to do anymore, just toast
        this.notification.addAlert(true, "SubscribeRemoteFailure")
      }
    }

    if (this.waitingSubscribeList.length === 0) {
      this.stopResubscribeTimer()
    }
  }

  private addWaitingSubscribe(user: IAgoraRTCRemoteUser, mediaType: "video" | "audio") {
    this.waitingSubscribeList.push(new WaitingSubscribe(user, mediaType))

    this.startResubscribeTimer()
  }

  private checkAndRemoveWaitingSubscribe(user: IAgoraRTCRemoteUser, mediaType?: "video" | "audio") {
    do {
      const index = this.waitingSubscribeList.findIndex((waiting: any) => waiting.user.uid === user.uid)
      if (index < 0) break

      const item = this.waitingSubscribeList[index]

      if (mediaType !== undefined && mediaType !== item.mediaType) break

      this.waitingSubscribeList.splice(index, 1)

      if (this.waitingSubscribeList.length === 0) {
        this.stopResubscribeTimer()
      }
    } while (false);
  }

  private checkRemoteUserMediaValid(user: IAgoraRTCRemoteUser, mediaType: "video" | "audio") {
    return (mediaType === "audio" && user.hasAudio) || (mediaType === "video" && user.hasVideo)
  }

  private clearWaitingSubscribeList() {
    this.waitingSubscribeList = []

    this.stopResubscribeTimer()
  }

  private startResubscribeTimer() {
    if (!this.resubscribeTimer) {
      this.resubscribeTimer = window.setInterval(() => { this.checkAndResubscribe() }, RESUBSCRIBE_INTERVAL)
    }
  }

  private stopResubscribeTimer() {
    if (this.resubscribeTimer) {
      clearInterval(this.resubscribeTimer)
      this.resubscribeTimer = undefined
    }
  }

  @observable
  public currentMicrophoneTrackLabel?: string
  @observable
  public currentCameraTrackLabel?: string
  @observable
  public localCameraTrackEndCount = 0
  @action
  public refreshMicrophoneTrackLabel() {
    this.currentMicrophoneTrackLabel = this.audioTrack?.getTrackLabel()

    log(`refreshMicrophoneTrackLabel ${this.currentMicrophoneTrackLabel}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
  }

  @action
  public refreshCameraTrackLabel() {
    this.currentCameraTrackLabel = this.videoTrack?.getTrackLabel()

    log(`refreshCameraTrackLabel ${this.currentCameraTrackLabel}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
  }

  public setLocalCameraEndState() {
    setTimeout(() => {
      runInAction(() => {
        this.localCameraTrackEndCount++
      })
    }, 200)// if camera end, can not create new track immediately, wait a moment
  }

  public getSelectedCameraId() {
    const cameraId = this.rootStore.user.selectedCameraId
    if (this.isCameraListInited) {
      const current = this.cameraDeviceList.find((item: any) => {
        return item.deviceId === cameraId
      })

      if (!current) {
        return ''
      }
    }

    return cameraId
  }

  public getSelectedMicrophoneId() {
    const microphoneId = this.rootStore.user.selectedMicrophoneId
    if (this.isMicrophoneListInited) {
      const current = this.micDeviceList.find((item: any) => {
        return item.deviceId === microphoneId
      })

      if (!current) {
        return ''
      }
    }

    return microphoneId
  }

  public getCallId() {
    return (this.client as any)._sessionId
  }

  @computed
  public get isInChannel() {
    return this.channelConnState !== RTCConnectState.DISCONNECTED && this.channelConnState !== RTCConnectState.DISCONNECTING
  }

  private getChannelStateByRtc(state: ConnectionState) {
    // "DISCONNECTED" | "CONNECTING" | "RECONNECTING" | "CONNECTED" | "DISCONNECTING"
    switch (state) {
      case 'DISCONNECTED':
        return RTCConnectState.DISCONNECTED
      case 'CONNECTING':
        return RTCConnectState.CONNECTING
      case 'RECONNECTING':
        return RTCConnectState.RECONNECTING
      case 'CONNECTED':
        return RTCConnectState.CONNECTED
      case 'DISCONNECTING':
        return RTCConnectState.DISCONNECTING
      default:
        log(`unknown rtc state: ${state}`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
        return RTCConnectState.UNKNOWN
    }
  }

  @action
  public async subscribeRemoteAudio(streamId: number) {
    log(`subscribeRemote Audio streamId:${streamId}`)
    const user = this.client.remoteUsers.find((item: IAgoraRTCRemoteUser) => item.uid === streamId)
    this.unsubscribeAudioStreamId.delete(streamId)
    if (!user) {
      return
    }
    try {
      await this.subscribe(user, 'audio')
      this.handleRemoteMediaStateChange(user, 'audio')
    } catch (e) {
      logException(`subscribe remote track exception ${user.uid} `, e, LOG_MODULE.RTC)
      if (this.checkRemoteUserMediaValid(user, 'audio')) {
        this.addWaitingSubscribe(user, 'audio')
      }
      return
    }
  }

  @action
  public async unsubscribeRemoteAudio(streamId: number) {
    log(`unsubscribeRemote Audio streamId:${streamId}`)
    const user = this.client.remoteUsers.find((item: IAgoraRTCRemoteUser) => item.uid === streamId)
    this.unsubscribeAudioStreamId.add(streamId)
    if (!user) {
      return
    }
    await this.client.unsubscribe(user, 'audio')
    this.handleRemoteMediaStateChange(user, 'audio')
  }

  @action
  public async subscribeRemoteVideo(streamId: number) {
    const user = this.client.remoteUsers.find((item: IAgoraRTCRemoteUser) => item.uid === streamId)
    log(`subscribeRemote Video streamId:${streamId} user:${user}`)
    this.unsubscribeVideoStreamId.delete(streamId)
    if (!user) {
      return
    }
    try {
      await this.subscribe(user, 'video')
      this.handleRemoteMediaStateChange(user, 'video')
    } catch (e) {
      logException(`subscribe remote track exception ${user.uid} `, e, LOG_MODULE.RTC)
      if (this.checkRemoteUserMediaValid(user, 'video')) {
        this.addWaitingSubscribe(user, 'video')
      }
      return
    }
  }

  @action
  public async unsubscribeRemoteVideo(streamId: number) {
    const user = this.client.remoteUsers.find((item: IAgoraRTCRemoteUser) => item.uid === streamId)
    log(`unsubscribeRemote Video streamId:${streamId}`)
    this.unsubscribeVideoStreamId.add(streamId)
    if (!user) {
      return
    }
    await this.client.unsubscribe(user, 'video')
    this.handleRemoteMediaStateChange(user, 'video')
  }
}
