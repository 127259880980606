import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { color, colorAgora, colorCommon, colorDefault } from "../../style/color";

const useStyle = makeStyles((theme: Theme) => {
  const { customColor: { commonModelBackground, qualityFeedBackLine } } = theme

  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      borderRadius: '0.2rem',
    },
    box: {
      width: '20rem',
      // height: '39.5rem',
      backgroundColor: commonModelBackground,
      boxShadow: '0rem 0.35rem 1.65rem 0rem rgba(38,40,61,0.12)',
      borderRadius: '0.2rem',
      outline: 0,
      marginRight: '0.7rem',
    },
    // boxEn: {
    //   width: '24rem',
    // },
    titleLine: {
      backgroundColor: commonModelBackground,
      height: '3.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '2px solid',
      borderBottomColor: qualityFeedBackLine,
      paddingLeft: '1.25rem',
      paddingRight: '0.6rem',
      borderRadius: '0.2rem',
    },
    modalTitle: {
      color: color.colorPrimary,
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    icon: {
      width: '1.2rem',
      height: '1.2rem',
    },
    audioRateModel: {
      padding: '1rem 0.4rem 0.1rem 0.4rem',
      borderBottom: '2px solid',
      borderBottomColor: qualityFeedBackLine
    },
    videoRateModel: {
      padding: '1rem 0.4rem 0.1rem 0.4rem',
    },
    modelTitleLine: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '100%',
      marginLeft: '0.5rem',
    },
    modelTitleText: {
      width: '5rem',
      height: '1.2rem',
      fontSize: '0.75rem',
      paddingTop: '0.1rem',
      paddingLeft: '0.4rem',
    },
    rateStar: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '5rem',
      paddingTop: '0.5rem',
    },
    rateText: {
      fontSize: '0.7rem',
    },
    labelModal: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: '2.1rem',
      paddingRight: '1.4rem',
      paddingBottom: '1rem',
    },
    // labelModalEn: {
    //   paddingLeft: '4.1rem',
    //   paddingRight: '4rem',
    // },
    labelButton: {
      height: '1.5rem',
      fontSize: '0.6rem',
      marginRight: '0.6rem',
      marginTop: '0.6rem',
      color: color.colorBorderGrey,
    },
    // labelButtonEn: {
    //   marginRight: '0',
    // },
    feedbackTextareaModal: {
      display: 'flex',
      paddingLeft: '2.11rem',
      paddingRight: '2rem',
      paddingTop: '1rem',
      position: 'relative'
    },
    // feedbackTextareaModalEn: {
    //   paddingRight: '1.4rem',
    // },
    feedbackTextareaContent: {
      display: 'block',
      backgroundColor: '#f9f9f9',
      resize: "none",
      outline: "none",
      border: "none",
      width: '21.5rem',
      height: '4rem',
      marginBottom: '1.2rem',
      marginTop: '-1rem',
      fontSize: '0.65rem',
      borderRadius: '0.2rem',
      '&::placeholder': {
        color: color.colorBorderGrey,
      }
    },
    videoTextareaContent: {
      marginBottom: '0.3rem',
    },
    feedbackTextNumber: {
      position: 'absolute',
      fontSize: '0.65rem',
      color: '#909399',
      right: '2.1rem',
      bottom: '1rem',
    },
    // feedbackTextNumberEn: {
    //   right: '1.4rem',
    // },
    videoFeedbackTextNumber: {
      bottom: '0.1rem',
    },
    fullText: {
      color: 'red',
    },
    commentButtonModal: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '1.5rem',
    },
    commentButton: {
      width: '15rem',
      height: '1.8rem',
      fontSize: '0.7rem',
    },
    commentButtonZh: {
      width: '15rem',
      height: '1.8rem',
    },
    selected: {
      color: color.colorPrimary,
      backgroundColor: '#f6fafd',
      borderColor: color.colorPrimary,
      boxShadow: 'none',
      '&:hover': {
        color: color.colorPrimary,
        backgroundColor: '#f6fafd',
        borderColor: color.colorPrimary,
        boxShadow: 'none',
      },
      '&:active': {
        color: color.colorPrimary,
        backgroundColor: '#f6fafd',
        borderColor: color.colorPrimary,
        boxShadow: 'none',
      },
    }
  })
})

export default useStyle

