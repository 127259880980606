import { DEEP_LINK_DOMAIN, DEEP_LINK_PARAM_CODE, DEEP_LINK_PARAM_PWD, DEEP_LINK_PARAM_RID, DEEP_LINK_PARAM_TYPE, DEEP_LINK_PATH_ROOM, DEEP_LINK_PATH_SECURE_ROOM, DEEP_LINK_SCHEME } from "../utils/constants";
import { decode, encode } from "../utils/urlsafe-base64";
import { isWX } from "./userAgentDetector";

export const isNormalShare = (url: string) => {
  return url.indexOf(`/${DEEP_LINK_PATH_ROOM}`) > 0
}

export const isAgoraShare = (url: string) => {
  return url.indexOf(`/${DEEP_LINK_PATH_SECURE_ROOM}`) > 0
}

export const isShareLink = (url: string) => {
  return isNormalShare(url) || isAgoraShare(url)
}

export const isCurrentLocationShare = () => {
  return isShareLink(window.location.href)
}

export const isCurrentLocationNormalShare = () => {
  return isNormalShare(window.location.href)
}

export const isCurrentLocationAgoraShare = () => {
  return isAgoraShare(window.location.href)
}

export const currentLocationToScheme = () => {
  let url = window.location.href
  if (url.indexOf('https://') >= 0) {
    url = url.slice('https://'.length)
  } else if (url.indexOf('http://') >= 0) {
    url = url.slice('http://'.length)
  }

  return DEEP_LINK_SCHEME + '://' + url
}

export const generateShareLink = (rid: string, pwd: string, isAgoraRoom: boolean, isPrivate: boolean, code?: string) => {
  let params = `?${DEEP_LINK_PARAM_RID}=${encode(Buffer.from(rid, 'utf8'))}`

  if (pwd) {
    params += `&${DEEP_LINK_PARAM_PWD}=${encode(Buffer.from(pwd, 'utf8'))}`
  }

  if (isAgoraRoom) {
    params += `&${DEEP_LINK_PARAM_TYPE}=${isPrivate ? 0 : 1}`
  }

  if (code) {
    params += `&${DEEP_LINK_PARAM_CODE}=${code}`
  }

  return `https://${DEEP_LINK_DOMAIN}${isAgoraRoom ? DEEP_LINK_PATH_SECURE_ROOM : DEEP_LINK_PATH_ROOM}` + params
}

export const getShareRidPwd = (url: string) => {
  const params = (new URL(url)).searchParams
  let rid = params.get(DEEP_LINK_PARAM_RID)
  if (rid) {
    rid = decode(rid).toString()
  }

  let pwd = params.get(DEEP_LINK_PARAM_PWD)
  if (pwd) {
    pwd = decode(pwd).toString()
  }

  return { rid, pwd }
}

export const getCurrentLocationShareRidPwd = () => {
  return getShareRidPwd(window.location.href)
}

export const getCurrentLocationShareType = () => {
  const url = window.location.href
  const params = (new URL(url)).searchParams

  let type: string | null | number = params.get(DEEP_LINK_PARAM_TYPE)
  if (type) {
    type = Number(type)
  }
  let code = params.get(DEEP_LINK_PARAM_CODE)

  return { type, code }
}


// 判断是分享链接，跳转到原生应用
if (isCurrentLocationShare() && !isWX()) {//// 微信打开会中途 404 页面
  setTimeout(() => {
    window.location.href = currentLocationToScheme()
  }, 500)
}