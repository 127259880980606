import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from 'react-i18next';
import { color } from "../../style/color";

export interface CustomDialogProps {
  open: boolean
  title?: string
  content: string
  param?: Map<string, string>,
  onCancel?: () => void
  onOK?: () => void
  onClose?: () => void
  onAsyncCancel?: () => Promise<any>
  onAsyncOK?: () => Promise<any>
  isComfirmType?: boolean
  confirmText?: string
  cancelText?: string
  confirmColor?: 'primary' | 'secondary'
  cancelColor?: 'primary' | 'secondary'
  disableBackgroundClick?: boolean
  isErrorAlert?: boolean
}

const useStyles = makeStyles({
  paper: {
    padding: '1rem',
    minWidth: '1rem',
    maxWidth:'15.25rem',
    minHeight: '7rem',
    margin:'0rem'
  },
  titleBox:{
    padding:0,
    margin:0,
    marginBottom:'0.5rem'
  },
  title: {
    '& .MuiTypography-root': {
      fontSize: '0.75rem',
      fontWeight:600,
      padding:0,
      margin:0
    }
  },
  errorTitle: {
    '& .MuiTypography-root': {
      fontSize: '0.75rem',
      padding:0,
      margin:0,
      fontWeight:600,
      color: color.colorError,
    }
  },
  content: {
    fontSize: '0.75rem',
    marginBottom: '1rem',
    color: color.colorTipGrey,
    padding:0,

  },
  wrapper: {
    position: 'relative',
    '&:nth-child(2)': {
      marginLeft: '1.5rem'
    }
  },
  buttonProgress: {
    color: color.colorPrimary,
    position: 'absolute',
    margin: 'auto',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
  },
  button: {
    fontSize: '0.75rem',
    width:'5.875rem',
    height:'1.875rem',
  },
  buttonGroup:{
    justifyContent:'flex-end',
    padding:0
  }
})

const CustomDialog = observer((props: CustomDialogProps) => {
  const [t] = useTranslation()
  const [cancelLoading, setCancelLoading] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const styles = useStyles()

  const handleClose = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const handleCancel = async () => {
    if (props.onAsyncCancel) {
      setCancelLoading(true)

      try {
        await props.onAsyncCancel()
      } catch (error) {
      } finally {
        setCancelLoading(false)
      }
    } else {
      if (props.onCancel) {
        props.onCancel()
      }
    }
  }

  const handleOK = async () => {
    if (props.onAsyncOK) {
      setConfirmLoading(true)

      try {
        await props.onAsyncOK()
      } catch (error) {
      } finally {
        setConfirmLoading(false)
      }
    } else {
      if (props.onOK) {
        props.onOK()
      }
    }
  }

  const getAlertContent = () => {
    if (props.param && props.param.size > 0) {
      return t(props.content, Object.fromEntries(props.param))
    } else {
      return t(props.content)
    }
  }

  return (
    <Dialog
      disableBackdropClick={props.disableBackgroundClick !== undefined ? props.disableBackgroundClick : false}
      classes={{ paper: styles.paper }}
      maxWidth='xs'
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
      className={styles.titleBox}
       classes={{ root: props.isErrorAlert ? styles.errorTitle : styles.title }}
      >{t(props.title ? props.title : 'Prompt')}</DialogTitle>
      <DialogContent classes={{ root: styles.content }}>{getAlertContent()}</DialogContent>
      <DialogActions className={styles.buttonGroup}>
        {props.isComfirmType &&
          (
            <div className={styles.wrapper}>
              <Button id="alert-cancel" variant="contained"
              className={styles.button}
               disabled={cancelLoading}  onClick={() => { handleCancel() }}>
  
                {t(props.cancelText ? props.cancelText : 'CANCEL')}
              </Button>
              {cancelLoading && <CircularProgress size='1.2rem' className={styles.buttonProgress} />}
            </div>
          )
        }
        <div className={styles.wrapper}>
          <Button id="alert-confirm" variant="contained" 
            className={styles.button}
          disabled={confirmLoading} color={props.confirmColor ? props.confirmColor : 'primary'} onClick={() => { handleOK() }}>
            {t(props.confirmText ? props.confirmText : 'OK')}
          </Button>
          {confirmLoading && <CircularProgress size='1.2rem' className={styles.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>
  )
})

export default CustomDialog
