import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { colorCommon } from "../../style/color"
export const useStyles = makeStyles((theme: Theme) => {
  const { palette } = theme;
  return createStyles({
    alertContainer: {
      position: 'absolute',
      backgroundColor: palette.background.default,
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
      width: '15rem',
      // boxShadow:boxShadow,
      boxShadow: theme.customColor.alertBoxShadow,
      display: 'flex',
      borderRadius: '0.75rem',
      padding: '1rem',
      flexDirection: 'column',
      zIndex: 5000,
    },
    Container: {
      backgroundColor: palette.background.default,
      top: '50%',
      left: '50%',
      width: '15rem',
      display: 'flex',
      borderRadius: '0.75rem',
      padding: '1rem',
      flexDirection: 'column',
      zIndex: 5000,
      transform: 'translate(-50%,-50%)',

    },
    alertPosition: {
      position: 'fixed'
    },
    mask: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 103
    },
    content: {
      fontSize: "0.75rem",
    },
    hasTitleContent: {
      color: colorCommon.colorAlertContentGrey,
    },
    alertContent: {
      // marginBottom: '.5rem',
    },
    buttonGroups: {
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'space-between'
    },
    button: {
      width: '5.875rem',
      fontSize: '0.75rem',
      '&:nth-child(2)': {
        marginLeft: '0.75rem'
      }
    }
  })
})

export const customPopStyle = makeStyles((theme: Theme) => {
  const { palette } = theme;
  return createStyles({
    popPaper: {
      overflow: 'inherit',
      borderRadius: '0.75rem',
      padding: '0.75rem 1rem',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.customColor.alertBoxShadow,
      maxWidth: '13.25rem',
      width: 'auto',
    },
    paper: {
      overflow: 'inherit',
      borderRadius: '0.75rem',
      padding: '0.75rem 1rem',
      width: '15.25rem',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.customColor.alertBoxShadow
    },
    triangle: {
      transform: 'rotate(270deg) translate(50%,-50%)',
      position: 'absolute',
      top: '50%',
      left: '-0.4375rem',
      fill: theme.palette.background.paper,
    },
    buttonGroups: {
      marginTop: '0.75rem',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      width: 'auto',
      padding:'0.25rem 1rem',
      fontSize: '0.75rem',
      textTransform: 'none',
      '&:nth-child(2)': {
        marginLeft: '0.75rem'
      }
    }
  })
})
