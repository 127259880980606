/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.protobuf = (function() {

    /**
     * Namespace protobuf.
     * @exports protobuf
     * @namespace
     */
    var protobuf = {};

    protobuf.User = (function() {

        /**
         * Properties of a User.
         * @memberof protobuf
         * @interface IUser
         * @property {string|null} [name] User name
         * @property {number|null} [parentStreamId] User parentStreamId
         * @property {number|null} [status] User status
         */

        /**
         * Constructs a new User.
         * @memberof protobuf
         * @classdesc Represents a User.
         * @implements IUser
         * @constructor
         * @param {protobuf.IUser=} [properties] Properties to set
         */
        function User(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * User name.
         * @member {string} name
         * @memberof protobuf.User
         * @instance
         */
        User.prototype.name = "";

        /**
         * User parentStreamId.
         * @member {number} parentStreamId
         * @memberof protobuf.User
         * @instance
         */
        User.prototype.parentStreamId = 0;

        /**
         * User status.
         * @member {number} status
         * @memberof protobuf.User
         * @instance
         */
        User.prototype.status = 0;

        /**
         * Creates a new User instance using the specified properties.
         * @function create
         * @memberof protobuf.User
         * @static
         * @param {protobuf.IUser=} [properties] Properties to set
         * @returns {protobuf.User} User instance
         */
        User.create = function create(properties) {
            return new User(properties);
        };

        /**
         * Encodes the specified User message. Does not implicitly {@link protobuf.User.verify|verify} messages.
         * @function encode
         * @memberof protobuf.User
         * @static
         * @param {protobuf.IUser} message User message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        User.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.parentStreamId != null && Object.hasOwnProperty.call(message, "parentStreamId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.parentStreamId);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified User message, length delimited. Does not implicitly {@link protobuf.User.verify|verify} messages.
         * @function encodeDelimited
         * @memberof protobuf.User
         * @static
         * @param {protobuf.IUser} message User message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        User.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a User message from the specified reader or buffer.
         * @function decode
         * @memberof protobuf.User
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {protobuf.User} User
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        User.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.User();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.parentStreamId = reader.int32();
                    break;
                case 3:
                    message.status = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a User message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof protobuf.User
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {protobuf.User} User
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        User.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a User message.
         * @function verify
         * @memberof protobuf.User
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        User.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.parentStreamId != null && message.hasOwnProperty("parentStreamId"))
                if (!$util.isInteger(message.parentStreamId))
                    return "parentStreamId: integer expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isInteger(message.status))
                    return "status: integer expected";
            return null;
        };

        /**
         * Creates a User message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof protobuf.User
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {protobuf.User} User
         */
        User.fromObject = function fromObject(object) {
            if (object instanceof $root.protobuf.User)
                return object;
            var message = new $root.protobuf.User();
            if (object.name != null)
                message.name = String(object.name);
            if (object.parentStreamId != null)
                message.parentStreamId = object.parentStreamId | 0;
            if (object.status != null)
                message.status = object.status | 0;
            return message;
        };

        /**
         * Creates a plain object from a User message. Also converts values to other types if specified.
         * @function toObject
         * @memberof protobuf.User
         * @static
         * @param {protobuf.User} message User
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        User.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.name = "";
                object.parentStreamId = 0;
                object.status = 0;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.parentStreamId != null && message.hasOwnProperty("parentStreamId"))
                object.parentStreamId = message.parentStreamId;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            return object;
        };

        /**
         * Converts this User to JSON.
         * @function toJSON
         * @memberof protobuf.User
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        User.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return User;
    })();

    protobuf.Biz = (function() {

        /**
         * Properties of a Biz.
         * @memberof protobuf
         * @interface IBiz
         * @property {string|null} [hostUid] Biz hostUid
         * @property {boolean|null} [roomAudio] Biz roomAudio
         * @property {boolean|null} [roomVideo] Biz roomVideo
         * @property {string|null} [rtmServerPeerId] Biz rtmServerPeerId
         * @property {number|Long|null} [timestamp] Biz timestamp
         */

        /**
         * Constructs a new Biz.
         * @memberof protobuf
         * @classdesc Represents a Biz.
         * @implements IBiz
         * @constructor
         * @param {protobuf.IBiz=} [properties] Properties to set
         */
        function Biz(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Biz hostUid.
         * @member {string} hostUid
         * @memberof protobuf.Biz
         * @instance
         */
        Biz.prototype.hostUid = "";

        /**
         * Biz roomAudio.
         * @member {boolean} roomAudio
         * @memberof protobuf.Biz
         * @instance
         */
        Biz.prototype.roomAudio = false;

        /**
         * Biz roomVideo.
         * @member {boolean} roomVideo
         * @memberof protobuf.Biz
         * @instance
         */
        Biz.prototype.roomVideo = false;

        /**
         * Biz rtmServerPeerId.
         * @member {string} rtmServerPeerId
         * @memberof protobuf.Biz
         * @instance
         */
        Biz.prototype.rtmServerPeerId = "";

        /**
         * Biz timestamp.
         * @member {number|Long} timestamp
         * @memberof protobuf.Biz
         * @instance
         */
        Biz.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Biz instance using the specified properties.
         * @function create
         * @memberof protobuf.Biz
         * @static
         * @param {protobuf.IBiz=} [properties] Properties to set
         * @returns {protobuf.Biz} Biz instance
         */
        Biz.create = function create(properties) {
            return new Biz(properties);
        };

        /**
         * Encodes the specified Biz message. Does not implicitly {@link protobuf.Biz.verify|verify} messages.
         * @function encode
         * @memberof protobuf.Biz
         * @static
         * @param {protobuf.IBiz} message Biz message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Biz.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hostUid != null && Object.hasOwnProperty.call(message, "hostUid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.hostUid);
            if (message.roomAudio != null && Object.hasOwnProperty.call(message, "roomAudio"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.roomAudio);
            if (message.roomVideo != null && Object.hasOwnProperty.call(message, "roomVideo"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.roomVideo);
            if (message.rtmServerPeerId != null && Object.hasOwnProperty.call(message, "rtmServerPeerId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.rtmServerPeerId);
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.timestamp);
            return writer;
        };

        /**
         * Encodes the specified Biz message, length delimited. Does not implicitly {@link protobuf.Biz.verify|verify} messages.
         * @function encodeDelimited
         * @memberof protobuf.Biz
         * @static
         * @param {protobuf.IBiz} message Biz message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Biz.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Biz message from the specified reader or buffer.
         * @function decode
         * @memberof protobuf.Biz
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {protobuf.Biz} Biz
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Biz.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.Biz();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.hostUid = reader.string();
                    break;
                case 2:
                    message.roomAudio = reader.bool();
                    break;
                case 3:
                    message.roomVideo = reader.bool();
                    break;
                case 4:
                    message.rtmServerPeerId = reader.string();
                    break;
                case 5:
                    message.timestamp = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Biz message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof protobuf.Biz
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {protobuf.Biz} Biz
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Biz.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Biz message.
         * @function verify
         * @memberof protobuf.Biz
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Biz.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hostUid != null && message.hasOwnProperty("hostUid"))
                if (!$util.isString(message.hostUid))
                    return "hostUid: string expected";
            if (message.roomAudio != null && message.hasOwnProperty("roomAudio"))
                if (typeof message.roomAudio !== "boolean")
                    return "roomAudio: boolean expected";
            if (message.roomVideo != null && message.hasOwnProperty("roomVideo"))
                if (typeof message.roomVideo !== "boolean")
                    return "roomVideo: boolean expected";
            if (message.rtmServerPeerId != null && message.hasOwnProperty("rtmServerPeerId"))
                if (!$util.isString(message.rtmServerPeerId))
                    return "rtmServerPeerId: string expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                    return "timestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates a Biz message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof protobuf.Biz
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {protobuf.Biz} Biz
         */
        Biz.fromObject = function fromObject(object) {
            if (object instanceof $root.protobuf.Biz)
                return object;
            var message = new $root.protobuf.Biz();
            if (object.hostUid != null)
                message.hostUid = String(object.hostUid);
            if (object.roomAudio != null)
                message.roomAudio = Boolean(object.roomAudio);
            if (object.roomVideo != null)
                message.roomVideo = Boolean(object.roomVideo);
            if (object.rtmServerPeerId != null)
                message.rtmServerPeerId = String(object.rtmServerPeerId);
            if (object.timestamp != null)
                if ($util.Long)
                    (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                else if (typeof object.timestamp === "string")
                    message.timestamp = parseInt(object.timestamp, 10);
                else if (typeof object.timestamp === "number")
                    message.timestamp = object.timestamp;
                else if (typeof object.timestamp === "object")
                    message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Biz message. Also converts values to other types if specified.
         * @function toObject
         * @memberof protobuf.Biz
         * @static
         * @param {protobuf.Biz} message Biz
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Biz.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.hostUid = "";
                object.roomAudio = false;
                object.roomVideo = false;
                object.rtmServerPeerId = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestamp = options.longs === String ? "0" : 0;
            }
            if (message.hostUid != null && message.hasOwnProperty("hostUid"))
                object.hostUid = message.hostUid;
            if (message.roomAudio != null && message.hasOwnProperty("roomAudio"))
                object.roomAudio = message.roomAudio;
            if (message.roomVideo != null && message.hasOwnProperty("roomVideo"))
                object.roomVideo = message.roomVideo;
            if (message.rtmServerPeerId != null && message.hasOwnProperty("rtmServerPeerId"))
                object.rtmServerPeerId = message.rtmServerPeerId;
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (typeof message.timestamp === "number")
                    object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                else
                    object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
            return object;
        };

        /**
         * Converts this Biz to JSON.
         * @function toJSON
         * @memberof protobuf.Biz
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Biz.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Biz;
    })();

    protobuf.Control = (function() {

        /**
         * Properties of a Control.
         * @memberof protobuf
         * @interface IControl
         * @property {number|null} [targetStreamId] Control targetStreamId
         * @property {number|null} [requestType] Control requestType
         * @property {number|null} [sequenceId] Control sequenceId
         */

        /**
         * Constructs a new Control.
         * @memberof protobuf
         * @classdesc Represents a Control.
         * @implements IControl
         * @constructor
         * @param {protobuf.IControl=} [properties] Properties to set
         */
        function Control(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Control targetStreamId.
         * @member {number} targetStreamId
         * @memberof protobuf.Control
         * @instance
         */
        Control.prototype.targetStreamId = 0;

        /**
         * Control requestType.
         * @member {number} requestType
         * @memberof protobuf.Control
         * @instance
         */
        Control.prototype.requestType = 0;

        /**
         * Control sequenceId.
         * @member {number} sequenceId
         * @memberof protobuf.Control
         * @instance
         */
        Control.prototype.sequenceId = 0;

        /**
         * Creates a new Control instance using the specified properties.
         * @function create
         * @memberof protobuf.Control
         * @static
         * @param {protobuf.IControl=} [properties] Properties to set
         * @returns {protobuf.Control} Control instance
         */
        Control.create = function create(properties) {
            return new Control(properties);
        };

        /**
         * Encodes the specified Control message. Does not implicitly {@link protobuf.Control.verify|verify} messages.
         * @function encode
         * @memberof protobuf.Control
         * @static
         * @param {protobuf.IControl} message Control message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Control.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.targetStreamId != null && Object.hasOwnProperty.call(message, "targetStreamId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.targetStreamId);
            if (message.requestType != null && Object.hasOwnProperty.call(message, "requestType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.requestType);
            if (message.sequenceId != null && Object.hasOwnProperty.call(message, "sequenceId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sequenceId);
            return writer;
        };

        /**
         * Encodes the specified Control message, length delimited. Does not implicitly {@link protobuf.Control.verify|verify} messages.
         * @function encodeDelimited
         * @memberof protobuf.Control
         * @static
         * @param {protobuf.IControl} message Control message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Control.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Control message from the specified reader or buffer.
         * @function decode
         * @memberof protobuf.Control
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {protobuf.Control} Control
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Control.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.Control();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.targetStreamId = reader.int32();
                    break;
                case 2:
                    message.requestType = reader.int32();
                    break;
                case 3:
                    message.sequenceId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Control message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof protobuf.Control
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {protobuf.Control} Control
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Control.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Control message.
         * @function verify
         * @memberof protobuf.Control
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Control.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.targetStreamId != null && message.hasOwnProperty("targetStreamId"))
                if (!$util.isInteger(message.targetStreamId))
                    return "targetStreamId: integer expected";
            if (message.requestType != null && message.hasOwnProperty("requestType"))
                if (!$util.isInteger(message.requestType))
                    return "requestType: integer expected";
            if (message.sequenceId != null && message.hasOwnProperty("sequenceId"))
                if (!$util.isInteger(message.sequenceId))
                    return "sequenceId: integer expected";
            return null;
        };

        /**
         * Creates a Control message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof protobuf.Control
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {protobuf.Control} Control
         */
        Control.fromObject = function fromObject(object) {
            if (object instanceof $root.protobuf.Control)
                return object;
            var message = new $root.protobuf.Control();
            if (object.targetStreamId != null)
                message.targetStreamId = object.targetStreamId | 0;
            if (object.requestType != null)
                message.requestType = object.requestType | 0;
            if (object.sequenceId != null)
                message.sequenceId = object.sequenceId | 0;
            return message;
        };

        /**
         * Creates a plain object from a Control message. Also converts values to other types if specified.
         * @function toObject
         * @memberof protobuf.Control
         * @static
         * @param {protobuf.Control} message Control
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Control.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.targetStreamId = 0;
                object.requestType = 0;
                object.sequenceId = 0;
            }
            if (message.targetStreamId != null && message.hasOwnProperty("targetStreamId"))
                object.targetStreamId = message.targetStreamId;
            if (message.requestType != null && message.hasOwnProperty("requestType"))
                object.requestType = message.requestType;
            if (message.sequenceId != null && message.hasOwnProperty("sequenceId"))
                object.sequenceId = message.sequenceId;
            return object;
        };

        /**
         * Converts this Control to JSON.
         * @function toJSON
         * @memberof protobuf.Control
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Control.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Control;
    })();

    protobuf.Metadata = (function() {

        /**
         * Properties of a Metadata.
         * @memberof protobuf
         * @interface IMetadata
         * @property {protobuf.IUser|null} [user] Metadata user
         * @property {protobuf.IBiz|null} [biz] Metadata biz
         * @property {protobuf.IControl|null} [ctrl] Metadata ctrl
         */

        /**
         * Constructs a new Metadata.
         * @memberof protobuf
         * @classdesc Represents a Metadata.
         * @implements IMetadata
         * @constructor
         * @param {protobuf.IMetadata=} [properties] Properties to set
         */
        function Metadata(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Metadata user.
         * @member {protobuf.IUser|null|undefined} user
         * @memberof protobuf.Metadata
         * @instance
         */
        Metadata.prototype.user = null;

        /**
         * Metadata biz.
         * @member {protobuf.IBiz|null|undefined} biz
         * @memberof protobuf.Metadata
         * @instance
         */
        Metadata.prototype.biz = null;

        /**
         * Metadata ctrl.
         * @member {protobuf.IControl|null|undefined} ctrl
         * @memberof protobuf.Metadata
         * @instance
         */
        Metadata.prototype.ctrl = null;

        /**
         * Creates a new Metadata instance using the specified properties.
         * @function create
         * @memberof protobuf.Metadata
         * @static
         * @param {protobuf.IMetadata=} [properties] Properties to set
         * @returns {protobuf.Metadata} Metadata instance
         */
        Metadata.create = function create(properties) {
            return new Metadata(properties);
        };

        /**
         * Encodes the specified Metadata message. Does not implicitly {@link protobuf.Metadata.verify|verify} messages.
         * @function encode
         * @memberof protobuf.Metadata
         * @static
         * @param {protobuf.IMetadata} message Metadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Metadata.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.protobuf.User.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.biz != null && Object.hasOwnProperty.call(message, "biz"))
                $root.protobuf.Biz.encode(message.biz, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.ctrl != null && Object.hasOwnProperty.call(message, "ctrl"))
                $root.protobuf.Control.encode(message.ctrl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Metadata message, length delimited. Does not implicitly {@link protobuf.Metadata.verify|verify} messages.
         * @function encodeDelimited
         * @memberof protobuf.Metadata
         * @static
         * @param {protobuf.IMetadata} message Metadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Metadata.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Metadata message from the specified reader or buffer.
         * @function decode
         * @memberof protobuf.Metadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {protobuf.Metadata} Metadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Metadata.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.Metadata();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.user = $root.protobuf.User.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.biz = $root.protobuf.Biz.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.ctrl = $root.protobuf.Control.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Metadata message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof protobuf.Metadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {protobuf.Metadata} Metadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Metadata.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Metadata message.
         * @function verify
         * @memberof protobuf.Metadata
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Metadata.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.user != null && message.hasOwnProperty("user")) {
                var error = $root.protobuf.User.verify(message.user);
                if (error)
                    return "user." + error;
            }
            if (message.biz != null && message.hasOwnProperty("biz")) {
                var error = $root.protobuf.Biz.verify(message.biz);
                if (error)
                    return "biz." + error;
            }
            if (message.ctrl != null && message.hasOwnProperty("ctrl")) {
                var error = $root.protobuf.Control.verify(message.ctrl);
                if (error)
                    return "ctrl." + error;
            }
            return null;
        };

        /**
         * Creates a Metadata message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof protobuf.Metadata
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {protobuf.Metadata} Metadata
         */
        Metadata.fromObject = function fromObject(object) {
            if (object instanceof $root.protobuf.Metadata)
                return object;
            var message = new $root.protobuf.Metadata();
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".protobuf.Metadata.user: object expected");
                message.user = $root.protobuf.User.fromObject(object.user);
            }
            if (object.biz != null) {
                if (typeof object.biz !== "object")
                    throw TypeError(".protobuf.Metadata.biz: object expected");
                message.biz = $root.protobuf.Biz.fromObject(object.biz);
            }
            if (object.ctrl != null) {
                if (typeof object.ctrl !== "object")
                    throw TypeError(".protobuf.Metadata.ctrl: object expected");
                message.ctrl = $root.protobuf.Control.fromObject(object.ctrl);
            }
            return message;
        };

        /**
         * Creates a plain object from a Metadata message. Also converts values to other types if specified.
         * @function toObject
         * @memberof protobuf.Metadata
         * @static
         * @param {protobuf.Metadata} message Metadata
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Metadata.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.user = null;
                object.biz = null;
                object.ctrl = null;
            }
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.protobuf.User.toObject(message.user, options);
            if (message.biz != null && message.hasOwnProperty("biz"))
                object.biz = $root.protobuf.Biz.toObject(message.biz, options);
            if (message.ctrl != null && message.hasOwnProperty("ctrl"))
                object.ctrl = $root.protobuf.Control.toObject(message.ctrl, options);
            return object;
        };

        /**
         * Converts this Metadata to JSON.
         * @function toJSON
         * @memberof protobuf.Metadata
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Metadata.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Metadata;
    })();

    return protobuf;
})();

module.exports = $root;
