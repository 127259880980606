import { makeStyles, createStyles, Theme } from "@material-ui/core"
import iosQRCode from "../../assets/ios_qrcode.png";
import androidCodeLocal from "../../assets/android_yingyongbao_qrcode.png";
import androidCodeExternal from "../../assets/android_gplay_qrcode.png";

export const useStyles = makeStyles((theme: Theme) => {
  const { customColor: { downloadTextColor } } = theme
  const isDark = theme.palette.type === 'dark'

  const styles = createStyles({
    downloadContent: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom:'1rem'
    },
    downLoadLine: {
      width: '18.125rem',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '1rem',
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      width: '4rem',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      position: 'relative',
    },
    icon: {
      position: 'absolute',
      margin: 'auto',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
    },
    iconMac: {
      width: '1.25rem',
      height: '1.5625rem',
    },
    iconContainer: {
      width: '3.125rem',
      height: '3.125rem',
      position: 'relative',
      marginBottom: '0.5rem',
      // boxShadow: isDark ? 'none' : '0px 6px 10px 0px #e5e6e7',
      borderRadius: '1.5625rem',
      background: isDark ? '#38383B' : '#fff',
    },
    iconDownload: {
      width: '3.125rem',
      height: '3.125rem',
    },
    iosCode: {
      backgroundImage: `url(${iosQRCode})`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: '4rem',
      height: '4.3125rem',
    },
    androidCodeLocal: {
      backgroundImage: `url(${androidCodeLocal})`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: '4rem',
      height: '4.3125rem',
    },
    androidCodeExternal: {
      backgroundImage: `url(${androidCodeExternal})`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: '4rem',
      height: '4.3125rem',
    },
    androidItem: {
      '&:hover .androidCode': {
        display: 'block'
      }
    },
    iosItem: {
      '&:hover .iosCode': {
        display: 'block'
      }
    },
    tooltipPlacementBottom: {
      boxShadow: 'none',
      borderRadius: '0.625rem',
      '& div':{
        boxShadow: 'none',
      }
    },
    donwloadText: {
      // color: downloadTextColor,
    },
    clientDownload: {
      // fontWeight: 500,
    },
  })
  return styles
})
