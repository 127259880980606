import { action, observable, computed } from "mobx";
import { APPLY_ASSISTANT_LANG } from "../utils/constants";
import { isChinese } from "../utils/helper";
import { BizUser } from "./bizUser";

export class AssistantInfo {
  @observable
  public assistantName: string = ''
  @observable
  public nickName: string = ''
  public language: APPLY_ASSISTANT_LANG = APPLY_ASSISTANT_LANG.CH
  @observable
  public assistantUid: string = ''
  @observable
  public streamId: number = 0
  public assistantDetail: BizUser = new BizUser()
  public selfUid: string = ''
  @observable
  public thirdpartyName: string = ''

  @observable
  public thirdpartyAlias: string = ''

  @action
  public updateAssistantInfo(assistantInfo: AssistantInfo) {
    this.assistantUid = assistantInfo.assistantUid
    this.language = assistantInfo.language
    this.streamId = assistantInfo.streamId
    this.assistantDetail = assistantInfo.assistantDetail
    this.thirdpartyName = assistantInfo.thirdpartyName || ''
    this.thirdpartyAlias = assistantInfo.thirdpartyAlias || ''
    this.nickName = assistantInfo.nickName || ''
    this.assistantName = this.getAssistantName()
  }

  public getAssistantName() {
    let assistantName = this.nickName
    if (this.thirdpartyAlias) {
      assistantName = `${this.thirdpartyName}(${this.thirdpartyAlias})`
    } else if (this.thirdpartyName) {
      assistantName = this.thirdpartyName
    } else {
      assistantName = this.nickName
    }
    return assistantName
  }

  @action
  public resetAssistantInfo() {
    this.assistantUid = ''
    this.thirdpartyName = ''
    this.thirdpartyAlias = ''
    this.nickName = ''
    this.assistantName = this.getAssistantName()
    this.streamId = 0
    this.language = APPLY_ASSISTANT_LANG.CH
  }

  public  getAssistantUid() {
    return this.assistantUid
  }

  public isAssistant(user: BizUser): boolean {
    if (this.hasAssistant()) {
      return user.uid === this.assistantUid
    }
    return false
  }

  public setHelpName() {
    return isChinese() ? "同声传译" : "Interpreter"
  }

  public hasAssistant() {
    return Boolean(this.assistantUid)
  }

  public  isMeAssistant() {
    return this.selfUid === this.assistantUid
  }
}