import { Box, IconButton, Tooltip, useTheme } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as Dump } from "../../assets/icon_dump.svg";
import IssueFeedBackModal from './IssueFeedBackModal';

const IssueFeedbackView = observer(() => {
  const [t] = useTranslation()
  const [isShow, modalHandle] = useState(false)
  const theme = useTheme()
  const { customColor: { participantsIconColor, } } = theme
  const openInsideModalTime = useRef<number>()
  const handleOpenModal = () => {
    modalHandle(true)
    openInsideModalTime.current = new Date().getTime()
  }

  const handleCloseModal = () => {
    modalHandle(false)
  }

  return (
    <Box id="issueFeedbackContainer" >
      <Tooltip title={
        t('room_tooltip_dump')
      } placement="bottom">
        <IconButton onClick={handleOpenModal} style={{ marginLeft: '1rem' }}>
          <Dump id="issue-feedback"  fill={participantsIconColor} />
        </IconButton>
      </Tooltip>
      {isShow && <IssueFeedBackModal setClose={handleCloseModal} openInsideModalTime={openInsideModalTime} />}
    </Box>
  )
})

export default IssueFeedbackView
