import { action, observable, remove } from "mobx";
import moment from 'moment';
import { BizRoomInfo, JoinRoomResult, Recorder } from "../../types/bizRoomInfo";
import { AssistantInfo } from "../../types/assistantInfo";
import { BizUser, UserOperationEx } from "../../types/bizUser";
import { ChatMessage } from "../../types/chatMessage";
import { MyRequestResponse, OP_TYPE, UserRequest, SelfApplyAssistantResponse, UserApplyAssistantRequest } from "../../types/userRequest";
import { BIZ_ROOM_STATE, CloudRecordingError, ENCRYPTION_MODE, ERR_BIZ_DISCONNECT, ERR_BIZ_USER_LOST, ERR_JOIN_INVALID_RID, ERR_JOIN_WRONG_PWD, ERR_JOIN_WRONG_SESSION, ERR_REQUEST_FAILURE, ROOM_AGORA_JOIN_NO_PERMISSION, ROOM_MODE, RTMConnectState, UserChangedReason } from "../../utils/constants";
import { log, logException, LOG_MODULE, LOG_TYPE, uploadLog } from "../../utils/Log";
import { reporter } from "../../utils/Reporter";
import { CommManagerStore } from "../commManager";
import { getServerId, isRoomReject, isTokenError } from "../connector/http";
import { RtmEngineLayerStore, RtmError } from "./rtmEngineLayer";
import { isChinese } from "../../utils/helper";
class RemoteRequest {
  public requestIds: number = 0
  public timerId :number = 0
}
interface IConnectRoomInfo {
  roomMode: ROOM_MODE,
  roomId: string,
  password: string,
  rtcEncryption: boolean,
  channelName: string,
  selfUid: string,
  audioState: boolean,
  videoState: boolean,
  accessToken: string,
  roomToken: string
}
export class RtmCommLayerStore {
  @observable
  public roomState: BIZ_ROOM_STATE = BIZ_ROOM_STATE.BIZ_ROOM_DISCONNECTED
  public roomMode: ROOM_MODE = ROOM_MODE.MODE_NORMAL
  public roomInfo: BizRoomInfo = new BizRoomInfo();
  public assistantInfo: AssistantInfo = new AssistantInfo()
  private roomElapsed: number = 0
  private roomAppendMark: string = ""
  private hasMoreUser = false

  public users: Map<string, BizUser> = new Map()

  private selfUid: string = ''
  private accessToken: string = ''
  private currentMajorStreamId: number = 0

  public unhandledUserJoinEvents: BizUser[] = [];

  private currentJoinMsgId: number = 0
  private joinRetryTimerId: number = 0
  private joinTimeoutId: number = 0

  @observable
  public chatMessage: ChatMessage[] = []
  @observable
  public unreadMsgCount: number = 0

  private rtmEngine: RtmEngineLayerStore
  private commManager: CommManagerStore
  private margeUserListTimer: any = null
  private mergeRoomUserList: any[] = []
  private entersOrLeavesTimes: number = 0
  private lastEntersOrLeavesTimestamp: number = 0;
  private MAX_THRESHOLD: number = 3
  private MIN_THRESHOLD: number = 0
  private USER_INTERVAL_MILLISECOND: number = 300
  private requestCollector: { requestId: number, timer: number, type: string }[] = []

  public constructor(rtmEngine: RtmEngineLayerStore, commManager: CommManagerStore) {
    this.rtmEngine = rtmEngine
    this.commManager = commManager
  }

  /************************************ ROOM CONNECTION ***********************************/

  @action
  public connectRoom(data:IConnectRoomInfo) {
    const { roomMode, roomId, selfUid, password, videoState,
      channelName, accessToken, rtcEncryption, audioState,roomToken} = data
    log(`connectRoom ${roomId} ${password} self uid: ${selfUid}`, LOG_TYPE.INFO, LOG_MODULE.RTM)
    if (!selfUid) {
      log(`no selfUid, keep disconnect`, LOG_TYPE.ERROR, LOG_MODULE.RTM)
      this.setRoomConnState(BIZ_ROOM_STATE.BIZ_ROOM_DISCONNECTED)
      this.commManager.onBizJoinFail('DisconnectedRTMCheckNetwork')
      return
    }

    this.roomMode = roomMode
    this.selfUid = selfUid
    this.accessToken = accessToken

    this.rtmEngine.requestLogin(selfUid)

    this.setRoomConnState(BIZ_ROOM_STATE.BIZ_ROOM_CONNECTING)

    this.clearRoom()

    this.roomInfo = new BizRoomInfo()
    this.roomInfo.rid = roomId
    this.roomInfo.pwd = password
    this.roomInfo.rtcEncryption = rtcEncryption
    this.roomInfo.channelName = channelName
    this.roomInfo.roomToken = roomToken

    this.addUser(this.buildSelf(selfUid, audioState, videoState, false))

    if (!this.rtmEngine.isConnected) {
      log("connectRoom, rtm not connected, wait...", LOG_TYPE.INFO, LOG_MODULE.RTM)
      return
    }

    this.startConnectRoom()
  }

  @action
  private async startConnectRoom() {
    if (!this.isInRoom()) {
      // better check, otherwise user may exits during post delay time.
      return
    }

    try {
      const res = await getServerId(this.roomInfo.rid, this.roomInfo.pwd, this.roomInfo.roomToken)
      const { data: responseData } = res
      if (!responseData.success) {
        throw { ...responseData }
      }
      this.rtmEngine.setServerId(res.data.account)
      const { rtc: { key, encryptionMode, token, salt, cname }, roomToken } = res.data;
      if (cname) {
        this.roomInfo.channelName = cname
        this.roomInfo.rtcToken = token
      }
      this.roomInfo.encryptionMode = encryptionMode
      this.commManager.onMediaRoomJoinInfo({
        encryption: encryptionMode !== ENCRYPTION_MODE.NONE,
        rtcSecretKey: key,
        roomToken,
        channelName: this.roomInfo.channelName,
        rtcToken: this.roomInfo.rtcToken,
        encryptionMode,
        salt })
      this.roomInfo.rtcEncryption = encryptionMode !== ENCRYPTION_MODE.NONE;
      this.roomInfo.salt = salt

      this.doConnectRoom()
    } catch (error) {
      if (!this.isInRoom()) return
      // Token 错误时，重试也没用
      // https://jira.agoralab.co/browse/APP-2098
      if (isRoomReject(error.code)) {
        this.setRoomConnState(BIZ_ROOM_STATE.BIZ_ROOM_DISCONNECTED)
        this.commManager.onBizJoinDenied(error.code, error.errorMsg)
        return
      }
      if (!isTokenError(error)) {
        this.commManager.setBizConnectErrorMessage(error.response?.status || error.code)
        this.postRetryConnect()
      } else {
        this.setRoomConnState(BIZ_ROOM_STATE.BIZ_ROOM_RECONNECTING_PEER)
        this.commManager.onBizJoinFail('DisconnectedRTMCheckNetwork')
      }
    }
  }

  public async doConnectRoom() {
    if (!this.isInRoom()) return // user may already click leave

    if (!this.rtmEngine.isConnected) {
      log("doConnectRoom, rtm not connected, wait...", LOG_TYPE.INFO, LOG_MODULE.RTM)
      return
    }

    this.unhandledUserJoinEvents = []

    if (!this.rtmEngine.isInChannel(this.roomInfo.rid)) {
      this.rtmEngine.joinChannel(this.roomInfo.rid).catch((e: any) => {
        logException(`join rtm channel error `, e, LOG_MODULE.RTM)

        if (this.rtmEngine.isConnected) {
          this.rtmEngine.sendLeaveCmd(this.roomInfo.rid)
        }

        this.postRetryConnect()
      })
    } else {
      log("already in rtm channel", LOG_TYPE.INFO, LOG_MODULE.RTM)
    }

    try {
      this.currentJoinMsgId = this.rtmEngine.peekMsgId()
      this.joinTimeoutId = window.setTimeout(this.connectTimeout.bind(this, this.currentJoinMsgId), this.rtmEngine.getMessageTimeout())

      const userMe = this.getUserMe()

      await this.rtmEngine.sendJoinCmd(this.roomInfo.rid, this.roomInfo.pwd, "", this.accessToken, userMe!.status, userMe!.shareId)
    } catch (error) {
      if (this.isInRoom() && error.msgId === this.currentJoinMsgId) {
        log(`send join cmd exception, code: ${error.code} desc: ${error.desc}`, LOG_TYPE.ERROR, LOG_MODULE.RTM)

        this.rtmEngine.leaveChannel()

        if (this.isJoinDenied(error.code)) {
          this.setRoomConnState(BIZ_ROOM_STATE.BIZ_ROOM_DISCONNECTED)

          this.rtmEngine.requestLogout()

          this.commManager.onBizJoinDenied(error.code, error.desc)
        } else {
          this.postRetryConnect()
        }
      } else {
        log(`ignore join cmd exception, not in room or msg id not match`, LOG_TYPE.INFO, LOG_MODULE.RTM)
      }
    }
  }

  private connectTimeout(joinMsgId: number) {
    if (this.isInRoom() && joinMsgId === this.currentJoinMsgId) {
      log(`rtm room connectTimeout join msg id: ${joinMsgId}`, LOG_TYPE.ERROR, LOG_MODULE.RTM)

      // server may still wait rtm channel join, so force reconnect
      this.doDisconnectRoom()

      this.postRetryConnect()
    }
  }

  private clearJoinTimeoutTimer() {
    if (this.joinTimeoutId > 0) {
      window.clearTimeout(this.joinTimeoutId)
      this.joinTimeoutId = 0
    }
  }

  private clearJoinAndLeaveInterval() {
    if (this.margeUserListTimer) {
      clearInterval(this.margeUserListTimer)
      this.margeUserListTimer = null
    }
    this.mergeRoomUserList = []
    this.entersOrLeavesTimes = 0
    this.lastEntersOrLeavesTimestamp = 0
  }

  private postRetryConnect() {
    if (!this.isInRoom()) return

    log("postRetryConnect", LOG_TYPE.ERROR, LOG_MODULE.RTM)

    this.setRoomConnState(BIZ_ROOM_STATE.BIZ_ROOM_RECONNECTING_PEER)

    this.clearJoinTimeoutTimer()

    this.clearRetryConnectTimer()

    this.joinRetryTimerId = window.setTimeout(() => {
      this.startConnectRoom()
    }, 3000)

    this.currentJoinMsgId = 0
  }

  private clearRetryConnectTimer() {
    if (this.joinRetryTimerId !== 0) {
      window.clearTimeout(this.joinRetryTimerId)
      this.joinRetryTimerId = 0
    }
  }

  public disconnectRoom() {
    if (!this.isInRoom()) {
      log("room already disconnected, return", LOG_TYPE.INFO, LOG_MODULE.RTM)
      return
    }

    log("disconnectRoom", LOG_TYPE.INFO, LOG_MODULE.RTM)

    this.doDisconnectRoom()

    this.clearRoom()

    this.setRoomConnState(BIZ_ROOM_STATE.BIZ_ROOM_DISCONNECTED)

    this.rtmEngine.requestLogout()

    reporter.setupReportFlag()
  }

  private doDisconnectRoom() {
    if (this.rtmEngine.isConnected) {
      this.rtmEngine.sendLeaveCmd(this.roomInfo.rid)

      this.rtmEngine.leaveChannel()
    }
  }

  @action
  private clearRoom() {
    this.currentJoinMsgId = 0
    this.currentMajorStreamId = 0
    this.unhandledUserJoinEvents = []
    this.users.clear()
    this.roomInfo = new BizRoomInfo()
    this.roomElapsed = 0
    this.roomAppendMark = ""
    this.hasMoreUser = false
    this.chatMessage = []
    this.unreadMsgCount = 0

    this.clearRetryConnectTimer()
    this.clearJoinTimeoutTimer()
    this.clearJoinAndLeaveInterval()
  }

  @action
  public setRoomConnState(state: BIZ_ROOM_STATE) {
    log(`biz room state: ${BIZ_ROOM_STATE[state]}`, LOG_TYPE.INFO, LOG_MODULE.RTM)

    this.roomState = state

    this.commManager.onBizRoomState(state)
  }

  /****************** CALLBACK from RtmEngine *****************/

  public onConnectStateChanged(state: RTMConnectState) {
    if (!this.isInRoom()) return

    if (state === RTMConnectState.CONNECTED) {
      this.startConnectRoom()
    } else if (state === RTMConnectState.RECONNECTING ||
      state === RTMConnectState.ABORTED ||
      state === RTMConnectState.DISCONNECTED
    ) {
      this.setRoomConnState(BIZ_ROOM_STATE.BIZ_ROOM_RECONNECTING)
    }
  }

  public onJoinSuccessResult(result: JoinRoomResult) {

    log(`onJoinSuccessResult msg id: ${result.joinMsgId}`, LOG_TYPE.INFO, LOG_MODULE.RTM)

    if (result.joinMsgId !== this.currentJoinMsgId) {
      log(`OnJoinSuccessResult, join msg id not match, current: ${this.currentJoinMsgId}, got: ${result.joinMsgId}`, LOG_TYPE.INFO, LOG_MODULE.RTM)
      return
    }

    this.clearJoinTimeoutTimer()
    this.currentJoinMsgId = 0

    // 1. Set state
    this.setRoomConnState(BIZ_ROOM_STATE.BIZ_ROOM_CONNECTED)

    // if (this.roomInfo.rtcEncryption !== result.roomInfo.rtcEncryption) {
    //   this.commManager.onRoomEncryptionChanged(result.roomInfo.rtcEncryption)
    // }

    // 2. Set room info
    // Before users, because AddUser may check room_info_
    this.roomInfo = result.roomInfo

    this.roomElapsed = result.elapsedTime
    this.roomAppendMark = result.appendMark
    this.hasMoreUser = result.more

    // 3. Set room users

    // in case server join-success return users without me;
    const userMe = this.getUserMe()

    this.users.clear()

    const userList = result.users
    userList.forEach((item: BizUser) => {
      this.addUser(item)
    })

    // userMe may lost after this.users.clear()
    if (!this.getUserMe()) {
      this.addUser(userMe!)

      userList.push(userMe!)
    }

    // 4. process pending users, this usually happens when 'join-success' peer message & 'user-join' channel message synchronization
    if (!this.hasMoreUser) {
      this.unhandledUserJoinEvents.forEach((item: BizUser) => {
        if (!this.findUserByUid(item.uid)) {
          log(`onJoinSuccessResult process unhandled user join, uid: ${item.uid}`, LOG_TYPE.INFO, LOG_MODULE.RTM)

          this.addUser(item)

          userList.push(item)
        }
      })

      this.unhandledUserJoinEvents = []
    }

    // 5. notify
    this.commManager.onBizRoomStatus(this.roomInfo, userList, this.roomElapsed, this.hasMoreUser)

    if (this.isRoomCloudRecordingExists()) {
      this.commManager.onBizCloudRecordingStatus(true, this.roomInfo.recorder!, false)

      if (this.isSelfCloudRecording() && this.currentMajorStreamId !== 0) {
        log(`onJoinSuccessResult update server cloud recording major stream id: ${this.currentMajorStreamId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

        this.rtmEngine.sendUpdateLayoutRecordingCmd(this.roomInfo.recorder!.recordingId, this.currentMajorStreamId)
      }
    }

    if (result.assistant.assistantUid) {
      this.commManager.onRoomAssistantChanged(true, result.assistant, "")
      this.removeTimerType("assistant")
    } else {
      this.commManager.onRoomAssistantChanged(false, new AssistantInfo(), "")
    }
  }

  public onJoinSuccessAppendResult(bizUsers: BizUser[], appendMark: string, hasMoreUser: boolean) {
    if (this.roomAppendMark !== appendMark) {
      log(`onJoinSuccessAppendResult append mark not match, current: ${this.roomAppendMark} get: ${appendMark}`, LOG_TYPE.ERROR, LOG_MODULE.RTM)
      return
    }

    this.hasMoreUser = hasMoreUser

    if (!this.hasMoreUser) {
      this.unhandledUserJoinEvents.forEach((item: BizUser) => {
        if (!this.findUserByUid(item.uid)) {
          log(`onJoinSuccessAppendResult process unhandled user join, uid: ${item.uid}`, LOG_TYPE.INFO, LOG_MODULE.RTM)

          bizUsers.push(item)
        }
      })

      this.unhandledUserJoinEvents = []
    }

    bizUsers.forEach((item: BizUser) => {
      this.addUser(item)
    })

    this.commManager.onBizRoomUsersAppend(bizUsers, this.hasMoreUser)
  }

  private setEntersOrLeavesTimes() {
    const current = new Date().getTime()
    if (current - this.lastEntersOrLeavesTimestamp < this.USER_INTERVAL_MILLISECOND) {
      this.entersOrLeavesTimes < this.MAX_THRESHOLD && this.entersOrLeavesTimes++
    } else {
      this.entersOrLeavesTimes > this.MIN_THRESHOLD && this.entersOrLeavesTimes--
    }
    this.lastEntersOrLeavesTimestamp = current
  }


  public setIntervalMargeUserListTimer() {
    log(`rtm setIntervalMargeUserListTimer`, LOG_TYPE.INFO, LOG_MODULE.RTM)
    this.margeUserListTimer = setInterval(() => {
      this.mergeRoomUserList.forEach((userItem: { user: BizUser | string, isJoinRoom: boolean }) => {
        if (userItem.isJoinRoom) {
          this.onUserJoin(userItem.user as BizUser)
        } else {
          this.onUserLeave(userItem.user as string)
        }
      })
      this.mergeRoomUserList = []
      if (this.entersOrLeavesTimes === this.MIN_THRESHOLD || 
        new Date().getTime() - this.lastEntersOrLeavesTimestamp > this.USER_INTERVAL_MILLISECOND * this.MAX_THRESHOLD * this.MAX_THRESHOLD) {
        log(`rtm clearInterval`, LOG_TYPE.INFO, LOG_MODULE.RTM)
        clearInterval(this.margeUserListTimer)
        this.margeUserListTimer = null
      }
    }, this.USER_INTERVAL_MILLISECOND * this.MAX_THRESHOLD)
  }

  public mergeUserJoinOrLeaveEvent(userEvent: { user: BizUser | string, isJoinRoom: boolean }) {
    this.setEntersOrLeavesTimes()
    if (this.margeUserListTimer === null) {
      if (this.entersOrLeavesTimes === this.MAX_THRESHOLD) {
        this.mergeRoomUserList.push(userEvent)
        this.setIntervalMargeUserListTimer()
        return
      }
      if (userEvent.isJoinRoom) {
        this.onUserJoin(userEvent.user as BizUser)
      } else {
        this.onUserLeave(userEvent.user as string)
      }
    } else {
      this.mergeRoomUserList.push(userEvent)
    }
  }

  public onUserJoin(user: BizUser) {
    if (this.isRoomConnected() && !this.hasMoreUser) {
      this.addUser(user)

      this.commManager.onBizUserJoin(user)
    } else {
      log(`onUserJoin, room state: ${BIZ_ROOM_STATE[this.roomState]} hasMoreUser: ${this.hasMoreUser}, just push to stack`, LOG_TYPE.ERROR, LOG_MODULE.RTM)

      this.unhandledUserJoinEvents.push(user)
    }
  }

  public onUserLeave(uid: string) {
    if (this.selfUid === uid) return

    if (this.isRoomConnected() && !this.hasMoreUser) {
      const user = this.findUserByUid(uid)
      if (!user) {
        return
      }

      this.removeUser(uid)

      this.commManager.onBizUserLeave(user)
    } else {
      const pos = this.unhandledUserJoinEvents.findIndex(item => item.uid === uid)
      if (pos >= 0) {
        this.unhandledUserJoinEvents.splice(pos, 1)
      }
    }
  }

  @action
  public onChatMessage(msg: ChatMessage) {
    if (msg.sender !== this.selfUid) {
      const user = this.findUserByUid(msg.sender)

      if (user) {
        msg.senderName = user.getUserFullName(this.roomMode === ROOM_MODE.MODE_NORMAL ? 'tourist' : 'member')
        msg.portraitId = user.portraitId
      }

      msg.time = moment().format('HH:mm:ss')

      this.chatMessage.push(msg)

      this.unreadMsgCount++
    }
  }

  public onUserOperation(operation: UserOperationEx) {
    if (operation.source === this.selfUid) {
      return
    }

    const target = this.findUserByUid(operation.target)
    if (!target) {
      log(`onUserOperation find no target by ${operation.target}`, LOG_TYPE.ERROR, LOG_MODULE.RTM)
      return
    }

    const source = this.findUserByUid(operation.source)
    if (!source) {
      log(`onUserOperation find no source by ${operation.source}`, LOG_TYPE.ERROR, LOG_MODULE.RTM)
      return
    }

    const op = operation.op
    switch (op) {
      case OP_TYPE.OP_BAN:
        this.commManager.onBizUserKickOut(target, source)
        break;
      case OP_TYPE.OP_ENABLE_AUDIO:
      case OP_TYPE.OP_DISABLE_AUDIO: {
        const enable = op === OP_TYPE.OP_ENABLE_AUDIO
        if (operation.target === this.selfUid) {
          if (enable) {
            // not support
          } else {
            this.commManager.onMuteMineAudio(source, operation.seq)
          }
        } else {
          target.setAudioState(enable)
        }
        break
      }
      case OP_TYPE.OP_ENABLE_VIDEO:
      case OP_TYPE.OP_DISABLE_VIDEO: {
        const enable = op === OP_TYPE.OP_ENABLE_VIDEO
        if (operation.target === this.selfUid) {
          if (enable) {
            // not support
          } else {
            this.commManager.onMuteMineVideo(source, operation.seq)
          }
        } else {
          target.setVideoState(enable)
        }
        break
      }
      case OP_TYPE.OP_SHARE: {
        if (operation.options) {
          // tslint:disable-next-line: no-string-literal
          const shareId = operation.options['shareId']
          if (shareId) {
            const enableWatermark = operation.options['enableWatermark']
            target.setShare(true, shareId, enableWatermark)
            this.commManager.onBizUserChanged(target, UserChangedReason.REASON_SHARE)
          }
        }
        break
      }
      case OP_TYPE.OP_UNSHARE: {
        target.setShare(false, 0, false)
        this.commManager.onBizUserChanged(target, UserChangedReason.REASON_SHARE)
        break
      }
      case OP_TYPE.OP_INTERRUPT:
      case OP_TYPE.OP_RESUME: {
        target.setInterrupt(op === OP_TYPE.OP_INTERRUPT)
        this.commManager.onBizUserChanged(target, UserChangedReason.REASON_INTERRUPT)
        break
      }
      case OP_TYPE.OP_START_ISSUE_REPORT:
      case OP_TYPE.OP_END_ISSUE_REPORT: {
        target.setDumping(op === OP_TYPE.OP_START_ISSUE_REPORT)
        this.commManager.onBizUserChanged(target, UserChangedReason.REASON_DUMPING_ISSUE);
        break;
      }
      default:
        log(`onUserOperation unknown op type: ${op}`, LOG_TYPE.ERROR, LOG_MODULE.RTM)
        break
    }
  }

  public onUserRequest(request: UserRequest) {
    const source = this.findUserByUid(request.sender)
    if (!source) {
      log(`onUserRequest find no source by ${request.sender}`, LOG_TYPE.ERROR, LOG_MODULE.RTM)
      return
    }

    switch (request.opType) {
      case OP_TYPE.OP_ENABLE_AUDIO:
        this.commManager.onUnmuteMineAudio(source, request.seqId, request.reqeustId)
        break
      case OP_TYPE.OP_ENABLE_VIDEO:
        this.commManager.onUnmuteMineVideo(source, request.seqId, request.reqeustId)
        break
    }
  }

  public onMyRequestResponse(response: MyRequestResponse) {
    const target = this.findUserByUid(response.target)

    response.targetStreamId = target?.streamId || 0

    this.commManager.onMyRequestResponse(response)
  }

  public onRoomUpdate(roomInfo: BizRoomInfo) {
    this.roomInfo.updateRoomInfo(roomInfo)

    this.commManager.onBizRoomInfoChanged(roomInfo)
  }

  public onUserStartCloudRecording(uid: string, elapsedTime: number) {
    const source = this.findUserByUid(uid)

    this.userStartCloudRecording(uid, elapsedTime, "", source)
  }

  public onUserEndCloudRecording(uid: string) {
    const source = this.findUserByUid(uid)

    this.userStopCloudRecording(source)
  }

  public onUserApplyAssistant(user: any) {

    const { uid, innerName, lang, alias } = user.assistant
    const applyUserInfo = new UserApplyAssistantRequest(user.requestId, uid, lang, innerName, alias)
    const source = this.findUserByUid(uid)
    this.userApplyAssistant(applyUserInfo, source)
  }

  public onSelfApplyAssistantResponse(response: SelfApplyAssistantResponse) {
    const source = this.findUserByUid(this.selfUid)
    const handleUserRequest = this.requestCollector.find((item) => item.requestId === item.requestId)

    if (handleUserRequest || response.isSuccess) {
      this.removeTimerByRequestId(response.requestId)
      this.commManager.onSelfApplySelfApplyAssistantResponse(response, source)
    }

  }

  public onBroadcastAssistant(on: boolean, assistant: AssistantInfo, operatorUid: string) {
    this.removeTimerType("assistant")
    if (on) {
      this.commManager.onRoomAssistantChanged(true, assistant, operatorUid)
      this.assistantInfo.updateAssistantInfo(this.assistantInfo)
    } else {
      this.commManager.onRoomAssistantChanged(false, assistant, operatorUid)
      this.assistantInfo.resetAssistantInfo()
    }
  }

  public onPullLogFromAtlas() {
    uploadLog()
  }

  public onKickUserFromAtlas() {
    this.commManager.onKickUserForAtlas()
  }

  public onPeerServerReboot() {
    log(`onPeerServerReboot`, LOG_TYPE.ERROR, LOG_MODULE.RTM)

    if (!this.isInRoom()) return

    if (this.isRoomConnected()) {
      this.startConnectRoom()
    } else {
      // do nothing, already trying to connecting
    }
  }

  /************************** Public APIs: User Control **************************/

  public setLocalAudio(enable: boolean) {
    if (!this.isInRoom()) {
      return
    }

    const userMe = this.getUserMe()
    if (!userMe) {
      return
    }

    if (userMe.getAudioState() !== enable) {
      userMe.setAudioState(enable)

      if (this.isRoomConnected()) {
        log(`set local audio,is enable ${enable}, cmd:${enable ? OP_TYPE.OP_ENABLE_AUDIO : OP_TYPE.OP_DISABLE_AUDIO}`)
        this.sendControlCmd(enable ? OP_TYPE.OP_ENABLE_AUDIO : OP_TYPE.OP_DISABLE_AUDIO, this.selfUid, 0)
      } else {
        // rtm rejoin will sync to server
      }
    }
  }

  public setLocalVideo(enable: boolean) {
    if (!this.isInRoom()) {
      return
    }

    const userMe = this.getUserMe()
    if (!userMe) {
      return
    }

    if (userMe.getVideoState() !== enable) {
      userMe.setVideoState(enable)
      if (this.isRoomConnected()) {
        log(`set local video,is enable ${enable}, cmd:${enable ? OP_TYPE.OP_ENABLE_VIDEO : OP_TYPE.OP_DISABLE_VIDEO}`)
        this.sendControlCmd(enable ? OP_TYPE.OP_ENABLE_VIDEO : OP_TYPE.OP_DISABLE_VIDEO, this.selfUid, 0)
      } else {
        // rtm rejoin will sync to server
      }
    }
  }

  public async startCloudRecording(channelId: string, majorStreamId: number) {
    this.currentMajorStreamId = majorStreamId

    if (this.isRoomCloudRecordingExists()) {
      return this.isSelfCloudRecording() ? 0 : CloudRecordingError.ERR_RECORDING_ALREADY_ON
    }

    if (!this.hasRoomControlPermission()) {
      return CloudRecordingError.ERR_ONLY_HOST_CAN_START_RECORDING
    }

    if (!this.isRoomConnected()) {
      return ERR_BIZ_DISCONNECT
    }

    try {
      const response = await this.rtmEngine.sendStartRecordingCmd(this.roomInfo.rid, channelId, majorStreamId)

      const recordingId = response.recodingId// server spell error, should be 'recordingId'
      if (recordingId && recordingId.length > 0) {
        this.userStartCloudRecording(this.selfUid, 0, recordingId, this.getUserMe())
      }

      return 0
    } catch (error) {
      return error?.code || ERR_REQUEST_FAILURE
    }
  }

  public async stopCloudRecording() {
    if (!this.isRoomConnected()) {
      return ERR_BIZ_DISCONNECT
    }

    if (!this.isSelfCloudRecording()) {
      return CloudRecordingError.ERR_RECORDING_NOT_RUNNING
    }

    let code = 0
    try {
      await this.rtmEngine.sendEndRecordingCmd(this.roomInfo.recorder!.recordingId)
    } catch (error) {
      code = error?.code
    }

    if (code === 0 || code === CloudRecordingError.ERR_RECORDING_TOO_SHORT ||
      code === CloudRecordingError.ERR_RECORDING_NOT_RUNNING || code === CloudRecordingError.ERR_RECORDING_GENERATE_FILE) {
      this.userStopCloudRecording(this.getUserMe())
    }

    return code
  }

  public setCurrentMajorStreamId(streamId: number) {
    if (this.currentMajorStreamId === streamId) {
      return
    }

    this.currentMajorStreamId = streamId

    if (!this.isSelfCloudRecording()) {
      return
    }

    if (this.isRoomConnected()) {
      this.rtmEngine.sendUpdateLayoutRecordingCmd(this.roomInfo.recorder!.recordingId, streamId)
    }
  }

  public async enableRemoteVideo(uid: string, seqId: number) {
    if (!this.isRoomConnected()) {
      throw new RtmError(ERR_BIZ_DISCONNECT)
    }

    if (!uid || this.findUserByUid(uid) === undefined) {
      throw new RtmError(ERR_BIZ_USER_LOST)
    }

    const response = await this.sendRequestCmd(OP_TYPE.OP_ENABLE_VIDEO, uid, seqId)
    return response.requestId
  }

  public async disableRemoteVideo(uid: string, seqId: number) {
    if (!this.isRoomConnected()) {
      throw new RtmError(ERR_BIZ_DISCONNECT)
    }

    if (!uid || this.findUserByUid(uid) === undefined) {
      throw new RtmError(ERR_BIZ_USER_LOST)
    }

    await this.sendControlCmd(OP_TYPE.OP_DISABLE_VIDEO, uid, seqId);
  }

  public async enableRemoteAudio(uid: string, seqId: number) {
    if (!this.isRoomConnected) {
      throw new RtmError(ERR_BIZ_DISCONNECT)
    }

    if (!uid || this.findUserByUid(uid) === undefined) {
      throw new RtmError(ERR_BIZ_USER_LOST)
    }

    const response = await this.sendRequestCmd(OP_TYPE.OP_ENABLE_AUDIO, uid, seqId)
    return response.requestId
  }

  public async disableRemoteAudio(uid: string, seqId: number) {
    if (!this.isRoomConnected()) {
      throw new RtmError(ERR_BIZ_DISCONNECT)
    }

    if (!uid || this.findUserByUid(uid) === undefined) {
      throw new RtmError(ERR_BIZ_USER_LOST)
    }

    await this.sendControlCmd(OP_TYPE.OP_DISABLE_AUDIO, uid, seqId);
  }

  public async kickUser(uid: string) {
    if (!this.isRoomConnected()) {
      throw new RtmError(ERR_BIZ_DISCONNECT)
    }

    if (!uid || this.findUserByUid(uid) === undefined) {
      throw new RtmError(ERR_BIZ_USER_LOST)
    }

    await this.sendControlCmd(OP_TYPE.OP_BAN, uid, 0)
  }

  public acceptRemoteRequest(requestId: string, seqId: number) {
    return this.rtmEngine.sendRequestResponseCmd(this.roomInfo.rid, true, requestId, seqId)
  }

  public refuseRemoteRequest(requestId: string, seqId: number) {
    return this.rtmEngine.sendRequestResponseCmd(this.roomInfo.rid, false, requestId, seqId)
  }

  public async enableScreenShare(seq: number, enableWatermark: boolean) {
    if (!this.isRoomConnected()) return [seq, 0]

    try {
      const response = await this.rtmEngine.sendOperationCmd(this.roomInfo.rid, OP_TYPE.OP_SHARE, this.selfUid, 0,{
        enableWatermark : enableWatermark}
      )

      const shareId = response.shareId
      const shareMediaToken = response.mediatoken

      const userMe = this.getUserMe()

      userMe!.setShare(true, shareId, enableWatermark)

      return [seq, shareId, shareMediaToken]
    } catch (error) {
      return [seq, error?.code === 2009 ? -1 : 0]
    }
  }

  public disableScreenShare() {
    if (!this.isInRoom()) {
      return
    }

    const userMe = this.getUserMe()

    userMe!.setShare(false, 0, false)

    if (this.isRoomConnected()) {
      this.rtmEngine.sendOperationCmd(this.roomInfo.rid, OP_TYPE.OP_UNSHARE, this.selfUid, 0, {
        enableWatermark: false
      })
    } else {
      // rtm rejoin will sync to server
    }
  }

  private async sendControlCmd(op: number, target: string, seq: number) {
    return this.rtmEngine.sendOperationCmd(this.roomInfo.rid, op, target, seq)
  }

  private async sendRequestCmd(op: number, target: string, seq: number) {
    return this.rtmEngine.sendRequestCmd(this.roomInfo.rid, op, target, seq)
  }

  /************************************** Public APIs: Room Control ****************************************/

  public async enableRoomHost(enable: boolean) {
    if (!this.isRoomConnected()) {
      throw new RtmError(ERR_BIZ_DISCONNECT)
    }

    log(`${enable ? 'request' : 'give up'} room host`, LOG_TYPE.INFO, LOG_MODULE.RTM)

    await this.rtmEngine.sendRoomUpdateCmd(this.roomInfo.rid, {
      host: enable ? this.selfUid : ''
    })
  }

  public async enableRoomAudio(enable: boolean) {
    if (!this.isRoomConnected()) {
      throw new RtmError(ERR_BIZ_DISCONNECT)
    }

    log(`${enable ? 'enable' : 'disable'} room audio`, LOG_TYPE.INFO, LOG_MODULE.RTM)

    await this.rtmEngine.sendRoomUpdateCmd(this.roomInfo.rid, {
      allowAudio: enable
    })
  }

  public async enableRoomVideo(enable: boolean) {
    if (!this.isRoomConnected()) {
      throw new RtmError(ERR_BIZ_DISCONNECT)
    }

    log(`${enable ? 'enable' : 'disable'} room video`, LOG_TYPE.INFO, LOG_MODULE.RTM)

    await this.rtmEngine.sendRoomUpdateCmd(this.roomInfo.rid, {
      allowVideo: enable
    })
  }

  public async applyMeetingAssistant(apply: boolean, lang: string) {
    if (!this.isRoomConnected()) {
      throw new RtmError(ERR_BIZ_DISCONNECT)
    }
    const actionName = apply ? 'apply' : 'cancel'

    log(`${actionName} meeting assistant`, LOG_TYPE.INFO, LOG_MODULE.RTM)

     return await this.rtmEngine.sendMeetingAssistantCmd(this.roomInfo.rid, actionName, lang)
  }

  public async asyncEventDispatcher(handler: () => any, timeout: number, eventType: string, timeoutCallback: () => any) {
    try {
      const { requestId } = await handler()
      if (this.requestCollector.findIndex(item => item.requestId === requestId) === -1) {
        const timer = window.setTimeout(() => {
          timeoutCallback()
          this.removeTimerByRequestId.bind(this, requestId)
        }, timeout)
        this.requestCollector.push({
          requestId,
          type: eventType,
          timer
        })
      }
    } catch (error) {
      log(`asyncEventDispatcher ${error}`, LOG_TYPE.ERROR, LOG_MODULE.RTM)
    }
  }

  private removeTimerByRequestId(requestId: number| string) {
    const pos = this.requestCollector.findIndex(item => item.requestId === requestId)
    if (pos > -1) {
      const pending = this.requestCollector[pos]
      clearTimeout(pending.timer)
      this.requestCollector.splice(pos, 1)
      return pending
    }
  }

  private removeTimerType(type: string) {
    const pos = this.requestCollector.findIndex(item => item.type === type)
    if (pos > -1) {
      const pending = this.requestCollector[pos]
      clearTimeout(pending.timer)
      this.requestCollector.splice(pos, 1)
      return pending
    }
  }

  /************************************** Public APIs: Chat ****************************************/

  @action
  public sendChatMessage(msg: string) {
    const chat = new ChatMessage(msg, this.selfUid, true)

    if (!this.isRoomConnected()) {
      chat.time = moment().format('HH:mm:ss')
      chat.sendFailed = true
      this.chatMessage.push(chat)
    } else {
      chat.isSending = true
      chat.time = moment().format('HH:mm:ss')
      this.chatMessage.push(chat)
      const index = this.chatMessage.indexOf(chat)
      this.rtmEngine.sendChatCmd(this.roomInfo.rid, msg).then(() => {
        this.chatMessage[index].isSending = false
      }).catch((e: any) => {
        chat.sendFailed = true
      })
    }
  }

  @action
  public resendMessage(index: number) {
    if (!this.isRoomConnected()) return

    this.chatMessage[index].sendFailed = false
    this.chatMessage[index].isSending = true
    this.rtmEngine.sendChatCmd(this.roomInfo.rid, this.chatMessage[index].text).then((res: any) => {
      this.chatMessage[index].isSending = false
    }).catch((e: any) => {
      this.chatMessage[index].sendFailed = true
    })
  }

  @action
  public readAllChatMessage() {
    this.unreadMsgCount = 0
  }

  /************************************** Users ****************************************/

  private buildSelf(uid: string, audio: boolean, video: boolean, supportExPlan: boolean): BizUser {
    const user = new BizUser()
    user.uid = uid
    user.setAudioState(audio)
    user.setVideoState(video)
    user.setFeatureExPlan(supportExPlan)

    return user
  }

  private addUser(user: BizUser) {
    user.isHost = this.roomInfo.isHost(user)
    this.users.set(user.uid, user)
  }

  private removeUser(uid: string) {
    this.users.delete(uid)
  }

  /************************************** Others ****************************************/

  private userStartCloudRecording(ownerUid: string, elapsed: number, recordingId: string, owner?: BizUser) {
    if (this.roomInfo.recorder?.uid === ownerUid) {
      if (elapsed !== 0) {
        this.roomInfo.recorder.elapsedTime = elapsed
      }
      if (recordingId.length > 0) {
        this.roomInfo.recorder.recordingId = recordingId
      }
    } else {
      const recorder = new Recorder()
      recorder.uid = ownerUid
      recorder.elapsedTime = elapsed
      if (recordingId.length > 0) {
        recorder.recordingId = recordingId
      }

      if (owner) {
        recorder.innerName = owner.thirdpartyName
        recorder.alias = owner.thirdpartyAlias
      } else {
        log(`userStartCloudRecording source user null by uid: ${ownerUid}`, LOG_TYPE.ERROR, LOG_MODULE.RTM)
      }

      this.roomInfo.recorder = recorder
    }

    this.commManager.onBizCloudRecordingStatus(true, this.roomInfo.recorder, true)

    if (owner) {
      owner.setCloudRecording(true)

      this.commManager.onBizUserChanged(owner, UserChangedReason.REASON_CLOUD_RECORDING)
    }
  }

  private userStopCloudRecording(owner?: BizUser) {
    this.commManager.onBizCloudRecordingStatus(false, this.roomInfo.recorder, false)
    const recorder = this.roomInfo.recorder?.uid || ''

    this.roomInfo.recorder = undefined

    if (owner) {
      owner.setCloudRecording(false)

      this.commManager.onBizUserChanged(owner, UserChangedReason.REASON_CLOUD_RECORDING)
    } else {
      if (recorder) {
        this.commManager.onStopAssistantCloudRecording(recorder)
      }
    }
  }

  private userApplyAssistant(requestInfo: UserApplyAssistantRequest, owner?: BizUser) {
    this.commManager.onUserApplyAssistant(requestInfo, owner)
  }

  public findUserByUid(uid: string) {
    return this.users.get(uid)
  }

  public getUserMe() {
    return this.findUserByUid(this.selfUid)
  }

  public isRoomConnected(): boolean {
    return this.roomState === BIZ_ROOM_STATE.BIZ_ROOM_CONNECTED
  }

  public isInRoom(): boolean {
    return this.roomState !== BIZ_ROOM_STATE.BIZ_ROOM_DISCONNECTED
  }

  private isJoinDenied(code: number): boolean {
    return code === ERR_JOIN_WRONG_PWD || code === ERR_JOIN_WRONG_SESSION || code === ROOM_AGORA_JOIN_NO_PERMISSION || code === ERR_JOIN_INVALID_RID
  }

  public isRoomCloudRecordingExists(): boolean {
    return this.roomInfo.recorder !== undefined
  }

  public isSelfCloudRecording() {
    return this.roomInfo.recorder !== undefined && this.roomInfo.recorder.uid === this.selfUid
  }

  public isRoomHostExists(): boolean {
    return this.roomInfo.getHostUid().length > 0
  }

  public isSelfHost(): boolean {
    return this.roomInfo.getHostUid() === this.selfUid
  }

  public hasRoomControlPermission(): boolean {
    return !this.isRoomHostExists() || this.isSelfHost()
  }

}
