import { makeStyles, createStyles, Theme } from "@material-ui/core"
import { colorDefault, colorAgora, colorCommon } from '../../style/color'
import { isLessSafari14 } from '../../utils/userAgentDetector'

export interface IconSvgProps {
  fill?: string,
}

export const useStyles = makeStyles((theme: Theme) => {
  const { customColor: { tooltipBorderColor, tooltipTextColor, commonModelBackground, tooltipBoxShadow } } = theme

  return createStyles({
    labelControl: {
      '& > span.MuiIconButton-root': {
        boxShadow: 'none',
        background: 'none',
      }
    },
    form: {
      width: '18.75rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    controlBox: {
      width: '100%',
      margin: '-0.6rem 0',
    },
    control: {
      width: '100%',
      paddingeft: '0.2rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
    },
    formLabel: {
      marginBottom: '0.5rem',
      fontWeight: 600,
    },
    item: {
      // height: '4.31rem',
      marginBottom: '1rem',
      "& > div > input": {
        fontSize: '0.875rem',
        padding: '0',
        '&::placeholder': {
          fontSize: '0.75rem',
          paddingTop: isLessSafari14() && '4px',
        },
      },
    },
    root: {
      // width: '20rem',
      width:'18.75rem',
      height: '2.5rem',
      fontSize: '0.6rem',
      borderRadius: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      boxSizing:'border-box',
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '-1rem',
      marginTop: '0.1rem',
      fontSize: "0.75rem",
      transform: 'scale(0.9)',
			position: 'absolute',
      top: '2.3125rem',
      left: '0.5625rem',
    },
    button: {
      width: '18.75rem',
      height: '2.5rem',
      fontSize: '0.875rem',
      borderRadius: '0.5rem',
      marginTop: '2.75rem',
    },
    checkBox: {
      width: '0.875rem',
      height: '0.875rem',
      border: `0.0625rem solid ${colorCommon.colorInputBorderGrey}`,
      borderRadius: '0.25rem',
      boxShadow: 'inset 0 0 0 1px rgba(200,202,206,1)',
    },
    checkedBox: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '0.25rem',
      '&:before': {
        display: 'block',
        width: '0.875rem',
        height: '0.875rem',
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
    },
    checkBoxText: {
      fontSize: '0.875rem',
      transform: 'none',
      '& h6':{
        fontWeight:600,
      }
    },
    cursor: {
      cursor: 'pointer',
    },
    popper: {
      paddingLeft: '0.5rem',
    },
    tooltipPlacementRight: {
      borderColor: tooltipBorderColor,
      backgroundColor: commonModelBackground,
      borderRadius: '0.63rem',
      boxShadow: tooltipBoxShadow,
    },
    tooltipText: {
      color: tooltipTextColor,
    },
    helperTextContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    helperTextIcon: {
      marginBottom: '0.1rem',
    },
    helperTextContent: {
      marginLeft: '0.3rem',
    },
  })
});
