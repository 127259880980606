import React, { forwardRef, Ref } from "react";
import { Box, Button, Typography } from '@material-ui/core'
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useUIStore } from '../../stores/hooks'
import { ReactComponent as IconLoading } from "../../assets/icon_loading.svg";
import useStyle from "./style";
import { UPLOAD_LOG_STATE } from "../../utils/constants";

interface UploadLogViewProps {
  debugClick: () => void
}

const UploadLogView = observer(forwardRef((props: UploadLogViewProps, ref: Ref<HTMLDivElement>) => {
  const [t] = useTranslation()
  const styles = useStyle()
  const uiStore = useUIStore()

  return (
    <div className={styles.uploadLogBox} ref={ref} id="upload_logs">
      <Box className={styles.uploadText}>
        <Typography variant="h3">{t('Upload log')}</Typography>
        <Typography variant="body1" className={styles.uploadTips}>{t('Help developers analyze the problem')}</Typography>
      </Box>
      <Box id="debug-box" className={styles.debugBox} onClick={props.debugClick} />
      {uiStore.logUploadingStatus === UPLOAD_LOG_STATE.DEFAULT && (<Button id="button-upload-log" className={`${styles.safariButton}`} variant="outlined" color="secondary" onClick={uiStore.uploadLog}>{t('Upload log')}</Button>)}
      {uiStore.logUploadingStatus === UPLOAD_LOG_STATE.UPLOADING && (<Button id="button-upload-log-loading" className={`${styles.safariButton}`}  variant="outlined" color="secondary" disabled><IconLoading className={styles.loading} /></Button>)}
      {uiStore.logUploadingStatus === UPLOAD_LOG_STATE.SUCCESS && (<Button id="button-upload-log-success" className={`${styles.buttonSuccess} ${styles.safariButton}`} variant="outlined" color="secondary" disabled>{t('Upload success')}</Button>)}
      {uiStore.logUploadingStatus === UPLOAD_LOG_STATE.FAILURE && (<Button id="button-upload-log-failure" className={`${styles.buttonFailed} ${styles.safariButton}`} variant="outlined" color="secondary" disabled>{t('Upload failure')}</Button>)}
    </div>
  )
}))

export default UploadLogView
