import { userRequest, HTTP_FEEDBACBK } from "../stores/connector/http"
import { isSafari, isChrome, isFirefox } from './userAgentDetector';
import { localStorage as storage } from './Storage';
import { VersionContents } from "./helper"
import { reporter } from "../utils/Reporter"
import { formatDailyTime } from "./DailyTime";
import { BI_REPORT_EVENT } from "./constants";

export enum LOG_TYPE {
  VERBOSE,
  INFO,
  WARNING,
  ERROR,
  COMM,
}

export enum LOG_MODULE {
  DEFAULT,
  RTC,
  RTM,
  COMM,
  UI,
  SHARE,
  HTTP,
  MODEL,
  QUALITY,
}

// const logQueue: string[] = [];

const MAX_LOG_SIZE = 2000
const DAY_TIME_MILLISECONDS = 1000 * 60 * 60 * 24
const PROPERTY_TS = 'ts'

const DB_NAME = 'agoravideocall-db';
const DB_VERSION = 1;
const DB_STORE_NAME = 'log';

let db: any

export function openDb() {
  if (db) return

  console.log("openDb...")

  var req = indexedDB.open(DB_NAME, DB_VERSION);
  req.onsuccess = function (evt) {
    // Equal to: db = req.result;
    console.log(`${(new Date()).toISOString()} openDb success, remove old logs...`)

    let database = this.result

    try {
      let store = database.transaction(DB_STORE_NAME, 'readwrite').objectStore(DB_STORE_NAME)
      let index = store.index(PROPERTY_TS)
      let cursorReq = index.openCursor()
      const oldest = (+new Date()) - DAY_TIME_MILLISECONDS

      let deleteCount = 0
      cursorReq.onsuccess = function (evt: any) {
        let cursor = evt.target.result;

        if (cursor && cursor.key < oldest) {
          deleteCount++
          cursor.delete()
          cursor.continue()
        } else {
          console.log(`${(new Date()).toISOString()} deleteCount: `, deleteCount);
          onDbOpened(database)
        }
      }

      cursorReq.onerror = function (evt: any) {
        console.error("db prepare, store get cursor error: ", evt.target.errorCode)

        let store = database.transaction(DB_STORE_NAME, 'readwrite').objectStore(DB_STORE_NAME)
        store.clear()
      }
    } catch (error) {
      console.error(`db prepare exception`, error)
    }
  };

  req.onerror = function (evt: any) {
    console.error("openDb error:", evt.target.errorCode);
  };

  req.onupgradeneeded = function (evt: any) {
    console.log("openDb.onupgradeneeded...");

    let objStore = evt.currentTarget.result.createObjectStore(
      DB_STORE_NAME, { keyPath: 'id', autoIncrement: true })

    try {
      objStore.createIndex(PROPERTY_TS, PROPERTY_TS, { unique: false });
    } catch (error) {
      console.error(`object store createIndex exception`, error)
    }
  };

  req.onblocked = function (evt: any) {
    console.log("openDb.onblocked...");
  }
}

function onDbOpened(database: any) {
  console.log(`${(new Date()).toISOString()} onDbOpened ...`);

  db = database

  db.onversionchange = function (evt: any) {
    console.log("db onversionchange ...");

    db.close()
    db = undefined
  }

  if (tempLogQueue.length > 0) {
    addLogs(tempLogQueue)
    tempLogQueue = []
  }
}

function getObjectStore(store_name: string, mode: string) {
  if (!db) return undefined

  try {
    var tx = db.transaction(store_name, mode);
    return tx.objectStore(store_name);
  } catch (error) {
    console.error(`get object store error: `, error)
    db = undefined
    return undefined
  }
}

// function clearObjectStore() {
//   var store = getObjectStore(DB_STORE_NAME, 'readwrite')
//   if (!store) return

//   var req = store.clear();
//   req.onsuccess = function (evt: any) {
//     console.error("db store cleard:", evt.target.errorCode);
//   };
//   req.onerror = function (evt: any) {
//     console.error("db store clear error:", evt.target.errorCode);
//   };
// }

export function getLogs(): Promise<string[]> {
  const p = new Promise<string[]>((resolve, reject) => {
    var store = getObjectStore(DB_STORE_NAME, 'readonly')
    if (!store) {
      resolve([])
      return
    }

    var i = 0
    var logs: string[] = [];
    var req = store.openCursor();

    req.onsuccess = function (evt: any) {
      var cursor = evt.target.result;

      // If the cursor is pointing at something, ask for the data
      if (cursor) {
        req = store.get(cursor.key);
        req.onsuccess = function (evt: any) {
          var value = evt.target.result
          if (value.content) {
            logs.push(value.content)
          }
        };

        // Move on to the next object in store
        cursor.continue();

        // This counter serves only to create distinct ids
        i++;
      } else {
        console.log(`No more entries, read logs success count: ${i}`)
        resolve(logs)
      }
    };

    req.onerror = function () {
      console.error("openCursor error", this.error);
      reject(this.error)
    };
  })

  return p
}

let tempLogQueue: string[] = [];

function addTempLog(log: string) {
  tempLogQueue.push(log)

  if (tempLogQueue.length > MAX_LOG_SIZE + 100) {
    tempLogQueue.slice(0, 100)
  }
}

function addLog(log: string) {
  if (!db) {
    addTempLog(log)
    return
  }

  var store = getObjectStore(DB_STORE_NAME, 'readwrite');
  var req;
  try {
    req = store.add({
      content: log,
      ts: +new Date()
    });
  } catch (e) {
    console.error("insert db exception", e)
    return
  }

  req.onsuccess = function (evt: any) {
  };
  req.onerror = function () {
    console.error("insert db error", this.error);
  };
}

function addLogs(logs: string[]) {
  console.log(`addLogs length: ${logs.length}`)
  if (!db) {
    console.log(`db not ready`)
    return
  }

  var store = getObjectStore(DB_STORE_NAME, 'readwrite');
  try {
    logs.forEach(item => {
      store.add({
        content: item,
        ts: +new Date()
      });
    })
  } catch (e) {
    console.error("insert db exception", e)
    return
  }
}

export function logIf(condition: boolean, text: string, logType: LOG_TYPE = LOG_TYPE.INFO, module: LOG_MODULE = LOG_MODULE.DEFAULT) {
  if (condition) {
    log(text, logType, module)
  }
}

export function log(text: string, logType: LOG_TYPE = LOG_TYPE.INFO, module: LOG_MODULE = LOG_MODULE.DEFAULT): void {
  let currentTime = formatDailyTime()
  // let str = `${(new Date).toISOString()} [AVC-${LOG_TYPE[logType]}]: [${LOG_MODULE[module]}] ${text}`
  let str = `${currentTime} [${LOG_TYPE[logType]}][${LOG_MODULE[module]}] AVC ${text}`

  if (logType === LOG_TYPE.ERROR) {
    console.error(str)
  } else {
    console.log(str)
  }

  addLog(str)
}

export function logException(text: string, e: Error, module: LOG_MODULE = LOG_MODULE.DEFAULT) {
  let str = `${formatDailyTime()} [${LOG_TYPE[LOG_TYPE.ERROR]}][${LOG_MODULE[module]}] AVC ${text} ${e && e.message} ${e && e.stack}`
  // let str = `${(new Date).toISOString()} [${LOG_TYPE.ERROR}]: [${LOG_MODULE[module]}] ${text} ${e && e.message} ${e && e.stack}`

  console.error(str, e)

  addLog(str)
}

const getUserAgent = () => {
  return isSafari()
    ? 'safari'
    : isChrome()
      ? 'chrome'
      : isFirefox()
        ? 'firefox'
        : navigator.userAgent;
}

export async function formatLogs() {
  let logQueue: string[]
  try {
    logQueue = await getLogs()
    if (logQueue.length === 0) {
      logQueue = tempLogQueue
    }
  } catch (error) {
    throw error
  }

  if (logQueue.length > MAX_LOG_SIZE) {
    logQueue = logQueue.slice(logQueue.length - MAX_LOG_SIZE)
  }
  const trunk = logQueue.join("\n")
  return trunk;
}
export async function uploadLog() {
  const trunk = await formatLogs()
  const ua = getUserAgent()
  let streamId = -1;
  const userInfo = storage.read('userState')
  if (userInfo) {
    streamId = userInfo.streamId
  }

  await reporter.promiseDecorate(userRequest.post(`${HTTP_FEEDBACBK}`, {
    streamId,
    content: `[streamId: ${streamId}, name: ${userInfo.name} ]\n${trunk}`,
    identifier: `avc ${VersionContents[0]}, streamID: ${streamId}, index: 0`,
    os: ua,
    platform: 'web',
    appVersion: `${VersionContents[0]}`,
  }), BI_REPORT_EVENT.UPLOAD_LOG)
}
