import { action, computed, observable } from "mobx";
import { AssistantInfo } from "./assistantInfo";
import { BizRoomInfo } from "./bizRoomInfo";
import { ROOM_MODE, APPLY_ASSISTANT_LANG, ENCRYPTION_MODE } from "../utils/constants";
import { Biz } from "./metadata";

export class RoomInfo {
  @observable
  public roomId: string = ''

  @observable
  public topic: string = ''

  @observable
  public audioState: boolean = true
  @observable
  public videoState: boolean = true

  @observable
  public hasPwd: boolean = false

  @observable
  public roomKey: string = ''

  @observable
  public roomPwd: string = ''

  @observable
  public rtcEncryption: boolean = false

  @observable
  public rtcSecretKey: string = ''

  @observable
  public salt: Uint8Array | undefined = undefined;

  @observable
  public mediaToken: string | null = null

  @observable
  public shareMediaToken: string | null = null

  @observable
  public shareId: string | null = null

  @observable
  public sharerId: string | null = null

  @observable
  public hostUid: string = ''

  @observable
  public hostName: string = ''

  @observable
  public timestamp: number = 0

  @observable
  public createTime: string = ''

  @observable
  public serverPeerId: string = ''

  @observable
  public assistantUid: string = ''

  @observable
  public assistantName: string = ''

  public selfUid: string = ''
  public selfStreamId: number = 0
  public channelName: string = ''
  public rtcToken: string = ''
  public roomToken: string = ''
  public encryptionMode: ENCRYPTION_MODE = ENCRYPTION_MODE.NONE

  @observable
  public bizInit: boolean = false

  @observable
  public mediaInit: boolean = false

  private showMode: 'tourist' | 'member'

  @computed
  public get isMeHost() {
    return this.hostUid === this.selfUid
  }

  @computed
  public get hasHost() {
    return this.hostUid.length > 0
  }

  constructor(mode: ROOM_MODE) {
    this.showMode = mode === ROOM_MODE.MODE_NORMAL ? 'tourist' : 'member'
  }

  @action
  public updateBizRoomInfo(bizRoomInfo: BizRoomInfo) {
    this.roomPwd = bizRoomInfo.pwd
    this.audioState = bizRoomInfo.getAudioState()
    this.videoState = bizRoomInfo.getVideoState()
    this.createTime = bizRoomInfo.createTime
    this.hostUid = bizRoomInfo.getHostUid()
    this.hostName = bizRoomInfo.getHostName(this.showMode)
    this.timestamp = bizRoomInfo.timestamp
    this.serverPeerId = bizRoomInfo.serverPeerId
    this.bizInit = true
  }

  @action
  public updateBizRoomInfoLocal(bizRoomInfo: BizRoomInfo) {
    this.audioState = bizRoomInfo.getAudioState()
    this.videoState = bizRoomInfo.getVideoState()
    this.hostUid = bizRoomInfo.getHostUid()
    this.hostName = bizRoomInfo.getHostName(this.showMode)
  }

  @action
  public updateMediaRoomInfo(mediaRoomInfo: Biz) {
    this.hostUid = mediaRoomInfo.hostUid || ''
    if (this.hostUid.length === 0) {
      this.hostName = ''
    }

    if (mediaRoomInfo.roomAudio !== undefined) {
      this.audioState = mediaRoomInfo.roomAudio
    }

    if (mediaRoomInfo.roomVideo !== undefined) {
      this.videoState = mediaRoomInfo.roomVideo
    }

    if (mediaRoomInfo.rtmServerPeerId) {
      this.serverPeerId = mediaRoomInfo.rtmServerPeerId
    }

    if (mediaRoomInfo.timestamp) {
      this.timestamp = mediaRoomInfo.timestamp
    }

    this.mediaInit = true
  }

  @action
  public updateRoomAudio(audioState: boolean) {
    this.audioState = audioState
  }

  @action
  public updateRoomVideo(videoState: boolean) {
    this.videoState = videoState
  }

  public isValid() {
    return this.bizInit || this.mediaInit
  }

}

export class PendingRequest {
  @observable
  requestId?: string
  @observable
  seq?: number
  @observable
  targetUid?: string
  @observable
  isTargetMe: boolean = false
  @observable
  isTargetAudio: boolean = false
  @observable
  sourceUidList: string[] = []
  @observable
  requestIdList: string[] = []
  @observable
  seqList: number[] = []
  @observable
  timer?: number

  sourceNameList: string[] = []
}