import axios from 'axios'
import { BI_REPORT_EVENT, ERR_JOIN_INVALID_RID, ERR_JOIN_WRONG_PWD, ERR_JOIN_WRONG_SESSION, ERR_TOKEN_500, ERR_TOKEN_EXPIRED_OR_OTHER, ROOM_AGORA_JOIN_NO_PERMISSION, ROOM_TOKEN_EXPIRED, ROOM_TOKEN_INVALID } from "../../utils/constants"
import { log, LOG_MODULE, LOG_TYPE } from '../../utils/Log'
import { reporter } from "../../utils/Reporter"
import { localStorage as storage } from '../../utils/Storage'
import { encode } from "../../utils/urlsafe-base64"
import { getDeviceName, getPlatform } from '../../utils/userAgentDetector'

const HTTP_FEEDBACBK = "/v1/account/generalLog"

const roomRequest = axios.create({
  baseURL:
    process.env.REACT_APP_ENV !== 'production'
      ? process.env.REACT_APP_ROOM_SERVICE_TEST_URL
      : process.env.REACT_APP_ROOM_SERVICE_URL,
  headers: {
    access_token: storage.read('jwt')
  },
  timeout: 10000
});

const userRequest = axios.create({
  baseURL:
    process.env.REACT_APP_ENV !== 'production'
      ? process.env.REACT_APP_USER_SERVICE_TEST_URL
      : process.env.REACT_APP_USER_SERVICE_URL,
  headers: {
    access_token: storage.read('jwt')
  },
  timeout: 10000
});

const createBaseRequest=(postData:any)=>{
 const defaultBaseURL =  process.env.REACT_APP_ENV !== 'production'
 ? process.env.REACT_APP_USER_SERVICE_TEST_URL
 : process.env.REACT_APP_USER_SERVICE_URL
 const baseURL = postData.baseURL ||defaultBaseURL;
  const axiosSettingData = {
    timeout: 10000,
    ...postData,
    headers: {
      access_token: storage.read('jwt'),
      ...postData.headers
    },
    url: baseURL + postData.url
  }
  return axios(axiosSettingData)
}

const handleRequestMiddleware = (request: any) => {
  log(`${request.url} request`, LOG_TYPE.COMM, LOG_MODULE.HTTP)

  return request
}

const handleSuccessMiddleware = (response: any) => {
  const url = response?.config?.url || ""
  if (url.includes('roomInfo')) {
    const data = {
      ...response.data,
      rtc: {
        ...response.data.rtc,
        token: '*******',
        key: '*******',
        salt:'*******',
      }
    }
    log(`${url} success, \n${JSON.stringify(data)}`, LOG_TYPE.COMM, LOG_MODULE.HTTP)
  } else {
    log(`${url} success, \n${JSON.stringify(response.data)}`, LOG_TYPE.COMM, LOG_MODULE.HTTP)
  }

  return response
}

const handleErrorMiddleware = (err: any) => {
  let url = err?.config?.url || ""

  const code = err?.response?.data?.code || err?.response?.status || err.code
  const desc = err?.response?.data?.errorMsg || err?.response?.statusText || err.message
  log(`${url} failure, code: ${code} msg: ${desc}`, LOG_TYPE.ERROR, LOG_MODULE.HTTP)

  if (isTokenError(err) && url.indexOf(HTTP_FEEDBACBK) < 0) {
    // 上传日志接口不检查token错误
    log(`Authentication Error ${err.response.status}`, LOG_TYPE.ERROR, LOG_MODULE.HTTP)

    clearAccessToken()

    // avoid refresh page when in meeting
    // getRtmToken/updateNickname（失败也可以进入房间）/getServerId 这三个都无需要刷新页面, getRtcToken 出现异常，重进首页，触发token重新获取
    // https://jira.agoralab.co/browse/APP-2098

    // setTimeout(() => {
    //   window.location.reload()
    //   window.location.hash = ''
    // }, 1500);
  }

  return Promise.reject({
    code,
    desc
  });
}

userRequest.interceptors.request.use(handleRequestMiddleware)
roomRequest.interceptors.request.use(handleRequestMiddleware)
userRequest.interceptors.response.use(handleSuccessMiddleware, handleErrorMiddleware)
roomRequest.interceptors.response.use(handleSuccessMiddleware, handleErrorMiddleware)

const updateAccessToken = (jwt: string) => {
  roomRequest.defaults.headers.access_token = jwt
  userRequest.defaults.headers.access_token = jwt
  storage.save('jwt', jwt)
};

const clearAccessToken = () => {
  storage.clear('jwt')
};

const getAccessToken = () => {
  return storage.read('jwt')
};

const setRequestMaxTimeout = (ms: number) => {
  roomRequest.defaults.timeout = ms
  userRequest.defaults.timeout = ms
}

const getToken = async (name?: string) => {
  return reporter.promiseDecorate(
    userRequest.post('/v1/account/getToken', {
      platform: getPlatform(),
      deviceName: getDeviceName(),
      channel: 0,
      device: 0,
      name
    }), BI_REPORT_EVENT.REQUEST_HTTP, 'account/getToken')
}

const getRtmToken = async () => {
  return reporter.promiseDecorate(
    roomRequest.get('/v1/account/getRtmToken'),
    BI_REPORT_EVENT.REQUEST_HTTP, 'v1/account/getRtmToken')
}

const updateNickname = async (name: string = '') => {
  return reporter.promiseDecorate(
    userRequest.post('/v1/account/update', { name }),
    BI_REPORT_EVENT.REQUEST_HTTP, 'account/update')
}

const sendQualityFeedback = async (data: any) => {
  return reporter.promiseDecorate(
    userRequest.post('v1/account/generalReport', { ...data }),
    BI_REPORT_EVENT.REQUEST_HTTP, 'v1/account/generalReport')
}
const sendIssueFeedback = async (data: any) => {
  const formData = new FormData();
  for (let key in data) {
    formData.append(key,data[key]);
  }
  formData.append('platform', getPlatform());
  formData.append('deviceName', getDeviceName());
  const postData = {
    headers: { 'Content-Type': 'multipart/form-data' },
    url:'/v1/account/generalIssue',
    access_token: storage.read('jwt'),
    method:'POST',
    data:formData,
  }
  return reporter.promiseDecorate(
    createBaseRequest(postData),
    BI_REPORT_EVENT.REQUEST_HTTP, 'v1/account/generalIssue')
}

const getServerId = async (roomId: string, pwd: string, roomToken: string = '') => {
  return reporter.promiseDecorate(
    userRequest.post('/v1/account/roomInfo', { rid: roomId, pwd, roomToken, }),
    BI_REPORT_EVENT.REQUEST_HTTP, 'v1/account/roomInfo')
}

const getRtcToken = async (channelName: string, streamId?: number) => {
  return reporter.promiseDecorate(
    roomRequest.get(`/v1/account/getRtcToken?channelName=${encode(new Buffer(channelName))}${streamId !== undefined ? (`&streamId=${streamId}`) : ''}`),
    BI_REPORT_EVENT.REQUEST_HTTP, 'v1/account/getRtcToken')
}

const getConfig = async () => {
  return reporter.promiseDecorate(
    roomRequest.get('v1/app-config', {
      params: {
        type: 6,
        platform: 'web',
        version: process.env.REACT_APP_APP_VERSION,
        view: 'all'
      }
    }),
    BI_REPORT_EVENT.REQUEST_HTTP, 'v1/config')
}

const loginByWeChatWork = async (code: string) => {
  log(`login by wechat work, code: ${code}`, LOG_TYPE.COMM, LOG_MODULE.HTTP)

  return reporter.promiseDecorate(
    userRequest.post('v1/account/innerSession', {
      code,
      platform: getPlatform(),
      deviceName: getDeviceName(),
      source: 1,// 1 for wx, 2 for email
    }),
    BI_REPORT_EVENT.REQUEST_HTTP, 'v1/account/innerSession')
}

const logoutByWeChatWork = async (session: string) => {
  log(`logout by wechat work`, LOG_TYPE.COMM, LOG_MODULE.HTTP)

  return reporter.promiseDecorate(
    userRequest.delete('v1/account/innerSession', {
      data: {
        innerSession: session
      }
    }),
    BI_REPORT_EVENT.REQUEST_HTTP, 'v1/account/innerSession')
}

const checkWeChatLoginStatus = async (session: string) => {
  log(`check wechat work login status`, LOG_TYPE.COMM, LOG_MODULE.HTTP)

  return reporter.promiseDecorate(
    userRequest.post('v1/account/verifySession', {
      innerSession: session
    }), BI_REPORT_EVENT.REQUEST_HTTP, 'v1/account/verifySession')
}

const loginByWeChatWorkForTest = async (user: any) => {
  log(`login by wechat work for QA`, LOG_TYPE.COMM, LOG_MODULE.HTTP)

  return reporter.promiseDecorate(
    userRequest.post('v1/account/autoQA', user),
    BI_REPORT_EVENT.REQUEST_HTTP, 'v1/account/autoQA')
}

const getAgoraRoomShareLinkId = async (rid: string, token: string) => {
  log(`get agora room share linkId`, LOG_TYPE.COMM, LOG_MODULE.HTTP)

  return reporter.promiseDecorate(
    userRequest.get(`v1/account/linkId?rid=${rid}`, {
      headers: {
        token
      }
    }), BI_REPORT_EVENT.REQUEST_HTTP, 'v1/account/linkId')
}

const getAgoraRoomShareInfoByLinkId = async (linkId: string) => {
  log(`get agora room share info by linkId: ${linkId}`, LOG_TYPE.COMM, LOG_MODULE.HTTP)

  return reporter.promiseDecorate(
    userRequest.get(`v1/account/innerMeetingInfo?linkId=${linkId}`),
    BI_REPORT_EVENT.REQUEST_HTTP, 'v1/account/innerMeetingInfo')
}

const isTokenError = (err: any) => {
  const code = err?.response !== undefined ? err.response.status : err.code
  return code === ERR_TOKEN_EXPIRED_OR_OTHER || code === ERR_TOKEN_500
}

const isRoomReject = (code: number) => {
  return code === ERR_JOIN_WRONG_PWD || code === ERR_JOIN_INVALID_RID || code === ERR_JOIN_WRONG_SESSION || code === ROOM_AGORA_JOIN_NO_PERMISSION
    || code === ROOM_TOKEN_EXPIRED || code === ROOM_TOKEN_INVALID
}

export {
  roomRequest,
  userRequest,
  updateAccessToken,
  getAccessToken,
  clearAccessToken,
  setRequestMaxTimeout,
  getRtmToken,
  updateNickname,
  sendQualityFeedback,
  getServerId,
  getRtcToken,
  getConfig,
  getToken,
  loginByWeChatWork,
  logoutByWeChatWork,
  loginByWeChatWorkForTest,
  checkWeChatLoginStatus,
  getAgoraRoomShareLinkId,
  getAgoraRoomShareInfoByLinkId,
  isTokenError,
  isRoomReject,
  sendIssueFeedback,
  HTTP_FEEDBACBK
}
