import { VideoEncoderConfigurationPreset } from "agora-rtc-sdk-ng";
import { action, computed, observable, values } from "mobx";
import { v4 } from 'uuid';
import { MIN_CONTROL_TIMEOUT, MIN_REQUEST_TIMEOUT, User } from "../types/user";
import { UserPreference } from "../types/userPreference";
import { ROOM_MODE, ROOM_MODE_AGORA_START } from "../utils/constants";
import { log, LOG_MODULE, LOG_TYPE } from "../utils/Log";
import { reporter } from "../utils/Reporter";
import { sessionStorage as storage} from '../utils/Storage';
import { RtcEngineLayerStore } from "./rtc/rtcEngineLayer";

const ProfileMap = {
  0: '480p_1',
  50: '720p_5',
  100: '1080p_1',
}

const ProfileValueMap = {
  '480p_1': 0,
  '720p_5': 50,
  '1080p_1': 100
}

export const VideoProfileLabelMap = {
  '480p_1': 'Low',
  '720p_5': 'Medium',
  '1080p_1': 'High',
}

export class UserStore {
  @observable
  public user: User = new User();

  public preference: UserPreference = new UserPreference();
  private preferenceChanged = false

  @observable
  public selectedMicrophoneId: string = ''

  // Why not save selectedCameraId, because we can not verify last selectedCameraId still exist, create local track using unexisted devices will throw exception
  // Verify last selectedCameraId needs refresh devices list, needs permission, and enable local video goes after refresh devices list. this is too complex.
  @observable
  public selectedCameraId: string = ''

  private rtcStore: RtcEngineLayerStore

  constructor(rtcStore: RtcEngineLayerStore) {
    this.rtcStore = rtcStore

    // old version videoProfile may have changed
    if (ProfileValueMap[this.user.videoProfile] === undefined) {
      this.user.videoProfile = '720p_5'
    }

    this.tempVideoProfile = this.user.videoProfile

    if (!this.user.uuid) {
      this.user.uuid = v4().replace(/-/g, '')
      this.user.updateLocalData()
    }

    if (this.user.uid) {
      reporter.setSid(this.user.uuid)
      reporter.setUid(this.user.uid)
    }
  }

  @computed
  public get MESSAGE_TIMEOUT() {
    return this.user.maxMessageTimeout
  }

  @computed
  public get REQUEST_TIMEOUT() {
    return this.user.maxRequestTimeout
  }

  @computed
  public get CONTROL_TIMEOUT() {
    return this.user.maxControlTimeout
  }

  @computed
  public get UserValues() {
    return values(this.user)
  }

  public refreshAudioDevices() {
    this.rtcStore.refreshAudioDevices()
  }
  public refreshVideoDevices() {
    this.rtcStore.refreshVideoDevices()
  }

  @computed
  public get cameraId() {
    log(`compute camera id, current track label: ${this.rtcStore.currentCameraTrackLabel}`, LOG_TYPE.INFO, LOG_MODULE.UI)
    if (this.rtcStore.currentCameraTrackLabel) {
      let current = this.rtcStore.cameraDeviceList.find((item) => {
        return item.label === this.rtcStore.currentCameraTrackLabel
      })

      if (current) {
        log(`compute camera id, use current track : ${current.deviceId}`, LOG_TYPE.INFO, LOG_MODULE.UI)
        return current.deviceId
      }
    }

    if (this.selectedCameraId) {
      let current = this.rtcStore.cameraDeviceList.find((item) => {
        return item.deviceId === this.selectedCameraId
      })

      if (current) {
        log(`compute camera id, use last selected: ${current.deviceId}`, LOG_TYPE.INFO, LOG_MODULE.UI)
        return current.deviceId
      }
    }

    const newDeviceId = this.rtcStore.cameraDeviceList.length > 0 ? this.rtcStore.cameraDeviceList[0].deviceId : ''
    log(`compute camera id, choose new selected: ${newDeviceId}`, LOG_TYPE.INFO, LOG_MODULE.UI)

    return newDeviceId
  }

  @computed
  public get micId() {
    if (this.rtcStore.currentMicrophoneTrackLabel) {
      let current = this.rtcStore.micDeviceList.find((item) => {
        return item.label === this.rtcStore.currentMicrophoneTrackLabel
      })

      if (current) {
        log(`compute microphone id, use current track : ${current.deviceId}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
        return current.deviceId
      }
    }

    if (this.selectedMicrophoneId) {
      let current = this.rtcStore.micDeviceList.find(item => {
        return item.deviceId === this.selectedMicrophoneId
      })

      if (current) {
        log(`compute microphone id, use last selected: ${current.deviceId}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
        return current.deviceId
      }
    }

    const newDeviceId = this.rtcStore.micDeviceList.length > 0 ? this.rtcStore.micDeviceList[0].deviceId : ''
    log(`compute microphone id, choose new selected: ${newDeviceId}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    return newDeviceId
  }

  @action
  public setCameraId(cameraId: string) {
    log(`user select device, type: camera, device name: ${this.rtcStore.currentCameraTrackLabel}, device id: ${this.rtcStore.getSelectedCameraId()}`, LOG_TYPE.COMM, LOG_MODULE.UI)

    this.selectedCameraId = cameraId// selectedCameraId 没有写入 userStore， 如果要记录，则这里逻辑需要全盘梳理

    this.rtcStore.setCameraId(this.selectedCameraId)
  }

  @action
  public setMicrophoneId(micId: string) {
    log(`user select device, type: microphone, device name: ${this.rtcStore.currentMicrophoneTrackLabel},
     device id: ${this.rtcStore.getSelectedMicrophoneId()}`, LOG_TYPE.COMM, LOG_MODULE.UI)

    this.selectedMicrophoneId = micId

    this.rtcStore.setMicrophoneId(this.selectedMicrophoneId)
  }

  @computed
  public get IsCameraReady() {
    return this.rtcStore.cameraDeviceList.length > 0
  }

  @computed
  public get IsMicrophoneReady() {
    return this.rtcStore.micDeviceList.length > 0
  }

  // Why use tempVideoProfile, becase video resolution in meeting is different than outside setting
  @observable
  public tempVideoProfile: VideoEncoderConfigurationPreset

  @computed
  public get videoProfileLabel() {
    let value = this.rtcStore.isInChannel ? VideoProfileLabelMap[this.tempVideoProfile] : VideoProfileLabelMap[this.user.videoProfile]
    // log(`video label value: ${value} ${this.rtcStore.isInChannel} ${this.tempVideoProfile} ${this.user.videoProfile}`, LOG_TYPE.ERROR, LOG_MODULE.UI)
    return value
  }

  @computed
  public get videoProfileValue() {
    let value = this.rtcStore.isInChannel ? ProfileValueMap[this.tempVideoProfile] : ProfileValueMap[this.user.videoProfile]
    // log(`video value: ${value} ${this.rtcStore.isInChannel} ${this.tempVideoProfile} ${this.user.videoProfile}`, LOG_TYPE.ERROR, LOG_MODULE.UI)
    return value
  }

  @action
  public setVideoProfile(value: number) {
    log(`set video profile ${value}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    this.tempVideoProfile = ProfileMap[value]

    if (this.rtcStore.isInChannel) {
      this.rtcStore.setVideoResolution(ProfileMap[value])
    } else {
      this.user.videoProfile = ProfileMap[value]

      this.user.updateLocalData()
    }
    log(`user select resolution: ${ProfileMap[value]}`, LOG_TYPE.COMM, LOG_MODULE.UI)
  }

  public hasLowerResolution() {
    return ProfileValueMap[this.tempVideoProfile] > 0
  }

  public lowerResolutionLabel() {
    const value = ProfileValueMap[this.tempVideoProfile] - 50
    log(`user action quality tip  lowerResolutionLabel ,
    this.tempVideoProfile :${this.tempVideoProfile},
    ProfileValueMap:${ProfileValueMap[this.tempVideoProfile]}
    value: ${value}`)

    if (value < 0) return ""

    const resolution = ProfileMap[value]

    return VideoProfileLabelMap[resolution]
  }

  public lowerResolution() {
    const value = ProfileValueMap[this.tempVideoProfile] - 50
    if (value < 0) {
      log(`has none lower resolution, current: ${ProfileValueMap[this.tempVideoProfile]}`, LOG_TYPE.ERROR, LOG_MODULE.UI)
      return false
    }

    this.setVideoProfile(value)
    return true
  }

  @action
  public updateGdprAccepted(gdprAccepted: boolean): void {
    this.user.gdprAccepted = gdprAccepted
    this.updateUser({ gdprAccepted })
  }

  @action
  public updateRtcEncryption(encryption: boolean) {
    if (this.user.rtcEncryption !== encryption) {
      log(`updateRtcEncryption: ${encryption}`, LOG_TYPE.INFO, LOG_MODULE.UI)

      this.user.rtcEncryption = encryption
      this.user.updateLocalData()
    }
  }

  @action
  public updateWaterMarkConfig(isShowWatermark: boolean) {
    if (this.user.isShowWatermark !== isShowWatermark) {
      log(`updateWaterMarkConfig: ${isShowWatermark}`, LOG_TYPE.INFO, LOG_MODULE.UI)

      this.user.isShowWatermark = isShowWatermark
      this.user.updateLocalData()
    }
  }

  @action
  public updateConfig(rtcEncryption: boolean, controlTimeout: number, requestTimeout: number, isShowWatermark: boolean) {
    this.updateRtcEncryption(rtcEncryption)
    this.updateWaterMarkConfig(isShowWatermark)

    if (this.user.maxControlTimeout !== controlTimeout || this.user.maxRequestTimeout !== requestTimeout) {
      if (controlTimeout >= MIN_CONTROL_TIMEOUT) {
        this.user.maxControlTimeout = controlTimeout
      }

      if (requestTimeout >= MIN_REQUEST_TIMEOUT) {
        this.user.maxRequestTimeout = requestTimeout
      }

      this.user.updateLocalData()
    }
  }

  @observable
  public lastRoomId: string = ''
  @observable
  public lastRoomPwd: string = ''
  @observable
  public lastRoomMode: ROOM_MODE = ROOM_MODE.MODE_NORMAL
  private lastRoomToken: string = ''

  public getLastRoomShowId(): string {
    if (this.lastRoomId.startsWith(ROOM_MODE_AGORA_START)) {
      return this.lastRoomId.slice(ROOM_MODE_AGORA_START.length)
    }
    return this.lastRoomId
  }

  @action
  public setMeetingRecord(rid: string, pwd: string, mode: ROOM_MODE) {
    log(`set meeting record ${rid} ${pwd} ${ROOM_MODE[mode]}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    this.lastRoomId = rid
    this.lastRoomPwd = pwd
    this.lastRoomMode = mode

    storage.save('meeting', {
      rid,
      pwd,
      mode,
    })
  }

  @action
  public setMeetingInfo(rid: string, pwd: string) {
    log(`set meeting info ${rid} ${pwd}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    this.lastRoomId = rid
    this.lastRoomPwd = pwd
  }

  public getMeetingRecord() {
    const meeting = storage.read('meeting')
    if (meeting) {
      this.lastRoomId = meeting.rid
      this.lastRoomPwd = meeting.pwd
      this.lastRoomMode = meeting.mode
      this.lastRoomToken = meeting.roomToken
    }
    return meeting
  }

  public updateChannelNameCache(roomToken: string) {
    this.lastRoomToken = roomToken
    this.updateMeetingStorage({
      roomToken
    })

  }

  private updateMeetingStorage(data: { [key: string]: any }) {
    const currentMeetingStorage = storage.read('meeting')
    const newMeetingStorage = {
      ...currentMeetingStorage,
      ...data
    }
    storage.save('meeting', newMeetingStorage)
  }

  @action
  public clearMeetingRecord() {
    storage.clear('meeting')

    this.lastRoomId = ''
    this.lastRoomPwd = ''
    this.lastRoomMode = ROOM_MODE.MODE_NORMAL
    this.lastRoomToken = ''
  }

  public getUid() {
    return this.user.uid
  }

  public getStreamId() {
    return this.user.streamId
  }

  public getName() {
    return this.user.name
  }

  public getDefalutAudio() {
    return this.user.enableAudio
  }

  public getDefaultVideo() {
    return this.user.enableVideo
  }

  public getRtcEncryption() {
    return this.user.rtcEncryption
  }

  public getWaterMarkConfig() {
    return this.user.isShowWatermark
  }

  public getMessageTimeout() {
    return this.user.maxMessageTimeout
  }

  @action
  public updateLocalUser({ uid, name, streamId }: { uid: string, name: string, streamId: number }) {
    this.user.uid = uid
    this.user.name = name
    this.user.streamId = streamId

    this.user.updateLocalData();

    if (this.user.uid) {
      reporter.setSid(this.user.uuid)
      reporter.setUid(this.user.uid)
    }
  }

  @action
  public updateUser(user: any) {
    if (!user) return
    Object.keys(user).forEach(key => {
      this.user[key] = user[key]
    })

    this.user.updateLocalData()
  }

  public shouldShowShareTip(): boolean {
    return !this.preference.shareTip
  }

  public setShareTipShowed() {
    this.preference.shareTip = true
    this.preference.updateLocalData()
  }

  public setChatPanelOpenState(open: boolean) {
    if (this.preference.chatOpen !== open) {
      this.preference.chatOpen = open
      this.preferenceChanged = true
    }
  }

  public setParticipantsOpen() {
    this.preference.isParticipantsOpen = !this.preference.isParticipantsOpen
  }

  public resetDrawerPanelStatus(){
    this.preference.isParticipantsOpen = false
    this.preference.chatOpen = false
  }

  public saveLastRoomPreference() {
    if (this.preferenceChanged) {
      this.preference.updateLocalData()
      this.preferenceChanged = false
    }
  }

  /********************************************* Third Party Login *************************************/

  @computed
  public get isThirdPartyLoggedIn() {
    return this.user.thirdPartyUser !== undefined && this.user.thirdPartyUser.session
  }

  public isThirdPartyFirstLogin(): boolean {
    return !this.preference.loginTip
  }

  public setThirdPartyTipShowed() {
    this.preference.loginTip = true
    this.preference.updateLocalData()
  }

  @action
  public updateWeChatWorkUser(isInit: boolean, user: any) {
    log(`updateWeChatWorkUser ${JSON.stringify(user)}`, LOG_TYPE.INFO, LOG_MODULE.HTTP)

    const changed = this.user.updateWeChatWorkUserInfo(isInit, user)

    if (changed) {
      this.user.updateLocalData()
    }
  }

  @computed
  public get thirdPartyUserName() {
    return this.user.thirdPartyUserName
  }

  public clearWeChatWorkUser() {
    this.user.clearWeChatWorkUserInfo()

    this.user.updateLocalData()
  }
}
