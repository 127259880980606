import { observer } from "mobx-react";
import React from "react";
import { useUIStore } from "../stores/hooks";
import CustomDialog from "./Alert/CustomDialog";


interface RequestLoginDialogProps {
  openSetting: () => void
}

const RequestLoginDialog = observer((props: RequestLoginDialogProps) => {
  const uiStore = useUIStore()

  const abort = () => {
    uiStore.setRequestLogin(false)
  }

  const next = () => {
    uiStore.setRequestLogin(false)

    props.openSetting()
  }

  return (
    <CustomDialog open={uiStore.requestLogin} onClose={abort} onCancel={abort} onOK={next} title={'RequestLoginTitle'} content={'AgoraRoomNoPermission'} confirmText={'GotoLogin'} />
  )
})

export default RequestLoginDialog
