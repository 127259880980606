
import { Button, Drawer, IconButton, Typography, Box, TextField } from '@material-ui/core';
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import { ReactComponent as IconIssueMore } from "../../assets/icon_issue_more.svg";
import { ReactComponent as IconSend } from "../../assets/icon_send.svg";
import { ReactComponent as IconLoading } from '../../assets/icon_loading.svg'
import { ReactComponent as IconClose } from '../../assets/icon_drawer_panel_close.svg'
import { useCommManagerStore, useRtmCommLayerStore, useUIStore } from "../../stores/hooks";
import { color } from '../../style/color';
import { getShareRidPwd, isAgoraShare, isShareLink } from "../../utils/Scheme";
import { isSafari } from "../../utils/userAgentDetector";
import { execCopy } from '../../utils/helper';
import { ATLAS_RECORDING_URL } from '../../utils/constants';
import { useStyles } from './style';
import CloseIcon from '@material-ui/icons/Close';
import { CustomAlert } from "../CustomPopup/customAlert";

export interface IChatPanelProps {
  open: boolean
  toggleChatPanel: () => void,
  isParticipantsOnDisplay: boolean,
}

const DrawerChatPanel = observer((props: IChatPanelProps) => {
  const styles = useStyles()
  const [t] = useTranslation()
  const rtmCommLayer = useRtmCommLayerStore()
  const commManager = useCommManagerStore()
  const uiStore = useUIStore()

  const [chatValue, setChatValue] = useState('')
  const [listCount, setListCount] = useState(0)
  const [scrollCount, setScrollCount] = useState(0)
  const [jumpRoomUrl, setJumpRoomUrl] = useState('')
  const [jumpSameRoomOpen, setJumpSameRoomOpen] = useState(false)

  const container = useRef<HTMLDivElement>(null);
  const inputNode = useRef<HTMLElement>(null);

  useEffect(() => {
    if (props.open) {
      rtmCommLayer.readAllChatMessage()
      // if (uiStore.showChatFocus) {
      //   // inputNode.current?.focus()
      //   uiStore.showChatFocus = false
      // }
      const node = container.current
      if (!node) return
      if (node.scrollHeight - node.scrollTop >= node.clientHeight) {
        node.scrollTop = node.scrollHeight;
      }
    }
  }, [props.open, props.isParticipantsOnDisplay])

  useEffect(() => {
    // avoid input affects list scroll
    if (props.open) {
      rtmCommLayer.readAllChatMessage()
    }
    if (listCount !== rtmCommLayer.chatMessage.length) {
      const node = container.current
      if (!node) return
      setListCount(rtmCommLayer.chatMessage.length)
      if (scrollCount === 0 && node.scrollHeight - node.scrollTop >= node.clientHeight) {
        node.scrollTop = node.scrollHeight;
        setScrollCount(0)
      }
    }

  }, [rtmCommLayer.chatMessage.length])

  const handleChange = (value: string) => {
    if (isSafari() && value.length > 1 && value[value.length - 1] === '\n' && value[value.length - 2] === '\n') {
      // tslint:disable-next-line: no-parameter-reassignment
      value = value.slice(0, value.length - 1)
    }

    setChatValue(value)
  }

  const sendMessage = () => {
    const trimValue = chatValue.trimRight()// avoid trimLeft remove "Codes copy first indent"
    if (!trimValue) {
      setTimeout(() => {
        setChatValue('')// set timeout to reset cursor position
      })
      return
    }

    setScrollCount(0)// user input must scroll to buttom

    rtmCommLayer.sendChatMessage(trimValue)

    setTimeout(() => {
      setChatValue('')// set timeout to reset cursor position
    })
  }

  const insertChar = (e: any, ch: string) => {
    const start = e.target.selectionStart
    const end = e.target.selectionEnd
    setChatValue(chatValue.slice(0, start) + ch + chatValue.slice(end))
    setTimeout(() => {
      const node = inputNode.current as any
      if (!node) return

      node.selectionStart = node.selectionEnd = start + 1

      node.blur()
      node.focus()// scroll to textarea position
    })
  }

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 9) {// tab
      e.preventDefault()
      insertChar(e, '\t')
    } else if (e.keyCode === 13 && (e.altKey || e.ctrlKey || e.metaKey)) { // enter
      insertChar(e, '\n')
    } else if (e.keyCode === 13) {
      sendMessage()
    }
  }

  const resend = (index: number) => {
    rtmCommLayer.resendMessage(index)
  }

  const onScroll = () => {
    const node = container.current
    if (!node) return

    if (node.scrollHeight - node.scrollTop > node.clientHeight) {
      // user want to see old msg
      setScrollCount(previousCount => previousCount + 1)
    } else {
      setScrollCount(0)
    }
  }

  const onLinkClick = (e: any, href: string) => {
    if (isShareLink(href)) {
      const { rid } = getShareRidPwd(href)
      if (!rid) return

      e.preventDefault()

      if (rid === commManager.roomInfo.roomId) {
        setJumpSameRoomOpen(true)
        return;
      }

      if (commManager.localCloudRecordingIconState) {
        execCopy(ATLAS_RECORDING_URL, 'on recording leave and join agora room copy success')
      }

      setJumpRoomUrl(href)
    }
  }

  const componentDecoratorMe = (href: any, text: any, key: any) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer" onClick={(e) => { onLinkClick(e, href) }} className={styles.linkMe}>
      {text}
    </a>
  )

  const componentDecoratorOther = (href: any, text: any, key: any) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer" onClick={(e) => { onLinkClick(e, href) }} className={styles.linkOther}>
      {text}
    </a>
  )

  const onJumpRoom = () => {
    const href = jumpRoomUrl
    setJumpRoomUrl('')
    window.location.href = href
  }

  const getAlertContent = (content: string, param?: Map<string, string>) => {
    if (param && param.size > 0) {
      return t(content, Object.fromEntries(param))
    }
    return t(content)
  }

  return (
    <Drawer className={styles.drawer} anchor={props.isParticipantsOnDisplay ? "bottom" : "right"} variant="persistent"
      open={props.open}
      classes={{
        paper: `${props.isParticipantsOnDisplay ? styles.halfScreen : styles.drawerPaper}`,
      }}>
      <div className={styles.container}>
        <Box className={styles.header}>
          <Typography variant="h2">{t('Chat')}</Typography>
          <IconButton className={styles.close} id="close-chat" onClick={() => props.toggleChatPanel()}>
            <IconClose className={styles.iconClose} style={{ fontSize: '1rem' }} />
          </IconButton>
        </Box>
        <div className={`${styles.content} notranslate`} ref={container} onScroll={onScroll}>
          {rtmCommLayer.chatMessage.map((message, index) => (
            <div key={index} className={styles.messageContent}>
              <div className={`${message.isMine ? styles.messageLineMe : styles.messageLineOther} notranslate`}>
                {message.sendFailed && <IconIssueMore className={styles.failIcon} id="resend-message"  fill={color.colorError} onClick={() => resend(index)} />}
                {message.isMine && message.isSending && !message.sendFailed && <IconLoading className={styles.loading} />}
                <span className={message.isMine ? styles.messageMe : styles.messageOther}>
                  {!message.isMine &&
                    <div className={styles.name}>
                      {message.senderName}
                  </div>
                  }
                  <Linkify componentDecorator={message.isMine ? componentDecoratorMe : componentDecoratorOther}>{message.text}</Linkify>
                </span>
              </div>
              <div className={message.isMine ? styles.timeMe : styles.timeOther}>
                <span className={styles.timeMsg}>{message.time}</span>
                {/* {message.sendFailed && <span id="resend-message" className={styles.resend} onClick={() => resend(index)}>{t('Resend')}</span>} */}
              </div>
            </div>
          ))}
        </div>
        <Box className={styles.chatBox}>
          {/* Safari/Firefox only support onKeyDown */}
          <TextField
            id="standard-multiline-flexible"
            inputRef={inputNode}
            multiline
            classes={{ root: styles.chatInput }}
            className={styles.inputMessage}
            rowsMax={3}
            value={chatValue}
            size="small"
            placeholder={t('InputHint')}
            onKeyDown={handleKeyPress}
            variant="outlined"
            onChange={e => handleChange(e.target.value)}
          />
          <IconSend id="send-message" className={styles.send} color="primary" onClick={() => sendMessage()} />
          {/* <Button id="send-message" className={styles.send} color="primary" variant="text" onClick={() => sendMessage()}>{t('Send')}</Button> */}
        </Box>
      </div>
      <CustomAlert
        isNeedPosition={false}
        isOpen={jumpSameRoomOpen}
        isAlert={true}
        content={t('AlreadyInRoom')}
        buttonGroups={
          <Box className={styles.buttonGroups}>
            <Button variant="contained" color="primary" className={styles.confirmButton} onClick={() => setJumpSameRoomOpen(false)}>{t('OK')}</Button>
          </Box>
        }
      />
      {jumpRoomUrl && <CustomAlert
        isAlert={true}
        isOpen={jumpRoomUrl.length > 0}
        title={getAlertContent(commManager.localCloudRecordingIconState ? isAgoraShare(jumpRoomUrl) ? "ConfirmCloudRecordingLeaveAndJoinNewAgora" : "ConfirmCloudRecordingLeaveAndJoinNew" : isAgoraShare(jumpRoomUrl) ? 'ConfirmLeaveAndJoinNewAgora' : 'ConfirmLeaveAndJoinNew', new Map([['name', getShareRidPwd(jumpRoomUrl)?.rid || '']]))}
        content={commManager.localCloudRecordingIconState ? t('ConfirmCloudRecordingLeaveAndJoinTips') : ''}
        onCancel={() => setJumpRoomUrl('')}
        onClose={() => setJumpRoomUrl('')}
        onConfirm={onJumpRoom}
      />}
    </Drawer>
  )
})

export default DrawerChatPanel
