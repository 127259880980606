import moment from 'moment';

export function formatDailyTime() {
    // get ISO local time
    // replace milliseconds '.' to ':'
    let currentTime = moment().toISOString(true).replace('.', ':')

    // get currentTime and currentZone
    // replace zone  '.' to ':'
    let newTime = currentTime.slice(0, -6)
    let oldZone = currentTime.slice(-6)
    let newZone = oldZone.split('').filter(x => x !== ':').join('')

    let dailyTime = `${newTime} ${newZone}`
    return dailyTime
}