import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Typography, makeStyles, } from "@material-ui/core";
import i18n from 'i18next';
import { observer } from "mobx-react";
import React from "react";
import { useUIStore } from "../../stores/hooks"
import { ReactComponent as Logo } from "../../assets/icon_logo.svg"
import { useStyles } from "./style"
import { isChinese } from "../../utils/helper"

export interface TrackStatsDialogProps {
  open: boolean;
  onOk: () => void;
}

const GDPRTermsView = observer((props) => {
  const classes = useStyles()
  const uiStore = useUIStore()

  return (
    <div>
      <Dialog
        classes={{
          paperWidthMd: classes.paperWidthMd,
        }}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        // z index higher than tooltip
        style={{ zIndex: 3000 }}
        open={props.open}>
        {/* keep dialog title alive to stay an padding in dialog */}
        {/* keep this space for no errors */}
        <DialogTitle> </DialogTitle>
        <DialogContent className={classes.dialogContent}>{
          i18n.language !== 'zh-CN' ?
            <div className={classes.content}>
              <div className={classes.contentTitle}>
                <Logo width="40px" height="40px" fill={uiStore.modePrimaryColor} />
                <Typography style={{ paddingLeft: '0.5rem' }} variant="h1">Agora Privacy And Terms of Service</Typography>
              </div>
              <Typography variant="subtitle2">AgoraVideoCall (this "Product") is a test product provided by Agora. This Product is available for our current and potential customers only, and for them to test the functionality and quality of this Product. This Product is provided neither for commercial nor for public use. Agora enjoys the copyright and ownership of this product. You shall not modify, consolidate, compile, adjust, reverse engineer, sub-license, transfer, sell this Product or infringe the legitimate interests of Agora in any way.</Typography>
              <Typography variant="subtitle2">If you’d like to test this Product, you’re welcome to download, install and use it. Agora hereby grants you a world-wide and royalty-free license to use this Product. This product is provided "as is" without any express or implicit warranty, including but not limited to guarantees of suitability, suitability for specific purposes, and non-infringement. Whether it is due to any contract, infringement or other forms of conduct related to this Product or the use of this Product or otherwise, Agora shall not be liable for any claims, damages or liabilities arising out of or related to your use of this Product. It’s your freedom to choose to test this Product or not. But if you decide to do so, and if you download, install, or use this Product, it means that you confirm and agree that under no circumstances shall Agora be liable for any form of loss or injury caused to yourself or others when you use this Product for any reason, in any manner.</Typography>
              <Typography variant="subtitle2">If you have any query, please feel free to contact support@agora.io.</Typography>

              <Typography variant="h2"> Agora Privacy Policy</Typography>
              <Typography variant="h2">Introduction</Typography>
              <Typography variant="subtitle2">Agora Lab, Inc. (together with our parent companies, subsidiaries and affiliates, “ Agora,” “we,” or “us”) values the privacy of everyone who uses our websites (such as www.agora.io, collectively the “Site”), as well as all related web sites, events (career applications, recruitment activities, events and surveys), networks, embeddable widgets, downloadable software (including any development kits and any associated tools, also known as SDKs), mobile applications, beta products (including, without limitation, AgoraVideoCall etc.), recording, whiteboard, cloud player, aPaaS services and other services that link to this Privacy Policy (collectively, together with the Site, our “Service”).</Typography>
              <Typography variant="subtitle2">This Privacy Policy (“Policy”) describes how we collect and use Personal Information from or about you through the Service we provide and in connection with our customer, partner and vendor relationships, your rights and choices with respect to your Personal Information, and how you can contact us if you have any questions or concerns. For the purpose of this Policy, “Personal Information” means any information relating to an identified or identifiable individual and does not include data whereby personally identifiable information has been removed (such as anonymous data).  In addition to the activities described in this Policy, we may process Personal Information provided by our enterprise customers (“Enterprise Customers”) when they use our Service. We process such Personal Information as a data processor on behalf of and following instructions from our Enterprise Customers, which are the entities responsible for the data processing.  To understand how an Enterprise Customer processes your Personal Information, please refer to that Enterprise Customer’s privacy policy.  If you are interested in what Personal Information we process when providing services to our Enterprise Customers, please refer to the <a href="https://www.agora.io/en/agora-processor-privacy-statement/">Agora Processor Privacy Statement for more information.</a></Typography>
              <Typography variant="subtitle2">Please read this Policy carefully. If you do not agree with this Policy or any part thereof, you should not access or use any part of the Service, or otherwise provide us with your Personal Information. If you change your mind in the future, you should stop using the Service and provide us with no further Personal Information.</Typography>

              <Typography variant="subtitle2">Contents of this Policy</Typography>
              <Typography variant="subtitle2">This Policy outlines the following topics:</Typography>
              <Typography variant="subtitle2">1.	<a href="https://www.agora.io/en/privacy-policy/#personal-information">Personal Information We Collect and How We Use It</a></Typography>
              <Typography variant="subtitle2">2.	<a href="https://www.agora.io/en/privacy-policy/#legal-basis">Our Legal Basis for Processing European Personal Information</a></Typography>
              <Typography variant="subtitle2">3.	<a href="https://www.agora.io/en/privacy-policy/#how-we-disclose">How We Disclose or Share Your Personal Information</a></Typography>
              <Typography variant="subtitle2">4.	<a href="https://www.agora.io/en/privacy-policy/#your-rights">Your Rights and Choices</a></Typography>
              <Typography variant="subtitle2">5.	<a href="https://www.agora.io/en/privacy-policy/#third-party-services">Third-Party Services</a></Typography>
              <Typography variant="subtitle2">6.	<a href="https://www.agora.io/en/privacy-policy/#children-privacy">Children’s Privacy</a></Typography>
              <Typography variant="subtitle2">7.	<a href="https://www.agora.io/en/privacy-policy/#data-security">Data Security</a></Typography>
              <Typography variant="subtitle2">8.	<a href="https://www.agora.io/en/privacy-policy/#data-transfers">Data Transfers</a></Typography>
              <Typography variant="subtitle2">9.	<a href="https://www.agora.io/en/privacy-policy/#data-retention">Data Retention</a></Typography>
              <Typography variant="subtitle2">10.	<a href="https://www.agora.io/en/privacy-policy/#changes-and-updates">Changes and Updates to This Policy</a></Typography>
              <Typography variant="subtitle2">11.	<a href="https://www.agora.io/en/privacy-policy/#contact-us">Contact Us</a></Typography>

              <Typography variant="h2">1. Personal Information We Collect and How We Use It</Typography>
              <Typography variant="subtitle2">We obtain Personal Information relating to you from various sources described below. Where applicable, we indicate whether and why you must provide us with your Personal Information, as well as the consequences of failing to do so. If you do not provide Personal Information when requested, you may not be able to benefit from our Service if that information is necessary to provide you with the Service, or if we are legally required to collect it.</Typography>
              <Typography variant="subtitle2">Account Registration</Typography>
              <Typography variant="subtitle2">Personal Information Collected from You</Typography>
              <Typography variant="subtitle2">If you register to use the Service, then you must provide us with:</Typography>
              <Typography variant="subtitle2">– Your name</Typography>
              <Typography variant="subtitle2">– Email address</Typography>
              <Typography variant="subtitle2">– A password to create an account and user profile</Typography>
              <Typography variant="subtitle2">– Optional information, such as a photo of yourself</Typography>

              <Typography variant="subtitle2">How We Use It</Typography>
              <Typography variant="subtitle2">– Create a user account for the use of our services</Typography>
              <Typography variant="subtitle2">– Display user ID and avatars to other users with whom you interact</Typography>
              <Typography variant="subtitle2">– Provide marketing or promotions, should you desire</Typography>
              <Typography variant="subtitle2">– Provide you with the latest news and updates on our applications</Typography>
              <Typography variant="subtitle2">– Provide you with support, respond to your requests and complaints,  and otherwise communicate with you</Typography>
              <Typography variant="subtitle2">– Invite you to participate in surveys and events</Typography>

              <Typography variant="subtitle2">Communication and Support</Typography>
              <Typography variant="subtitle2">Personal Information Collected from You</Typography>
              <Typography variant="subtitle2">When you communicate with us via the Service, or you send us a request, application, claim, report or complaint, we collect Personal Information, such as:</Typography>
              <Typography variant="subtitle2">– Your name</Typography>
              <Typography variant="subtitle2">– Email address</Typography>
              <Typography variant="subtitle2">– Physical address, if applicable</Typography>
              <Typography variant="subtitle2">– IP Address</Typography>
              <Typography variant="subtitle2">– Phone number</Typography>
              <Typography variant="subtitle2">– Contents and nature of your correspondence with us, including technical support requests, meeting (virtual or in-person, public or private) requests, competition requests, complaints, queries and feedback on your use of the Service</Typography>

              <Typography variant="subtitle2">Further, we provide you with the option of using our chatbot or similar tools to communicate with us.</Typography>
              <Typography variant="subtitle2">If you choose to interact with such tools, you agree that we will process your Personal Information provided by you during your interaction with such tools in accordance with this Policy, the Personal Information of which may include your account preferences, IP address, and the nature of any issues you want to resolve which you enter into the chatbot or similar tools.</Typography>

              <Typography variant="subtitle2">How We Use It</Typography>
              <Typography variant="subtitle2">– Provide you with support, respond to your requests and complaints, and otherwise communicate with you</Typography>
              <Typography variant="subtitle2">– Provide you with the information you request, and refine the products to meet your feedback</Typography>
              <Typography variant="subtitle2">– Analyze your request, application, claim, report or complaint and improve the quality of our products if necessary</Typography>
              <Typography variant="subtitle2">– Understand your preferences and support you with necessary products or features</Typography>
              <Typography variant="subtitle2">Cookie Information</Typography>
              <Typography variant="subtitle2">Personal Information Collected from You</Typography>
              <Typography variant="subtitle2">We will automatically collect information about your visit to our Site and use of our service via cookies. The information collected will typically include Personal Information, such as</Typography>
              <Typography variant="subtitle2">– Your IP address</Typography>
              <Typography variant="subtitle2">– Advertising identifiers</Typography>
              <Typography variant="subtitle2">For detailed information about the Cookies and similar technologies we use in our Site, please visit our <a href="https://www.agora.io/en/cookie-policy/">Cookie Policy</a>.</Typography>
              <Typography variant="subtitle2">How We Use It</Typography>
              <Typography variant="subtitle2">– Analyze how our Site is used to generate a better experience</Typography>
              <Typography variant="subtitle2">– Remember your settings and preferences</Typography>
              <Typography variant="subtitle2">– Personalize our service and provide customized advertisements, content, and information</Typography>
              <Typography variant="subtitle2">– Analyze our marketing activities and improve them</Typography>
              <Typography variant="subtitle2">– Provide our Service and support</Typography>
              <Typography variant="subtitle2">– Other uses stated in our <a href="https://www.agora.io/en/cookie-policy/">Cookie Policy</a></Typography>
              <Typography variant="subtitle2">Job Applications</Typography>
              <Typography variant="subtitle2">Personal Information Collected from You</Typography>
              <Typography variant="subtitle2">We will collect any information you choose to provide to us when you apply for a job with Agora, including your resume and references.</Typography>
              <Typography variant="subtitle2">How We Use It</Typography>
              <Typography variant="subtitle2">– Process your job application</Typography>
              <Typography variant="subtitle2">– Communicate with you</Typography>
              <Typography variant="subtitle2">Events and Surveys</Typography>
              <Typography variant="subtitle2">Personal Information Collected from You</Typography>
              <Typography variant="subtitle2">We will collect information about you when you attend an event (e.g., our RTC/RTE Innovation Challenge or annual RTE Meeting, virtual format or in-person), or fill out a form or survey with us. Such information includes:</Typography>
              <Typography variant="subtitle2">– Your name</Typography>
              <Typography variant="subtitle2">– Email address</Typography>
              <Typography variant="subtitle2">– IP address</Typography>
              <Typography variant="subtitle2">– Phone number</Typography>
              <Typography variant="subtitle2">– Zip/post code</Typography>
              <Typography variant="subtitle2">If you do not object to recording of the meetings/events (for meetings/events in the European Economic Area (“EEA”) or the United Kingdom (“UK”), we or third parties on our behalf , will ask you for your explicit consent before such recording), we will typically collect your voice, images, photographs, or video shared or captured with or on behalf of us during the events/meetings, including, without limitation, the content of media:</Typography>
              <Typography variant="subtitle2">– Subjects captured</Typography>
              <Typography variant="subtitle2">– Associated content, voice, likeness, image, or statements</Typography>
              <Typography variant="subtitle2">– Metadata information</Typography>
              <Typography variant="subtitle2">We might also collect your age, gender, or other Personal Information; however, we will only collect those with your explicit consent.</Typography>
              <Typography variant="subtitle2">How We Use It</Typography>
              <Typography variant="subtitle2">– Analyze how our Site is used and generate a better experience</Typography>
              <Typography variant="subtitle2">– Send marketing or promotions</Typography>
              <Typography variant="subtitle2">– Provide you with the latest news and updates about our Service</Typography>
              <Typography variant="subtitle2">– Invite you to provide your feedback</Typography>
              <Typography variant="subtitle2">– Invite you to events and send gifts or rewards</Typography>
              <Typography variant="subtitle2">– Promote and market the events</Typography>
              <Typography variant="subtitle2">– Share features and results of an event to its participants</Typography>
              <Typography variant="subtitle2">– Promote interaction and engagement among participants</Typography>
              <Typography variant="subtitle2">Payment Information</Typography>
              <Typography variant="subtitle2">Personal Information Collected from You</Typography>
              <Typography variant="subtitle2">We will collect payment-related information for the purpose of creating your paid account, and process your payment to provide you with our paid services, including your:</Typography>
              <Typography variant="subtitle2">– Billing name</Typography>
              <Typography variant="subtitle2">– Billing phone</Typography>
              <Typography variant="subtitle2">– Billing address</Typography>
              <Typography variant="subtitle2">– Payment method</Typography>
              <Typography variant="subtitle2">– Company name (if applicable)</Typography>
              <Typography variant="subtitle2">You can provide us with this information through your account dashboard or directly in our Enterprise Customer agreement.</Typography>
              <Typography variant="subtitle2">How We Use It</Typography>
              <Typography variant="subtitle2">– Create a paid account</Typography>
              <Typography variant="subtitle2">– Provide our paid account services</Typography>
              <Typography variant="subtitle2">– Provide support and respond to requests</Typography>
              <Typography variant="subtitle2">– Process your payment</Typography>
              <Typography variant="subtitle2">– Communicate with you</Typography>
              <Typography variant="subtitle2">Information from Enterprise Customers</Typography>
              <Typography variant="subtitle2">Personal Information Obtained from Third Parties</Typography>
              <Typography variant="subtitle2">If you are using the Service as an end user of an Enterprise Customer, we may process Personal Information about you provided by our Enterprise Customers who use the Service with their own end users. Please refer to Agora Processor Privacy Statement for more information about what Personal Information we process.</Typography>
              <Typography variant="subtitle2">How We Use It</Typography>
              <Typography variant="subtitle2">– Provide you with the Service</Typography>
              <Typography variant="subtitle2">– Other uses as stated in our <a href="https://www.agora.io/en//agora-processor-privacy-statement/">Processor Privacy Statement</a></Typography>
              <Typography variant="subtitle2">Information from Other Third Parties</Typography>
              <Typography variant="subtitle2">Personal Information Obtained from Third Parties</Typography>
              <Typography variant="subtitle2">We may obtain information, including Personal Information from third parties and sources other than from our Service. If we combine or associate information from other sources with Personal Information that we collect through our Service, we will treat the combined information as Personal Information in accordance with this Policy.</Typography>
              <Typography variant="subtitle2">How We Use It</Typography>
              <Typography variant="subtitle2">– Provide you with the Service</Typography>
              <Typography variant="subtitle2">– Contact you and provide you with the support you requested</Typography>
              <Typography variant="subtitle2">– Invite you to our events, and present you each feature of our events, surveys, and other activities</Typography>
              <Typography variant="subtitle2">– Provide direct marketing to you</Typography>
              <Typography variant="subtitle2">– Otherwise communicate with you</Typography>
              <Typography variant="subtitle2">Notwithstanding the above, we may use your Personal Information to enforce our End User License Agreement and Terms of Service, to defend our legal rights, to conduct audits, to comply with our legal obligations and internal policies, to contact you for administrative purposes such as to address intellectual property infringement, privacy violations or defamation issues related to user content posted on the Service. Where we do so, we will use the Personal Information relevant to such a case. Some processing may also be necessary to comply with a legal obligation placed on us.</Typography>
              <Typography variant="h2">2. Our Legal Basis for Processing European Personal Information</Typography>
              <Typography variant="subtitle2">If you are located in the European Economic Area or the United Kingdom, we only process your Personal Information based on a valid legal basis, including when:</Typography>
              <Typography variant="subtitle2">	•	You have consented to the use of your Personal Information;</Typography>
              <Typography variant="subtitle2">	•	We need your Personal Information to provide you with the Service, including for account registration and to respond to your inquiries;</Typography>
              <Typography variant="subtitle2">	•	We have a legal obligation to use your Personal Information; or</Typography>
              <Typography variant="subtitle2">	•	We, or a third party, have a legitimate interest in using your Personal Information. In particular, we have a legitimate interest in using your Personal Information to conduct business analytics and direct marketing, and otherwise improve the safety, security, and performance of our Service. We only rely on our, or a third party’s, legitimate interests to process your Personal Information when these interests are not overridden by your rights and interests.</Typography>
              <Typography variant="subtitle2">3. How We Disclose or Share Your Personal Information</Typography>
              <Typography variant="subtitle2">Agora Affiliates and Subsidiaries</Typography>
              <Typography variant="subtitle2">We will typically disclose Personal Information about you within our Agora affiliates and subsidiaries in a secure manner for the purpose of providing our Service or communicating with you.</Typography>
              <Typography variant="subtitle2">Agora Users</Typography>
              <Typography variant="subtitle2">Any Personal Information that you choose to include on or through the Service, including information in a publicly accessible area of the Service (such as a public profile page) or information you share with or allow to be shared with other users, will be available to anyone who has access to that content, including other users. We are not responsible for privacy practices of the other users who will view and use the posted information.</Typography>
              <Typography variant="subtitle2">Agora’s Partners, Vendors and Service Providers</Typography>
              <Typography variant="subtitle2">We work with third party service providers to provide website or application development, hosting, maintenance, and other services for us. These third parties may have access to or process your Personal Information as part of providing those services for us. We limit the information provided to these service providers to that which is necessary for them to perform their functions, and we require them to agree to maintain the confidentiality of such information. Such third parties may typically include storage vendors, internet carriers, payment processing partners, survey firms and virtual or in-person event organizers.</Typography>
              <Typography variant="subtitle2">For more information about third parties and partners we use or work with to provide you with a better experience and service, please refer to our <a href="https://www.agora.io/en/cookie-policy/">Cookie Policy</a>.</Typography>
              <Typography variant="subtitle2">Advertising Partners</Typography>
              <Typography variant="subtitle2">We may share your Personal Information with our third-party advertising partners to provide ads that we think may interest you. For more information about our advertising and marketing practices and those of the third-party advertising partners, please see our <a href="https://www.agora.io/en/cookie-policy/">Cookie Policy.</a></Typography>
              <Typography variant="subtitle2">Other Third Parties</Typography>
              <Typography variant="subtitle2">We may also share Personal Information about you with other third parties in the following circumstances:</Typography>
              <Typography variant="subtitle2">	•	In Aggregated Form. We may make certain automatically-collected, aggregated, or otherwise de-identified information available to third parties for various purposes, including (i) compliance with various reporting obligations; (ii) for business or marketing purposes; or (iii) to assist such parties in understanding our users’ interests, habits, and usage patterns for certain programs, content, services, advertisements, promotions, and/or functionality available through the Service.</Typography>
              <Typography variant="subtitle2">	•	To Comply with Legal Obligations. We may disclose your information if required to do so by law or in the good-faith belief that such action is necessary to comply with state and federal laws (such as U.S. copyright law), in response to a court order, judicial or other government subpoena or warrant, or to otherwise cooperate with law enforcement or other governmental agencies.</Typography>
              <Typography variant="subtitle2">	•	To Protect and Enforce Our Rights. We also reserve the right to disclose your information that we believe, in good faith, is appropriate or necessary to (i) take precautions against liability, (ii) protect ourselves or others from fraudulent, abusive, or unlawful uses or activity, (iii) investigate and defend ourselves against any third-party claims or allegations, (iv) protect the security or integrity of the Service and any facilities or equipment used to make the Service available, or (v) protect our property or other legal rights (including, but not limited to, enforcement of our agreements), or the rights, property, or safety of others.</Typography>
              <Typography variant="subtitle2">	•	In case of Merger, Sale, or Other Asset Transfer. Information about our users, including Personal Information, may be disclosed and otherwise transferred to an acquirer, or successor or assignee as part of any merger, acquisition, debt financing, sale of assets, or similar transaction, as well as in the event of an insolvency, bankruptcy, or receivership in which information is transferred to one or more third parties as one of our business assets.</Typography>
              <Typography variant="subtitle2">	•	With Your Consent. We also may disclose your Personal Information as may be described in a notice to you at the time the information is collected or before it is shared, or in any other manner to which you consent.</Typography>
              <Typography variant="h2">4. Your Rights and Choices</Typography>
              <Typography variant="subtitle2">Account Information</Typography>
              <Typography variant="subtitle2">You are entitled to decline to share certain Personal Information with us, in which case we may not be able to provide you with some of the features and functionalities of the Service. You may update, correct, or delete your profile information and preferences at any time by accessing your account settings page on the Service. If you wish to access or amend any other Personal Information we hold about you, you may contact us at <a href="mailto:privacy@agora.io">privacy@agora.io</a>. Please note that while any changes you make will be reflected in active user databases instantly or within a reasonable period of time, we may retain all information you submit for backups, archiving, prevention of fraud and abuse, analytics, satisfaction of legal obligations, or where we otherwise reasonably believe that we have a legitimate reason to do so, to the extent permitted under applicable law.</Typography>
              <Typography variant="subtitle2">Opt-Out from Commercial Communication</Typography>
              <Typography variant="subtitle2">If you receive any commercial emails from us, you may unsubscribe at any time by following the instructions contained within the email. You may also opt-out from receiving commercial emails from us, and any other promotional communications that we may send to you from time to time, by sending your request to us by email (to <a href="mailto:privacy@agora.io">privacy@agora.io</a>) or by writing to us at the address given at the end of this policy. We may allow you to view and modify settings relating to the nature and frequency of promotional communications that you receive from us in user account functionality on the Service. Please be aware that if you opt-out of receiving commercial email from us or otherwise modify the nature or frequency of promotional communications you receive from us, it may take up to fifteen business days for us to process your request, and you may receive promotional communications from us that you have opted-out from during that period, unless we are required by applicable law to process your request within a shorter period of time. Additionally, even after you opt-out from receiving commercial messages from us, you will continue to receive administrative messages from us regarding the Service.</Typography>
              <Typography variant="subtitle2">Privacy Settings</Typography>
              <Typography variant="subtitle2">Although we may allow you to adjust your privacy settings to limit access to certain Personal Information, please be aware that no security measures are perfect or impenetrable. To the fullest extent permitted under applicable law, we are not responsible for circumvention of any privacy settings or security measures on the Service. Additionally, we cannot control the actions of other users with whom you may choose to share your information. Further, even after information posted on the Service is removed, caching and archiving services may have saved that information, and other users or third parties may have copied or stored the information available on the Service. To the fullest extent permitted under applicable law, we cannot and do not guarantee that information you post on or transmit to the Service will not be viewed by unauthorized persons.</Typography>
              <Typography variant="subtitle2">Do Not Track</Typography>
              <Typography variant="subtitle2">Some web browsers incorporate a “Do Not Track” feature. Because there is not yet an accepted standard for how to respond to Do Not Track signals, our website does not currently respond to such signals.</Typography>
              <Typography variant="subtitle2">Do Not Sell Personal Information</Typography>
              <Typography variant="subtitle2">We will not sell your Personal Information to any third party without your prior written consent.</Typography>
              <Typography variant="subtitle2">Additional Privacy Rights</Typography>
              <Typography variant="subtitle2">Depending on your jurisdiction and applicable data protection legislation, you may have additional rights. If you are located in EEA or UK, you have these additional rights in relation to the Personal Information that we hold:</Typography>
              <Typography variant="subtitle2">Withdrawal of Consent</Typography>
              <Typography variant="subtitle2">Where we rely on consent for the processing of your Personal Information, you have the right to withdraw your consent at any time.</Typography>
              <Typography variant="subtitle2">Access</Typography>
              <Typography variant="subtitle2">You have the right to access the Personal Information we hold about you, and to receive an explanation of how we use it and with whom we share it.</Typography>
              <Typography variant="subtitle2">Correction</Typography>
              <Typography variant="subtitle2">You have the right to correct any Personal Information we hold about you that is inaccurate or incomplete.</Typography>
              <Typography variant="subtitle2">Erasure</Typography>
              <Typography variant="subtitle2">You have the right to request for your Personal Information to be erased or deleted.</Typography>
              <Typography variant="subtitle2">Object to Processing</Typography>
              <Typography variant="subtitle2">You have the right to object to our processing of your Personal Information where we are relying on a legitimate interest.</Typography>
              <Typography variant="subtitle2">Restrict Processing</Typography>
              <Typography variant="subtitle2">You have a right in certain circumstances to stop us processing your Personal Information other than for storage purposes.</Typography>
              <Typography variant="subtitle2">Portability</Typography>
              <Typography variant="subtitle2">You have the right to receive, in a structured, commonly used and machine-readable format, Personal Information that you have provided with us if we process it on the basis of our contract with you or with your consent, or to request that we transfer such Personal Information to a third party.</Typography>
              <Typography variant="subtitle2">Complaint</Typography>
              <Typography variant="subtitle2">You have the right to lodge a complaint with a data protection supervisory authority in your country of residence, place of work, or where an incident takes place.</Typography>
              <Typography variant="subtitle2">Exercising Your Rights</Typography>
              <Typography variant="subtitle2">Please note that, prior to any response to the exercise of such rights, we may require you to verify your identify. In addition, we may have valid legal reasons to refuse your request and will inform you if that is the case. For more information on, or to exercise your rights, please email <a href="mailto:privacy@agora.io">privacy@agora.io</a>.</Typography>
              <Typography variant="h2">5. Third-Party Services</Typography>
              <Typography variant="subtitle2">This Policy applies only to the processing of your Personal Information by Agora. The Service may contain features or links to websites and services provided by third parties. The policies and procedures described in this Privacy Policy do not apply to Third Party websites. Any information you provide on third-party sites or services is provided directly to the operators of such services and is subject to those operators’ policies, if any, governing privacy and security, even if accessed through the Service. We are not responsible for the content or privacy and security practices and policies of third-party sites or services to which links or access are provided through the Service. We encourage you to learn about third parties’ privacy and security policies before providing them with information.</Typography>
              <Typography variant="h2">6. Children’s Privacy</Typography>
              <Typography variant="subtitle2">Our Service is not directed to children under the age of 16, and we do not knowingly collect Personal Information from children under the age of 16 without obtaining parental consent. If you are under 16 years of age, then please do not use or access the Service at any time or in any manner. If we learn that Personal Information has been collected on the Service from persons under 16 years of age and without verifiable parental consent, then we will take the appropriate steps to delete this information. If you are a parent or guardian and discover that your child under 16 years of age has obtained an account on the Service, then you may alert us at <a href="mailto:privacy@agora.io">privacy@agora.io</a> and request that we delete that child’s Personal Information from our systems.</Typography>
              <Typography variant="h2">7. Data Security</Typography>
              <Typography variant="subtitle2">We use certain physical, managerial, and technical safeguards that are designed to appropriately protect Personal Information against accidental or unlawful destruction, accidental loss, unauthorized alteration, unauthorized disclosure or access, misuse, and any other unlawful form of processing of the Personal Information in our possession. WE CANNOT, HOWEVER, ENSURE OR WARRANT THE SECURITY OF ANY INFORMATION YOU TRANSMIT TO US OR STORE ON THE SERVICE, AND YOU DO SO AT YOUR OWN RISK. WE ALSO CANNOT GUARANTEE THAT SUCH INFORMATION MAY NOT BE ACCESSED, DISCLOSED, ALTERED, OR DESTROYED BY BREACH OF ANY OF OUR PHYSICAL, TECHNICAL, OR MANAGERIAL SAFEGUARDS. The foregoing is subject to requirements under applicable law to ensure or warrant information security.</Typography>
              <Typography variant="subtitle2">If, under applicable law, we learn of a security breach, then we may attempt to notify you electronically so that you can take appropriate protective steps. We may post a notice through the Service if a security breach occurs. Depending on where you live, you may have a legal right to receive notice of a security breach in writing. To receive a free written notice of a security breach, you should notify us at <a href="mailto:privacy@agora.io">privacy@agora.io</a>.</Typography>
              <Typography variant="h2">8. Data Transfers</Typography>
              <Typography variant="subtitle2">While Agora provides its Service globally, the Service is hosted in the United States. If you choose to use the Service, then please note that you are transferring your Personal Information outside of your jurisdiction to the United States and other regions where our data centers are located for storage and processing, which may not have the same level of data protection as that of your jurisdiction. Also, we may further transfer your data from the U.S. to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating the Service.</Typography>
              <Typography variant="subtitle2">If you’re from EEA or UK, when we transfer your Personal Information out of EEA or UK, we will comply with applicable EEA/UK data protection laws and take appropriate measures to safeguard security of your Personal Information. For example, we may transfer your Personal Information to countries which have been found to provide adequate protection by the EU Commission (e.g. Switzerland and Canada) to process and store your Personal Information, or we may use contractual protections for the transfer of your Personal Information to regions which haven’t been found to provide adequate protection by the EU Commission. You may contact us as specified below to obtain a copy of the safeguards we use to transfer your Personal Information outside of EEA or UK.</Typography>
              <Typography variant="h2">9. Data Retention</Typography>
              <Typography variant="subtitle2">We take measures to delete your Personal Information or keep it in a form that does not permit identification when this information is no longer required for the purposes for which we processed it, unless we are required by law to keep this information for a longer period.</Typography>
              <Typography variant="subtitle2">When determining the retention period, we consider various criteria which includes:</Typography>
              <Typography variant="subtitle2">	•	The type of products and services used by or provided to you</Typography>
              <Typography variant="subtitle2">	•	The nature and length of our relationship with you</Typography>
              <Typography variant="subtitle2">	•	Possible re-enrollment with our products or services</Typography>
              <Typography variant="subtitle2">	•	The impact on the services we provide to you if we delete some information from or about you</Typography>
              <Typography variant="subtitle2">	•	Mandatory retention periods provided by law and the statute of limitations</Typography>
              <Typography variant="subtitle2">Additionally, if you no longer desire to use our Service, you can choose to delete your account and associated information. We provide you with this option when accessing your account dashboard, where, after authenticating yourself and satisfying certain conditions, you’ll be able to request the deletion of your Agora account and associated information, unless we are legally required to keep it.</Typography>
              <Typography variant="h2">10. Changes and Updates to This Policy</Typography>
              <Typography variant="subtitle2">Please revisit this page periodically to stay aware of any changes to this Policy, which we may update from time to time. If we modify the Policy, we will make it available through the Service, and indicate the date of the latest revision. If the modifications materially alter your rights or obligations hereunder, we will make reasonable efforts to notify you of the change. For example, we may send a message to your email address, if we have one on file, or generate a pop-up or similar notification when you access the Service for the first time after such material changes are made. If you have an Agora account, we may also notify you of any changes / updates to this Policy in your account dashboard. Your continued use of the Service after the revised Policy has become effective indicates that you have read, understood and agreed to the current version of the Policy.</Typography>
              <Typography variant="h2">11. Contact Us</Typography>
              <Typography variant="subtitle2">Agora Lab, Inc. is the entity responsible for the processing of your Personal Information as described in this Policy. If you have any questions or comments about this Policy, your Personal Information, our use and disclosure practices, your consent choices, or if you would like to exercise your rights, please contact us by email at privacy@agora.io or write to us at:</Typography>
              <Typography variant="subtitle2">Agora Lab, Inc.</Typography>
              <Typography variant="subtitle2">2804 Mission College Blvd, Suite 110</Typography>
              <Typography variant="subtitle2">Santa Clara, CA 95054</Typography>
              <Typography variant="subtitle2">United States</Typography>
              <Typography variant="h2">Agora Terms of Service</Typography>
              <Typography variant="subtitle2">LEASE READ THE FOLLOWING TERMS CAREFULLY.</Typography>
              <Typography variant="subtitle2">BY CLICKING “I ACCEPT,” OR BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE SERVICES, PLATFORM, OR WEBSITE, YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND CONDITIONS, INCLUDING AGORA’S PRIVACY POLICY (TOGETHER, THESE “TERMS”). If you are not eligible, or do not agree to the Terms, then you do not have our permission to use the Service. YOUR USE OF THE SERVICE, AND AGORA’S PROVISION OF THE SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY AGORA AND BY YOU TO BE BOUND BY THESE TERMS.</Typography>
              <Typography variant="subtitle2">Arbitration NOTICE. Except for certain kinds of disputes described in Section 23, you agree that disputes arising under these Terms will be resolved by binding, individual arbitration, and BY ACCEPTING THESE TERMS, YOU AND AGORA ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend your rights under this contract (except for matters that may be taken to small claims court). Your rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or jury. (See Section 23.) </Typography>
              <Typography variant="subtitle2">	1.	ACCEPTANCE OF AGORA PLATFORM TERMS AND CONDITIONS</Typography>
              <Typography variant="subtitle2">These Terms govern your access to the information contained on the Agora website (the “Website”), use of the Agora application program interface(s) (API(s)), sample software code, and other related websites, networks, applications, SDKs, and other materials provided or made available by Agora (the “Platform”) that enable you to implement the Agora live video platform services offered by Agora (collectively, the “Service”) on one or more website(s), web application(s), or mobile application(s) owned and controlled by you (“Your Product”).</Typography>
              <Typography variant="subtitle2">“Agora”, “we”, “us”, “our”, and similar means Agora, Inc. and its affiliates.</Typography>
              <Typography variant="subtitle2">“You” or “your” means you, the person who has accessed the Website or has downloaded and is using or uses the Platform and Service and is the licensee under these Terms. If you are signing up for the Platform and the Service on behalf of a company (an “Enterprise Customer”), you represent that you are duly authorized to represent such company and accept the Terms on behalf of such company. To the extent that your use of the Platform or Services is subject to an Enterprise Customer agreement, such Enterprise Customer agreement, including any addendum thereto, will prevail over any conflicting provision in these Terms.</Typography>
              <Typography variant="subtitle2">In order to use the Website, Platform, and Services, you are required to accept and to agree to be bound by these Terms. If you are an Enterprise Customer, you agree to incorporate these Terms into Your Products and ensure that your customers adhere to these Terms (which may also include Additional Terms as described in Section 24.4).</Typography>
              <Typography variant="subtitle2">	2.	ELIGIBILITY AND AUTHORITY</Typography>
              <Typography variant="subtitle2">You must be at least 18 years old to use the Service. By agreeing to these Terms, you represent and warrant to us that: (a) you are at least 18 years old; (b) you have not previously been suspended or removed from the Platform or Service; and (c) your registration and your use of the Platform or Service is in compliance with any and all applicable laws and regulations.</Typography>
              <Typography variant="subtitle2">	3.	Accounts and Registration</Typography>
              <Typography variant="subtitle2">To access some features of the Platform or Service, you must register for an account. When you register for an account, you may be required to provide us with some information about yourself, such as your name, email address, or other contact information. You agree that the information you provide to us is accurate and that you will keep it accurate and up-to-date at all times. When you register, you will be asked to provide a password.</Typography>
              <Typography variant="subtitle2">You are solely responsible for maintaining the confidentiality of your account and password, including any application programming interface (“API”) key provided to you by Agora, and you accept responsibility for all activities that occur under your account and API key. If you believe that your account is no longer secure or that someone has used your API key without your permission, then you must immediately notify us at <a href="mailto:privacy@agora.io">privacy@agora.io</a>.</Typography>
              <Typography variant="subtitle2">	4.	Licenses</Typography>
              <Typography variant="subtitle2">4.1. Subject to your compliance with these Terms and Agora’s Acceptable Use policy located in our privacy policy page, Agora grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to (a) access and uses the Website and Service and (b) use the Platform in order to display, interface and implement the Service on Your Product, solely in accordance with the terms and conditions of these Terms. You may not install or use the Platform for any other purpose without Agora’s prior written consent.</Typography>
              <Typography variant="subtitle2">4.2. You will not sell, transfer, assign, rent, lease, or sublicense Agora’s code, the Platform, or the Service to anyone. Except as expressly authorized by Agora, and except and solely to the extent such a restriction is impermissible under applicable law, you may not: (a) reproduce, distribute, publicly display, or publicly perform the Platform or Service; (b) make modifications to the Website, Platform or Service; or (c) interfere with or circumvent any feature of the Website, Platform or Service, including any security or access control mechanism. If you are prohibited under applicable law from using the Website, Platform or Service, you may not use it. In addition, you may not nor allow any third party to copy, reverse engineer, decompile or disassemble Agora’s code, the Platform, or the Service, or build alternative methods to access the Service other than as provided through the Platform (except to the limited extent such restrictions are expressly prohibited by applicable statutory law). Nor may you obscure or cover any graphical element of the Service or otherwise interfere with the operation of the Website, Platform, or Service.</Typography>
              <Typography variant="subtitle2">4.3. To the extent any features available through the Service are provided by other third parties, Agora will make commercially reasonable efforts to communicate any policies, requirements, or guidelines of those third parties to you. You agree to follow those policies, requirements, or guidelines. By using any of these features, you agree that Agora may transfer your information to the applicable third party service solely to the extent necessary to perform the Service. Agora does not control and, to the fullest extent permitted by law, is not responsible for any third party services and content.</Typography>
              <Typography variant="subtitle2">4.4. You hereby grant Agora a limited, non-exclusive, non-transferable, non-sublicensable license to display your trade names, trademarks, service marks, logos, domain names and the like for the purpose of promoting or advertising that your use of the Platform and the Service.</Typography>
              <Typography variant="subtitle2">4.5. If you choose to provide input and suggestions regarding problems with or proposed modifications or improvements to the Website, Platform or Service (“Feedback”), then you hereby grant Agora an unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right to exploit the Feedback in any manner and for any purpose, including to improve the Website, Platform or Service and create other products and services.</Typography>
              <Typography variant="subtitle2">	5.	INTEGRATION OF THE SERVICE ON YOUR PRODUCT</Typography>
              <Typography variant="subtitle2">5.1.  The Service includes branding for Agora. You agree not to remove, obscure, or alter any branding contained in the Service or any notice of any Agora Marks. You may not display Agora Marks on Your Product (or otherwise) other than (a) through the display of the Service in accordance with the Platform and Agora’s branding guidelines and (b) solely for the purpose of disclosing that Your Product has implemented the Service in a manner that does not suggest any further relationship or endorsement of Your Product by Agora.</Typography>
              <Typography variant="subtitle2">5.2.  Other than through the API configuration options provided by Agora, you may not, nor allow any third party to alter, change or modify any user interface, feature or functionality of the Service without the express written consent of Agora.</Typography>
              <Typography variant="subtitle2">5.3.  Notwithstanding the foregoing paragraphs of this Section 5, Agora licenses certain components of the Platform (e.g. the Agora HTML Embed) under a permissive software license. In these cases, you agree not to use any Agora Marks, including those originally built into the code we provide, in any modified version of that code unless (a) it follows the branding guidelines or (b) you have entered into a separate written trademark license agreement with Agora.</Typography>
              <Typography variant="subtitle2">5.4.  You agree to update code provided by Agora in connection with modifications to the Service or Platform in a reasonable and timely fashion after Agora makes them available.</Typography>
              <Typography variant="subtitle2">5.5.  Agora may update files on our servers that will automatically change the functionality of the Platform or Service, and you consent to those updates.</Typography>
              <Typography variant="subtitle2">5.6.  Agora reserves the right to place volume limitations on access to the Platform or Service. Agora reserves the right to cap concurrent video chat sessions conducted via Your Product in its discretion.</Typography>
              <Typography variant="subtitle2">6.     User Content</Typography>
              <Typography variant="subtitle2">6.1 Certain features of the Service may permit you or your users to upload content to or via the Service, including messages, photos, video, audio, images, folders, data, text, and other types of works (“User Content”) and to display, transmit, record, and store User Content on or via the Service. You will retain any copyright and other proprietary rights that you may hold in the User Content that you upload to the Service. You warrant and represent to Agora and its affiliates that you have fully and explicitly disclosed, with simple and easy-to-read words, how and why you and the Service collect User Content and what kind of User Content will be collected when your users interact with the Service, and that you and/or your users have obtained all the necessary and explicit consent and authorization from your users and/or any third party (as applicable and necessary), before using the Service (including the recording and/or storage features), for Agora to provide the Service for you or your users.</Typography>
              <Typography variant="subtitle2">6.2 By providing User Content to or via the Service, you grant Agora a worldwide, non-exclusive, irrevocable, royalty-free, fully paid, transferable right and license (with the right to sublicense) to copy, reproduce, use, host, store, transfer, publicly display, publicly perform, transmit, reproduce, modify for the purpose of formatting for display, and distribute your User Content, in whole or in part, in any media formats and through any media channels now known or hereafter developed.</Typography>
              <Typography variant="subtitle2">6.3 Agora disclaims any and all liability in connection with User Content. You are solely responsible for your User Content and the consequences of providing User Content via the Service. By providing User Content via the Service, you affirm, represent, and warrant that:</Typography>
              <Typography variant="subtitle2">(a) you are the creator and owner of the User Content, or have the necessary licenses, rights, consents, and permissions to authorize Agora to use and distribute your User Content as necessary to exercise the licenses granted by you in this Section, in the manner contemplated by Agora the Service, and these Terms;</Typography>
              <Typography variant="subtitle2">(b) your User Content, and the use of your User Content as contemplated by these Terms, does not and will not: (i) infringe, violate, or misappropriate any legitimate right of your users or any third party, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, any other intellectual property/proprietary right, right to give and withdraw consent, or any other data protection right; (ii) slander, defame, libel, or invade the right of privacy, publicity or other property rights of any other person; or (iii) cause Agora to violate any law or regulation; and</Typography>
              <Typography variant="subtitle2">(c) your User Content could not be deemed by a reasonable person to be objectionable, profane, indecent, pornographic, harassing, threatening, embarrassing, hateful, or otherwise inappropriate.</Typography>
              <Typography variant="subtitle2">6.4 We are under no obligation to edit or control User Content that you or other users post or publish, and will not be in any way responsible or liable for User Content. Agora may, however, at any time and without prior notice, screen, remove, edit, or block any User Content that in our sole judgment violates these Terms or is otherwise objectionable. You understand that when using the Service you will be exposed to User Content from a variety of sources and acknowledge that User Content may be inaccurate, offensive, indecent, or objectionable. You agree to waive, and do waive, any legal or equitable right or remedy you have or may have against Agora with respect to User Content. If notified by a user or content owner that User Content allegedly does not conform to these Terms, we may investigate the allegation and determine in our sole discretion whether to remove the User Content, which we reserve the right to do at any time and without notice. For clarity, Agora does not permit copyright-infringing activities on the Service.</Typography>
              <Typography variant="subtitle2">6.5 We do not control and does not have any obligation to monitor: (a) User Content; (b) any content made available by third parties; or (c) the use of the Service by its users. You acknowledge and agree that Agora reserves the right to, and may at any time monitor any and all information transmitted or received through the Service for operational and other purposes. If at any time Agora chooses to monitor the content, Agora still assumes no responsibility or liability for content or any loss or damage incurred as a result of the use of content. During monitoring, information may be examined, recorded, copied, and used in accordance with our Privacy Policy.</Typography>
              <Typography variant="subtitle2">	7.	FREE TRIALS</Typography>
              <Typography variant="subtitle2">7.1.  In its sole discretion, Agora will determine whether you are eligible for a free trial subscription to the Service. You must agree to these Terms in order to be eligible for a free trial.</Typography>
              <Typography variant="subtitle2">7.2.  During the free trial, you may use the Service for internal demonstration purposes only. Agora expressly prohibits you from deploying the Service on any public or privately-facing website or mobile application for any commercial purpose (a “Live Deployment”) during the free trial including without limitation (a) for the purpose of generating advertising revenue directly or indirectly from the Service, (b) as an inducement for downloading toolbars, plugins, or downloads of any type, (c) as part of a paid service of any kind, (d) to provide any form of paid or unpaid support to your customers or users, or (e) as part of any brand, product, or service promotion or communication activity of any kind.</Typography>
              <Typography variant="subtitle2">7.3.  We reserve the right to limit the number of free trials per account, take actions to prevent abuse, and change the conditions of the free trial or discontinue it entirely at any time without notice. Agora reserves the right at any time to terminate your free trial and suspend your API key should it determine in its sole discretion that your free trial is a Live Deployment.</Typography>
              <Typography variant="subtitle2">7.4.  Agora is not obligated in any way to provide customer support or technical assistance to you during your free trial.</Typography>
              <Typography variant="subtitle2">	8.	Ownership; Proprietary Rights</Typography>
              <Typography variant="subtitle2">The Website, Platform, and Service is owned and operated by Agora. The visual interfaces, graphics, design, compilation, information, data, computer code (including source code or object code), products, software, services, and all other elements (“Materials”) provided by Agora are protected by intellectual property and other laws. All Materials are the property of Agora or its third party licensors.</Typography>
              <Typography variant="subtitle2">You acknowledge that the Website, Platform and the Service are protected by copyrights, trademarks, service marks, international treaties, and/or other proprietary rights and laws of the U.S. and other countries and that all ownership and intellectual property rights in the Website, Platform and the Service, including without limitation the trademarks AGORA and all related trade names, service marks, logos, domain names and the like (“Agora Marks”) do and shall, as between you and Agora, belong exclusively to Agora. Except as expressly provided herein, these Terms grant you no right, title, license, or interest in any intellectual property owned or licensed by Agora, including (but not limited to) the Website, Platform, the Service, Materials, or the Agora Marks.</Typography>
              <Typography variant="subtitle2">	9.	FEES</Typography>
              <Typography variant="subtitle2">9.1.  Certain features of the Service may require you to pay fees. Before you pay any fees, you will have an opportunity to review and accept the fees that you will be charged.</Typography>
              <Typography variant="subtitle2">9.2.  Agora reserves the right to determine pricing for the Service. Agora will make reasonable efforts to keep pricing information published on the Website up to date. We may increase or add new fees for any existing Service or Service feature by giving you advance notice of changes before they apply. Agora, at its sole discretion, may make promotional offers with different features and different pricing to any of Agora’s customers. These promotional offers, unless made to you, will not apply to your offer or these Terms.</Typography>
              <Typography variant="subtitle2">9.3.  To the extent the Service or any portion thereof are made available for any fee, you agree to pay all applicable fees (including any minimum subscription fees) as set forth in the pricing section of our Website. You authorize Agora to charge all sums for the orders that you make and any level of Service you select as described in these Terms or published by Agora, including all applicable taxes, to the payment method specified in your account. We may specify the manner in which you will pay any fees, and any such payment shall be subject to our general accounts receivable policies from time to time in effect. If you pay any fees with a credit card, Agora may seek pre-authorization of your credit card account prior to your purchase to verify that the credit card is valid and has the necessary funds or credit available to cover your purchase.</Typography>
              <Typography variant="subtitle2">9.4.  All fees payable by you are exclusive of federal, state, local and foreign taxes, duties, tariffs, levies, withholdings and similar assessments (including without limitation, sales taxes, use taxes and value added taxes) (“Additional Charges”), and you agree to bear and be responsible for the payment of all such Additional Charges, excluding taxes based upon Agora’s net income.</Typography>
              <Typography variant="subtitle2">9.5.  All amounts payable by you under this Agreement will be made without setoff or counterclaim and without deduction or withholding. If any deduction or withholding is required by applicable law, you shall notify us and shall pay such additional amounts to us as necessary to ensure that the net amount that we receive, after such deduction and withholding, equals the amount we would have received if no such deduction or withholding had been required.</Typography>
              <Typography variant="subtitle2">9.6.  Should you have any dispute as to fees associated with your account, please contact us at support@agora.io within 30 days of the date of the activity that generated such dispute, and we will attempt to resolve the matter. Any and all refunds issued to resolve such a dispute shall be issued as credits to your account, but in no event shall there be any cash refunds. Disputes older than 90 days shall not be entitled to any refunds or credits.</Typography>
              <Typography variant="subtitle2">	10.	Subscription and Auto-Renewals</Typography>
              <Typography variant="subtitle2">Agora may allow you to subscribe to a plan (“Subscription Plan”) for which you will be periodically billed the amounts indicated to you at the time of your subscription, as may be updated from time to time by Agora, on a forward-going basis, upon notice to you and your acceptance thereof (the “Subscription Fee”). When you subscribe to a Subscription Plan, the Subscription Plan will be billed on a periodic basis. You hereby authorize Agora to charge you on a going-forward basis and until cancellation of either the Subscription Plan or your account. The “Subscription Billing Date” is the day of the month when you sign up to your Subscription Plan. Your account will be charged automatically on the Subscription Billing Date all applicable fees and taxes for the next subscription period.</Typography>
              <Typography variant="subtitle2">BY PURCHASING A SUBSCRIPTION, YOU AGREE THAT YOUR SUBSCRIPTION WILL AUTOMATICALLY RENEW FOR SUCCESSIVE PERIODS UNLESS YOU CANCEL YOUR SUBSCRIPTION OR ACCOUNT AS FURTHER DESCRIBED BELOW.</Typography>
              <Typography variant="subtitle2">YOU MAY CANCEL YOUR SUBSCRIPTION PLAN AT ANY TIME, IN WHICH CASE YOUR SUBSCRIPTION WILL EXPIRE AT THE END OF THAT SUBSCRIPTION PERIOD (AND UPON WHICH EXPIRATION YOUR SUBCRIPTION WILL NO LONGER BE RENEWED OR CHARGED). YOU MAY CANCEL YOUR SUBSCRIPTION PLAN by emailing support@agora.io  . You must cancel your Subscription Plan at least 24 hours before it renews in order to avoid billing of the next periodic Subscription Fee to your account.</Typography>
              <Typography variant="subtitle2">	11.	 Rewards and Promotions</Typography>
              <Typography variant="subtitle2">Agora may include an opportunity for you to earn (including by completing actions or activities), reward points through various programs. Any rewards points will be governed by the terms of that program. In addition, Agora, at its sole discretion, may make promotional offers with different features to any of Agora’s users. These promotional offers, unless made to you, will not apply to your offer or these Terms.</Typography>
              <Typography variant="subtitle2">	12.	 ARCHIVING AND STORAGE OF RECORDED COMMUNICATIONS</Typography>
              <Typography variant="subtitle2">You acknowledge and understand that there are federal, state, local, and international laws governing the electronic recording of communications and that Agora will not be liable for any illegal use by you of the Service. You should carefully review your own circumstances when deciding whether to use the recording features of the Service and it is your responsibility to determine if the electronic recordings are legal under applicable federal and state laws. You agree to comply, and require that your users comply, with all applicable laws, whether federal, state, local or international, relating to the privacy of communication for all parties to a conversation, including, when required, advising all participants in a recorded video chat that the video chat is being recorded. In addition, you acknowledge that the storage and maintenance of recorded communications is not guaranteed by Agora and agree that Agora will not have any liability whatsoever for any damage, liabilities, losses (including any loss of data or profits) or any other consequences that you may incur with respect to the loss or deletion of recorded communications.</Typography>
              <Typography variant="subtitle2">	13.	 ACCEPTABLE USE POLICY; Compliance obligations</Typography>
              <Typography variant="subtitle2">Your use of the Website, Platform, and Service is subject to Agora’s Acceptable Use Policy located in our <a href="https://www.agora.io/en/acceptable-use-policy/">(“Acceptable Use Policy”)</a> page, including all compliance obligations listed therein. You are solely responsible for all content, services and advertising available through Your Product, and Your Product’s compliance with the Acceptable Use Policy.</Typography>
              <Typography variant="subtitle2">Agora reserves the right to investigate and take appropriate legal action against anyone who, in Agora’s sole discretion, violates these Terms, including without limitation, removing the offending content from the Service, suspending or terminating the access of such violators and reporting you to the law enforcement authorities. If you are unsure whether Your Product or use of the Website, Platform, or Services complies with the Acceptable Use Policy, please email us at support@agora.io so we can discuss whether your proposed use is acceptable.</Typography>
              <Typography variant="subtitle2">	14.	 Privacy; Data transfers</Typography>
              <Typography variant="subtitle2">14.1.  Privacy. The Agora Data Processing Addendum is incorporated by this reference into, and made a part of, these Terms.</Typography>
              <Typography variant="subtitle2">14.2.   Data Transfers for Users Located in the European Economic Area. If you or your users are located in the European Economic Area, you agree that all international data transfers will be made pursuant to the Standard Contractual Clauses, as updated, amended, replaced or superseded from time to time by the European Commission, the approved version of which in force at present is that set out in the European Commission’s Decision 2004/915/EC of 27 December 2004, available at: <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32004D0915">http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32004D0915</a>.</Typography>
              <Typography variant="subtitle2">By agreeing to these Terms, you and Agora conclude the Standard Contractual Clauses as included in the Data Processing Addendum.</Typography>
              <Typography variant="subtitle2">	15.	 SECURITY</Typography>
              <Typography variant="subtitle2">15.1.  You must be reachable during reasonable business hours for security questions or concerns through the contact information that you provided upon requesting your credentials to the Platform or API key. You will ensure that all networks, computer and operating systems, software and other systems used to operate Your Product employ security measures to prevent unauthorized access to or use of any user data and the Service. You must promptly report any security deficiencies in or intrusions to your systems to Agora at privacy@agora.io.</Typography>
              <Typography variant="subtitle2">15.2.  You understand that the operation of the Service, including your (or your users’) content, may be unencrypted and involve (i) transmissions over various networks; (ii) changes to conform and adapt to technical requirements of connecting networks or devices and (iii) transmission to Agora’s third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to operate and maintain the Service. Accordingly, you acknowledge that you bear sole responsibility for adequate security, protection and backup of your content. Agora will have no liability for any unauthorized access or use of any content, or any corruption, deletion, destruction or loss of any content. Except otherwise agreed to in a Enterprise Customer agreement, you further acknowledge and agree that Agora may preserve content and may also disclose content if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (i) comply with legal process, applicable laws or government requests; (ii) enforce these Terms; (iii) respond to claims that any content violates the rights of third parties; or (iv) protect the rights, property, or personal safety of Agora, its users and the public.</Typography>
              <Typography variant="subtitle2">15.3.  In addition to our rights to terminate or suspend the Service as described in Section 17 below, you acknowledge that: (i) your access to and use of the Service may be suspended for the duration of any unanticipated or unscheduled downtime or unavailability of any portion or all of the Services for any reason, including as a result of power outages, system failures or other interruptions; and (ii) we shall also be entitled, without any liability to you, to suspend access to any portion or all of the Service at any time, on a Service-wide basis: (a) for scheduled downtime to permit us to conduct maintenance or make modifications to any Service; (b) in the event of a denial of service attack or other attack on the Service or other event that we determine, in our sole discretion, may create a risk to the applicable Service, to you or to any of our other customers if the Service were not suspended; or (c) in the event that we determine that any Service is prohibited by applicable law or we otherwise determine that it is necessary or prudent to do so for legal or regulatory reasons (collectively, “Service Suspensions”).</Typography>
              <Typography variant="subtitle2">	16.	 REPRESENTATIONS AND WARRANTIES</Typography>
              <Typography variant="subtitle2">You represent and warrant that: (a) you have the right to use, reproduce, transmit, copy, publicly display, publicly perform, and distribute any content or data on Your Product or that is used or incorporated with the Service, and that neither Your Product nor any related content violate the rights of any third party (e.g. copyright, patent, trademark, or other proprietary right of any person or entity), or any applicable regulation or law, including but not limited to any export, re-export, or import laws and the laws of any country in which Your Product, content or service is made available; (b) you have all necessary rights and authorizations to agree to these Terms and to use the Platform and the Service as contemplated by these Terms; and (c) your agreement and compliance with these Terms and use of the Platform and the Service will not violate any law, regulation or contractual obligation.</Typography>
              <Typography variant="subtitle2">	17.	 TERM and termination</Typography>
              <Typography variant="subtitle2">17.1.  These Terms are effective beginning when you accept the Terms or first download, install, access, or use the Website or Service, and ending when terminated as described in this Section. You may terminate the Service at any time, for any or no reason, by deleting your account, unsubscribing from the Service, and disabling Your Product’s access of the Platform and use of the Service. Refunds for Services will not be provided unless you have prepaid for Services, in which case you may be eligible for a refund for unused, prepaid amounts. If you purchased an irrevocable, non-refundable package, no refunds will be provided.</Typography>
              <Typography variant="subtitle2">17.2.  We reserve the right to suspend or terminate your right and license to access or use any or all of the Website, Platform and Service or terminate these Terms in their entirety (and, accordingly, your right to use the Service) at any time, for any or no reason, by providing you 30 days’ advance notice. If Agora determines that providing advance notice would negatively impact Agora’s ability to provide the Service, Agora may suspend your right and license to access or use any or all if the Platform and Service or terminate these Terms in their entirety (and, accordingly, cease providing all Services to you), with no notice.</Typography>
              <Typography variant="subtitle2">17.3.  In addition, we may suspend your right and license to access and use the Platform and the Service or terminate these Terms in their entirety (and, accordingly, your right to use the Service), for cause effective as set forth below:</Typography>
              <Typography variant="subtitle2">(a) Immediately upon notice if: (i) Agora determines that Your Product is harmful to or inconsistent with Agora’s reputation and goodwill; (ii) if you violate, or we have reason to believe that you have violated, any provision of the Acceptable Use Policy; (iii) there is an unusual spike or increase in your use of the Service for which there is reason to believe such traffic or use is fraudulent or negatively impacting the operating capability of the Service; (iv) we determine, in our sole discretion, that our provision of any of the Service to you is prohibited by applicable law, or has become impractical or unfeasible for any legal or regulatory reason; or (v) subject to applicable law, upon your liquidation, commencement of dissolution proceedings, disposal of your assets, failure to continue your business, assignment for the benefit of creditors, or if you become the subject of a voluntary or involuntary bankruptcy or similar proceeding;</Typography>
              <Typography variant="subtitle2">(b) Immediately and without notice if you are in default of any payment obligation with respect to any of the Service or if any payment mechanism you have provided to us is invalid or charges are refused for such payment mechanism; or</Typography>
              <Typography variant="subtitle2">(c) Five (5) days following our notice to you of a breach of any other provision of these Terms and failure, as determined by us, in our sole discretion, to cure such breach within such 5-day period.</Typography>
              <Typography variant="subtitle2">17.4.  Upon our suspension of your use of any Service, in whole or in part, for any reason: (i) fees will continue to accrue for any Service that is still in use by you, notwithstanding the suspension; (ii) you remain liable for all fees, charges and any other obligations you have incurred through the date of suspension with respect to the Service; and (iii) all of your rights with respect to the Service shall be terminated during the period of the suspension.</Typography>
              <Typography variant="subtitle2">17.5.  Upon any termination of these Terms or your access to the Service, for any reason: (i) you remain liable for all fees, charges and any other obligations you have incurred through the date of termination with respect to the Services; (ii) all rights and licenses granted by Agora to you herein shall terminate, (iii) you shall immediately remove the Service from Your Product and remove all copies of the Platform from all computers, hard drives, networks, and other storage media, (iv) all of your content on the Service (if any) may be permanently deleted by Agora.</Typography>
              <Typography variant="subtitle2">17.6.  Notwithstanding anything to the contrary in these Terms, the following provisions shall survive and remain in full force and effect following any termination of the parties’ obligations under these Terms: Sections 4.5, 6, 13, 15, 16, 17, 18, 21, 22, 23, 24, 25, and 26.</Typography>
              <Typography variant="subtitle2">	18.	 DMCA</Typography>
              <Typography variant="subtitle2">The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under U.S. copyright law. Agora will promptly process and investigate notices of alleged infringement and will take appropriate actions under the DMCA and other applicable intellectual property laws with respect to any alleged or actual infringement. A notification of claimed copyright infringement should be emailed to Agora at <a href="mailto:privacy@agora.io">privacy@agora.io</a> (subject line: “DMCA Takedown Request”). You may also contact us by mail at:</Typography>
              <Typography variant="subtitle2">Attention:</Typography>
              <Typography variant="subtitle2">DMCA c/o Office Manager at Agora Lab Inc</Typography>
              <Typography variant="subtitle2">2804 Mission College Blvd. Ste 110, </Typography>
              <Typography variant="subtitle2">Santa Clara, CA  95054</Typography>
              <Typography variant="subtitle2">Notice: To be effective, the notification must be in writing and contain the following information:</Typography>
              <Typography variant="subtitle2">	•	   an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest; </Typography>
              <Typography variant="subtitle2">		   ·    a description of the copyrighted work or other intellectual property that you claim has been infringed;</Typography>
              <Typography variant="subtitle2">		   ·    a description of where the material that you claim is infringing is located on the Service, with enough detail that we may find it;</Typography>
              <Typography variant="subtitle2">		   ·    your address, telephone number, and email address; </Typography>
              <Typography variant="subtitle2">		   ·    a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright or intellectual property owner, its agent, or the law; </Typography>
              <Typography variant="subtitle2">		   ·    a statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner’s behalf.</Typography>
              <Typography variant="subtitle2">		   ·    your physical or electronic signature; </Typography>
              <Typography variant="subtitle2">		   ·    identification of the content that has been removed or to which access has been disabled and the location at which the content appeared before it was removed or disabled; </Typography>
              <Typography variant="subtitle2">		   ·    a statement that you have a good faith belief that the content was removed or disabled as a result of mistake or a misidentification of the content; and </Typography>
              <Typography variant="subtitle2">		   ·    your name, address, telephone number, and e-mail address, a statement that you consent to the jurisdiction of the federal court located within the Northern District of California and a statement that you will accept service of process from the person who provided notification of the alleged infringement.</Typography>
              <Typography variant="subtitle2">If a counter-notice is received by Agora, Agora will send a copy of the counter-notice to the original complaining party informing that person that it may replace the removed content or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider, member or user, the removed content may be replaced, or access to it restored, in 10 to 14 business days or more after receipt of the counter-notice, at Agora’s sole discretion.</Typography>
              <Typography variant="subtitle2">Repeat Infringer Policy: In accordance with the DMCA and other applicable law, Agora has adopted a policy of terminating, in appropriate circumstances and at Agora’s sole discretion, the access to the Service of Platform users who are deemed to be repeat infringers. Agora may also at its sole discretion limit access to the Service and/or terminate the access of any Platform users who infringe any intellectual property rights of others, whether or not there is any repeat infringement.</Typography>
              <Typography variant="subtitle2">	19.	 EXPORT COMPLIANCE</Typography>
              <Typography variant="subtitle2">Agora’s products may be subject to U.S. export and re-export control laws and regulations, including the Export Administration Regulations (“EAR”) maintained by the U.S. Department of Commerce, trade and economic sanctions maintained by the Treasury Department’s Office of Foreign Assets Control (“OFAC”), and the International Traffic in Arms Regulations (“ITAR”) maintained by the Department of State. You warrant that you are (1) not located in Cuba, Iran, North Korea, Sudan, or Syria, and (2) not a denied party as specified in the regulations listed above.</Typography>
              <Typography variant="subtitle2">You agree to comply with all applicable export and re-export control laws and regulations, including the EAR, trade and economic sanctions maintained by OFAC, and the ITAR. Specifically, you covenant that you shall not — directly or indirectly — sell, export, re-export, transfer, divert, or otherwise dispose of any products, software, or technology (including products derived from or based on such technology) received from Agora under these Terms to any destination, entity, or person prohibited by the laws or regulations of the United States, without obtaining prior authorization from the competent government authorities as required by those laws and regulations.</Typography>
              <Typography variant="subtitle2">Furthermore, you agree to indemnify, to the fullest extent permitted by law, Agora from and against any fines or penalties that may arise as a result of your breach of this provision. This export control clause shall survive termination or cancellation of these Terms.</Typography>
              <Typography variant="subtitle2">	20.	 Modifications</Typography>
              <Typography variant="subtitle2">We reserve the right to change, modify, or update the Website and Platform at any time, to impose additional restrictions or limitations on the Service (including, without limitation, the maximum period of time that data or other content will be retained by the Service and the maximum storage space that will be allotted on Agora’s servers on your behalf), or to change, modify or update the fees charged for all or a portion of the Service. You agree that Agora has no responsibility or liability for the deletion or failure to store any data or other content maintained or transmitted by the Service. Agora may decide what features to include in the Service and whether to make them optional or mandatory. You further acknowledge that Agora reserves the right to change these general practices and limits at any time, in its sole discretion, with or without notice.</Typography>
              <Typography variant="subtitle2">We also reserve the right to change, modify or update the Terms at any time. If we revise the Terms, we will post the revised Terms on our Website, and you consent to receiving notice of any changes through such posting. If required by law, Agora will also provide you notice of changes as provided in Section 25.2. If you do not agree to the revisions, you must cease to use the Platform and remove components of the Service from Your Product. Your continued use of the Platform or use components of the Service on Your Product after the posting of the revised Terms means that you have accepted the new Terms; provided that material modifications are effective upon your acceptance of the revised Terms. Disputes arising under these Terms will be resolved in accordance with the version of these Terms that was in effect at the time the dispute arose.</Typography>
              <Typography variant="subtitle2">	21.	 NO AGORA WARRANTIES</Typography>
              <Typography variant="subtitle2">AGORA DOES NOT REPRESENT OR WARRANT THAT THE WEBSITE, PLATFORM OR THE SERVICE COMPLIES WITH ANY LEGAL REQUIREMENTS, IS FREE OF INACCURACIES, ERRORS, BUGS, OR INTERRUPTIONS (INCLUDING WITHOUT LIMITATION SERVICE SUSPENSIONS), OR IS RELIABLE, ACCURATE, COMPLETE, OR OTHERWISE VALID. THE WEBSITE, PLATFORM AND THE SERVICE ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITH NO WARRANTY, EXPRESS OR IMPLIED, OF ANY KIND AND AGORA EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, LEGALITY, AVAILABILITY, SECURITY, TITLE AND/OR NON-INFRINGEMENT. YOUR USE OF THE WEBSITE, PLATFORM AND THE SERVICE ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE THAT RESULTS FROM THEIR USE, INCLUDING, BUT NOT LIMITED TO, ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OR CORRUPTION OF DATA.</Typography>
              <Typography variant="subtitle2">	22.	 LIMITATION OF LIABILITY</Typography>
              <Typography variant="subtitle2">AGORA WILL NOT, UNDER ANY CIRCUMSTANCES, BE LIABLE TO YOU, WHETHER BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE, PRODUCT LIABILITY OR OTHERWISE), STATUTE OR ANY OTHER LEGAL THEORY (i) FOR ANY LOST PROFITS, LOST OR CORRUPTED DATA, COMPUTER FAILURE OR MALFUNCTION, INTERRUPTION OF BUSINESS, OR OTHER INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH USE OF THE WEBSITE, PLATFORM OR THE SERVICE, ANY SERVICE SUSPENSION, OR ANY OTHER PECUNIARY LOSS, WHETHER OR NOT AGORA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR (ii) FOR ANY AMOUNT IN EXCESS OF $100.00 OR, IF GREATER, THE FEES PAID BY YOU TO AGORA IN THE SIX (6) MONTH PERIOD PRIOR TO THE DATE THE CLAIM AROSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS OF SECTION 21 AND THIS SECTION 22 MAY NOT APPLY TO YOU.</Typography>
              <Typography variant="subtitle2">	23.	 INDEMNITY AND WAIVER</Typography>
              <Typography variant="subtitle2">You agree to indemnify, defend, and hold Agora and its affiliates, officers, directors, agents, service providers, partners, and employees harmless from any claim, demand or allegation made by any third party, and all related losses, damages, liabilities, costs and expenses (including attorneys’ fees), that it is any way related to:</Typography>
              <Typography variant="subtitle2">	•	      your use of the Website, Platform or the Service;</Typography>
              <Typography variant="subtitle2">	•	      use of the Service by the users of Your Product;</Typography>
              <Typography variant="subtitle2">	•	      Your Product, including any content, services or advertisements on Your Product or that you incorporate with the Service;</Typography>
              <Typography variant="subtitle2">	•	      any claims for copyright infringement, defamation, invasion of privacy or right of publicity arising out of or in connection with any unauthorized use of the Service and your content on the Service; and</Typography>
              <Typography variant="subtitle2">	•	      your breach of any representation, warranty or covenant included in these Terms.</Typography>
              <Typography variant="subtitle2">The foregoing indemnity provision shall be in addition to and not in lieu of any other indemnification obligations set forth in these Terms.</Typography>
              <Typography variant="subtitle2">	24.	 Dispute Resolution and Arbitration</Typography>
              <Typography variant="subtitle2">24.1.  Generally. In the interest of resolving disputes between you and Agora in the most expedient and cost-effective manner, and except as described in Section 24.2, you and Agora agree that every dispute arising in connection with these Terms will be resolved by binding arbitration. Arbitration is less formal than a lawsuit in court.</Typography>
              <Typography variant="subtitle2">Arbitration uses a neutral arbitrator instead of a judge or jury, may allow for more limited discovery than in court, and can be subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award. This agreement to arbitrate disputes includes all claims arising out of or relating to any aspect of these Terms, whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and regardless of whether a claim arises during or after the termination of these Terms. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND AGORA ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.</Typography>
              <Typography variant="subtitle2">24.2.  Exceptions. Despite the provisions of Section 24.1, nothing in these Terms will be deemed to waive, preclude, or otherwise limit the right of either party to: (a) bring an individual action in small claims court; (b) pursue an enforcement action through the applicable federal, state, or local agency if that action is available; (c) seek injunctive relief in a court of law in aid of arbitration; or (d) to file suit in a court of law to address an intellectual property infringement claim.</Typography>
              <Typography variant="subtitle2">24.3 If you do not wish to resolve disputes by binding arbitration, you may opt out of the provisions of this Section 24 within 30 days after the date that you agree to these Terms by sending a letter to Agora, Inc., Attention: Arbitration Opt-Out, 2804 Mission College Blvd, Suite 110, Santa Clara, CA 95054 that specifies: your full legal name, the email address associated with your account on the Service, and a statement that you wish to opt out of arbitration (“Opt-Out Notice”). Once Agora receives your Opt-Out Notice, this Section 24 will be void and any action arising out of these Terms will be resolved as set forth in Section 24.3. The remaining provisions of these Terms will not be affected by your Opt-Out Notice. </Typography>
              <Typography variant="subtitle2">24.4 Arbitrator. Any arbitration between you and Agora will be settled under the Federal Arbitration Act and administered by the American Arbitration Association (“AAA”) under its Consumer Arbitration Rules (collectively, “AAA Rules”) as modified by these Terms. The AAA Rules and filing forms are available online at <a href="www.adr.or">www.adr.org</a>, by calling the AAA at 1-800-778-7879, or by contacting Agora. The arbitrator has exclusive authority to resolve any dispute relating to the interpretation, applicability, or enforceability of this binding arbitration agreement.</Typography>
              <Typography variant="subtitle2">24.5. Notice of Arbitration; Process. A party who intends to seek arbitration must first send a written notice of the dispute to the other party by certified U.S. Mail or by Federal Express (signature required) or, only if that other party has not provided a current physical address, then by electronic mail (“Notice of Arbitration”). Agora’s address for Notice is: Agora, Inc., 2804 Mission College Blvd, Suite 110, Santa Clara, CA 95054. The Notice of Arbitration must: (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought (“Demand”). The parties will make good faith efforts to resolve the claim directly, but if the parties do not reach an agreement to do so within 30 days after the Notice of Arbitration is received, you or Agora may commence an arbitration proceeding. All arbitration proceedings between the parties will be confidential unless otherwise agreed by the parties in writing. During the arbitration, the amount of any settlement offer made by you or Agora must not be disclosed to the arbitrator until after the arbitrator makes a final decision and award, if any. If the arbitrator awards you an amount higher than the last written settlement amount offered by Agora in settlement of the dispute prior to the award, Agora will pay to you the higher of: (i) the amount awarded by the arbitrator; or (ii) $1,000.</Typography>
              <Typography variant="subtitle2">24.6.  Fees. If you commence arbitration in accordance with these Terms, Agora will reimburse you for your payment of the filing fee, unless your claim is for more than $10,000, in which case the payment of any fees will be decided by the AAA Rules. Any arbitration hearing will take place at a location to be agreed upon in Santa Clara County, California, but if the claim is for $10,000 or less, you may choose whether the arbitration will be conducted: (a) solely on the basis of documents submitted to the arbitrator; (b) through a non-appearance based telephone hearing; or (c) by an in-person hearing as established by the AAA Rules in the county (or parish) of your billing address. If the arbitrator finds that either the substance of your claim or the relief sought in the Demand is frivolous or brought for an improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment of all fees will be governed by the AAA Rules. In that case, you agree to reimburse Agora for all monies previously disbursed by it that are otherwise your obligation to pay under the AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator must issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the decision and award, if any, are based. The arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees or expenses at any time during the proceeding and upon request from either party made within 14 days of the arbitrator’s ruling on the merits.</Typography>
              <Typography variant="subtitle2">24.7.  No Class Actions. YOU AND AGORA AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and Agora agree otherwise, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of a representative or class proceeding.</Typography>
              <Typography variant="subtitle2">24.8.  Modifications to this Arbitration Provision. If Agora makes any future change to this arbitration provision, other than a change to Agora’s address for Notice of Arbitration, you may reject the change by sending us written notice within 30 days of the change to Agora’s address for Notice of Arbitration, in which case your account with Agora will be immediately terminated and this arbitration provision, as in effect immediately prior to the changes you rejected will survive.</Typography>
              <Typography variant="subtitle2">24.9.  Enforceability. If Section 24.7 is found to be unenforceable or if the entirety of this Section 24 is found to be unenforceable, then the entirety of this Section 24 will be null and void and, in that case, the parties agree that the exclusive jurisdiction and venue described in Section 25.3 will govern any action arising out of or related to these Terms.</Typography>
              <Typography variant="subtitle2">	25.	 Miscellaneous</Typography>
              <Typography variant="subtitle2">25.1.  General. These Terms govern your use of the Website, Platform and the Service and constitute the entire agreement between you and Agora regarding the subject matter hereof. These Terms supersede any prior agreements between you and Agora relating to your use of the Website, Platform and the Service (including, but not limited to, any prior versions of these Terms). The failure of Agora to exercise or enforce any right or provision of these Terms does not constitute a waiver of that right or provision, and a waiver of any default is not a waiver of any other default. If a court of competent jurisdiction finds any provision of these Terms to be invalid, the provision shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the parties’ original intentions as reflected in the provision, and so that the other provisions of the Terms remain in full force and effect. All headings in the Terms are for convenience only and have no legal or contractual effect. The Terms are personal to you and may not be assigned or transferred for any reason whatsoever (including, without limitation, by operation of law, merger, reorganization, or as a result of an acquisition or change of control involving you) without Agora’s prior written consent and any action or conduct in violation of the foregoing shall be void and without effect. Agora expressly reserves and shall have the right to assign the Terms and to delegate any of its obligations hereunder.</Typography>
              <Typography variant="subtitle2">25.2.  Notices. We may send you any notices, including those regarding changes to these Terms, to the email address you provided when you requested your API key or through any other reasonable means. Any notices to Agora must be sent to Agora Lab, 2804 Mission College Blvd. Ste. 110, Santa Clara, CA  95054 via first class or air mail or overnight courier and are deemed given upon receipt.</Typography>
              <Typography variant="subtitle2">25.3.  Governing Law. These Terms shall be governed by the internal laws of the State of California, without giving effect to principles of conflict of laws. You hereby consent to the exclusive jurisdiction and venue of the state courts sitting in Santa Clara County, California or the federal courts in the Northern District of California to resolve any disputes arising under these Terms. In each case these Terms shall be construed and enforced without regard to the United Nations Convention on the International Sale of Goods.</Typography>
              <Typography variant="subtitle2">25.4.  Additional Terms. Your use of the Service is subject to all additional terms, policies, rules, or guidelines applicable to the Service or certain features of the Service that we may post on or link to from the Service (the “Additional Terms”). All Additional Terms are incorporated by this reference into, and made a part of, these Terms.</Typography>
              <Typography variant="subtitle2">25.5.  Consent to Electronic Communications. By using the Service, you consent to receiving certain electronic communications from us as further described in our Privacy Policy. Please read our Privacy Policy to learn more about our electronic communications practices. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including that those communications be in writing.</Typography>
              <Typography variant="subtitle2">25.6.  Notice to California Residents. If you are a California resident, under California Civil Code Section 1789.3, you may contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone at (800) 952-5210 in order to resolve a complaint regarding the Service or to receive further information regarding use of the Service.</Typography>
              <Typography variant="subtitle2">25.7.  No Support. These Terms do not entitle you to any support for the Website, Platform or the Service, unless you make separate arrangements with Agora and pay all fees associated with such support (if any). Any such support provided by Agora shall be subject to the Terms as modified by the associated support agreement.</Typography>
              <Typography variant="subtitle2">	26.	 Notice Regarding Apple. This Section 26 only applies to the extent you are using our mobile application on an iOS device. You acknowledge that these Terms are between you and Agora only, not with Apple Inc. (“Apple”), and Apple is not responsible for the Service or the content thereof. Apple has no obligation to furnish any maintenance and support services with respect to the Service. If the Service fails to conform to any applicable warranty, you may notify Apple and Apple will refund any applicable purchase price for the mobile application to you; and, to the maximum extent permitted by applicable law, Apple has no other warranty obligation with respect to the Service. Apple is not responsible for addressing any claims by you or any third party relating to the Service or your possession and/or use of the Service, including: (a) product liability claims; (b) any claim that the Service fails to conform to any applicable legal or regulatory requirement; or (c) claims arising under consumer protection or similar legislation. Apple is not responsible for the investigation, defense, settlement and discharge of any third party claim that the Service and/or your possession and use of the Service infringe a third party’s intellectual property rights. You agree to comply with any applicable third party terms when using the Service. Apple and Apple’s subsidiaries are third party beneficiaries of these Terms, and upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third party beneficiary of these Terms. You hereby represent and warrant that: (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.</Typography>
            </div>
            :
            <div className={classes.content}>
              <div className={classes.contentTitle}>
                <Logo width="40px" height="40px" fill={uiStore.modePrimaryColor} />
                <Typography style={{ paddingLeft: '0.5rem' }} variant="h1">Agora 隐私政策和服务条款</Typography>
              </div>
              <Typography variant="subtitle2">AgoraVideoCall（“本产品”）是由Agora提供的一款测试产品，主要用于向Agora客户展示内嵌Agora SDK后可以实现的效果。本产品仅为测试效果之目的提供给现有或潜在客户使用，而非提供给大众使用的正式产品。Agora享有本产品的著作权和所有权，任何人不得对本产品进行修改、合并、调整、逆向工程、再许可和/或出售该软件的副本以及做出其他损害Agora合法权益的行为。</Typography>
              <Typography variant="subtitle2">若您想试用本产品，欢迎您下载、安装并使用，Agora特此授权您在全球范围内免费使用本产品的权利。本产品按“现状”提供，没有任何形式的明示或暗示担保，包括但不限于对适配性、特定目的的适用性和非侵权性的担保。无论是由于与本产品或本产品的使用或其他方式有关的任何合同、侵权或其他形式的行为，Agora均不对任何索赔、损害或其他责任负责。如果您下载、安装、使用本产品，即表明您确认并同意Agora对因任何原因在试用本产品时可能对您自身或他人造成的任何形式的损失和伤害不承担任何责任。</Typography>
              <Typography variant="subtitle2">若您有任何疑问，请联系support@agora.io.</Typography>

              <Typography variant="h2">Agora 隐私政策</Typography>
              <Typography variant="subtitle2">本版本生效日期：2020年5月1日</Typography>
              <Typography variant="subtitle2">感谢您对声网（以下亦称“我方”或“我们”）的关注，也欢迎并感谢使用我们的网站<a href="https://www.agora.io">www.agora.io</a>（以下简称“网站”）、Agora软件开发工具包（以下简称“SDK”）、网络、嵌入式小工具及API、可下载的软件、移动应用程序（包括平板电脑应用程序）以及我们提供的其他服务（统称为“服务”）。</Typography>
              <Typography variant="subtitle2">我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维护您对我们的信任，恪守如下原则：</Typography>
              <Typography variant="subtitle2">权责一致</Typography>
              <Typography variant="subtitle2">目的明确</Typography>
              <Typography variant="subtitle2">最小必要</Typography>
              <Typography variant="subtitle2">公开透明</Typography>
              <Typography variant="subtitle2">确保安全</Typography>
              <Typography variant="subtitle2">主体参与</Typography>
              <Typography variant="subtitle2">同时，我们承诺，我们将按照业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。本《声网隐私政策》（以下简称“本政策”）描述了我们如何收集、使用、处理、披露、转移和保护您的个人信息。请在使用我们的服务前，仔细阅读并了解本政策，并作出您认为适当的选择。通过使用我们的服务或其他符合适用法律的操作，即表示您已阅读并接受本政策中所述之所有条款，包括我们定期作出的任何变更。</Typography>

              
<Typography variant="subtitle2">1) 我们收集哪些您的个人信息</Typography>
              <Typography variant="subtitle2">就本政策而言，“个人信息”是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映自然人活动情况的各种信息。</Typography>
              <Typography variant="subtitle2">为了向您提供我们的服务，我们会主动向您收集为提供服务所必需的个人信息。您有权拒绝提供该等个人信息，若您拒绝提供，则我们可能无法向您提供某些服务，或者您无法享受某些功能，但不影响您使用不需要个人信息的服务或者功能。</Typography>
              <Typography variant="subtitle2">我们仅为明确的、合法的、已向您披露的目的收集必要的个人信息，并保证不会以与该等目的不符的方式处理这些信息。我们从以下来源获取与您相关的个人信息：</Typography>

              <Typography variant="subtitle2">1.1、我们直接自您处收集的信息</Typography>
              <Typography variant="subtitle2">当您注册账户时：</Typography>
              <Typography variant="subtitle2">在您以个人名义或者代表您所在企业注册使用本服务，您将向我们提供您的姓名、电子邮箱，并设置您的密码。您也可以选择添加其他信息来完善您的账户，例如上传您的照片。为完成支付，我们还将收集您的银行账号信息。</Typography>
              <Typography variant="subtitle2">当您使用我们的产品时：</Typography>
              <Typography variant="subtitle2">在您使用我们的RTC、RTM、录制以云录制等产品时，我们将收集为实现实时音视频业务功能所必需的如下信息：</Typography>
              <Typography variant="subtitle2">a、与您设备相关的信息： 为实现实时音频或视频通话，我们需要您授权开启您的摄像头功能、麦克风功能；我们还会收集您的设备类型、设备型号、CPU信息、储存信息、电池电量信息、操作系统信息；</Typography>
              <Typography variant="subtitle2">b、与您网络状况相关的信息： 您的IP地址、信号强度信息、网络类型、用户代理、蓝牙开启信息；</Typography>
              <Typography variant="subtitle2">c、您与我们产品交互相关的信息： 收发端用户ID、用户属性、频道信息、使用时长、频道内设置姓名、日志；</Typography>
              <Typography variant="subtitle2">d、您的音视频信息： 我们为企业客户提供实时音视频云服务及相关配套服务，若您作为企业客户的终端用户使用我们的该项功能时或者以个人用户名义使用我们的Demo时，我们将为实现实时音视频互动业务功能收集、传输、录制、储存您在使用服务过程中产生的音频流信息、视频流信息以及文字通讯信息，这些信息可能包括您或者/及与您进行互动的个人向我们提供的您的个人信息（包括您发送的文字、图片、音频、视频、您的图像、声音、肖像以及您交流的内容）。</Typography>
              <Typography variant="subtitle2">e、当您使用Beckon时： 我们将收集您的电话号码，您也可以上传您的照片、设置您的昵称、分享您的通讯录信息。</Typography>
              <Typography variant="subtitle2">当您在使用我们产品过程中向我们反馈服务质量、申请技术支持、或反馈产品优化建议时，或者通过我们的官网、邮箱、电话向我们提出申请、要求、诉求、投诉时，我们将收集您的姓名、邮箱、通讯地址、电话，以及您申请、要求、诉求及投诉的内容。为加强沟通，若您主动提供，我们也将收集您的微信账号、QQ账号。</Typography>
              <Typography variant="subtitle2">当您提出服务咨询时：</Typography>
              <Typography variant="subtitle2">当您在使用我们产品过程中向我们反馈服务质量、申请技术支持、或反馈产品优化建议时，或者通过我们的官网、邮箱、电话向我们提出申请、要求、诉求、投诉时，我们将收集您的姓名、邮箱、通讯地址、电话，以及您申请、要求、诉求及投诉的内容。为加强沟通，若您主动提供，我们也将收集您的微信账号、QQ账号。</Typography>
              <Typography variant="subtitle2">当您向我们申请工作机会时：</Typography>
              <Typography variant="subtitle2">我们将收集及使用您向我们提供的简历所包含的信息。</Typography>
              <Typography variant="subtitle2">当您参加我们的调研活动时：</Typography>
              <Typography variant="subtitle2">我们将不时向不特定对象发出调研邀请，若您接收我们的邀请，并填写相关信息时，我们将收集及使用您填写的个人信息。</Typography>
              <Typography variant="subtitle2">当您参加我们的RTC比赛或大会时：</Typography>
              <Typography variant="subtitle2">我们每年会举行各类RTC比赛以及年度大会，当您申请参加该等比赛或大会时，您将向我们或者我们的活动合作方提供姓名、电话、邮箱等个人信息，以及您主动填写的其他信息。</Typography>
              <Typography variant="subtitle2">当我们使用Cookie技术时：</Typography>
              <Typography variant="subtitle2">我们及第三方可能会通过cookies及类似技术自动收集您访问、使用我们网站、服务的信息，这些信息可能包含您的个人信息，比如您的IP地址、广告标识符，详见“我们的Cookies政策”。</Typography>

              <Typography variant="subtitle2">1.2 我们自第三方处收集的您的个人信息</Typography>
              <Typography variant="subtitle2">除自您处直接收集的信息外，我们还可能从如下来源收集、使用您的个人信息：</Typography>
              <Typography variant="subtitle2">自我们的企业客户：</Typography>
              <Typography variant="subtitle2">若您是我们企业客户产品的终端用户，而该产品内置了我们的实时音视频SDK，我们将在您使用我们实时音视频功能时自我们企业客户处获取、使用您的个人信息，所收集、使用的信息详见“当您使用我们产品时”章节。</Typography>
              <Typography variant="subtitle2">自我们的合作伙伴：</Typography>
              <Typography variant="subtitle2">我们可能从合作伙伴等第三方渠道获得信息，当我们将这些信息与我们收集、处理的个人信息结合时，我们亦将该等第三方来源信息作为个人信息处理，并按照本政策的规定进行使用、保护。</Typography>
              <Typography variant="subtitle2">第三方账户：</Typography>
              <Typography variant="subtitle2">您可能通过第三方账户登录我们的网站，比如通过您的微信账户、GitHub账户、Google账户或者Cocos账户，此时我们将自该等第三方获取您的注册信息，包括您的姓名、邮箱地址、及公司名称。</Typography>
              <Typography variant="subtitle2">Google Analytics（分析）：</Typography>
              <Typography variant="subtitle2">我们通过Google Analytics（分析），利用和类似技术收集与分析网站使用有关信息，形成报告并预测趋势。您可以登陆 <a href="https://www.google.com/policies/privacy/partners/">https://www.google.com/policies/privacy/partners/</a> 查看相关信息，或者登陆 <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a> 下载相关浏览器插件，停用Google Analytics（分析）。</Typography>
              <Typography variant="subtitle2">第三方网络信标和第三方按钮：</Typography>
              <Typography variant="subtitle2">我们还可能会在服务上植入第三方内容或广告，这些第三方内容或广告可能会使用clear gif或其他形式的网络信标，以便第三方内容提供商根据您在服务中查看的第三方内容在您的浏览器中读取和写入cookies。此外，我们也可能会采用第三方按钮（例如Facebook“喜欢”或“分享”按钮），即使您不对按钮进行操作，第三方也可能通过第三方的浏览器cookies收集有关您的信息。通过网络信标和按钮收集的信息由第三方直接收集，我们不参与该数据传输。第三方以此方式收集的信息须遵守第三方自己的数据收集、使用和披露政策。</Typography>


              <Typography variant="subtitle2">2) 我们如何使用您的个人信息</Typography>
              <Typography variant="subtitle2">我们可能会出于以下目的使用本政策第1节“我们收集哪些您的个人信息”项下所收集的您的个人信息：</Typography>
              <Typography variant="subtitle2">为履行与您之间的协议：</Typography>
              <Typography variant="subtitle2">当您选择接受我们的“使用协议”并继续使用我们服务时，我们之间达成一份协议。为了履行这份协议并为您提供您选择的服务，我们将使用您的必要个人信息。</Typography>
              <Typography variant="subtitle2">为维护我们服务的安全、质量和稳定运行：</Typography>
              <Typography variant="subtitle2">我们将使用您的个人信息以确保我们服务的安全及稳定，以及为您提供更好的服务质量。</Typography>
              <Typography variant="subtitle2">改进服务及研发新产品：</Typography>
              <Typography variant="subtitle2">我们使用服务中收集的信息（如日志信息）来了解和分析用户的使用趋势和偏好，分析产品故障、服务稳定性、大网运行状况，用以解决故障、改进服务，以及开发新产品、服务和功能。</Typography>
              <Typography variant="subtitle2">进行结算及申报纳税：</Typography>
              <Typography variant="subtitle2">为了向您提供账单、完成服务费用的结算，以及按照所适用的法律法规的规定申报纳税，我们会使用您的账户ID、姓名、邮箱及银行账号信息。</Typography>
              <Typography variant="subtitle2">联系与推送：</Typography>
              <Typography variant="subtitle2">我们可能会使用您的电子邮件或我们在服务中收集的其他信息，用于(i)与您联系，例行管理，如客户服务、处理知识产权侵权、隐私侵犯、处理与服务上发布的用户内容相关的争端解决；或(ii)发送与我方及我们合作的第三方提供的产品和服务相关的促销和活动信息。通常您可以选择退出接收任何促销信息。</Typography>
              <Typography variant="subtitle2">提供定制化内容：</Typography>
              <Typography variant="subtitle2">我们可能会将您的个人信息用于：(i)记住您，以便您在访问服务期间或下一次访问时不必再重新输入；(ii)向您提供个性化展示，例如记住您对字体、语言、区域的偏好设置，避免您每次登陆时重复操作；(iii)提供定制的广告、内容和信息。您可以通过账户管理界面或浏览器设置界面取消这些定制化设置或者变更相应的设置。</Typography>
              <Typography variant="subtitle2">优化广告：</Typography>
              <Typography variant="subtitle2">我们和我们的广告合作伙伴可能会将您的个人信息用于优化广告投放，此类信息包含您在服务中的活动、您的浏览记录、产品偏好、公司名称、技术平台。</Typography>
              <Typography variant="subtitle2">进行数据统计：</Typography>
              <Typography variant="subtitle2">我们可能会将个人信息脱敏并整合，用于监测和分析第三方营销活动的有效性，以及监控服务质量指标，如访问者总人数、总浏览页面数、在线用户数、用户使用时长、用户用量。</Typography>
              <Typography variant="subtitle2">履行法定义务：</Typography>
              <Typography variant="subtitle2">为遵守法律法规的要求，我们可能会使用您的个人信息。</Typography>
              <Typography variant="subtitle2">维护合法权益：</Typography>
              <Typography variant="subtitle2">出于维护您或其他个人的生命、财产等重大合法权益，或者为维护我们的合法权益，我们可能会使用您的个人信息。</Typography>
              <Typography variant="subtitle2">其他目的：</Typography>
              <Typography variant="subtitle2">在使用用户个人信息时有必要超出本政策既定的使用目的或范围的，我们将另行取得您的同意。</Typography>
              <Typography variant="subtitle2">如果您位于欧洲经济区（EEA）或英国，我们仅根据有效的法律依据处理您的个人信息，包括：</Typography>
              <Typography variant="subtitle2">您已同意我们使用您的个人信息；</Typography>
              <Typography variant="subtitle2">我们需要您的个人信息为您提供服务，包括账户注册和回复您的请求；</Typography>
              <Typography variant="subtitle2">我们有法律义务使用您的个人信息；</Typography>
              <Typography variant="subtitle2">我们或第三方有合法权益使用您的个人信息。尤其是我们有合法权益使用您的个人信息进行业务分析，以及提高我们服务的安全性和性能。我们仅在不与您的权益冲突的情况下，依靠我们或第三方的合法利益来处理您的个人信息。</Typography>


              <Typography variant="subtitle2">3) 我们如何共享、转让及公开披露您的个人信息</Typography>
              <Typography variant="subtitle2">除本政策所述或者在收集您的信息时特别说明外，未经您的明示同意，我们不会将您的信息共享给第三方。在以下情况下，我们可能会向第三方共享您的信息：</Typography>
              <Typography variant="subtitle2">我们的关联公司：</Typography>
              <Typography variant="subtitle2">我们可能会向我们的关联公司披露您的个人信息。“关联公司”指直接或间接控制我们、受我们控制或与我们共同受他人控制的任何其他主体。</Typography>
              <Typography variant="subtitle2">其他用户：</Typography>
              <Typography variant="subtitle2">若您将您的信息发布在可供公众访问的区域或者在使用服务过程中分享您的信息，则其他用户将可能获取、阅读、使用您发布、分享的信息，包括您的头像、图片、肖像、声音、视频、音频、文字、观点等。</Typography>
              <Typography variant="subtitle2">销售商与服务供应商：</Typography>
              <Typography variant="subtitle2">在我们与第三方服务提供商的合作中，第三方可为我们提供网站或应用程序开发、托管、维护和其他服务。作为向我们提供这些服务的一部分，该第三方有可能可访问或处理您的信息。我们将向这些服务提供商提供的信息限制在为履行其职能所必需范围之内，同时我们要求该服务提供商同意不得为履行其职能以外的目的而使用或披露个人信息，并履行保密义务。</Typography>
              <Typography variant="subtitle2">广告合作伙伴：</Typography>
              <Typography variant="subtitle2">我们可能会和第三方广告合作伙伴分享您的个人信息，以便提供我们认为您可能感兴趣的广告内容。若您想了解我们的广告营销活动和第三方广告伙伴，请参考以上“我们的Cookie政策”一节。</Typography>
              <Typography variant="subtitle2">企业客户：</Typography>
              <Typography variant="subtitle2">如果您作为我们企业客户的终端用户使用服务，我们可能会向企业客户披露您的个人信息，例如您的IP地址、设备信息、使用我们服务的情况以及用户ID。</Typography>
              <Typography variant="subtitle2">其他第三方： 在以下情况中，我们可能会与其他第三方分享您的个人信息:</Typography>
              <Typography variant="subtitle2">a、以整合的形式： 在不透露您的隐私资料或在取得您的授权的前提下，我们可能会将某些自动收集、汇总或其他非个人身份相关的信息提供给第三方，用于以下用途：（i）履行各种报告职能；（ii）运营或营销目的；或（iii）协助第三方了解用户对于服务提供的特定程序、内容、服务、广告、促销和/或功能的兴趣、习惯和使用模式。</Typography>
              <Typography variant="subtitle2">b、履行法律责任： 如根据法律法规要求，或为执行法庭判决、裁定、或为遵守相关政府机关的要求我们无须就保留、保存、共享、转让或披露您的信息征得您的事先授权同意。</Typography>
              <Typography variant="subtitle2">c、保护和实施我们的权利： 基于诚信如果我们认为合理或必要，我们还保留披露您的信息的权利，以便（i）采取预防措施防止赔偿责任，（ii）保护自己或他人免受欺诈或非法使用或活动，（iii）对任何第三方索赔或指控进行调查和辩护，（iv）保护服务以及服务所需的任何设施或设备的安全性或完整性，或（v）保护我们的财产或其他合法权利（包括但不限于执行我们的协议），保护其他人的权利、财产或安全。</Typography>
              <Typography variant="subtitle2">d、通过您的同意： 我们也可以在收集信息时以通知的形式说明将如何披露收集到的信息，或通过您明示同意以任何其他方式披露该信息。</Typography>
              <Typography variant="subtitle2">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情形除外：</Typography>
              <Typography variant="subtitle2">在获取明确同意的情况下转让：</Typography>
              <Typography variant="subtitle2">获得您的明确同意后，我们会向其他方转让您的个人信息；</Typography>
              <Typography variant="subtitle2">在涉及合并、收购或破产清算时：</Typography>
              <Typography variant="subtitle2">如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</Typography>
              <Typography variant="subtitle2">我们仅会在以下情形下，公开披露您的个人信息：</Typography>
              <Typography variant="subtitle2">获得您明确同意后；</Typography>
              <Typography variant="subtitle2">基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</Typography>


              <Typography variant="subtitle2">4) 您的信息保护权利</Typography>
              <Typography variant="subtitle2">账户管理：</Typography>
              <Typography variant="subtitle2">您可以拒绝与我们分享某些个人信息，在这种情况下，我们可能无法向您提供本服务的某些功能。您可以随时在服务中访问您的账户设置页面，查询、更新、更正或删除您的个人信息和偏好设置或者注销用户账户。如果您希望访问或修改我们所持有的任何其他关于您的个人信息，或要求我们删除通过企业客户获得的任何有关您的信息，您可通过 privacy@agora.io 与我们联系。请注意，尽管您所做的任何更改将立即或在合理的时间内反映在有效用户数据库中，我们可能会在适用法律允许的范围内保留您提交的所有信息，用于备份、归档、防止欺诈和滥用、分析、履行法律义务。</Typography>
              <Typography variant="subtitle2">取消订阅：</Typography>
              <Typography variant="subtitle2">如果您收到我们的商业电子邮件，您可以随时按照电子邮件中的说明取消订阅。您也可以通过发送电子邮件至 privacy@agora.io ，或寄信至本文末尾处列出的地址，要求不再向您发送商业电子邮件和不时向您发送的任何其他促销信息。我们可允许您在本服务的用户账户功能中查看和修改关于接收促销邮件性质以及接收频率的设置。</Typography>
              <Typography variant="subtitle2">请注意：如您选择不接收商业电子邮件，或修改促销信息性质以及接收频率，我们可能需要十个工作日来处理您的请求，在这段时间内您仍可能会收到我们发送的促销信息，除非适用法律要求我们加快处理进程。此外，即使您选择不接收我们的商业消息，您仍将继续收到我们有关本服务的管理信息。</Typography>
              <Typography variant="subtitle2">隐私设置：</Typography>
              <Typography variant="subtitle2">虽然我们可能允许您调整您的隐私设置以限制访问某些个人信息，但请注意，没有任何安全措施是完美的或坚不可摧的。对于在服务中规避隐私设置或安全措施的行为我们概不负责。另外，对于您选择与其共享信息的其他用户所进行的操作，我们也无法控制。此外，即使在服务上发布的信息被删除之后，缓存和归档服务也可能已经保存了该信息，并且其他用户或第三方可能已经复制或存储了服务上的可用信息。我们不能也不保证您发布或传送到服务的信息将不会被未经授权的人员查看。</Typography>
              <Typography variant="subtitle2">禁止追踪：</Typography>
              <Typography variant="subtitle2">有些网页浏览器具备禁止追踪功能。但由于缺乏统一的响应标准，我们的网站目前还不能处理禁止追踪的信号。</Typography>
              <Typography variant="subtitle2">其他个人信息保护权利。 您还享有以下个人信息保护权利：</Typography>
              <Typography variant="subtitle2">a、撤销同意的权利： 在我们依赖于您的同意而收集、处理您的个人信息的场景下，您有权在任何时候通过发出书面通知的方式要求撤销您的同意，我们将在核实您的身份后三十日内处理您的请求。</Typography>
              <Typography variant="subtitle2">b、访问个人信息的权利： 您有权要求访问我们收集的您的个人信息，以及要求我们向您解释我们怎样使用以及向谁分享您的个人信息。</Typography>
              <Typography variant="subtitle2">c、纠正个人信息的权利： 您有权纠正您的个人信息，以确保您的个人信息是准确及完整的。</Typography>
              <Typography variant="subtitle2">d、删除个人信息的权利： 您有权要求我们删除您的个人信息。通常情况下，我们将尊重您的删除权，但若所适用的法律法规对保存您的个人信息有明确规定或者我们有合法权益保留您的个人信息且这种权益能超越您的个人权利，则我们将保留所必需的信息。</Typography>
              <Typography variant="subtitle2">e、约束信息系统自动决策的权利： 在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权要求我们作出解释，我们也将提供适当的救济方式。</Typography>
              <Typography variant="subtitle2">f、获取个人信息副本的权利： 在我们基于您的同意或者与您之间的协议收集您的个人信息的场景下，您有权要求我们向您提供一份以合理结构及常用形式展示的、机器可读取的您的个人信息副本，或者要求我们将您的个人信息转移给您指定的第三方。</Typography>
              <Typography variant="subtitle2">为实现您的上述权利，您可以向privacy@agora.io发出书面通知。为了核实您的身份，我们可能会要求您提供您的个人信息，并在核实您的身份后予以删除。我们将在核实您的身份后三十日内进行处理。若我们无法处理的，我们亦将在核实您身份后三十日内予以回复，并解释为什么我们无法进行处理。</Typography>
              <Typography variant="subtitle2">对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情形收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份系统/服务器上存放的信息）的请求，我们可能会予以拒绝。在以下情形中，我们将无法响应您的请求：</Typography>
              <Typography variant="subtitle2">与我们或者我们企业客户（作为个人信息控制者）履行法律法规规定的义务相关的；</Typography>
              <Typography variant="subtitle2">与国家安全、国防安全直接相关的；</Typography>
              <Typography variant="subtitle2">与公共安全、公共卫生、重大公共利益直接相关的；</Typography>
              <Typography variant="subtitle2">与刑事侦查、起诉、审判和执行判决等直接相关的；</Typography>
              <Typography variant="subtitle2">我们或者我们企业客户（作为个人信息控制者）有充分证据表明个您存在主观恶意或滥用权利的；</Typography>
              <Typography variant="subtitle2">出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</Typography>
              <Typography variant="subtitle2">响应您的请求将导致个您或其他个人、我们的合法权益受到严重损害的；</Typography>
              <Typography variant="subtitle2">涉及商业秘密的。</Typography>


              <Typography variant="subtitle2">5) 企业客户及第三方服务提供商的除外适用</Typography>
              <Typography variant="subtitle2">本政策仅适用于声网直接处理的个人信息。声网主要为企业客户提供服务。若我们企业客户在他们的产品内嵌入了我们的服务（如我们的实时音视频信息服务、录制服务、云录制服务、鉴黄服务等），而您作为该企业客户的终端用户在与我们的产品互动过程中提供了您的个人信息。在这种场景下，我们作为信息处理方，遵照我们与企业客户的协议以及企业客户的指令处理您的个人信息，企业客户作为信息控制方对处理您的个人信息承担责任，并负有义务向您披露如何处理、保护您的个人信息。若您希望了解企业客户如何处理、分享、保护您的个人信息，请参阅该企业客户的隐私政策。若您对此有任何问题，请直接联系该企业客户。</Typography>
              <Typography variant="subtitle2">此外，本服务可能包含第三方提供的功能或第三方提供的网站和服务的链接。本政策所描述的规定与程序不适用于第三方网站。您向第三方网站或服务提供的任何信息都将直接提供给这些服务的运营商，即使通过本服务进行访问，也须遵守该运营商适用的隐私政策和用户协议（如有）。对于通过本服务提供链接或访问的第三方网站或服务的内容、隐私和安全措施和政策，我们概不负责。我们建议您在向第三方提供信息之前先了解其隐私政策和用户协议。</Typography>
              

<Typography variant="subtitle2">6) 我们如何处理儿童的个人信息</Typography>
              <Typography variant="subtitle2">我们的服务主要面向成年人。如果没有父母或者监护人的同意，儿童不应使用我们的服务或者注册我们的账户。对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。尽管当地法律和习俗对儿童的定义不同，但我们将不满 14 周岁的任何人均视为儿童。如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</Typography>
              <Typography variant="subtitle2">若我们的企业客户使用我们的产品为儿童提供服务，则由我们企业客户负责取得儿童的父母或者监护人的同意。若此种场景下您作为儿童的父母或者监护人认为我们的企业客户未取得您的同意而通过我们的产品收集儿童信息，请第一时间通知我们，我们在核实情况后尽快删除相关数据。</Typography>
              

<Typography variant="subtitle2">7) 我们如何保护您的信息安全</Typography>
              <Typography variant="subtitle2">我们承诺，将严格遵守中华人民共和国的有关法律、法规的规定，采取必要而适当的安全技术措施，对用户的个人信息进行保存、管理、传输，确保用户的个人信息准确、完好，并防止该等个人信息被篡改、泄露、或灭失。</Typography>
              <Typography variant="subtitle2">我们采用特定的物理、管理和技术保障措施，旨在保护我们所收集的个人身份信息免于意外或非法破坏，丢失，未经授权更改、披露或访问，滥用以及任何其他非法形式的个人信息处理。但是，我们不能确保或保证您传送给我们或存储在本服务上的任何信息的安全性，对此您将自行承担风险。此外，我们也无法保证他人不违反我们的物理、技术或管理保障措施，对此类信息进行访问、披露、更改或毁坏此类信息。上述规定受适用法律要求的约束，以保证信息安全。</Typography>
              <Typography variant="subtitle2">互联网环境并非百分之百安全，我们将尽力确保用户发送给我们的任何信息的安全性，但用户在使用我们提供的服务时应充分理解互联网环境所带来的风险。</Typography>
              <Typography variant="subtitle2">如果不幸发生个人信息安全事故，我们将按照法律法规的要求，及时以电子邮件的方式向您告知，以便您采取适当的保护措施。难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事故的处置情况。</Typography>
              

<Typography variant="subtitle2">8) 我们在多长时期内保留您的个人信息</Typography>
              <Typography variant="subtitle2">不同目的收集的信息可能会适用不同的信息保留期限。我们仅在为实现目的的最低期限或者法律要求的期限内保留您的个人信息。在您停止使用我们的服务、注销您的账户时，我们原则上将在三十日内删除您的个人信息，除非所适用的法律或者为实现其他合法目的需要保留更长的期限。</Typography>


              <Typography variant="subtitle2">9) 您的个人信息如何在全球范围转移</Typography>
              <Typography variant="subtitle2">原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。由于我们通过遍布全球的资源和服务器提供服务，这意味着，若您与其他国家/地区的主体进行实时互动，或者您在其他国家/地区使用我们的服务，则您的个人信息将会被转移、传输到其他国家/地区，或者被其他国家/地区的服务器访问。此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化、匿名化或数字加密等安全举措。</Typography>


              <Typography variant="subtitle2">10) 本政策如何更新</Typography>
              <Typography variant="subtitle2">本政策可能会不时更新，并在网站上公布，请定期重新访问此页面，以便了解本政策的任何变更。在本政策更新发布后，您继续使用我们提供的服务的，即表明您已经同意相关变更或更新。</Typography>
              <Typography variant="subtitle2">对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件等形式发送通知，或者在进行此类更改后当您首次访问服务时，生成弹出式窗口或类似通知形式，说明本政策的具体变更内容）。</Typography>


              <Typography variant="subtitle2">11) 如何联系我们</Typography>
              <Typography variant="subtitle2">声网是对本政策中描述的个人信息处理负责的实体。对于本政策或相关事宜有任何问题、意见或建议，或希望行使您的权利，请通过以下联系方式与我们联系，一般情况下，我们将在三十日内予以回复：</Typography>
              <Typography variant="subtitle2">地址：上海市杨浦区淞沪路333号12号楼8层   声网法务部</Typography>
              <Typography variant="subtitle2">电子邮件： privacy@agora.io</Typography>
              <Typography variant="subtitle2">若您是我们的终端用户，我们将直接回复您的请求，并进行处理。若您是我们企业客户的终端用户，我们将及时将您的请求转达我们的企业客户，并由企业客户回复您及进行处理。我们仅在企业客户明确授权的情况下才会直接回复您并根据企业客户的指令进行处理。</Typography>
              <Typography variant="subtitle2">若您对我们的回复不满意，或者认为我们的处理方式损害了您的合法权益，您还可以寻求司法救济。</Typography>



              <Typography variant="subtitle2">Agora 服务条款</Typography>
              <Typography variant="subtitle2">1. 接受Agora平台条款与条件</Typography>
              <Typography variant="subtitle2">这些条款和条件（“条款”）约束您访问 Agora 网站（“网站”）中包含的信息，使用 Agora 应用程序界面（API）、示例软件代码和其他Agora（“平台”）提供或made available的相关材料，使您能够在您拥有和控制的一个或多个网站、Web应用程序或网站（“您的产品”）上实施 Agora（“服务”）提供的 Agora实时视频平台服务。</Typography>
              <Typography variant="subtitle2">在本条款中，“ Agora ”，“我们”，“我们”，“我们的”和类似称谓是指 Agora Lab， Inc。</Typography>
              <Typography variant="subtitle2">“您”或“您的”是指已下载并正在使用平台和服务的人，并且是本条款的被许可人，或您代表其行事的雇主或其他实体。</Typography>
              <Typography variant="subtitle2">要使用本网站、平台和服务，您必须接受并同意接受这些条款的约束。您同意将这些条款纳入您的产品，并确保您的客户遵守这些条款。</Typography>
              <Typography variant="subtitle2">我们保留随时更改、修改或更新平台的权利，对服务施加额外的限制（包括但不限于服务保留的数据或其他内容的最长期限，和代表您在Agora服务器分配的最大存储空间），或更改、修改或更新全部或部分服务收取的费用。您同意Agora对于删除或未能存储本服务维护或传输的任何数据或其他内容不承担任何责任或义务。Agora可以决定服务包含哪些功能，以及将其设置为可选或必选。您确认Agora保留随时自行决定更改这些通用做法和限制的权利，无论通知与否。</Typography>
              <Typography variant="subtitle2">我们还保留随时更改、修改或更新条款的权利。如果我们修改条款，我们将在我们的网站上发布修订后的条款，并且您同意通过此类发布接收任何更改通知。如果法律要求，Agora 还将按照第18条的规定向您提供更改通知。如果您不同意修订，则必须停止使用平台并从产品中删除服务。在发布修订条款后，您继续使用平台或使用产品服务意味着您已接受新条款。</Typography>
              <Typography variant="subtitle2">2. API密钥</Typography>
              <Typography variant="subtitle2">您同意对您个人信息的收集和处理，以便为您提供“API”密钥。如果您与任何人共享您的API密钥，您（而非 Agora）将对此人可能采取的任何行为负责。如果您认为某人未经您的许可使用了您的API密钥，请与我们联系security@Agora.com 。</Typography>
              <Typography variant="subtitle2">3. 资格和授权</Typography>
              <Typography variant="subtitle2">如果您代表公司注册平台和服务，则表示您已获得正式授权代表该公司并代表该公司接受本条款。如果您代表贵公司签订这些条款，则这些条款中的“您”和“您的”指的是您的公司及其所有员工。 如果您以个人的名义 签订这些条款，同意接受这些条款的约束。在这种情况下，“您”和“您的”指的是您本人。</Typography>
              <Typography variant="subtitle2">4. 许可</Typography>
              <Typography variant="subtitle2">在您遵守这些条款以及服务的付款要求的前提下，Agora 授予您有限的、非独占的、不可再授权的、可撤销的、不可转让的许可，在您的产品中使用平台进行展示、接入和实施服务，完全符合这些条款和条件。未经 Agora 事先的书面同意，您不得出于任何其他目的安装或使用本平台。</Typography>
              <Typography variant="subtitle2">您确认平台和服务受到任何国家/地区的版权、商标、服务商标、国际条约和/或其他所有权和可适用法律的保护和约束。以及您确认在双方之间，平台和服务中的所有权和知识产权，包括但不限于AGORA商标和所有相关商业名称、服务商标、形象徽标、域名等（“ Agora 商标”），所有权和使用权仅属于Agora。除非另有明确规定，否则这些条款不授予您对Agora 拥有或许可的任何知识产权任何权利、所有权、许可或权益，包括（但不限于）平台、服务或Agora商标。</Typography>
              <Typography variant="subtitle2">您不得向任何人出售、转移、转让、出租、租赁或再许可Agora的代码、平台或服务。</Typography>
              <Typography variant="subtitle2">如果通过本服务提供的任何功能由其他第三方提供，Agora将作出合理努力，向您传达这些第三方的任何政策、要求或规定。您同意遵守这些政策、要求或规定。</Typography>
              <Typography variant="subtitle2">5. 整合您的产品服务</Typography>
              <Typography variant="subtitle2">本服务包括 Agora 商标。您同意不删除、隐藏或更改服务中包含的任何商标或任何显示Agora商标的通知。您可以在您的产品上显示或不显示Agora商标，前提是（i）本服务的商标显示遵循Agora商标规定（ii）仅为了披露您的产品以实施本服务，但不代表Agora与您的产品存在进一步的关系或背书。</Typography>
              <Typography variant="subtitle2">除了Agora提供的API配置选项之外，未经 Agora 明确书面同意，您不得也不能允许任何第三方变更、更改或修改本服务的任何用户界面、特性或功能 。</Typography>
              <Typography variant="subtitle2">除非平台提供，否则您不得也不能允许任何第三方复制、反向工程、反编译或反汇编Agora的代码、平台或服务，或构建访问服务的替代方法（但适用成文法明确禁止此类限制的有限情况例外）。</Typography>
              <Typography variant="subtitle2">尽管本条前述段落有所规定，Agora对平台的特定组件（例如Agora HTML Embed）以软件许可的形式授予许可。在这些情况下，您同意不会在该代码的任何修改版本中使用任何 Agora商标，包括我们提供的代码中自带的商标，除非（i）它遵循Agora的商标规定（ii）您已与Agora签订单独的商标许可书面协议。</Typography>
              <Typography variant="subtitle2">您同意在本服务和平台有所修改并发布后，合时合理地更新Agora提供的代码。</Typography>
              <Typography variant="subtitle2">Agora可能会更新我们服务器上的文件，这些文件会自动更改平台或服务的功能，您同意这些更新。</Typography>
              <Typography variant="subtitle2">您不得模糊或覆盖服务的任何图形元素，或以其他方式干扰平台或服务的运行。</Typography>
              <Typography variant="subtitle2">当用户与服务交互时，Agora接收并存储某些非身份相关信息以及整体用户信息和统计数据，例如唯一用户数，会话数和总流分钟数。Agora可能自行存储此类信息，或者将此类信息与Agora附属公司、代理商、服务提供商以及当前和潜在的业务合作伙伴存储和共享 。Agora使用上述信息来提供、改进、营销和增强其产品和服务，以及达到其他合法的商业目的。</Typography>
              <Typography variant="subtitle2">Agora 保留对访问平台或服务的数量加以限制的权利。Agora保留酌情限制在您的产品中同时进行的视频聊天会话的权利。</Typography>
              <Typography variant="subtitle2">这些条款不授予您对平台或服务的任何支持的所有权，除非您与Agora另有约定并支付与此类支持相关的所有费用（如有）。Agora提供的任何此类支持均受依照相关支持协议修改的条款约束。</Typography>
              <Typography variant="subtitle2">您特此授予Agora有限的、非独家的、不可转让的、不可再授权的许可，显示您的商品名称、商标、服务商标、形象徽标、域名等，以便宣传您使用了本平台和服务。</Typography>
              <Typography variant="subtitle2">6. 免费试用</Typography>
              <Typography variant="subtitle2">Agora自行决定您是否有资格免费试用本服务。</Typography>
              <Typography variant="subtitle2">我们保留限制每个帐户免费试用次数，并采取措施防止滥用的权利。</Typography>
              <Typography variant="subtitle2">您必须同意这些条款才有资格获得免费试用。</Typography>
              <Typography variant="subtitle2">在免费试用期间，您被授予的权利仅限在Agora允许的范围内访问和使用平台和服务。</Typography>
              <Typography variant="subtitle2">在免费试用期间，Agora没有义务以任何方式向您提供客户支持或技术协助。提供的任何客户支持或技术协助只可保证尽力而为。</Typography>
              <Typography variant="subtitle2">Agora可以随时更改免费试用的条件或完全停止试用，恕不另行通知。</Typography>
              <Typography variant="subtitle2">在免费试用期间，您仅可将本服务用于内部演示目的。Agora明确禁止您在免费试用期间出于任何商业目的（“实时部署”）在任何公共及私人网站、移动应用程序上部署服务，包括但不限于（i）用于通过服务直接或间接产生广告收入，（ii）用于诱导下载任何类型的工具栏、插件或下载程序，（iii）作为任何类型付费服务的一部分，（iv）向您的客户或用户提供任何形式的付费或非付费支持，或（v）作为任何品牌、产品或服务促销或任何形式的传播活动的一部分。Agora自行认定您的免费试用是否属于实时部署，保留随时终止您的免费试用权并暂停您的API密钥的权利。</Typography>
              <Typography variant="subtitle2">Agora可能会不时与您联系，您可以选择取消订阅这些通信。</Typography>

              <Typography variant="subtitle2">7. 费用与发票</Typography>
              <Typography variant="subtitle2">在服务或其任何可收费部分的范围内，您同意支付我们网站定价部分规定的所有适用费用（包括任何最低订阅费用）。您可以通过您的个人账户直接在线付款购买服务，也可以通过对公账户向Agora指定账户支付服务费。除非您与我们另有书面协议约定，我们可能会通知您，提高或增加任何现有服务或服务功能的收费，具体价格以Agora官网（<a href="https://www.agora.io/cn/price/">https://www.agora.io/cn/price/</a>）最新公布的定价内容为准。您应支付的所有费用不包括中国和外国税，进口关税，出口关税，预扣税和类似税款核定（包括但不限于销售税、使用税和增值税）（“额外费用”），以及您同意承担并负责支付所有此类额外费用，不包括基于Agora净收入的税款。</Typography>
              <Typography variant="subtitle2">除非您与我们另有书面协议约定，您在付款后向我们申请发票时应通过 <a href="https://dashboard.agora.io/finance/receipt">https://dashboard.agora.io/finance/receipt</a> 提交开票申请和开票所需信息，发票开具的具体方式及发票开具的时间请查看 <a href="https://docs.agora.io/cn/Agora%20Platform/apply_invoice?platform=All%20Platforms">https://docs.agora.io/cn/Agora%20Platform/apply_invoice?platform=All%20Platforms</a>。</Typography>
              <Typography variant="subtitle2">Agora服务的结算方式、计费标准、付款方式及其他商务信息参见Agora官网（<a href="https://docs.agora.io/cn/Interactive%20Broadcast/billing_rtc?platform=All%20Platforms">https://docs.agora.io/cn/Interactive%20Broadcast/billing_rtc?platform=All%20Platforms</a>），您可以参照网站信息执行，双方另行达成书面协议的情形除外。我们可以指定您以何种方式支付任何费用，而任何此类款项应受不时生效的通用应收账款政策约束。您根据本协议应付的所有款项将不可抵销或反索赔，也不会减免或预扣。如果适用法律要求任何减免或预扣，您应通知我们并在必要时向我们支付额外款项，以确保我们在减免和预扣后收到的净额等于我们在没有此类减免或预扣情况下应当收到的金额。</Typography>
              <Typography variant="subtitle2">Agora保留在您未按照约定支付全部费用之前，不向您提供服务和/或技术支持（对于预付费服务），或终止提供服务和/或技术支持（对于后付费服务）的权利。您理解并同意，Agora保留对后付费服务中的欠费行为追究法律责任的权利。</Typography>
              <Typography variant="subtitle2">如果您对与您的帐户相关的费用有争议，请通过billingcn@Agora.com与我们联系。在产生此争议的活动发生之日起30天内，我们将尽力解决此事。为解决此类争议而发放的任何和所有退款均应作为信用额度发放到您的账户，但在任何情况下都不会有任何现金退款。超过90天的争议无权获得任何退款或信用额度。</Typography>
              <Typography variant="subtitle2">Agora月度订阅自动续订。如果您想取消续订，则必须在每月续订日期的至少24小时前登录您的帐户以取消订阅。如果您有任何疑问，请联系我们。</Typography>
              
<Typography variant="subtitle2">8. 通信记录的归档</Typography>
              <Typography variant="subtitle2">您承认并理解有关于您所经营业务所在地有关通信电子记录的法律规定，以及Agora不对任何本服务的非法使用负责。在决定是否使用本服务的录制功能时，您应仔细审阅自己的情况，并且您有责任根据适用的法律确定电子录音是否合法。您同意遵守并要求您的用户遵守所有适用的法律，包括与谈话各方的通信隐私、用户数据收集、处理、传输、存储、转让和删除相关的的可适用法律，包括在必要时告知所有视频聊天的参与者该视频聊天正在被录制。此外，您承认Agora不保证通信记录的存储，并同意Agora对任何损害、责任、遗失（包括任何数据丢失或利润损失）或记录通信的丢失或删除可能对您造成的任何其他后果不承担任何责任。</Typography>
              <Typography variant="subtitle2">9. 禁止的网站和内容</Typography>
              <Typography variant="subtitle2">您对通过您的产品提供的所有内容、服务和广告承担全部责任。如果您的产品或您产品提供的任何内容、服务或广告（或者您对本服务的使用）符合以下任何描述，则您不得合并使用或关联使用本服务：</Typography>
              <Typography variant="subtitle2">主要针对13岁及以下儿童，或13岁及以下儿童占其用户的很大比例；</Typography>
              <Typography variant="subtitle2">在与Agora提供的产品或服务竞争的产品或服务中使用本平台，或使用本平台开发此类产品或服务；</Typography>
              <Typography variant="subtitle2">包含成人娱乐内容，包括色情、情色内容，明显性内容、卖淫或任何其他不适合一般观众的内容；</Typography>
              <Typography variant="subtitle2">提供或推广赌博、涉及支付任何代价的机会游戏、或非法博弈和竞赛；</Typography>
              <Typography variant="subtitle2">推广、鼓励或促进任何非法活动，违反法律或侵犯任何第三方的权利（包括但不限于知识产权、隐私权或人格权）；</Typography>
              <Typography variant="subtitle2">构成、推广或用于处理：间谍软件、广告软件或其他恶意程序或代码；仿冒品；受中国、美国或其他可适用法律（包括国际法律）所禁运限制的物品；群发垃圾邮件；多层次传销内容； 仇恨宣传材料；黑客/监视/拦截/解扰设备； 中伤、诽谤、辱骂、骚扰或其他令人反感的内容； 身体部位或体液；失窃产品或用于盗窃的物品； 烟花、爆炸物或有害物质；武器。</Typography>
              <Typography variant="subtitle2">如果您不确定您的产品是否符合上述任何描述，请发送电子邮件至legal@Agora.com，以便我们讨论您建议的用途是否可接受。</Typography>
              
<Typography variant="subtitle2">10. 您的合规义务</Typography>
              <Typography variant="subtitle2">隐私。如果您的产品收集、展示或传输任何有关您用户的个人信息，您将突出显示符合所有适用法律的隐私政策，并使用户清楚了解您收集的数据以及您将如何使用、展示或分享该数据。您同意将结合自身业务经营所在地及用户所在地等标准自行完成所适用隐私及信息保护相关的规定并负责起草所适用的隐私政策，并明确告知Agora作为相关数据的传输方和处理方应配合遵守的法律要求。您将仅根据您的隐私政策和所有适用的法律法规收集、传输和使用您产品上的用户数据。</Typography>
              <Typography variant="subtitle2">数据披露。在不限制本条款规定的义务的情况下，除遵守您的隐私政策和所有适用的法律要求外，您不会披露您通过使用本服务的用户获得的任何用户数据（包括但不限于聊天记录、注册信息、联系信息或IP地址）。</Typography>
              <Typography variant="subtitle2">监控和滥用。Agora不会主动监控本服务，但可以自行决定采取这种做法。但是，Agora不对基于任何用户在本服务中发布的任何评论或内容，或任何本服务用户采取的任何行动提出的任何索赔负责，包括任何用户内容侵犯了任何人的权利，或属于中伤、诽谤或者非法的索赔。您同意自行负责监控和控制与您的产品相关的服务中的滥用或不当行为。您将定期监控并回应滥用报告，包括在适当情况下终止用户帐户。您还将确保您实施本服务的产品 始终遵守对所有用户的约束条款和条件，这些条款和条件对 Agora 和平台的保护（和限制）程度不低于本条款。</Typography>
              <Typography variant="subtitle2">《中华人民共和国网络安全法》的相关规定，您应遵守其中针对网络运行安全及网络信息安全的任何所有规定。</Typography>
              <Typography variant="subtitle2">内容。您对您或您的任何产品用户上传、存储、发布、公开、展示或与本服务有关的传输或使用（以下“简称”发布）的所有信息、数据、文本、通信、录音、视频、音乐、声音、照片、消息或其他材料（以下简称“内容”）全权负责。以下是非法或Agora禁止的内容和/或使用的示例。Agora保留自行决定对任何违反这些条款的人进行调查并采取适当法律行动的权利，包括但不限于从服务中删除违规内容、暂停或终止此类违规者的访问并向执法当局举报您。在不限制前述规定的前提下，您不得（也不允许任何第三方）使用本服务：</Typography>
              <Typography variant="subtitle2">（i）发布任何以下内容：非法、骚扰、侵权、诽谤、色情、中伤或侵犯他人隐私；您无权根据任何法律或合同或信托关系进行传播；对任何人构成或造成隐私或安全风险；侵犯任何一方的任何知识产权或其他所有权；包含软件病毒或任何其他旨在中断、破坏或限制任何计算机软件或硬件或电信设备的功能的计算机代码、文件或程序；或者Agora全权判断为令人反感的，或者限制或禁止任何其他人使用或享受本服务，或者可能使Agora或其平台用户遭受任何伤害或负担任何类型的责任；</Typography>
              <Typography variant="subtitle2">（ii）干扰或破坏本服务或与本服务相关的服务器或网络，或违反本服务相关网络的任何要求、程序、政策或规定；</Typography>
              <Typography variant="subtitle2">（iii）违反任何适用的地方、州、地区、国家或国际法律或任何具有法律效力的法规；</Typography>
              <Typography variant="subtitle2">（iv）促进或推广任何犯罪活动或企业，或提供有关非法活动的指导信息； 或</Typography>
              <Typography variant="subtitle2">（v）通过本服务无意提供的任何方式获取或试图访问或以其他方式获取任何材料或信息。</Typography>
              <Typography variant="subtitle2">11. 安全</Typography>
              <Typography variant="subtitle2">联系与合作。如有安全问题或疑虑，我们会通过您在申请平台密钥时提供的联系信息联系您，请您保持在合理的工作时间可联络状态。</Typography>
              <Typography variant="subtitle2">您的网络。您将确保用于运行您的产品的所有网络、计算机和操作系统、软件和其他系统采用安全措施防止未经授权访问或使用任何用户数据和服务。您必须通过security@Agora.com向Agora及时报告任何安全缺陷或系统入侵在。</Typography>
              <Typography variant="subtitle2">免责声明。您了解服务的运营，包括您（或您的用户）的内容可能是未加密的，并涉及（i）通过各种网络进行的传输；（ii）为符合并适配连接网络或设备的技术要求作出的改变，以及（iii）到Agora第三方供应商和托管合作伙伴的传输，以提供运营和维护本服务所需的硬件、软件、网络、存储和相关技术。因此，您同意您对内容的充分安全、保护和备份承担全部责任。对于任何未经授权的对任何内容的访问或使用，或任何内容的损坏、删除、破坏或丢失，Agora不承担任何责任。您进一步确认并同意Agora在法律的要求下可以保留内容，也可以披露内容，或有充分理由相信保存或披露内容才能（i）遵守法律程序、适用法律或政府要求；（ii）执行本条款；（iii）回应对任何侵犯了第三方权利内容的索赔； 或（iv）保护Agora、其用户和公众的权利、财产或人身安全 。</Typography>
              <Typography variant="subtitle2">除了下文第13条所述我们有权终止或暂停向您提供服务之外，您同意：（i）您在任何原因造成的意外或计划外停机、部分或所有服务不可用期间，您对服务的访问和使用可能会暂停，包括停电、系统故障或其他中断；（ii）我们还有权在不对您承担任何责任的情况下，在任何时间，在整个服务范围内暂停对任何部分或全部服务的访问：（a）允许我们进行维护或修改任何服务的计划停机时间； （b）如果发生拒绝服务攻击或其他对服务的攻击，或我们自行判断如果服务没有暂停可能对适用的服务、您或我们的其他客户造成风险的，或（c）我们确定适用法律禁止任何服务，或者出于法律或监管原因（以下统称“服务中止”）确定有必要或为了审慎起见采取此作法。</Typography>
              <Typography variant="subtitle2">12. 陈述和保证</Typography>
              <Typography variant="subtitle2">您声明并保证：</Typography>
              <Typography variant="subtitle2">您有权在您的产品或与本服务一起使用或合并的产品中使用、复制、传输、复制、公开展示、公开演示和分发任何内容或数据，并且您的产品或任何相关内容没有侵犯任何第三方的权利（例如任何个人或实体的版权、专利、商标或其他所有权），或任何适用的法规或法律，包括但不限于任何出口、再出口或进口法律，以及您的内容或服务所在国家/地区的法律；</Typography>
              <Typography variant="subtitle2">您拥有所有必要的权利和授权，以同意这些条款并使用这些条款所阐明的平台和服务；</Typography>
              <Typography variant="subtitle2">您对本条款的同意与遵守以及对平台和服务的使用不会违反任何法律、法规或合同义务。</Typography>
              <Typography variant="subtitle2">13. 期限</Typography>
              <Typography variant="subtitle2">您随时可以出于任何原因，通过删除您的帐户，取消订阅本服务以及禁用您的产品访问平台和使用本服务来终止本服务。</Typography>
              <Typography variant="subtitle2">我们保留权利，因任何里有或无理由，根据下文第18条规定的通知条款，提前三十天向您发出通知，随时暂停或终止您访问或使用任何或所有平台和服务的权利的许可，或全面终止这些条款（以及相应的您使用服务的权利）。如果Agora确定提前通知会对Agora提供服务的能力产生负面影响，Agora可以在不另行通知的情况下暂停您访问或使用任何或所有平台和服务的权利和许可，或全面终止这些条款（并因此停止向您提供所有服务）。</Typography>
              <Typography variant="subtitle2">此外，我们可能会暂停您访问和使用平台和服务的权利和许可，或完全终止这些条款（以及相应的使用服务的权利），原因如下： 在以下情况我们会立即终止服务并通知：（i） Agora确定您的产品对Agora的声誉和商誉有害，（ii）如果您违反第9条的任何规定或我们有理由相信您违反第9条的任何规定，（iii）您对服务的使用量有不寻常的飙升或增加，使我们有理由相信此类流量或使用是欺诈性的，或对服务的运营能力产生负面影响； （iv）我们自行判定我们向您提供任何服务是适用法律所禁止的，或因任何法律或监管原因而变得不切实际或不可行； 或（i）在适用法律的约束下，您进行清算、启动解散程序，处置资产，未能持续经营，为债权人的利益转让，或如果您成为自愿或非自愿破产或类似程序的主体。 如果您违反任何服务的任何付款义务，或者您提供给我们的任何付款机制无效或此类机制拒绝付款，我们会立即终止服务，恕不另行通知。 如果您违反本条款的任何其他条款，且我们自行判定在发出通知五天内没有解决此类违约行为。</Typography>
              <Typography variant="subtitle2">我们因任何原因暂停您使用全部或部分服务：（i）即使暂停，您仍将继续为仍在使用的任何服务付费；（ii）您对服务暂停之日前产生的所有费用，收费和任何其他义务仍然负有责任；（iii）您在服务暂停期间与服务有关的所有权利会被终止。</Typography>
              <Typography variant="subtitle2">出于任何原因终止本条款或您对服务的访问时：（i）您仍然需要承担服务终止前产生的所有服务有关费用，收费和任何其他义务； （ii）Agora授予您的所有权利和许可将终止，（iii）您应立即从您的产品中删除本服务，并从所有计算机、硬盘驱动器、网络和其他存储介质中删除本平台的所有副本，（iv）Agora可能会永久删除您在服务上的所有内容（如有）。尽管本条款中可能存在任何相反的规定，但在本条款规定的双方义务终止后，以下条款仍然有效并完全有效：第12条，第14条，第15条，第16条，第17条，第20条和第21条。</Typography>
              <Typography variant="subtitle2">14. 无Agora担保</Typography>
              <Typography variant="subtitle2">Agora不代表或担保本平台或服务符合任何法律要求，不存在任何不准确、错误、漏洞或中断（包括但不限于服务暂停），或者是可靠、准确、完整或有效的。平台和服务按“现状”和“现有”提供，不提供任何形式的明示或暗示的担保，并且Agora明确免除任何担保和条件，包括但不限于任何暗示担保，适用于特定用途，合法性、可用性、安全性、所有权和/或非侵权性。您对平台和服务的使用由您自行决定并承担风险，并且您将对因使用而造成的任何损害承担全部责任，包括但不限于对您的计算机系统造成的任何损害，或数据的丢失或损坏。</Typography>
              <Typography variant="subtitle2">15. 责任限制</Typography>
              <Typography variant="subtitle2">在任何情况下，Agora均不对您承担任何责任，无论是否违反合同、违反担保、侵权行为（包括疏忽、产品责任或其他原因）（i）任何利润损失，数据丢失或损坏，计算机故障，业务中断，或因为使用平台或服务或与之有关的其他非直接、偶然、间接、特殊或惩戒性损害，任何服务暂停或任何其他特殊损失而导致的故障，无论Agora对此类损害的可能性知情与否，或（ii）任何超出1000.00元人民币的款项，或您在提出索赔的前六个月期间支付给Agora的费用（如更高）。 某些司法管辖区不允许免除某些担保或限制，或免除对偶发或继发损害的责任。因此，上述第14条和第15条的某些限制可能不适用于您。</Typography>
              <Typography variant="subtitle2">16. 赔偿和豁免</Typography>
              <Typography variant="subtitle2">您同意对Agora及其子公司、高级职员、董事、代理商、服务提供商、合作伙伴和员工因下列因素产生的任何第三方索赔、要求或指控，以及所有相关损失、损害、责任、成本和开支（包括律师费）进行赔偿、抗辩及保护其不受损害：</Typography>
              <Typography variant="subtitle2">您对本平台或本服务的使用；</Typography>
              <Typography variant="subtitle2">您的产品的用户对本服务的使用；</Typography>
              <Typography variant="subtitle2">您的产品，包括您产品上的任何内容、服务或广告，或您与本服务合并使用的内容，服务或广告；</Typography>
              <Typography variant="subtitle2">任何因未经授权使用本服务而引起或与之相关的侵犯版权、诽谤、侵犯隐私权或公开权的任何索赔；</Typography>
              <Typography variant="subtitle2">您违反本条款中包含的任何陈述、保证或契约。</Typography>
              <Typography variant="subtitle2">上述赔偿条款应是对这些条款中规定的任何其他赔偿义务的增补而非替代。</Typography>
              <Typography variant="subtitle2">17. 通用信息</Typography>
              <Typography variant="subtitle2">本条款约束您对平台和服务的使用，并构成您与Agora之间关于本协议主题的完整协议。 这些条款取代您与Agora之间有关您使用平台和服务的任何先前协议（包括但不限于本条款的任何先前版本）。Agora没有行使或执行本条款中任何权利或规定，不构成该权利或规定的放弃，任何违约的豁免不代表其他违约的豁免。如果有管辖权的法院认定本条款的任何条目无效，则该条目应以与适用法律一致的方式解释，以尽可能地反映当事方在该条款中反映的原始意图，使本条款的其他条目仍然完全有效。本条款中的所有标题仅为方便起见，不具有法律或合同效力。本条款对您个人而言，没有Agora的事先书面同意，不得以任何理由（包括但不限于法律运作、合并、重组、因收购或涉及您的控制权更改）转让，任何违反前述规定的行动或行为均为无效。Agora明确保留并有权转让本条款，并委任本条款项下的任何义务。</Typography>
              <Typography variant="subtitle2">18. 通知</Typography>
              <Typography variant="subtitle2">我们可能会向您在申请API密钥时提供的电子邮件地址或通过任何其他合理方式向您发送任何通知，包括有关这些条款更改的通知。任何发送给Agora的通知都必须通过普通邮件，航空邮件或隔夜快递发送到上海兆言网络科技有限公司，上海市杨浦区国通路127号16层，送达即视为收到。</Typography>
              <Typography variant="subtitle2">19. 信息移除</Typography>
              <Typography variant="subtitle2">如果第三方认为通过Agora传输或存储的资料侵犯了任何可适用的法律给予该方的知识产权或其他权利，Agora收到将及时处理和调查涉嫌侵权的通知，并将根据所适用的知识产权法，针对任何涉嫌或实际的侵权行为采取适当行动。声明侵犯版权的通知应通过电子邮件发送至Agora，邮件地址为agoralegal@agora.io。</Typography>
              <Typography variant="subtitle2">重复侵权者政策：按照所适用法律，Agora已在适当情况下自行决定采取政策，终止被判定为重复侵权者的用户对平台服务的访问。Agora还可以自行决定限制和/或终止任何侵犯他人知识产权的平台用户的访问权限，无论是否存在重复侵权。</Typography>
              <Typography variant="subtitle2">异议通知：如果您认为已删除的相关内容（或禁用的访问权限）未侵权，或您获得版权所有者、版权所有者代理人或法律提供的发布使用此类内容的授权，您可以向 agoralegal@agora.io 发送包含以下信息的书面抗辩通知：</Typography>
              <Typography variant="subtitle2">你的亲笔或电子签名；</Typography>
              <Typography variant="subtitle2">已删除的内容或已禁用的访问内容以及内容在删除或禁用之前的位置的确认；</Typography>
              <Typography variant="subtitle2">您确信内容因错误或误认而被删除或禁用；</Typography>
              <Typography variant="subtitle2">您的姓名、地址、电话号码和电子邮件地址。</Typography>
              <Typography variant="subtitle2">20. 出口合规</Typography>
              <Typography variant="subtitle2">Agora的产品可能受中国、美国或其他可适用法律（包括国际法律）可适用的出口和再出口管制法律法规的约束，包括但不限于美国商务部维护的《出口管理条例》（Export Administration Regulations, EAR），美国财政部外国资产管制处（Office of Foreign Assets Control, OFAC）维护的贸易和经济制裁和美国国务院维护的《国际武器贸易条例》（International Traffic in Arms Regulations, ITAR）。您保证您（1）不位于古巴、伊朗、朝鲜、苏丹或叙利亚，以及（2）不是上述规定中规定的被拒方。</Typography>
              <Typography variant="subtitle2">您同意遵守所有适用的出口和再出口控制法律和法规，包括EAR、OFAC维护的贸易和经济制裁以及ITAR。具体而言，根据本条款，您不会在未经遵循这些法律法规获得有关政府当局事先授权的情况下，直接或间接出售、出口、再出口、转让、转移或以其他方式处置从 Agora处收到的任何产品、软件或技术（包括衍生自或基于此类技术的产品），至可适用的法律法规禁止的任何目的地、实体或人员。</Typography>
              <Typography variant="subtitle2">此外，您同意在法律允许的最大范围内赔偿Agora您违反此规定可能产生的任何罚款或罚金。此出口管制条款在终止或取消本条款后仍然有效。</Typography>
              <Typography variant="subtitle2">21. 管辖法律</Typography>
              <Typography variant="subtitle2">这些条款应受可适用法律的管辖，但不适用法律冲突原则。您在此同意接受位于Agora注册地所在法院的专属管辖，解决根据这些条款产生的任何争议。在每种情况下，这些条款的解释和执行均不考虑《联合国国际货物销售合同公约》（United Nations Convention on Contracts for the International Sale of Goods）。</Typography>
            </div>
        }
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
                <Button
            id="button-gdpr-agree"
            onClick={props.onOk}
            variant="outlined"
            color="secondary"
            size="small"
          >
            {!isChinese() ? "Agree" : "同意"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})

export default GDPRTermsView
