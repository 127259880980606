import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconIssueMore } from "../../assets/icon_issue_more.svg";
import { ReactComponent as IconClearable } from "../../assets/icon_clearable.svg";
import { ReactComponent as IconRoomName } from "../../assets/icon_room_name.svg";
import { ReactComponent as IconPassword } from "../../assets/icon_pass_word.svg";
import { ReactComponent as IconNickName } from "../../assets/icon_nick_name.svg";
import { getAgoraRoomShareInfoByLinkId } from "../../stores/connector/http";
import { useNotificationStore, useUIStore, useUserStore } from "../../stores/hooks";
import { ERR_SHARE_CODE_EXPIRED, NICK_NAME_MAX_LENGTH, ROOM_AGORA_TYPE, ROOM_MODE, ROOM_MODE_AGORA_START, ROOM_MODE_AGORA_START_SHOW, ROOM_NAME_MAX_LENGTH, ROOM_NAME_MIN_LENGTH, ROOM_PWD_MAX_LENGTH } from "../../utils/constants";
import { log, logException, LOG_MODULE, LOG_TYPE } from "../../utils/Log";
import { getCurrentLocationShareRidPwd, getCurrentLocationShareType, isCurrentLocationAgoraShare, isCurrentLocationNormalShare } from "../../utils/Scheme";
import { isSafari, isWindows, isWX } from "../../utils/userAgentDetector";
import CustomDialog from "../Alert/CustomDialog";
import InputNicknameDialog from "../InputNicknameDialog";
import { useStyles } from './style'

export const FORM_RID = "rid"
export const FORM_PWD = "pwd"
export const FORM_NICKNAME = "nickname"
export const FORM_MODE = 'mode'


const JoinRoomForm = observer((props) => {
  const [t] = useTranslation()
  const userStore = useUserStore()
  const user = userStore.user
  const uiStore = useUIStore()
  const notification = useNotificationStore()
  const classes = useStyles()
  const theme = useTheme()
  const isDark = theme.palette.type === 'dark'

  const [state, setState] = useState({
    enableVideo: user.enableVideo,
    enableAudio: user.enableAudio,
  })
  const [nameError, setNameError] = useState('')
  const [nicknameError, setNicknameError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [roomNameInputLock, setRoomNameInputLock] = useState(false)
  const [roomName, setRoomName] = useState(userStore.getLastRoomShowId())
  const [pwdInputLock, setPwdInputLock] = useState(false)
  const [roomPwd, setRoomPwd] = useState(userStore.lastRoomPwd)
  const [nickName, setNickName] = useState(user.name)
  const formRef = useRef<HTMLFormElement>(null)
  const [pasteText, setPasteText] = useState('')

  const [roomNamefocus, setRoomNameFocus] = useState(false)
  const [roomPwdfocus, setRoomPwdFocus] = useState(false)
  const [nikeNamefocus, setNikeNameFocus] = useState(false)

  const [roomInvitation, setRoomInvitation] = useState({})
  const [invitationDetail, setInvitationDetail] = useState({})
  const [nicknameDialogOpen, setNicknameDialogOpen] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('homeMode')) {
      const homeMode = parseInt(localStorage.getItem('homeMode') as string)
      if (homeMode === ROOM_MODE.MODE_AGORA) {
        if (userStore.isThirdPartyLoggedIn) {
          uiStore.changeModeToAgora()
        } else {
          uiStore.changeModeToNormal()
        }
      } else {
        uiStore.changeModeToNormal()
      }
    }

    const parseAgoraRoom = (rid: string, pwd: string | null) => {
      uiStore.changeModeToAgora()

      if (rid?.startsWith(ROOM_MODE_AGORA_START)) {
        rid = rid?.slice(ROOM_MODE_AGORA_START.length)
      }

      setRoomName(rid || '')
      setRoomPwd(pwd || '')
    }

    const checkRoomLink = () => {
      if (isWX()) {
        return// Windows WeChat Client black page on setRoomInvitation
      }

      if (isCurrentLocationNormalShare()) {
        const { rid, pwd } = getCurrentLocationShareRidPwd()

        log(`on room link, room name: ${rid}, pwd: ${pwd}`, LOG_TYPE.COMM, LOG_MODULE.UI)

        if (!rid) return

        uiStore.changeModeToNormal()

        setRoomName(rid)
        setRoomPwd(pwd || '')
      } else if (isCurrentLocationAgoraShare()) {
        let { rid, pwd } = getCurrentLocationShareRidPwd()
        const { type, code } = getCurrentLocationShareType()

        console.log(`current location agora  ${rid} ${pwd} ${type} ${code}`)

        if (!rid) return

        if (type === ROOM_AGORA_TYPE.TYPE_PRIVATE) {
          if (userStore.isThirdPartyLoggedIn) {
            parseAgoraRoom(rid, pwd)
          } else {
            uiStore.setRequestLogin(true)
          }
        } else {
          if (userStore.isThirdPartyLoggedIn) {
            parseAgoraRoom(rid, pwd)
          } else {
            setRoomInvitation({
              open: true,
              content: 'AgoraRoomInvitationContent',
              param: new Map([['rid', rid]]),
              code,
            })
          }
        }
      }
    }

    checkRoomLink()
  }, [])

  const onInvitationCanceled = () => {
    setRoomInvitation({ open: false })
  }

  // 内部人或普通邀请链接，弹窗确认后，直接进入会议， 目前Web不弹框
  const onInvitationConfirmed = () => {
    setRoomInvitation({ open: false })

    //https://stackoverflow.com/questions/49587933/firefox-doesnt-preventing-dispatched-submit-event
    formRef.current!.dispatchEvent(new Event("submit", { cancelable: true }))
  }

  // 外部人通过 linkId 验证后进入会议
  const onInvitationVerify = () => {
    return new Promise<void>((resolve, reject) => {
      const code = (roomInvitation as any).code

      log(`get agora room share by linkId code: ${code}`, LOG_TYPE.INFO, LOG_MODULE.UI)

      if (!code) {
        log(`verify invitation, code null`, LOG_TYPE.ERROR, LOG_MODULE.UI)

        reject('code null')
        return
      }

      getAgoraRoomShareInfoByLinkId(code).then((res) => {
        const verified = res.data.success
        const rid = res.data.rid
        const pwd = res.data.pass

        if (!verified) {
          notification.addAlert(true, 'ShareCodeExpired')//TODO 5.0 TEST
          return
        }

        resolve()

        setRoomInvitation({ open: false })

        // setTimeout to avoid react Warning: Can't perform a React state update on an unmounted component.
        // because can not setRoomInvitation when JoinRoomForm component unmounted
        // first setRoomInvitation, then mannualSubmit to unmount JoinRoomForm component
        setTimeout(() => {
          mannualSubmit(ROOM_MODE.MODE_AGORA, rid, pwd, nickName)
        })
      }).catch((e) => {
        notification.addAlert(true, e.code === ERR_SHARE_CODE_EXPIRED ? 'ShareCodeExpired' : 'RequestFailed')

        logException(`get agora room share by linkId exception`, e, LOG_MODULE.HTTP)

        reject()

        setRoomInvitation({ open: false })
      })
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    userStore.updateUser({ [event.target.name]: event.target.checked })
    log(`user set expected ${event.target.name === 'enableVideo' ? 'camera' : 'microphone'} ${event.target.checked === true ? 'enabled' : 'disable'}`, LOG_TYPE.COMM, LOG_MODULE.UI)
  }

  const validateRoomName = (rid?: string) => {
    if (!rid) {
      setNameError(t('Please input a room name'))
      return false
    }
    if (rid.length < ROOM_NAME_MIN_LENGTH) {
      setNameError(t('RoomNameLimit'))
      return false
    }
    if (rid.length > ROOM_NAME_MAX_LENGTH) {
      setNameError(t('RoomNameLimit'))
      return false
    }
    if (!/^[0-9a-zA-Z]+$/.test(rid)) {
      setNameError(t('RoomNameLimit'))
      return false
    }

    setNameError('')
    return true
  }

  const validateRoomPwd = (pwd?: string) => {
    if (pwd && pwd.length > ROOM_PWD_MAX_LENGTH) {
      setPasswordError(t('PwdLimit'))
      return false
    }
    if (pwd && getValidPwd(pwd) !== pwd) {
      setPasswordError(t('Invalid password'))
      return false
    }
    setPasswordError('')

    return true
  }

  const validateNickName = (showError: boolean, name?: string) => {
    name = name?.trim()

    if (name?.length === 0 && name !== nickName) {
      setNickName(name)
    }

    if (!name) {
      if (showError) {
        setNicknameError(t('Please input your name'))
      }
      return false
    }

    if (name.length > NICK_NAME_MAX_LENGTH) {
      if (showError) {
        setNicknameError(t('NicknameLimit'))
      }
      return false
    }

    return true
  }

  const handleRoomName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // 兼容中文输入法
    if (roomNameInputLock && (isSafari() || isWindows())) {
      setRoomName(e.target.value)
    } else {
      changeNameValue(e.target.value)
    }
  }

  const [ridErrorTimer, setRidErrorTimer] = useState(0)
  const resetRidError = () => {
    if (ridErrorTimer > 0) {
      window.clearTimeout(ridErrorTimer)
      setRidErrorTimer(0)
    }

    setRidErrorTimer(window.setTimeout(() => {
      setNameError('')
      setRidErrorTimer(0)
    }, 2000))
  }

  const changeNameValue = (value: string) => {
    setNameError('')

    // 粘贴板只粘贴 AG-XXX 的 XXX
    // https://jira.agoralab.co/browse/APP-2871
    if (pasteText.slice(0, 3) === 'AG-') {
      let slicePaste = pasteText.slice(3)
      value = roomName + slicePaste
      setPasteText('')
    }

    value = value.toUpperCase()

    if (value && !/^[0-9A-Z]+$/.test(value)) {
      setNameError(t('RoomNameLimit'))

      value = value.replace(/[^0-9A-Z]/g, '')

      resetRidError()
    }

    if (value.length > ROOM_NAME_MAX_LENGTH) {
      setNameError(t('RoomNameLimit'))
      value = value.substr(0, ROOM_NAME_MAX_LENGTH)

      resetRidError()
    }

    setRoomName(value)

    return value
  }

  const getValidPwd = (value: string) => {
    const allow: string = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!#$%&()+-:;<=.>?@[]^_{}|~,"
    let output: string = ''
    for (let i = 0; i < value.length; i++) {
      let ch = value.charAt(i)

      let valid = false
      for (let j = 0; j < allow.length; j++) {

        if (ch === allow.charAt(j)) {
          valid = true
          break
        }
      }

      if (valid) {
        output = output.concat(`${ch}`)
      }
    }
    return output
  }

  const handleRoomPwd = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // 兼容中文输入法
    if (pwdInputLock && (isSafari() || isWindows())) {
      setRoomPwd(e.target.value)
    } else {
      e.target.value = changeRoomPwd(e.target.value) // rewrite back to e.target.value, Safari/Firefox support
    }
  }

  const [pwdErrorTimer, setPwdErrorTimer] = useState(0)
  const resetPwdError = () => {
    if (pwdErrorTimer > 0) {
      window.clearTimeout(pwdErrorTimer)
      setPwdErrorTimer(0)
    }

    setPwdErrorTimer(window.setTimeout(() => {
      setPasswordError('')
      setPwdErrorTimer(0)
    }, 2000))
  }

  const changeRoomPwd = (value: string) => {
    setPasswordError('')

    let output = getValidPwd(value)// old reg match not working with '/'
    if (output !== value) {
      setPasswordError(t('Invalid password'))

      resetPwdError()
    }

    if (output.length > ROOM_PWD_MAX_LENGTH) {
      setPasswordError(t('PwdLimit'))
      output = output.substr(0, ROOM_PWD_MAX_LENGTH)

      resetPwdError()
    }

    setRoomPwd(output)

    return output
  }

  const [nicknameErrorTimer, setNicknameErrorTimer] = useState(0)
  const resetNicknameError = () => {
    if (nicknameErrorTimer > 0) {
      window.clearTimeout(nicknameErrorTimer)
      setNicknameErrorTimer(0)
    }

    setNicknameErrorTimer(window.setTimeout(() => {
      setNicknameError('')
      setNicknameErrorTimer(0)
    }, 2000))
  }

  const handleNickname = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNicknameError('')

    let value = e.target.value

    if (value.length > NICK_NAME_MAX_LENGTH) {
      setNicknameError(t('NicknameLimit'))
      value = value.substr(0, NICK_NAME_MAX_LENGTH)

      resetNicknameError()
    }

    setNickName(value)
  }

  const mannualSubmit = (mode: ROOM_MODE, rid: string, pwd: string, nickName: string) => {
    if (uiStore.isHomeModeNormal && !validateNickName(false, nickName)) {
      log('nick name invalid, not expected, return', LOG_TYPE.ERROR, LOG_MODULE.UI)

      let detail = {}
      detail[FORM_MODE] = mode
      detail[FORM_RID] = rid
      detail[FORM_PWD] = pwd

      setInvitationDetail(detail)

      setNicknameDialogOpen(true)
    } else {
      let data: any = {}
      data[FORM_MODE] = mode
      data[FORM_RID] = rid
      data[FORM_PWD] = pwd
      data[FORM_NICKNAME] = nickName

      props.joinRoom(data)

      uiStore.changeModeToAgora()
      props.onChangeMode(true)  
    }
  }

  const onNicknameDialogConfirm = (nickname: string) => {
    let data = invitationDetail
    data[FORM_NICKNAME] = nickname

    setInvitationDetail({})

    setNicknameDialogOpen(false)

    props.joinRoom(data)

    // 外部用户确认进入 "允许未登录用户进入声网房间" 的邀请链接, 房间主题改为声网房间模式
    uiStore.changeModeToAgora()
    props.onChangeMode(true)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    let data = {}
    data[FORM_RID] = roomName
    data[FORM_PWD] = roomPwd
    data[FORM_NICKNAME] = nickName

    if (!validateRoomName(data[FORM_RID])) {
      log('room name invalid, not expected, return', LOG_TYPE.ERROR, LOG_MODULE.UI)
      return
    }

    if (!validateRoomPwd(data[FORM_PWD])) {
      log('room password invalid, not expected, return', LOG_TYPE.ERROR, LOG_MODULE.UI)
      return
    }

    if (uiStore.isHomeModeNormal && !validateNickName(true, data[FORM_NICKNAME])) {
      log('nick name invalid, not expected, return', LOG_TYPE.ERROR, LOG_MODULE.UI)
      return
    }

    data[FORM_MODE] = uiStore.homeMode

    if (!uiStore.isHomeModeNormal) {
      data[FORM_RID] = ROOM_MODE_AGORA_START + data[FORM_RID]
    }

    localStorage.setItem("homeMode", uiStore.homeMode.toString())

    props.joinRoom(data)
  }

  const handlePaste = (event: any) => {
    const pasteText = event.clipboardData.getData('text')
    setPasteText(pasteText)
  }

  return (
    <form ref={formRef} id="joinRoom" className={classes.form} onSubmit={e => handleSubmit(e)}>
      {/* room name */}
      <Box>
        <Typography className={`${classes.formLabel} notranslate`} variant="subtitle2">{t('Room Name')}</Typography>
        <TextField
          onFocus={() => setRoomNameFocus(true)}
          onBlur={() => setRoomNameFocus(false)}
          className={`${classes.item} notranslate`}
          onChange={e => handleRoomName(e)}
          onCompositionStart={() => { setRoomNameInputLock(true) }}
          onCompositionEnd={(e) => {
            setRoomNameInputLock(false)
            changeNameValue(roomName)
          }}
          onPaste={handlePaste}
          placeholder={t('Enter Room Name')}
          name={FORM_RID}
          variant="outlined"
          error={!!nameError}
          helperText={(
            nameError && 
            <span className={classes.helperTextContainer}>
              <IconIssueMore className={classes.helperTextIcon} width={'12px'} height={'12px'} fill={'#FD3E3F'} />
              <span className={classes.helperTextContent}>{nameError}</span>
            </span>
          )}
          InputProps={{
            classes: {
              root: classes.root,
            },
            // 声网房间加上 AG-
            startAdornment:
              (

                <>
                  <div style={{ paddingRight: '0.5rem' }}>
                    <IconRoomName fill={roomNamefocus ? isDark ? theme.palette.info.main : theme.palette.primary.main : isDark ? '#7F7F80' : '#A5ADBF'} />
                  </div>
                  {
                    (!uiStore.isHomeModeNormal) && <InputAdornment position="start">
                      <Typography variant="h3" className={`notranslate`}>{ROOM_MODE_AGORA_START_SHOW}</Typography>
                    </InputAdornment>
                  }
                </>
              ),
            endAdornment: (
              roomName && <>
                <IconClearable width={uiStore.isHomeModeNormal ? '16px' : '18px'}
                  height={uiStore.isHomeModeNormal ? '17px' : '19px'} className={classes.cursor}
                  onClick={() => setRoomName('')} fill={isDark ? '#7F7F80' : '#A5ADBF'} />
              </>
            )
          }}
          FormHelperTextProps={{ className: classes.input }}
          size="small"
          value={roomName}
          autoComplete={"off"}
          autoFocus
        />
      </Box>

      {/* room password */}
      <Box>
        <Typography className={`${classes.formLabel} notranslate` } variant="subtitle2">{t('Room Password')}</Typography>
        <TextField
          onFocus={() => setRoomPwdFocus(true)}
          onBlur={() => setRoomPwdFocus(false)}
          className={`${classes.item} notranslate`}
          onChange={e => handleRoomPwd(e)}
          onCompositionStart={() => { setPwdInputLock(true) }}
          onCompositionEnd={(e) => {
            setPwdInputLock(false)
            changeRoomPwd(roomPwd)
          }}
          placeholder={t('Enter Password')}
          name={FORM_PWD}
          variant="outlined"
          value={roomPwd}
          error={!!passwordError}
          helperText={(
            passwordError && 
            <span className={classes.helperTextContainer}>
              <IconIssueMore className={classes.helperTextIcon} width={'12px'} height={'12px'} fill={'#FD3E3F'} />
              <span className={classes.helperTextContent}>{passwordError}</span>
            </span>
          )}
          autoComplete={"off"}
          InputProps={{
            classes: {
              root: classes.root
            },
            startAdornment: (
              <div style={{ paddingRight: '0.5rem', paddingTop: '0.2rem', }}>
                <IconPassword fill={roomPwdfocus ? isDark ? theme.palette.info.main : theme.palette.primary.main : isDark ? '#7F7F80' : '#A5ADBF'} />
              </div>
            ),
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <Tooltip
                    arrow
                    classes={{
                      popper: classes.popper,
                      tooltipPlacementRight: classes.tooltipPlacementRight,
                    }}
                    title={
                      <Box>
                        <Typography className={`${classes.tooltipText} notranslate`} variant="subtitle2">{t('PasswordTip1')}</Typography>
                        <Typography className={`${classes.tooltipText} notranslate`} variant="subtitle2">{t('PasswordTip2')}</Typography>
                      </Box>
                    }
                    placement="right">
                    <IconIssueMore className={`cursor`} fill={isDark ? '#7F7F80' : '#A5ADBF'} />
                  </Tooltip>
                </InputAdornment>
              </>
            ),
          }}
          FormHelperTextProps={{ className: classes.input }}
          size="small"
        />
      </Box>

      {/* nick name */}
      <Box>
        <Typography className={classes.formLabel} variant="subtitle2">{uiStore.isHomeModeNormal ? t('Your Nickname') : t('ShowName')}</Typography>
        <TextField
          onFocus={() => setNikeNameFocus(true)}
          onBlur={() => setNikeNameFocus(false)}
          className={`${classes.item} notranslate`}
          disabled={!uiStore.isHomeModeNormal}
          onChange={e => handleNickname(e)}
          variant="outlined"
          placeholder={t('Enter Your Nickname')}
          value={uiStore.isHomeModeNormal ? nickName : user.thirdPartyUserName}
          name={FORM_NICKNAME}
          error={!!nicknameError}
          helperText={(
            nicknameError && 
            <span className={classes.helperTextContainer}>
              <IconIssueMore className={classes.helperTextIcon} width={'12px'} height={'12px'} fill={'#FD3E3F'} />
              <span className={classes.helperTextContent}>{nicknameError}</span>
            </span>
          )}
          autoComplete={"off"}
          InputProps={{
            classes: {
              root: classes.root,
            },
            startAdornment: (
              <div style={{ paddingRight: '0.5rem', paddingTop: '0.2rem', zIndex: 200 }}>
                <IconNickName fill={nikeNamefocus ? isDark ? theme.palette.info.main : theme.palette.primary.main : isDark ? '#7F7F80' : '#A5ADBF'} />
              </div>
            ),
            endAdornment: (
              nickName && <>
                <IconClearable width={'16px'} height={'17px'} className={classes.cursor} onClick={() => setNickName('')} fill={isDark ? '#7F7F80' : '#A5ADBF'} />
              </>
            )
          }}
          FormHelperTextProps={{ className: classes.input }}
          size="small" />
      </Box>
      <div className={classes.controlBox}>
        <Box className={`${classes.control}`}>
          <FormControlLabel
            classes={{ 
              root: classes.labelControl,
              label: classes.checkBoxText,
            }}
            control={
              <Checkbox
                color="default"
                checked={state.enableVideo}
                onChange={handleChange}
                name="enableVideo"
                icon={<span className={classes.checkBox} />}
                checkedIcon={<span className={classes.checkedBox}></span>}
              />
            }
            label={
              <Typography variant='subtitle2'>{t('Turn on camera')}</Typography>
            }
          />
        </Box>
        <Box className={classes.control}>
          <FormControlLabel
            classes={{ 
              root: classes.labelControl,
              label: classes.checkBoxText,
            }}
            control={
              <Checkbox
                color="default"
                checked={state.enableAudio}
                onChange={handleChange}
                name="enableAudio"
                icon={<span className={classes.checkBox} />}
                checkedIcon={<span className={classes.checkedBox}></span>}
              />
            }
            label={<Typography variant='subtitle2'>{t('Turn on the microphone')}</Typography>}
          />
        </Box>
      </div>
      {/* color='primary' use containedPrimary, color='secondary' use containedSecondary */}
      <Button
        className={classes.button}
        color={'primary'}
        variant="contained"
        form="joinRoom"
        type="submit"
        disabled={props.loading || !props.ready}>
        {props.loading ? t('JoinLoading') : t('Join Room')}
      </Button>
      {
        (roomInvitation as any).open &&
        <CustomDialog open={(roomInvitation as any).open !== undefined ? (roomInvitation as any).open : false}
          content={(roomInvitation as any).content} param={(roomInvitation as any).param} onClose={onInvitationCanceled} onCancel={onInvitationCanceled} onOK={(roomInvitation as any).code ? undefined : onInvitationConfirmed}
          onAsyncOK={(roomInvitation as any).code && onInvitationVerify} isComfirmType={true} />
      }
      {
        nicknameDialogOpen &&
        <InputNicknameDialog open={nicknameDialogOpen} onClose={() => { setNicknameDialogOpen(false) }} onCancel={() => { setNicknameDialogOpen(false) }} onConfirm={onNicknameDialogConfirm} />
      }
    </form>
  )
})

export default JoinRoomForm
