import { Box, useTheme, } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useCommManagerStore, useRtcEngineStore, } from "../../stores/hooks";
import { CommUser } from "../../types/commUser";
import UserOperation from "./UserOperation";
import VoiceVolumeIcon from "./VoiceVolumn";
import { useStyles } from './styles';
import { ReactComponent as IconShare } from "../../assets/icon_stream_screen.svg";
import { UserNameTooltip } from './UserNameTooltip'

export interface UserItemInfoProps {
  user: CommUser,
  index:number,
}

const UserItemInfo = observer((props: UserItemInfoProps) => {
  const [t] = useTranslation()
  const commManager = useCommManagerStore()
  const rtcStore = useRtcEngineStore()
  const user = props.user
  const classNames = useStyles()
  const [isShowMore, setShowMore] = useState(false)
  const theme = useTheme()

  const handleMenuClose = () => {
    setShowMore(false)
  };

  return (
    <div className={classNames.userInfoContainer} 
    onMouseEnter={() => setShowMore(true)} 
    onMouseOver={() => setShowMore(true)} 
    onMouseLeave={handleMenuClose} key={props.user.streamId}>
      <Box className={classNames.listItem} key={props.user.streamId}>
         <UserNameTooltip user={props.user} key={props.user.streamId} >
          <div className={`${classNames.userName} notranslate`} id={`user-item-spokesman-name-${props.index}`}>
            {props.user.showName || t('Unknown')}
          </div>
        </UserNameTooltip>
        <div className={classNames.itemControl}>
          <div className={classNames.micState}>
            {user.parentStreamId ? <IconShare fill={theme.palette.primary.main} /> :
              <VoiceVolumeIcon isBigStream={false} volumeLevel={Math.ceil(rtcStore.volumeLevel * 100)} key={props.user.streamId} audioState={user.audioState} isSpeaking={user.isSpeaking && !user.isMe} isMe={user.isMe} />}
          </div>
          {
            (!user.isMe && (!commManager.roomInfo.hasHost || commManager.roomInfo.isMeHost)) && (!user.isAssistant)
            && isShowMore && !user.parentStreamId &&
            <UserOperation isShowMore={isShowMore} handleMenuClose={handleMenuClose} user={user} />
          }
        </div>
      </Box>
    </div >
  )
})

export default UserItemInfo
