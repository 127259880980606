import { UAParser } from "ua-parser-js";

const parser = new UAParser()

const userAgentInfo = parser.getResult()

export const isSafari = () => {
  return (
    userAgentInfo.browser.name === 'Safari' ||
    userAgentInfo.browser.name === 'Mobile Safari'
  );
};

export const isChrome = () => {
  return userAgentInfo.browser.name === 'Chrome';
};

export const isFirefox = () => {
  return userAgentInfo.browser.name === 'Firefox';
};

export const isMobile = () => {
  return userAgentInfo.device.type === 'mobile';
};

export const isAndroid = () => {
  return userAgentInfo.os.name !== undefined && userAgentInfo.os.name.indexOf('Android') >= 0
}

export const isIOS = () => {
  return userAgentInfo.os.name !== undefined && userAgentInfo.os.name.match(/iPhone|iPad|iPod/i) ? true : false;
}

// iPad OS 13 以上, safari 浏览器 navigator.userAgent 默认为 macOS 内核
// 需要在设置中切换访问移动端网站
// note: UAParser 没有对 iPad 做兼容判断
// Macintosh (苹果设备) 再判断触摸事件以用于判断移动端设备
export const isIpad = () => {
  const ua = window.navigator.userAgent;
  if (ua.indexOf('iPad') > -1) {
    return true;
  }

  if (ua.indexOf('Macintosh') > -1) {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) { }
  }

  return false;
}

export const exceedSafari = (version: number) => {
  const browserVersion = userAgentInfo.browser.version && parseInt(userAgentInfo.browser.version)
  return isSafari() && (browserVersion && browserVersion >= version)
}

export const isLessSafari14 = () => {
  const browserVersion = userAgentInfo.browser.version && parseInt(userAgentInfo.browser.version)
  return isSafari() && (browserVersion && browserVersion < 14)
}

export const isWindows = () => {
  return userAgentInfo.os.name !== undefined && userAgentInfo.os.name.indexOf('Windows') >= 0
}

export const isWX = () => {
  return userAgentInfo.ua.match(/micromessenger/i) ? true : false;
}

export const isQQ = () => {
  return userAgentInfo.browser.name === "QQ"
}

export const isQQBrowser = () => {
  return userAgentInfo.browser.name === "QQBrowser"
}

export const isUCBrowser = () => {
  return userAgentInfo.browser.name === "UCBrowser"
}

export const getPlatform = () => {
  return 'Web'
}

export const getDeviceName = () => {
  return `${userAgentInfo.os.name}, ${userAgentInfo.browser.name}`
}

export const isBrowserSupportScheme = () => {
  //https://caniuse.com/mdn-http_data-url_top_level_navigation_blocked
  if (isChrome()) {
    let version = userAgentInfo.browser.version
    if (version) {
      let index = version.indexOf('.')
      if (index > 0) {
        version = version.substring(0, index)
        const major = parseInt(version)
        if (major < 60) {
          return false
        }
      }
    }
  }

  return true
}

export default userAgentInfo;
