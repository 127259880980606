import { Box, Grid, Tooltip, Typography, Popover, IconButton, Button, useTheme } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconArrowDropDown} from "../../assets/icon_arrow_drop_down.svg";
import { ReactComponent as Logo } from "../../assets/icon_logo.svg";
import { ReactComponent as IconExpand } from "../../assets/icon_expand.svg";
import { ReactComponent as IconShrink } from "../../assets/icon_shrink_list.svg";
import { getAgoraRoomShareLinkId } from "../../stores/connector/http";
import {
  useCommManagerStore,
  useNotificationStore,
  useRtmEngineLayerStore,
  useUIStore,
  useUserStore
} from "../../stores/hooks";
import { formatDuration, execCopy, isChinese } from "../../utils/helper";
import { log, LOG_MODULE, LOG_TYPE } from "../../utils/Log";
import { generateShareLink } from "../../utils/Scheme";
import QualityFeedbackView from "../QualityFeedback/QualityFeedbackInsideContainer";
import UserSettingView from "../UserSetting/UserSettingView";
import SignalView from "../SignalView/SignalView";
import IssueFeedbackView from "../IssueFeedback/IssueFeedBackView";
import { useStyles } from "./RoomHeaderStyles"
import { ReactComponent as IconSettings } from "../../assets/icon_settings.svg";
import { ReactComponent as IconTriangle } from "../../assets/icon_triangle.svg";

interface IUserListViewProps {
  toggle: boolean;
  handleToggle: () => any | void;
  style?: React.CSSProperties;
  isShowListViewControl:boolean
}

const RoomHeader = observer((props: IUserListViewProps) => {
  const [t] = useTranslation()
  const styles = useStyles()
  const commManager = useCommManagerStore()
  const rtmEngineLayer = useRtmEngineLayerStore()
  const userStore = useUserStore()
  const [tipOpen, setTipOpen] = React.useState(userStore.shouldShowShareTip())
  const [settingOpen, setSettingOpen] = React.useState(false)
  const [shareDialogOpen, setShareDialogOpen] = React.useState(false)
  const notification = useNotificationStore()
  const uiStore = useUIStore()
  const theme = useTheme()
  const { customColor: { participantsIconColor, } } = theme

  const handleTooltipClose = () => {
    setTipOpen(false)
    userStore.setShareTipShowed()
  };

  const onShare = (isAgoraRoom: boolean, isPrivate: boolean, code?: string) => {
    log(`on share click... is agora room: ${isAgoraRoom}, is private: ${isPrivate}, code: ${code}`, LOG_TYPE.INFO, LOG_MODULE.DEFAULT)

    const link = generateShareLink(commManager.roomInfo.roomId, commManager.roomInfo.roomPwd, isAgoraRoom, isPrivate, code)

    const data = `${t(isAgoraRoom ? "AgoraShareContent" : "ShareContent", {
      name: `${isAgoraRoom ? userStore.thirdPartyUserName : userStore.getName()}`,
      rid: `${commManager.roomInfo.roomId}`,
      link: link
    })}`

    execCopy(data, 'on share copy success')
  }

  const startShare = () => {
    if (uiStore.isRoomModeNormal) {
      onShare(false, false)
      setCopyText(t('ShareLinkCopyed'))
      // notification.toast("ShareLinkCopyed")
    } else {
      if (userStore.isThirdPartyLoggedIn) {
        setShareDialogOpen(true)
      } 
      // else {
      //   notification.toastError('NonLoggedInUserCannotShare')
      // }
    }
  }

  const onAgoraPublicRoomShare = () => {
    return new Promise<void>((resolve, reject) => {
      getAgoraRoomShareLinkId(commManager.roomInfo.roomId, userStore.user.getThirdPartySession()).then(res => {
        setTimeout(() => {
          onShare(true, false, res.data.linkId)
        }, 100)// !!! Set timeout to let input element selectable, otherwise conflict with dialog close

        setShareDialogOpen(false)

        setCopyText(t('AgoraPublicShareLinkCopyed'))
        // setOpenedPopover(false)

        resolve()

        // notification.toast("AgoraPublicShareLinkCopyed")
      }).catch(e => {
        setShareDialogOpen(false)

        setCopyText(t('RequestFailed'))
        // setOpenedPopover(false)

        reject(e)

        // notification.toastError('RequestFailed')
      })
    })
  }

  const onAgoraPrivateRoomShare = () => {
    setShareDialogOpen(false)

    setCopyText(t('AgoraPrivateShareLinkCopyed'))
    // setOpenedPopover(false)

    setTimeout(() => {
      onShare(true, true)
    }, 100) // !!! Set timeout to let input element selectable, otherwise conflict with dialog close

    // notification.toast("AgoraPrivateShareLinkCopyed")
  }

  const [openedPopover, setOpenedPopover] = useState(false)
  const [copyText, setCopyText] = useState('')
  const popoverAnchor = useRef(null)
  const onRoomHeaderEnter = () => {
    setOpenedPopover(true)
  }

  const onRoomHeaderLeave = () => {
    setOpenedPopover(false)
    setShareDialogOpen(false)
    setCopyText('')
  }

  const agoraShareCopyInfo = () => {
    return (
       <>
        <div className={styles.popoverContentItem}>
          {t('AgoraShareDialogText')}
        </div>
        <div className={styles.buttonBox}>
          <Button onClick={onAgoraPrivateRoomShare} className={`${styles.popoverButton} ${styles.popverCancelColor}` } variant="contained">{t('Disallow')}</Button>
          <Button onClick={onAgoraPublicRoomShare} className={styles.popoverButton} variant="contained" color="primary">{t('Allow')}</Button>
        </div>
      </>
    )
  }

  const defaultHeaderInfo = () => {
    return (
      copyText ?
      <div className={`${styles.popoverCopyText} ${styles.popoverContentItem}`}>{copyText}</div> 
      :
      <>
        <div className={styles.popoverContentItem} id="header-room-name">
          {t('Room Name')}: <span>{commManager.roomInfo.roomId}</span>
        </div>
        <div className={`${styles.popoverContentItem} ${styles.popoverSecondItem}`}  id="header-room-password">
          {t('Room Password')}: <span>{commManager.roomInfo.roomPwd}</span>
        </div>
        {
          uiStore.isRoomModeNormal ?
          <Box style={{ paddingTop: '0.5rem' }}  >
            <div className={styles.popoverContentItem} id="header-invitation-script">
              {t('CopyShareLinkNormal')}
            </div>
            <div id="header-copy-link" className={`${styles.copyLink} ${styles.popoverContentItem}`} onClick={startShare}>{t('CopyLink')}</div>
          </Box>
          :
          <div className={styles.popoverTipItem}  id="header-invitation-script">
            {t('CopyShareLinkAgora')}
            {
              userStore.isThirdPartyLoggedIn && <div id="header-copy-link" className={styles.copyLink} onClick={startShare}>{t('CopyLink')}</div>
            }
          </div>
        }
      </>
    )
  }


  return (
    <Grid
      className={styles.root}
      container
      direction="row"
      justify="space-between"
      style={{ ...props.style }}
      alignItems="center">
      {props.isShowListViewControl && <div className={styles.listViewControl} onClick={props.handleToggle} >
        <Typography variant="h2" >{t('listView')} </Typography>
        <IconButton className={styles.listViewIconButton} id="header-expand-list">
          {!props.toggle ? <IconExpand fill={theme.customColor.operationButtonFill} className={styles.listViewIcon} /> :
            <IconShrink fill={theme.customColor.operationButtonFill} className={styles.listViewIcon} />}
        </IconButton>
      </div>}
      <Box className={styles.roomHeader}>
        <div className={styles.logoContainer}>
          <Logo className={styles.logo} fill={theme.palette.primary.main} />
            <div
              className={styles.roomInfo}
              // onMouseEnter={onRoomHeaderEnter}
              // onMouseLeave={onRoomHeaderLeave}
              onClick={onRoomHeaderEnter}
            >
              <div className={`${styles.roomNameContainer} notranslate`}>
                <Typography id="channel-name" variant="h3">{commManager.roomInfo.roomId}</Typography>
                <IconArrowDropDown fill={theme.palette.primary.main} id="header-channel-operation"  ref={popoverAnchor}/>
              </div>
              <div className={styles.duration} id="room-duration">{commManager.roomElapsed > 0 ? formatDuration(commManager.roomElapsed) : "--:--:--"}</div>
            </div>
        </div>
        <div className={styles.buttons}>
          <Grid container direction="row" alignItems="center" justify="space-between" className={styles.setting}>
            <QualityFeedbackView />
            <SignalView />
            <IssueFeedbackView />
            <Box>
              <Tooltip  title={t('setting')} placement="bottom">
                <IconButton id="setting" onClick={() => setSettingOpen(true)} className={styles.iconButton}>
                  <IconSettings fill={participantsIconColor} />
                </IconButton>
              </Tooltip>
            </Box>
            <UserSettingView roomState={true} open={settingOpen} setOpen={setSettingOpen} />
          </Grid>
        </div>
      </Box>

      {openedPopover && 
          <Popover
            onClose={onRoomHeaderLeave}
            id="room-header-popover"
            className={styles.popoverContainer}
            classes={{
              paper: copyText === t('ShareLinkCopyed') || copyText === t('RequestFailed') ? styles.popoverNormalLink : isChinese() ? styles.popover : styles.popoverEn,
            }}
            open={openedPopover}
            anchorEl={popoverAnchor.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <IconTriangle className={styles.triangleTop} />
            {
              !shareDialogOpen && openedPopover  ? defaultHeaderInfo()
              :
              shareDialogOpen && agoraShareCopyInfo()
            }
          </Popover>
      }
    </Grid>
  )
})

export default RoomHeader
