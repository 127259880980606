
export type NotificationLevel = "success" | "error" | "warning" | "info";

export class NotificationInfo {
  level: NotificationLevel
  content: string
  params?: Map<string, string>

  constructor(level: NotificationLevel, content: string, params?: Map<string, string>) {
    this.level = level
    this.content = content
    this.params = params
  }
}
export class AlertInfo {
  title: string
  content: string
  params?: Map<string, string>
  isErrorAlert: boolean = false
  confirmText: string

  constructor(isErrorAlert: boolean, title: string, content: string, confirmText: string, params?: Map<string, string>) {
    this.title = title
    this.content = content
    this.params = params
    this.isErrorAlert = isErrorAlert
    this.confirmText = confirmText
  }
}
