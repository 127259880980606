import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import enUsTrans from "./locales/en/translation.json";
import zhCnTrans from "./locales/zh/translation.json";

const resources = {
  en: {
    translation: enUsTrans
  },
  zh: {
    translation: zhCnTrans
  }
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      order: ['navigator'],
    },
    fallbackLng: "en",
    debug: false,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true
    }
  })

export default i18n;
