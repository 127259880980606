import { UserStore } from "./userStore";
import { RtcCommLayerStore } from "./rtc/rtcCommLayer"
import { RtcEngineLayerStore } from "./rtc/rtcEngineLayer"
import { CommManagerStore } from "./commManager"
import { NotificationStore } from "./notification";
import { RtmCommLayerStore } from "./rtm/rtmCommLayer"
import { RtmEngineLayerStore } from "./rtm/rtmEngineLayer"
import ShareManagerStore from "./shareManager";
import UIStateStore from "./uiStateStore";
import { UserManagerStore } from "./userManager";

export class RootStore {
  user: UserStore;
  rtcCommLayer: RtcCommLayerStore;
  rtcEngineLayer: RtcEngineLayerStore;
  commManger: CommManagerStore;
  rtmCommLayer: RtmCommLayerStore;
  rtmEngineLayer: RtmEngineLayerStore;
  notification: NotificationStore;
  shareManager: ShareManagerStore;
  uiStore: UIStateStore;
  userManager: UserManagerStore;

  constructor() {
    const notification = new NotificationStore(this);

    this.commManger = new CommManagerStore(this);
    this.rtcEngineLayer = new RtcEngineLayerStore(this, notification);
    this.rtcCommLayer = new RtcCommLayerStore(this.rtcEngineLayer, this.commManger, notification);
    this.userManager = new UserManagerStore(this.commManger, this.rtcCommLayer)
    this.rtmEngineLayer = new RtmEngineLayerStore(this, notification);
    this.rtmCommLayer = new RtmCommLayerStore(this.rtmEngineLayer, this.commManger);

    this.shareManager = new ShareManagerStore(this.rtcCommLayer, this.rtmCommLayer, this.commManger, this.userManager, notification)

    this.user = new UserStore(this.rtcEngineLayer);

    this.notification = notification;

    this.uiStore = new UIStateStore(this.commManger);
  }
}