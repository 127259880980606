import { Box, Button, IconButton, makeStyles, Modal, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Rating from "@material-ui/lab/Rating";
import cloneDeep from "lodash.clonedeep";
import { observer } from "mobx-react";
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ReactComponent as IconAudioActive } from "../../assets/icon_big_audio_active.svg";
import { ReactComponent as IconVideoActive } from "../../assets/icon_big_video_active.svg";
import { ReactComponent as IconClose } from "../../assets/icon_close.svg";
import { sendQualityFeedback } from "../../stores/connector/http";
import { useCommManagerStore, useRtcCommLayerStore, useRtcEngineStore, useUserManagerStore, useUserStore } from "../../stores/hooks";
import { color } from "../../style/color";
import { log, LOG_MODULE, LOG_TYPE } from "../../utils/Log";
import useStyle from "./style";
import { isChinese } from "../../utils/helper"

interface FeedbackModal {
  setClose: Dispatch<SetStateAction<boolean>>
  openInsideModalTime?: any
}

enum AudioRateLabelType {
  PerceivableDelay = 1,
  NoticeableEcho = 2,
  NoticeableNoise = 3,
  IntermittentVoice = 4,
  VolumeLow = 5
}

enum VideoRateLabelType {
  Blurriness = 1,
  Mosaic = 2,
  Lag = 3,
  Freeze = 4,
}

const QualityFeedbackButton = withStyles({
  root: {
    boxShadow: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    borderColor: color.colorGrey,
    '&:hover': {
      borderColor: color.colorGrey,
      backgroundColor: '#fff',
      boxShadow: 'none',
    },
    '&:active': {
      color: 'none',
      boxShadow: 'none',
    }
  },
})(Button);

const QualityFeedbackModal = observer((props: FeedbackModal) => {
  const [t] = useTranslation()
  const styles = useStyle()
  const user = useUserStore()
  const rtcStore = useRtcEngineStore()
  const rtcComm = useRtcCommLayerStore()
  const commManager = useCommManagerStore()
  const userManger = useUserManagerStore()

  const audioRateTextContent = [
    { value: AudioRateLabelType.PerceivableDelay, text: 'AudioPerceivableDelay', selected: false, },
    { value: AudioRateLabelType.NoticeableEcho, text: 'AudioNoticeableEcho', selected: false, },
    { value: AudioRateLabelType.NoticeableNoise, text: 'AudioNoticeableNoise', selected: false, },
    { value: AudioRateLabelType.IntermittentVoice, text: 'AudioIntermittentVoice', selected: false, },
    { value: AudioRateLabelType.VolumeLow, text: 'AudioVolumeLow', selected: false, },
  ]

  const videoRateTextContent = [
    { value: VideoRateLabelType.Blurriness, text: 'VideoBlurriness', selected: false, },
    { value: VideoRateLabelType.Mosaic, text: 'VideoMosaic', selected: false, },
    { value: VideoRateLabelType.Lag, text: 'VideoLag', selected: false, },
    { value: VideoRateLabelType.Freeze, text: 'VideoFreeze', selected: false, },
  ]

  const [audioRateValue, setAudioRateValue] = useState(0)
  const [audioRateHover, setAudioRateHover] = useState(-1)
  const [audioRateTextArea, setAudioRateTextArea] = useState(false)

  const [videoRateValue, setVideoRateValue] = useState(0)
  const [videoRateHover, setVideoRateHover] = useState(-1)
  const [videoRateTextArea, setVideoRateTextArea] = useState(false)

  const [audioFeedbackText, setAudioFeedbackText] = useState('')
  const [videoFeedbackText, setVideoFeedbackText] = useState('')

  const [audioRateText, setAudioRateText] = useState(audioRateTextContent)
  const [videoRateText, setVideoRateText] = useState(videoRateTextContent)


  const audioRateLabels = {
    1: t('RateBad'),
    2: t('RateBelowAverage'),
    3: t('RateAverage'),
    4: t('RateGood'),
    5: t('RateExcellent'),
  }

  const videoRateLabels = {
    1: t('RateBad'),
    2: t('RateBelowAverage'),
    3: t('RateAverage'),
    4: t('RateGood'),
    5: t('RateExcellent'),
  }

  const handleToggleLabel = (type: string) => {
    if (type === 'audio') {
      setAudioRateTextArea(!audioRateTextArea)
    } else if (type === 'video') {
      setVideoRateTextArea(!videoRateTextArea)
    }
  }

  const handleChangeTextNumber = (event: any, type: string) => {
    let text = event.target.value;
    if (type === 'audio') {
      if (text.length >= 140) text.slice(0, 140)
      setAudioFeedbackText(text)
    } else if (type === 'video') {
      if (text.length >= 140) text.slice(0, 140)
      setVideoFeedbackText(text)
    }
  }

  const setupQualityFeedbackHooks = () => {
    setAudioRateValue(0)
    setAudioRateTextArea(false)
    setVideoRateValue(0)
    setVideoRateTextArea(false)
    setAudioFeedbackText('')
    setVideoFeedbackText('')
    setAudioRateText(audioRateTextContent)
    setVideoRateText(videoRateTextContent)
  }

  const handleSubmitFeedback = () => {
    let audioSelectedLabel = audioRateText.filter(item => item.selected).map(v => v.value)
    let videoSelectedLabel = videoRateText.filter(item => item.selected).map(v => v.value)

    let userId = user.getUid()
    let userName = user.getName()
    let streamId = user.getStreamId()
    let channelName = rtcComm.getChannelName()
    let callId = rtcStore.getCallId()

    props.setClose(false)
    let delta = props.openInsideModalTime ? new Date().getTime() - (props.openInsideModalTime.current || undefined) : undefined
    let peerUid = props.openInsideModalTime && userManger.majorUser?.videoState ? userManger.majorUser?.streamId : undefined

    let postQualityFeedbackData = {
      app_version: process.env.REACT_APP_APP_VERSION as string,
      audio_label: audioSelectedLabel,
      audio_rating: audioRateValue,
      audio_remark: audioFeedbackText,
      video_label: videoSelectedLabel,
      video_rating: videoRateValue,
      video_remark: videoFeedbackText,
      name: userName,
      uid: userId,
      stream_id: streamId,
      channel_name: channelName,
      call_id: callId,
      delta: delta,
      peer_uid: peerUid,
    }
    log('User do submit quality report', LOG_TYPE.COMM, LOG_MODULE.UI)
    sendQualityFeedback(postQualityFeedbackData).then(() => {
      setupQualityFeedbackHooks()
      log('User Finished submit quality report', LOG_TYPE.COMM, LOG_MODULE.UI)
    })
  }

  const handleCloseModal = () => {
    setupQualityFeedbackHooks()
    props.setClose(false)
    log('User close quality feedback modal', LOG_TYPE.COMM, LOG_MODULE.UI)
  }

  return (
    <Modal className={`${styles.modal}`} open={true} hideBackdrop={false}>
      <Box className={`${styles.box}`}>
        <Box className={`${styles.titleLine}`}>
          <Box component="span" className={`${styles.modalTitle}`}>{t('FeedBackTitle')}</Box>
          <IconButton id="close" size="small" onClick={() => {handleCloseModal()}}>
            <IconClose />
          </IconButton>
        </Box>
        <Box className={`${styles.audioRateModel}`}>
          <div>
            <Box className={`${styles.modelTitleLine}`}>
              <IconAudioActive className={styles.icon}/>
              <Box component="span" className={`${styles.modelTitleText}`}>{t('AudioQuality')}</Box>
            </Box>
            <Box className={`${styles.rateStar}`}>
              <Rating
                name="audio-rate"
                value={audioRateValue}
                defaultValue={0}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
                onChange={(event, newValue) => {
                  setAudioRateValue(newValue as number)
                }}
                onChangeActive={(event, newHover) => {
                  setAudioRateHover(newHover)
                }}
              />
              {audioRateValue !== null && (
                <Box ml={3} className={`${styles.rateText}`}>{audioRateLabels[audioRateHover !== -1 ? audioRateHover : audioRateValue]}</Box>
              )}
            </Box>
            <Box className={`${styles.labelModal}`} id="audio-rate-modal">
              {audioRateText.map((item, index) => {
                return <QualityFeedbackButton key={index} value={item.value}
                  className={`${styles.labelButton} ${item.selected && styles.selected}`}
                  variant="outlined" color="primary"
                  onClick={() => {
                    audioRateText[index].selected = !item.selected
                    setAudioRateText(cloneDeep(audioRateText))
                  }}>
                  {t(item.text)}
                </QualityFeedbackButton>
              })}
              <QualityFeedbackButton
                onClick={() => handleToggleLabel('audio')}
                className={`${styles.labelButton} ${audioRateTextArea && styles.selected}`}
                variant="outlined"
                color="primary"
                startIcon={audioRateTextArea ? <CloseIcon /> : <CreateOutlinedIcon />}>
                {t('QualityFeedbackComment')}</QualityFeedbackButton>
            </Box>

            {/* audio feedback text area */}
            {audioRateTextArea && (<Box className={`${styles.feedbackTextareaModal}`}>
              <textarea
                value={audioFeedbackText}
                className={`${styles.feedbackTextareaContent}`}
                placeholder={t('AudioFeedbackContent')}
                maxLength={140}
                onChange={(e) => handleChangeTextNumber(e, 'audio')}
              >
              </textarea>
              <span
                className={`${styles.feedbackTextNumber} ${audioFeedbackText.length >= 140 && styles.fullText}`}>
                {audioFeedbackText.length}/140
                  </span>
            </Box>)}
          </div>
        </Box>

        {/* video rate modal */}
        <Box className={`${styles.videoRateModel}`}>
          <div>
            <Box className={`${styles.modelTitleLine}`}>
              <IconVideoActive className={styles.icon}/>
              <Box component="span" className={`${styles.modelTitleText}`}>{t('VideoQuality')}</Box>
            </Box>
            <Box className={`${styles.rateStar}`}>
              <Rating
                name="video-rate"
                value={videoRateValue}
                defaultValue={0}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
                onChange={(event, newValue) => {
                  setVideoRateValue(newValue as number)
                }}
                onChangeActive={(event, newHover) => {
                  setVideoRateHover(newHover)
                }}
              />
              {videoRateValue !== null && (
                <Box ml={3} className={`${styles.rateText}`}>{videoRateLabels[videoRateHover !== -1 ? videoRateHover : videoRateValue]}</Box>
              )}
            </Box>
            <Box className={`${styles.labelModal}`} id="video-rate-modal">
              {videoRateText.map((item, index) => {
                return <QualityFeedbackButton key={index} value={item.value}
                  className={`${styles.labelButton} ${item.selected && styles.selected}`}
                  variant="outlined" color="primary"
                  onClick={() => {
                    videoRateText[index].selected = !item.selected
                    setVideoRateText(cloneDeep(videoRateText))
                  }}>
                  {t(item.text)}
                </QualityFeedbackButton>
              })}
              <QualityFeedbackButton
                onClick={() => handleToggleLabel('video')}
                className={`${styles.labelButton}  ${videoRateTextArea && styles.selected}`}
                variant="outlined"
                color="primary"
                startIcon={videoRateTextArea ? <CloseIcon /> : <CreateOutlinedIcon />}>
                {t('QualityFeedbackComment')}</QualityFeedbackButton>
            </Box>

            {/* video feedback text area */}
            {videoRateTextArea && (<Box className={`${styles.feedbackTextareaModal}`}>
              <textarea
                value={videoFeedbackText}
                className={`${styles.feedbackTextareaContent} ${!isChinese() && styles.videoTextareaContent}`}
                placeholder={t('VideoFeedbackContent')}
                maxLength={140}
                onChange={(e) => handleChangeTextNumber(e, 'video')}
              >
              </textarea>
              <span
                className={`${styles.feedbackTextNumber} ${styles.videoFeedbackTextNumber} ${videoFeedbackText.length >= 140 && styles.fullText}`}>
                {videoFeedbackText.length}/140
                  </span>
            </Box>)}
          </div>
        </Box>

        {/* submit button */}
        <Box className={`${styles.commentButtonModal}`}>
          <Button id="submit-feedback" className={`${styles.commentButton} ${isChinese() && styles.commentButtonZh}`}
            variant="contained" color="primary" type="submit" onClick={handleSubmitFeedback}>
            {t('SubmitComment')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
})

export default QualityFeedbackModal
