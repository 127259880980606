import { computed } from "mobx"
import { APPLY_ASSISTANT_LANG } from '../utils/constants'

export enum OP_TYPE {
  OP_UNKNOWN = 0,
  OP_BAN = 1,
  OP_ENABLE_AUDIO = 2,
  OP_DISABLE_AUDIO = 3,
  OP_ENABLE_VIDEO = 4,
  OP_DISABLE_VIDEO = 5,
  OP_SHARE = 6,
  OP_UNSHARE = 7,
  OP_INTERRUPT = 8,
  OP_RESUME = 9,
  OP_START_ISSUE_REPORT = 10,
  OP_END_ISSUE_REPORT = 11,
  OP_APPLY_ASSISTANT = 12
};

export class UserRequest {
  op: string
  reqeustId: string
  seqId: number
  sender: string
  opType: OP_TYPE

  public constructor(op: string, requestId: string, seq: number, sender: string) {
    this.op = op
    this.reqeustId = requestId
    this.seqId = seq
    this.sender = sender

    if (op === 'unmuteAudio') {
      this.opType = OP_TYPE.OP_ENABLE_AUDIO
    } else if (op === 'unmuteVideo') {
      this.opType = OP_TYPE.OP_ENABLE_VIDEO
    } else {
      this.opType = OP_TYPE.OP_UNKNOWN
    }
  }
}

export class PendingMessage {

}

export class MyRequestResponse {
  requestId: string
  seqId: number = 0
  reason: string
  source: string
  target: string
  isSuccess: boolean = false
  isAudio: boolean = false

  targetStreamId: number = 0

  public constructor(requestId: string, reason: string, source: string, target: string, isSuccess: boolean, isAudio: boolean) {
    this.requestId = requestId
    this.reason = reason
    this.source = source
    this.target = target
    this.isSuccess = isSuccess
    this.isAudio = isAudio
  }

  @computed
  public get isRefused() {
    return !this.isSuccess && this.reason === 'REJECTED_BY_USER'
  }
}

export class SelfApplyAssistantRequest {
  requestId: string
  isSuccess: boolean = false
  reason: string
  public constructor(requestId: string, isSuccess: boolean, reason: string) {
    this.requestId = requestId
    this.isSuccess = isSuccess
    this.reason = reason
  }

}

export class SelfApplyAssistantResponse {
  requestId: string
  isSuccess: boolean = false
  reason: string
  public constructor(requestId: string, isSuccess: boolean, reason: string) {
    this.requestId = requestId
    this.isSuccess = isSuccess
    this.reason = reason
  }
}

export class UserApplyAssistantRequest {
  requestId: string
  uid: string
  language: APPLY_ASSISTANT_LANG
  innerName: string
  alias: string
  public constructor(requestId: string, uid: string, lang: APPLY_ASSISTANT_LANG, innerName: string, alias: string) {
    this.requestId = requestId
    this.uid = uid
    this.language = lang
    this.innerName = innerName
    this.alias = alias
  }
}