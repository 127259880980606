import { Box, Drawer, IconButton, Input, Typography, useTheme } from '@material-ui/core';
import { observer } from "mobx-react";
// import { Close as CloseIcon } from '@material-ui/icons';
import React, { useEffect, useState, useRef, useCallback } from "react";
import { isSafari } from "../../utils/userAgentDetector";
import { useTranslation } from 'react-i18next';
import { useCommManagerStore, useUserManagerStore, } from "../../stores/hooks";
import { CommUser } from "../../types/commUser";
import { useStyles } from './style'
import { ReactComponent as IconSearch } from "../../assets/icon_search.svg";
import { ReactComponent as IconDelete } from "../../assets/icon_delete_search.svg";
import { ReactComponent as IconClose } from '../../assets/icon_drawer_panel_close.svg'
import { CustomPop } from "../CustomPopup/customPop";
import UserActions from "./userActions";
import { throttle } from "../../utils/tools"
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { APPLY_ASSISTANT_LANG, ASSISTANT_STATE, } from '../../utils/constants';
export interface IParticipantsProps {
  open: boolean,
  toggleParticipant: () => void,
  IsChatOnDisplay: boolean,
}

const Participants = observer((props: IParticipantsProps) => {
  const styles = useStyles();
  const [t] = useTranslation();
  const theme = useTheme();
  const commManager = useCommManagerStore()
  const userManager = useUserManagerStore()
  const assistantInfo = commManager.assistantInfo
  const [currentTab, setCurrenTab] = useState('attendeeList')
  const [isShowOutline, setShowOutLine] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [dialogContent, setDialogContent] = React.useState('')
  const [contentType, setContentType] = React.useState('audio')
  const [cancelText, setCancelText] = React.useState('')
  const [confirmText, setConfirmText] = React.useState('')
  const [peerUser, setPeerUser] = React.useState<CommUser>()
  const [peerUserAssistantState, setPeerUserState] = React.useState(false)
  const [filterUser, setFilterUser] = React.useState('')
  const [filterList, setFilterList] = React.useState<CommUser[]>(userManager.attendeeList)
  const popoverAnchor = useRef(null)
  const tabs = {
    attendeeList: 0,
    loggedInUserList: 1,
    nonLoggedInUserList: 2
  }
  const onChangeFilterButton = (type: string) => {
    setCurrenTab(type)
    setFilterList(userManager[type])
    // @ts-ignore The parameters have been processed in throttled
    throttled(filterUser, tabs[type])
  }

  useEffect(() => {
    if (!props.open) {
      setFilterUser("")
      setCurrenTab("attendeeList")
      onClosePop()
      return
    }
    if (!filterUser) {
      setFilterList(userManager[currentTab])
    }
  }, [props.open, userManager.userCount])

  useEffect(() => {
    if (!peerUser) {
      return
    }
    const user = userManager.attendeeList.find(item => item.streamId === peerUser?.streamId)
    if (!user || user.isAssistant !== peerUserAssistantState) {
      onClosePop()
    }
  })

  const onClosePop = () => {
    // setAnchorEl(null);
    setDialogOpen(false)
  }
  const handlerUserActions = (event: any, type: string, user: CommUser) => {
    let content = '';
    let cancel = '';
    let confirm = '';
    if (user.isMe) {
      return
    }
    switch (type) {
      case 'audio':
        content = user.audioState ? t('peer_invite_microphone_mute_confirm', { name: user.showName }) : t('Turn on Other Microphone', { name: user.showName })
        cancel = t('Cancel')
        confirm = t('Okay')
        break;
      case 'video':
        content = user.videoState ? t('peer_invite_camera_mute_confirm', { name: user.showName }) : t('Turn on Other Camera', { name: user.showName })
        cancel = t('Cancel')
        confirm = t('Okay')
        break;

      case 'kick':
        content = t('Remove Other from the room', { name: user.showName })
        cancel = t('Cancel')
        confirm = t('Okay')
        break;

      case 'assistantInterpreting':
        content = assistantInfo.language === APPLY_ASSISTANT_LANG.EN ? t('Assistant_ask_users_en') : t('Assistant_ask_users_cn')
        cancel = t('as_open_button_original')
        confirm = t('as_open_button_listen')
        break;

      case 'assistantLeave':
        content = t('as_close_leave_info')
        break;
      case 'assistantListening':
        content = t('as_close_title')
        cancel = t('as_close_button_close')
        if (commManager.assistantStatus === ASSISTANT_STATE.ASSIST_ENABLED_WITHOUT_ORIGIN_SOUND) {
          confirm = t('as_close_button_original_open')
        } else if (commManager.assistantStatus === ASSISTANT_STATE.ASSIST_ENABLED_WITH_ORIGIN_SOUND) {
          confirm = t('as_close_button_original_close')
        }
        break;

    }

    setCancelText(cancel)
    setConfirmText(confirm)
    setContentType(type)
    setDialogContent(content)
    setDialogOpen(true)
    setPeerUser(user)
    setPeerUserState(user.isAssistant)

  }

  const onCancel = () => {
    setDialogOpen(false)
    if (!peerUser) { return }
    switch (contentType) {
      case 'assistantInterpreting':
        // 收听助手并关闭原声
        commManager.enableAssist(false)
        if (!commManager.closeUserAssistantNotification) {
          commManager.setCloseAssistantNotification(true)
        }
        break;

      case 'assistantListening':

        commManager.disableAssist()
        if (!commManager.closeUserAssistantNotification) {
          commManager.setCloseAssistantNotification(true)
        }
        break;
    }

  }

  const onConfirm = async () => {
    setDialogOpen(false)
    if (!peerUser) { return }
    switch (contentType) {
      case 'audio':
        commManager.enableRemoteAudio(peerUser.streamId, !peerUser.audioState)
        break;
      case 'video':
        commManager.enableRemoteVideo(peerUser.streamId, !peerUser.videoState)
        break;
      case 'kick':
        commManager.kickUser(peerUser.streamId)
        break;
      case 'assistantInterpreting':
        // 仅收听助手打开原声
        commManager.enableAssist(true)
        commManager.setCloseAssistantNotification(true)
        break;
      case 'assistantListening':
        if (commManager.assistantStatus === ASSISTANT_STATE.ASSIST_ENABLED_WITHOUT_ORIGIN_SOUND) {
          commManager.enableAssist(true)
        } else if (commManager.assistantStatus === ASSISTANT_STATE.ASSIST_ENABLED_WITH_ORIGIN_SOUND) {
          commManager.enableAssist(false)
        }
        commManager.setCloseAssistantNotification(true)
        break;
    }
  }
  const setSearchList = (user: string, type: number) => {
    userManager.searchUser(user.trim(), type)
    setFilterList(userManager.searchResultUsers)
  }
  const throttled = useCallback(throttle((user: string, type: number) => setSearchList(user, type), 300, { leading: true, trailing: true }), [])

  const handleChange = (value: string) => {
    if (isSafari() && value.length > 1 && value[value.length - 1] === '\n' && value[value.length - 2] === '\n') {
      value = value.slice(0, value.length - 1)
    }
    setFilterUser(value)
    // @ts-ignore The parameters have been processed in throttled
    throttled(value, tabs[currentTab])
  }

  const removerFilter = () => {
    setFilterUser("")
    // @ts-ignore The parameters have been processed in throttled
    throttled("", tabs[currentTab])
  }
  const userActionView = ({ data, index, style }: any) => {
    return <UserActions style={style} index={index}
      isSearchStatus={!!filterUser}
      key={data[index].streamId} user={data[index]} onUserAction={handlerUserActions}  onClose={onClosePop}/>
  }

  return (
    <Drawer
      elevation={1}
      className={styles.participantsDrawer}
      anchor={props.IsChatOnDisplay ? "top" : "right"} variant="persistent" open={props.open}
      classes={{
        paper: `${props.IsChatOnDisplay ? styles.halfScreen : styles.participantsDrawerPaper}`,
      }}>
      <div className={styles.container} ref={popoverAnchor}>
        <Box className={styles.header}>
          <Typography variant="h2">{t('Participants')} </Typography>
          <IconButton className={styles.close} id="participants-close"
            onClick={() => props.toggleParticipant()}>
            <IconClose className={styles.iconClose} style={{ fontSize: '1rem' }} />
          </IconButton>
        </Box>
        {commManager.isRoomAgora && <Box className={styles.filterContainer}>
          <div id="participants-all" className={`${currentTab === 'attendeeList' ? styles.active : styles.participantsTab}`} onClick={() => onChangeFilterButton('attendeeList')} >
            <span>{t('All')}</span>
            <span>({userManager.userCount})</span> </div>
          <div id="participants-logged" className={`${currentTab === 'loggedInUserList' ? styles.active : styles.participantsTab}`} onClick={() => onChangeFilterButton('loggedInUserList')}>
            <span>{t('Logged')}</span>
            <span >({userManager.loggedInUsersCount})</span>
          </div>
          <div id="participants-no-logged" className={`${currentTab === 'nonLoggedInUserList' ? styles.active : styles.participantsTab}`} onClick={() => onChangeFilterButton('nonLoggedInUserList')}>
            <span>{t('NonLogged')}</span>
            <span>({userManager.nonLoggedInUsersCount})</span> </div>
        </Box>}
        <Box className={commManager.isRoomAgora ? styles.search : styles.searchBox} >
          <div className={styles.searchUser} id="participants-search"
            style={isShowOutline ? { borderColor: theme.palette.primary.main } : {}}>
            <IconSearch className={styles.IconSearch} />
            <Input disableUnderline
              onFocus={() => { setShowOutLine(true) }}
              onBlur={() => { setShowOutLine(false) }}
              onChange={e => handleChange(e.target.value)}
              value={filterUser} className={styles.searchUserName}
              placeholder={t('SearchKeywords')}
            />
            {filterUser.length > 0 &&
              <IconButton size="small" className={styles.delete} onClick={removerFilter} id="participants-clear-search" >
                <IconDelete className={styles.deleteIcon} /></IconButton>}
          </div>
        </Box>
        <div className={styles.userList} style={commManager.isRoomAgora ? { height: `calc(100% - 11rem)` } : { border: 'none' }}>
          <div className={styles.listContainer} >
            {filterList.length === 0 && filterUser && <div className={styles.notFound}>{t('NotFoundUsers')}</div>}
            <AutoSizer >
              {({ height, width }) => (
                <List
                  style={{ overflowX: 'hidden' }}
                  className={styles.userListContainer}
                  height={height}
                  itemCount={filterList.length}
                  itemSize={50}
                  width={width}
                  itemData={filterList}
                >
                  {userActionView}
                </List>
              )}
            </AutoSizer>
          </div>

        </div>
        <CustomPop
          isOpen={dialogOpen}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center"
          }}
          content={dialogContent}
          anchorEl={popoverAnchor.current}
          onClose={onClosePop}
          isShowButton={contentType === 'assistantLeave' ? false : true}
          isNeedTriangle={false}
          // style={{ marginLeft: '0.875rem' }}
          // vertical={'center'}
          onCancel={onCancel}
          onConfirm={onConfirm}
          cancelText={cancelText}
          confirmText={confirmText}
          buttonGroupStyle={contentType.includes('assistant') ? { display: 'flex', flexDirection: 'column', } : {}}
          buttonStyle={contentType.includes('assistant') ? { marginLeft: 0, marginTop: '0.5rem' } : {}}
          buttonColor={contentType.includes('assistant') ? "default" : "primary"}
        />

      </div>
    </Drawer>)
})
export default Participants