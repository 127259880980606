import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useCommManagerStore, useUserManagerStore } from "../../stores/hooks";
import UserItemMenu from "./UserItemMenu";
import { useStyles } from "./styles";
import { useTheme, IconButton } from "@material-ui/core";
import { ReactComponent as IconMore } from "../../assets/icon_more.svg";
import { CommUser } from "../../types/commUser";

interface IUserOperation {
  isShowMore: boolean,
  user: CommUser,
  handleMenuClose: () => any,
  id?: string,
  style?: React.CSSProperties,
  isNeedStyle?: boolean

}

const UserOperation = ((props: IUserOperation) => {

  const [t] = useTranslation()
  const styles = useStyles()
  const theme = useTheme()
  const isDark = theme.palette.type === 'dark';
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [controlOpen, setControlOpen] = React.useState(Boolean(anchorEl));
  // const controlOpen = Boolean(anchorEl);
  const { isShowMore, user, handleMenuClose, id = "user-item-option", isNeedStyle = true } = props
  const container = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!props.isShowMore) {
      return
    }
  }, [props.isShowMore])
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
    setControlOpen(true)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setControlOpen(false)
    handleMenuClose()
  }
  if (!(isShowMore)) {
    return null
  }
  return (
    <div className={isNeedStyle ? styles.iconContainer : ''} style={{ ...props.style }} ref={container} key={user.streamId}>
      <IconButton id={id} classes={{ label: styles.safariButton }} className={`${styles.iconButton} ${styles.safariButton}`}
        size="small" edge="start" onClick={(e) => handleMenuClick(e)}>
        <IconMore fill={isDark ? '#fff' : theme.palette.primary.main} />
      </IconButton>
      {Boolean(controlOpen && anchorEl) &&
      <UserItemMenu open={Boolean(controlOpen && anchorEl)} handleClose={handleClose}
        user={user} anchorEl={anchorEl || container.current}
        key={user.streamId} />}
    </div>
  )
})

export default UserOperation