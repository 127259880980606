import { Box, List, ListItem, ListItemIcon, ListItemText, Popover } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconAudioInactive } from "../../assets/icon_big_audio_inactive.svg";
import { ReactComponent as IconAudioActive } from "../../assets/icon_big_audio_active.svg";
import { ReactComponent as IconHangup } from "../../assets/icon_kick.svg";
import { ReactComponent as IconVideoInactive } from "../../assets/icon_big_video_inactive.svg";
import { ReactComponent as IconVideoActive } from "../../assets/icon_big_video_active.svg";
import { useCommManagerStore } from "../../stores/hooks";
import { CommUser } from "../../types/commUser";
import { Loading } from "../../components/Loading";
import { useUserItemStyles } from './styles';
import { CustomPop } from "../CustomPopup/customPop";
import { ReactComponent as IconTriangle } from "../../assets/icon_triangle.svg";


export interface UserItemMenuProps {
  open: boolean
  user: CommUser
  handleClose: () => void
  anchorEl?: null | Element | ((element: Element) => Element);
}

const UserItemMenu = observer((props: UserItemMenuProps) => {
  const [t] = useTranslation()
  const commManager = useCommManagerStore()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [dialogOpenType, setDialogOpenType] = React.useState(0)
  const [dialogContent, setDialogContent] = React.useState('')
  const [dialogActionState, setDialogActionState] = React.useState(false)
  const styles = useUserItemStyles();

  const user = props.user
  useEffect(() => {
    if (props.open) {
      setDialogOpen(false)
    }
  }, [props.open])

  const onVideoClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setDialogOpen(true)
    setDialogOpenType(0)
    setDialogContent(user.videoState ? 'peer_invite_camera_mute_confirm' : 'Turn on Other Camera')
    setDialogActionState(user.videoState) // avoid user video changed during dialog pop
  }

  const onAudioClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setDialogOpen(true)
    setDialogOpenType(1)
    setDialogContent(user.audioState ? 'peer_invite_microphone_mute_confirm' : 'Turn on Other Microphone')
    setDialogActionState(user.audioState)
  }

  const onKickClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setDialogOpen(true)
    setDialogOpenType(2)
    setDialogContent('Remove Other from the room')
  }

  const onDialogConfirm = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (dialogOpenType === 0) {
      commManager.enableRemoteVideo(user.streamId, !dialogActionState)
    } else if (dialogOpenType === 1) {
      commManager.enableRemoteAudio(user.streamId, !dialogActionState)
    } else {
      commManager.kickUser(user.streamId)
    }
    onClose()
  }

  const onDialogCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onClose();
  }
  const onClose = async () => {
    setDialogOpen(false)
    if (props.handleClose) {
      props.handleClose()
    }

  }
  if (!props.anchorEl) {
    return null
  }
  return (
    <Fragment>
      <Popover
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={onClose}
        classes={{
          paper: styles.popoverPaper
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: 'left',
        }}
      >
        <Box  key={user.streamId}>
          {/* <div className={styles.triangle} /> */}
          <IconTriangle className={styles.triangle} />
          {!dialogOpen && <List disablePadding component="nav" aria-label="main mailbox folders">
            <ListItem className={styles.listItem} id="user-item-peer-video" button onClick={(event) => onVideoClick(event)}>
              <ListItemIcon className={styles.listIcon} style={{minWidth:0}}>
                {user.isVideoPending ? <Loading style={{ width: '2.125rem' }} /> :
                  (user.videoState ? <IconVideoActive className={styles.iconButton} /> :
                    <IconVideoInactive />)
                }
              </ListItemIcon>
              <ListItemText className={styles.listItemText} primary={user.videoState ? t('Turn off Camera') : t('Turn on Camera')} />
            </ListItem>
            <ListItem className={styles.listItem} id="user-item-peer-audio" button onClick={(event) => onAudioClick(event)}>
              <ListItemIcon className={styles.listIcon} style={{minWidth:0}}>
                {user.isAudioPending ? <Loading style={{ width: '2.125rem' }} /> :
                  (user.audioState ? <IconAudioActive className={styles.iconButton} /> : <IconAudioInactive />)
                }
              </ListItemIcon>
              <ListItemText  className={styles.listItemText} primary={user.audioState ? t('Turn off Microphone') : t('Turn on Microphone')} />
            </ListItem>
            <ListItem className={styles.listItem} id="user-item-kick" button onClick={(event) => onKickClick(event)}>
              <ListItemIcon className={styles.listIcon} style={{minWidth:0}}>
                <IconHangup className={styles.iconButton} />
              </ListItemIcon>
              <ListItemText className={styles.listItemText} primary={t('Remove from the room')} />
            </ListItem>
          </List>}
          {dialogOpen &&
            <CustomPop
              isOpen={dialogOpen}
              anchorEl={props.anchorEl}
              isNeedTriangle={true}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              trianglePosition="left"
              transformOrigin={{
                vertical: "center",
                horizontal: 'left',
              }}
              classes={styles.paper}
              isShowButton={true}
              content={t(dialogContent, { name: props.user.showName })}
              onConfirm={onDialogConfirm}
              onCancel={onDialogCancel}
              onClose={onClose}
            />
          }
        </Box>
      </Popover>
    </Fragment>
  )
})

export default UserItemMenu