import { action, computed, observable } from "mobx";
import { CommUser } from "../types/commUser";
import { SHARE_STATE } from "../utils/constants";
import { log, LOG_MODULE, LOG_TYPE } from "../utils/Log";
import { CommManagerStore } from "./commManager";
import { NotificationStore } from "./notification";
import { RtcCommLayerStore } from "./rtc/rtcCommLayer";
import { RtmCommLayerStore } from "./rtm/rtmCommLayer";
import { UserManagerStore } from "./userManager";

export default class ShareManagerStore {
  @observable
  public shareState: SHARE_STATE = SHARE_STATE.SHARE_IDLE

  @observable
  public isShowErrorMessage = false

  private shareSequence: number = 0

  private rtcComm: RtcCommLayerStore
  private rtmComm: RtmCommLayerStore
  private commManager: CommManagerStore
  private userManager: UserManagerStore
  private notification: NotificationStore

  @computed
  public get IsLocalSharing() {
    return this.shareState !== SHARE_STATE.SHARE_IDLE
  }

  @observable
  public remoteShareOwner?: CommUser = undefined

  @action
  public updateRemoteSharing(owner?: CommUser) {
    this.remoteShareOwner = owner
  }

  @action
  public resetShowErrorMessage() {
    this.isShowErrorMessage = false
  }

  @computed
  public get isRemoteSharing() {
    return this.remoteShareOwner !== undefined
  }

  constructor(rtcComm: RtcCommLayerStore, rtmComm: RtmCommLayerStore, commManager: CommManagerStore, userManager: UserManagerStore, notification: NotificationStore) {
    this.rtcComm = rtcComm
    this.rtmComm = rtmComm
    this.commManager = commManager
    this.userManager = userManager
    this.notification = notification
  }

  public toggleShare(isNeedWatermark?: boolean) {
    if (this.isSharing) {
      log('user disable screen share', LOG_TYPE.COMM, LOG_MODULE.UI)

      this.stopShare()
    } else {
      log('user enable screen share', LOG_TYPE.COMM, LOG_MODULE.UI)

      this.startShare(isNeedWatermark || false)
    }
  }

  public checkAndStopScreenShare() {
    log(`check and stop screen share`, LOG_TYPE.INFO, LOG_MODULE.SHARE)

    if (this.isSharing) {
      this.stopShare()
    }
    if (this.isRemoteSharing) {
      this.remoteShareOwner = undefined
    }
  }

  public async startShare(isNeedWatermark: boolean) {
    log('startShare', LOG_TYPE.INFO, LOG_MODULE.SHARE)
    if (this.userManager.isShareExists()) {
      log(`current room already has share user`, LOG_TYPE.ERROR, LOG_MODULE.SHARE)
      this.isShowErrorMessage = true;
      return
    }

    if (this.shareState !== SHARE_STATE.SHARE_IDLE) {
      log(`startShare, share already, current state: ${SHARE_STATE[this.shareState]}`, LOG_TYPE.ERROR, LOG_MODULE.SHARE)
      return
    }

    this.shareSequence++

    this.setShareState(SHARE_STATE.SHARE_INITIALIZING_1)

    const isSuccess = await this.rtcComm.chooseShareWindow(this)
    if (!isSuccess) {
      log(`startShare, choose share window failure`, LOG_TYPE.ERROR, LOG_MODULE.SHARE)
      this.setShareState(SHARE_STATE.SHARE_IDLE)
      return
    }

    // If user clicks confirm in Browser UI, we still assumes user want to share
    // if (this.isShareStopOrIdel()) {
    //   log(`startShare, current state ${SHARE_STATE[this.shareState]}, return 1`, LOG_TYPE.INFO, LOG_MODULE.SHARE)
    //   this.rtcComm.cancelShareWindow()
    //   return
    // }

    this.setShareState(SHARE_STATE.SHARE_INITIALIZING_2)

    let [seq, shareId, mediaToken] = await this.rtmComm.enableScreenShare(this.shareSequence, isNeedWatermark)

    if (this.shareSequence !== seq || this.isShareStopOrIdel()) {
      log(`startShare return 2, current seq: ${this.shareSequence} get seq: ${seq}, current share state: ${SHARE_STATE[this.shareState]}`, LOG_TYPE.ERROR, LOG_MODULE.SHARE)
      return
    }

    if (shareId === -1) { // -1 means not allowed
      this.rtcComm.cancelShareWindow()
      this.setShareState(SHARE_STATE.SHARE_IDLE)
      this.isShowErrorMessage = true;
      return
    }

    this.setShareState(SHARE_STATE.SHARE_INITIALIZING_3)

    shareId = shareId > 0 ? shareId : Math.floor(150000000 + Math.random() * 50000000)
    const [seq1, ret] = await this.rtcComm.startScreenShare(this.shareSequence, shareId, this.commManager.roomInfo.encryptionMode,this.commManager.roomInfo.salt,mediaToken, isNeedWatermark)

    if (this.shareSequence !== seq1 || this.isShareStopOrIdel()) {
      log(`startShare return 3, current seq: ${this.shareSequence} get seq: ${seq}, current share state: ${SHARE_STATE[this.shareState]}`, LOG_TYPE.ERROR, LOG_MODULE.SHARE)
      return
    }

    if (ret) {
      this.commManager.onLocalShareStart(shareId)

      this.setShareState(SHARE_STATE.SHARE_RUNNING)
      log('startShare success', LOG_TYPE.INFO, LOG_MODULE.SHARE)
      const myInformation = this.userManager.getUserMe()
      this.isShowErrorMessage = false;
      this.notification.onNoticeCLocalSharing(myInformation?.userFullName || '')
    } else {
      log('startShare failure, stop share', LOG_TYPE.ERROR, LOG_MODULE.SHARE)

      this.stopShare()
    }
  }

  private stopShare() {
    log('stopShare', LOG_TYPE.INFO, LOG_MODULE.SHARE)

    if (this.shareState === SHARE_STATE.SHARE_STOPPING || this.shareState === SHARE_STATE.SHARE_IDLE) {
      log(`stop share already, current state: ${SHARE_STATE[this.shareState]}`, LOG_TYPE.ERROR, LOG_MODULE.SHARE)
      return
    }

    const oldState = this.shareState
    this.setShareState(SHARE_STATE.SHARE_STOPPING)

    this.rtcComm.cancelShareWindow()

    // APP-2357: remove (&& this.rtcComm.getSelfShareId() < 150000000), avoid biz already has shareId, but we did not get it,
    if (oldState >= SHARE_STATE.SHARE_INITIALIZING_2) {
      this.rtmComm.disableScreenShare()
    }

    if (oldState >= SHARE_STATE.SHARE_INITIALIZING_3) {
      this.rtcComm.stopScreenShare()
    }

    this.commManager.onLocalShareEnd()

    this.setShareState(SHARE_STATE.SHARE_IDLE)
    const myInformation = this.userManager.getUserMe()
    this.notification.onNoticeStopShare(myInformation?.userFullName || '')
  }

  public onShareWindowEnd() {
    log('onShareWindowEnd', LOG_TYPE.INFO, LOG_MODULE.SHARE)

    if (this.isShareStopOrIdel()) {
      log(`onShareWindowEnd, current state ${SHARE_STATE[this.shareState]}, return`, LOG_TYPE.INFO, LOG_MODULE.SHARE)
      return
    }

    this.stopShare()
  }

  @action
  private setShareState(state: SHARE_STATE) {
    log(`set share state: ${SHARE_STATE[state]}`, LOG_TYPE.INFO, LOG_MODULE.SHARE)
    this.shareState = state
  }

  @computed
  public get isSharing() {
    return this.shareState !== SHARE_STATE.SHARE_IDLE
  }

  @computed
  public get isInitializing() {
    return this.shareState === SHARE_STATE.SHARE_INITIALIZING_1 || this.shareState === SHARE_STATE.SHARE_INITIALIZING_2 || this.shareState === SHARE_STATE.SHARE_INITIALIZING_3
  }

  public isShareStopOrIdel() {
    return this.shareState === SHARE_STATE.SHARE_STOPPING || this.shareState === SHARE_STATE.SHARE_IDLE
  }
}
