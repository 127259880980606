import { makeStyles, createStyles, Theme } from "@material-ui/core";
import {  colorCommon } from "../../style/color";
import { isSafari } from '../../utils/userAgentDetector'
export const useStyles = makeStyles((theme: Theme) => {
  const {
    customColor: {
      componentsBackGround,
      participantsIconColor,
      badgeBackgroundColor,
      badgeColor,
      controlAudioActiveBackground,
      controlAudioActiveShade,
    },
    palette: { primary: { dark, light } } } = theme;
  const backgroundColor = componentsBackGround;
  const isDark = theme.palette.type === 'dark';
  return createStyles({
    footer: {
      width: '100%',
      marginTop: '0.75rem',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor,
      padding: '0.75rem 0',
      borderRadius: '0.75rem',
      width: '100%',
    },
    iconBack: {
      '&:active': {
        backgroundColor: dark,
      },
      '&:hover': {
        backgroundColor: light,
      },
      '&:hover svg': {
        fill: colorCommon.colorWhite,
      }
    },
    chatIconBack: {
      '&:active': {
        backgroundColor: dark,
      },
    },
    badge: {
      height: '0.9375rem',
      minHeight: '.9375rem',
      width: '1.875rem',
      color: badgeColor,
      backgroundColor: badgeBackgroundColor,
      borderRadius: '0.4731rem 0.4731rem 0.4731rem 0rem',
      fontSize: '0.75rem',
      transform: 'scale(.66)',
      transformOrigin: '0 0',
      top: 0,
      fontWeight:600,
      right: '-0.9375rem'
    },
    badgeActive: {
      height: '0.9375rem',
      minHeight: '.9375rem',
      width: '1.875rem',
      color: theme.palette.primary.main,
      backgroundColor:colorCommon.colorWhite,
      borderRadius: '0.4731rem 0.4731rem 0.4731rem 0rem',
      fontSize: '0.75rem',
      transform: 'scale(.66)',
      transformOrigin: '0 0',
      top: 0,
      right: '-0.9375rem',
      fontWeight:600,
    },
    label: {
      marginTop: "-0.4rem",
      fontSize: '0.6rem',
    },
    iconButton: {
      fill: participantsIconColor
    },
    chatButton: {
      fill: participantsIconColor,
      '& path': {
        stroke: participantsIconColor,
      },
      // '&:hover path': {
      //   stroke:colorCommon.colorWhite
      // }
    },
    iconBackgroundActive: {
      backgroundColor: theme.palette.primary.main,
      '& svg path': {
        fill: colorCommon.colorWhite,
        stroke: colorCommon.colorWhite,
      },
      '&:hover': {
        backgroundColor: light,
        fill: dark,
        stroke: dark,
      },
      '&:active': {
        fill: colorCommon.colorWhite,
        backgroundColor: dark,
      },
      '& ellipse':{
        fill: colorCommon.colorWhite,
      }
    },
    hangupIconBack: {
      backgroundColor: isDark ? theme.palette.error.main : colorCommon.colorWhite,
      fill: isDark ? colorCommon.colorWhite :theme.palette.error.main,
      '& svg path': {
        fill:  isDark ? colorCommon.colorWhite :theme.palette.error.main,
        stroke: isDark ? colorCommon.colorWhite :theme.palette.error.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.error.main,
        fill: colorCommon.colorWhite,
        '& svg path': {
          fill: colorCommon.colorWhite,
          stroke: colorCommon.colorWhite,
        },
      },
      '&:active': {
        backgroundColor: theme.palette.error.main,
        fill: colorCommon.colorWhite,
        '& svg path': {
          fill: colorCommon.colorWhite,
          stroke: colorCommon.colorWhite,
        },
      },
    },
    paper:{
      width:'auto',
      overflow: 'inherit',
      borderRadius: '0.75rem',
      padding: '0.75rem 1rem',
      marginTop:'-0.6rem',
      boxShadow: theme.customColor.alertBoxShadow
    },
    buttonAudio: {
      '& > .MuiIconButton-label': {
        position: 'relative',
      }
    },
    micBoxControl: {
      top: '10px',
      width: isSafari() ? '6px' : '5.5px',
      height: '9.5px',
      left:'15px',
      backgroundColor: controlAudioActiveBackground,
      borderRadius: '20px',
      overflow: 'hidden',
      position: 'absolute',
      transform: 'scale(1.05)',

    },
    micShadeControl: {
      bottom: 0,
      width: '100%',
      height: '50%',
      backgroundColor: controlAudioActiveShade,
      overflow: 'hidden',
      position: 'absolute',
      transition: 'height ease-in-out 1ms',
      zIndex: 1000,
    },
    shareButton: {
      width: '2.25rem',
      height: '2.25rem',
    },
  })
})
