import { Box, Button, Modal, Typography, } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Dispatch, SetStateAction, } from "react";
import { useTranslation } from 'react-i18next';
import { useShareManagerStore } from "../../stores/hooks";
import { log, LOG_MODULE, LOG_TYPE } from "../../utils/Log";
import shareRequestBanner from '../../assets/banner_share_watermark_request.svg'
import useStyle from './style'

interface ShareWatermarkProp {
  setClose: Dispatch<SetStateAction<boolean>>,
}

const ShareWatermarkRequestView = observer((props: ShareWatermarkProp) => {
  const [t] = useTranslation()
  const shareManager = useShareManagerStore()
  const styles = useStyle()

  const enableWatermarkShareScreen = (enable?: boolean) => {
    log(`user set watermark in share screen enable: ${enable}`, LOG_TYPE.INFO, LOG_MODULE.UI)
    shareManager.toggleShare(enable)
    props.setClose(false)
  }

  return (
    <Modal className={styles.modal} open={true} hideBackdrop={true}>
      <div className={styles.box}>
        <div className={styles.wrapper} id="right-side-share-request">
          <div className={`${styles.boxTitle}`}>
            <Typography variant="h3" className={`${styles.watermarkTitle}`}>{t('screen_watermark_title_a')}&ensp;{t('screen_watermark_title_b')}</Typography>
          </div>
          <img src={shareRequestBanner} className={`${styles.banner}`} />
          <div className={`${styles.boxButton}`}>
            <Button id="submit-watermark" variant="contained" color="primary" className={`${styles.shareRequestButton}`} onClick={() => enableWatermarkShareScreen(true)}>{t('screen_watermark_button_no')}</Button>
            <Button id="cancel-watermark" variant="contained" className={`${styles.shareRequestButton} ${styles.shareRequestButtonCancel}`} onClick={() => enableWatermarkShareScreen(false)}>{t('screen_watermark_button_yes')}</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
})

export default ShareWatermarkRequestView
