import { Fade, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import { useInterval } from "../../utils/helper";
import { useStyles } from "./style"
import { ReactComponent as IconScreenShare } from "../../assets/icon_screen_share.svg";


const ShareInitView = () => {
  const styles = useStyles()
  const [checked, setChecked] = useState(false)
  const classes = useStyles()

  useInterval(() => {
    setChecked(previewsCheck => !previewsCheck)
  }, 500)

  return (
    <Fade in={checked} timeout={500}>
      {/* <IconButton className={styles.iconBackgroundActive}> */}
        <IconScreenShare />
      {/* </IconButton> */}
    </Fade>
  )
}

export default ShareInitView