import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { colorCommon } from "../../style/color";
import { isLessSafari14 } from '../../utils/userAgentDetector'

const useStyle = makeStyles((theme: Theme) => {
  const { customColor: { settingBackground,
    commonModelBackground, selectMenuItemHover,
    settingTipBackground, settingTextColor,
    selectedColor, settingTabBackgroundColor
  } } = theme
  const settingBgc = settingBackground
  const settingItemBgc = commonModelBackground
  return createStyles({
    // common
    contentBox: {
      width: '36.125rem',
      marginTop: '1rem',
      padding: '1.5rem 2.25rem',
      scrollMarginTop: '1rem',
      borderRadius: '0.75rem',
      background: settingItemBgc
    },
    mediaView:{
      minHeight:'35.5rem'
    },
    roomMediaView:{
      minHeight:'20.75rem'
    },
    commContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '31.625rem',
      marginTop: '.1875rem',
    },
    button: {
      height: '1.875rem',
      borderRadius: '0.5rem',
      border: '0.0625rem solid rgba(35, 50, 87, 0.28)',
    },
    buttonText: {
      height: '1.25rem',
      fontSize: '.875rem',
      fontFamily: 'PingFangSC-Regular, PingFang SC',
      fontWeight: 400,
      color: '#233257',
      lineHeight: '1.25rem',
    },
    box: {
      display: 'flex',
      width: '48.75rem',
      height: '37.5rem',
      backgroundColor: settingBgc,
      boxShadow: theme.palette.type === 'dark' ? '0rem 0.35rem 1.65rem 0rem #0C0D11' : '0rem 0.35rem 1.65rem 0rem rgba(38,40,61,0.12)',
      borderRadius: '1.875rem',
      outline: 0,
      scrollBehavior: 'smooth',
    },
    checkBox: {
      width: '0.875rem',
      height: '0.875rem',
      // border: '0.0625rem solid #B8B8CB',
      borderRadius: '0.25rem',
      boxShadow: 'inset 0 0 0 1px rgba(200,202,206,1)',
    },
    checkedBox: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '0.25rem',
      '&:before': {
        display: 'block',
        width: '0.88rem',
        height: '0.88rem',
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      }
    },
    tabBoxSelected: {
      position: 'relative',
      background: settingTabBackgroundColor,
      '&:before': {
        content: '""',
        position: 'absolute',
        borderRadius: '3px',
        width: '3px',
        height: '40px',
        right: 0,
        backgroundColor: theme.palette.primary.main,
      },
    },
    tabTextSelectedColor: {
      color: colorCommon.colorSettingTabTextGrey,
    },
    line: {
      width: '27.5rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '2.5rem',
      marginTop: '-4rem',
    },
    subLine: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '-3.5rem',
    },
    icon: {
      width: '1.2rem',
      height: '1.2rem',
      cursor: 'pointer',
    },

    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
    },
    leftSide: {
      flex: '1',
      width: '10.4375rem',
      borderRadius: '1.875rem 0rem 0rem 1.875rem',
      background: settingItemBgc,
    },
    tabBox: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '.75rem',
      paddingLeft: '1rem',
      boxSizing: 'border-box',
      cursor: 'pointer',
    },
    menuItem: {
      height: '1.6rem',
      fontSize: '0.875rem!important',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '&:hover': {
        backgroundColor: selectedColor
      },
      '&.MuiListItem-root.Mui-selected': {
        backgroundColor: `${selectedColor}`,
        '&:hover': {
          backgroundColor: `${selectedColor}`,
        }
      },
    },
    rightSide: {
      position: 'relative',
      width: '38.125rem',
      padding: '0 1rem 1rem 1rem',
      borderRadius: '0 1.875rem 1.875rem 0',
      overflow: 'auto',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      }
    },
    setting: {
      display: 'flex',
      marginTop: '1.0625rem',
      marginBottom: '1rem',
      paddingLeft: '1rem',
    },
    settingIcon: {
      width: '2.5rem',
      height: '2.5rem',
      padding: 0
    },

    /** AboutUs View  */
    aboutUsBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '7.25rem',
      padding: '0 2.25rem',
      marginTop: '1rem',
      scrollMarginTop: '1rem',
      borderRadius: '.75rem',
      background: settingItemBgc,
    },
    aboutUsText: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '4.75rem'
    },
    agoraLaw: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      cursor: "pointer"
    },
    control: {
      display: 'flex',
      flexDirection: 'column',
    },
    checkIcon: {
      width: '.875rem',
      height: '.875rem',
      borderRadius: '.25ren',
      border: '.0625rem solid #B8B8CB'
    },
    labelPlacementStart: {
      justifyContent: 'space-between',
      marginLeft: 0,
    },
    subLinLoggedIn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    subLinLoggedInContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '7.25rem',
      height: '1.875rem',
    },
    userText: {
      backgroundColor: 'rgba(184, 184, 203, 0.1)',
      borderRadius: '1rem',
      fontWeight: 600,
    },
    agoraName: {
      textOverflow: 'ellipsis',
      width: '8.5rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      paddingTop: '0.0625rem',
    },
    label: {
      transform: 'scale(1)',
    },
    labelControl: {
      paddingTop: '1rem',
      '& > span.MuiIconButton-root': {
        boxShadow: 'none',
        width: 0,
        height: 0,
        background: 'none'
      }
    },
    h3LineHeight: {
      lineHeight: '2.5rem',
    },

    /** AccountView  */
    loginBox: {
      marginTop: '.5rem'
    },
    wxLoginBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '4.6875rem',
      paddingBottom: '1.1875rem'
    },

    /** MeetingView  */
    hostName: {
      marginLeft: '.75rem',
      color: theme.palette.primary.main,
      maxWidth:'23rem',
      overflow:'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    meetingText: {
      padding: '.6875rem 0 .5rem 0',
      margin: 0
    },
    meetingLabelControl: {
      height:'1.25rem',
      '& > span.MuiIconButton-root': {
        boxShadow: 'none !important',
        background: 'none !important',
      }
    },
    meetingLabel: {
      transform: 'scale(1)'
    },
    meetingCheckBoxDisabled: {
      backgroundColor: '#C4C4D4',
      borderRadius: '0.25rem',
      '&:before': {
        display: 'block',
        width: '0.88rem',
        height: '0.88rem',
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
    },
    meetingDisabledText: {
      color: colorCommon.colorSettingTabTextGrey,
    },
    hostInfo: {
      display: 'flex'
    },
    assistantText: {
      marginTop: '1rem',
    },
    assistantTips: {
      maxWidth: '16.4rem',
      background: settingTipBackground,
      borderRadius: '.625rem',
      color: '#9399AF',
      padding: '.1875rem .625rem',
      marginTop: '-2px',
      display:'inline-block'
    },

    /** MediaView  */
    videoPreview: {
      width: '27.25rem',
      height: '15.25rem',
      margin: '.5rem 0',
      borderRadius: '.625rem',
      backgroundColor: theme.customColor.videoPreviewBackgroundColor,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        width: '3.25rem',
        height: '3.25rem'
      },
      '& > div > div > div': {
        '& > div': {
          borderRadius: '.625rem',
        }
      }
    },
    videoText: {
      marginBottom: '.5rem',
      marginTop:'.5rem',
    },
    commonBox: {
      marginTop: '1rem',
    },
    selectTextColor: {
      color: settingTextColor,
      borderRadius:'8px'
    },
    resolutionText: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '.5rem'
    },
    resolutionTips: {
      display: 'flex',
      padding: '.1875rem .625rem',
      background: settingTipBackground,
      borderRadius: '.625rem',
      color: '#9399AF',
      marginLeft: '.5rem',
    },
    micBox: {
      display: 'flex',
      marginTop: '.5rem',
      '& > .MuiOutlinedInput-root': {
        marginRight: '.375rem'
      }
    },
    volumeBox: {
      display: 'flex',
      justifyContent: "space-between",
      width: '12.25rem',
      marginTop: '1rem'
    },
    volumeIndicator: {
      width: '.375rem',
      height: '.375rem',
      borderRadius: '.1875rem',
      background: theme.palette.primary.main,
    },
    volumeNone: {
      width: '.375rem',
      height: '.375rem',
      borderRadius: '.1875rem',
      background: colorCommon.colorSetting
    },

    /*Upload log View */
    uploadLogBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '4.25rem',
      padding: '0 2.25rem',
      marginTop: '1rem',
      scrollMarginTop: '1rem',
      borderRadius: '.75rem',
      background: settingItemBgc,
    },
    uploadTips: {
      display: 'flex',
      padding: '.1875rem .625rem',
      background: settingTipBackground,
      borderRadius: '.625rem',
      color: '#9399AF',
      marginLeft: '.5rem',
      transformOrigin: '0'
    },
    uploadText: {
      display: 'flex',
      alignItems: 'center',
    },
    loading: {
      animation: '$rotating 2s linear infinite',
    },
    '@keyframes rotating': {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '100%': {
        transform: 'rotate(360deg)'
      }
    },
    buttonSuccess: {
      background: '#6FCF97',
      border: '0 !important',
      '& > .MuiButton-label': {
        color: 'white',
      }
    },
    buttonFailed: {
      background: colorCommon.colorError,
      border: '0 !important',
      '& > .MuiButton-label': {
        color: 'white',
      }
    },
    debugBox: {
      flex: 1,
      width: '100%',
      height: '4.25rem',
    },
    safariButton: {
      '& > .MuiButton-label': {
        marginTop: isLessSafari14() && '-3.5px !important',
      },
    },

    // logout dialog
    logoutDialog: {
      position: 'fixed',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '15rem',
      height: '6.875rem',
      padding: '1rem',
      boxShadow: '0px 1px 13px 0px rgba(67, 92, 115, 0.17)',
      zIndex: 1,
      transform: 'translate(-20%, -50%)',
      backgroundColor: `${settingItemBgc} !important`
    },
    logoutButtonBox: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '13rem',
    },
    customDialog: {
      boxShadow: '0px 1px 13px 0px rgba(67, 92, 115, 0.17)',
    },
    logoutButton: {
      width: '5.875rem',
      height: '1.875rem'
    },
    customButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      marginTop: '1rem',
    },
    assistantButtonBox: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '1rem',
    },
    assistantButton: {
      width: '13.25rem',
      height: '1.875rem',
      marginTop: '0.5rem',
    },
  })

})

export default useStyle

