import { createStyles, makeStyles, Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) => {
  const itemWidth = '17rem';
  const { customColor: { componentsBackGround, boxShadow }, palette } = theme;

  const isDark = theme.palette.type === 'dark'
  const background = isDark ? ' linear-gradient(270deg, #364446 0%, #443B34 51%, #374546 100%);' : componentsBackGround;

  return createStyles({
    container: {
      width: '100%',
      height: '100%',
    },

    mainPlayer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      background,
      borderRadius: '0.75rem',
      position:'relative',
      transition: '.3s all ease',
    },
    preview:{
      width: 'calc(100vw - 2.5rem)',
      height: 'calc(100vh - 2.5rem)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      background,
      borderRadius: '0.75rem',
      position:'fixed',
      top:'1.25rem',
      left:'1.25rem',
      zIndex:20,
      transition: '.3s all ease',
    },

    padding: {
      paddingLeft: 160,
    },
    userName: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      height: 80,
      textOverflow: 'ellipsis',
      paddingBottom: 56,
      fontSize: 352,
      fontWeight: 600,
      color: 'var(--colorDarkGrey)',
    },
    iconMore:{
      width:'1.25rem',
      height:'1.25rem'
    },
    spokesman: {
      position: 'absolute',
      bottom: 16,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft:'0.625rem',
      zIndex: 1100,
    },
    spokesmanName: {
      color: '#fff',
      background: '#35363942',
      borderRadius: '0.9375rem',
      margin:'0 0.75rem',
      padding: '0.3125rem 1rem',
      width:'8.75rem',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textShadow: `0px 1px 0px #0000001A`,
      fontWeight:600,
    },
    spokesmanNameHover:{
      color: '#fff',
      background: '#35363942',
      borderRadius: '0.9375rem',
      margin:'0 0.75rem',
      padding: '0.3125rem 1rem',
      width:'8.75rem',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '&:hover': {
        background: 'rgba(53,54,57,0.35)'
      }
    },
    iconSmile: {
      fill: theme.customColor.occupationsIconColor,
      '& path': {
        fill: palette.type === 'dark' ? '#404041FF' :'#C0C1C2',
      },
      '& circle': {
        fill: palette.type === 'dark' ? '#404041FF' :'#C0C1C2',
      }
    },
    iconContainer: {
      width: '1.2rem',
      height: '1.2rem',
      background: '#FFFFFF',
      position: 'absolute',
      top: '0.5rem',
      right: '0.5rem',
      boxShadow: '0rem 0.06rem 0.31rem 0rem rgba(0, 0, 0, 0.3)',
      borderRadius: '0.24rem',
    },
    viewMouseEventBox:{
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    connectText: {
      position: 'absolute',
      zIndex: 1,
      top: '2.25rem',
      left: '2.25rem',
      backgroundColor: '#00000059',
      color: '#fff',
      padding:'0.5rem 0.75rem',
      minWidth:'6.25rem',
      fontSize:'0.75rem',
      boxShadow: '0px 1px 13px 0px rgba(67, 92, 115, 0.17)',
      borderRadius: '0.625rem',
    },
    loading: {
      // width: '100px',
      '&::after': {
        animation: '$loading 1s ease alternate infinite',
        content: "'.'"
      },
    },
    '@keyframes loading': {
      '60%': { textShadow: '0.35em 0 0 currentColor' },
      '100%': { textShadow: '0.35em 0 0 currentColor, 0.75em 0 0 currentColor' }
    },

  })
})