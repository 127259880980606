import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Notification from "../components/Notification";
import { NotificationInfo } from "../types/notification";
import { isCurrentLocationShare } from '../utils/Scheme';
import { isAndroid, isWX } from '../utils/userAgentDetector';
import AndroidDeepLinkJumpDialog from "./AndroidDeepLinkJumpDialog";
import styles from "./style.module.css";
import { isChinese } from "../utils/helper"

const Website = () => {
  const [t] = useTranslation()
  const [scheme, setScheme] = useState(false)

  const jump = (platform: string) => {
    if (platform === 'ios') {
      if (isAndroid()) {
        if (isChinese()) {
          window.open('https://apps.apple.com/cn/app/agora-video-call/id1080303824')
        } else {
          window.open('https://apps.apple.com/us/app/agora-video-call/id1080303824')
        }
      } else {
        window.open('itms-apps://itunes.apple.com/app/apple-store/id1080303824')
      }
    }
    if (platform === 'android') {
      if (isChinese()) {
        window.open('https://android.myapp.com/myapp/detail.htm?apkName=io.agora.vcall')
      } else {
        window.open('https://play.google.com/store/apps/details?id=io.agora.vcall')
      }
    }
  }

  useEffect(() => {
    if (isCurrentLocationShare()) {
      setScheme(true)
    }
  }, [])

  return (
    <div className={styles.container}>
      {(scheme && isAndroid() && !isWX()) && <AndroidDeepLinkJumpDialog onClose={() => setScheme(false)}/>}
      <div className={styles.logo}></div>
      <div className={`${styles.company} notranslate`}>Agora Video Call</div>
      <div className={styles.ios} onClick={() => { jump('ios') }}>
        <div>
          <div className={styles.download}>{t('iOSDownloadNow')}</div>
        </div>
      </div>
      <div className={styles.android} onClick={() => { jump('android') }}>
        <div>
          <div className={styles.download}>{t('AndroidDownloadNow')}</div>
        </div>
      </div>
      { (scheme && isWX()) && <div className={styles.notificationList}>
        <Notification key={0} className={styles.notification} info={new NotificationInfo("info", "OpenWithBrowser")} onExited={() => {}}/>
      </div>}
    </div>
  )
}

export default Website