import React from 'react';
import { Popover, Box, Typography, Button, PopoverOrigin, PopoverPosition, PropTypes, } from '@material-ui/core';
import { customPopStyle, useStyles } from "./styles"
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconTriangle } from "../../assets/icon_triangle.svg";
interface IPosition {
  vertical?: 'top' | 'center' | 'bottom' | number;
  horizontal?: 'left' | 'center' | 'right' | number;
}
interface ICustomPop {
  title?: string | React.ReactDOM,
  content: string | React.ReactDOM,
  isOpen: boolean,
  isNeedTriangle: boolean,
  anchorOrigin?: PopoverOrigin,
  trianglePosition?: 'right' | 'left' | 'top' | 'bottom',
  transformOrigin?: PopoverOrigin,
  isShowConfirmButton?: boolean;
  isShowButton?: boolean;
  isShowCancelButton?: boolean;
  onCancel?: (event?:any) => void;
  onConfirm?: (event?:any) => void;
  onClose?: (event?:any) => void;
  cancelText?: string;
  confirmText?: string;
  anchorEl?: null | Element | ((element: Element) => Element);
  triangleStyle?: React.CSSProperties,
  style?: React.CSSProperties,
  anchorPosition?: PopoverPosition;
  marginThreshold?: number,
  buttonGroupStyle?: React.CSSProperties,
  buttonStyle?: React.CSSProperties,
  buttonColor?: PropTypes.Color,
  needCustomerWidth?: boolean,
  classes?: any,
}
const triangle = {
  top: {
    transform: 'translate(-50%,-50%)',
    top: '-0.4375rem',
    left: '50%',
    marginRight: '1.375rem',
  },
  bottom: {
    transform: 'rotate(180deg) translate(50%,50%)',
    top: 'calc(100% + 0.4375rem)',
    left: '50%',
  },
  left: {
    top: '50%',
    left: '-0.4375rem',
    transform: 'rotate(270deg) translate(50%,-50%)'
  },
  right: {
    top: '50%',
    left: 'calc(100% + 0.4375rem)',
    transform: 'rotate(90deg) translate(-50%,50%)'
  }
}
export const CustomPop = (props: ICustomPop) => {

  const styles = { ...(useStyles()), ...customPopStyle(), };
  const [t] = useTranslation()
  const { onCancel,
    title,
    content,
    isNeedTriangle = true,
    confirmText = t('Okay'),
    cancelText = t('Cancel'),
    onConfirm,
    anchorOrigin = {
      vertical: "center",
      horizontal: "center"
    },
    transformOrigin = {
      vertical: "center",
      horizontal: "center"
    },
    marginThreshold = 16,
    trianglePosition = "top",
    needCustomerWidth = false,
    isShowCancelButton = true,
    isShowButton = false } = props;
  const popoverClasses = props.style?.width ? styles.popPaper : styles.paper;
  if (!props.anchorEl) {
    return null
  }
  return (
    <Popover
      open={props.isOpen}
      marginThreshold={marginThreshold}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      classes={{
        paper: props.classes || popoverClasses
      }}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      style={{ ...props.style }}
    >
      <Box>
       {isNeedTriangle && <IconTriangle className={styles.triangle} style={{ ...triangle[trianglePosition], ...props.triangleStyle, }} />}
        {title ? <Typography variant="h3" style={{ marginBottom: '0.5rem' }}  id="custom-pop-title">{title}</Typography> : null}
        <div className={`${styles.content} notranslate  ${title ? styles.hasTitleContent : ''}`} id="custom-pop-content">{content}</div>
        {isShowButton && <div className={styles.buttonGroups} style={{ ...props.buttonGroupStyle }}>
          {isShowCancelButton && <Button variant="contained"
           id="custom-pop-cancel"
            onClick={(e) => onCancel && onCancel(e)}
            className={styles.button}
            style={{ ...props.buttonStyle }}
          >{cancelText}</Button>}
          <Button variant="contained"
            onClick={(e) =>onConfirm && onConfirm(e)}
            color={props.buttonColor ? props.buttonColor : 'primary'}
            id="custom-pop-confirm"
            style={{ ...props.buttonStyle }}
            className={styles.button} >{confirmText}</Button>
        </div>}

      </Box>
    </Popover>
  )
}