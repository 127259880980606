import { List, useTheme } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { CommUser } from "../../types/commUser";
import { useCommManagerStore, useUserManagerStore } from "../../stores/hooks";
import styles from "./style.module.css";
import UserItemView from "./UserItem";
import { userListStyles as useStyles } from './styles';
// import { ReactComponent as IconOccupations } from "../../assets/icon_occupations.svg";

interface UserListViewProps {
  toggle: boolean;
}

const UserListView = observer((props: UserListViewProps) => {
  const classes = useStyles()
  const commManager = useCommManagerStore()
  const theme =useTheme()
  const userManager = useUserManagerStore()
  const container = useRef<HTMLDivElement>(null);
  const [occupations, setOccupations] = useState<any[]>([])
  useEffect(() => {
    window.addEventListener('resize', handleOnResize)
    return () => {
      window.removeEventListener('resize', handleOnResize)
    }
  },[])
  useEffect(() => {
    handleOnResize()
  }, [userManager.mediaList.length]);

  const handleOnResize = () => {
    const containerHeight = container.current?.clientHeight || document.body.clientHeight;
    const occupationNum = Math.floor(containerHeight / 148)
    const hasStreamList = userManager.mediaList.filter((item) => (item.videoState || item.audioState))
    const length = occupationNum - hasStreamList.length;
    if (length > 0) {
      const newArray: any[] = Array(length).fill('')
      setOccupations(newArray)
    } else {
      setOccupations([])
    }
  }

  return (
    <div className={styles.container} style={{ left: props.toggle ? '0' : '-11rem' }} ref={container}>
      <List className={styles.list} classes={{ root: classes.listRoot }} >
        {userManager.mediaList.map((user: CommUser, index: number) => (
          <UserItemView user={user} key={user.streamId} index={index} />
        ))}
        {
          occupations.length > 0 && occupations.map((_, index: number) => {
            return <div className={classes.occupations} key={index}>
              {/* <IconOccupations key={index} fill={theme.customColor.occupationsIconColor} /> */}
            </div>
          })
        }
      </List>
    </div>
  )
})

export default UserListView
