import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { reporter } from "./utils/Reporter";
import { isIpad, isMobile } from './utils/userAgentDetector';
import Website from './website/index';

if ((isMobile() || isIpad()) && window.location.search.indexOf('needMobile') === -1) {

  ReactDOM.render(<Website />, document.getElementById('root'));

} else {
  reporter.reportAppInit()

  ReactDOM.render(<App />, document.getElementById('root'));
}

serviceWorker.unregister();
