import { Button, Dialog, DialogActions, DialogContent, Link, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { currentLocationToScheme } from "../utils/Scheme";

export interface LinkProps {
  onClose: () => void
}

const useStyles = makeStyles({
  paper: {
    padding: '0.5rem 1rem 0.5rem 0.5rem',
    minWidth: '15.2rem',
  },
  title: {
    '& .MuiTypography-root': {
      fontSize: '0.9rem',
    }
  },
  content: {
    fontSize: '0.9rem',
  },
  link: {
    color: '#00A8FF',
    marginBottom: '0.2rem',
  }
})

const AndroidDeepLinkJumpDialog = (props: LinkProps) => {
  const [t] = useTranslation()
  const [open, setOpen] = useState(true);
  const styles = useStyles()

  const handleClose = () => {
    setOpen(false)

    props.onClose()
  }

  const schemeLink = currentLocationToScheme()

  return (
    <Dialog
      classes={{ paper: styles.paper }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent classes={{ root: styles.content }}>{t('GotoLink')}</DialogContent>
      <DialogActions>
        <Button onClick={() => { handleClose() }}>
          {t('CANCEL')}
        </Button>
        <Link className={styles.link} href={schemeLink} onClick={() => { handleClose() }}>
          {t('OK')}
        </Link>
      </DialogActions>
    </Dialog>
  )
}

export default AndroidDeepLinkJumpDialog