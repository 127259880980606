import { action, observable } from "mobx";
import { RTC_QUALITY_TYPE } from "../../utils/constants";
import { log, logIf, LOG_MODULE, LOG_TYPE } from "../../utils/Log";

export const MAX_QUALITY_COUNT = 5
export const IMMUNE_WEAK_TIME = 30 * 1000
export const IMMUNE_BY_TYPE = 3 * 60 * 1000
const QUALITY_THRESHOLD = 24

let QUALITY_LOG = false

export function showQualityLog() {
  return QUALITY_LOG
}

export function setShowQualityLog(show: boolean) {
  QUALITY_LOG = show
}

export enum QualityType {
  LOCAL_NETWORK_OFF = 0,
  REMOTE_NETWORK_OFF = 1,
  LOCAL_NETWORK_DOWN_WEAK = 2,
  REMOTE_NETWORK_UP_WEAK = 3,
  LOCAL_NETWORK_UP_WEAK = 4,
  REMOTE_NETWORK_DOWN_WEAK = 5,
  LOCAL_NETWORK_UP_GOOD = 6,
  LOCAL_NETWORK_DOWN_GOOD = 7,
  REMOTE_NETWORK_UP_GOOD = 8,
  REMOTE_NETWORK_DOWN_GOOD = 9,
  LOCAL_NETWORK_DETECTING = 10,
  REMOTE_NETWORK_DETECTING = 11,
  LOCAL_NETWORK_CALCING = 12,
  REMOTE_NETWORK_CALCING = 13,
  LOCAL_NETWORK_UP_UNKNOWN = 14,
  LOCAL_NETWORK_DOWN_UNKNOWN = 15,
  REMOTE_NETWORK_UP_UNKNOWN = 16,
  REMOTE_NETWORK_DOWN_UNKNOWN = 17,
  NETWORK_NONE = 18,
  NETWORK_MAX = 19,
  SHOW_QUALITY_Feedback = 20
}

enum IMMUNE_TYPE {
  LOCAL_WEAK = 0,
  REMOTE_WEAK = 1,
  LOCAL_OFF = 2,
  REMOTE_OFF = 3,
  TYPE_MAX = 4
}

declare type CalcType = "local tx" | "local rx" | "remote tx" | "remote rx";

class QualityCalc {
  public streamId: number = 0
  private qualities: number[] = [0, 0, 0]
  private index = 0
  private type: CalcType
  private weak: QualityType
  private good: QualityType

  constructor(type: CalcType, good: QualityType, weak: QualityType) {
    this.type = type
    this.weak = weak
    this.good = good
  }

  public inputQuality(quality: number): QualityType {
    if (quality === RTC_QUALITY_TYPE.QUALITY_UNKNOWN || quality === RTC_QUALITY_TYPE.QUALITY_DOWN || quality === RTC_QUALITY_TYPE.QUALITY_DETECTING) {
      this.index = 0
      for (let i = 0; i < MAX_QUALITY_COUNT; i++) {
        this.qualities[i] = 0
      }

      if (quality === RTC_QUALITY_TYPE.QUALITY_DOWN) {
        return this.streamId === 0 ? QualityType.LOCAL_NETWORK_OFF : QualityType.REMOTE_NETWORK_OFF
      }

      if (quality === RTC_QUALITY_TYPE.QUALITY_DETECTING) {
        return this.streamId === 0 ? QualityType.LOCAL_NETWORK_DETECTING : QualityType.REMOTE_NETWORK_DETECTING
      }

      if (quality === RTC_QUALITY_TYPE.QUALITY_UNKNOWN) {
        // 此处返回UNKNOWN 有两个原因
        // https://jira.agoralab.co/browse/APP-2085
        // 原因1: 断网恢复后，如果没有音视频发流，则本地上行会一直反馈 0（Unknown），但其实网络已经恢复了
        // 原因2: 本地下行弱网，提示中，远端关闭媒体流，sdk反馈本地下行 UNKNOWN， 这时候要取消下行弱网提示，所以这里区分 UNKNOWN 为上下行
        if (this.type === "local tx") {
          return QualityType.LOCAL_NETWORK_UP_UNKNOWN
        }
        if (this.type === "local rx") {
          return QualityType.LOCAL_NETWORK_DOWN_UNKNOWN
        }
        if (this.type === "remote tx") {
          return QualityType.REMOTE_NETWORK_UP_UNKNOWN
        }
        if (this.type === "remote rx") {
          return QualityType.REMOTE_NETWORK_DOWN_UNKNOWN
        }
        return QualityType.NETWORK_NONE
      }
      return QualityType.NETWORK_NONE
    }

    this.qualities[this.index++ % MAX_QUALITY_COUNT] = quality

    let total = 0
    for (let i = 0; i < MAX_QUALITY_COUNT; i++) {
      logIf(QUALITY_LOG, `calc ${this.type} index; ${i} quality: ${this.qualities[i]}`, LOG_TYPE.INFO, LOG_MODULE.QUALITY)
      if (this.qualities[i] === 0) {
        return this.streamId === 0 ? QualityType.LOCAL_NETWORK_CALCING : QualityType.REMOTE_NETWORK_CALCING
      }

      total += this.qualities[i]
    }

    logIf(QUALITY_LOG, `${this.type} calc quality, total: ${total}`, LOG_TYPE.INFO, LOG_MODULE.QUALITY)

    if (total >= QUALITY_THRESHOLD) {
      return this.weak
    }

    return this.good
  }

  public setStreamId(streamId: number) {
    log(`${this.type} reset stream id: ${streamId} old: ${this.streamId}`)

    this.streamId = streamId
    this.reset()
  }

  public reset() {
    this.index = 0;
    this.qualities = []
  }
}

export class NetworkEval {
  @observable
  public lastQualityType = QualityType.NETWORK_NONE

  private ignoreRemote = true

  // tslint:disable-next-line: prefer-array-literal
  private immuneTpyes: number[] = new Array(IMMUNE_TYPE.TYPE_MAX)
  private immuneWeakTimer: number = 0
  private lastWeakTimestamp = 0

  private ignoreLocalTx = true
  private ignoreRemoteTx = true

  private localTxCalc = new QualityCalc("local tx", QualityType.LOCAL_NETWORK_UP_GOOD, QualityType.LOCAL_NETWORK_UP_WEAK)
  private localRxCalc = new QualityCalc("local rx", QualityType.LOCAL_NETWORK_DOWN_GOOD, QualityType.LOCAL_NETWORK_DOWN_WEAK)
  private remoteTxCalc = new QualityCalc("remote tx", QualityType.REMOTE_NETWORK_UP_GOOD, QualityType.REMOTE_NETWORK_UP_WEAK)
  private remoteRxCalc = new QualityCalc("remote rx", QualityType.REMOTE_NETWORK_DOWN_GOOD, QualityType.REMOTE_NETWORK_DOWN_WEAK)

  public setIgnoreLocalTx(ignore: boolean): QualityType {
    if (this.ignoreLocalTx !== ignore) {
      this.ignoreLocalTx = ignore

      this.localTxCalc.reset()

      if (this.lastQualityType === QualityType.LOCAL_NETWORK_UP_WEAK) {
        this.setQualityType(QualityType.NETWORK_NONE)
      }
    }

    return this.lastQualityType
  }

  public isIgnoreRemote(): boolean {
    return this.ignoreRemote
  }

  public setIgnoreRemoteTx(streamId: number, ignore: boolean): QualityType {
    if (this.ignoreRemote) return this.lastQualityType

    if (this.remoteTxCalc.streamId !== streamId) return this.lastQualityType

    if (this.ignoreRemoteTx !== ignore) {
      this.ignoreRemoteTx = ignore

      this.remoteTxCalc.reset()

      if (this.lastQualityType === QualityType.REMOTE_NETWORK_UP_WEAK) {
        this.setQualityType(QualityType.NETWORK_NONE)
      }
    }

    return this.lastQualityType
  }

  public startEvalRemote(streamId: number, ignoreRemoteTx: boolean) {
    log(`start eval remote, stream id: ${streamId}, ignore remote tx: ${ignoreRemoteTx}`, LOG_TYPE.INFO, LOG_MODULE.QUALITY)

    this.ignoreRemote = false

    this.remoteTxCalc.setStreamId(streamId)
    this.remoteRxCalc.setStreamId(streamId)

    this.setIgnoreRemoteTx(streamId, ignoreRemoteTx)
  }

  public stopEvalRemote() {
    if (!this.ignoreRemote) {
      log(`stop eval remote`, LOG_TYPE.INFO, LOG_MODULE.QUALITY)

      this.ignoreRemote = true

      this.remoteTxCalc.reset()
      this.remoteRxCalc.reset()

      if (this.isCurrentRemoteQuality()) {
        this.setQualityType(QualityType.NETWORK_NONE)
      }
    }
  }

  public resetNetworkEval() {
    log(`reset network eval`, LOG_TYPE.INFO, LOG_MODULE.QUALITY)

    // Unit test check
    this.immuneTpyes.forEach((value: number, index: number) => {
      if (value > 0) {
        window.clearTimeout(value)
        this.immuneTpyes[index] = 0
      }
    })

    if (this.immuneWeakTimer > 0) {
      window.clearTimeout(this.immuneWeakTimer)
      this.immuneWeakTimer = 0
    }

    this.ignoreRemote = true

    this.localTxCalc.reset()
    this.localTxCalc.reset()
    this.remoteTxCalc.reset()
    this.remoteRxCalc.reset()

    this.ignoreLocalTx = true
    this.ignoreRemoteTx = true

    this.setQualityType(QualityType.NETWORK_NONE)
  }

  public immuneByType() {
    const type = this.qualityTypeToImmuneType(this.lastQualityType)

    log(`immune by type: ${IMMUNE_TYPE[type]}`, LOG_TYPE.INFO, LOG_MODULE.QUALITY)

    if (type !== IMMUNE_TYPE.TYPE_MAX) {
      this.immuneTpyes[type] = window.setTimeout(() => {
        this.immuneTpyes[type] = 0
      }, IMMUNE_BY_TYPE)
    } else {
      log(`immune by type, not expect current type: ${QualityType[this.lastQualityType]}`, LOG_TYPE.ERROR, LOG_MODULE.QUALITY)
    }

    this.setQualityType(QualityType.NETWORK_NONE)

    return this.lastQualityType
  }

  private qualityTypeToImmuneType(type: QualityType) {
    switch (type) {
      case QualityType.LOCAL_NETWORK_OFF:
        return IMMUNE_TYPE.LOCAL_OFF
      case QualityType.REMOTE_NETWORK_OFF:
        return IMMUNE_TYPE.REMOTE_OFF
      case QualityType.LOCAL_NETWORK_UP_WEAK:
      case QualityType.LOCAL_NETWORK_DOWN_WEAK:
        return IMMUNE_TYPE.LOCAL_WEAK
      case QualityType.REMOTE_NETWORK_UP_WEAK:
      case QualityType.REMOTE_NETWORK_DOWN_WEAK:
        return IMMUNE_TYPE.REMOTE_WEAK
      default:
        return IMMUNE_TYPE.TYPE_MAX
    }
  }

  public immuneWeakNetwork() {
    this.immuneWeakTimer = window.setTimeout(() => {
      this.immuneWeakTimer = 0
    }, IMMUNE_WEAK_TIME)

    this.setQualityType(QualityType.NETWORK_NONE)

    return this.lastQualityType
  }

  public onLocalQuality(tx: number, rx: number): QualityType {
    logIf(QUALITY_LOG, `on local network-quality tx: ${tx} rx: ${rx}, ignore local tx: ${this.ignoreLocalTx}`)

    const rxType = this.localRxCalc.inputQuality(rx)
    this.onQualityType(rxType)

    if (!this.ignoreLocalTx) {
      const txType = this.localTxCalc.inputQuality(tx)
      this.onQualityType(txType)
    }

    return this.lastQualityType
  }

  public onRemoteQuality(streamId: number, tx: number, rx: number): QualityType {
    if (this.ignoreRemote) return this.lastQualityType

    if (this.remoteTxCalc.streamId !== streamId) {
      log(`on remote quality, stream id not match, current: ${this.remoteTxCalc.streamId}, get: ${streamId}`, LOG_TYPE.ERROR, LOG_MODULE.QUALITY)

      return this.lastQualityType
    }

    logIf(QUALITY_LOG, `on remote quality stream id: ${streamId} tx: ${tx} rx: ${rx}, ignore remote tx: ${this.ignoreRemoteTx}`)

    if (!this.ignoreRemoteTx) {
      const txType = this.remoteTxCalc.inputQuality(tx)
      this.onQualityType(txType)
    }

    const rxType = this.remoteRxCalc.inputQuality(rx)
    this.onQualityType(rxType)

    return this.lastQualityType
  }

  public onQualityType(type: QualityType) {
    logIf(QUALITY_LOG, `on quality type: ${QualityType[type]}, last type: ${QualityType[this.lastQualityType]}`)

    const newType = this.calcQualityType(type)

    if (newType === QualityType.NETWORK_MAX) return

    if (this.immuneWeakTimer > 0 && (this.isLocalWeak(newType) || this.isRemoteWeak(newType))) {
      logIf(QUALITY_LOG, `on quality type, new type: ${QualityType[newType]}, immune weak, return`)
      return
    }

    const immuneType = this.qualityTypeToImmuneType(newType)
    if (immuneType !== IMMUNE_TYPE.TYPE_MAX && this.immuneTpyes[immuneType] > 0) {
      logIf(QUALITY_LOG, `on quality type, new type: ${QualityType[newType]}, immune by type ${IMMUNE_TYPE[immuneType]}, return`)
      return
    }

    if (this.isCurrentWeak() && !this.isWeak(newType)) {
      this.lastWeakTimestamp = +new Date()
    }

    if (this.isWeak(newType) && this.lastQualityType === QualityType.NETWORK_NONE) {
      const duration = (+new Date()) - this.lastWeakTimestamp
      // https://jira.agoralab.co/browse/APP-1946
      if (duration < 10 * 1000) {
        logIf(QUALITY_LOG, `cancel this weak type because of 10s immune`)
        return
      }
    }

    logIf(QUALITY_LOG, `on quality final type: ${QualityType[newType]}`)

    this.setQualityType(newType)
  }

  private calcQualityType(type: QualityType) {
    if (type < QualityType.LOCAL_NETWORK_UP_GOOD) {
      if (type < this.lastQualityType) {
        return type
      }

      if (this.lastQualityType === QualityType.LOCAL_NETWORK_OFF && (type === QualityType.LOCAL_NETWORK_UP_WEAK || type === QualityType.LOCAL_NETWORK_DOWN_WEAK)) {
        return type
      }

      if (this.lastQualityType === QualityType.REMOTE_NETWORK_OFF && (type === QualityType.REMOTE_NETWORK_UP_WEAK || type === QualityType.REMOTE_NETWORK_DOWN_WEAK)) {
        return type
      }
    } else {
      switch (type) {
        case QualityType.LOCAL_NETWORK_UP_GOOD:
          if (this.lastQualityType === QualityType.LOCAL_NETWORK_UP_WEAK || this.lastQualityType === QualityType.LOCAL_NETWORK_OFF) {
            return QualityType.NETWORK_NONE
          }
          break;
        case QualityType.LOCAL_NETWORK_DOWN_GOOD:
          if (this.lastQualityType === QualityType.LOCAL_NETWORK_DOWN_WEAK || this.lastQualityType === QualityType.LOCAL_NETWORK_OFF) {
            return QualityType.NETWORK_NONE
          }
          break;
        case QualityType.REMOTE_NETWORK_UP_GOOD:
          if (this.lastQualityType === QualityType.REMOTE_NETWORK_UP_WEAK || this.lastQualityType === QualityType.REMOTE_NETWORK_OFF) {
            return QualityType.NETWORK_NONE
          }
          break;
        case QualityType.REMOTE_NETWORK_DOWN_GOOD:
          if (this.lastQualityType === QualityType.REMOTE_NETWORK_DOWN_WEAK || this.lastQualityType === QualityType.REMOTE_NETWORK_OFF) {
            return QualityType.NETWORK_NONE
          }
          break;
        case QualityType.LOCAL_NETWORK_CALCING:
          if (this.lastQualityType === QualityType.LOCAL_NETWORK_OFF) {
            return QualityType.NETWORK_NONE
          }
          break;
        case QualityType.REMOTE_NETWORK_CALCING:
          if (this.lastQualityType === QualityType.REMOTE_NETWORK_OFF) {
            return QualityType.NETWORK_NONE
          }
          break;
        case QualityType.LOCAL_NETWORK_DETECTING:
          if (this.isCurrentLocalWeak() || this.lastQualityType === QualityType.LOCAL_NETWORK_OFF) {
            return QualityType.NETWORK_NONE
          }
          break;
        case QualityType.REMOTE_NETWORK_DETECTING:
          if (this.isCurrentRemoteWeak() || this.lastQualityType === QualityType.REMOTE_NETWORK_OFF) {
            return QualityType.NETWORK_NONE
          }
          break;
        case QualityType.LOCAL_NETWORK_UP_UNKNOWN:
          // UNKNOWN 取消断网
          if (this.lastQualityType === QualityType.LOCAL_NETWORK_OFF) {
            return QualityType.NETWORK_NONE
          }
          // 上行 UNKNOWN 取消 上行弱网
          if (this.lastQualityType === QualityType.LOCAL_NETWORK_UP_WEAK) {
            return QualityType.NETWORK_NONE
          }
          break;
        case QualityType.LOCAL_NETWORK_DOWN_UNKNOWN:
          // UNKNOWN 取消断网
          if (this.lastQualityType === QualityType.LOCAL_NETWORK_OFF) {
            return QualityType.NETWORK_NONE
          }
          // 下行 UNKNOWN 取消 下行弱网
          if (this.lastQualityType === QualityType.LOCAL_NETWORK_DOWN_WEAK) {
            return QualityType.NETWORK_NONE
          }
          break;
        case QualityType.REMOTE_NETWORK_UP_UNKNOWN:
        case QualityType.REMOTE_NETWORK_DOWN_UNKNOWN:
          // 因为目前SDK没有远端质量上报，这里不做上述 类似本地UNKNOWN 的处理逻辑
          if (this.lastQualityType === QualityType.REMOTE_NETWORK_OFF) {
            return QualityType.NETWORK_NONE
          }
          break;
      }
    }

    return QualityType.NETWORK_MAX
  }

  @action
  private setQualityType(type: QualityType) {
    if (this.lastQualityType !== type) {
      log(`output network quality: ${QualityType[type]}`, LOG_TYPE.INFO, LOG_MODULE.QUALITY)

      this.lastQualityType = type
    }
  }

  private isLocalWeak(type: QualityType) {
    return type === QualityType.LOCAL_NETWORK_UP_WEAK || type === QualityType.LOCAL_NETWORK_DOWN_WEAK
  }

  private isRemoteWeak(type: QualityType) {
    return type === QualityType.REMOTE_NETWORK_UP_WEAK || type === QualityType.REMOTE_NETWORK_DOWN_WEAK
  }

  private isCurrentLocalWeak() {
    return this.isLocalWeak(this.lastQualityType)
  }

  private isCurrentRemoteWeak() {
    return this.isRemoteWeak(this.lastQualityType)
  }

  public isCurrentWeak() {
    return this.isCurrentLocalWeak() || this.isCurrentRemoteWeak()
  }

  public isWeak(type: QualityType) {
    return this.isLocalWeak(type) || this.isRemoteWeak(type)
  }

  private isCurrentRemoteQuality() {
    return this.lastQualityType === QualityType.REMOTE_NETWORK_DOWN_WEAK || this.lastQualityType === QualityType.REMOTE_NETWORK_UP_WEAK
      || this.lastQualityType === QualityType.REMOTE_NETWORK_OFF
  }
}

// startTest()