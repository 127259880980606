import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { color } from '../../style/color';
export const useStyles = makeStyles((theme: Theme) => {
  const { customColor: { componentsBackGround, tabBackground, boxShadow, bubbleColor, otherBubbleColor, borderColor }, palette } = theme;
  const backgroundColor = componentsBackGround;
  return createStyles({
    drawer: {
      width: '15.5rem',
      flexShrink: 0,
    },
    drawerPaper: {
      border: 'none',
      left: 'auto',
      top: '1.25rem',
      backgroundColor,
      width: '15.5rem',
      boxSizing: 'border-box',
      borderRadius: '0.75rem',
      height: `calc(100vh - 2.5rem)`,
      marginRight: '1.25rem',
      zIndex: 100
    },
    halfScreen: {
      border: 'none',
      left: 'auto',
      marginTop: '1rem',
      backgroundColor,
      width: '15.5rem',
      boxSizing: 'border-box',
      borderRadius: '0.75rem',
      bottom: '1.25rem',
      height: `calc(50vh - 2rem)`,
      marginRight: '1.25rem',
    },
    container: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
    close: {
      width: '1.5rem',
      height: '1.5rem',
      borderRadius: '0.23rem',
      backgroundColor: theme.customColor.operationButtonBackground
    },
    iconClose: {
      color: theme.customColor.operationButtonFill,
      fill:theme.customColor.operationButtonFill,
    },
    header: {
      display: 'flex',
      padding: '1.13rem 1rem',
      borderBottom: `1px solid ${borderColor}`,
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    content: {
      padding: '0.5rem 1rem 1rem 1rem',
      height: 'calc(100% -  8.25rem)',
      overflowY: 'scroll',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    messageContent:{
      overflow: 'hidden'
    },
    messageLineMe: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: '0.1rem',
    },
    messageLineOther: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '0.1rem',
    },
    messageMe: {
      backgroundColor: bubbleColor,
      borderRadius: '0.75rem 0.75rem 0rem 0.75rem',
      fontSize: '0.75rem',
      maxWidth: '10rem',
      padding: '0.5rem 0.6rem',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      boxShadow: `0 1px 5px 0 ${theme.customColor.BubbleShadow}`,
    },
    messageOther: {
      backgroundColor: otherBubbleColor,
      borderRadius: '0.75rem 0.75rem 0.75rem 0rem',
      fontSize: '0.75rem',
      maxWidth: '10rem',
      padding: '0.5rem 0.6rem',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      boxShadow: boxShadow
    },
    linkMe: {
      color: theme.palette.primary.main,
    },
    linkOther: {
      color: theme.palette.primary.main,
    },
    timeMe: {
      textAlign: 'right',
      color: color.colorBlack,
      fontSize:'0.75rem',
      marginBottom: '0.5rem',

      transformOrigin: '100% 23%',
      float: 'right',
      transform: 'scale(.83)',
    },
    timeOther: {
      textAlign: 'left',
      color: color.colorBlack,
      fontSize:'0.75rem',
      marginBottom: '0.5rem',
      transform: 'scale(.83)',
      transformOrigin: '0 0',
    },
    chatBox: {
      width: '100%',
      position: 'absolute',
      boxSizing: 'border-box',
      padding: '1.38rem 1rem 1rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      bottom: '0rem',
      '& .MuiFormControl-root': {
        width: '100%'
      }
    },
    inputMessage: {},

    chatInput: {
      '& .MuiOutlinedInput-root': {
        backgroundColor: tabBackground,
        borderRadius: '0.56rem',
        padding: '0.5rem 2.44rem 0.5rem 0.94rem',
        fontSize: '0.75rem',
        lineHeight: '1.19rem',
        minHeight: '2.5rem',
        boxSizing: 'border-box',
      },
      '& .MuiOutlinedInput-input': {
        fontWeight: 400,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffffff00',
        boxShadow: boxShadow
      },
      '& .MuiFormControl-root': {
        width: '100%'
      }

    },
    send: {
      position: 'absolute',
      right: '1.38rem',
      fill: palette.primary.main,
      cursor: 'pointer',
      '& :hover': {
        fill: palette.primary.light,
        '& path': {
          fill: '#fff'
        }
      },
    },
    loading: {
      marginRight: '0.2rem',
      animation: '$rotating 2s linear infinite',
    },
    '@keyframes rotating': {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '100%': {
        transform: 'rotate(360deg)'
      }
    },
    inputRoot: {
      width: '100%',
    },
    input: {
      fontSize: '0.7rem',
    },
    name: {
      fontSize: '0.75rem',
      fontWeight: 600,
    },
    resend: {
      color: color.colorPrimaryLight,
      fontSize: '0.6rem',
      paddingLeft: '0.5rem',
      cursor: 'pointer',
    },
    failIcon: {
      cursor: 'pointer',
      marginRight: '0.2rem',
    },
    timeMsg: {
      color:theme.customColor.timerColor,
      fontSize:'0.75rem',
    },

    buttonGroups: {
      display: 'flex',
      flexDirection: 'row-reverse',
      marginTop:'1rem',
    },
    confirmButton: {
      height: '1.875rem'
    },
  })
})
