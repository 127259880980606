import { Snackbar, Button } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { Provider } from "mobx-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import AlertDialog from "./components/Alert/AlertDialog";
import NotificationContainer from "./components/NotificationList";
import HomePage from "./pages/HomePage/index";
import MeetingPage from "./pages/MeetingPage";
import { RootStore } from "./stores/rootStore";
import { APP_SUB_PATH, ROOM_MODE } from "./utils/constants";
import { versionMessage } from "./utils/helper";
import { log, LOG_MODULE, LOG_TYPE, openDb } from "./utils/Log";
import userAgentInfo, { isChrome, exceedSafari, isSafari, } from "./utils/userAgentDetector";
import { themingAgora, themingDefault } from "./theming"


const rootStore = new RootStore();

openDb();

(window as any).store = rootStore

const userInfo = () => {
  const active = navigator.onLine ? 'online' : 'offline'
  const user = {
    os: `${userAgentInfo.os.name} ${userAgentInfo.os.version}`,
    net: `${active}`,
  }
  return user
}

const appInitInfo = () => {
  const versions = `Web${versionMessage.app} RTC${versionMessage.rtc} RTM${versionMessage.rtm}`
  const infos = `${userInfo().os} ${userInfo().net}`
  const t = `${versions} ${infos}`
  return t
}

log(`App Init, ${appInitInfo()} ua: ${userAgentInfo.ua}`, LOG_TYPE.COMM, LOG_MODULE.UI)

const App = () => {
  const [t] = useTranslation()
  const [open, setOpen] = React.useState(!isChrome())
  const [isAgoraMode, setIsAgoraMode] = React.useState(false)
  const [isDarkMode, setIsDarkMode] = React.useState(false)

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload)
    return () => {
      window.removeEventListener('beforeunload', beforeUnload)
    }
  })

  useEffect(() => {
    let homeMode = Number(localStorage.getItem("homeMode"))
    homeMode === ROOM_MODE.MODE_AGORA ? setIsAgoraMode(true) : setIsAgoraMode(false)

    // follow up system dark or light mode, safari version support >= 14
    if (exceedSafari(14) || !isSafari()) {
      window.matchMedia('(prefers-color-scheme: dark)').matches ? setIsDarkMode(true) : setIsDarkMode(false)
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', changeDarkTheme)
      return () => {
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', changeDarkTheme)
      }
    }
  }, [])

  const changeDarkTheme = (event: any) => {
    event.matches ? setIsDarkMode(true) : setIsDarkMode(false)
  }

  const beforeUnload = () => {
    return rootStore.commManger.clearUserState()
  }
  const onChangeMode = (isAgora: boolean) => {
    setIsAgoraMode(isAgora)
  }
  // todo from path="/room" to path="/meeting" needs remove path

  return (
    <ThemeProvider theme={isAgoraMode ? themingAgora(isDarkMode) : themingDefault(isDarkMode)}>
      <Provider store={rootStore}>
        <CssBaseline />
        <NotificationContainer />
        <AlertDialog />
        <Snackbar open={open}>
          <Alert severity="warning" onClose={() => { setOpen(false) }}>{t('RecommendChrome')}</Alert>
        </Snackbar>
        <Router>
          <Switch>
            <Route path={`${APP_SUB_PATH}/meeting`} render={() => <MeetingPage onChangeMode={onChangeMode} />} />
            <Route path={`${APP_SUB_PATH}/`} render={() => <HomePage onChangeMode={onChangeMode} />} />
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  )
}

export default App;
