import { Box, makeStyles, Theme, Tooltip, IconButton, useTheme, Popover } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as SignalBad } from "../../assets/icon_bad.svg";
import { ReactComponent as SignalDown } from "../../assets/icon_down.svg";
import { ReactComponent as SignalGood } from "../../assets/icon_good.svg";
import { ReactComponent as SignalPoor } from "../../assets/icon_poor.svg";
import { ReactComponent as SignalUnknown } from "../../assets/icon_unknown.svg";
import { ReactComponent as IconTriangle } from "../../assets/icon_triangle.svg";
import { ReactComponent as IconMessageClose } from "../../assets/icon_disconnected.svg";
import { useRtcCommLayerStore, useRtmEngineLayerStore, useCommManagerStore, } from "../../stores/hooks";
import { QualityState } from "../../utils/constants";
import { useStyles } from "./styles"

const SignalView = observer(() => {
  const [t] = useTranslation()
  const styles = useStyles()
  const [isShowSignalPop, setShowSignalPop] = useState(false)
  const rtcComm = useRtcCommLayerStore()
  const rtmEngineLayer = useRtmEngineLayerStore()
  const theme = useTheme()
  const anchorEl = useRef<HTMLDivElement>(null)
  const commManager = useCommManagerStore()
  // const controlOpen = Boolean(anchorEl);

  const getSignalClass = () => {
    switch (rtcComm.mineNetworkQuality) {
      case QualityState.NETWORK_GOOD:
        return <SignalGood />
      case QualityState.NETWORK_SOFT:
        return <SignalPoor />
      case QualityState.NETWORK_POOR:
        return <SignalBad />
      case QualityState.NETWORK_DOWN:
        return <SignalDown />
      default:
        return <SignalUnknown />
    }
  }

  const getSignalDesc = () => {
    switch (rtcComm.mineNetworkQuality) {
      case QualityState.NETWORK_GOOD:
        return <span style={{ color: theme.palette.success.main }}>{t('signalGoodIcon')}</span>
      case QualityState.NETWORK_SOFT:
        return <span style={{ color: theme.palette.warning.main }}>{t('signalPoorIcon')}</span>
      case QualityState.NETWORK_POOR:
        return <span style={{ color: theme.palette.error.main }}>{t('signalBadIcon')}</span>
      case QualityState.NETWORK_DOWN:
        return <span style={{ color: theme.customColor.timerColor }}>{t('signalDownIcon')}</span>
      default:
        return <span style={{ color: theme.customColor.timerColor }}>{t('signalUnknownIcon')}</span>
    }
  }
  const NetWorkStatus = () => {
    return <div onClick={() => setShowSignalPop(true)}
      className={styles.button}
    // ref={anchorEl}
    >
      {
        getSignalClass()
      }
    </div>
  }
  return (
    <>
      <div onClick={() => setShowSignalPop(true)}
        className={styles.button} ref={anchorEl}>
        {commManager.showBizDisconnect ?
          <IconButton id="rtm-disconnect-signal" className={styles.iconButton}><IconMessageClose /></IconButton>
          :
          <IconButton className={styles.iconButton}><NetWorkStatus /></IconButton>
        }
      </div>
      <Popover
        open={isShowSignalPop}
        // marginThreshold={marginThreshold}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: styles.paper
        }}
        anchorEl={anchorEl?.current}
        onClose={() => setShowSignalPop(false)}
      >
        <Box className={styles.signalView}>
          <IconTriangle className={styles.triangle} />
          {commManager.showBizDisconnect ?
              <div className={styles.serverStatus}>{t('MessageDisconnected')}</div>
            :
            <>
              <div className={styles.serverStatus}>{t("MessageSeverConnected")}</div>
              <div className={styles.signalStatus}>{getSignalDesc()} </div>
            </>
          }
        </Box>
      </Popover>
    </>
  )
})

export default SignalView