import React, { forwardRef, Ref } from 'react';
import { Box, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next';
import useStyle from "./style";
import { VersionContents } from "../../utils/helper";
import GDPRDialog from "../GDPR/GDPRTermsView";

const AboutUsView = observer(forwardRef((props, ref: Ref<HTMLDivElement>) => {
  const styles = useStyle()
  const [t] = useTranslation()
  const [termsOpen, setTermsOpen] = React.useState(false)
  const [versionIndex, setVersionIndex] = React.useState(0)

  const switchVersion = () => {
    let count = versionIndex === VersionContents.length - 1 ? 0 : versionIndex + 1
    setVersionIndex(count)
  }

  return (
    <div className={styles.aboutUsBox} id="about_us" ref={ref}>
      <Box className={styles.aboutUsText}>
        <Typography variant="h3">{t('About us')}</Typography>
        <Typography variant="subtitle1" onClick={switchVersion}>{`${t('Version')} ${VersionContents[versionIndex]}`}</Typography>
        <Typography variant="subtitle1" className={styles.agoraLaw} onClick={() => setTermsOpen(true)}>{t('Agora Terms')}</Typography>
      </Box>
      <GDPRDialog open={termsOpen} onOk={() => setTermsOpen(false)} />
    </div>
  )
}))

export default AboutUsView
