import { observer } from "mobx-react";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useCommManagerStore, useUserManagerStore, useUIStore, useRtcEngineStore } from "../../stores/hooks";
import { ReactComponent as IconShare } from "../../assets/icon_stream_screen.svg";
import { useStyles } from "./styles";
import VoiceVolumeIcon from "../UserList/VoiceVolumn";
import { useTheme, } from "@material-ui/core";
import { UserNameTooltip } from '../UserList/UserNameTooltip'
import UserOperation from "../UserList/UserOperation";

const SpokesmanName = observer(() => {
  const [t] = useTranslation()
  const commManager = useCommManagerStore()
  const userManager = useUserManagerStore()
  const rtcStore = useRtcEngineStore()
  const styles = useStyles()
  const theme = useTheme()
  const uiStore = useUIStore()
  const [isShowMore, setShowMore] = useState(false)

  const handleMenuClose = () => {
    setShowMore(false)
  };

  if (!userManager.majorUser) return null
  return (
    <div className={styles.viewMouseEventBox}
      onMouseEnter={() => setShowMore(true)}
      onMouseOver={() => setShowMore(true)}
      onMouseLeave={handleMenuClose}>
      <div className={styles.spokesman}>
        {userManager.majorUser.parentStreamId ? <IconShare fill={theme.palette.primary.main} /> :
         <VoiceVolumeIcon isBigStream={true} volumeLevel={Math.ceil(rtcStore.volumeLevel * 100)} audioState={userManager.majorUser.audioState} isSpeaking={!userManager.majorUser.isMe && userManager.majorUser.isSpeaking} isMe={userManager.majorUser.isMe} />
        }
        <UserNameTooltip user={userManager.majorUser} >
          <div className={`${!uiStore.isRoomModeNormal ? styles.spokesmanNameHover : styles.spokesmanName} notranslate`}
            id="big-stream-spokesman-name" >
            {userManager.majorUser.showName || t('Unknown')}
          </div>
        </UserNameTooltip>
        <div className={styles.iconMore}>
          {
            isShowMore && (!commManager.roomInfo.hasHost || commManager.roomInfo.isMeHost) && !userManager.majorUser.isMe && !userManager.majorUser.parentStreamId && !userManager.majorUser.isAssistant &&
            <UserOperation
              isShowMore={true}
              isNeedStyle={false}
              handleMenuClose={handleMenuClose} user={userManager.majorUser} />}
        </div>
      </div>
    </div>
  )

})

export default SpokesmanName