import { action } from "mobx";
import { RoomInfo } from "./roomInfo";

export enum REQUEST_TYPE {
  TYPE_UNKNOWN = 0,
  DISABLE_AUDIO = 1,
  ENABLE_AUDIO = 2,
  DISABLE_VIDEO = 3,
  ENABLE_VIDEO = 4,
  KICK_USER = 5,
  TYPE_MAX = 6,
}

export class User {
  name: string = ''
  parentStreamId: number = 0
}

export class Biz {
  hostUid?: string
  roomAudio?: boolean
  roomVideo?: boolean
  rtmServerPeerId?: string
  timestamp?: number

  public hostName: string = ''

  public setMediaRoomInfo(roomInfo: RoomInfo) {
    this.hostUid = roomInfo.hostUid
    this.roomAudio = roomInfo.audioState
    this.roomVideo = roomInfo.videoState
    this.rtmServerPeerId = roomInfo.serverPeerId
    this.timestamp = roomInfo.timestamp
  }
}

export class Control {
  targetStreamId?: number
  requestType?: number
  sequenceId?: number

  public setMediaControl(data: any) {
    Object.keys(data).forEach(key => {
      (this as any)[key] = data[key]
    })
  }

  public requestMedia(isAudio: boolean, enable: boolean, target: number, seq: number) {
    this.requestType = isAudio ? (enable ? REQUEST_TYPE.ENABLE_AUDIO : REQUEST_TYPE.DISABLE_AUDIO) : (enable ? REQUEST_TYPE.ENABLE_VIDEO : REQUEST_TYPE.DISABLE_VIDEO)
    this.targetStreamId = target
    this.sequenceId = seq
  }

  public kickUser(target: number, seq: number) {
    this.requestType = REQUEST_TYPE.KICK_USER;
    this.targetStreamId = target
    this.sequenceId = seq
  }
}

export class Metadata {
  user?: User
  biz?: Biz
  ctrl?: Control

  public setAllocatedUser(user: User) {
    this.user = user
  }

  public setAllocatedBiz(biz: Biz) {
    this.biz = biz
    console.log(this)
  }

  public setAllocatedCtrl(ctrl: Control) {
    this.ctrl = ctrl
  }

  public clearBiz() {
    this.biz = undefined
  }

  @action
  public clearCtrl() {
    this.ctrl = undefined
  }
}
