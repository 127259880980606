import { action, computed, observable, runInAction } from "mobx";
import { BizRoomInfo, Recorder } from "../types/bizRoomInfo";
import { BizUser } from "../types/bizUser";
import { CommUser } from "../types/commUser";
import { MediaUser } from "../types/mediaUser";
import { Biz, Control, REQUEST_TYPE } from "../types/metadata";
import { RoomInfo } from "../types/roomInfo";
import ThirdPartyUser from "../types/thirdPartyUser";
import { AssistantInfo } from "../types/assistantInfo";
import { MyRequestResponse, SelfApplyAssistantResponse, UserApplyAssistantRequest } from "../types/userRequest";
import {
  alertErrorMap,
  ATLAS_RECORDING_URL,
  BIZ_ROOM_STATE,
  CloudRecordingError,
  ERR_JOIN_WRONG_PWD,
  RoomState, ROOM_AGORA_JOIN_NO_PERMISSION,
  ROOM_MODE,
  APPLY_ASSISTANT_LANG,
  UserChangedReason,
  ASSISTANT_REASON,
  ASSISTANT_STATE,
  APPLY_ASSISTANT_RESULT,
  SUBSCRIBE_ASSISTANT_STATUS,
  VOLUME,
  ENCRYPTION_MODE
} from "../utils/constants";
import { execCopy, isChinese } from "../utils/helper";
import { log, LOG_MODULE, LOG_TYPE } from "../utils/Log";
import { getAccessToken, loginByWeChatWorkForTest, logoutByWeChatWork } from "./connector/http";
import { NetworkEval, QualityType, setShowQualityLog, showQualityLog } from "./model/NetworkEval";
import { RootStore } from "./rootStore";
import { base64ToUint8Array, getRtcEncryptionKey } from "../utils/tools";

// tslint:disable-next-line: no-var-requires
const controlMute = require('../assets/ctr_mute.mp3')
// tslint:disable-next-line: no-var-requires
const beMutedEn = require('../assets/be_muted_en.mp3')
// tslint:disable-next-line: no-var-requires
const beMutedCn = require('../assets/be_muted_cn.mp3')
// tslint:disable-next-line: no-var-requires
const controlRequestUnmute = require('../assets/ctr_request_unmute.mp3')
// tslint:disable-next-line: no-var-requires
const beRemovedEn = require('../assets/be_removed_en.mp3')
// tslint:disable-next-line: no-var-requires
const beRemovedCn = require('../assets/be_removed_cn_2.mp3')
// tslint:disable-next-line: no-var-requires
const cloudRecordingSound = require('../assets/cloud_recording.mp3')
// tslint:disable-next-line: no-var-requires
const dumpSound = require('../assets/dump.mp3')

export enum PendingType {
  PENDING_AUDIO = 0,
  PENDING_VIDEO = 1,
}

interface IRoomInfo {
  rid: string;
  pwd: string;
  rtcEncryption: boolean;
  selfUid: string;
  selfStreamId: number;
  selfName: string;
  selfAudio: boolean;
  selfVideo: boolean;
  roomMode: ROOM_MODE;
  thirdparty?: ThirdPartyUser;
  channelName?: string,
  roomToken?:string
}

interface IMediaRoomJoinInfo {
  encryption: boolean,
  rtcSecretKey: string,
  channelName: string,
  rtcToken: string,
  encryptionMode: ENCRYPTION_MODE,
  salt: string,
  roomToken:string,
}
class PendingOperation {
  constructor(type: PendingType, seq: number, target: number, timerId: number, isOpenOperations: boolean) {
    this.pendingType = type
    this.opSeq = seq
    this.target = target
    this.timerId = timerId
    this.isOpenOperations = isOpenOperations
  }

  public pendingType = PendingType.PENDING_AUDIO
  public opSeq = 0
  public target = 0
  public requestIds: string[] = []
  public timerId = 0
  public isOpenOperations: boolean
}

class RemoteRequest {
  public sources: Map<number, CommUser> = new Map()
  public requestIds: Map<number, string> = new Map()
  public timerId = 0
}

export class CommManagerStore {

  private rootStore: RootStore

  @observable
  public roomMode: ROOM_MODE = ROOM_MODE.MODE_NORMAL
  @observable
  public roomState: RoomState = 0
  @observable
  public roomInfo: RoomInfo = new RoomInfo(ROOM_MODE.MODE_NORMAL)
  @observable
  public assistantInfo: AssistantInfo = new AssistantInfo()
  @observable
  public roomElapsed: number = 0
  private roomAlreadyElapsed: number = 0
  private elapseStart: number = 0
  private roomTimer: number = 0

  @observable
  private assistantUser: BizUser = new BizUser()

  @observable
  public bizRoomState: BIZ_ROOM_STATE = BIZ_ROOM_STATE.BIZ_ROOM_DISCONNECTED

  @observable
  public needReturnHomePage: boolean = false

  @observable
  public isOpenWatermark: boolean = false

  @observable
  public isOpenQualityFeedback: boolean = false

  @observable
  public bizConnectedErrorMessage: string = ''

  @observable
  public isRtcConnected: boolean = false

  @observable
  public subscribeAssistantStatus: SUBSCRIBE_ASSISTANT_STATUS = SUBSCRIBE_ASSISTANT_STATUS.DEFAULT

  @observable
  public assistantStatus: ASSISTANT_STATE = ASSISTANT_STATE.ASSIST_NONE

  @observable
  public applyAssistantResult: APPLY_ASSISTANT_RESULT = APPLY_ASSISTANT_RESULT.DEFAULT;
  @observable
  public closeUserAssistantNotification: boolean = false;
  @observable
  public isShowAssistantAudioVolume: boolean = false
  @observable
  public hasPreviewVideo: boolean = false
  private seqId: number = 0
  private joinId: number = 0
  public selfAudio: boolean = false
  public selfVideo: boolean = false
  private joinRtcTimer?: number
  private applyAssistantLang: APPLY_ASSISTANT_LANG = APPLY_ASSISTANT_LANG.EN

  @computed
  public get isOutsider() {
    return this.roomMode === ROOM_MODE.MODE_AGORA && !this.rootStore.user.isThirdPartyLoggedIn
  }

  @computed
  public get isRoomAgora() {
    return this.roomMode === ROOM_MODE.MODE_AGORA
  }

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @computed
  public get bizConnected() {
    return this.bizRoomState === BIZ_ROOM_STATE.BIZ_ROOM_CONNECTED
  }

  @computed
  public get showBizDisconnect() {
    return this.bizRoomState !== BIZ_ROOM_STATE.BIZ_ROOM_CONNECTED && this.bizRoomState !== BIZ_ROOM_STATE.BIZ_ROOM_CONNECTING
  }

  public get roomReady() {
    if (this.roomMode === ROOM_MODE.MODE_NORMAL) {
      return true
    }
    return this.isRtcConnected
  }

  public async joinRoom(roomInfo: IRoomInfo) {

    const {
      rid,
      selfStreamId,
      pwd,
      rtcEncryption,
      selfName,
      selfUid,
      roomMode,
      thirdparty,
      selfVideo,
      channelName = '',
      selfAudio,
      roomToken = ''
    } = roomInfo

    this.updateNeedReturnHomePage(false)

    this.clearRoomStats()

    this.initRoomMode(roomMode)
    const defaultChannelName = channelName || this.GetRtcChannelName(rid, pwd)

    this.initRoomInfo(rid, pwd, rtcEncryption, selfUid, selfStreamId, roomMode, defaultChannelName, roomToken)

    this.seqId = parseInt(Date.now() / 1000 + '')

    this.selfAudio = selfAudio

    this.selfVideo = selfVideo

    if (rtcEncryption) {
      this.roomInfo.encryptionMode = ENCRYPTION_MODE.AES_128_GCM
    }

    this.setRoomState(RoomState.ROOM_STATE_CONNECTING) // must before AddUser to start preview

    log(`user join room, room name: ${this.roomInfo.roomId}, pwd: ${this.roomInfo.roomPwd}, encryption: ${rtcEncryption},
     expect audio: ${selfAudio}, expect video: ${selfVideo}`, LOG_TYPE.COMM, LOG_MODULE.UI)

    this.rootStore.userManager.networkEval = this.networkEval// !!! must before initState
    this.rootStore.userManager.initState(roomMode, selfStreamId, selfUid, selfName, selfAudio, selfVideo, thirdparty)
    this.rootStore.rtmCommLayer.connectRoom({
      roomMode,
      roomId: this.roomInfo.roomId,
      password: this.roomInfo.roomPwd,
      rtcEncryption,
      channelName,
      selfUid,
      audioState: selfAudio,
      videoState: selfVideo,
      accessToken: getAccessToken(),
      roomToken
    })
    this.rootStore.rtcCommLayer.previewMedia(selfStreamId, selfAudio, selfVideo, this.getUserMe()!.userFullName)
    if (roomMode === ROOM_MODE.MODE_AGORA) {
      this.hasPreviewVideo =  selfVideo
      // this.rootStore.rtmCommLayer.connectRoom(roomMode, this.roomInfo.roomId, this.roomInfo.roomPwd, rtcEncryption, selfUid, selfAudio, selfVideo, getAccessToken())
      this.joinRtcTimer = window.setTimeout(() => {
        if (!this.isRtcConnected) {
          this.returnToHome(true, this.bizConnectedErrorMessage || 'DisconnectedRTMCheckNetwork')
        }
      }, 10 * 1000)
    } else {
      this.joinRTCChannel()
    }
  }

  public joinRTCChannel() {
    this.rootStore.rtcCommLayer.joinChannel(
      ++this.joinId,
      this.roomInfo.channelName,
      this.roomInfo.rtcEncryption,
      this.roomInfo.rtcSecretKey,
      this.roomInfo.selfStreamId,
      this.selfAudio, this.selfVideo,
      this.getUserMe()!.userFullName,
      this.roomInfo.rtcToken,
      this.roomInfo.encryptionMode,
      this.roomInfo.salt
    )
  }

  @action
  private initRoomMode(mode: ROOM_MODE) {
    this.roomMode = mode
  }

  @action
  private initRoomInfo(rid: string, pwd: string, rtcEncryption: boolean, selfUid: string, selfStreamId: number, roomMode: ROOM_MODE, channelName: string,roomToken:string) {
    this.roomInfo = new RoomInfo(roomMode) // reset to init state
    this.roomInfo.roomId = rid
    this.roomInfo.roomPwd = pwd
    this.roomInfo.rtcEncryption = rtcEncryption
    this.roomInfo.selfUid = selfUid
    this.roomInfo.selfStreamId = selfStreamId
    this.roomInfo.channelName = channelName
    this.elapseStart = 0
    this.roomElapsed = 0
    // TODO when rtcEncryption is true set rtcSecretKey
    // if (rtcEncryption && roomMode === ROOM_MODE.MODE_NORMAL) {
    this.roomInfo.rtcSecretKey = getRtcEncryptionKey(channelName)
    // }
    this.roomInfo.roomToken = roomToken
    this.assistantInfo = new AssistantInfo()
    this.assistantInfo.selfUid = selfUid
  }

  public leaveRoom() {
    this.updateNeedReturnHomePage(false)

    if (this.roomState === RoomState.ROOM_STATE_DISCONNECT) {
      return
    }

    log(`leaveRoom room: ${this.roomInfo.roomId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    this.doLeaveRoom()
  }

  @computed
  public get subscribeAssistant() {
    return this.subscribeAssistantStatus === SUBSCRIBE_ASSISTANT_STATUS.SUBSCRIBE
  }

  @computed
  public get isUnsubscribeAssistant() {
    return this.subscribeAssistantStatus === SUBSCRIBE_ASSISTANT_STATUS.UNSUBSCRIBE
  }

  private doLeaveRoom() {
    this.setRoomState(RoomState.ROOM_STATE_DISCONNECT)

    this.rootStore.rtcCommLayer.leaveChannel()

    this.rootStore.rtmCommLayer.disconnectRoom()

    this.clearRoomStats()
    this.resetAssist()

    this.assistantInfo.resetAssistantInfo()
  }

  private clearRoomStats() {
    this.rootStore.userManager.clearState()

    this.clearRoomTimer()

    this.resetAssist()

    this.clearAllPendingOperations()

    this.clearAllRemoteRequests()

    this.networkEval.resetNetworkEval()

    this.rootStore.shareManager.checkAndStopScreenShare()

    this.clearRecordInfo()

    this.clearDumpStatus()

    this.rootStore.user.resetDrawerPanelStatus()
  }

  /********************************************* Public APIs for UI *************************************/

  public setLocalAudio(on: boolean) {
    if (on && this.remoteAudioRequests) {
      this.remoteAudioRequests = ""
    }
    this.selfAudio = on;
    this.rootStore.rtcCommLayer.enableLocalAudio(on)

    this.rootStore.rtmCommLayer.setLocalAudio(on)

  }

  public setLocalVideo(on: boolean) {
    if (on && this.remoteVideoRequests) {
      this.remoteVideoRequests = ""
    }
    this.selfVideo = on;
    this.rootStore.rtmCommLayer.setLocalVideo(on)
    this.rootStore.rtcCommLayer.enableLocalVideo(on)
  }

  public setMajor(target: number) {
    this.rootStore.userManager.setMajor(target)
  }

  public async enableRemoteAudio(streamId: number, enable: boolean) {
    const user = this.rootStore.userManager.getUser(streamId)
    if (!user) {
      return
    }

    log(`enableRemoteAudio enable: ${enable} ${user.description()}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    const opSeq = this.seqId++
    const pendingType = PendingType.PENDING_AUDIO

    this.rootStore.userManager.setUserOperationPending(streamId, pendingType, true)

    this.addPendingOperation(pendingType, opSeq, streamId, enable ? this.rootStore.user.REQUEST_TIMEOUT : this.rootStore.user.CONTROL_TIMEOUT, enable)

    if (enable) {
      const sent = this.rootStore.rtcCommLayer.enableRemoteAudio(user.streamId, opSeq)

      try {
        const requestId = await this.rootStore.rtmCommLayer.enableRemoteAudio(user.uid, opSeq)

        this.addPendingOperationRequestId(opSeq, requestId)
      } catch (e) {
        if (!sent) {
          this.alertError(e.code, e.desc)
        }
      }
    } else {
      const sent = this.rootStore.rtcCommLayer.disableRemoteAudio(user.streamId, opSeq)

      this.rootStore.rtmCommLayer.disableRemoteAudio(user.uid, opSeq).catch((e: any) => {
        if (!sent) {
          this.alertError(e.code, e.desc)
        }
      })
    }
  }

  public async enableRemoteVideo(streamId: number, enable: boolean) {
    const user = this.rootStore.userManager.getUser(streamId)
    if (!user) {
      return
    }

    log(`enableRemoteVideo enable: ${enable} ${user.description()}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    const opSeq = this.seqId++
    const pendingType = PendingType.PENDING_VIDEO

    this.rootStore.userManager.setUserOperationPending(streamId, pendingType, true)

    this.addPendingOperation(pendingType, opSeq, streamId, enable ? this.rootStore.user.REQUEST_TIMEOUT : this.rootStore.user.CONTROL_TIMEOUT, enable)

    if (enable) {
      const sent = this.rootStore.rtcCommLayer.enableRemoteVideo(user.streamId, opSeq)

      try {
        const requestId = await this.rootStore.rtmCommLayer.enableRemoteVideo(user.uid, opSeq)

        this.addPendingOperationRequestId(opSeq, requestId)
      } catch (e) {
        if (!sent) {
          this.alertError(e.code, e.desc)
        }
      }
    } else {
      const sent = this.rootStore.rtcCommLayer.disableRemoteVideo(user.streamId, opSeq)

      this.rootStore.rtmCommLayer.disableRemoteVideo(user.uid, opSeq).catch((e: any) => {
        if (!sent) {
          this.alertError(e.code, e.desc)
        }
      })
    }
  }

  public async kickUser(streamId: number) {
    const target = this.rootStore.userManager.getUser(streamId)

    if (!target) {
      log(`kickUser, find no target by stream id: ${streamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }

    if (target.isHost) {
      log(`kickUser, can not kick host: ${streamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }

    const sent = this.rootStore.rtcCommLayer.kickUser(streamId, this.seqId++)

    this.rootStore.rtmCommLayer.kickUser(target.uid).catch((e: any) => {
      if (!sent) {
        this.alertError(e.code, e.desc)
      }
    })
  }

  public enableRoomHost(request: boolean) {
    if (!request && !this.assistantInfo.hasAssistant()) {
      this.setCloseAssistantNotification(true)
    }

    this.rootStore.rtmCommLayer.enableRoomHost(request).catch((e: any) => {
      this.alertError(e.code, e.desc)
    })
  }

  public enableRoomAudio(enable: boolean) {
    this.rootStore.rtmCommLayer.enableRoomAudio(enable).catch((e: any) => {
      this.alertError(e.code, e.desc)
    })
  }

  public enableRoomVideo(enable: boolean) {
    this.rootStore.rtmCommLayer.enableRoomVideo(enable).catch((e: any) => {
      this.alertError(e.code, e.desc)
    })
  }

  public async applyAssistant(lang: APPLY_ASSISTANT_LANG) {
    this.applyAssistantLang = lang
    this.setApplyAssistantResult(APPLY_ASSISTANT_RESULT.WAIT)
    this.setCloseAssistantNotification(true)
    this.rootStore.rtmCommLayer.asyncEventDispatcher(async () => {
      return await this.rootStore.rtmCommLayer.applyMeetingAssistant(true, this.applyAssistantLang).catch((e: any) => {
        this.setApplyAssistantResult(APPLY_ASSISTANT_RESULT.REFUSE)
        return
      })
    }, this.rootStore.user.REQUEST_TIMEOUT, "assistant", () => {
      this.setApplyAssistantResult(APPLY_ASSISTANT_RESULT.REFUSE)
      this.setCloseAssistantNotification(false)
      this.rootStore.notification.onApplyAssistantTimeout()
    })
  }

  public async cancelAssistant() {
    this.setCloseAssistantNotification(true)
    await this.rootStore.rtmCommLayer.applyMeetingAssistant(false, this.applyAssistantLang).catch((e: any) => {
      this.setApplyAssistantResult(APPLY_ASSISTANT_RESULT.DEFAULT)
      if (e.code !== 2042) {
        this.alertError(e.code, 'Reconnecting to the biz server')
      }
      return false
    })
    this.onAssistantCanceled(this.assistantInfo, "")
    this.setApplyAssistantResult(APPLY_ASSISTANT_RESULT.DEFAULT)
    return true
  }

  public disableAssist() {
    if (this.subscribeAssistant) {
      this.stopAssist()
    }
    log(`disableAssist assistant streamId : ${this.assistantInfo.streamId}`)
    this.unsubscribeUser(this.assistantInfo.streamId)
    this.setSubscribeAssistantStatus(SUBSCRIBE_ASSISTANT_STATUS.UNSUBSCRIBE, ASSISTANT_STATE.ASSIST_DISABLED)
  }

  public enableAssist(isListenOriginSound: boolean = true) {
    log(`enableAssist isListenOriginSound ${isListenOriginSound}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
    switch (this.assistantStatus) {
      case ASSISTANT_STATE.ASSIST_NONE: break;
      case ASSISTANT_STATE.ASSIST_DISABLED: this.subscribeUser(this.assistantInfo.streamId); break;
      case ASSISTANT_STATE.ASSIST_ENABLED_WITHOUT_ORIGIN_SOUND: break;
      case ASSISTANT_STATE.ASSIST_ENABLED_WITH_ORIGIN_SOUND: break;
    }

    if (isListenOriginSound) {
      this.setSubscribeAssistantStatus(SUBSCRIBE_ASSISTANT_STATUS.SUBSCRIBE, ASSISTANT_STATE.ASSIST_ENABLED_WITH_ORIGIN_SOUND)
    } else {
      this.setSubscribeAssistantStatus(SUBSCRIBE_ASSISTANT_STATUS.SUBSCRIBE, ASSISTANT_STATE.ASSIST_ENABLED_WITHOUT_ORIGIN_SOUND)
    }
    this.startAssistIfReady()
    this.setCloseAssistantNotification(false)
  }

  private subscribeUser(streamId: number) {
    this.rootStore.userManager.subscribeUser(streamId)
    this.rootStore.rtcCommLayer.subscribeUser(streamId)
  }

  private unsubscribeUser(streamId: number) {
    this.rootStore.userManager.unsubscribeUser(streamId)
    this.rootStore.rtcCommLayer.unsubscribeUser(streamId)
  }

  private startAssistIfReady(target?: any) {
    if (this.assistantInfo.hasAssistant() && (this.subscribeAssistant)) {
      if (!target) {
        target = this.rootStore.userManager.getUser(this.assistantInfo.streamId);
      }
      if (!target) {
        log("StartAssistIfReady can not find assist", LOG_TYPE.ERROR, LOG_MODULE.COMM)
        return;
      }
      if (target.audioState && this.assistantStatus === ASSISTANT_STATE.ASSIST_ENABLED_WITH_ORIGIN_SOUND) {
        this.rootStore.rtcCommLayer.lowerRemotesVolumesWithExempt(true, this.assistantInfo.streamId)
      }
      if (this.assistantStatus === ASSISTANT_STATE.ASSIST_ENABLED_WITHOUT_ORIGIN_SOUND) {
        this.rootStore.rtcCommLayer.lowerRemotesVolumesWithExempt(false, this.assistantInfo.streamId)
      }
    }
  }

  private stopAssist() {
    this.rootStore.rtcCommLayer.restoreRemotesVolumes()
  }
  private resetAssist() {
    switch (this.assistantStatus) {
      case ASSISTANT_STATE.ASSIST_NONE: break;
      case ASSISTANT_STATE.ASSIST_DISABLED: this.subscribeUser(this.assistantInfo.streamId); break;
      case ASSISTANT_STATE.ASSIST_ENABLED_WITH_ORIGIN_SOUND:
      case ASSISTANT_STATE.ASSIST_ENABLED_WITHOUT_ORIGIN_SOUND: this.stopAssist(); break;
    }
    this.setSubscribeAssistantStatus(SUBSCRIBE_ASSISTANT_STATUS.DEFAULT, ASSISTANT_STATE.ASSIST_NONE)

  }
  @action
  public setCloseAssistantNotification(isClose: boolean) {
    this.closeUserAssistantNotification = isClose
    if (isClose) {
      setTimeout(() => {
        this.closeUserAssistantNotification = false
      }, 500)
    }
  }

  /********************************************* Media Callback *************************************/
  public onStreamJoinSuccess() {
    clearTimeout(this.joinRtcTimer)
    this.isRtcConnected = true;
    this.setRoomState(RoomState.ROOM_STATE_CONNECTED)
  }

  public onStreamJoinFailure(msg: string) {
    log(`onStreamJoinFailure ${msg}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)

    this.setRoomState(RoomState.ROOM_STATE_DISCONNECT)

    this.rootStore.rtmCommLayer.disconnectRoom()

    this.clearRoomStats()

    this.returnToHome(true, msg)
  }

  public onStreamDisconnected() {
    log(`onStreamDisconnected`, LOG_TYPE.ERROR, LOG_MODULE.COMM)

    this.setRoomState(RoomState.ROOM_STATE_DISCONNECT)

    this.rootStore.rtmCommLayer.disconnectRoom()

    this.clearRoomStats()

    this.returnToHome(true, "RoomDisconnected")
  }

  @action
  private setAssistantUser(user: BizUser) {
    this.assistantUser = user
  }

  @action
  private setRoomState(state: RoomState) {
    log(`Room State: ${RoomState[state]}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    this.roomState = state
  }

  public onStreamUserJoin(mediaUser: MediaUser) {
    this.rootStore.userManager.onMediaUserJoin(mediaUser)
  }

  public onStreamUserLeave(mediaUser: MediaUser) {
    this.rootStore.userManager.onMediaUserLeave(mediaUser)
  }

  public onStreamUserChanged(mediaUser: MediaUser, reason: UserChangedReason) {
    this.rootStore.userManager.onMediaUserChanged(mediaUser, reason)
  }

  public onStreamRoomInfo(mediaRoomInfo: Biz) {
    if (!mediaRoomInfo.timestamp || (this.roomInfo.timestamp >= mediaRoomInfo.timestamp)) {
      return
    }

    log(`onStreamRoomInfo fresh media room info ${JSON.stringify(mediaRoomInfo)}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    const oldHostUid = this.roomInfo.hostUid
    const newHostUid = mediaRoomInfo.hostUid || ''

    this.roomInfo.updateMediaRoomInfo(mediaRoomInfo)

    if (oldHostUid !== newHostUid) {
      if (newHostUid.length > 0) {
        // update host name
        const hostUser = this.rootStore.userManager.getUserByUid(newHostUid)
        if (hostUser) {
          this.roomInfo.hostName = hostUser.userFullName
        } else {
          this.roomInfo.hostName = ""
        }
      }

      this.rootStore.userManager.hostChanged(oldHostUid, newHostUid)
    }

    this.rootStore.rtcCommLayer.setRoomInfo(this.roomInfo)
  }

  public onStreamControl(sourceStreamId: number, control: Control) {
    const source = this.rootStore.userManager.getUser(sourceStreamId)
    if (!source) {
      log(`onStreamControl find no source user by stream id: ${sourceStreamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }

    switch (control.requestType) {
      case REQUEST_TYPE.ENABLE_AUDIO: {
        this.handleUnmuteMineAudio(source, control.sequenceId ? control.sequenceId : 0, "")
        break
      }

      case REQUEST_TYPE.DISABLE_AUDIO: {
        this.handleMuteMineAudio(source, control.sequenceId ? control.sequenceId : 0)
        break
      }

      case REQUEST_TYPE.ENABLE_VIDEO: {
        this.handleUnmuteMineVideo(source, control.sequenceId ? control.sequenceId : 0, "")
        break
      }

      case REQUEST_TYPE.DISABLE_VIDEO: {
        this.handleMuteMineVideo(source, control.sequenceId ? control.sequenceId : 0)
        break
      }

      case REQUEST_TYPE.KICK_USER: {
        const userMe = this.rootStore.userManager.getUserMe()
        if (userMe?.isHost) {
          log(`onStreamControl can not kick me, because I'm a host`, LOG_TYPE.INFO, LOG_MODULE.COMM)
          return
        }

        this.playRemoveRoomEffect()
        log(`onStreamControl KICK_USER source: ${source.description()}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
        this.returnToHome(false, 'YourAreRemovedBy', 'name', source?.isAssistant ? isChinese() ? '同声传译' : 'Interpreter' :  source.userFullName)
        break
      }
    }
  }

  public onStreamNetworkEvaluation(target: number, tx: number, rx: number) {
    this.rootStore.userManager.evaluateNetworkQuality(target, tx, rx)
  }

  /********************************************* Biz Callback *************************************/

  public onBizRoomState(state: BIZ_ROOM_STATE) {
    log(`onBizRoomState state: ${BIZ_ROOM_STATE[state]}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    if (this.bizRoomState === BIZ_ROOM_STATE.BIZ_ROOM_CONNECTED && (state === BIZ_ROOM_STATE.BIZ_ROOM_RECONNECTING || state === BIZ_ROOM_STATE.BIZ_ROOM_RECONNECTING_PEER)) {
      this.rootStore.userManager.bizDisconnected()
    }
    if (state === BIZ_ROOM_STATE.BIZ_ROOM_CONNECTED && !this.isRtcConnected && this.roomMode === ROOM_MODE.MODE_AGORA) {
      log(`rtc disconnect & join Biz success`)
      this.joinRTCChannel()
    }
    runInAction(() => {
      this.bizRoomState = state
    })
  }

  public onBizJoinDenied(err: number, des: string) {
    log(`onBizJoinForbidden ${err} ${des}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)

    this.returnToHome(true, alertErrorMap[err] || des)

    if (err === ROOM_AGORA_JOIN_NO_PERMISSION && !this.rootStore.uiStore.isHomeModeNormal) {
      this.rootStore.user.clearWeChatWorkUser()

      this.rootStore.uiStore.changeModeToNormal()
    }
  }

  public onMediaRoomJoinInfo(joinInfo:IMediaRoomJoinInfo) {
    const { encryption, rtcSecretKey, channelName, rtcToken, encryptionMode, salt, roomToken } = joinInfo
    log(`updateRTCEncryptionInfo current: ${this.roomInfo.rtcEncryption} new: ${encryption},channelName:${channelName}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
    if (!this.isInRoom()) return;

    if (encryption) {
      this.rootStore.user.updateRtcEncryption(encryption)
      if (encryptionMode === ENCRYPTION_MODE.AES_128_GCM2 || encryptionMode === ENCRYPTION_MODE.AES_256_GCM2) {
        this.roomInfo.salt = base64ToUint8Array(salt)
      } else {
        this.roomInfo.salt = undefined
      }
    } else {
      this.roomInfo.salt = undefined
    }

    const defaultChannelName = channelName || this.roomInfo.channelName
    const defaultEncryptionKey = getRtcEncryptionKey(defaultChannelName)
    if (this.roomInfo.rtcToken !== rtcToken) {
      this.roomInfo.rtcToken = rtcToken
    }

    this.rootStore.user.updateChannelNameCache(roomToken)
    if (this.roomMode === ROOM_MODE.MODE_NORMAL) {
      this.roomInfo.rtcSecretKey = defaultEncryptionKey
      if (this.roomInfo.encryptionMode !== encryptionMode) {
        this.roomInfo.encryptionMode = encryptionMode
        this.roomInfo.rtcEncryption = encryption
        this.rootStore.rtcCommLayer.changeEncryption(
          ++this.joinId,
          this.roomInfo.channelName,
          encryption, this.roomInfo.rtcSecretKey,
          this.roomInfo.selfStreamId, this.getUserMe()!.userFullName,
          rtcToken,
          this.roomInfo.encryptionMode,
          this.roomInfo.salt
        )
        this.rootStore.userManager.mediaDisconnected()
      }
    }

    if (this.roomInfo.channelName !== defaultChannelName) {
      this.roomInfo.channelName = defaultChannelName
    }

    if (this.roomMode === ROOM_MODE.MODE_AGORA) {
      this.roomInfo.encryptionMode = encryptionMode
      if (encryption) {
        this.roomInfo.rtcEncryption = encryption
        this.roomInfo.rtcSecretKey = rtcSecretKey || defaultEncryptionKey
      } else {
        this.roomInfo.rtcEncryption = false
        this.roomInfo.rtcSecretKey = defaultEncryptionKey
      }
      // if (!this.isRtcConnected) {
        // this.joinRTCChannel()
      // }

    }
  }

  public onBizJoinFail(msg: string) {
    this.returnToHome(true, msg)
  }

  public onBizRoomStatus(bizRoomInfo: BizRoomInfo, bizUsers: BizUser[], roomElapsed: number, hasMoreUser: boolean) {
    if (this.roomInfo.roomPwd !== bizRoomInfo.pwd) {
      log(`onBizRoomStatus pwd not match, leave room`, LOG_TYPE.ERROR, LOG_MODULE.COMM)

      this.doLeaveRoom()

      this.returnToHome(true, alertErrorMap[ERR_JOIN_WRONG_PWD])

      return
    }
    // 1. Room Info
    this.startRoomTimer(roomElapsed)
    this.handleBizRoomInfo(bizRoomInfo)

    // 2. User List
    this.rootStore.userManager.onBizUserSetup(bizUsers, !hasMoreUser)
  }

  public onBizRoomUsersAppend(bizUsers: BizUser[], hasMoreUser: boolean) {
    this.rootStore.userManager.onBizUsersAppend(bizUsers, !hasMoreUser)
  }

  public onBizRoomUsersPatch(bizUsers: BizUser[]) {
    this.rootStore.userManager.onBizUsersPatch(bizUsers)
  }

  public onBizUserJoin(bizUser: BizUser) {

    this.rootStore.userManager.onBizUserJoin(bizUser)
  }

  public onBizUserLeave(bizUser: BizUser) {
    this.rootStore.userManager.onBizUserLeave(bizUser)
  }

  public onBizUserChanged(bizUser: BizUser, reason: UserChangedReason) {

    const user = this.rootStore.userManager.onBizUserChanged(bizUser, reason)
    if (reason === UserChangedReason.REASON_INTERRUPT && user && this.subscribeAssistant) {
      if (user.isAssistant) {
        if (user.isInterrupt) {
          this.rootStore.notification.onNoticeAssistantInterrupt()
          this.rootStore.rtcCommLayer.restoreRemotesVolumes()
        } else {
          this.rootStore.notification.onNoticeAssistantBack()
          this.enableAssist(this.assistantStatus === ASSISTANT_STATE.ASSIST_ENABLED_WITH_ORIGIN_SOUND)
        }
        return
      }
    }

    if (user && reason === UserChangedReason.REASON_INTERRUPT && bizUser.IsInterrupt() && (this.rootStore.userManager.attendeesCount < 50 || user.isMediaActiveOrJustMuted())) {
      this.rootStore.notification.onNoticeUserInterrupt(user.userFullName)
    }

  }

  public onBizRoomInfoChanged(roomInfo: BizRoomInfo) {
    this.handleBizRoomInfo(roomInfo)
  }

  private handleBizRoomInfo(bizRoomInfo: BizRoomInfo) {
    const oldHostUid = this.roomInfo.hostUid
    const newHostUid = bizRoomInfo.getHostUid()

    if (oldHostUid !== newHostUid) {
      this.rootStore.userManager.hostChanged(oldHostUid, newHostUid)
    }
    this.roomInfo.updateBizRoomInfo(bizRoomInfo)
    this.rootStore.rtcCommLayer.setRoomInfo(this.roomInfo)
  }

  public onMuteMineAudio(bizSource: BizUser, seqId: number) {
    const source = this.rootStore.userManager.getUser(bizSource.streamId)
    if (!source) {
      log(`onMuteMineAudio, find none source, stream id: ${bizSource.streamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }

    this.handleMuteMineAudio(source, seqId)
  }

  public onMuteMineVideo(bizSource: BizUser, seqId: number) {
    const source = this.rootStore.userManager.getUser(bizSource.streamId)
    if (!source) {
      log(`onMuteMineVideo, find none source, stream id: ${bizSource.streamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }

    this.handleMuteMineVideo(source, seqId)
  }

  public onUnmuteMineAudio(bizSource: BizUser, seqId: number, requestId: string) {
    const source = this.rootStore.userManager.getUser(bizSource.streamId)
    if (!source) {
      log(`OnUnmuteMineAudio, find none user, stream id: ${bizSource.streamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }

    this.handleUnmuteMineAudio(source, seqId, requestId)
  }

  public onUnmuteMineVideo(bizSource: BizUser, seqId: number, requestId: string) {
    const source = this.rootStore.userManager.getUser(bizSource.streamId)
    if (!source) {
      log(`onUnmuteMineVideo, find none user, stream id: ${bizSource.streamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }

    this.handleUnmuteMineVideo(source, seqId, requestId)
  }

  private handleMuteMineAudio(source: CommUser, seqId: number) {
    if (seqId <= source.audioSeq) {
      log(`handleMuteMineAudio, old seq, ignore`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }

    source.audioSeq = seqId

    const userMe = this.rootStore.userManager.getUserMe()

    if (userMe!.audioState) {
      log(`handleMuteMineAudio, source name: ${source.userFullName}, seq: ${seqId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

      this.setLocalAudio(false)
      this.rootStore.notification.onNoticeMuteAudio(source.isAssistant ? isChinese() ? '同声传译' : 'Interpreter' : source.userFullName)

      isChinese() ? this.rootStore.rtcEngineLayer.playSoundEffect(beMutedCn) : this.rootStore.rtcEngineLayer.playSoundEffect(beMutedEn)
    }
  }

  private handleMuteMineVideo(source: CommUser, seqId: number) {
    if (seqId <= source.videoSeq) {
      log(`handleMuteMineVideo, old seq, ignore`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }

    source.videoSeq = seqId

    const userMe = this.rootStore.userManager.getUserMe()

    if (userMe!.videoState) {
      log(`handleMuteMineVideo, source name: ${source.userFullName}, seq: ${seqId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

      this.setLocalVideo(false)
      this.rootStore.notification.onNoticeMuteVideo(source.isAssistant ? isChinese() ? '同声传译' : 'Interpreter' : source.userFullName)

      // this.rootStore.notification.toast('Turn off your camera', 'name', source.userFullName)

      this.rootStore.rtcEngineLayer.playSoundEffect(controlMute)
    }
  }

  public onSelfApplySelfApplyAssistantResponse(response: SelfApplyAssistantResponse, owner?: BizUser) {
    log(`onSelfApplyAssistantResponse request id: ${response.requestId}  ${response.isSuccess} `, LOG_TYPE.INFO, LOG_MODULE.COMM)

    if (!response.isSuccess) {
      this.setApplyAssistantResult(APPLY_ASSISTANT_RESULT.REFUSE)
      response.reason !== 'TIMEOUT' ? this.rootStore.notification.onApplyAssistantRefuse() : this.rootStore.notification.onApplyAssistantTimeout()
      return
    }
    if (owner) {
      // const assistant = this.parseAssistantInformation(owner, this.applyAssistantLang)
      this.setApplyAssistantResult(APPLY_ASSISTANT_RESULT.SUCCESS)
      // this.assistantInfo.updateAssistantInfo(assistant)
      this.setCloseAssistantNotification(false)
      this.rootStore.notification.onApplyAssistantSuccess(response.reason)
    }
  }

  @action
  public onUserApplyAssistant(requestInfo: UserApplyAssistantRequest, owner?: BizUser) {
    if (!this.roomInfo.isMeHost || this.roomMode !== ROOM_MODE.MODE_AGORA) {
      return
    }
    const assistantInfo = new AssistantInfo()
    assistantInfo.language = requestInfo.language
    assistantInfo.thirdpartyAlias = requestInfo.alias
    assistantInfo.thirdpartyName = requestInfo.innerName
    assistantInfo.assistantUid = requestInfo.uid
    assistantInfo.updateAssistantInfo(assistantInfo)
    this.setCloseAssistantNotification(true)
    this.setCloseAssistantNotification(false)
    this.rootStore.notification.onUserApplyAssistant(assistantInfo, requestInfo.requestId, this.rootStore.user.REQUEST_TIMEOUT / 1000)
  }



  public assentAssistantApply(requestId: string) {
    this.rootStore.rtmCommLayer.acceptRemoteRequest(requestId, 0)

  }
  public rejectAssistantApply(requestId: string) {
    this.rootStore.rtmCommLayer.refuseRemoteRequest(requestId, 0)
  }

  public onRoomAssistantChanged(existence: boolean, assistantInfo: AssistantInfo, source: string) {
    log(`onRoomAssistantChanged existence ${existence} new assistantInfo.uid :${assistantInfo.assistantUid},old assistantInfo ${this.assistantInfo.assistantUid},source:${source}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
    if (existence) {
      if (this.assistantInfo.hasAssistant()) {
        if (this.assistantInfo.assistantUid !== assistantInfo.assistantUid) {
          this.onAssistantCanceled(this.assistantInfo, "");
          this.onAssistantApplied(assistantInfo);
        }
      } else {
        this.onAssistantApplied(assistantInfo);
      }
      if (this.applyAssistantResult === APPLY_ASSISTANT_RESULT.WAIT) {
        this.setApplyAssistantResult(APPLY_ASSISTANT_RESULT.DEFAULT)
      }
    } else {
      if (!this.subscribeAssistant) {
        this.setCloseAssistantNotification(false)
        this.setCloseAssistantNotification(true)
      }
      if (this.subscribeAssistant && !this.assistantInfo.isMeAssistant()) {
        this.rootStore.notification.onCancelAssistant()
      }
      this.onAssistantCanceled(assistantInfo, source);

    }

  }

  private onAssistantCanceled(assistantInfo: AssistantInfo, source: string) {
    const user = this.convertAssistantToBizUser(assistantInfo)
    log(`onAssistantCanceled room.assistantInfo ${JSON.stringify(this.assistantInfo)}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
    this.rootStore.userManager.onRoomAssistantCanceled(user)
    this.resetAssist()
    this.assistantInfo.resetAssistantInfo()
  }

  private onAssistantApplied(assistantInfo: AssistantInfo) {
    log(`onAssistantApplied new assistantInfo ${JSON.stringify(assistantInfo)} old assistantInfo ${JSON.stringify(this.assistantInfo)}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
    const user = this.convertAssistantToBizUser(assistantInfo)
    this.assistantInfo.updateAssistantInfo(assistantInfo)
    if (!this.assistantInfo.isMeAssistant()) {
      this.disableAssist()
    }
    this.rootStore.userManager.onRoomAssistantApplied(user)
  }

  private convertAssistantToBizUser(assistantInfo: AssistantInfo) {
    const user = new BizUser()
    user.uid = assistantInfo.assistantUid
    user.streamId = assistantInfo.streamId
    user.thirdpartyAlias = assistantInfo.thirdpartyAlias
    user.thirdpartyName = assistantInfo.thirdpartyName
    user.name = assistantInfo.nickName
    user.streamId = assistantInfo.streamId
    user.isLoggedIn = Boolean(this.roomMode === ROOM_MODE.MODE_AGORA && user.name && user.thirdpartyName)
    user.isHost = assistantInfo.assistantUid === this.roomInfo.hostUid
    user.setCloudRecording(user.uid === this.recorderUserUid)
    // this.setAssistantUser(user)
    return user
  }

  public onStopAssistantCloudRecording(uid: string) {
    if (uid === this.assistantInfo.assistantUid) {
      this.rootStore.userManager.onStopAssistantCloudRecording(uid)
    }
  }

  public onBizUserKickOut(bizUser: BizUser, bizSource?: BizUser) {
    const target = this.rootStore.userManager.getUser(bizUser.streamId)
    if (!target) {
      log(`OnBizUserKickOut, find none target by ${bizUser.streamId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
      return
    }

    let source: CommUser | undefined;
    if (bizSource) {
      source = this.rootStore.userManager.getUser(bizSource.streamId)
    }

    if (target.isMe) {
      if (target.isHost) {
        log(`onBizUserKickOut can not kick me because I'm a host`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
        return
      }

      this.playRemoveRoomEffect()
      this.returnToHome(false, "YourAreRemovedBy", 'name', source?.isAssistant ? isChinese() ? '同声传译' : 'Interpreter' :  source?.userFullName || "Unknown")
    } else {
      this.rootStore.notification.toast("SomeoneAreRemoved", 'name', target.userFullName)
    }
  }

  public onKickUserForAtlas() {
    this.playRemoveRoomEffect()
    this.returnToHome(false, "join_fail_sever_kick_out", 'name')
    this.leaveRoom()
  }

  private startRoomTimer(alreadyElapsed: number) {
    this.clearRoomTimer()

    this.roomAlreadyElapsed = alreadyElapsed
    this.elapseStart = +new Date()

    this.refreshRoomTimer()

    this.roomTimer = window.setInterval(() => {
      this.refreshRoomTimer()
    }, 1000)
  }

  private refreshRoomTimer() {
    runInAction(() => {
      const current = +new Date()
      this.roomElapsed = this.roomAlreadyElapsed + (current - this.elapseStart)
    })
  }

  private clearRoomTimer() {
    if (this.roomTimer > 0) {
      window.clearInterval(this.roomTimer)
      this.roomTimer = 0
    }
  }

  public setBizConnectErrorMessage(error: number) {
    this.bizConnectedErrorMessage = alertErrorMap[error] || 'TimeoutRTMConnect'
  }

  public onAssistantAudioStateChanged(user: CommUser) {
    if (!this.subscribeAssistant) {
      return
    }
    log(`onAssistantAudioStateChanged current user audio state :${user.audioState }`)
    if (user.audioState || this.assistantStatus === ASSISTANT_STATE.ASSIST_ENABLED_WITHOUT_ORIGIN_SOUND) {
      this.startAssistIfReady(user);
    } else {
      this.stopAssist();
    }
  }

  public onAssistantInterruptStateChange(user: CommUser) {
    if (!this.subscribeAssistant) {
      return
    }
    if (user.isInterrupt) {
      this.rootStore.notification.onNoticeAssistantInterrupt()
    } else {
      this.rootStore.notification.onNoticeAssistantBack()
    }

  }

  public onAssistantOnlineStateChanged(user: CommUser) {
    if (!this.subscribeAssistant) {
      return
    }
    if (!user.online) {
      this.rootStore.notification.onNoticeAssistantLeave()
      this.rootStore.rtcCommLayer.restoreRemotesVolumes()
    } else {
      this.rootStore.notification.onNoticeAssistantBack()
      this.enableAssist(this.assistantStatus === ASSISTANT_STATE.ASSIST_ENABLED_WITH_ORIGIN_SOUND)
    }
  }

  /****************************** REQUEST *****************************/

  /************************* Remote Request *************************/
  // remote reuqests
  public remoteAudioRequestPendings = new RemoteRequest()
  public remoteVideoRequestPendings = new RemoteRequest()
  @observable
  public remoteAudioRequests: string = ''
  @observable
  public remoteVideoRequests: string = ''

  private handleUnmuteMineAudio(source: CommUser, seqId: number, requestId: string) {
    if (seqId <= source.audioSeq) {
      if (requestId) {
        log(`OnUnmuteMineAudio, old seq, update request id: ${requestId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

        this.updateRemoteRequestId(PendingType.PENDING_AUDIO, source, requestId)
      }
    } else {
      log(`OnUnmuteMineAudio, source name: ${source.userFullName}, seq: ${seqId}`)

      source.audioSeq = seqId

      this.addRemotePendingRequest(PendingType.PENDING_AUDIO, source, requestId)

      this.rootStore.rtcEngineLayer.playSoundEffect(controlRequestUnmute)
    }
  }

  private handleUnmuteMineVideo(source: CommUser, seqId: number, requestId: string) {
    if (seqId <= source.videoSeq) {
      if (requestId) {
        log(`onUnmuteMineVideo, old seq, update`, LOG_TYPE.INFO, LOG_MODULE.COMM)

        this.updateRemoteRequestId(PendingType.PENDING_VIDEO, source, requestId)
      }
    } else {

      log(`OnUnmuteMineAudio, source name: ${source.userFullName}, seq: ${seqId}`)

      source.videoSeq = seqId

      this.addRemotePendingRequest(PendingType.PENDING_VIDEO, source, requestId)

      this.rootStore.rtcEngineLayer.playSoundEffect(controlRequestUnmute)
    }
  }

  public acceptRemoteRequest(isAudio: boolean) {
    log(`accept remote request, is audio: ${isAudio}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    if (isAudio) {
      log('user accept remote audio request', LOG_TYPE.COMM, LOG_MODULE.UI)

      this.setLocalAudio(true)
    } else {
      log('user accept remote video request', LOG_TYPE.COMM, LOG_MODULE.UI)

      this.setLocalVideo(true)
    }

    const requestIds = this.removeRemoteRequest(isAudio ? PendingType.PENDING_AUDIO : PendingType.PENDING_VIDEO)

    requestIds.forEach((item: string) => {
      this.rootStore.rtmCommLayer.acceptRemoteRequest(item, 0)
    })
  }

  public refuseRemoteRequest(isAudio: boolean) {
    log(`refuse remote request, is audio: ${isAudio}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    const requestIds = this.removeRemoteRequest(isAudio ? PendingType.PENDING_AUDIO : PendingType.PENDING_VIDEO)
    requestIds.forEach((item: string) => {
      this.rootStore.rtmCommLayer.refuseRemoteRequest(item, 0)
    })
  }

  private addRemotePendingRequest(type: PendingType, source: CommUser, requestId: string) {
    const request = type === PendingType.PENDING_AUDIO ? this.remoteAudioRequestPendings : this.remoteVideoRequestPendings
    if (request.timerId !== 0) {
      clearTimeout(request.timerId)
    }

    request.timerId = window.setTimeout(this.remoteRequestTimeout.bind(this, type), this.rootStore.user.REQUEST_TIMEOUT)

    request.sources.set(source.streamId, source)
    request.requestIds.set(source.streamId, requestId)

    this.setRemoteRequests(type, request.sources)
  }

  private remoteRequestTimeout(type: PendingType) {
    const request = type === PendingType.PENDING_AUDIO ? this.remoteAudioRequestPendings : this.remoteVideoRequestPendings
    request.timerId = 0

    request.sources.clear()
    request.requestIds.clear()

    this.setRemoteRequests(type, request.sources)
  }

  private removeRemoteRequest(type: PendingType): string[] {
    const request = type === PendingType.PENDING_AUDIO ? this.remoteAudioRequestPendings : this.remoteVideoRequestPendings

    if (request.timerId !== 0) {
      clearTimeout(request.timerId)
      request.timerId = 0
    }

    request.sources.clear()

    const requestIds: string[] = []
    request.requestIds.forEach(item => requestIds.push(item))
    request.requestIds.clear()

    this.setRemoteRequests(type, request.sources)

    return requestIds
  }

  private updateRemoteRequestId(type: PendingType, source: CommUser, requestId: string) {
    const request = type === PendingType.PENDING_AUDIO ? this.remoteAudioRequestPendings : this.remoteVideoRequestPendings

    request.requestIds.set(source.streamId, requestId)
  }

  private clearAllRemoteRequests() {
    if (this.remoteAudioRequestPendings.timerId !== 0) {
      clearTimeout(this.remoteAudioRequestPendings.timerId)
      this.remoteAudioRequestPendings.timerId = 0
    }

    this.remoteAudioRequestPendings.sources.clear()
    this.remoteAudioRequestPendings.requestIds.clear()

    this.setRemoteRequests(PendingType.PENDING_AUDIO, undefined)

    if (this.remoteVideoRequestPendings.timerId !== 0) {
      clearTimeout(this.remoteVideoRequestPendings.timerId)
      this.remoteVideoRequestPendings.timerId = 0
    }

    this.remoteVideoRequestPendings.sources.clear()
    this.remoteVideoRequestPendings.requestIds.clear()

    this.setRemoteRequests(PendingType.PENDING_VIDEO, undefined)
  }

  @action
  private setRemoteRequests(type: PendingType, sources?: Map<number, CommUser>) {
    let str = ''
    let isAssistantRequest = false

    if (sources && sources.size > 0) {
      sources.forEach((item: CommUser) => {
        str += `${item.userFullName}, `
        isAssistantRequest = item.isAssistant
      })
      str = str.slice(0, str.length - 2)// remove last ", "
    }

    if (type === PendingType.PENDING_AUDIO) {
      log(`remote audio requests: ${str}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
      this.remoteAudioRequests = isAssistantRequest ? isChinese() ? '同声传译' : 'Interpreter' : str
    } else {
      log(`remote video requests: ${str}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
      this.remoteVideoRequests = isAssistantRequest ? isChinese() ? '同声传译' : 'Interpreter' : str
    }
  }

  /************************* Local Request *************************/

  // local requests
  public pendingOperations: PendingOperation[] = []

  public onMyRequestResponse(response: MyRequestResponse) {
    log(`onMyRequestResponse request id: ${response.requestId} target uid: ${response.target} ${response.isSuccess} ${response.isAudio}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    const pending = this.removePendingOperationByRequestId(response.targetStreamId, response.requestId)
    if (!pending) {
      return
    }

    if (!response.isSuccess) {
      const pendingType = response.isAudio ? PendingType.PENDING_AUDIO : PendingType.PENDING_VIDEO

      const target = this.rootStore.userManager.setUserOperationPending(response.targetStreamId, pendingType, false)
      if (target) {
        response.reason === "REJECTED_BY_USER" && this.rootStore.notification.onNoticeRejectRequest(target?.isAssistant ? isChinese() ? '同声传译' : 'Interpreter' : target?.userFullName || '', response.isAudio)
      } else {
        log(`onMyRequestResponse find no user by stream id: ${response.targetStreamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      }
    }
  }

  public onMyRequestMediaResponse(type: PendingType, target: number) {
    log(`onMyRequestMediaResponse target: ${target} is audio: ${type === PendingType.PENDING_AUDIO}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    this.removePendingOperationByTargetType(type, target)
  }

  private addPendingOperation(type: PendingType, seq: number, target: number, timeout: number, isOpenOperations: boolean) {
    const timerId = window.setTimeout(this.pendingOperationTimeout.bind(this, seq), timeout)

    const pos = this.pendingOperations.findIndex(item => item.target === target && item.pendingType === type)
    if (pos >= 0) {
      const pending = this.pendingOperations[pos]
      clearTimeout(pending.timerId)
      pending.timerId = timerId
      pending.opSeq = seq
      pending.isOpenOperations = isOpenOperations
    } else {
      this.pendingOperations.push(new PendingOperation(type, seq, target, timerId, isOpenOperations))
    }
  }

  private addPendingOperationRequestId(seq: number, requestId: string) {
    const pending = this.pendingOperations.find(item => item.opSeq === seq)
    if (pending) {
      pending.requestIds.push(requestId)
    } else {
      log(`addPendingOperationRequestId find no pending by seq: ${seq}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
    }
  }

  private removePendingOperation(seq: number): PendingOperation | undefined {
    log(`removePendingOperation by seq: ${seq}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    const pos = this.pendingOperations.findIndex(item => item.opSeq === seq)
    if (pos >= 0) {
      const pending = this.pendingOperations[pos]
      clearTimeout(pending.timerId)
      this.pendingOperations.splice(pos, 1)
      return pending
    }
    return undefined
  }

  private removePendingOperationByRequestId(target: number, requestId: string): PendingOperation | undefined {
    log(`removePendingOperation by target: ${target} and request id: ${requestId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    const pos = this.pendingOperations.findIndex(item => item.target === target && item.requestIds.find(id => id === requestId))
    if (pos >= 0) {
      const pending = this.pendingOperations[pos]
      clearTimeout(pending.timerId)
      this.pendingOperations.splice(pos, 1)
      return pending
    }
    return undefined
  }

  private removePendingOperationByTargetType(type: PendingType, target: number) {
    log(`removePendingOperation by target: ${target} and type: ${PendingType[type]}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    const pos = this.pendingOperations.findIndex(item => item.target === target && item.pendingType === type)
    if (pos >= 0) {
      const pending = this.pendingOperations[pos]
      clearTimeout(pending.timerId)
      this.pendingOperations.splice(pos, 1)
      return pending
    }
    return undefined
  }

  private clearPendingOperationsByTarget(target: number) {
    let index = this.pendingOperations.length
    while (index--) {
      const pending = this.pendingOperations[index]
      if (pending.target === target) {
        clearTimeout(pending.timerId)
        this.pendingOperations.splice(index, 1)
      }
    }
  }

  private clearAllPendingOperations() {
    this.pendingOperations.forEach((item: PendingOperation) => {
      clearTimeout(item.timerId)
    })
    this.pendingOperations = []
  }

  private pendingOperationTimeout(seq: number) {
    const pending = this.removePendingOperation(seq)
    if (!pending) {
      return
    }

    this.rootStore.userManager.setUserOperationPending(pending.target, pending.pendingType, false)
    const isAudio = pending.pendingType === PendingType.PENDING_AUDIO;

    log(`wait remote response timeout, remote stream id: ${pending.target} is audio: ${pending.pendingType === PendingType.PENDING_AUDIO}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    // this.rootStore.notification.toast('ResponseWaitTimeout')
    const peerUser = this.rootStore.userManager.attendeeList.find(item => item.streamId === pending.target)
    this.rootStore.notification.onNoticeResponseWaitTimeout(peerUser?.isAssistant ? isChinese() ? '同声传译' : 'Interpreter' : peerUser?.userFullName || '', isAudio, pending.isOpenOperations)

  }

  public setLocalMediaState(user: MediaUser, reason: UserChangedReason) {
    if (this.isRtcConnected) {
      this.onStreamUserChanged(user, reason)
    }
  }

  /****************************** Public APIs: UserManager Callback *****************************/

  @observable
  public isRoomDumpIssue: boolean = false
  private dumpUsers: CommUser[] = []

  // NEW ADD
  public onUserStreamTypeNeedChange(streamId: number, isHigh: boolean) {
    this.rootStore.rtcCommLayer.setStreamVideoQuality(streamId, isHigh)
  }

  public onUserIssueDumpingStateChanged(user: CommUser) {
    const pos = this.dumpUsers.findIndex(item => item.streamId === user.streamId)

    if (user.isIssueRecording) {
      if (pos < 0) {
        this.dumpUsers.push(user)

        if (user.isMe) {
          this.rootStore.rtcEngineLayer.playSoundEffect(dumpSound)
        } else if (!user.dumpAudioEffectOnce) {
          user.dumpAudioEffectOnce = true
          this.rootStore.rtcEngineLayer.playSoundEffect(dumpSound)
        }
      }
    } else {
      if (pos >= 0) {
        this.dumpUsers.splice(pos, 1)
      }
    }

    this.notifyCurrentDumpStatus()
  }

  public onUserRemoved(streamId: number) {
    // clear dump status
    const pos = this.dumpUsers.findIndex(item => item.streamId === streamId)
    if (pos >= 0) {
      this.dumpUsers.splice(pos, 1)

      this.notifyCurrentDumpStatus()
    }

    this.clearPendingOperationsByTarget(streamId)
  }

  private clearDumpStatus() {
    this.dumpUsers = []

    this.notifyCurrentDumpStatus()
  }

  @action
  private notifyCurrentDumpStatus() {
    this.isRoomDumpIssue = this.dumpUsers.length !== 0
  }

  public onMajorUserReplaced(streamId: number) {
    if (this.rootStore.rtcCommLayer.getSelfShareId() !== 0) {
      this.rootStore.rtmCommLayer.setCurrentMajorStreamId(streamId)
    }
  }

  public onUserHostStateChanged(isHost: boolean, user: CommUser) {
    // no need to toast anymore
  }

  public onRemoteSharingState(sharing: boolean, user: CommUser) {
    this.rootStore.shareManager.updateRemoteSharing(sharing ? user : undefined)
    const isStartShare = user && (user?.isShareJustStartByBiz ||
      (!user?.isShareJustStartByBiz && ((new Date()).valueOf()) - user?.createTimestamp) > 2 * 1000)
    this.rootStore.notification.onNoticeRemoteSharing(sharing, isStartShare, user?.isAssistant ? isChinese() ? '同声传译' : 'Interpreter' : user?.userFullName)
  }

  /****************************** Public APIs: Others *****************************/

  public onLocalShareStart(shareId: number) {
    this.rootStore.userManager.setSelfShareInfo(shareId)

    // local share id is cloud record major id
    this.rootStore.rtmCommLayer.setCurrentMajorStreamId(this.getCloudRecordingMajorId())
  }

  public onLocalShareEnd() {
    this.rootStore.userManager.clearSelfShareInfo()

    // restore cloud record major id
    this.rootStore.rtmCommLayer.setCurrentMajorStreamId(this.getCloudRecordingMajorId())
  }
  /****************************** Network Quality *****************************/

  private networkEval = new NetworkEval()

  @computed
  public get showQualityTip() {
    return this.networkEval.lastQualityType < QualityType.LOCAL_NETWORK_UP_GOOD
    // return true
  }

  @computed
  public get qualityTipTitle() {
    switch (this.networkEval.lastQualityType) {
      case QualityType.LOCAL_NETWORK_OFF:
        return "DisconnectedLocalNetwork";
      case QualityType.REMOTE_NETWORK_OFF:
        return "DisconnectedRemoteNetwork";
      case QualityType.LOCAL_NETWORK_DOWN_WEAK:
        return "PoolLocalNetwork";
      case QualityType.REMOTE_NETWORK_UP_WEAK:
        return "PoolRemoteNetwork";
      case QualityType.LOCAL_NETWORK_UP_WEAK:
        return "PoolLocalNetwork";
      case QualityType.REMOTE_NETWORK_DOWN_WEAK:
        return "PoolRemoteNetwork";
      default:
        return ""
    }
  }

  @computed
  public get qualityTipContent() {
    switch (this.networkEval.lastQualityType) {
      case QualityType.LOCAL_NETWORK_OFF:
        return "CheckLocalNetwork";
      case QualityType.REMOTE_NETWORK_OFF:
        return "WaitRemoteConnect";
      case QualityType.LOCAL_NETWORK_DOWN_WEAK:
        return "CheckLocalNetwork";
      case QualityType.REMOTE_NETWORK_UP_WEAK:
        return "CheckRemoteNetwork";
      case QualityType.LOCAL_NETWORK_UP_WEAK: {
        const userMe = this.rootStore.userManager.getUserMe()
        if (userMe?.videoState) {
          if (this.rootStore.user.hasLowerResolution() || userMe?.audioState) {
            return "TryTip"
          }
          return "CheckLocalNetwork";
        }
        return "CheckLocalNetwork";
      }
      case QualityType.REMOTE_NETWORK_DOWN_WEAK: {
        const userMe = this.rootStore.userManager.getUserMe()
        if (userMe?.videoState) {
          if (this.rootStore.user.hasLowerResolution() || userMe?.audioState) {
            return "TryTip"
          }
          return "CheckRemoteNetwork";
        }
        return "CheckRemoteNetwork";
      }
    }
    return ""
  }

  @computed
  public get qualityTipSubcontent() {
    switch (this.networkEval.lastQualityType) {
      case QualityType.LOCAL_NETWORK_UP_WEAK:
      case QualityType.REMOTE_NETWORK_DOWN_WEAK: {
        const userMe = this.rootStore.userManager.getUserMe()
        if (userMe?.videoState) {
          if (this.rootStore.user.hasLowerResolution()) {
            return { text: "TurnDownResolution", value: this.rootStore.user.lowerResolutionLabel() }
          }
          if (userMe?.audioState) {
            return { text: "ShutDownCamera" }
          }
        }
      }
    }
    return { text: "" }
  }

  public userQualityTipClose() {
    log(`user close quality tip`, LOG_TYPE.INFO, LOG_MODULE.MODEL)

    this.networkEval.immuneByType()
  }

  public userQualityTipAction() {
    log(`user action quality tip`, LOG_TYPE.INFO, LOG_MODULE.MODEL)

    if (this.qualityTipSubcontent.text === "TurnDownResolution") {
      const lowerResolutionLabel = this.rootStore.user.lowerResolutionLabel()
      log(`user action quality tip `, lowerResolutionLabel)

      const ret = this.rootStore.user.lowerResolution()
      if (ret) {
        this.rootStore.notification.onNoticeResolutionHasTurnDown(lowerResolutionLabel)
      }
    } else if (this.qualityTipSubcontent.text === "ShutDownCamera") {
      const userMe = this.rootStore.userManager.getUserMe()
      if (userMe?.videoState && userMe?.audioState) {
        this.rootStore.rtcCommLayer.enableLocalVideo(false)

        this.rootStore.notification.toast('CameraHasShutDown')
      } else {
        log(`user tip action, not expect current video: ${userMe?.videoState}, audio: ${userMe?.audioState}`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
      }
    }

    // check action, than remove quality type
    this.networkEval.immuneWeakNetwork()
  }

  /***************************** Cloud Record *****************************/

  @observable
  public isRoomCloudRecording: boolean = false
  @observable
  public recorderUserUid: string = ''

  @observable
  public recorderUserName: string = ''
  @observable
  public recorderElapsedTime: number = 0

  private recordTimer: number = 0
  private recordElapseStart: number = 0
  private recordAlreadyElapsed: number = 0

  @observable
  public localCloudRecordingIconState: boolean = false
  @observable
  public localCloudRecordingFailReason: string = ''

  @action
  private clearRecordInfo() {
    this.isRoomCloudRecording = false
    this.recorderUserName = ''
    this.recorderUserUid = ''
    this.clearCloudRecordTimer()
    this.localCloudRecordingIconState = false
  }

  @action
  private setSubscribeAssistantStatus(status: SUBSCRIBE_ASSISTANT_STATUS, exceptAssistantStatus: ASSISTANT_STATE) {
    this.subscribeAssistantStatus = status
    this.assistantStatus = exceptAssistantStatus
  }

  @action setApplyAssistantResult(status: APPLY_ASSISTANT_RESULT) {
    this.applyAssistantResult = status
    if (status === APPLY_ASSISTANT_RESULT.REFUSE) {
      window.setTimeout(() => {
        this.applyAssistantResult = APPLY_ASSISTANT_RESULT.DEFAULT
      }, 3000)
    }
  }

  public onBizCloudRecordingStatus(enable: boolean, recorder: Recorder | undefined, isJustStarted: boolean) {
    if (enable) {
      this.startCloudRecordTimer(recorder!.elapsedTime)

      if (isJustStarted) {
        this.rootStore.rtcEngineLayer.playSoundEffect(cloudRecordingSound)
      }

      if (recorder?.uid === this.roomInfo.selfUid) {
        this.setLocalCloudRecordingIcon(true)
      }
    } else {
      this.clearCloudRecordTimer()
    }

    this.setRoomCloudRecordingState(enable, recorder)
  }

  @action
  public setRoomCloudRecordingState(enable: boolean, recorder: Recorder | undefined) {
    let name = ''
    if (enable) {
      if (recorder?.innerName) {
        name = recorder.innerName
      }
      if (recorder?.alias) {
        name = `${name}(${recorder.alias})`
      }
    } else {
      if (recorder?.uid === this.roomInfo.selfUid) {
        this.localCloudRecordingIconState = false
      }
    }

    this.isRoomCloudRecording = enable
    this.recorderUserUid = recorder?.uid || ''
    this.recorderUserName = name
  }

  @action
  private startCloudRecordTimer(elapsed: number) {
    this.recordAlreadyElapsed = elapsed

    this.recordElapseStart = +new Date()

    this.refreshCloudRecordingTimer()

    this.clearCloudRecordTimer()

    this.recordTimer = window.setInterval(() => {
      this.refreshCloudRecordingTimer()
    }, 1000)
  }

  private refreshCloudRecordingTimer() {
    runInAction(() => {
      const current = +new Date()
      this.recorderElapsedTime = this.recordAlreadyElapsed + (current - this.recordElapseStart)
    })
  }

  private clearCloudRecordTimer() {
    if (this.recordTimer > 0) {
      window.clearInterval(this.recordTimer)
      this.recordTimer = 0
      this.recorderElapsedTime = 0
    }
  }

  @action
  public setLocalCloudRecordingIcon(enable: boolean) {
    this.localCloudRecordingIconState = enable
  }

  @action
  public setLocalCloudRecordingFailReason(reason: string) {
    this.localCloudRecordingFailReason = reason
  }

  public async startCloudRecording() {
    const majorId = this.getCloudRecordingMajorId()

    const code = await this.rootStore.rtmCommLayer.startCloudRecording(this.roomInfo.channelName, majorId)
    if (code === 0) {
      this.setLocalCloudRecordingIcon(true)
    } else {
      // this.setLocalCloudRecordingFailReason(errp'StartCloudRecordingFailure')
      this.setLocalCloudRecordingFailReason(alertErrorMap[code])
    }
  }

  public async stopCloudRecording() {
    const code = await this.rootStore.rtmCommLayer.stopCloudRecording()

    if (code === 0 || code === CloudRecordingError.ERR_RECORDING_NOT_RUNNING) {
      execCopy(ATLAS_RECORDING_URL, 'on end recording copy success')

      this.setLocalCloudRecordingFailReason('HasStoppedCloudRecording')
      this.setLocalCloudRecordingIcon(false)
    } else {
      if (code === CloudRecordingError.ERR_RECORDING_TOO_SHORT || code === CloudRecordingError.ERR_RECORDING_GENERATE_FILE) {
        this.setLocalCloudRecordingIcon(false)
        this.setLocalCloudRecordingFailReason(alertErrorMap[code])
      } else {
        this.setLocalCloudRecordingIcon(true)
        execCopy(ATLAS_RECORDING_URL, 'on end recording error copy success')
        // this.rootStore.notification.addAlert(false, 'EndCloudRecordingFailed', '', '', 'Notice', "Got it")
      }
    }
  }

  private getCloudRecordingMajorId(): number {
    const shareId = this.rootStore.rtcCommLayer.getSelfShareId()

    return shareId !== 0 ? shareId : this.rootStore.userManager.getMajorStreamId()
  }

  /******************** Other Functions ********************/

  public clearUserState() {
    log(`clearUserState`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    this.leaveRoom()
  }

  public GetRtcChannelName(roomName: string, pwd: string): string {
    if (this.roomMode === ROOM_MODE.MODE_AGORA) {
      if (pwd.length > 0) {
        return `${roomName}-${pwd}`
      }
      return roomName
    }

    if (pwd && pwd.length > 0) {
      return `${roomName} ${pwd}`
    }
    return roomName
  }

  private playRemoveRoomEffect() {
    isChinese() ? this.rootStore.rtcEngineLayer.playSoundEffect(beRemovedCn) : this.rootStore.rtcEngineLayer.playSoundEffect(beRemovedEn)
  }

  public uploadLogForAtlas() {
    this.rootStore.rtmCommLayer.onPullLogFromAtlas()
  }

  public updateWatermarkConfig(isOpen: boolean) {
    this.isOpenWatermark = isOpen
  }

  private getUserMe(): CommUser | undefined {
    return this.rootStore.userManager.getUserMe()
  }

  public checkAndForceReturnHome() {
    this.returnToHome(true, 'UserNotAgoranAnymoreInRoom')
  }

  @action
  public updateNeedReturnHomePage(status: boolean) {
    log(`updateNeedReturnHomePage ${status} `, LOG_TYPE.INFO, LOG_MODULE.UI)

    this.needReturnHomePage = status
    this.isRtcConnected = false
    clearTimeout(this.joinRtcTimer)
  }

  private returnToHome(isError: boolean, msg: string, paramsKey?: string, paramsValue?: string) {
    if ((window.location.href).indexOf('meeting') !== -1) {
      log(`return to home ${msg}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)

      this.updateNeedReturnHomePage(true)

      this.rootStore.notification.addAlert(isError, msg, paramsKey, paramsValue)
    }
  }

  private alertError(err: number, msg?: string) {
    log(`toast error ${err} ${msg} ${alertErrorMap[err]}`, LOG_TYPE.ERROR, LOG_MODULE.UI)

    if (alertErrorMap[err]) {
      this.rootStore.notification.addAlert(true, alertErrorMap[err])
    } else if (msg) {
      this.rootStore.notification.addAlert(true, msg)
    }
  }

  private isInRoom() {
    return this.roomState !== RoomState.ROOM_STATE_DISCONNECT
  }

  /****************************** DEBUG *****************************/

  @observable
  public testDisconnectRTM: boolean = false

  @action
  public debugDisconnectBiz(enable: boolean) {
    this.testDisconnectRTM = enable

    this.rootStore.rtmEngineLayer.testLogout(enable)
  }

  @observable
  public testShowLocalDownNetworkWeakTip: boolean = false
  @observable
  public testShowRemoteUpNetworkWeakTip: boolean = false
  @observable
  public testShowLocalUpNetworkWeakTip: boolean = false
  @observable
  public testShowRemoteDownNetworkWeakTip: boolean = false
  private showNetworkWeakTipTimer: number = 0

  @action
  public debugSetNetworkWeak(show: boolean, num: QualityType) {
    switch (num) {
      case QualityType.LOCAL_NETWORK_DOWN_WEAK:
        this.testShowLocalDownNetworkWeakTip = !show

        if (this.testShowLocalDownNetworkWeakTip) {
          this.showNetworkWeakTipTimer = window.setInterval(() => {
            this.networkEval.lastQualityType = QualityType.LOCAL_NETWORK_DOWN_WEAK
          }, 10)
        } else {
          if (this.showNetworkWeakTipTimer > 0) clearInterval(this.showNetworkWeakTipTimer)
        }
        break

      case QualityType.REMOTE_NETWORK_UP_WEAK:
        this.testShowRemoteUpNetworkWeakTip = !show

        if (this.testShowRemoteUpNetworkWeakTip) {
          this.showNetworkWeakTipTimer = window.setInterval(() => {
            this.networkEval.lastQualityType = QualityType.REMOTE_NETWORK_UP_WEAK
          }, 10)
        } else {
          if (this.showNetworkWeakTipTimer > 0) clearInterval(this.showNetworkWeakTipTimer)
        }
        break

      case QualityType.LOCAL_NETWORK_UP_WEAK:
        this.testShowLocalUpNetworkWeakTip = !show

        if (this.testShowLocalUpNetworkWeakTip) {
          this.showNetworkWeakTipTimer = window.setInterval(() => {
            this.networkEval.lastQualityType = QualityType.LOCAL_NETWORK_UP_WEAK
          }, 10)
        } else {
          if (this.showNetworkWeakTipTimer > 0) clearInterval(this.showNetworkWeakTipTimer)
        }
        break

      case QualityType.REMOTE_NETWORK_DOWN_WEAK:
        this.testShowRemoteDownNetworkWeakTip = !show

        if (this.testShowRemoteDownNetworkWeakTip) {
          this.showNetworkWeakTipTimer = window.setInterval(() => {
            this.networkEval.lastQualityType = QualityType.REMOTE_NETWORK_DOWN_WEAK
          }, 10)
        } else {
          if (this.showNetworkWeakTipTimer > 0) clearInterval(this.showNetworkWeakTipTimer)
        }
        break
      case QualityType.SHOW_QUALITY_Feedback:
        this.isOpenQualityFeedback = !show;
        break;
    }
  }

  @observable
  public testNeedShowQualityLog: boolean = showQualityLog()

  @action
  public debugSetShowNetworkQualityLog(show: boolean) {
    this.testNeedShowQualityLog = !show

    setShowQualityLog(this.testNeedShowQualityLog)
  }

  @action
  public debugAssistantAudioVolume(show: boolean) {
    this.isShowAssistantAudioVolume = !show
  }
  

  @observable
  public testLoginQA: boolean = false

  @action
  public async debugLoginWechatQA(enable: boolean) {
    this.testLoginQA = !enable

    const testQAUser = {
      name: "周俊捷",
      innerName: "周俊捷",
      alias: "Zhoujunjie",
      department: "后端业务测试（QA of Cloud Services）",
      innerSession: "emhvdWp1bmppZTg4NDU6MTYwNjIxMDAzNw==",
      uid: this.rootStore.user.getUid(),
    }

    if (this.testLoginQA) {
      await loginByWeChatWorkForTest(testQAUser)
      // work-around, 接口新增了 innerName 设置用户内部会议名字
      testQAUser.name = testQAUser.innerName
      this.rootStore.user.updateWeChatWorkUser(true, testQAUser)
    } else {
      await logoutByWeChatWork(testQAUser.innerSession)
      this.rootStore.user.clearWeChatWorkUser()
      this.rootStore.uiStore.changeModeToNormal()
    }
  }
  public setVolume(volume: number) {
    this.rootStore.rtcCommLayer.setTestVolume(volume);

  }
}
