import { Box, IconButton, createStyles, makeStyles, Theme, useTheme, Tooltip } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useCommManagerStore, useUserStore } from "../../stores/hooks";
import { log, LOG_MODULE, LOG_TYPE } from "../../utils/Log";
import { ReactComponent as IconCloudRecord } from "../../assets/icon_cloud_record.svg";
import { useStyles as useRoomControlSytles } from "../RoomController/style"
import { CustomPop } from "../CustomPopup/customPop";
import { Loading } from "../../components/Loading"
import { exceedSafari } from "../../utils/userAgentDetector"

const useStyles = makeStyles((theme: Theme) => {
  const { palette: { primary: { dark, light } } } = theme
  return createStyles({
    cloudRecordingContainer: {
      marginTop: '0.05rem',
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: '0.4rem',
      paddingLeft: '0.4rem',
      paddingRight: '0.4rem',
      '& > .MuiIconButton-root': {
        width: exceedSafari(14) ? '2.25rem' : '',
        height: exceedSafari(14) ? '2.25rem' : '',
      }
    },
    iconBack: {
      '&:active': {
        backgroundColor: dark,
      },
      '&:hover': {
        backgroundColor: light,
        fill: dark
      },
      '&:hover svg': {
        fill: dark,
      }
    },
  })
})

const CloudRecordingView = observer(() => {
  const [t] = useTranslation()
  const styles = useStyles()
  const classes = useRoomControlSytles()

  const commManager = useCommManagerStore()
  const userStore = useUserStore()
  const theme: Theme = useTheme()
  const [isShowPop, setShowPop] = React.useState(false)
  const [popContent, setPopContent] = React.useState('')
  const [isPending, setPending] = React.useState(false)
  const [popTitle, setPopTitle] = React.useState('')
  const popoverAnchor = useRef(null)
  const [isShowPopButton, setShowPopButton] = React.useState(true)
  const [isShowCancelButton, setShowPopCancelButton] = React.useState(true)

  const showCustomPop = () => {
    setShowPop(true)
    setShowPopButton(true)
    setShowPopCancelButton(true)
    if (commManager.localCloudRecordingIconState) {
      setPopTitle(t('ConfirmEndCloudRecordingText'))
      setPopContent(t('EndCopyLink'))
    } else {
      setPopContent(t('cloud_recording_start_reminder'))
    }
  }
  useEffect(() => {
    if (isPending) {
      changePendingContent()
    } else if (!commManager.localCloudRecordingIconState && commManager.localCloudRecordingFailReason) {
      setPopTitle('')
      setPopContent(t(commManager.localCloudRecordingFailReason))
      setShowPop(true)
      setShowPopButton(false)
      commManager.setLocalCloudRecordingFailReason('')
    }
  }, [commManager.localCloudRecordingIconState, commManager.localCloudRecordingFailReason])

  const changePendingContent = () => {
    setPending(false)
    setShowPop(true)
    if (commManager.localCloudRecordingIconState) {
      setShowPopCancelButton(true);
      setPopTitle(t("EndCloudRecordingFailed"));
      setPopContent(t("checkCloudRecordingNetwork"));
    } else {
      setShowPopButton(false)
      setPopContent(t(commManager.localCloudRecordingFailReason))
    }
  }

  const onPopClose = () => {
    setShowPop(false)
  }

  const onConfirm = async () => {
    setShowPop(false)
    if (commManager.localCloudRecordingIconState) {
      setPending(true)
      await commManager.stopCloudRecording()
    } else {
      await commManager.startCloudRecording()
    }
  }
  return (
    <div>
      {commManager.isRoomAgora && userStore.isThirdPartyLoggedIn &&
        <Tooltip title={<Box >{t('CloudRecording')}</Box>}>
          <div className={styles.item} ref={popoverAnchor}>
          <IconButton className={`${!commManager.localCloudRecordingIconState || isPending ? classes.iconButton : classes.iconBackgroundActive}`}
            id="cloud-record" onClick={isPending ? () => null : showCustomPop}>
              {/* id="cloud-record" onClick={() => !commManager.localCloudRecordingIconState ? setStartRecordDialogOpen(true) : setEndRecordDialogOpen(true)}> */}
              {isPending ? <Loading /> : <IconCloudRecord className={classes.iconButton} />}
            </IconButton>
          </div>

        </Tooltip>
      }
      <CustomPop
        isOpen={isShowPop}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: "center"
        }}
        title={popTitle}
        content={popContent}
        anchorEl={popoverAnchor.current}
        onClose={onPopClose}
        isShowCancelButton={isShowCancelButton}
        isShowButton={isShowPopButton}
        isNeedTriangle={true}
        trianglePosition="bottom"
        marginThreshold={87}
        onConfirm={isShowCancelButton ? onConfirm : onPopClose}
        onCancel={onPopClose}
        style={{ width: 'auto' }}
        buttonGroupStyle={{ justifyContent: 'flex-end', marginTop: '0.75rem' }}
        buttonStyle={{ width: '3.25rem', height: '1.5rem', fontSize: '0.75rem', fontWeight: 600 }}
      />
    </div>
  )
})

export default CloudRecordingView
