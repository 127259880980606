import { Box, Typography, Grid, Hidden, IconButton, } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/icon_logo.svg";
import DownloadLine from "../../components/Download/Download";
import GDPRDialog from "../../components/GDPR/GDPRTermsView";
import JoinRoomForm, { FORM_MODE, FORM_NICKNAME, FORM_PWD, FORM_RID } from "../../components/JoinRoomForm/JoinRoomForm";
import QualityFeedbackOutsideContainer from "../../components/QualityFeedback/QualityFeedbackOutsideContainer";
import RequestLoginDialog from '../../components/RequestLoginDialog';
import UserSettingView from "../../components/UserSetting/UserSettingView";
import {
  checkWeChatLoginStatus, getAccessToken,
  getConfig,
  getToken, loginByWeChatWork,
  updateAccessToken,
  updateNickname
} from "../../stores/connector/http";
import { useCommManagerStore, useNotificationStore, useUIStore, useUserStore } from "../../stores/hooks";
import { APP_SUB_PATH, ENCRYPTION_MODE, ERR_WECHAT_LOGIN_EXPIRED, ERR_WECHAT_LOGIN_INVALID } from "../../utils/constants";
import { log, logException, LOG_MODULE, LOG_TYPE } from "../../utils/Log";
import { isWX } from "../../utils/userAgentDetector";
import { useStyles } from "./style";
import { ReactComponent as IconAccountViewNormal } from "../../assets/icon_aboutus_account_normal.svg";
import { ReactComponent as IconAccountViewAgora } from "../../assets/icon_aboutus_account_agora.svg";
import { ReactComponent as IconSettings } from "../../assets/icon_settings.svg";

import defaultRoomBannerLeftLight from "../../assets/banner_left_default_blue_light.svg"
import defaultRoomBannerLeftDark from "../../assets/banner_left_default_blue_dark.svg"
import defaultRoomBannerRightLight from "../../assets/banner_right_default_blue_light.svg"
import defaultRoomBannerRightDark from "../../assets/banner_right_default_blue_dark.svg"

import agoraRoomBannerLeftLight from "../../assets/banner_left_agora_light.svg"
import agoraRoomBannerLeftDark from "../../assets/banner_left_agora_dark.svg"
import agoraRoomBannerRightLight from "../../assets/banner_right_agora_light.svg"
import agoraRoomBannerRightDark from "../../assets/banner_right_agora_dark.svg"

import defaultRoomLightCn from "../../assets/default_door_light_cn.svg";
import defaultRoomDarkCn from "../../assets/default_door_dark_cn.svg";
import agoraRoomLightCn from "../../assets/agora_door_light_cn.svg";
import agoraRoomDarkCn from "../../assets/agora_door_dark_cn.svg";
import defaultRoomLightEn  from "../../assets/default_door_light_en.svg";
import defaultRoomDarkEn  from "../../assets/default_door_dark_en.svg";
import agoraRoomLightEn  from "../../assets/agora_door_light_en.svg";
import agoraRoomDarkEn  from "../../assets/agora_door_dark_en.svg";
import { isChinese } from "../../utils/helper";
import { Height } from "@material-ui/icons";

const defaultRoomLightDoor = isChinese() ? defaultRoomLightCn : defaultRoomLightEn;
const defaultRoomDarkDoor = isChinese() ? defaultRoomDarkCn : defaultRoomDarkEn;
const agoraRoomLightDoor = isChinese() ? agoraRoomLightCn : agoraRoomLightEn;
const agoraRoomDarkDoor = isChinese() ? agoraRoomDarkCn : agoraRoomDarkEn;

let timer: any;
const HomePage = observer((props) => {
  const userStore = useUserStore();
  const notification = useNotificationStore()
  const uiStore = useUIStore()
  const commManager = useCommManagerStore()
  const user = userStore.user
  const history = useHistory()
  const theme = useTheme()
  const styles = useStyles()

  const [loading, setLoading] = useState(false)
  const [termsOpen, setTermsOpen] = useState(false)
  const [settingOpen, setSettingOpen] = useState(false)
  const token = getAccessToken()
  const [registered, setRegistered] = useState(token !== undefined && token !== '' && token !== null)
  const isDark = theme.palette.type === "dark";
  const defaultRoomBannerLeft = isDark ? defaultRoomBannerLeftDark : defaultRoomBannerLeftLight;
  const defaultRoomBannerRight = isDark ? defaultRoomBannerRightDark : defaultRoomBannerRightLight;
  const agoraRoomBannerLeft = isDark ? agoraRoomBannerLeftDark : agoraRoomBannerLeftLight;
  const agoraRoomBannerRight = isDark ? agoraRoomBannerRightDark : agoraRoomBannerRightLight;
  useEffect(() => {
    if (window.location.pathname.indexOf('login') > 0) {
      thirdPartyLogin()
      history.push(`${APP_SUB_PATH}/`)
    }
  }, [])

  useEffect(() => {
    log(`regiserterd: ${registered}`, LOG_TYPE.INFO, LOG_MODULE.UI)

    if (isWX()) {
      notification.addAlert(true, 'DesktopOpenWithBrowser')
    }

    if (!registered) {
      // avoid warning
      const registerUser = (name?: string) => {
        log(`registerUser`, LOG_TYPE.INFO, LOG_MODULE.COMM)

        getToken(name).then((res: any) => {
          if (res.data) {
            log(`registerUser success`, LOG_TYPE.INFO, LOG_MODULE.COMM)

            const { uid, name, streamId, token } = res.data;

            userStore.updateLocalUser({ uid, name, streamId })

            updateAccessToken(token)

            setRegistered(true)
          }
        }).catch((e: any) => {
          // notification.toastError('RegisterUserFailure')

          setTimeout(() => {
            registerUser(user.name || '')
          }, 3000)
        })
      }

      registerUser(user.name || '')
    }

    userStore.clearMeetingRecord()

    getConfig().then((res: any) => {
      if (!res.data) return

      const { app: { needUploadLog = true, encryptionMode = ENCRYPTION_MODE.NONE, enableWatermark = false } = {}, valoran: { maxRequestTimeout = 10, businessTimeout = 15 } = {} } = res.data

      if (needUploadLog) commManager.uploadLogForAtlas()
      commManager.updateWatermarkConfig(enableWatermark)

      userStore.updateConfig(encryptionMode !== ENCRYPTION_MODE.NONE, maxRequestTimeout * 1000, businessTimeout * 1000, enableWatermark)
    }).catch(_ => _)

    /***** Check WeChat Login */
    const checkThirdPartyLoginStatus = async () => {
      try {
        const session = user.getThirdPartySession()
        const res = await checkWeChatLoginStatus(session)

        if (session && res.data) {
          userStore.updateWeChatWorkUser(false, res.data)
        }
      } catch (error) {
        if (error.code === ERR_WECHAT_LOGIN_INVALID || error.code === ERR_WECHAT_LOGIN_EXPIRED) {
          notification.addAlert(true, 'UserNotAgoranAnymore')

          userStore.clearWeChatWorkUser()

          if (!uiStore.isHomeModeNormal) {
            uiStore.changeModeToNormal()
            props.onChangeMode(false)
          }

          if (!uiStore.isRoomModeNormal) {
            commManager.checkAndForceReturnHome()
          }
        }
      }
    }

    if (registered) {
      // 如果没有注册，则无需同步第三方登录信息（!!!如果没有注册还调用此方法，会导致返回400，清除getToken刚刚拿到的token）
      // 退出登录是客户端清除缓存的，
      checkThirdPartyLoginStatus()
    }
  }, []);

  const acceptGDPR = () => {
    userStore.updateGdprAccepted(true)

    setTermsOpen(false)
  }

  const thirdPartyLogin = () => {
    const params = (new URL(window.location.href)).searchParams
    let code = params.get("code")
    if (!code) {
      loginResultByWeChatWork(false, null, 0, "wechat code null")
    } else {
      loginByWeChatWork(code).then((res: any) => {
        log(`loginByWeChatWork success ${res}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

        if (res.data) {
          loginResultByWeChatWork(true, res.data)
        } else {
          loginResultByWeChatWork(false, null, 0, "response data null")
        }
      }).catch((e: any) => {
        loginResultByWeChatWork(false, null, e.code, e.desc)
      })
    }
  }

  const joinRoom = async (data: any) => {
    setLoading(true)

    userStore.setMeetingRecord(data[FORM_RID], data[FORM_PWD], data[FORM_MODE])

    const nickName = data[FORM_NICKNAME]
    if (nickName && nickName !== user.name) {
      userStore.updateUser({ name: nickName })
      try {
        await updateNickname(nickName)
      } catch (e) {
        logException(`update account failure, code: ${e.code}, msg: ${e.desc}`, e, LOG_MODULE.HTTP)

        notification.addAlert(true, 'UpdateNickNameFailure')
      }
    }

    setLoading(false)

    history.push(`${APP_SUB_PATH}/meeting`)
  }

  const loginResultByWeChatWork = (isSuccess: boolean, data?: any, code?: number, desc?: string) => {
    log(`login wechat result, ${isSuccess} ${data} ${code} ${desc}`, LOG_TYPE.INFO)

    if (isSuccess) {
      userStore.updateWeChatWorkUser(true, data)
    } else {
      // TODO 5.0 TEST 外部企业微信账号扫码的
      notification.addAlert(true, 'LoginFailure')
    }
  }
  const changeRoomType = () => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      if (uiStore.isHomeModeNormal) {
        uiStore.changeModeToAgora()
        props.onChangeMode(true)
        return
      }
      uiStore.changeModeToNormal();
      props.onChangeMode(false)
    }, 100);

  }

  const doorBackground = () => {
    if (userStore.isThirdPartyLoggedIn) {
      return uiStore.isHomeModeNormal ? <img src={isDark ? defaultRoomDarkDoor : defaultRoomLightDoor} className={styles.doorBackground} />
        : <img src={isDark ? agoraRoomDarkDoor : agoraRoomLightDoor} className={styles.doorBackground} />
    }
  }
  return (
    <div className={`${styles.container}`}>
      <GDPRDialog open={!user.gdprAccepted || termsOpen} onOk={acceptGDPR} />
      <QualityFeedbackOutsideContainer />
      <RequestLoginDialog openSetting={() => setSettingOpen(true)} />
      <Box className={`${styles.gridContainer} `}>
        <img src={!uiStore.isHomeModeNormal ? agoraRoomBannerLeft : defaultRoomBannerLeft} className={styles.bannerLeft} />
        {/* <Box className={`${styles.joinRoom} ${door()}`}> */}
        <Box className={`${styles.doorRoomContent}`}>
        {doorBackground()}
        <Box className={`${styles.joinRoom}  ${!userStore.isThirdPartyLoggedIn ? styles.joinRoomContent : null}`}>
            <Box className={styles.noticeGroup}>
              <Typography variant="h4">特殊版：VP9, AV1 功能打磨</Typography>
              <Typography variant="h4">特殊版联系人：孟永富</Typography>
              <Typography variant="h4">备注：纯 Web 使用场景，存在和 Native 互通黑屏及浏览器限制问题</Typography>
            </Box>
            <Box className={`${userStore.isThirdPartyLoggedIn ? styles.line : styles.settingContent}`}>
            <Box className={`${styles.subLinLoggedIn} ${styles.userText}`}>
              {userStore.isThirdPartyLoggedIn && (
                <Box className={styles.subLinLoggedInContent}>
                  {
                    uiStore.isHomeModeNormal ?
                      <IconAccountViewNormal style={{ marginLeft: '0.7rem' }} width={'11px'} height={'11px'} />
                      :
                      <IconAccountViewAgora style={{ marginLeft: '0.7rem' }} width={'11px'} height={'11px'} />
                  }
                  <Typography variant="h4" id="agora-room-inner-name" className={styles.agoraName} style={{ color: theme.palette.primary.main, paddingLeft: '0.375rem' }}>
                    {user.thirdPartyUserName}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box id="icon-user-setting" className={styles.subLine}>
              <IconButton className={theme.palette.type === 'dark' ? styles.settingButtonDark : styles.settingButton} onClick={() => setSettingOpen(true)}>
                <IconSettings className={styles.icon} fill={theme.palette.primary.main} />
              </IconButton>
            </Box>
          </Box>
            <Box className={`${userStore.isThirdPartyLoggedIn ? styles.product : styles.logoView}`}>
            <Logo className={styles.logo} fill={theme.palette.primary.main} />
            <Typography className={`${styles.productTitle} notranslate`} variant="h2">Agora Video Call</Typography>
          </Box>
          <Typography className={`${styles.noticeTitle} notranslate`} variant="h3">仅供 Agora 内部使用，不得外传；如有泄密，后果自负。</Typography>
            <Box className={styles.numberPlateOccupied}>
              {userStore.isThirdPartyLoggedIn ?
                <div id="normal-mode-agora-active"
                  className={`${styles.modeSwitchAction} ${!uiStore.isHomeModeNormal ? styles.agoraAction : null}`}
                  onClick={userStore.isThirdPartyLoggedIn ? changeRoomType : () => null} /> :
                <div style={{ height: '4.875rem' }} />
              }
            </Box>
            {userStore.isThirdPartyLoggedIn &&
              <div className={`${uiStore.isHomeModeNormal ? styles.handle : styles.agoraHandle}`}
                onClick={userStore.isThirdPartyLoggedIn ? changeRoomType : () => null} />
            }

            <Box className={`${userStore.isThirdPartyLoggedIn ? styles.formContainer : styles.defaultFormContainer} `}>
              <JoinRoomForm onChangeMode={props.onChangeMode} joinRoom={joinRoom} loading={loading} ready={registered} />
          </Box>
        </Box>
        </Box>

        <Box className={styles.bannerRight}>
          <img src={!uiStore.isHomeModeNormal ? agoraRoomBannerRight : defaultRoomBannerRight} className={styles.bannerRightImg} />
          <DownloadLine />
        </Box>
      </Box>

      <Box className={styles.footer} />
      <UserSettingView roomState={false} open={settingOpen} setOpen={setSettingOpen} onChangeMode={props.onChangeMode} />
    </div>
  )
})

export default HomePage
