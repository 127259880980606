import React, { forwardRef, Ref } from 'react';
import { Box, Typography, FormControlLabel, Checkbox } from '@material-ui/core'
import { observer } from 'mobx-react'
import { useCommManagerStore } from "../../stores/hooks";
import { useTranslation } from 'react-i18next';
import useStyle from "./style";
import i18n from "i18next";
interface IProps {
  onChangeMode?: (mode: boolean) => void
}
const DeveloperView = observer(forwardRef((props:IProps , ref: Ref<HTMLDivElement>) => {
  const styles = useStyle()
  const commManager = useCommManagerStore()
  const [t] = useTranslation()

  const debugNetworkWeakDataList = [
    { text: '本地下行网络弱', checked: commManager.testShowLocalDownNetworkWeakTip, handleChange: (event: any) => { commManager.debugSetNetworkWeak(!event.target.checked, 2) } },
    { text: '远端上行网络弱', checked: commManager.testShowRemoteUpNetworkWeakTip, handleChange: (event: any) => { commManager.debugSetNetworkWeak(!event.target.checked, 3) } },
    { text: '本地上行网络弱', checked: commManager.testShowLocalUpNetworkWeakTip, handleChange: (event: any) => { commManager.debugSetNetworkWeak(!event.target.checked, 4) } },
    { text: '远端下行网络弱', checked: commManager.testShowRemoteDownNetworkWeakTip, handleChange: (event: any) => { commManager.debugSetNetworkWeak(!event.target.checked, 5) } },
  ]
// need to change the theme color when log out by deselecting internal login
  const debuggerLogin = (checked: any) => {
    commManager.debugLoginWechatQA(!checked)
    if (!checked) {
      props.onChangeMode && props.onChangeMode(checked)
    }
  }
  return (
    <div className={styles.contentBox} ref={ref} id="developer">
      <Typography variant="h3">{t('Developer')}</Typography>
      <Box className={styles.control} width={i18n.language === 'zh-CN' ? '4.125rem' : '5.125rem'}>
        <FormControlLabel
          id="debug-rtm"
          classes={{ root: styles.labelControl, labelPlacementStart: styles.labelPlacementStart, label: styles.label }}
          control={
            <Checkbox
              color="primary"
              checked={commManager.testDisconnectRTM}
              onChange={(event) => commManager.debugDisconnectBiz(event.target.checked)}
              name="debugRTM"
              icon={<span className={styles.checkBox} />}
              checkedIcon={<span className={styles.checkedBox} />}
            />
          }
          label={<Typography variant="subtitle1">{t('Disconnect')}</Typography>}
          labelPlacement="start"
        />
        <FormControlLabel
          id="debug-login"
          classes={{ root: styles.labelControl, labelPlacementStart: styles.labelPlacementStart, label: styles.label }}
          control={
            <Checkbox
              color="primary"
              checked={commManager.testLoginQA}
              onChange={(event) => { debuggerLogin(event.target.checked) }}
              name="debugLogin"
              icon={<span className={styles.checkBox} />}
              checkedIcon={<span className={styles.checkedBox} />}
            />
          }
          label={<Typography variant="subtitle1">{t('Log in')}</Typography>}
          labelPlacement="start"
        />
        <FormControlLabel
          id="debug-video-rate"
          classes={{ root: styles.labelControl, labelPlacementStart: styles.labelPlacementStart, label: styles.label }}
          control={
            <Checkbox
              color="primary"
              checked={commManager.isOpenQualityFeedback}
              onChange={(event) => {commManager.debugSetNetworkWeak(!event.target.checked, 20)}}
              name="debug-video-rate"
              icon={<span className={styles.checkBox} />}
              checkedIcon={<span className={styles.checkedBox} />}
            />
          }
          label={<Typography variant="subtitle1">{t('Video rate')}</Typography>}
          labelPlacement="start"
        />
        <FormControlLabel
          id="debug-audio-volume"
          classes={{ root: styles.labelControl, labelPlacementStart: styles.labelPlacementStart, label: styles.label }}
          control={
            <Checkbox
              color="primary"
              checked={commManager.isShowAssistantAudioVolume}
              onChange={(event) => { commManager.debugAssistantAudioVolume(!event.target.checked) }}
              name="debug-audio-volume"
              icon={<span className={styles.checkBox} />}
              checkedIcon={<span className={styles.checkedBox} />}
            />
          }
          label={<Typography variant="subtitle1">会议助手音量测试</Typography>}
          labelPlacement="start"
        />
        <FormControlLabel
          id="debug-network-quality"
          classes={{ root: styles.labelControl, labelPlacementStart: styles.labelPlacementStart, label: styles.label }}
          control={
            <Checkbox
              color="primary"
              checked={commManager.testNeedShowQualityLog}
              onChange={(event) => {commManager.debugSetShowNetworkQualityLog(!event.target.checked)}}
              name="debug-network-quality"
              icon={<span className={styles.checkBox} />}
              checkedIcon={<span className={styles.checkedBox} />}
            />
          }
          label={<Typography variant="subtitle1">{t('ShowNetworkQuality')}</Typography>}
          labelPlacement="start"
        />
        {
          debugNetworkWeakDataList.map((item, index) => {
            return (<FormControlLabel
              key={`debug-network-toast-${index}`}
              id={`debug-network-toast-${index}`}
              classes={{ root: styles.labelControl, labelPlacementStart: styles.labelPlacementStart, label: styles.label }}
              control={
                <Checkbox
                  color="primary"
                  checked={item.checked}
                  onChange={item.handleChange}
                  name={`debug-network-toast-${index}`}
                  icon={<span className={styles.checkBox} />}
                  checkedIcon={<span className={styles.checkedBox} />}
                />
              }
              label={<Typography variant="subtitle1">{item.text}</Typography>}
              labelPlacement="start"
              /> )
          })
        }
      </Box>
    </div>
  )
}))

export default DeveloperView
