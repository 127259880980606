
export const debounce = (fn: any, delay: number) => {
  let timer: any = null
  return () => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(fn, delay)
  }
}

export function throttle(func: any, wait: number, options?: any) {
  let timeout: any;
  let context: null;
  let previous = 0;
  let args: any;
  // tslint:disable-next-line: no-parameter-reassignment
  if (!options) options = {};
  // tslint:disable-next-line: only-arrow-functions
  const later = function () {
    previous = options.leading === false ? 0 : new Date().getTime();
    timeout = null;
    func.apply(context, args);
    if (!timeout) context = args = null;
  };

  const throttled = function (this: any) {
    const now = new Date().getTime();
    if (!previous && options.leading === false) previous = now;
    const remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
  };
  // tslint:disable-next-line: only-arrow-functions
  throttled.cancel = function () {
    clearTimeout(timeout);
    previous = 0;
    timeout = null;
  };

  return throttled;
}

export function getRtcEncryptionKey(rid: string): string {
  let key = ''
  for (let i = 0; i < rid.length; i++) {
    let ch = Math.abs(rid.charCodeAt(i) - rid.charCodeAt(rid.length - 1 - i))
    if (ch === 0) {
      ch = 'A'.charCodeAt(0) + i
    }
    key += String.fromCharCode(ch)
  }
  if (rid.length < 8) {
    key += rid
  }
  return btoa(key)
}

const AES_GCM_IV_LEN = 12
const AES_GCM_TAG_LEN = 16
const AES_GCM_KEY_LEN = 16
const AES_GCM_DECRYPTED_MIN_LEN = AES_GCM_IV_LEN + AES_GCM_TAG_LEN

export async function Aes128GcmDecrypt(data: Uint8Array, key: CryptoKey): Promise<Uint8Array> {
  if (data.length <= AES_GCM_DECRYPTED_MIN_LEN) {
    throw new Error(`data length: ${data.length} invalid`)
  }

  const iv = data.slice(0, AES_GCM_IV_LEN)
  const text = data.slice(AES_GCM_IV_LEN)

  try {
    const buffer = await window.crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: iv,
        tagLength: AES_GCM_TAG_LEN * 8// 8bits
      },
      key,
      text
    )

    return new Uint8Array(buffer)
  } catch (error) {
    throw error
  }
}

export async function Aes128GcmEncrypt(data: Uint8Array, key: CryptoKey): Promise<Uint8Array> {
  if (data.length === 0) {
    throw new Error(`data length 0`)
  }

  const iv = window.crypto.getRandomValues(new Uint8Array(AES_GCM_IV_LEN));
  const ciphertext = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv
    },
    key,
    data
  );

  const cipherTextUint8 = new Uint8Array(ciphertext)
  const combined = new Uint8Array(iv.length + cipherTextUint8.length)
  combined.set(iv, 0)
  combined.set(cipherTextUint8, iv.length)
  return combined
}

export async function GenerateAes128GcmCryptoKey(key: string) {
  if (key.length === 0) {
    throw new Error('Invalid input key')
  }

  let modifiedKey = key
  while (modifiedKey.length < AES_GCM_KEY_LEN) {
    modifiedKey += key
  }

  const keyUint8Array = new TextEncoder().encode(modifiedKey.slice(0, AES_GCM_KEY_LEN))

  return await window.crypto.subtle.importKey(
    "raw",
    keyUint8Array,
    "AES-GCM",
    true,
    ["encrypt", "decrypt"]
  )
}

export function  base64ToUint8Array(base64String:string) {
  const  padding = '='.repeat((4 - base64String.length % 4) % 4);
  const  base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
