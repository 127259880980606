import React, { useEffect, useRef } from "react";
import { useRtcCommLayerStore, useUserStore, } from "../stores/hooks";
import { log, LOG_MODULE, LOG_TYPE } from "../utils/Log";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    videoContainer: {
      width: "100%",
      height: "100%",
      borderRadius: '0.75rem',
      "&>div": {
        borderRadius: '.75rem',
        background:'none !important'
      }
    },
    watermarkBox: {
      position: 'absolute',
      overflow: 'hidden',
      width: '100%',
      height: '100%',
      zIndex: 100,
      pointerEvents: 'none',
    },
    videoWatermark: {
      width: "400%",
      height: "400%",
      position: 'absolute',
      transform: 'rotate(-20deg) translate(0%, -50%)',
    },
    videoWatermarkSmall: {
      backgroundSize: "18%",
    },
  })
})
export interface VideoPlayerProps {
  streamId: number;
  isFit?: boolean;
  isShareVideo?: boolean;
  isNeedWatermark?: boolean;
  isSmallVideo?: boolean;
}

const VideoPlayer = (props: VideoPlayerProps) => {
  const container = useRef<HTMLDivElement>(null);
  const watermark = useRef<HTMLDivElement>(null);
  const rtcComm = useRtcCommLayerStore()
  const userStore = useUserStore()
  const user = userStore.user
  const styles = useStyles()

  const createWatermark = () => {
    const watermarkText = user.thirdPartyUserName || userStore.getName()
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = 180
    canvas.height = 100
    // 字体大小
    const fontSize = 13
    // 坐标
    const x = 0
    const y = 20
    // 旋转角度
    // const angle = -20
    if (context) {
      context.save()
      // 透明度
      context.globalAlpha = 0.7
      context.translate(x, y + fontSize)
      // 字体
      const fontFamily = context.font.split(' ')[1]
      context.font = `${fontSize}px ${fontFamily}`
      // 旋转角度
      // context.rotate(Math.PI / 180 * angle)
      // 写字
      context.fillStyle = '#D8D8D8'
      context.fillText(watermarkText, x, y + fontSize)
      context.restore()
      // 导出图片
      if (watermark.current) {
        let imageUrl = canvas.toDataURL("image/jpg", 0.1)
        watermark.current.style.backgroundImage = "url(" + imageUrl + ")"
        log(`VideoPlayer add watermark in ${props.isSmallVideo ? 'user item video' : 'big stream'}, stream id: ${props.streamId}`, LOG_TYPE.INFO, LOG_MODULE.UI)
      }
    }
  }

  useEffect(() => {
    if (!container.current) return;

    if (container.current) {
      log(`VideoPlayer play stream id: ${props.streamId}`, LOG_TYPE.INFO, LOG_MODULE.UI)

      rtcComm.startPlayVideo(props.streamId, props.isFit !== undefined ? props.isFit : true, container.current)

      props.isNeedWatermark && props.isShareVideo && createWatermark()
    }

    return () => {
      log(`VideoPlayer stop play stream id: ${props.streamId}`, LOG_TYPE.INFO, LOG_MODULE.UI)

      rtcComm.stopPlayVideo(props.streamId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container.current, props.streamId]);

  return (
    <>
      <div id="share-screen-watermark" className={styles.watermarkBox}>
        <div ref={watermark} className={`${styles.videoWatermark} ${props.isSmallVideo && styles.videoWatermarkSmall}`}></div>
      </div>
      <div ref={container} className={styles.videoContainer} />
    </>
  );
}

export default VideoPlayer;
