import { action, computed, observable } from "mobx";
import { BizUser } from "../types/bizUser";
import { AttendeePriority, CommUser, MATCH_FULL_NAME, MATCH_HELP_NAME, MATCH_HELP_NAME_PINYIN, MATCH_NAME, MATCH_NAME_PINYIN, MATCH_NONE, MATCH_THIRDPARTY_ALIAS, MATCH_THIRDPARTY_ALIAS_PINYIN, MATCH_THIRDPARTY_NAME, MATCH_THIRDPARTY_NAME_PINYIN } from "../types/commUser";
import { MediaUser } from "../types/mediaUser";
import ThirdPartyUser from "../types/thirdPartyUser";
import { hasReason, REASON_ATTENDEE_GROUP_CONCERN, REASON_MEDIA_GROUP_CONCERN, ROOM_MODE, UserChangedReason, VOLUME } from "../utils/constants";
import { log, LOG_MODULE, LOG_TYPE } from "../utils/Log";
import { CommManagerStore, PendingType } from "./commManager";
import { RtcCommLayerStore } from "./rtc/rtcCommLayer"
import { NetworkEval } from "./model/NetworkEval";
import { isChinese } from "../utils/helper";
import { Assistant } from "@material-ui/icons";

const MAJOR_POS = Number.MAX_VALUE

const IMPORTANT_REASONS = UserChangedReason.REASON_INFO
  | UserChangedReason.REASON_HOST
  | UserChangedReason.REASON_SHARE
  | UserChangedReason.REASON_DUMPING_ISSUE
  | UserChangedReason.REASON_CLOUD_RECORDING
  | UserChangedReason.REASON_AUDIO
  | UserChangedReason.REASON_VIDEO

const hasImportantReason = (reason: number) => {
  return (reason & IMPORTANT_REASONS) !== 0;
}

export enum UserListType {
  TYPE_ATTENDEES_ALL = 0,
  TYPE_ATTENDEES_LOGGED_IN = 1,
  TYPE_ATTENDEES_NON_LOGGED_IN = 2,
}

export class UserManagerStore {

  private commManager: CommManagerStore
  private rtcCommLayer: RtcCommLayerStore

  @computed
  public get attendeesCount() {
    return this.attendeeList.length
  }

  @computed
  public get loggedInUsersCount() {
    return this.loggedInUserList.length
  }

  @computed
  public get nonLoggedInUsersCount() {
    return this.nonLoggedInUserList.length
  }

  @observable
  public attendeeList: CommUser[] = []

  @observable
  public loggedInUserList: CommUser[] = []

  @observable
  public nonLoggedInUserList: CommUser[] = []

  @observable
  public majorUser?: CommUser

  @observable
  public mediaList: CommUser[] = []

  @computed
  public get userCount() {
    return this.attendeeList.length
  }

  public networkEval?: NetworkEval

  private roomMode = ROOM_MODE.MODE_NORMAL
  private selfStreamId = 0
  private isRemoteSharing = false;

  public constructor(commManager: CommManagerStore, rtcCommLayer: RtcCommLayerStore) {
    this.commManager = commManager
    this.rtcCommLayer = rtcCommLayer
  }

  public initState(mode: ROOM_MODE, selfStreamId: number, uid: string, name: string, expectedAudio: boolean, expectedVideo: boolean, thirdparty?: ThirdPartyUser) {
    this.clearState()

    this.roomMode = mode
    this.selfStreamId = selfStreamId
    this.isRemoteSharing = false

    const userMe = new CommUser(mode)
    userMe.setIsMe(true)
    // !!! self can not be erase from attendees_, avoid IsAllLost return true, when rtc connecting or mediaDisconnected or bizDisconnected situation
    userMe.isRtcLost = false
    userMe.updateBasicInfo(selfStreamId, uid, name, expectedAudio, expectedVideo, mode === ROOM_MODE.MODE_AGORA ? thirdparty : undefined)

    this.networkEval?.setIgnoreLocalTx(!userMe.isEntityMediaActive())

    this.addUser(userMe)
  }

  @action
  public clearState() {
    this.attendeeList = []
    this.majorUser = undefined
    this.mediaList = []
    this.loggedInUserList = []
    this.nonLoggedInUserList = []
    this.searchResultUsers = []
    this.lastSearchType = UserListType.TYPE_ATTENDEES_ALL
    this.lastSearchInput = ''
  }

  /********************** Public API: User Media & Biz Event **********************/
  public onMediaUserJoin(mediaUser: MediaUser) {
    log(`onMediaUserJoin stream id: ${mediaUser.streamId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    const pos = this.findUser(mediaUser.streamId)
    if (pos >= 0) {
      log("onMediaUserJoin user already exists, just update", LOG_TYPE.INFO, LOG_MODULE.COMM)

      this.handleUserMediaChanged(this.attendeeList[pos], pos, mediaUser, UserChangedReason.REASON_MEDIA_UPDATE)
    } else {
      const ownerPos = this.findUserByBizShareId(mediaUser.streamId)
      if (ownerPos < 0 || this.attendeeList[ownerPos].isCurrentSharing()) {
        log(`onMediaUserJoin new user, ownerPos: ${ownerPos}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

        this.addUser(new CommUser(this.roomMode, mediaUser))
      } else {
        log("onMediaUserJoin this media is share media", LOG_TYPE.INFO, LOG_MODULE.COMM)

        const owner = this.attendeeList[ownerPos]
        const share = new CommUser(this.roomMode, mediaUser)

        this.userShareStart(owner, share, ownerPos)
      }
    }
  }

  public onMediaUserLeave(mediaUser: MediaUser) {
    log(`onMediaUserLeave stream id: ${mediaUser.streamId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    const pos = this.findUser(mediaUser.streamId)
    if (pos >= 0) {
      const user = this.attendeeList[pos]
      const reason = user.lostRtc()

      if (user.parentStreamId !== 0) {
        const ownerPos = this.findUser(user.parentStreamId)
        if (ownerPos >= 0 && this.attendeeList[ownerPos].mediaShareId === user.streamId) {
          this.attendeeList[ownerPos].updateMediaShareId(0, false)
        }
      }

      if (user.isAllLost()) {
        this.removeUser(user, pos)
      } else {
        this.notifyUserChanged(user, pos,reason)
      }
    } else {
      const ownerPos = this.findUserByShareId(mediaUser.streamId)
      if (ownerPos >= 0) {
        const owner = this.attendeeList[ownerPos]

        this.userShareStop(owner, ownerPos)

        if (owner.isAllLost()) {
          this.findAndRemoveUser(owner)
        }
      } else {
        log(`onMediaUserLeave find no user by stream id: ${mediaUser.streamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      }
    }
  }

  public onMediaUserChanged(mediaUser: MediaUser, reason: number) {
    if (hasImportantReason(reason)) {
      log(`onMediaUserChanged ${mediaUser.description()} reason: ${UserChangedReason[reason]}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
    }

    const pos = this.findUser(mediaUser.streamId)
    if (pos >= 0) {
      if (hasReason(reason, UserChangedReason.REASON_INFO) && mediaUser.parentStreamId !== 0) {
        log("onMediaUserChanged this user is a share stream ", LOG_TYPE.INFO, LOG_MODULE.COMM)
        const share = this.attendeeList[pos]
        share.updateByMediaReason(mediaUser, reason)
        const isWatermark = mediaUser.hasWatermark()
        const ownerPos = this.findUser(mediaUser.parentStreamId)
        if (ownerPos >= 0) {
          const owner = this.attendeeList[ownerPos]
          owner.updateMediaShareId(share.streamId, isWatermark)
          if (owner.isCurrentSharing()) {
            log(`onMediaUserChanged share owner is current sharing, wait it finish`, LOG_TYPE.INFO, LOG_MODULE.COMM)
          } else {
            log(`onMediaUserChanged share owner ready`, LOG_TYPE.INFO, LOG_MODULE.COMM)
            this.userShareStart(owner, share, ownerPos)
          }
        } else {
          log(`onMediaUserChanged create owner by share stream: ${share.description()}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)

          const owner = new CommUser(this.roomMode)
          owner.streamId = mediaUser.parentStreamId
          owner.updateMediaShareId(share.streamId, isWatermark)
          this.userShareStart(owner, share, -1)
          this.addUserToAttendeeGroup(owner)
        }
      } else {
        const targetUser = this.attendeeList[pos]
        this.handleUserMediaChanged(targetUser, pos, mediaUser, reason)
      }
    } else {
      const ownerPos = this.findUserByShareId(mediaUser.streamId)
      if (ownerPos >= 0) {
        const shareUser = this.attendeeList[ownerPos]
        this.handleShareMediaChanged(shareUser, ownerPos, mediaUser, reason)
      } else {
        log(`onMediaUserChanged find no user by stream id: ${mediaUser.streamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      }
    }
  }

  public onBizUserSetup(bizUsers: BizUser[], isComplete: boolean) {
    log(`onBizUserSetup size: ${bizUsers.length} isComplete: ${isComplete}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    this.attendeeList.forEach((item: CommUser) => {
      if (!item.isMe) {
        item.isBizLost = true;
      }
    })

    this.addBizUsers(bizUsers)

    if (isComplete) {
      this.checkAttendeesBizLostUser(false)
    }
  }

  public onBizUsersAppend(bizUsers: BizUser[], isComplete: boolean) {
    log(`onBizUsersAppend size: ${bizUsers.length} isComplete: ${isComplete}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    this.addBizUsers(bizUsers)

    if (isComplete) {
      this.checkAttendeesBizLostUser(false)
    }
  }

  public onBizUsersPatch(bizUsers: BizUser[]) {
    log(`onBizUsersPatch size: ${bizUsers.length}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
    this.addBizUsers(bizUsers)
  }

  public onBizUserJoin(bizUser: BizUser) {
    log(`onBizUserJoin ${bizUser.description()}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    const pos = this.findUser(bizUser.streamId)
    if (pos >= 0) {
      log(`onBizUserJoin user already exists, just update`, LOG_TYPE.INFO, LOG_MODULE.COMM)

      const user = this.attendeeList[pos]

      this.handleUserBizChanged(user, pos, bizUser, UserChangedReason.REASON_BIZ_UPDATE)

      this.checkAndAdjustUserListType(user)
    } else {
      log(`onBizUserJoin new user`, LOG_TYPE.INFO, LOG_MODULE.COMM)

      this.addUserToAttendeeGroup(new CommUser(this.roomMode, undefined, bizUser))
    }
  }

  public onBizUserLeave(bizUser: BizUser) {
    log(`onBizUserLeave ${bizUser.description()}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
    const pos = this.findUser(bizUser.streamId)
    if (pos >= 0) {
      const user = this.attendeeList[pos]

      const reason = user.lostRtm()
      if (user.isAllLost()) {
        this.removeUser(user, pos)
      } else {
        this.notifyUserChanged(user, pos, reason)
      }
    } else {
      log(`onBizUserLeave find no user by stream id: ${bizUser.streamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
    }
  }

  public onBizUserChanged(bizUser: BizUser, reason: number): CommUser | undefined {
    log(`onBizUserChanged ${bizUser.description()} reason: ${UserChangedReason[reason]}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    const pos = this.findUser(bizUser.streamId)
    if (pos >= 0) {
      const user = this.attendeeList[pos]
      this.handleUserBizChanged(this.attendeeList[pos], pos, bizUser, reason)
      return user
    }

    log(`onBizUserChanged find no user by stream id: ${bizUser.streamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
    return undefined
  }
  public onAssistantLeaveAndCancel(uid: string) {
    const pos = this.findUserByUid(uid)
    if (pos >= 0) {
      this.removeUserFromAttendees(pos)
    }
  }

  /********************** Public API: Other logic event **********************/

  public setMajor(target: number) {
    const mediaPos = this.findUserInMediaGroup(target)
    if (mediaPos < 0 || mediaPos === MAJOR_POS) {
      log(`setMajor find no user in meida list by stream id: ${target}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }

    const user = this.mediaList[mediaPos]

    user.userClickChoose = true

    this.removeUserFromMediaList(mediaPos)

    this.replaceMajor(user)
  }

  public hostChanged(oldHostUid: string, newHostUid: string) {
    log(`hostChanged old host: ${oldHostUid} new host: ${newHostUid}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    if (oldHostUid === newHostUid) {
      return
    }

    if (oldHostUid.length > 0) {
      const pos = this.findUserByUid(oldHostUid)
      if (pos >= 0) {
        const oldHostUser = this.attendeeList[pos]

        oldHostUser.setIsHost(false)

        this.notifyUserChanged(oldHostUser, pos, UserChangedReason.REASON_HOST)

        this.commManager.onUserHostStateChanged(false, oldHostUser)
      }
    }

    if (newHostUid.length > 0) {
      const pos = this.findUserByUid(newHostUid)
      if (pos >= 0) {
        const newHostUser = this.attendeeList[pos]

        newHostUser.setIsHost(true)

        this.notifyUserChanged(newHostUser, pos, UserChangedReason.REASON_HOST)
        this.commManager.onUserHostStateChanged(true, newHostUser)
      }
    }
  }

  public onRoomAssistantApplied(user: BizUser) {
    const pos = this.findUserByUid(user.uid)
    if (pos >= 0) {
      const newAssistant = this.attendeeList[pos]
      newAssistant.updateAssistantInfo(true)
      this.notifyUserChanged(newAssistant, pos, UserChangedReason.REASON_ASSISTANT_INFO)
    } else {
      const assistantUser = new CommUser(this.roomMode, undefined, user)
      assistantUser.setOnlineStatus(false)
      assistantUser.updateAssistantInfo(true)
      this.addUserToAttendeeGroup(assistantUser)
    }

  }

  public onStopAssistantCloudRecording(uid: string) {
    const user = this.getUserByUid(uid)
    if (user) {
      user.isCloudRecording = false
    }
  }

  public onRoomAssistantCanceled(target: BizUser) {
    const pos = this.findUser(target.streamId)

    if (pos >= 0) {
      const user = this.attendeeList[pos]
      user.subscribe = false
      user.updateAssistantInfo(false)
      if (user.isAllLost()) {
        this.removeUser(user, pos)
      } else {
        this.notifyUserChanged(user, pos, UserChangedReason.REASON_ASSISTANT_INFO)
      }
    }
  }

  // public onAssistantLeave(assistantUid: string, online: boolean) {
  //   const pos = this.findUserByUid(assistantUid)
  //   if (pos >= 0) {
  //     const assistant = this.attendeeList[pos]
  //     assistant.setOnlineStatus(online)
  //     this.notifyUserChanged(assistant, pos, UserChangedReason.REASON_USER_LEAVE)
  //     this.commManager.onAssistantLeave(online)
  //   }
  // }

  public updateSelfAssistantIdentity(isAssistant: boolean) {
    const userMe = this.getUserMe()
    if (userMe) {
      userMe.isAssistant = isAssistant
    }
  }

  public evaluateNetworkQuality(target: number, tx: number, rx: number) {
    if (target === this.selfStreamId) {
      const userMe = this.getUserMe()
      if (!userMe) {
        return
      }

      userMe.tx = tx
      userMe.rx = rx

      this.networkEval?.onLocalQuality(userMe.tx, userMe.rx)
    } else {
      if (this.networkEval?.isIgnoreRemote()) {
        return
      }

      let remote = this.getUser(target)
      if (!remote) {
        remote = this.getOwner(target)

        if (!remote) {
          log(`evaluateNetworkQuality find no user by stream id: ${target}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
          return
        }
      }

      if (remote.shareUser) {
        if (target === remote.shareUser.streamId) {
          remote.tx = tx

          this.networkEval?.onRemoteQuality(remote.streamId, remote.tx, remote.rx)
        } else {
          remote.rx = rx
        }
      } else {
        remote.tx = tx
        remote.rx = rx

        this.networkEval?.onRemoteQuality(remote.streamId, remote.tx, remote.rx)
      }
    }
  }

  @action
  public setSelfShareInfo(shareId: number) {
    const pos = this.findUser(this.selfStreamId)
    if (pos < 0) {
      log(`setSelfShareInfo can not find me ${this.selfStreamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }

    const userMe = this.attendeeList[pos]

    userMe.shareId = shareId

    this.notifyUserChanged(userMe, pos, UserChangedReason.REASON_SHARE)

    this.networkEval?.setIgnoreLocalTx(!userMe.isEntityMediaActive())
  }

  @action
  public clearSelfShareInfo() {
    const pos = this.findUser(this.selfStreamId)
    if (pos < 0) {
      log(`clearSelfShareInfo can not find me ${this.selfStreamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }

    const userMe = this.attendeeList[pos]

    userMe.shareId = 0

    this.notifyUserChanged(userMe, pos, UserChangedReason.REASON_SHARE)

    this.networkEval?.setIgnoreLocalTx(!userMe.isEntityMediaActive())
  }

  public bizDisconnected() {
    log(`bizDisconnected...`, LOG_TYPE.ERROR, LOG_MODULE.COMM)

    this.checkAttendeesBizLostUser(true)
  }

  public mediaDisconnected() {
    log(`mediaDisconnected...`, LOG_TYPE.ERROR, LOG_MODULE.COMM)

    let pos = this.attendeeList.length - 1
    while (pos >= 0) {
      const user = this.attendeeList[pos]
      user.lostRtc()

      if (user.isAllLost() && !user.isMe) {
        log(`mediaDisconnected user all lost, remove stream id: ${user.streamId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
        if (user.isAssistant) {
          user.online = false
        } else {
          this.removeUserFromAttendeesGroup(pos)
        }
      }

      pos--
    }
  }

  public isShareExists() {
    const userMe = this.getUserMe()
    return this.isRemoteSharing || (userMe && userMe.isShareOwner)
  }

  public setUserOperationPending(target: number, type: PendingType, pending: boolean): CommUser | undefined {
    const pos = this.findUser(target)
    if (pos < 0) {
      return undefined
    }

    const user = this.attendeeList[pos]

    if (type === PendingType.PENDING_AUDIO) {
      user.setAudioPending(pending)
    } else {
      user.setVideoPending(pending)
    }

    return user
  }

  /********************** Private APIs: User Add & Remove **********************/

  private addUser(user: CommUser) {
    log(`userManager addUser ${user.description()}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
    this.addUserToAttendeeGroup(user)
    this.addUserToMediaGroup(user)

  }

  private removeUser(user: CommUser, attendeePos: number) {
    log(`removeUser ${user.description()}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    this.removeUserFromAttendeesGroup(attendeePos)

    this.checkAndRemoteFromMediaGroup(user)
  }

  private findAndRemoveUser(user: CommUser) {
    const pos = this.findUser(user.streamId)
    if (pos >= 0) {
      this.removeUser(user, pos)
    }
  }

  public unsubscribeUser(userStreamId:number) {
    log(`unsubscribeUser : target user ${userStreamId}`, LOG_TYPE.COMM, LOG_MODULE.COMM)
    const user = this.getUser(userStreamId)
    if (!user) {
      log(`unsubscribeUser : not find target user ${userStreamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return

    }
    user.subscribe = false
    if (user.isCurrentSharing()) {
      this.hideShare(user, user.shareUser!)
    }
    this.checkAndRemoteFromMediaGroup(user)
  }

  public subscribeUser(streamId: number) {
    const user = this.getUser(streamId)
    if (!user) {
      log(`subscribeUser : not find target user ${streamId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }
    user.subscribe = true
    if (user.isCurrentSharing()) {
      this.showShare(user, user.shareUser!)
      // this.rtcCommLayer.subscribeUserVideo(user.shareUser!.streamId)
    }

  }

  private hideShare(owner: CommUser, share: CommUser) {
    const sharePos = this.findUserInMediaGroup(share.streamId)
    log(`unsubscribeUser : hide share streamId ${share.streamId}`, LOG_TYPE.COMM, LOG_MODULE.COMM)
    if (sharePos > -1) {
      this.checkAndRemoteFromMediaGroup(owner)
    }
    // this.rtcCommLayer.unsubscribeUserVideo(share.streamId)
  }

  private showShare(owner: CommUser, share: CommUser) {
    const sharePos = this.findUserInMediaGroup(share.streamId)
    if (sharePos > -1) {
      this.checkAndAdjustUserMediaPos(share, sharePos)
    } else {
      this.addUserToMediaGroup(share)
    }

  }

  @action
  private addBizUsers(users: BizUser[]) {
    const higherUsers: CommUser[] = []
    const normalUsers: CommUser[] = []

    users.forEach((item: BizUser) => {
      const pos = this.findUser(item.streamId)
      if (pos >= 0) {
        const user = this.attendeeList[pos]

        this.handleUserBizChanged(user, pos, item, UserChangedReason.REASON_BIZ_UPDATE)

        this.checkAndAdjustUserListType(user)
      } else {
        if (this.hasAttendeeHighPriority(item)) {
          higherUsers.splice(higherUsers.length, 0, new CommUser(this.roomMode, undefined, item))
        } else {
          normalUsers.splice(normalUsers.length, 0, new CommUser(this.roomMode, undefined, item))
        }
      }
    })

    higherUsers.forEach((item: CommUser) => {
      this.addUserToAttendeeGroup(item)
    })

    if (normalUsers.length > 0) {
      this.addUserRangeToAttendeeGroupEnd(normalUsers)
    }
  }

  @action
  private addUserToMediaGroup(user: CommUser) {
    if (user.priorityToMajor() === 0) {
      return
    }
    log(`addUserToMediaGroup ${user.streamId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    if (this.canReplaceMajor(user)) {
      this.replaceMajor(user)
    } else {
      this.addUserToMediaList(user)
    }
  }

  @action
  private addUserToMediaList(user: CommUser) {
    if (!user.isMediaActive()) {
      return
    }

    log(`addUserToMediaList ${user.description()}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    const priority = user.priorityInMediaList()
    let pos = this.mediaList.findIndex((item, index) => {
      return item.priorityInMediaList() < priority
    })

    if (pos < 0) {
      pos = this.mediaList.length
    }
    this.commManager.onUserStreamTypeNeedChange(user.streamId, false)
    this.mediaList.splice(pos, 0, user)
  }

  private addUserToAttendeeGroup(user: CommUser) {
    this.doAddUserToAttendeeGroup(user)

    this.checkShareStartByBiz(user)

    this.checkUserDumpStatus(user)

    this.checkIfNeedEvaluateRemote()

    this.checkAndAddSearchUser(user)
  }

  private doAddUserToAttendeeGroup(user: CommUser) {
    this.addUserToUserList(this.attendeeList, user)

    if (this.roomMode === ROOM_MODE.MODE_AGORA) {
      if (user.isLoggedIn) {
        this.addUserToUserList(this.loggedInUserList, user)
      } else {
        this.addUserToUserList(this.nonLoggedInUserList, user)
      }
    }
  }

  @action
  private addUserToUserList(list: CommUser[], user: CommUser) {
    if (user.isMe || list.length === 0) {
      list.unshift(user)
    } else {
      const priority = user.priorityInAttendees()
      let pos = -1

      if (priority > AttendeePriority.ATTENDEE_PRIORITY_NONE) {
        pos = list.findIndex(item => item.priorityInAttendees() < priority)
      }
      if (pos < 0) {
        pos = list.length
      }

      list.splice(pos, 0, user)
    }
  }

  @action
  private addUserRangeToAttendeeGroupEnd(users: CommUser[]) {
    log(`addUserRangeToAttendeeGroupEnd current size: ${this.attendeeList.length} add size: ${users.length}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    this.addUserRangeToUserList(this.attendeeList, users)

    if (this.roomMode === ROOM_MODE.MODE_AGORA) {
      const loggedInUsers: CommUser[] = []
      const nonLoggedInUsers: CommUser[] = []

      users.forEach((item: CommUser) => {
        if (item.isLoggedIn) {
          loggedInUsers.push(item)
        } else {
          nonLoggedInUsers.push(item)
        }
      })

      this.addUserRangeToUserList(this.loggedInUserList, loggedInUsers)

      this.addUserRangeToUserList(this.nonLoggedInUserList, nonLoggedInUsers);
    }

    this.checkAndDoResearch()
  }

  @action
  private addUserRangeToUserList(list: CommUser[], append: CommUser[]) {
    if (list.length === 0) {
      return
    }

    const start = list.length
    list.splice(start, 0, ...append)
  }

  private checkAndRemoteFromMediaGroup(user: CommUser) {
    const mediaPos = this.findUserInMediaGroup(user.streamId)
    const sharePos = this.findUserInMediaGroup(user.shareId)
    if (mediaPos >= 0) {
      this.removeUserFromMediaGroup(mediaPos)
    }
    if (sharePos >= 0) {
      this.removeUserFromMediaGroup(sharePos)
    }
  }

  @action
  private removeUserFromMediaGroup(mediaPos: number) {
    log(`remoteUserFromMediaGroup mediaPos: ${mediaPos}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
    if (mediaPos === MAJOR_POS) {
      if (this.majorUser) {
        this.majorUser.giveUpMajor()
        this.majorUser = undefined
      }

      let highestUser: CommUser
      const highestPos = this.findHighestMajorPriorityUser()
      if (highestPos < 0) {
        highestUser = this.attendeeList[0]
        log("remoteUserFromMediaGroup find no highest user in media, get one from attendees", LOG_TYPE.INFO, LOG_MODULE.COMM)
      } else {
        highestUser = this.mediaList[highestPos]

        this.removeUserFromMediaList(highestPos)
      }

      this.replaceMajor(highestUser)

    } else {
      this.removeUserFromMediaList(mediaPos)
    }
  }

  @action
  private removeUserFromMediaList(mediaPos: number) {
    if (mediaPos < 0 || mediaPos >= this.mediaList.length) {
      return
    }

    this.mediaList.splice(mediaPos, 1)
  }

  @action
  private removeUserFromAttendeesGroup(pos: number) {
    const user = this.attendeeList[pos]

    this.doRemoveUserFromAttendeeGroup(pos)

    this.checkIfNeedEvaluateRemote()

    this.checkAndRemoveSearchUser(user)
  }

  private doRemoveUserFromAttendeeGroup(pos: number) {
    const user = this.attendeeList[pos]

    this.removeUserFromAttendees(pos)

    if (this.roomMode === ROOM_MODE.MODE_AGORA) {
      let sublistPos = this.loggedInUserList.findIndex(item => item.streamId === user.streamId)
      if (sublistPos >= 0) {
        this.loggedInUserList.splice(sublistPos, 1)
      } else {
        sublistPos = this.nonLoggedInUserList.findIndex(item => item.streamId === user.streamId)
        if (sublistPos >= 0) {
          this.nonLoggedInUserList.splice(sublistPos, 1)
        } else {
          log(`doRemoveUserFromAttendeeGroup user not in loggedInUserList and nonLoggedInUserList`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
        }
      }
    }
  }

  @action
  private removeUserFromAttendees(pos: number) {
    if (pos < 0 || pos >= this.attendeeList.length) {
      log(`removeUserFromAttendees invalid pos: ${pos}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }

    const user = this.attendeeList[pos]
    this.attendeeList.splice(pos, 1)

    this.commManager.onUserRemoved(user.streamId)
  }

  private checkAndRemoveShareFromAttendeeGroup(share: CommUser) {
    const pos = this.findUser(share.streamId)
    if (pos >= 0) {
      log(`checkAndRemoveShareFromAttendeeGroup remove share: ${share.streamId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

      this.removeUserFromAttendeesGroup(pos)
    }
  }

  @action
  private checkAndAdjustUserListType(user: CommUser) {
    if (this.roomMode !== ROOM_MODE.MODE_AGORA || !user.isLoggedIn) {
      return
    }

    // User media join first, may have in non-logged in list
    const pos = this.nonLoggedInUserList.findIndex(item => item === user)
    if (pos >= 0) {
      this.nonLoggedInUserList.splice(pos, 1)

      this.addUserToUserList(this.loggedInUserList, user)
    }
  }

  /******************** Private APIs: Users Changed Functions ********************/
  private handleUserMediaChanged(user: CommUser, attendeePos: number, mediaUser: MediaUser, reason: number) {
    const audioPending = user.isAudioPending
    const videoPending = user.isVideoPending

    const realReason = user.updateByMediaReason(mediaUser, reason)
    if (realReason) {
      this.notifyUserChanged(user, attendeePos, realReason)

      if (hasReason(realReason, UserChangedReason.REASON_AUDIO) && audioPending) {
        this.commManager.onMyRequestMediaResponse(PendingType.PENDING_AUDIO, user.streamId)
      } else if (hasReason(realReason, UserChangedReason.REASON_VIDEO) && videoPending) {
        this.commManager.onMyRequestMediaResponse(PendingType.PENDING_VIDEO, user.streamId)
      }

      if (hasReason(realReason, UserChangedReason.REASON_AUDIO) || hasReason(realReason, UserChangedReason.REASON_VIDEO)) {
        if (user.isMe) {
          this.networkEval?.setIgnoreLocalTx(!user.isEntityMediaActive())
        } else {
          this.networkEval?.setIgnoreRemoteTx(user.streamId, !user.isEntityMediaActive())
        }
      }

      if (hasReason(realReason, UserChangedReason.REASON_INFO)) {
        this.checkAndAddSearchUser(user)
      }
    }
  }

  private handleShareMediaChanged(owner: CommUser, attendeePos: number, mediaUser: MediaUser, reason: number) {
    const share = owner.shareUser
    if (!share) {
      log("handleShareMediaChanged this owner has no share media", LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }

    const realReason = share.updateByMediaReason(mediaUser, reason)
    if (realReason && owner.subscribe) {
      this.notifyMediaGroupUserChanged(share, realReason)
    }
  }

  private handleUserBizChanged(user: CommUser, attendeePos: number, bizUser: BizUser, reason: number) {
    const realReason = user.updateByBizReason(bizUser, reason)
    if (realReason) {
      this.notifyUserChanged(user, attendeePos, realReason)

      if (hasReason(realReason, UserChangedReason.REASON_DUMPING_ISSUE)) {
        this.commManager.onUserIssueDumpingStateChanged(user)
      }

      if (hasReason(realReason, UserChangedReason.REASON_INFO)) {
        if (user.isCurrentSharing()) {
          user.shareUser?.updateInfoByShareOwner(user)
        }
      }

      if (hasReason(realReason, UserChangedReason.REASON_INFO)) {
        this.checkAndAddSearchUser(user)
      }
    }

    if (hasReason(reason, UserChangedReason.REASON_SHARE)) {
      this.checkShareStartByBiz(user)
    }
  }

  private notifyUserChanged(user: CommUser, attendeePos: number, reason: number) {
    if (hasReason(reason, REASON_MEDIA_GROUP_CONCERN)) {
      this.notifyMediaGroupUserChanged(user, reason)
    }
    if (hasReason(reason, REASON_ATTENDEE_GROUP_CONCERN)) {
      this.notifyAttendeeGroupUserChanged(user, attendeePos, reason)
    }
    if (hasReason(reason, UserChangedReason.REASON_INTERRUPT) && user.isAssistant) {
      this.commManager.onAssistantInterruptStateChange(user)
    }
    if (hasReason(reason, UserChangedReason.REASON_AUDIO) && user.isAssistant) {
      this.commManager.onAssistantAudioStateChanged(user)
    }
    if (hasReason(reason, UserChangedReason.REASON_USER_LEAVE) && user.isAssistant) {
      this.commManager.onAssistantOnlineStateChanged(user)
    }
  }

  private notifyMediaGroupUserChanged(user: CommUser, reason: number) {
    const mediaPos = this.findUserInMediaGroup(user.streamId)
    if (mediaPos < 0) {
      if (user.isMediaActive()) {
        this.addUserToMediaGroup(user)
      }
    } else {
      if (hasReason(reason, UserChangedReason.REASON_AUDIO) || hasReason(reason, UserChangedReason.REASON_VIDEO) || hasReason(reason, UserChangedReason.REASON_SHARE)) {
        if (user.isMediaActive()) {
          this.checkAndAdjustUserMediaPos(user, mediaPos)
        } else {
          this.removeUserFromMediaGroup(mediaPos)
        }
      }
    }
  }

  private notifyAttendeeGroupUserChanged(user: CommUser, attendeePos: number, reason: number) {
    if (this.hasAttendeePosChangeReason(reason)) {
      this.checkAndAdjustAttendeeGroupPos(user, attendeePos)
    }
  }

  private canReplaceMajor(user: CommUser): boolean {
    return user && (!this.majorUser || this.majorUser.priorityToMajor() < user.priorityToMajor())
  }

  @action
  private replaceMajor(user: CommUser) {
    if (!this.majorUser) {
      log(`replaceMajor old major null, new major: ${user.streamId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

      this.majorUser = user
    } else {
      log(`replaceMajor old major: ${this.majorUser.streamId} , new major: ${user.streamId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

      const oldMajor = this.majorUser
      oldMajor.giveUpMajor()
      this.majorUser = user

      this.addUserToMediaList(oldMajor)
    }

    this.commManager.onMajorUserReplaced(user.streamId)
    this.commManager.onUserStreamTypeNeedChange(user.streamId, true)
  }

  @action
  private preposeShareOwnerInMediaList(owner: CommUser) {
    if (!owner.videoState) {
      return
    }

    const oldPos = this.findUserInMediaGroup(owner.streamId)
    if (oldPos < 0 || oldPos === MAJOR_POS) {
      return
    }

    let pos = oldPos
    const ownerPriority = this.mediaList[pos].priorityInMediaList()

    for (pos -= 1; pos >= 0; pos--) {
      if (this.mediaList[pos].priorityInMediaList() >= ownerPriority) {
        pos++
        break
      }
    }

    if (pos !== oldPos) {
      this.mediaList.splice(oldPos, 1)

      this.mediaList.splice(pos, 0, owner)
    }
  }

  private checkShareStartByBackupShareId(user: CommUser, oldShareId: number) {
    if (user.isCurrentSharing()) {
      log(`checkShareStartByBackupShareId current sharing, share id: ${user.shareId}`, LOG_TYPE.COMM, LOG_MODULE.COMM)
      return
    }

    log(`checkShareStartByBackupShareId user: ${user.streamId}, old share id: ${oldShareId}, bizShareId: ${user.bizShareId} mediaShareId: ${user.mediaShareId}`,
      LOG_TYPE.COMM, LOG_MODULE.COMM)

    if (user.mediaShareId !== 0 && user.mediaShareId !== oldShareId) {
      this.checkShareStartByMedia(user)
    } else if (user.bizShareId !== 0 && user.bizShareId !== oldShareId) {
      this.checkShareStartByBiz(user)
    }
  }

  private checkShareStartByBiz(owner: CommUser) {
    if (owner.bizShareId !== 0 && !owner.isMe) {
      if (owner.isCurrentSharing()) {
        log(`checkShareStartByBiz current sharing, share id: ${owner.shareId}`, LOG_TYPE.COMM, LOG_MODULE.COMM)
        return
      }

      const pos = this.findUser(owner.bizShareId)
      if (pos >= 0) {
        log(`checkShareStartByBiz find share in attendees, find user share media: ${owner.bizShareId} already in attendees`, LOG_TYPE.INFO, LOG_MODULE.COMM)

        const share = this.attendeeList[pos]

        const ownerPos = this.findUser(owner.streamId)
        if (ownerPos >= 0) {
          this.userShareStart(owner, share, ownerPos)
        }
      }
    }
  }

  private checkShareStartByMedia(owner: CommUser) {
    if (owner.mediaShareId !== 0 && !owner.isMe) {
      if (owner.isCurrentSharing()) {
        log(`checkShareStartByMedia current sharing, share id: ${owner.shareId}`, LOG_TYPE.COMM, LOG_MODULE.COMM)
        return
      }

      const pos = this.findUser(owner.mediaShareId)
      if (pos >= 0) {
        log(`checkShareStartByMedia find share in attendees, find user share media: ${owner.mediaShareId} already in attendees`, LOG_TYPE.INFO, LOG_MODULE.COMM)

        const share = this.attendeeList[pos]

        const ownerPos = this.findUser(owner.streamId)
        if (ownerPos >= 0) {
          this.userShareStart(owner, share, ownerPos)
        }
      }
    }
  }

  @action
  private userShareStart(owner: CommUser, share: CommUser, ownerPos: number) {
    if (owner.isMe) {
      log("userShareStart not expect owner is me", LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }
    if (owner.isCurrentSharing()) {
      log(`userShareStart user already sharing, wait, current share id: ${owner.shareId}`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }
    log(`userShareStart owner: ${owner.streamId} share: ${share.streamId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    this.isRemoteSharing = true
    owner.linkShare(share)
    if (!owner.isNameUnknown()) {
      share.updateInfoByShareOwner(owner)
    } else {
      owner.updateInfoByShareStream(share)
    }
    this.checkAndRemoveShareFromAttendeeGroup(share)

    if (ownerPos >= 0) {
      this.notifyUserChanged(owner, ownerPos, UserChangedReason.REASON_SHARE)
    }

    this.commManager.onRemoteSharingState(true, owner)
    this.networkEval?.setIgnoreRemoteTx(owner.streamId, !owner.isEntityMediaActive())
    if (!share.subscribe && share.isAssistant) {
      this.hideShare(owner, share)
    } else {
      this.showShare(owner, share)

    }
  }

  @action
  private userShareStop(owner: CommUser, ownerPos: number) {
    const share = owner.shareUser
    if (!share) {
      log(`userShareStop owner: ${owner.streamId} has no share stream`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
      return
    }

    log(`userShareStop owner: ${owner.streamId} share: ${share.streamId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    this.isRemoteSharing = false

    owner.unlinkShare()

    if (ownerPos >= 0) {
      this.notifyUserChanged(owner, ownerPos, UserChangedReason.REASON_SHARE)
    }

    this.checkAndRemoteFromMediaGroup(share)

    this.networkEval?.setIgnoreRemoteTx(owner.streamId, !owner.isEntityMediaActive())

    this.commManager.onRemoteSharingState(false, owner)

    this.checkShareStartByBackupShareId(owner, share.streamId)
  }

  @action
  private checkAndAdjustUserMediaPos(user: CommUser, mediaPos: number) {
    if (mediaPos === MAJOR_POS) {
      const highestPos = this.findHighestMajorPriorityUser()
      if (highestPos >= 0) {
        const highestUser = this.mediaList[highestPos]
        if (this.canReplaceMajor(highestUser) && !user.userClickChoose) {
          this.removeUserFromMediaList(highestPos)

          this.replaceMajor(highestUser)
        }
      }
    } else {
      if (this.canReplaceMajor(user)) {
        this.removeUserFromMediaList(mediaPos)

        this.replaceMajor(user)
      } else if (!user.isMe) {
        let pos = mediaPos
        const priority = user.priorityInMediaList()

        if ((pos - 1) >= 0 && this.mediaList[pos - 1].priorityInMediaList() < priority) {
          pos--
          while ((pos - 1) >= 0 && this.mediaList[pos - 1].priorityInMediaList() < priority) {
            pos--
          }
        } else if ((pos + 1) < this.mediaList.length && this.mediaList[pos + 1].priorityInMediaList() > priority) {
          pos++
          while (pos < this.mediaList.length && this.mediaList[pos].priorityInMediaList() > priority) {
            pos++
          }
        }

        if (pos !== mediaPos) {
          this.mediaList.splice(mediaPos, 1)

          this.mediaList.splice(pos > mediaPos ? pos - 1 : pos, 0, user)
        }
      }
    }
  }

  private checkAndAdjustAttendeeGroupPos(user: CommUser, attendeePos: number) {
    this.checkAndAdjustListPos(user, this.attendeeList, attendeePos)

    if (this.roomMode === ROOM_MODE.MODE_AGORA) {
      let listPos = this.loggedInUserList.findIndex(item => item.streamId === user.streamId)
      if (listPos >= 0) {
        this.checkAndAdjustListPos(user, this.loggedInUserList, listPos)
      } else {
        listPos = this.nonLoggedInUserList.findIndex(item => item.streamId === user.streamId)
        if (listPos >= 0) {
          this.checkAndAdjustListPos(user, this.nonLoggedInUserList, listPos)
        } else {
          log(`checkAndAdjustAttendeeGroupPos user not in loggedInUserList and nonLoggedInUserList`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
        }
      }
    }
  }

  @action
  private checkAndAdjustListPos(user: CommUser, list: CommUser[], listPos: number) {
    if (user.isMe) {
      return
    }

    let pos = listPos
    const priority = user.priorityInAttendees()

    if ((pos - 1) >= 0 && list[pos - 1].priorityInAttendees() < priority) {
      pos--
      while ((pos - 1) >= 0 && list[pos - 1].priorityInAttendees() < priority) {
        pos--
      }
    } else if ((pos + 1) < list.length && list[pos + 1].priorityInAttendees() > priority) {
      pos++
      while (pos < list.length && list[pos].priorityInAttendees() > priority) {
        pos++
      }
    }

    if (pos !== listPos) {
      list.splice(listPos, 1)

      list.splice(pos > listPos ? pos - 1 : pos, 0, user)
    }
  }

  @action
  private checkAttendeesBizLostUser(isBizDisconnected: boolean) {
    log(`checkAttendeesBizLostUser isBizDisconnected: ${isBizDisconnected}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    let pos = this.attendeeList.length - 1
    while (pos >= 0) {
      const user = this.attendeeList[pos]
      if (isBizDisconnected) {
        user.lostRtm()
      }

      if (user.isAllLost() && !user.isMe) {
        log(`checkAttendeesBizLostUser user all lost, remove stream id: ${user.streamId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
        this.removeUserFromAttendeesGroup(pos)
      }

      pos--
    }
  }

  private checkIfNeedEvaluateRemote() {
    if (this.attendeeList.length === 2) {
      const remote = this.attendeeList[1]
      if (remote.streamId === this.selfStreamId) {
        log(`checkIfNeedEvaluateRemote remote should not be me`, LOG_TYPE.ERROR, LOG_MODULE.COMM)
        return
      }

      this.networkEval?.startEvalRemote(remote.streamId, !remote.isEntityMediaActive())
    } else {
      this.networkEval?.stopEvalRemote()
    }
  }

  private checkUserDumpStatus(user: CommUser) {
    if (user.isIssueRecording) {
      // no need effect any more
      user.dumpAudioEffectOnce = true

      this.commManager.onUserIssueDumpingStateChanged(user)
    }
  }

  /******************** Find Users Functions ********************/

  private findHighestMajorPriorityUser(): number {
    if (this.mediaList.length === 0) {
      return -1
    }

    let highestPos = 0
    let highestUser = this.mediaList[0]

    this.mediaList.forEach((user, index) => {
      const score = user.priorityToMajor()
      if (score > highestUser.priorityToMajor()) {
        highestUser = user
        highestPos = index
      }
    })

    return highestPos
  }

  private findUser(streamId: number): number {
    return this.attendeeList.findIndex(user => user.streamId === streamId)
  }

  private findUserByUid(uid: string): number {
    return this.attendeeList.findIndex(user => user.uid === uid)
  }

  private findUserByShareId(streamId: number) {
    return this.attendeeList.findIndex(user => user.shareId === streamId)
  }

  private findUserByBizShareId(streamId: number) {
    return this.attendeeList.findIndex(user => user.bizShareId === streamId)
  }

  private findUserInMediaGroup(streamId: number): number {
    if (this.majorUser && this.majorUser.streamId === streamId) {
      return MAJOR_POS
    }

    return this.mediaList.findIndex(user => user.streamId === streamId)
  }

  private getOwner(shareId: number): CommUser | undefined {
    const pos = this.findUserByShareId(shareId)
    return pos >= 0 ? this.attendeeList[pos] : undefined
  }

  public getUserMe(): CommUser | undefined {
    const pos = this.findUser(this.selfStreamId)
    return pos >= 0 ? this.attendeeList[pos] : undefined
  }

  public getUser(streamId: number): CommUser | undefined {
    const pos = this.findUser(streamId)
    return pos >= 0 ? this.attendeeList[pos] : undefined
  }

  public getUserByUid(uid: string): CommUser | undefined {
    const pos = this.findUserByUid(uid)
    return pos >= 0 ? this.attendeeList[pos] : undefined
  }

  /******************** Help Functions ********************/
  private hasAttendeePosChangeReason(reason: number): boolean {
    return hasReason(reason, UserChangedReason.REASON_AUDIO) || hasReason(reason, UserChangedReason.REASON_VIDEO) || hasReason(reason, UserChangedReason.REASON_DUMPING_ISSUE)
      || hasReason(reason, UserChangedReason.REASON_HOST) || hasReason(reason, UserChangedReason.REASON_SHARE) || hasReason(reason, UserChangedReason.REASON_CLOUD_RECORDING)
      || hasReason(reason, UserChangedReason.REASON_ASSISTANT_INFO) || hasReason(reason, UserChangedReason.REASON_USER_LEAVE)
  }

  private hasAttendeeHighPriority(user: BizUser) {
    return user.isHost || user.shareId !== 0 || user.isDumping || user.isCloudRecording
  }

  public getMajorStreamId(): number {
    return this.majorUser !== undefined ? this.majorUser.streamId : 0
  }

  @observable
  public searchResultUsers: CommUser[] = []
  private lastSearchType: UserListType = UserListType.TYPE_ATTENDEES_ALL
  private lastSearchInput: string = ''

  /**
   * !!! Caveats:
   * 1. not invoke too much, try at lest 500ms intervals
   * 2. trim 'input' before invoke, in-between space allowed
   * 3. highlight words index: CommUser.matchStart, CommUser.matchEnd
   */
  @action
  public searchUser(input: string, type: UserListType) {
    this.lastSearchInput = input
    this.lastSearchType = type

    let list: CommUser[]
    switch (type) {
      case UserListType.TYPE_ATTENDEES_LOGGED_IN:
        list = this.loggedInUserList
        break;
      case UserListType.TYPE_ATTENDEES_NON_LOGGED_IN:
        list = this.nonLoggedInUserList
        break;
      default:
        list = this.attendeeList
        break;
    }

    const outputThirdPartyName: CommUser[] = []
    const outputThirdPartyAlias: CommUser[] = []
    const outputName: CommUser[] = []
    const outputHelpName: CommUser[] = []

    list.forEach((item: CommUser) => {
      const result = item.match(input)
      switch (result) {
        case MATCH_THIRDPARTY_NAME:
        case MATCH_THIRDPARTY_NAME_PINYIN:
          outputThirdPartyName.push(item)
          break;
        case MATCH_THIRDPARTY_ALIAS:
        case MATCH_THIRDPARTY_ALIAS_PINYIN:
          outputThirdPartyAlias.push(item)
          break;
        case MATCH_NAME:
        case MATCH_NAME_PINYIN:
          outputName.push(item)
          break;
        case MATCH_FULL_NAME:
          outputName.push(item)
          break;
        case MATCH_HELP_NAME:
        case MATCH_HELP_NAME_PINYIN:
          outputHelpName.push(item)
        default:
          break;
      }
    })

    this.searchResultUsers = outputThirdPartyName.concat(outputThirdPartyAlias).concat(outputHelpName).concat(outputName)
  }

  @action
  private checkAndAddSearchUser(user: CommUser) {
    if (!this.lastSearchInput || user.isNameUnknown()) {
      return
    }

    // old search user may update user names by BIZ
    const find = this.searchResultUsers.findIndex(item => item.streamId === user.streamId)
    if (find >= 0) {
      this.searchResultUsers.splice(find, 1)
    }

    const result = user.match(this.lastSearchInput)
    if (result === MATCH_NONE) {
      return
    }

    const pos = this.searchResultUsers.findIndex(item => item.matchType < result)
    if (pos < 0) {
      this.searchResultUsers.push(user)
    } else {
      this.searchResultUsers.splice(pos, 0, user)
    }
  }

  @action
  private checkAndRemoveSearchUser(user: CommUser) {
    if (!this.lastSearchInput) {
      return
    }

    const pos = this.searchResultUsers.findIndex(item => item.streamId === user.streamId)
    if (pos >= 0) {
      this.searchResultUsers.splice(pos, 1)
    }
  }

  private checkAndDoResearch() {
    if (!this.lastSearchInput) {
      return
    }

    this.searchUser(this.lastSearchInput, this.lastSearchType)
  }
}