import { VideoEncoderConfigurationPreset } from "agora-rtc-sdk-ng";
import { action, computed, observable } from "mobx";
import { localStorage as storage } from '../utils/Storage';
import ThirdPartyUser, { ThirdPartySource } from "./thirdPartyUser";


export const MIN_MESSAGE_TIMEOUT = 15 * 1000
export const MIN_REQUEST_TIMEOUT = 20 * 1000
export const MIN_CONTROL_TIMEOUT = 15 * 1000

export class User {
  @observable
  uid: string = "";
  @observable
  name: string = "";
  @observable
  streamId: number = 0;

  @observable
  enableVideo: boolean = true;
  @observable
  enableAudio: boolean = true;

  @observable
  videoProfile: VideoEncoderConfigurationPreset = '720p_5';

  @observable
  gdprAccepted?: boolean = false;

  rtcEncryption: boolean = true
  isShowWatermark: boolean = false

  maxMessageTimeout: number = MIN_MESSAGE_TIMEOUT // min
  maxRequestTimeout: number = MIN_REQUEST_TIMEOUT// min
  maxControlTimeout: number = MIN_CONTROL_TIMEOUT // min

  uuid: string = ""

  @observable
  thirdPartyUser?: ThirdPartyUser;

  constructor() {
    const storageInfo = storage.read('userState')
    this.updateFromLocalStorage(storageInfo)
  }

  @action
  public updateFromLocalStorage(user: any): void {
    if (!user) return
    Object.keys(user).forEach(key => {
      (this as any)[key] = user[key]
    })
  }

  public updateLocalData() {
    storage.save('userState', this)
  }

  @action
  public updateWeChatWorkUserInfo(isInit: boolean, data: any) {
    let changed = false
    const { name, alias, department, innerSession } = data;
    if (isInit) {
      this.thirdPartyUser = new ThirdPartyUser(ThirdPartySource.SOURCE_WECHAT_WORK, name, alias, department, innerSession)
      changed = true
    } else {
      if (this.thirdPartyUser) {
        if (this.thirdPartyUser.name !== name) {
          this.thirdPartyUser.name = name
          changed = true
        }

        if (this.thirdPartyUser.alias !== alias) {
          this.thirdPartyUser.alias = alias
          changed = true
        }

        if (this.thirdPartyUser.department !== department) {
          this.thirdPartyUser.department = department
          changed = true
        }
      }
    }

    return changed
  }

  @computed
  public get thirdPartyUserName() {
    let alias = ''
    if (this.thirdPartyUser?.alias) {
      alias = ` (${this.thirdPartyUser.alias})`
    }
    return this.thirdPartyUser ? (this.thirdPartyUser.name + alias) : ""
  }

  @action
  public clearWeChatWorkUserInfo() {
    this.thirdPartyUser = undefined
  }

  public getThirdPartySession(): string {
    return this.thirdPartyUser ? this.thirdPartyUser.session : "";
  }
}
