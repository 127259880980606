import {
  Box,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconStar } from "../../assets/star.svg";
import { color } from "../../style/color";
import QualityFeedbackModal from "./QualityFeedbackModal";
import { useCommManagerStore} from "../../stores/hooks";

const QualityFeedbackView = observer(() => {
  const [t] = useTranslation()
  const [open, setOpen] = useState(false)
  const commManager = useCommManagerStore()

  const openInsideModalTime = useRef<number>()
  const handleOpenModal = () => {
    setOpen(true)
    openInsideModalTime.current = new Date().getTime()
  }

  const handleCloseModal = () => {
    setOpen(false)
  }
  // if(!commManager.isDebuggerMode) return null;
  if(!commManager.isOpenQualityFeedback) return null;
  return (
    <Box>
      <Tooltip title={
        <Box style={{ color: color.colorTextBlack, lineHeight: '1rem' }}>{t('QualityFeedbackTip')}</Box>
      } placement="bottom">
        <IconButton onClick={handleOpenModal}>
          <IconStar   id="quality-feedback"/>
        </IconButton>
      </Tooltip>
      {open && <QualityFeedbackModal setClose={handleCloseModal} openInsideModalTime={openInsideModalTime}></QualityFeedbackModal>}
    </Box>
  )
})

export default QualityFeedbackView
