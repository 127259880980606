import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';

import { useNotificationStore } from "../stores/hooks"
import Notification from "./Notification"

const useStyles = makeStyles({
  notificationList: {
    position: 'fixed',
    margin: 'auto',
    top: '3.6rem',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 200,
  },
  notification: {
    fontSize: '0.6rem'
  }
})

const NotificationContainer = observer(() => {
  const styles = useStyles()
  const notification = useNotificationStore();

  return (
    <div className={styles.notificationList}>
      { notification.queue.map((info, index) => (
        <Notification
          key={index}
          className={styles.notification}
          info={info} onExited={() => notification.close(info)}
        />
      )) }
    </div>
  )
})

export default NotificationContainer