import { IRemoteAudioTrack, IRemoteVideoTrack } from "agora-rtc-sdk-ng";
import { VOLUME, RTC_QUALITY_TYPE, USER_STATUS_FLAG } from "../utils/constants";

export class MediaUser {
  streamId: number = 0;
  audioTrack?: IRemoteAudioTrack;
  videoTrack?: IRemoteVideoTrack;
  audioState: boolean = false;
  videoState: boolean = false;
  signalQuality: number = 0;
  txQuality = RTC_QUALITY_TYPE.QUALITY_UNKNOWN
  rxQuality = RTC_QUALITY_TYPE.QUALITY_UNKNOWN
  isSpeaking: boolean = false;
  isRemoteStreamHigh: boolean = true
  isNeedWatermark: boolean = false
  status?: number = 0;

  name: string = ''
  parentStreamId: number = 0

  opSeq: number = 0
  lastAudioOffTimestamp = 0
  isUnknown: boolean = true;
  isMe: boolean = false

  constructor(user?: any) {
    if (user) {
      this.updateFromNewUser(user)
    }
  }

  public updateAudioState(enable: boolean, audioTrack?: IRemoteAudioTrack, audioVolume: VOLUME = VOLUME.FULL_VOLUME) {
    this.audioState = enable
    this.audioTrack = audioTrack
    this.isSpeaking = false
    this.audioTrack?.setVolume(audioVolume)

    if (!enable) {
      this.lastAudioOffTimestamp = (new Date()).valueOf()
    }
  }

  public restoreAudioVolume() {
    this.audioTrack?.setVolume(100)
  }

  public setAudioVolume(volume:number) {
    this.audioTrack?.setVolume(volume)
  }

  public updateSpeakingState(isSpeaking: boolean) {
    this.isSpeaking = isSpeaking
  }

  public updateVideoState(enable: boolean, videoTrack?: IRemoteVideoTrack) {
    this.videoState = enable
    this.videoTrack = videoTrack
  }

  public updateFromNewUser(newUser: any) {
    if (!newUser) return
    Object.keys(newUser).forEach(key => {
      (this as any)[key] = newUser[key]
    })
  }

  public hasWatermark() {
    const isWatermark = ((this.status || 0) & USER_STATUS_FLAG.ADD_WATERMARK) !== 0;
    this.isNeedWatermark = isWatermark
    return isWatermark

  }

  //https://jira.agoralab.co/browse/APP-610
  public isAudioRecentTurnOff() {
    return ((new Date()).valueOf() - this.lastAudioOffTimestamp) <= 1500
  }

  public description(): string {
    return `stream id: ${this.streamId} audio: ${this.audioState} video: ${this.videoState}`
  }
}