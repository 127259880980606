import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { color } from "../../style/color";

const useStyle = makeStyles((theme: Theme) => {
  const { 
    customColor: { 
      issueFeedBackCancelBackground,
      settingBackground,
      issueFeedBackResult,
    } 
  } = theme
  const backgroundRight = issueFeedBackResult
  const settingBgc = settingBackground
  const buttonCancel = issueFeedBackCancelBackground
  const isDark = theme.palette.type === 'dark'
  return createStyles({
    box: {
      display: 'flex',
      width: '17.375rem',
      height: '21.5rem',
      backgroundColor: settingBgc,
      boxShadow: '0px 2px 24px 0px rgba(0, 0, 0, 0.19)',
      borderRadius: '0.5rem',
      outline: 0,
    },
    line: {
      width: '27.5rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '2.5rem',
      marginTop: '-4rem',
    },
    icon: {
      width: '1.2rem',
      height: '1.2rem',
      cursor: 'pointer',
    },

    modal: {
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
    },
    leftSide: {
      flex: '1',
      width: '14.13rem',
      borderRadius: '0.5rem',
    },
    wrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
      overflow: 'auto',
      background: backgroundRight,
      borderRadius: '0.5rem',
    },
    watermarkTitle: {
      color: isDark ? color.colorWhite : color.colorSubtitleBlack,
    },
    boxClose: {
      position: 'absolute',
      paddingTop: '0.5rem',
      right: '0.5rem',
      cursor: 'pointer',
    },
    boxTitle: {
      display: 'flex',
      justifyContent: 'center',
      padding: '1.25rem 3.125rem',
    },
    boxButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '0.875rem',
    },
    banner: {
      width: '17.375rem',
      height: '10.0625rem',
    },
    shareRequestButton: {
      width: '9.38rem',
      height: '1.88rem',
      '& > .MuiButton-label': {
        fontSize: '0.75rem',
      },
    },
    shareRequestButtonCancel: {
      backgroundColor: buttonCancel,
      marginTop: '1rem',
    },
  })
})

export default useStyle

