import { action, computed, observable } from "mobx";
import { log, LOG_MODULE, LOG_TYPE, uploadLog as doUplaodLog } from "../utils/Log"
import { CommManagerStore } from "./commManager";
import { color, colorAgora } from "../style/color";
import { ROOM_MODE, UPLOAD_LOG_STATE } from "../utils/constants";


export default class UIStateStore {
  private commManager: CommManagerStore

  constructor(commManager: CommManagerStore) {
    this.commManager = commManager
  }

  @observable
  public homeMode: ROOM_MODE = ROOM_MODE.MODE_NORMAL

  @computed
  public get isHomeModeNormal() {
    return this.homeMode === ROOM_MODE.MODE_NORMAL
  }

  @computed
  public get isRoomModeNormal() {
    return this.commManager.roomMode === ROOM_MODE.MODE_NORMAL
  }

  @computed
  public get modeIconButtonColor() {
    return this.isHomeModeNormal ? color.colorTitleGrey : color.colorWhite
  }

  @computed
  public get modeTextColor() {
    return this.isHomeModeNormal ? color.colorTextBlack : color.colorWhite
  }

  @computed
  public get modeTextMDColor() {
    return this.isHomeModeNormal ? "text.primary" : "primary.contrastText"
  }

  // @computed
  // public get modeTipTextColor() {
  //   return this.isHomeModeNormal ? color.colorTipGrey : color.colorGrey
  // }

  @computed
  public get modeTitleGreyColor() {
    return this.isHomeModeNormal ? color.colorTitleGrey : color.colorGrey
  }

  @computed
  public get modePrimaryColor() {
    return this.isHomeModeNormal ? color.colorPrimary : colorAgora.colorPrimary
  }

  @computed
  public get modePrimaryMDColor() {
    return this.isHomeModeNormal ? "primary.main" : "primary.contrastText"
  }

  @computed
  public get modeIssueMoreColor() {
    return this.isHomeModeNormal ? color.colorGrey : "#a4c5e6"
  }

  @action
  public changeModeToNormal() {
    if (this.homeMode !== ROOM_MODE.MODE_NORMAL) {
      this.homeMode = ROOM_MODE.MODE_NORMAL
      localStorage.setItem('homeMode', `${ROOM_MODE.MODE_NORMAL}`)
    }
  }

  @action
  public changeModeToAgora() {
    if (this.homeMode !== ROOM_MODE.MODE_AGORA) {
      this.homeMode = ROOM_MODE.MODE_AGORA
      localStorage.setItem('homeMode', `${ROOM_MODE.MODE_AGORA}`)
    }
  }


  @observable
  requestLogin = false

  @action
  setRequestLogin(request: boolean) {
    this.requestLogin = request
  }

  @observable logUploading = false
  @observable logUploadingStatus = UPLOAD_LOG_STATE.DEFAULT

  @action.bound async uploadLog() {
    this.logUploading = true
    this.logUploadingStatus = UPLOAD_LOG_STATE.UPLOADING
    log(`user upload log`, LOG_TYPE.COMM, LOG_MODULE.UI)

    try {
      await doUplaodLog()
      this.logUploadingStatus = UPLOAD_LOG_STATE.SUCCESS
    } catch (error) {
      this.logUploadingStatus = UPLOAD_LOG_STATE.FAILURE
    }

    setTimeout(() => {
      this.logUploading = false
      this.logUploadingStatus = UPLOAD_LOG_STATE.DEFAULT
    }, 3000)
  }

  public showChatFocus = false

  public homePageScrollTs: number = 0
}
