import { BizUser } from "./bizUser";
import { AssistantInfo } from "./assistantInfo";
import { ENCRYPTION_MODE } from "../utils/constants";
export class BizRoomInfo {
  public rid: string = ''
  public pwd: string = ''
  public rtcEncryption: boolean = false
  public encryptionMode: ENCRYPTION_MODE = ENCRYPTION_MODE.NONE
  public channelName: string = ''
  public roomToken: string = ''
  public rtcToken: string = ''
  public salt: string = ''

  public initialStatus: number = 0
  public createTime: string = ''
  public hosts: string[] = []

  public hostsDetails: BizUser[] = []
  public key: string = ''
  public mediatoken: string = ''
  public sdkConfigs?: SdkConfigs = undefined
  public recorder?: Recorder = undefined

  public timestamp: number = 0
  public serverPeerId: string = ''

  public getAudioState() {
    return (this.initialStatus & 0x01) === 0
  }

  public getVideoState() {
    return (this.initialStatus & 0x02) === 0
  }

  public getHostUid() {
    if (this.hosts.length === 0) {
      return ''
    }
    return this.hosts[0]
  }

  public getHostName(showMode: 'tourist' | 'member') {
    if (this.hostsDetails.length === 0) {
      return ''
    }
    return this.hostsDetails[0].getUserFullName(showMode)
  }

  public remark(ts: number, peerId: string) {
    this.timestamp = ts
    this.serverPeerId = peerId
  }

  public isHost(user: BizUser): boolean {
    if (this.hosts.length === 0) {
      return false
    }

    return this.hosts.find(item => item === user.uid) !== undefined
  }

  public updateRoomInfo(roomInfo: BizRoomInfo) {
    this.pwd = roomInfo.pwd
    this.key = roomInfo.key
    this.timestamp = roomInfo.timestamp
    this.serverPeerId = roomInfo.serverPeerId
    this.initialStatus = roomInfo.initialStatus
    this.hosts = roomInfo.hosts
    this.hostsDetails = roomInfo.hostsDetails
  }
}

class SdkConfigs {
  public channelProfile: number = 0
  public parameters: string[] = []
  public roomSecure: boolean = true
}

export class Recorder {
  public uid: string = ''
  public innerName: string = ''
  public alias: string = ''
  public elapsedTime: number = 0
  public recordingId: string = ''
}

export class JoinRoomResult {
  public users: BizUser[] = []
  public roomInfo!: BizRoomInfo
  public elapsedTime: number = 0
  public joinMsgId: number = 0
  public appendMark: string = ''
  public more: boolean = false
  public assistant: AssistantInfo = new AssistantInfo()
}

export class RoomUpdateInfo {
  // status: number = 0
  isAllowVideo: boolean = false
  isAllowAudio: boolean = false
  password: string = ''
  host: string | null = null

  public roomUpdateInfo(info: BizRoomInfo) {
    this.password = info.pwd
  }

  public getHostUid() {
    return this.host;
  }
  public enableHost(enable: boolean, uid: string) {
    this.host = null

    if (enable) {
      this.host = uid
    }
  }

  public updateStatus(video: boolean, audio: boolean) {
    this.isAllowVideo = video
    this.isAllowAudio = audio
  }
}