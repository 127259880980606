import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useRtcCommLayerStore, useRtcEngineStore } from "../stores/hooks";
import { Box, makeStyles } from "@material-ui/core";
import VideoPlayer from "./VideoPlayer";

const useStyles = makeStyles({
  video: {
    width: "100%",
    height: "100%",
    borderRadius: ".625rem",
  },
  mainPlayer: {
    width: "100%",
    height: "100%",
  },
});

const VideoPreview = observer(() => {
  const styles = useStyles();
  const rtcComm = useRtcCommLayerStore();
  const rtcEngine = useRtcEngineStore();

  useEffect(() => {
    rtcComm.startPreview();
    return () => {
      rtcComm.stopPreview();
    };
  }, [rtcEngine.localCameraTrackEndCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className={styles.video}>
      {rtcComm.mineVideoState && (
        <div className={styles.mainPlayer}>
          <VideoPlayer streamId={0} />
        </div>
      )}
    </Box>
  );
});

export default VideoPreview;
