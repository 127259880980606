import { Box, IconButton, Button, Modal, withStyles, Select, MenuItem, Dialog, LinearProgress, Typography, TextField, useTheme } from "@material-ui/core";
import cloneDeep from "lodash.clonedeep";
import { observer } from "mobx-react";
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { sendIssueFeedback } from "../../stores/connector/http";
import { ReactComponent as IconSelectDown } from '../../assets/icon_select_down.svg';
import { ReactComponent as IconSuccess } from "../../assets/icon_success.svg";
import { ReactComponent as IconUpload } from "../../assets/icon_upload.svg";
import { ReactComponent as IconWarning } from "../../assets/icon_warning.svg";
import { ReactComponent as IconCloseLight } from "../../assets/icon_delete_light.svg";
import { ReactComponent as IconCloseDark } from "../../assets/icon_delete_dark.svg";
import { useCommManagerStore, useUserManagerStore, useUserStore, useUIStore } from "../../stores/hooks";
import { color, colorCommon, } from "../../style/color";
import { formatLogs, log, LOG_MODULE, LOG_TYPE } from "../../utils/Log";
import { versionMessage } from "../../utils/helper";
import useStyle from "./styles";
import { CustomAlert } from "../CustomPopup/customAlert";

interface IFeedbackModal {
  setClose: Dispatch<SetStateAction<boolean>>
  openInsideModalTime?: any
}

enum AudioRateLabelType {
  PerceivableDelay = 0,
  NoticeableEcho = 1,
  NoticeableNoise = 2,
  IntermittentVoice = 3,
  LowSoundVolume = 4,
  NoSound = 5,
}

enum VideoRateLabelType {
  FrameFreeze = 6,
  IntermittentVideo = 7,
  HighLatency = 8,
  Corruption = 9,
  TooBright = 10,
  Blurriness = 11,
  ObviousNoise = 12,
  VideoAndAudioIsAsync = 13,
}

const IssueFeedBackModal = observer((props: IFeedbackModal) => {
  const MAX_INPUT_TEXT_LENGTH = 140;
  const [t] = useTranslation()
  const styles = useStyle()
  const user = useUserStore()
  const commManager = useCommManagerStore()
  const userManager = useUserManagerStore()
  const uiStore = useUIStore()
  const theme = useTheme()
  const userName = user.getName()
  const streamId = user.getStreamId()
  const isDark = theme.palette.type === 'dark'

  const buttonStyleKey = uiStore.isRoomModeNormal ? styles.selectedNormal : styles.seletedAgora

  const audioRateTextContent = [
    { value: AudioRateLabelType.PerceivableDelay, text: 'AudioPerceivableDelay', selected: false, },
    { value: AudioRateLabelType.IntermittentVoice, text: 'AudioIntermittentVoice', selected: false, },
    { value: AudioRateLabelType.LowSoundVolume, text: 'LowSoundVolume', selected: false, },
    { value: AudioRateLabelType.NoticeableEcho, text: 'AudioNoticeableEcho', selected: false, },
    { value: AudioRateLabelType.NoSound, text: 'NoSound', selected: false, },
    { value: AudioRateLabelType.NoticeableNoise, text: 'AudioNoticeableNoise', selected: false, },
  ]

  const videoRateTextContent = [
    { value: VideoRateLabelType.HighLatency, text: 'HighLatency', selected: false, },
    { value: VideoRateLabelType.IntermittentVideo, text: 'IntermittentVideo', selected: false, },
    { value: VideoRateLabelType.Blurriness, text: 'Blurriness', selected: false, },
    { value: VideoRateLabelType.ObviousNoise, text: 'ObviousNoise', selected: false, },
    { value: VideoRateLabelType.FrameFreeze, text: 'FrameFreeze', selected: false, },
    { value: VideoRateLabelType.Corruption, text: 'Corruption', selected: false, },
    { value: VideoRateLabelType.TooBright, text: 'TooBright', selected: false, },
    { value: VideoRateLabelType.VideoAndAudioIsAsync, text: 'VideoAndAudioIsAsync', selected: false, },
  ]

  const [videoFeedbackText, setVideoFeedbackText] = useState('')
  const [audioRateText, setAudioRateText] = useState(audioRateTextContent)
  const [videoRateText, setVideoRateText] = useState(videoRateTextContent)
  const [isDisabled, setSubmitDisabled] = useState(true)
  const [PeerWithUserStreamId, setPeerUser] = useState('')
  const [isShowDialog, setShowDialog] = useState(false)
  const [progress, setProgress] = React.useState(0)
  const [feedbackStatus, setFeedbackStatus] = React.useState(true)
  const [feedbackHelpDeskID, setFeedbackHelpDeskID] = React.useState('')
  const [isCancelUploadTips, setIsCancelUploadTips] = React.useState(false)
  const [isShowLeaveTips, setIsShowLeaveTips] = React.useState(false)

  const handleChangeTextNumber = (event: any, type: string) => {
    const text = event.target.value;
    if (text.length >= MAX_INPUT_TEXT_LENGTH) text.slice(0, MAX_INPUT_TEXT_LENGTH)
    setVideoFeedbackText(text)
  }

  const setupQualityFeedbackHooks = () => {
    setVideoFeedbackText('')
    setAudioRateText(audioRateTextContent)
    setVideoRateText(videoRateTextContent)
  }

  useEffect(() => {
    if (videoFeedbackText.length > MAX_INPUT_TEXT_LENGTH) {
      setSubmitDisabled(true);
    } else {
      const audioSelectedLabel = audioRateText.filter(item => item.selected).map(v => v.value)
      const videoSelectedLabel = videoRateText.filter(item => item.selected).map(v => v.value)
      const isChooseLabel = audioSelectedLabel.length || videoSelectedLabel.length;
      const isNotEmpty = videoFeedbackText.length !== 0
      setSubmitDisabled(!(isNotEmpty || isChooseLabel))
    }
  }, [videoFeedbackText, audioRateText, videoRateText])

  const handleSubmitFeedback = async () => {
    setProgress(0)
    setShowDialog(true)
    setFeedbackStatus(true)
    const audioSelectedLabel = audioRateText.filter(item => item.selected).map(v => v.value)
    const videoSelectedLabel = videoRateText.filter(item => item.selected).map(v => v.value)
    const tags = JSON.stringify([...audioSelectedLabel, ...videoSelectedLabel])
    const packet = await formatLogs();
    const postQualityFeedbackData = {
      appVersion: process.env.REACT_APP_APP_VERSION as string,
      issueName: user.isThirdPartyLoggedIn ? user.thirdPartyUserName : userName,
      issueStreamId: streamId,
      oppositeStreamId: PeerWithUserStreamId === t('None') ? '' : PeerWithUserStreamId,
      description: videoFeedbackText,
      sdkVersion: versionMessage.rtc,
      rid: commManager.roomInfo.roomId,
      tags,
      packet
    }
    setProgress(10)
    sendIssueFeedback(postQualityFeedbackData).then((res:any) => {
      const { data } = res;
      setFeedbackStatus((data.success as boolean))
      setFeedbackHelpDeskID(data.issueId)
      const timer = setInterval(() => {
        setProgress((oldProgress:number) => {
          if (oldProgress === 100) {
            clearInterval(timer);
          }
          const diff = Math.random() * 40;
          return Math.min(oldProgress + diff, 100);
        });
      }, 100)
      setIsCancelUploadTips(false)
      log('User Finished submit issue feedback report', LOG_TYPE.COMM, LOG_MODULE.UI)
    }).catch((e:Error) => {
      setFeedbackStatus(false)
      setProgress(100)
      log(`Submit issue  feedback report on fail : ${e}`, LOG_TYPE.COMM, LOG_MODULE.HTTP)
    })
  }

  const handleCloseModal = (isReconfirm?: boolean) => {
    if (!isDisabled && !isReconfirm) {
      setIsShowLeaveTips(true)
      return
    }
    setupQualityFeedbackHooks()
    props.setClose(false);
    log('User close issue feedback modal', LOG_TYPE.COMM, LOG_MODULE.MODEL)
  }
  const handleCloseTips = () => {
    if (progress !== 100) {
      setIsCancelUploadTips(true)
      return;
    }
    if (!feedbackStatus) {
      setShowDialog(false)
      return;
    }
    setIsCancelUploadTips(false)
    handleCloseModal(progress === 100);
    log('User close issue feedback Tips', LOG_TYPE.COMM, LOG_MODULE.MODEL)
  }

  const BorderLinearProgress = withStyles((theme:any) => ({
    root: {
      height: '.3125rem',
    },
    colorPrimary: {
      backgroundColor: color.colorWhite,
    },
    colorSecondary: {
      backgroundColor: theme.palette.type === 'dark' ? colorCommon.colorDarkModeBack : color.colorWhite,
    },
    bar: {
      borderRadius: progress === 100 ? 0 : '.625rem',
      backgroundColor: feedbackStatus ? theme.palette.primary.main : '#ED455F',
    },
  }))(LinearProgress);

  const onContinueUpload = () => {
    setIsCancelUploadTips(false);
    if (isShowLeaveTips) {
      setIsShowLeaveTips(false)
      return
    }
    setShowDialog(false)
  }

  const onTerminateUpload = () => {
    if (isShowLeaveTips) {
      handleCloseModal(true)
      return
    }
    setIsCancelUploadTips(false)
  }

  return (
    <>
      <Modal className={`${styles.modal}`} open={true} hideBackdrop={true} id="issueFeedbackModal">
        <Box className={styles.box}>
          <Box className={styles.titleBox}>
            <Typography variant="h2">{t('Dump')}</Typography>
          </Box>
          <Box className={styles.issueBox}>
            <Box className={`${styles.userName} notranslate`}>
              <Typography variant="h3">{user.isThirdPartyLoggedIn ? user.thirdPartyUserName : userName}</Typography>
              <Typography variant="subtitle2" className={styles.streamId}>ID: {streamId}</Typography>
            </Box>
            <Box className={styles.issueItemBox}>
              <Box className={styles.audioTitleBox}>
                <Box className={styles.audioTitleTip}>
                  <Typography variant="h3">{t('AudioIssues')}</Typography>
                  {/*<Typography variant="h5" className={styles.audioRecordTip}>{t('Record audio Help with investigating issues.')}</Typography>*/}
                </Box>
                {/*{<Button variant="contained" color="primary" className={styles.recordBtn}>{t('Go')}</Button>}*/}
              </Box>
              <Box className={styles.rateText}>
                {audioRateText.map((item, index) => {
                  return (
                    <Button onClick={() => {
                      audioRateText[index].selected = !item.selected
                      setAudioRateText(cloneDeep(audioRateText))
                    }} variant="contained" id={`audio-rate-modal-${index}`} className={item.selected ? buttonStyleKey : ''} key={index} size="small" value={item.value} >
                      <Typography variant="h4">{t(item.text)}</Typography>
                    </Button>
                  )
                })}
              </Box>
            </Box>
            <Box className={styles.issueItemBox}>
              <Typography variant="h3">{t('VideoIssues')}</Typography>
              <Box className={styles.rateText}>
                {videoRateText.map((item, index) => {
                  return (
                    <Button onClick={() => {
                      videoRateText[index].selected = !item.selected
                      setVideoRateText(cloneDeep(videoRateText))
                    }} variant="contained" id={`video-rate-modal-${index}`} className={`${item.selected ? buttonStyleKey : ''}`} key={index} size="small" value={item.value} >
                      <Typography variant="h4">{t(item.text)}</Typography>
                    </Button>
                  )
                })}
              </Box>
            </Box>
            <Box className={styles.issueItemBox}>
              <Typography variant="h3">{t('dump_describe')}</Typography>
              <Box className={styles.issueTextAreaBox}>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  variant="outlined"
                  value={videoFeedbackText}
                  rows={3}
                  InputProps={{
                    classes: {
                      root: styles.textFieldCaret,
                      multiline: styles.multiline,
                      input: styles.textFieldFont
                    }
                  }}
                  placeholder={t('dump_text_input')}
                  onChange={(e) => handleChangeTextNumber(e, 'video')}
                />
                <span className={`${styles.issueTextNumber} ${videoFeedbackText.length > 140 && styles.fullText}`}>{videoFeedbackText.length}/140</span>
              </Box>
            </Box>
            <Box className={styles.issueItemBox}>
              <Typography variant="h3">{t('PeerWithProblem')}</Typography>
              <Select
                className={styles.peerSelect}
                classes={{ root: styles.selectWidth }}
                IconComponent={IconSelectDown} id="oppositeUserSelect" variant="outlined" value={PeerWithUserStreamId} 
                onChange={(event:any) => setPeerUser((event.target.value as string))}>
                <MenuItem className={styles.menuItem} value={t('None')} key="none">{t('None')}</MenuItem>
                {userManager.attendeeList.map(userItem => (
                  userItem.streamId !== streamId ? 
                  <MenuItem id={`oppositeUser_${userItem.streamId}`} className={styles.menuItem} value={userItem.streamId} key={userItem.streamId}>{userItem.userName}</MenuItem> 
                  : null
                ))}
              </Select>
            </Box>
            <Box className={styles.buttonBox}>
              <Button id="submit-feedback" variant="contained" className={styles.issueButton} 
              onClick={handleSubmitFeedback} disabled={isDisabled} color="primary" style={{ marginLeft: '1rem' }}>{t('Submit')}</Button>
              <Button id="cancel-feedback" variant="contained"
               className={`${styles.issueButton} ${styles.issueButtonCancel}`} onClick={() => handleCloseModal()}>{t('Cancel')}</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Dialog open={isShowDialog} classes={{ container: styles.dialogBorder }} >
        <BorderLinearProgress variant="determinate" value={progress} color="secondary" />
        <Box className={styles.submitFeedbackResult} id="submitFeedbackResultId">
          <Box className={styles.closeButtonBox}>
            <IconButton id="closeTips" size="small" className={styles.closeButton} onClick={handleCloseTips} >
              { isDark ? <IconCloseDark /> : <IconCloseLight /> }
            </IconButton>
          </Box>
          {
            feedbackStatus ?
              <Box className={styles.submitFeedbackSuccess} >
                {progress === 100 ?
                  <Box className={styles.feedbackInfoBox}>
                    <IconSuccess fill={theme.palette.primary.main} />
                    <span className={styles.feedbackStatusText}>
                      {t("Uploaded")}
                    </span>
                    <Box className={styles.feedbackInfoColor}>
                      {t("CPNumber")}<span className={styles.cpNumber} id="resultCPNumber"> {feedbackHelpDeskID}</span>
                    </Box>
                    <span className={styles.feedbackInfoColor}>{t("Troubleshoot")}</span>
                  </Box> :
                  <Box className={styles.feedbackInfoBox}>
                    <IconUpload fill={theme.palette.primary.main} />
                    <span className={styles.feedbackStatusText}>{t("FeedbackUploading")}</span>
                    <span className={styles.feedbackInfoColor}>{t("PleaseWait")}</span>
                  </Box>}
                  <span className={styles.feedbackInfoColor}>{t("ThankHelp")}</span>
              </Box>
              :
              <Box className={styles.submitFeedbackSuccess} >
                <IconWarning />
                <span className={styles.feedbackStatusText}>{t("UploadFailed")}</span>
                <span className={styles.feedbackInfoColor}>{t('UploadFailedDescription', { roomName: commManager.roomInfo.roomId })}</span>
                <Button variant="contained" className={styles.retryButton} onClick={handleSubmitFeedback} id="uploadRetry">{t('Retry')}</Button>
              </Box>
          }
        </Box>

      </Dialog>

      { isShowLeaveTips || isCancelUploadTips ? 
        <CustomAlert
          isOpen={isShowLeaveTips || isCancelUploadTips}
          isAlert={true}
          title={isShowLeaveTips ? t("CancelReport") : ''}
          content={isShowLeaveTips ? t('editsDiscarded') : t('CancelUpload')}
          confirmText={isShowLeaveTips ? t('Quit') : t('Okay')}
          cancelText={isCancelUploadTips ? t('Cancel') : t('Continue')}
          onConfirm={isCancelUploadTips ? onContinueUpload : onTerminateUpload}
          onCancel={isCancelUploadTips ? onTerminateUpload : onContinueUpload}
        /> : null
      }
    </>
  )
})

export default IssueFeedBackModal

