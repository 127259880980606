import { observer } from "mobx-react";
import React, { useEffect, useRef, } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { useCommManagerStore, useRtcCommLayerStore } from "../../stores/hooks";
import { CommUser } from "../../types/commUser";
import VideoPlayer from "../VideoPlayer";
import UserItemInfo from "./UserItemInfo";
import { ReactComponent as IconSmile } from "../../assets/icon_smile.svg";

export interface UserItemProps {
  user: CommUser,
  index: number
}

const useStyles = makeStyles((theme: Theme) => {
  const { customColor: { componentsBackGround }, palette } = theme;
  const backgroundColor = componentsBackGround;
  return createStyles({
    listItemContainer: {
      width: '100%',
      position: 'relative',
      marginBottom: '.5rem',
      backgroundColor,
      borderRadius: '1.07rem',
    },
    iconSmile: {
      fill: theme.customColor.occupationsIconColor,
      '& path': {
        fill: palette.type === 'dark' ? '#404041FF' : '#C0C1C2',
      },
      '& circle': {
        fill: palette.type === 'dark' ? '#404041FF' : '#C0C1C2',
      }
    },
    smallVideo: {
      width: '100%',
      height: '8.75rem',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
      display: 'flex',
      justifyContent: 'center',
    }
  })
})
const UserItemView = observer((props: UserItemProps) => {
  const commManager = useCommManagerStore()
  const classNames = useStyles()
  const { user, index } = props
  const userItem = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!(user.videoState || user.audioState)) {
      return
    }
  }, [user.videoState, user.audioState])
  // return null
  const videoView = () => {
    if (user.videoState) {
      return <VideoPlayer isSmallVideo={true} isNeedWatermark={user.isNeedWatermark} isShareVideo={user.parentStreamId ? true : false} streamId={user.streamId} />
    }
    return <IconSmile style={{ margin: '2.5625rem auto' }} className={classNames.iconSmile} />
  }
  return (
    <div className={classNames.listItemContainer} ref={userItem} key={user.streamId}>
      {(user.videoState || user.audioState) &&
        <div className={classNames.smallVideo} onClick={(e) => { e.preventDefault(); commManager.setMajor(user.streamId); return false; }}>
          {videoView()}
          <UserItemInfo user={user} index={index} key={user.streamId} />
        </div>}
    </div>
  )
})

export default UserItemView
