import { observable } from "mobx"

export class ChatMessage {
  @observable
  text: string
  @observable
  sender: string
  @observable
  senderName: string = ''
  @observable
  portraitId: string = ''
  @observable
  isMine: boolean = false
  @observable
  isSending: boolean = false
  @observable
  sendFailed: boolean = false
  @observable
  time: string = ''

  constructor(text: string, sender: string, isMine: boolean = false) {
    this.text = text
    this.sender = sender
    this.isMine = isMine
  }
}