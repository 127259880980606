import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme, Link } from "@material-ui/core";
import {
  useCommManagerStore, useNotificationStore,
  useRtcCommLayerStore, useUserManagerStore, useShareManagerStore
} from "../../stores/hooks";
import notificationApi, { initNotification } from "../../components/NotificationView/index"
import { APPLY_ASSISTANT_LANG, ROOM_MODE } from "../../utils/constants";
export const ShowNotification = observer(() => {

  const commManager = useCommManagerStore()
  const userManager = useUserManagerStore()
  const shareManager = useShareManagerStore()
  const rtcCommLayerStore = useRtcCommLayerStore()
  const [t] = useTranslation()
  const theme = useTheme()
  const notification = useNotificationStore(); 

  const qualityLinkText = () => {
    if (!commManager.qualityTipSubcontent) {
      return null
    }
    if (commManager.qualityTipSubcontent.value) {
      return t(`${commManager.qualityTipSubcontent.text}`, { "value": `${t(commManager.qualityTipSubcontent.value)}` })
    }
    return t(`${commManager.qualityTipSubcontent.text}`)
  }
  useEffect(() => {
    if (commManager.roomMode === ROOM_MODE.MODE_AGORA && commManager.isRtcConnected) {
      initNotification()
    }
    if (commManager.roomState === 0) {
      notification.resetNewNotificationQueue()
      notificationApi.destroy()
    }
  }, [commManager.isRtcConnected])

  useEffect(() => {
    if (!commManager.roomReady) {
      return
    }
    const localAudioStatus = rtcCommLayerStore.mineAudioState
    const localVideoStatus = rtcCommLayerStore.mineVideoState
    if (commManager.remoteAudioRequests.length > 0) {
      if (localAudioStatus) {
        commManager.acceptRemoteRequest(true)
        notificationApi.close('audioRequest')
        return
      }
      notificationApi.dialog({
        confirmButtonColor: theme.palette.primary.main,
        key: 'audioRequest',
        duration: 0,
        message: t('RequestUnmuteAudio', { name: commManager.remoteAudioRequests }),
        confirmText: t('Open'),
        cancelText: t('Reject'),
        onConfirm: () => {
          commManager.acceptRemoteRequest(true)
          notificationApi.close('audioRequest')
        },
        onCancel: () => {
          commManager.refuseRemoteRequest(true);
          notificationApi.close('audioRequest')
        }
      })
    } else {
      notificationApi.close('audioRequest')
    }
    if (commManager.remoteVideoRequests.length > 0) {
      if (localVideoStatus) {
        commManager.acceptRemoteRequest(false)
        notificationApi.close('videoRequest')
        return
      }
      notificationApi.dialog({
        confirmButtonColor: theme.palette.primary.main,
        key: 'videoRequest',
        duration: 0,
        message: t('RequestUnmuteVideo', { name: commManager.remoteVideoRequests }),
        confirmText: t('Open'),
        cancelText: t('Reject'),
        onConfirm: () => {
          commManager.acceptRemoteRequest(false)
          notificationApi.close('videoRequest')
        },
        onCancel: () => {
          commManager.refuseRemoteRequest(false);
          notificationApi.close('videoRequest')
        }
      })
    } else {
      notificationApi.close('videoRequest')
    }
    if (commManager.showQualityTip) {
      notificationApi.manualClose({
        key: 'qualityTip',
        duration: 0,
        title: t(`${commManager.qualityTipTitle}`),
        message: t(`${commManager.qualityTipContent}`),
        linkText: qualityLinkText(),
        linkColor: theme.palette.primary.main,
        handleLinkAction: () => {
          commManager.userQualityTipAction()
        }
      })
    } else {
      notificationApi.close('qualityTip')
    }

    notification.newQueue.forEach((item, index) => {
      const key = item.key || `key_notification_${index}`
      notificationApi.open({
        key,
        type: item.type,
        message: t(item.content, item.contentParams),
        duration:item.duration,
        onClose: () => {
          notification.remove(index)
          notificationApi.close(key)
        },
        onConfirm: () => {
          notificationApi.close(key)
          notification.remove(index)
          if (item.onConfirm) {
            item.onConfirm()
          }
        },
        onCancel: () => {
          notificationApi.close(key)
          notification.remove(index)
          if (item.onCancel) {
            item.onCancel()
          }
        },
        cancelText: item.cancelText ? t(item.cancelText) : t("No"),
        confirmText: item.confirmText ? t(item.confirmText) : t("Yes")
      })
    })

    if (rtcCommLayerStore.showSelfMediaNeedDetermineTip) {
      notificationApi.dialog({
        message: t("MessageSeverDisconnected"),
        key: "MessageSeverDisconnected",
        onCancel: () => {
          notificationApi.close('MessageSeverDisconnected')
          rtcCommLayerStore.setCancelSelfMediaDetermined()
          rtcCommLayerStore.setShowSelfMediaNeedDetermineTip(false)
        },
        onConfirm: () => {
          notificationApi.close('MessageSeverDisconnected')
          rtcCommLayerStore.setSelfMediaDetermined()
        },
        cancelText: t("No"),
        confirmText: t("Yes")
      })
    } else {
      notificationApi.close('MessageSeverDisconnected')
    }
    if (commManager.roomState === 0) {
      notification.resetNewNotificationQueue()
      notificationApi.destroy()
    }
  }, [
    commManager.remoteAudioRequests.length,
    commManager.remoteVideoRequests.length,
    commManager.showQualityTip,
    userManager.isShareExists(),
    notification.newQueue,
    shareManager.IsLocalSharing,
    shareManager.isRemoteSharing,
    rtcCommLayerStore.showSelfMediaNeedDetermineTip,
    commManager.roomReady
  ])

  useEffect(() => {
    if (!commManager.roomReady) {
      return
    }
    if (commManager.assistantInfo.hasAssistant() && !commManager.assistantInfo.isMeAssistant() && !commManager.subscribeAssistant) {
      const contentText = commManager.assistantInfo.language === APPLY_ASSISTANT_LANG.EN ? 'Assistant_ask_users_en' : 'Assistant_ask_users_cn'
      const meetingAssistantIndex = notification.newQueue.findIndex((item:any) => item.key === 'meetingAssistant')
      if (meetingAssistantIndex > -1) {
        notification.remove(meetingAssistantIndex)
      }
      notificationApi.dialog({
        type: 'dialog',
        key: 'meetingAssistant',
        message: t(contentText),
        onConfirm: () => {
          notificationApi.close('meetingAssistant')
          notificationApi.manualClose({
            message: t("Assistant_answer_users_yes"),
            key: "meetingAssistant",
          })
          commManager.enableAssist(true)
        },
        onCancel: () => {
          notificationApi.close('meetingAssistant')
          commManager.disableAssist()
          notificationApi.manualClose({
            message: t("Assistant_answer_users_no"),
            key: "meetingAssistant",
          })
        },
        cancelText: t("No"),
        confirmText: t("Yes")
      })
      return
    }
  }, [
    commManager.roomReady,
    commManager.assistantInfo.hasAssistant(),
  ])
  useEffect(() => {
    if (commManager.closeUserAssistantNotification) {
      notificationApi.close('meetingAssistant')
      const meetingAssistantIndex = notification.newQueue.findIndex((item:any) => item.key === 'meetingAssistant')
      if (meetingAssistantIndex > -1) {
        notification.remove(meetingAssistantIndex)
      }
    }
  }, [commManager.closeUserAssistantNotification])

  const show = () => {
    const array = [1, 2, 3, 4, 5, 6, 7]
    array.forEach((item) => {
      notificationApi.open({
        type: 'toast',
        message: 'message' + item,
        key: 'key' + item,
        duration: item + 1,
      })
    })
    setTimeout(() => {
      notificationApi.open({
        type: "manualClose",
        message: 'message remover',
        key: 'key5',
        duration: 5,
      })
    }, 2000)
  }
  // return <button onClick={show}>show</button>
  return null
})