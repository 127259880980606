import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { isLessSafari14, } from '../../utils/userAgentDetector'

export const useStyles = makeStyles((theme: Theme) => {
  const { customColor: { tabBackground ,issueFeedBackResult} } = theme
  return createStyles({
    userInfoContainer: {
      width: '100%',
      position: 'absolute',
      zIndex: 1200,
    },
    listItem: {
      width: '100%',
      display: 'flex',
    },
    userName: {
      width: '7.25rem',
      height: '1.5rem',
      background: '#35363942',
      borderRadius: '0.6rem',
      color: '#fff',
      lineHeight: '1.5rem',
      fontSize:'0.75rem',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      margin: '6.75rem auto 1rem',
      padding:'0 0.625rem',
      fontWeight: 600,
      textShadow: `0px 1px 0px #0000001A`,
      // cursor:'pointer',
      '&:hover': {
        background: 'rgba(53,54,57,0.35)'
      }
    },
    itemControl: {

    },
    micState: {
      position: 'absolute',
      top: '0.5rem',
      left: '0.5rem',
      height: '1.25rem',
      width: '1.25rem',
      borderRadius: '50%'
    },

    iconContainer: {
      position: 'absolute',
      top: '0.5rem',
      right: '0.5rem',
    },
    iconButton: {
      background: issueFeedBackResult,
      width: '1.25rem',
      boxShadow: '0rem 0.06rem 0.31rem 0rem rgba(0, 0, 0, 0.3)',
      borderRadius: '0.24rem',
      height: '1.25rem',
      '&:hover':{
        background: issueFeedBackResult,
      }
    },
    safariButton: {
      marginTop: isLessSafari14() ? '-3.1px !important' : '',
    },
  })
})

export const userListStyles = makeStyles((theme: Theme) => {
  const { customColor: {
    componentsBackGround, }, } = theme;
  const backgroundColor = theme.palette.background.default;
  return createStyles({
    subHeader: {
      padding: 0,
      borderBottom: '0.05rem solid #ECECEC',
      paddingTop: '0.6rem',
      paddingBottom: '0.6rem',
    },
    listRoot: {
      paddingTop: 0,
      paddingBottom: 0,
      width: '9.125rem',
      paddingRight: '0.375rem',
      '&::-webkit-scrollbar': {
        width: '5px',
        backgroundColor: backgroundColor,
      },
      '&::-webkit-scrollbar-track': {
        width: '5px',
        backgroundColor: backgroundColor,
        margin: '0.625rem 0'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '0.1875rem',
        backgroundColor: '#CDD2DCFF',
      }
    },
    listItemRoot: {
      display: "block",
    },
    occupations: {
      width: '8.75rem',
      height: ' 8.75rem',
      backgroundColor: theme.customColor.componentsBackGround,
      borderRadius: '1.07rem',
      marginBottom: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:last-child': {
        marginBottom: '0',
      }
    },
    iconBox: {
      width: '1.2rem',
      height: '1.2rem',
    },
  })
})
export const useUserItemStyles = makeStyles((theme: Theme) => {
  const { customColor: { participantsIconColor } } = theme;
  return createStyles({
    box: {
      position: 'relative'
    },
    iconButton: {
      fill: participantsIconColor,
    },
    triangle: {
      transform: 'rotate(270deg) translate(50%,-50%)',
      position: 'absolute',
      top: '50%',
      left: '-0.4375rem',
      fill: theme.palette.background.paper,
    },
    paper: {
      zIndex: 4000,
      overflow: 'inherit',
      marginLeft: '1rem',
      borderRadius: '0.75rem',
      padding: '0.75rem 1rem',
      backgroundColor: theme.palette.background.paper
    },
    popoverPaper:{
      overflow: 'inherit',
      marginLeft: '1rem',
      borderRadius: '0.75rem',
      // padding: '0.5rem 1rem 0.25rem 0.3125rem',
      padding: '0.5rem 0rem 0.25rem 0rem',
      backgroundColor: theme.palette.background.paper
    },
    listItem: {
      paddingLeft: '0.3125rem',
      paddingRight:'1rem',
      // padding: 0,
      marginBottom: '0.25rem',
      height: '1rem',
      width: 'auto',
      display:'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: theme.palette.type === 'dark' ? '#F2F6FE33' : '#F2F6FE',
      }
    },
    listIcon: {
      width: 'auto',
      minWidth: 0
    },
    listItemText:{
      margin:0,
      padding:0
    }
  })
})

export const tooltipStyle = makeStyles((theme: Theme) => {
  const { customColor: { participantsIconColor } } = theme;
  return createStyles({
    tooltipContainer: {
      lineHeight:'0.875rem',
      fontSize: '0.75rem',
    },
    showName:{
      marginTop:'0.5rem'
    },
    fontWeight: {
      fontWeight: 600
    }
  })
})

export const useStyleVoiceVolume = makeStyles((theme: Theme) => {
  const { customColor: { voiceVolumeActiveBackground } } = theme;
  return createStyles({
    micBox: {
      top: '0.1875rem',
      left: '0.4375rem',
      width: '0.3438rem',
      height: '0.5625rem',
      backgroundColor: voiceVolumeActiveBackground,
      borderRadius: '1.25rem',
      overflow: 'hidden',
      position: 'absolute',
      transform: 'scale(0.8)',
    },
    micShade: {
      bottom: 0,
      width: '100%',
      height: '50%',
      backgroundColor: '#ffffff',
      overflow: 'hidden',
      position: 'absolute',
      transition: 'height ease-in-out 1ms',
      zIndex: 1000,
    },
    micBoxBigStream: {
      top: '8px',
      left: '7px',
      width: '5.5px',
      height: '0.5625rem',
      backgroundColor: voiceVolumeActiveBackground,
      borderRadius: '1.25rem',
      overflow: 'hidden',
      position: 'absolute',
      transform: 'scale(0.8)',
    },
    micShadeBigStream: {
      bottom: 0,
      width: '100%',
      height: '50%',
      backgroundColor: '#ffffff',
      overflow: 'hidden',
      position: 'absolute',
      transition: 'height ease-in-out 1ms',
      zIndex: 1000,
    },
  })
})
