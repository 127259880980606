import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { useTranslation } from 'react-i18next'
import { useCommManagerStore } from "../../stores/hooks";
import { formatDuration, isChinese } from "../../utils/helper";
import styles from "./style.module.css";
const CloudRecording = observer(() => {
  const [t] = useTranslation()
  const commManager = useCommManagerStore()

  return (
    <Fragment>
      {
        commManager.isRoomCloudRecording && commManager.roomReady ?
          <div className={styles.recordingContainer}>
            <div id="cloud-recording-message" className={`${styles.recording} ${isChinese() ? styles.recordingCn : styles.recordingEn}`}>
              <div className={styles.recordingContent}>
                <div className={styles.circle} />
                <div style={{ marginLeft: '0.5625rem' }} className={styles.recordingText}>
                  {`${t('cloud_recording_status')}`} <span style={{ paddingLeft: '0.2rem' }}>{commManager.recorderElapsedTime > 0 ? formatDuration(commManager.recorderElapsedTime) : "00:00"}</span>
                </div>
              </div>
            </div>
          </div> : null
      }
    </Fragment>
  )
})

export default CloudRecording
