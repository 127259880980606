export const color = {
  /* colorPrimary */
  colorPrimary: '#0087FF',
  colorPrimaryLight: '#00A0E9',
  colorWhite: '#FFFFFF',
  colorBackgroundWhite: '#e5e8f8',
  /* Text Color */
  colorTextBlack: '#2e3848',
  colorBlack: '#000000',
  colorLabelBlack: '#040405',
  colorTextGrey: '#D8D8D9',
  /* grey */
  colorGrey: '#E5E5E5',
  colorGreyLight: '#F2F2F2',
  colorBorderGrey: '#C8CACE',
  colorTitleGrey: '#8B92A9',
  colorSubtitleBlack: '#262626',
  colorTipGrey: '#6D7278',
  colorDarkGrey: '#949AB0',
  colorToolTipBorder: '#ECECEC',
  colorError: '#ed455f',
  colorSecondaryGrey: '#888896',

}

export const colorDefault = {
  // default theme
  colorPrimary: '#0087FF',
  colorPrimaryLight: '#0092FF',
  colorPrimaryDark: '#1892FF',
  colorBackground: '#EDF2F9',
  colorWait: '#edf2f98c',
  colorWaitVague: '#2b2b2c8c',
  colorConfirm: '#C5E4FF',
  colorText: '#A5ADBF',
  colorHoverLight: '#2096FF',
  colorHoverDark: '#3CA3FF',
  colorActiveLight: '#0274D9',
  colorActiveDark: '#0061B8',
  colorCheckbox: '#0087FF',
  colorBoxShadow: '#B7D0DCFF',
  colorIssueFeedbackSelectedDark: '#2A353F',
  colorIssueFeedbackSelectedLight: '#E7F4FF',
  colorIssueFeedbackSelectedTextLight: '#6EAFED',
  colorBubbleShadow:'#A0C2DF',
  colorBubbleDark:'#404448',
  colorSelectHover:'#F5F8FF',
  colorHomepageBackground:'#c4e7fd',
  colorHomepageBackgroundDark:'#00275c',
  colorPrimaryFilter:'#0087ff0a',
  colorParticipantsButtonHover:'#D9EAFF',
  colorAudioActiveBackground: '#BADFFF',
}

export const colorCommon = {
  // common
  colorDarkModeBack: '#0D0F0F',
  colorBlack: '#2B2B2C',
  colorTextGrey: '#AAAAAA',
  colorWhite: '#fff',
  colorDarkGrey: '#404041',
  colorUndertone: '#0D0F0F',
  colorGreen: '#00A688',
  colorGreenLight: '#12B99B',
  colorLightGrey: '#aaa',
  colorOrange: '#FFAA22',
  colorLightOrange: '#FFB642',
  colorError: '#FD3E3F',
  colorLightRed: '#FF4A4B',
  colorInputBorderGrey: '#B8B8CB',
  colorOutlineButtonHover: '#F8F8F8',
  colorOutlineButtonActive: '#F2F2F2',
  colorFontGrey: '#D8D8D9',
  colorBorder: '#DFDDE9',
  colorSetting: '#F2F6FE',
  colorTitleTextColor: '#040405',
  colorAlertBoxShadow: '#435C732b',
  bubbleColor: '#373738',
  colorOutlinedBorderLight: '#2332573F',
  colorOutlinedBorderDark: '#979797',
  colorOutlinedActiveDark: '#373738',
  colorDarkPlaceholder: '#7F7F80',
  colorOutlinedSecondaryLight: '#233257',
  colorOutlinedActiveLight: '#ECF1F8',
  colorOutlinedSecondaryDark: '#C2C2C3',
  colorOutlinedBorderHoverLight: '#C3C7D1',
  colorContainedLabelLight: '#1E2221',
  colorContainedBackgroundHoverLight: '#F2F7FF',
  colorContainedBackgroundActiveLight: '#D0DAE7',
  colorToolTipBoxShadow: '#435C7311',
  colorMenuItemHoverBlue: '#F5F8FF',
  colorIssueTipTextGrey: '#ABAFBD',
  colorIssueTitleBorderBlue: '#DDE0E9',
  colorIssueStreamIdGrey: '#8292A3',
  colorIssueStreamIdDark: '#C2C3C3',
  colorIssueButtonSelectedBlue: '#62B3FF',
  colorIssueButtonSelectedPink: '#787EFF',
  colorIssueFeedBackDark: '#7F7F80',
  colorIssueFeedBackInfoGrey: '#DDDDDD',
  colorIssueFeedBackDialogGrey: '#3C3C43',
  colorIssueFeedBackDialogDarkGrey: '#BEBEBF',
  colorInputDisabledDark: '#38383B',
  colorInputDisabledLight: '#F7F7F9',
  colorSettingTipWhite: '#F5F8FE',
  colorSettingTabTextGrey: '#9FA5BB',
  colorAlertContentGrey: '#878787',
  colorParticipantsButtonHover:'#d9eaff33',
  colorAudioActiveBackgroundDark: '#797979',
}


export const colorAgora = {
  // agora theme
  colorPrimary: '#8B62FF',
  colorPrimaryLight: '#946EFF',
  colorBackground: '#F1F3FF',
  colorWait: '#f7f8ff85',
  colorWaitVague: '#946eff',
  colorConfirm: '#E5DAFF',
  colorText: '#B0AABE',
  colorHover: '#6144B2',
  colorActive: '#907CC9',
  colorBoxShadow: '#C3B7DCFF',
  colorHoverLight: '#9A76FF',
  colorHoverDark: '#A484FF',
  colorActiveLight: '#6B48CB',
  colorActiveDark: '#643CD5',
  colorTextContainedSmall: '#AFADC7',
  colorIssueFeedbackSelectedDark: '#3C3349',
  colorIssueFeedbackSelectedLight: '#563F74',
  colorIssueFeedbackSelectedTextLight: '#8689FF',
  colorBubbleShadow:'#C3B7DC',
  colorBubbleDark:'#413E47',
  colorHomepageBackground:'#eaeafd',
  colorHomepageBackgroundDark:'#232057',
  colorPrimaryFilter:'#8B62FF0a',
  colorParticipantsButtonHover:'#DFE3FE',
  colorAudioActiveBackground: '#C5B0FF',
}
