import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { IconButton, Typography, } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { useCommManagerStore, useRtcEngineStore, useUIStore } from "../../stores/hooks";
import { CommUser, MATCH_THIRDPARTY_ALIAS, MATCH_THIRDPARTY_ALIAS_PINYIN } from "../../types/commUser";
import { useStyles } from './style'
import { ReactComponent as IconAudioActive } from "../../assets/icon_big_audio_active.svg";
import { ReactComponent as IconAudioActiveEffect } from "../../assets/icon_big_audio_active_effect.svg";
import { ReactComponent as IconAudioInactive } from "../../assets/icon_big_audio_inactive.svg";
import { ReactComponent as IconVideoActive } from "../../assets/icon_big_video_active.svg";
import { ReactComponent as IconVideoInactive } from "../../assets/icon_big_video_inactive.svg";
import { ReactComponent as IconHost } from "../../assets/icon_host.svg";
import { ReactComponent as IconKick } from "../../assets/icon_kick.svg";
import { ReactComponent as IconSpeaking } from "../../assets/icon_participants_speaking.svg";
import { ReactComponent as IconLeaving } from "../../assets/icon_assistant_leave.svg";

import { Loading } from "../../components/Loading";
import { UserNameTooltip } from '../UserList/UserNameTooltip'
export interface IUserAction {
  index: number,
  user: CommUser,
  onUserAction: (event: any, type: string, user: CommUser) => void,
  style: React.CSSProperties,
  isSearchStatus: boolean,
  onClose: () => any
}

const UserActions = observer((props: IUserAction) => {
  const styles = useStyles();
  const [t] = useTranslation();
  const uiStore = useUIStore()
  const commManager = useCommManagerStore()
  const rtcStore = useRtcEngineStore()
  const { index, user, onUserAction } = props
  const roomInfo = commManager.roomInfo
  const assistantInfo = commManager.assistantInfo

  const highlightUserName = (userItem: CommUser) => {
    if (!props.isSearchStatus) {
      return userItem.userFullName
    }
    if (commManager.isRoomAgora && (userItem.matchType === MATCH_THIRDPARTY_ALIAS || userItem.matchType === MATCH_THIRDPARTY_ALIAS_PINYIN)) {
      return (<span>{userItem.thirdpartyName} (
        {userItem.thirdpartyAlias.slice(0, userItem.matchStart)}
        <span className={styles.highlight}>{userItem.thirdpartyAlias.slice(userItem.matchStart, userItem.matchEnd)}</span>
        {userItem.thirdpartyAlias.slice(userItem.matchEnd)})</span>)
    }
    return (<span>{userItem.userFullName.slice(0, userItem.matchStart)}
      <span className={styles.highlight}>{userItem.userFullName.slice(userItem.matchStart, userItem.matchEnd)}</span>
      {userItem.userFullName.slice(userItem.matchEnd)}</span>)

  }

  const handlerUserActions = (event: any, type: string, userItem: CommUser) => {
    if (((!roomInfo.hasHost && !userItem.isAssistant) || (roomInfo.hasHost && roomInfo.isMeHost))) {
      return onUserAction(event, type, userItem);
    }
    return false
  }

  const handlerUserAssistant = (event: any, type: string, userItem: CommUser) => {
    let actionType = type
    if (type === 'assistant') {
      if (commManager.subscribeAssistant && user.online) {
        actionType = 'assistantListening'
      }
      if (!commManager.subscribeAssistant && assistantInfo.hasAssistant() && user.online) {
        actionType = 'assistantInterpreting'
      }
      if (assistantInfo.hasAssistant() && !user.online) {
        actionType = 'assistantLeave'
      }
      return onUserAction(event, actionType, userItem)
    }
  }

  const isDisableButton = !((!roomInfo.hasHost || (roomInfo.hasHost && roomInfo.isMeHost)) && !user.isMe && (!user.isAssistant || (roomInfo.hasHost && roomInfo.isMeHost && user.isAssistant)))
  const iconStyle = { cursor: isDisableButton ? 'not-allowed' : 'pointer' }
  const unSubscribeAssistant = ((user.isAssistant && commManager.subscribeAssistant) || (!user.isAssistant) || (assistantInfo.isMeAssistant()))
  return (
    <div key={user.streamId} data-key={user.streamId} className={styles.userItem} style={props.style}>
      <div className={styles.userInfo}>
        <UserNameTooltip user={user} placement="left">
          {user.isAssistant ?
          <div className={`${styles.assistantBox}`} id="username-assistant">
            <IconButton
              className={`${styles.assistantNameButton} notranslate`}
              onClick={user.isMe ? () => undefined : event => handlerUserAssistant(event, 'assistant', user)}
            >
              <Typography className={`${styles.assistantName}`} variant="h4">{user.helpName}</Typography>
            </IconButton>
          </div>
          :
            <span className={`${styles.userName} notranslate`} id={`username-${index}`}>{user.userFullName ? highlightUserName(user) : t('Unknown')}</span>}
        </UserNameTooltip>
        {
          !user.online && user.isAssistant ?
          <div className={styles.assistantLeaveText} id="assistant_leave">
            <IconLeaving />
            <Typography variant="subtitle2">{t('as_close_leave_prompt')}</Typography>
          </div>
          :
          <React.Fragment>
            <div className={styles.icons}
            id={`user_audio_${props.index}`}
              onClick={event => user.isAudioPending ? null : handlerUserActions(event, 'audio', user)} style={{ ...iconStyle, visibility: unSubscribeAssistant ? 'inherit' : 'hidden', }}>
              {user.isAudioPending ? <Loading /> :
                user.audioState ?
                  (
                    user.isSpeaking && !user.isMe ? <IconSpeaking className={styles.iconButton} /> :
                      <>
                        {user.isMe ?
                          <>
                            <div className={styles.micBox}>
                              <div className={styles.micShade} style={{ height: `${Math.ceil(rtcStore.volumeLevel  * 100)}%` }} />
                            </div>
                            <IconAudioActiveEffect className={styles.iconButton} />
                          </> :
                          <IconAudioActive className={styles.iconButton} />}
                  </>
                  )
                  : <IconAudioInactive />
              }
            </div>

            <div className={styles.icons}
              id={`user_video_${props.index}`}
              onClick={event => user.isVideoPending ? null : handlerUserActions(event, 'video', user)} style={{ ...iconStyle, visibility: unSubscribeAssistant ? 'inherit' : 'hidden', }}>
              {user.isVideoPending ? <Loading /> :
                user.videoState ? <IconVideoActive className={styles.iconButton} /> : <IconVideoInactive />}
            </div>

          </React.Fragment>
        }

        {user.isHost && <div className={styles.iconHost} id={`user_host_${props.index}`}> <IconHost /></div>}

        {(!roomInfo.hasHost || (roomInfo.hasHost && roomInfo.isMeHost)) && !user.isMe && !user.isAssistant ? <div className={styles.icons}
          id={`user_kick_${props.index}`} onClick={event => handlerUserActions(event, 'kick', user)}>
          <IconKick className={styles.iconButton} />
        </div> : null}
      </div>
      <div className={styles.userState}>
        {user.isCloudRecording && <span className={styles.cloudRecording} id={`user_cloud_recording_${props.index}`}>{t('CloudRecording')}</span>}
        {!user.isLoggedIn && !uiStore.isRoomModeNormal && <span className={styles.noLogin}  id={`user_no_logged_${props.index}`}>{t('NonLogged')}</span>}
        {user.isIssueRecording && <span className={styles.dump}  id={`user_dump_${props.index}`}>{t('participantsTagDump')}</span>}
        {user.isShareOwner ? <span className={styles.screenSharing} id={`user_share_screen_${props.index}`}>{t('participantsTagScreen')}</span> : null}
      </div>
    </div>
  )
});
export default UserActions