import userAgentInfo from './userAgentDetector';
import { CustomizedData } from './protobuf/BIData';
import { isProduction } from './constants';

enum RTM_FAIL_TYPE {
  DO_LOGIN_FAIL = 5,
  JOIN_CHANNEL_FAIL = 6,
}

class Reporter {
  private avcUid: string = ''
  private sid: string = ''
  private deviceType: number
  private osType: number
  private osVersion: string
  private appVersion: string

  private getRtmTokenFailOnce: boolean = false
  private getRtmNodeFailOnce: boolean = false
  private rtmDoLoginFailOnce: boolean = false
  private rtmJoinChannelFailOnce: boolean = false
  private getTokenFailOnce: boolean = false

  public constructor(deviceType: number, osType: number, osVersion: string, appVersion: string) {
    this.deviceType = deviceType
    this.osType = osType
    this.osVersion = osVersion
    this.appVersion = appVersion

    this.setupReportFlag()
  }

  setSid(uuid: string) {
    this.sid = uuid
  }

  setUid(uid: string) {
    this.avcUid = uid
  }

  setupReportFlag() {
    this.getRtmNodeFailOnce = false
    this.getRtmTokenFailOnce = false
    this.rtmDoLoginFailOnce = false
    this.rtmJoinChannelFailOnce = false
    this.getTokenFailOnce = false
  }

  reportAppInit() {
    this.report(11, true, 0)
  }

  report(eventType: number, success: boolean, ec: number, elapsed?: number, requestType?: string) {
    var data = new CustomizedData({ eventType, success, ec, elapsed, requestType, sid: this.sid, avcUid: this.avcUid, lts: (+new Date()), index: { index1: this.sid, index2: this.avcUid }, osType: this.osType, osVersion: this.osVersion, deviceType: this.deviceType, appVersion: this.appVersion },)

    console.log(data)

    if (!isProduction) return

    //statscollector-2.agora.io（备）
    fetch(`https://statscollector-1.agora.io:6443/events/proto-raw?id=${159}&sentTs=${Math.ceil(Date.now() / 1000).toString()}`, {
      body: CustomizedData.encode(data).finish(),
      method: 'POST',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
    })
  }

  promiseDecorate(p: any, eventType: number, requestType?: string) {
    const current = +new Date();
    return p
      .then((_: any) => {
        this.report(eventType, true, 0, (+new Date() - current), requestType);
        return _;
      })
      .catch((_: any) => {
        do {
          if (requestType === 'v1/account/getRtmToken') {
            if (this.getRtmTokenFailOnce === false) {
              this.getRtmTokenFailOnce = true
            } else {
              break
            }
          }

          if (requestType === "v1/account/roomInfo") {
            if (this.getRtmNodeFailOnce === false){
              this.getRtmNodeFailOnce = true
            } else {
              break
            }
          }

          if (eventType === RTM_FAIL_TYPE.DO_LOGIN_FAIL) {
            if (this.rtmDoLoginFailOnce === false) {
              this.rtmDoLoginFailOnce = true
            } else {
              break
            }
          }

          if (eventType === RTM_FAIL_TYPE.JOIN_CHANNEL_FAIL) {
            if (this.rtmJoinChannelFailOnce === false) {
              this.rtmJoinChannelFailOnce = true
            } else {
              break
            }
          }

          this.report(eventType, false, _.code || 0, (+new Date() - current), requestType);
        } while (false)
        throw _;
      });
  }
}

const reporter = new Reporter(
  0,// https://confluence.agoralab.co/pages/viewpage.action?pageId=635295917 deviceType is string, but https://cheelah.agoralab.co/reportdata/events/159 set proto type is number, so set to 0
  5,//web is 5
  userAgentInfo.browser.version || '',
  process.env.REACT_APP_APP_VERSION || ''
)

export { reporter }
