import { action } from "mobx";
import { localStorage as storage } from '../utils/Storage';

export class UserPreference {
  shareTip: boolean = false
  loginTip: boolean = false
  chatOpen: boolean = false
  isParticipantsOpen: boolean = false

  constructor() {
    const storageInfo = storage.read('userPreference')
    this.updateFromLocalStorage(storageInfo)
  }

  @action
  public updateFromLocalStorage(preference: any): void {
    if (!preference) return
    
    Object.keys(preference).forEach(key => {
      (this as any)[key] = preference[key]
    })
  }

  public updateLocalData() {
    storage.save('userPreference', this)
  }
}
