import { makeStyles, createStyles, Theme } from "@material-ui/core"

export const useStyles = makeStyles((theme: Theme) => {
  const { customColor: { gdprBorderColor, componentsBackGround } } = theme
  const backgroundColor = componentsBackGround

  return createStyles({
    root: {
      boxShadow: 'none',
      fontSize: '0.875rem',
    },
    dialogActions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1.9375rem 15.25rem',
    },
    dialogContent: {
      width: '46.375rem',
      border: `1px solid ${gdprBorderColor}`,
      margin: '0 auto',
      overflowY: 'scroll',
      '& p': {
        fontSize: '0.75rem',
      },
      '&::-webkit-scrollbar': {
        width:'5px',
        backgroundColor: backgroundColor,
      },
      '&::-webkit-scrollbar-track':{
        width:'5px',
        backgroundColor: backgroundColor,
      },
      '&::-webkit-scrollbar-thumb':{
        borderRadius: '0.1875rem',
        backgroundColor:'#CDD2DCFF',
      }
    },
    contentTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      // paddingLeft: '0.5rem',
    },
    paperWidthMd: {
      width: '49.875rem',
      height: '49rem',
      borderRadius: '0.75rem',
      overflow: 'hidden',
      boxShadow: '0rem 0.1875rem 2.0625rem 0rem rgba(0, 0, 0, 0.19)',
    },
    content: {
      '& h6': {
        margin: '0.8rem 0',
        lineHeight: '1.5625rem',
        fontWeight: '500',
      },
    },
  })
})
