import AgoraRTC, {
  ConnectionState,
  IAgoraRTCClient,
  ILocalVideoTrack,
  NetworkQuality, ScreenVideoTrackInitConfig
} from "agora-rtc-sdk-ng";
import { Metadata } from "../../types/metadata";
import { APPID, ENCRYPTION_MODE, SDK_ENCRYPTION_MODE } from "../../utils/constants";
import { log, logException, logIf, LOG_MODULE, LOG_TYPE } from "../../utils/Log";
import "../../utils/protobuf/RTCMetadata";
import userAgentInfo from "../../utils/userAgentDetector";
import { Aes128GcmEncrypt, GenerateAes128GcmCryptoKey, getRtcEncryptionKey } from "../../utils/tools";
import { showQualityLog } from "../model/NetworkEval";
import { NotificationStore } from "../notification";
import { RtcCommLayerStore } from "./rtcCommLayer";
import { CHANNL_MODE, CODEC, METADATA_ENABLED, METADATA_INTERVAL, DATA_STREAM_ENABLED } from "./rtcEngineLayer";

// tslint:disable-next-line: no-var-requires
const protobuf = require("protobufjs/minimal").roots.default.protobuf

export default class RtcShareLayer {
  private shareClient?: IAgoraRTCClient
  private shareVideoTrack?: ILocalVideoTrack
  private connState: ConnectionState = 'DISCONNECTED'

  private shareIntervalID?: number
  private shareDataStreamIntervalID?: number
  private shareMetadataUint8Array: Uint8Array | undefined
  private dataStreamMetadataUint8Array: Uint8Array | undefined
  private shareMetadata: Metadata = new Metadata()

  private rtcComm: RtcCommLayerStore
  private notification: NotificationStore

  constructor(rtcComm: RtcCommLayerStore, notification: NotificationStore) {
    this.rtcComm = rtcComm
    this.notification = notification
  }

  public async createShareTrack() {
    log(`create share track`, LOG_TYPE.INFO, LOG_MODULE.SHARE)

    const shareConfig: ScreenVideoTrackInitConfig = {
      encoderConfig: { width: 1920, height: 1080, frameRate: 5 },
      electronScreenSourceId: 'window',
      screenSourceType: 'window',
      // https://jira.agoralab.co/browse/APP-3796: vp9 av1
      scalabiltyMode: '3SL3TL',
    }

    // If user Chrome is low version, still allow use extension
    if (userAgentInfo && userAgentInfo.browser.name === 'Chrome') {
      const info = userAgentInfo.browser && userAgentInfo.browser.version && userAgentInfo.browser.version.split('.')
      if (info && Number(info[0]) < 72) {
        if (navigator && navigator.mediaDevices) {
          shareConfig.extensionId = 'minllpmhdgpndnkomcoccfekfegnlikg'
        }
      }
    }

    try {
      this.shareVideoTrack = await AgoraRTC.createScreenVideoTrack(shareConfig, 'disable')

      this.shareVideoTrack.on("track-ended", () => {
        log("screen track end", LOG_TYPE.INFO, LOG_MODULE.SHARE)
        this.rtcComm.onShareWindowEnd()
      })

      return true
    } catch (e) {
      logException("create share track exception", e, LOG_MODULE.SHARE)

      if (e.code === "PERMISSION_DENIED") {
        if (e.message && (e.message as string).indexOf("Permission denied by system") >= 0) {
          this.notification.addAlert(true, "ScreenShareDeniedBySystem")
        }
        // else {
        //   this.notification.toast("ScreenShareDenied")
        // }
      } else {
        this.notification.addAlert(true, "ScreenShareBrowserUpdate")
      }
      return false
    }
  }

  public closeShareTrack() {
    log(`cancle share track, track: ${this.shareVideoTrack}`, LOG_TYPE.INFO, LOG_MODULE.SHARE)

    if (this.shareVideoTrack) {
      this.shareVideoTrack.close()
      this.shareVideoTrack = undefined
    }
  }

  private lastCodec = ""

  public async startScreenShare(codec: string, channelName: string, shareId: number, token: string,parentStreamId: number, name: string, rtcEncryption: boolean, rtcSecretKey: string,
                                encryptionMode: SDK_ENCRYPTION_MODE,
                                salt: Uint8Array | undefined, status?: number) {
    log(`startScreenShare share id: ${shareId} parent stream id: ${parentStreamId} name: ${name},channelName:${channelName}`, LOG_TYPE.INFO, LOG_MODULE.SHARE)
    if (!this.shareClient || this.lastCodec !== codec) {
      this.shareClient = AgoraRTC.createClient({
        codec: (codec as any), mode: CHANNL_MODE
      })

      this.shareClient.on("connection-state-change", (curState: any) => {
        log(`share rtc event: connection-state-change ${curState}`, LOG_TYPE.INFO, LOG_MODULE.SHARE)

        this.connState = curState
      })

      this.shareClient.on("network-quality", this.onShareNetworkQuality.bind(this))
    }

    this.shareClient.disableDualStream().catch((error: any) => {
      logException(`disable share screen dual stream failure `, error, LOG_MODULE.RTC)
    })

    try {
      if (rtcEncryption) {
        if (encryptionMode !== SDK_ENCRYPTION_MODE.AES_128_GCM) {
          throw new Error(`share screen Unsupported encryption type:${encryptionMode}`)
        }
        log(`share screen encryptionMode ${encryptionMode}`)
        this.shareClient.setEncryptionConfig(encryptionMode, rtcSecretKey)
      }
      await this.shareClient.join(APPID, channelName, token, shareId)

    } catch (error) {
      logException(`share join channel exception `, error, LOG_MODULE.SHARE)

      this.resetShareClient()

      throw error
    }

    log(`startScreenShare join channel over`, LOG_TYPE.INFO, LOG_MODULE.SHARE)

    try {
      await this.shareClient.publish(this.shareVideoTrack!)
    } catch (error) {
      logException(`publish share track exception`, error, LOG_MODULE.SHARE)

      // user may already call leave channel
      if (this.isInChannel()) {
        this.resetShareClient()
      }

      throw error
    }

    this.prepareShareMetadata(name, parentStreamId, status || 0)
    this.prepareDataStreamMetaData(this.shareMetadataUint8Array, rtcEncryption, rtcSecretKey)

    this.startSendShareMetadata()
    this.startSendDataStream()
  }

  public async stopScreenShare() {
    log("stopScreenShare", LOG_TYPE.INFO, LOG_MODULE.SHARE)

    await this.resetShareClient()
  }

  public async resetShareClient() {
    log("reset share client", LOG_TYPE.INFO, LOG_MODULE.SHARE)

    this.closeShareTrack()

    if (this.shareClient) {
      if (this.isInChannel()) {
        try {
          await this.shareClient.leave()
        } catch (error) {
          logException(`reset share client, client leave excpetion `, error, LOG_MODULE.SHARE)
        }

        this.stopSendShareMetadata()
        this.stopSendShareDataStream()
      }
    }
  }

  public isInChannel() {
    return this.connState !== 'DISCONNECTED' && this.connState !== 'DISCONNECTING'
  }

  private onShareNetworkQuality(res: NetworkQuality) {
    logIf(showQualityLog(), `share network-quality up: ${res.uplinkNetworkQuality} down: ${res.downlinkNetworkQuality}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    this.rtcComm.onEvalShareNetworkQuality(res.uplinkNetworkQuality)
  }

  /*************************************** METADATA ************************************/

  private prepareShareMetadata(name: string, parentStreamId: number, status: number) {
    const user = new protobuf.User({
      name,
      parentStreamId,
      status
    })

    this.shareMetadata.setAllocatedUser(user)
    this.shareMetadata.clearBiz()
    this.shareMetadata.clearCtrl()

    this.setMetaData(this.shareMetadata)
  }

  private setMetaData(data: any) {
    log(`update share metadata: ${JSON.stringify(data)}`, LOG_TYPE.INFO, LOG_MODULE.SHARE)

    this.shareMetadataUint8Array = protobuf.Metadata.encode(data).finish()
  }

  private async prepareDataStreamMetaData(data: Uint8Array | undefined, isEncrypted: boolean, encryptionKey: string) {
    if (!isEncrypted || !encryptionKey || !data) {
      this.dataStreamMetadataUint8Array = data
      return
    }

    const cyptoKey = await GenerateAes128GcmCryptoKey(encryptionKey)

    this.dataStreamMetadataUint8Array = await Aes128GcmEncrypt(data, cyptoKey)
  }

  private startSendShareMetadata() {
    if (!this.shareIntervalID && METADATA_ENABLED) {
      log('startSendShareMetadata', LOG_TYPE.INFO, LOG_MODULE.SHARE)

      this.shareIntervalID = window.setInterval(() => { this.sendShareMetadata() }, METADATA_INTERVAL)
    }
  }

  private stopSendShareMetadata() {
    if (this.shareIntervalID) {
      log('stopSendShareMetadata', LOG_TYPE.INFO, LOG_MODULE.SHARE)

      clearInterval(this.shareIntervalID)
      this.shareIntervalID = undefined
    }
  }

  public async sendShareMetadata() {
    if (!this.shareMetadataUint8Array || !this.shareClient) return
    await (this.shareClient as any).sendMetadata(this.shareMetadataUint8Array).then((res: any) => {
      console.log('sendShareMetadata success')
    }).catch((e: any) => {
      log(`sendShareMetadata failed ${e.toString}`, LOG_TYPE.ERROR, LOG_MODULE.SHARE)
    })
  }

  /*************************************** Data Stream ************************************/

  private startSendDataStream() {
    this.sendShareDataStream()
    if (!this.shareDataStreamIntervalID && DATA_STREAM_ENABLED) {
      log('startSendDataStream', LOG_TYPE.INFO, LOG_MODULE.SHARE)
      this.shareDataStreamIntervalID = window.setInterval(() => { this.sendShareDataStream() }, METADATA_INTERVAL)
    }
  }

  public async sendShareDataStream() {
    if (!this.dataStreamMetadataUint8Array || !this.shareClient || this.connState !== 'CONNECTED') return
    await (this.shareClient as any).sendStreamMessage(this.dataStreamMetadataUint8Array).catch((e: any) => {
      // log(`sendShareDataStream failed ${e.toString}`, LOG_TYPE.ERROR, LOG_MODULE.SHARE)
    })
  }

  private stopSendShareDataStream() {
    if (this.shareDataStreamIntervalID) {
      log('stopSendShareDataStream', LOG_TYPE.INFO, LOG_MODULE.SHARE)
      clearInterval(this.shareDataStreamIntervalID)
      this.shareDataStreamIntervalID = undefined
    }
  }

}
