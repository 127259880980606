import React from "react";
import { ReactComponent as IconLoading } from "../assets/icon_loading.svg";
import { createStyles, makeStyles, Theme } from '@material-ui/core'
const useStyle = makeStyles((theme: Theme) => {
  return createStyles({
    loading: {
      animation: '$rotating 2s linear infinite',
    },
    '@keyframes rotating': {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '100%': {
        transform: 'rotate(360deg)'
      }
    },
  })
})
export const Loading = (props:any) => {
  const styles = useStyle()
  return (
      <IconLoading className={styles.loading}  {...props}/>)

}
