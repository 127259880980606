import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { color, colorAgora, colorCommon, colorDefault } from "../../style/color";

const useStyle = makeStyles((theme: Theme) => {
  const {
    customColor: {
      issueDialogTitle,
      issueDialogTitleBorder,
      issueDialogContent,
      issueStreamId,
      issueButtonSelected,
      issueFeedBackInfoText,
      issueFeedBackTextArea,
      issueFeedBackResult,
      issueFeedBackDialogText,
      selectMenuItemHover,
      issueFeedBackCancelBackground,
      issueButtonSelectedText,
      selectedColor,
    }
  } = theme

  return createStyles({
    // common
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      borderRadius: '0.625rem',
    },
    box: {
      width: '38.1875rem',
      boxShadow: '0px 2px 24px 0px rgba(0, 0, 0, 0.19)',
      borderRadius: '0.625rem',
      outline: 0,
      boxSizing: 'border-box',
    },
    titleBox: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '3.75rem',
      paddingLeft: '1.5rem',
      borderRadius: '.625rem .625rem 0 0',
      borderBottom: '.0625rem solid',
      borderBottomColor: issueDialogTitleBorder,
      background: issueDialogTitle
    },
    issueBox: {
      width: '100%',
      paddingLeft: '1.5rem',
      paddingBottom: '1.375rem',
      borderRadius: '0 0 .625rem .625rem',
      background: issueDialogContent
    },
    userName: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '.5rem 0 .625rem 0',
    },
    streamId: {
      color: issueStreamId,
      marginTop: '0.25rem',
    },
    issueItemBox: {
      display: 'flex',
      flexDirection: 'column',
    },
    audioTitleBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: '1.5rem',
    },
    audioTitleTip: {
      display: 'flex'
    },
    audioRecordTip: {
      paddingLeft: '.75rem',
      color: colorCommon.colorIssueTipTextGrey
    },
    recordBtn: {
      height: '1.25rem',
      fontSize: '.625rem',
    },
    rateText: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: '.5rem',
      '& > .MuiButton-root': {
        minWidth: 0
      }
    },
    issueTextAreaBox: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '35.1875rem',
      height: '4.25rem',
      marginTop: '.5rem',
      marginBottom: '1rem'
    },
    multiline: {
      height: '4.25rem',
      padding: '.5625rem 1.0625rem',
      lineHeight: '.75rem',
      overflow: 'hidden'
    },
    textFieldFont: {
      fontSize: '.75rem',
      fontWeight: 400,
      overflow: 'hidden',
      lineHeight: '1.13rem',
    },
    textFieldCaret: {
      caretColor: issueFeedBackTextArea,
      borderRadius:'0.375rem',
      '& > fieldset': {
        top: '-4px',
      }
    },
    issueDescribe: {
      position: 'relative',
    },
    issueTextNumber: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      fontSize: '.75rem',
      transform: 'scale(0.83)',
      transformOrigin: '0 0',
      color: issueFeedBackInfoText,
    },
    selectedNormal: {
      color: issueButtonSelectedText,
      backgroundColor: issueButtonSelected,
      boxShadow: 'none',
      // '&:hover': {
      //   color: color.colorWhite,
      //   backgroundColor: color.colorPrimary,
      //   boxShadow: 'none',
      // },
      // '&:active': {
      //   color: color.colorWhite,
      //   backgroundColor: color.colorPrimary,
      //   boxShadow: 'none',
      // },
    },
    seletedAgora: {
      color: issueButtonSelectedText,
      backgroundColor: issueButtonSelected,
      boxShadow: 'none',
    },
    peerSelect: {
      width: '35.1875rem',
      marginTop: '.5rem',
      fontSize: '0.75rem',
      borderRadius: '0.375rem',
    },
    menuItem: {
      width: '35.1875rem',
      height: '1.6rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '0.75rem',
      '&:hover': {
        background: selectedColor
      },
      '&.MuiListItem-root.Mui-selected': {
        backgroundColor: `${selectedColor}`,
        '&:hover': {
          backgroundColor: `${selectedColor}`,
        }
      },
    },
    selectWidth: {
      width: '35.1875rem',
      fontSize: '0.75rem',
    },
    buttonBox: {
      display: 'flex',
      flexDirection: 'row-reverse',
      width: '35.1875rem',
      marginTop: '1.125rem',
      '& > .MuiButton-contained.Mui-disabled': {
        color: '#fff',
      }
    },
    issueButton: {
      height: '1.875rem',
      fontSize: '.75rem',
    },
    issueButtonCancel: {
      backgroundColor: issueFeedBackCancelBackground,
    },
    audioIcon: {
      width: '1.2rem',
      height: '1rem',
    },
    feedbackTextareaModal: {
      display: 'flex',
      paddingTop: '1.4rem',
      position: 'relative',
      marginTop: '1.4rem',
      width: '30.25rem',
      boxSizing: 'border-box',
      backgroundColor: '#f9f9f9',
      paddingBottom: '0.65rem',
      borderRadius: '0.2rem',
      marginBottom: '1.2rem',
    },
    feedbackTextNumber: {
      position: 'absolute',
      fontSize: '0.6rem',
      color: '#909399',
      right: '2.1rem',
      bottom: '1rem',
    },
    fullText: {
      color: '#FF2A5B',
    },

    buttonGroup: {
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    disableButton: {
      border: 'none',
      backgroundColor: color.colorWhite,
      width: '4.95rem',
      height: '0.95rem',
      color: "#000",
      fontSize: '0.65rem',
      marginRight: '0.7rem',
    },

    submitFeedbackResult: {
      display: 'flex',
      flexDirection: 'column',
      width: '18.25rem',
      height: '11.625rem',
      backgroundColor: issueFeedBackResult,
    },
    submitFeedbackSuccess: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      fontSize: '.625rem',
      fontWeight: 400,
      lineHeight: '1rem',
      textAlign: 'center',
    },
    feedbackStatusText: {
      fontSize: '1rem',
      fontWeight: 600,
      display: "inline-block",
      padding: "1rem 0 .5rem 0"
    },
    feedbackInfoColor: {
      color: issueFeedBackDialogText
    },
    feedbackInfoBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    cpNumber: {
      color: '#FF2A5B',
      fontSize: '.625rem'
    },
    retryButton: {
      width: '5.75rem',
      height: '1.875rem',
      color: '#fff',
      background: '#FF4A4B',
      borderRadius: ' 0.3rem',
      marginTop: '0.75rem',
      '&:hover': {
        background: '#ED455F'
      }
    },
    closeButtonBox: {
      display: 'flex',
      flexDirection: 'row-reverse',
      width: '18.25rem',
    },
    closeButton: {
      width: '1rem',
      height: '1rem',
      margin: '.375rem .375rem 0 0',
      padding: 0
    },
    dialogBorder: {
      '& > div': {
        borderRadius: '.625rem',
      }
    },
  })
})

export default useStyle

