declare var window: any

class Storage {
  public storage: any

  constructor(type: string  = 'localStorage') {
    if (['sessionStorage', 'localStorage'].indexOf(type) === -1) {
      throw console.error('Type can only be session/local storage')
    }
    this.storage = window[type]
  }

  save(key: string | undefined, value?: any) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  saveMulti(datas: any) {
    if (datas instanceof Array) {
      for (const item of datas) {
        this.save(item.key, item.value)
      }
    } else if (datas instanceof Object) {
      const keys = Object.keys(datas)
      for (const key of keys) {
        this.save(key, datas[key])
      }
    }
  }

  read(key: string) {
    try {
      let result = JSON.parse(this.storage.getItem(key))
      return result
    } catch (err) {
      return this.storage.getItem(key)
    }
  }

  readMulti(keys: string[] = []) {
    return keys.map((key: string) => this.read(key))
  }

  clear(key: string) {
    this.storage.removeItem(key)
  }

  clearMulti(keys: string[] = []) {
    for (const key of keys) {
      this.clear(key)
    }
  }

  clearAll() {
    this.storage.clear()
  }
}

export const sessionStorage = new Storage('sessionStorage')
export const localStorage = new Storage('localStorage')
