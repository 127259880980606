import React, { forwardRef, Ref, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { useUIStore, useUserStore } from "../../stores/hooks";
import { useTranslation } from 'react-i18next';
import { logoutByWeChatWork } from "../../stores/connector/http";
import { logException, LOG_MODULE } from "../../utils/Log";
import useStyle from "./style";
import { CustomAlert } from '../CustomPopup/customAlert'

export const THIRDPARTY_LOGIN_CALLBACK = (process.env.REACT_APP_ENV === 'production') ? process.env.REACT_APP_LOGIN_CALLBACK : process.env.REACT_APP_LOGIN_CALLBACK_TEST
export const THIRDPARTY_LOGIN_AGENTID = (process.env.REACT_APP_ENV === 'production') ? process.env.REACT_APP_LOGIN_AGENTID : process.env.REACT_APP_LOGIN_AGENTID_TEST

interface AccountViewProps {
  onChangeMode?: (open: boolean) => void
}

const AccountView = observer(forwardRef((props: AccountViewProps, ref: Ref<HTMLDivElement>) => {
  const styles = useStyle()
  const user = useUserStore()
  const [t] = useTranslation()
  const uiStore = useUIStore()
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false)
  const [logoutDisabled, setLogoutDisabled] = useState(false)

  const logout = async () => {
    try {
      await logoutByWeChatWork(user.user.thirdPartyUser!.session)
      uiStore.changeModeToNormal()
      if (props.onChangeMode) {
        props.onChangeMode(false)
      }
    } catch (error) {
      logException(`logout by wechat work failure`, error, LOG_MODULE.DEFAULT)
    } finally {
      user.clearWeChatWorkUser()
      setLogoutDialogOpen(false)
      setLogoutDisabled(false)
    }
  }

  return (
    <div className={styles.contentBox} id="account" ref={ref}>
      <Box>
        <Typography variant="h3" >{t('Account')}</Typography>
        {user.isThirdPartyLoggedIn ? (
        <Box className={styles.commContainer}>
          <Typography id="account-username" variant="subtitle1" className={'notranslate'}>{user.thirdPartyUserName}</Typography>
          <Button onClick={() => {setLogoutDialogOpen(true);setLogoutDisabled(true)}} variant="outlined" color="secondary" disabled={logoutDisabled}>{t('Logout')}</Button>
        </Box>) : (
          <Box className={styles.loginBox}>
            <Typography variant="subtitle1">{t('PrivateRoomNotice')}</Typography>
            <Box className={styles.wxLoginBox}>
              <iframe width={300} height={378} frameBorder={0} scrolling={'no'} />
            </Box>
          </Box>
        )}
      </Box>
      <CustomAlert
        isOpen={logoutDialogOpen}
        content={<Typography variant="subtitle2">{t('Sign out of the account？')}</Typography>}
        onClose={() => {setLogoutDialogOpen(false);setLogoutDisabled(false)}}
        style={styles.logoutDialog}
        isNeedPosition={false}
        isAlert={true}
        buttonGroups={
          <Box className={styles.logoutButtonBox}>
            <Button variant="contained" className={styles.logoutButton} onClick={() => {setLogoutDialogOpen(false);setLogoutDisabled(false)}}>
              <Typography variant="h4">{t('Cancel')}</Typography>
            </Button>
            <Button variant="contained" color="primary" className={styles.logoutButton} onClick={logout}>
              <Typography variant="h4">{t('Sign out')}</Typography>
            </Button>
          </Box>
        }
      />
    </div>
  )
}))

export default AccountView
