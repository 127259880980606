import AgoraRTM from 'agora-rtm-sdk';
import { action, computed, observable } from "mobx";
import { BizRoomInfo, JoinRoomResult } from "../../types/bizRoomInfo";
import { AssistantInfo } from "../../types/assistantInfo";
import { BizUser, UserOperation, UserOperationDetail, UserOperationEx } from "../../types/bizUser";
import { ChatMessage } from "../../types/chatMessage";
import { MyRequestResponse, OP_TYPE, UserRequest, SelfApplyAssistantResponse } from "../../types/userRequest";
import { APPID, BI_REPORT_EVENT, RTMConnectState } from "../../utils/constants";
import { log, logException, LOG_MODULE, LOG_TYPE } from "../../utils/Log";
import { reporter } from "../../utils/Reporter";
import { getRtmToken, isTokenError } from "../connector/http";
import { NotificationStore } from "../notification";
import { RootStore } from "../rootStore";

export class RtmError {
  public code: number = 0 // unknown error
  public desc?: string
  public msgId?: number

  constructor(code: number, desc?: string, msgId?: number) {
    this.code = code
    this.desc = desc
    this.msgId = msgId
  }
}

export class RtmEngineLayerStore {

  private rtmInstance: any
  private rtmChannel: any
  public serverId: string = ''

  @observable
  private connectionState: RTMConnectState = RTMConnectState.DISCONNECTED
  private selfUid: string = ''
  private requestOnline = false
  private autoLoginRetryTimes: number = 0
  public testLogoutEnable: boolean = false

  public messageId: number = 0// starts with 0
  public chatSeqId: number = 0

  private rootStore: RootStore
  private notification: NotificationStore

  public constructor(rootStore: RootStore, notification: NotificationStore) {
    this.rootStore = rootStore
    this.notification = notification

    this.initRTMClient()
  }

  private initRTMClient() {
    this.rtmInstance = AgoraRTM.createInstance(
      APPID,
      {
        enableLogUpload: true,
        logFilter: AgoraRTM.LOG_FILTER_INFO
      }
    )

    this.rtmInstance.on('MessageFromPeer', ({ text }: { text: any }, peerId: string) => {
      let isPullLog = false
      try {
        const rtmRes = JSON.parse(text);
        isPullLog = rtmRes.cmd === "pullLog";
      } catch (err) {
        logException(`handle server message exception `, err, LOG_MODULE.RTM)
      }
      if (peerId !== this.serverId && !isPullLog) {
        log(`receive from unknown peer: ${peerId} text: ${text}`, LOG_TYPE.ERROR, LOG_MODULE.RTM)
        return
      }
      log(`rtm client recv msg. from ${peerId}, text: ${text.length > 2000 ? "..." + text.substring(text.length - 2000) : text}`, LOG_TYPE.COMM, LOG_MODULE.RTM)
      this.handleServerMessage(text)
    });

    this.rtmInstance.on('TokenExpired', this.handleTokenExpired.bind(this))

    this.rtmInstance.on('ConnectionStateChanged', this.onConnectionStateChanged.bind(this))
  }

  /**************** RTM LOGIN ***************/

  public requestLogin(uid: string) {
    log(`request login`, LOG_TYPE.INFO, LOG_MODULE.RTM)

    this.selfUid = uid

    if (this.requestOnline) return

    this.requestOnline = true

    this.checkOnlineState()
  }

  public requestLogout() {
    log(`request logout`, LOG_TYPE.INFO, LOG_MODULE.RTM)

    this.selfUid = ""

    if (!this.requestOnline) return

    this.requestOnline = false
    this.checkOnlineState()
  }

  private checkOnlineState() {
    log(`check online state, reqeust: ${this.requestOnline}, current state: ${RTMConnectState[this.connectionState]}`, LOG_TYPE.INFO, LOG_MODULE.RTM)

    if (this.requestOnline) {
      if (this.connectionState === RTMConnectState.DISCONNECTED) {
        this.startLogin() // only wait DISCONNECTED to allow new login connection
      }
    } else {
      if (this.connectionState !== RTMConnectState.DISCONNECTED) {
        this.startLogout() // other state all allow logout
      }
    }
  }

  private startLogin() {
    if (this.testLogoutEnable) {
      log("testLogoutEnable true, post retry", LOG_TYPE.INFO, LOG_MODULE.RTM)

      this.rootStore.rtmCommLayer.onConnectStateChanged(this.connectionState)// notify, in case biz state not accurate

      this.postRetryCheck()
      return
    }

    if (this.connectionState !== RTMConnectState.DISCONNECTED) {
      log(`startLogin current connection state: ${RTMConnectState[this.connectionState]}, return`, LOG_TYPE.INFO, LOG_MODULE.RTM)
      return
    }

    getRtmToken().then((res: any) => {

      this.doLogin(res.data.token)

    }).catch((e: any) => {
      // this.rootStore.rtmCommLayer.onConnectStateChanged(this.connectionState)// notify, in case biz state not accurate

      // Token 错误时，重试也没用
      // https://jira.agoralab.co/browse/APP-2098
      if (!isTokenError(e)) {
        this.postRetryCheck()
      }
    })
  }

  private async doLogin(token: string) {
    if (this.connectionState !== RTMConnectState.DISCONNECTED) {
      log(`doLogin current connection state: ${RTMConnectState[this.connectionState]}, return`, LOG_TYPE.INFO, LOG_MODULE.RTM)
      return
    }

    if (!this.requestOnline) {
      log(`doLogin requestOnline ${this.requestOnline}, return`, LOG_TYPE.INFO, LOG_MODULE.RTM)
      return
    }

    const uid = this.selfUid

    log("doLogin", LOG_TYPE.INFO, LOG_MODULE.RTM)

    try {
      await reporter.promiseDecorate(
        this.rtmInstance.login({
          uid,
          token
        }), BI_REPORT_EVENT.RTM_DO_LOGIN)

      log('rtm client login success', LOG_TYPE.COMM, LOG_MODULE.RTM)
    } catch (error) {
      logException(`rtm login error`, error, LOG_MODULE.RTM)

      this.rootStore.rtmCommLayer.onConnectStateChanged(this.connectionState)// notify, in case biz state not accurate

      this.postRetryCheck()
    }
  }

  private async handleTokenExpired() {
    log(`rtm token expired!`, LOG_TYPE.COMM, LOG_MODULE.RTM)

    const res = await getRtmToken()

    try {
      log('rtm do renew token', LOG_TYPE.COMM, LOG_MODULE.RTM)

      await this.rtmInstance.renewToken(res.data.token)

      log('rtm renew token success', LOG_TYPE.COMM, LOG_MODULE.RTM)
    } catch (e) {
      logException(`rtm renew token failure`, e, LOG_MODULE.RTM)
    }
  }

  private postRetryCheck() {
    this.autoLoginRetryTimes++

    log(`postRetryCheck, count: ${this.autoLoginRetryTimes}`, LOG_TYPE.ERROR, LOG_MODULE.RTM)

    window.setTimeout(() => {
      this.checkOnlineState()
    }, 3000)
  }

  public async startLogout() {
    if (this.connectionState === RTMConnectState.DISCONNECTED) {
      log(`startLogout current connection state: ${RTMConnectState[this.connectionState]}, return`, LOG_TYPE.INFO, LOG_MODULE.RTM)
      return
    }

    log("startLogout", LOG_TYPE.INFO, LOG_MODULE.RTM)

    this.leaveChannel()

    try {
      log('rtm client logout', LOG_TYPE.COMM, LOG_MODULE.RTM)

      await this.rtmInstance.logout()

      log('rtm client logout success', LOG_TYPE.COMM, LOG_MODULE.RTM)
    } catch (error) {
      logException(`rtm logout exception: `, error, LOG_MODULE.RTM)
    }
  }

  private onConnectionStateChanged(state: string, reason: string) {
    log(`rtm on connection state changed, state: ${state}, reason: ${reason}`, LOG_TYPE.COMM, LOG_MODULE.RTM)

    let tmpState = this.connectionState
    switch (state) {
      case 'CONNECTED':
        tmpState = RTMConnectState.CONNECTED
        this.autoLoginRetryTimes = 0
        break
      case 'ABORTED':
        tmpState = RTMConnectState.ABORTED
        break
      case 'CONNECTING':
        tmpState = RTMConnectState.CONNECTING
        break
      case 'DISCONNECTED':
        tmpState = RTMConnectState.DISCONNECTED
        break
      case 'RECONNECTING':
        tmpState = RTMConnectState.RECONNECTING
        break
      default:
        log(`onConnectionStateChanged unrecogonized state ${tmpState}`, LOG_TYPE.ERROR, LOG_MODULE.RTM)
        return;
    }

    if (this.connectionState === tmpState) return

    this.setConnectState(tmpState)

    // Avoid sigle thread reassigment of this.connectionState, so put action last
    if (this.connectionState === RTMConnectState.ABORTED) {
      this.startLogout()

      if (reason === "REMOTE_LOGIN") {
        this.notification.addAlert(true, "RtmAbortRemoteLogin")
        // If remote needs login, not try login again
      } else {
        this.postRetryCheck()
      }
    } else {
      this.checkOnlineState()
    }
  }

  @action
  private setConnectState(state: RTMConnectState) {
    log(`set rtm conn state: ${RTMConnectState[state]}`, LOG_TYPE.INFO, LOG_MODULE.RTM)

    this.connectionState = state

    this.rootStore.rtmCommLayer.onConnectStateChanged(this.connectionState)
  }

  public testLogout(enable: boolean) {
    this.testLogoutEnable = enable

    if (enable) {
      this.startLogout()
    }
  }

  @computed
  public get isConnected() {
    return this.connectionState === RTMConnectState.CONNECTED
  }

  /**************** RTM CHANNEL ***************/

  @action
  public async joinChannel(channelId: string) {
    log(`rtm join channel ${channelId}`, LOG_TYPE.COMM, LOG_MODULE.RTM)

    if (this.rtmChannel && this.rtmChannel.channelId === channelId) {
      log(`join rtm channel, already in channel: ${channelId}`, LOG_TYPE.INFO, LOG_MODULE.RTM)
      return
    }

    if (this.rtmChannel) {
      this.leaveChannel()
    }

    this.rtmChannel = this.rtmInstance.createChannel(channelId)
    log(`rtm create channel: ${channelId}`, LOG_TYPE.COMM, LOG_MODULE.RTM)

    this.handleChannelEvents()

    try {
      await reporter.promiseDecorate(this.rtmChannel.join(), BI_REPORT_EVENT.RTM_JOIN_CHANNEL)
      log(`rtm join channel: ${channelId} success`, LOG_TYPE.COMM, LOG_MODULE.RTM)
    } catch (e) {
      logException(`rtm join channel: ${channelId} failure, error: ${e.code}, desc: ${e.desc}`, e, LOG_MODULE.RTM)
      throw e
    }
  }

  @action
  public leaveChannel() {
    if (this.rtmChannel) {
      log("leave rtm channel", LOG_TYPE.INFO, LOG_MODULE.RTM)

      this.rtmChannel.leave()
      this.rtmChannel = null
    }
  }

  public isInChannel(channelId: string) {
    if (this.rtmChannel && this.rtmChannel.channelId === channelId) {
      return true
    }
    return false
  }

  @action
  private handleChannelEvents(): void {
    this.rtmChannel.on('MemberJoined', (memberId: string) => {
      log(`rtm channel member joined, id: ${memberId}`, LOG_TYPE.COMM, LOG_MODULE.RTM)

      if (memberId.indexOf('avc') === 0 && memberId !== this.serverId) {
        log(`rtm channel peer server: ${memberId} joined`, LOG_TYPE.ERROR, LOG_MODULE.RTM)
        this.serverId = memberId
        this.rootStore.rtmCommLayer.onPeerServerReboot()
      }
    })

    this.rtmChannel.on('MemberLeft', (memberId: string) => {
      log(`rtm channel member left, id: ${memberId}`, LOG_TYPE.COMM, LOG_MODULE.RTM)

      if (memberId.indexOf('avc') === 0 && memberId === this.serverId) {
        log(`rtm channel peer server: ${memberId} left`, LOG_TYPE.ERROR, LOG_MODULE.RTM)
        this.rootStore.rtmCommLayer.onPeerServerReboot()
      }
    })

    this.rtmChannel.on('ChannelMessage', ({ text }: { text: any }, peerId: string) => {
      if (peerId !== this.serverId) {
        log(`channel receive from unknown peer: ${peerId} text: ${text}`, LOG_TYPE.ERROR, LOG_MODULE.RTM)
        return
      }

      log(`rtm channel recv msg, from: ${peerId}, text: ${text.length > 2000 ? "..." + text.substring(text.length - 2000) : text}`, LOG_TYPE.COMM, LOG_MODULE.RTM)

      this.handleServerMessage(text)
    })
  }

  /****************** SEND DATA ******************/
  public async sendJoinCmd(rid: string, pwd: string, key: string, token: string, status: number, shareId?: number) {
    return this.emit('join', {
      rid,
      token,
      pwd,
      key,
      platform: 'web',
      appversion: process.env.REACT_APP_APP_VERSION,
      status,
      shareId,
      feature: 0,
    })
  }

  public async sendLeaveCmd(roomId: string) {
    // not care response
    return this.justEmit('leave', {
      rid: roomId
    })
  }

  public async sendChatCmd(roomId: string, msg: string) {
    return this.emit('broadcast', {
      rid: roomId,
      message: msg,
      seqid: this.chatSeqId++
    })
  }

  public async sendRoomUpdateCmd(roomId: string, update: any) {
    return this.emit('room-setting', {
      rid: roomId,
      data: {
        ...update
      }
    })
  }

  public async sendMeetingAssistantCmd(roomId: string, actionName: string, lang: string) {
    return this.emit('meeting-assistant', {
      rid: roomId,
      action: actionName,
      lang,
    })
  }

  public async sendRequestCmd(roomId: string, op: number, target: string, seq: number) {
    return this.emit('send-request', {
      op: this.getOperationCmd(op),
      target,
      rid: roomId,
      seq: seq
    })
  }

  public async sendOperationCmd(roomId: string, op: number, target: string, seq: number, options?: any) {
    return this.emit('control', {
      ...options,
      rid: roomId,
      targets: [target],
      opts: {},
      op: this.getOperationCmd(op),
      seq,
    })
  }

  public async sendRequestResponseCmd(roomId: string, accept: boolean, requestId: string, seq: number) {
    return this.emit('send-response', {
      accept,
      seq,
      rid: roomId,
      requestId
    })
  }

  /*
    * todo 5.2.0: web端增加开始云录制功能 结束云录制
    * start recording
    * params: rid, cname, maxResolutionId
    *
    * end recording
    * params: recordingId
    *
    * update layout
    * params: recordingId, maxResolutionId
  */
  public async sendStartRecordingCmd(roomId: string, cname: string, maxResolutionId: number) {
    return this.emit('start-recording', {
      rid: roomId,
      cname,
      maxResolutionId: maxResolutionId.toString(),
    })
  }

  // recordingId: string
  public async sendEndRecordingCmd(recodingId: string) {
    return this.emit('end-recording', {
      recodingId,
    })
  }

  public async sendUpdateLayoutRecordingCmd(recodingId: string, maxResolutionId: number) {
    return this.emit('update-layout', {
      recodingId,
      maxResolutionId: maxResolutionId.toString(),
    })
  }

  private async sendPeerMessage(msgId: number, cmd: string, opts: any) {
    const commandStr = JSON.stringify({
      msgid: msgId.toString(),
      cmd,
      opts: { ...opts },
      ver: 1
    });

    log(`rtm send msg, to: ${this.serverId}, msg id: ${msgId}, text: ${commandStr}`, LOG_TYPE.COMM, LOG_MODULE.RTM)

    await this.rtmInstance.sendMessageToPeer(
      {
        text: commandStr
      },
      this.serverId
    )
  }

  private justEmit(cmd: string, opts: any) {
    this.sendPeerMessage(this.useMsgId(), cmd, opts).catch((e: any) => {
      // last leave message will certainly exception because of rtm logout, so omit log;
      // logException(`justEmit, send peer meesage exception `, err, LOG_MODULE.RTM)
    })
  }

  private emit(cmd: string, opts: any) {
    const p = new Promise((resolve, reject) => {
      const msgId = this.useMsgId()
      this.sendPeerMessage(msgId, cmd, opts).catch((err: any) => {
        this.rtmInstance.off('MessageFromPeer', responseHandler)
        clearTimeout(timer)

        logException(`rtm send msg failure, msg id: ${msgId}, error: ${err}, dec: ${err.desc}`, err, LOG_MODULE.RTM)

        reject(new RtmError(err.code, err.message, msgId))
      })

      const responseHandler = ({ text }: { text: any }, peerId: any) => {
        try {
          const response = JSON.parse(text);
          if (peerId !== this.serverId && response !== "pullLog") {
            return
          }

          const { msgid, success, code, desc } = response

          // !!! string equal with int
          // tslint:disable-next-line: triple-equals
          if (msgid == msgId) {
            this.rtmInstance.off('MessageFromPeer', responseHandler)
            clearTimeout(timer)

            if (success) {
              resolve(response.opts)
            } else {
              log(`rtm response error code: ${code}`, LOG_TYPE.ERROR, LOG_MODULE.RTM)

              // 10003 means peer server had switched rtm peer id.
              if (code === 10003) {
                this.rootStore.rtmCommLayer.onPeerServerReboot()
              }

              reject(new RtmError(code, desc, msgId))
            }
          }
        } catch (err) {
          logException('Invalid format for response ', err, LOG_MODULE.RTM)
        }
      }

      const timer = setTimeout(() => {
        log(`send rtm cmd timout, msg id: ${msgId} cmd: ${cmd}`, LOG_TYPE.ERROR, LOG_MODULE.RTM)

        this.rtmInstance.off('MessageFromPeer', responseHandler)

        reject(new RtmError(2, 'Request timeout(code: 2)', msgId))
      }, this.rootStore.user.MESSAGE_TIMEOUT)

      this.rtmInstance.on('MessageFromPeer', responseHandler)
    })

    return reporter.promiseDecorate(p, BI_REPORT_EVENT.RTM_PEER_TO_PEER, cmd)
  }

  private useMsgId() {
    return this.messageId++
  }

  public peekMsgId() {
    return this.messageId
  }

  private getOperationCmd(op: number) {
    switch (op) {
      case OP_TYPE.OP_BAN:
        return "ban";
      case OP_TYPE.OP_ENABLE_AUDIO:
        return "unmuteAudio";
      case OP_TYPE.OP_DISABLE_AUDIO:
        return "muteAudio";
      case OP_TYPE.OP_ENABLE_VIDEO:
        return "unmuteVideo";
      case OP_TYPE.OP_DISABLE_VIDEO:
        return "muteVideo";
      case OP_TYPE.OP_INTERRUPT:
        return "interrupt";
      case OP_TYPE.OP_RESUME:
        return "resume";
      case OP_TYPE.OP_SHARE:
        return "share";
      case OP_TYPE.OP_UNSHARE:
        return "unshare";
      default:
        return "";
    }
  }

  /****************** RECEIVE DATA ******************/

  public handleServerMessage(msg: string) {
    try {
      const response = JSON.parse(msg)
      if (!response.cmd) return
      switch (response.cmd) {
        case "response":
          // see emit/responseHandler
          break;
        case "join-success":
          this.handleJoinSuccess(response.opts, response.ts)
          break;
        case "join-success-append":
          this.handleJoinSuccessAppend(response.opts)
          break;
        case "user-join":
          this.handleUserJoin(response.opts)
          break;
        case "user-leave":
          this.handleUserLeave(response.opts)
          break;
        case "room-info":
          this.handleRoomInfo(response.opts, response.ts)
          break;
        case "user-operation":
          this.handleOperation(response.opts)
          break;
        case "user-request":
          this.handleUserRequest(response.opts)
          break;
        case "user-recv-msg":
          this.handleRecvMsg(response.opts)
          break;
        case "user-response":
          this.handleUserResponse(response.opts)
          break;
        case "pullLog":
          this.handlePullLog()
          break;
        case "kick":
          this.handleKick(response.ts)
          break;
        case "start-recording-ch":
          this.handleStartRecordingCh(response.opts)
          break;
        case "end-recording-ch":
          this.handleEndRecordingCh(response.opts)
          break;
        case "user-assistant-request":
          this.handleUserApplyAssistantRequest(response.opts)
          break
        case "user-assistant-response":
          this.handUserSelfApplyAssistantResponse(response.opts)
          break
        case "assistant-info-ch":
          this.handleAssistantInfo(response.opts)
          break
        default:
          log(`unrecogonized response cmd: ${response.cmd}`)
          break;
      }
    } catch (err) {
      logException(`handle server message exception `, err, LOG_MODULE.RTM)
    }
  }

  private handleJoinSuccess(result: any, ts: number) {
    if (result && result['room-info']) {
      const joinResult = this.parseJoinSuccessResult(result)
      joinResult.roomInfo.remark(ts, this.serverId)

      this.rootStore.rtmCommLayer.onJoinSuccessResult(joinResult)
    }
  }

  private handleJoinSuccessAppend(result: any) {
    if (result['user-list']) {
      const userList: BizUser[] = []
      result['user-list'].forEach((item: any) => {
        userList.push(this.parseBizUser(item))
      })
      let appendMark = ''
      if (result['append-mark']) {
        appendMark = result['append-mark']
      }

      let hasMoreUser = false
      // tslint:disable-next-line: no-string-literal
      if (result['more']) {
        // tslint:disable-next-line: no-string-literal
        hasMoreUser = result['more']
      }

      this.rootStore.rtmCommLayer.onJoinSuccessAppendResult(userList, appendMark, hasMoreUser)
    }
  }

  private handleUserJoin(user: any) {
    this.rootStore.rtmCommLayer.mergeUserJoinOrLeaveEvent({
      user: this.parseBizUser(user),
      isJoinRoom: true
    })

  }

  private handleUserLeave(user: any) {
    this.rootStore.rtmCommLayer.mergeUserJoinOrLeaveEvent({ user: user.uid, isJoinRoom: false })
  }

  private handleRoomInfo(data: any, ts: number) {
    const roomInfo = this.parseRoomInfo(data)
    roomInfo.timestamp = ts
    this.rootStore.rtmCommLayer.onRoomUpdate(roomInfo)
  }

  private handleOperation(data: any) {
    const userOp = this.parseUserOperation(data)
    if (userOp.detail) {
      userOp.detail.targets.forEach((target: any) => {
        const userOperation = new UserOperationEx(userOp.operator, target, userOp.detail?.type || '', userOp.seq, userOp.detail?.options || {})
        this.rootStore.rtmCommLayer.onUserOperation(userOperation)
      })
    }
  }

  private handleUserRequest(data: any) {
    const userRequest = new UserRequest(data.op, data.requestId, data.seq, data.sender)
    this.rootStore.rtmCommLayer.onUserRequest(userRequest)
  }

  private handleRecvMsg(data: any) {
    // tslint:disable-next-line: no-string-literal
    const chat = new ChatMessage(data['msg'], data['sender'])
    this.rootStore.rtmCommLayer.onChatMessage(chat)
  }

  private handleUserResponse(data: any) {
    // tslint:disable-next-line: no-string-literal
    const isAudio = data['op'] === "unmuteAudio" || data['op'] === "muteAudio"
    // tslint:disable-next-line: no-string-literal
    const response = new MyRequestResponse(data['requestId'], data['reason'], data['sendor'], data['target'], data['success'], isAudio)
    this.rootStore.rtmCommLayer.onMyRequestResponse(response)
  }

  private handlePullLog() {
    this.rootStore.rtmCommLayer.onPullLogFromAtlas()
  }

  private handleKick(ts: number) {
    const kickTs = ts.toString()
    const kickedTs = sessionStorage.getItem("kicked")
    if (kickedTs) {
      if (kickedTs === kickTs) {
        return
      }
    }
    sessionStorage.setItem("kicked", kickTs)
    this.rootStore.rtmCommLayer.onKickUserFromAtlas()
  }

  private handleStartRecordingCh(user: any) {
    this.rootStore.rtmCommLayer.onUserStartCloudRecording(user.starter, user.elapsedTime)
  }

  private handleEndRecordingCh(user: any) {
    this.rootStore.rtmCommLayer.onUserEndCloudRecording(user.recorder)
  }

  private handleUserApplyAssistantRequest(user: any) {
    this.rootStore.rtmCommLayer.onUserApplyAssistant(user)
  }

  private handUserSelfApplyAssistantResponse(res: any) {
    const response = new SelfApplyAssistantResponse(res.requestId, res.success, res.reason)
    this.rootStore.rtmCommLayer.onSelfApplyAssistantResponse(response)
  }

  private handleAssistantInfo(res: any) {
    const on = res.action === 'apply'
    const operator = res.terminator || ''
    const assistant = this.paseAssistantInfo(res.assistant)
    this.rootStore.rtmCommLayer.onBroadcastAssistant(on, assistant, operator)
  }

  private paseAssistantInfo(info: any) {
    const assistant = new AssistantInfo()
    assistant.assistantUid = info.uid
    assistant.thirdpartyAlias = info.alias
    assistant.thirdpartyName = info.innerName
    assistant.streamId = info.streamId
    assistant.language = info.lang
    assistant.nickName = info.name
    return assistant

  }
  private parseUserOperation(result: any) {
    const userOperation = new UserOperation()
    userOperation.operator = result.operator
    userOperation.seq = result.seq

    // tslint:disable-next-line: no-string-literal
    if (result['original']) {
      const detail = new UserOperationDetail()

      userOperation.detail = detail

      // tslint:disable-next-line: no-string-literal
      detail.type = result['original'].type
      // tslint:disable-next-line: no-string-literal
      detail.options = result['original'].options

      // tslint:disable-next-line: no-string-literal
      result['original']['targets'].forEach((target: string) => {
        detail.targets.push(target)
      })
    }
    return userOperation
  }

  private parseJoinSuccessResult(result: any) {
    const joinRoomResult = new JoinRoomResult()
    joinRoomResult.roomInfo = this.parseRoomInfo(result['room-info'])

    if (result['room-info'].assistant) {
      joinRoomResult.assistant = this.parseAssistantInfo(result['room-info'])
    } else {
      joinRoomResult.assistant = new AssistantInfo()
    }

    if (result['user-list']) {
      result['user-list'].forEach((item: any) => {
        joinRoomResult.users.push(this.parseBizUser(item))
      })
    }

    if (result['append-mark']) {
      joinRoomResult.appendMark = result['append-mark']
    }

    joinRoomResult.elapsedTime = result['room-elapse']
    joinRoomResult.joinMsgId = Number(result['join-msgid'])
    // tslint:disable-next-line: no-string-literal
    joinRoomResult.more = result['more']

    return joinRoomResult
  }

  private parseRoomInfo(info: any) {
    const roomInfo = new BizRoomInfo()
    roomInfo.rid = info.rid || ''
    roomInfo.pwd = info.pwd || ''
    roomInfo.createTime = info.createTime || ''
    roomInfo.initialStatus = info.initialStatus || 0
    roomInfo.hosts = info.hosts || []
    roomInfo.sdkConfigs = info.sdkConfigs
    roomInfo.key = info.key || ''
    roomInfo.mediatoken = info.mediatoken || ''
    roomInfo.recorder = info.recorder
    roomInfo.rtcEncryption = info.rtcSecure || false

    // tslint:disable-next-line: no-string-literal
    if (info['hostsDetails']) {
      // tslint:disable-next-line: no-string-literal
      info['hostsDetails'].forEach((item: any) => {
        roomInfo.hostsDetails.push(this.parseBizUser(item))
      })
    }

    return roomInfo
  }

  private parseAssistantInfo(userInfo: any) {
    const { assistant } = userInfo
    const assistantInfo = new AssistantInfo()
    assistantInfo.updateAssistantInfo({
      assistantUid: assistant.uid || '',
      language: assistant.lang || 'ch',
      streamId: assistant.streamId,
      thirdpartyName: assistant.innerName,
      thirdpartyAlias: assistant.alias,
      nickName:assistant.name
    } as AssistantInfo)
    assistantInfo.assistantDetail = this.parseBizUser(assistant)
    return assistantInfo
  }

  private parseBizUser(user: any) {
    const bizUser = new BizUser()
    bizUser.uid = user.uid || ''
    bizUser.name = user.name || ''
    bizUser.portraitId = user.portraitId || ''
    bizUser.status = user.status || 0
    bizUser.streamId = user.streamId || 0
    bizUser.isLoggedIn = user.isInner || false
    bizUser.source = user.source || 0
    bizUser.thirdpartyName = user.innerName || ''
    bizUser.thirdpartyAlias = user.alias || ''
    bizUser.thirdpartyDepartment = user.department || ''
    bizUser.inviteBy = user.inviteBy || ''
    bizUser.feature = user.feature || 0
    bizUser.shareId = user.shareId || 0

    // up logic use 0 as invalid share id not -1
    if (bizUser.shareId === -1) {
      bizUser.shareId = 0
    }

    return bizUser
  }

  /****************** OTHERS ******************/

  public setServerId(serverId: string) {
    this.serverId = serverId
  }

  public getMessageTimeout() {
    return this.rootStore.user.MESSAGE_TIMEOUT
  }

}