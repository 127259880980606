import { VideoEncoderConfigurationPreset, ScreenEncoderConfigurationPreset } from "agora-rtc-sdk-ng";
// import 'agora-rtm-sdk/error-code';

export const VideoPresetMap: { [key: string]: VideoEncoderConfigurationPreset } = {};

export const ScreenPresetMap: { [key: string]: ScreenEncoderConfigurationPreset } = {};

export const APPID = (process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_APP_ID : process.env.REACT_APP_TEST_APP_ID) || ""

export const isProduction = process.env.REACT_APP_ENV === 'production'

export const APP_SUB_PATH = process.env.REACT_APP_SUB_PATH

export enum RTC_QUALITY_TYPE {
  /** 0: The network quality is unknown. */
  QUALITY_UNKNOWN = 0,
  /**  1: The network quality is excellent. */
  QUALITY_EXCELLENT = 1,
  /** 2: The network quality is quite good, but the bitrate may be slightly lower than excellent. */
  QUALITY_GOOD = 2,
  /** 3: Users can feel the communication slightly impaired. */
  QUALITY_POOR = 3,
  /** 4: Users cannot communicate smoothly. */
  QUALITY_BAD = 4,
  /** 5: The network is so bad that users can barely communicate. */
  QUALITY_VBAD = 5,
  /** 6: The network is down and users cannot communicate at all. */
  QUALITY_DOWN = 6,
  /** 7: Users cannot detect the network quality. (Not in use.) */

  /** 8: SDK 正在探测网络质量 */
  QUALITY_DETECTING = 7,
}

export const QualityState = {
  'NETWORK_UNKNOWN': 0,
  'NETWORK_POOR': 1,
  'NETWORK_SOFT': 2,
  'NETWORK_GOOD': 3,
  'NETWORK_DOWN': 4,
}

export enum BIZ_ROOM_STATE {
  BIZ_ROOM_DISCONNECTED = 0,
  BIZ_ROOM_CONNECTING = 1,
  BIZ_ROOM_RECONNECTING = 2,
  BIZ_ROOM_RECONNECTING_PEER = 3,
  BIZ_ROOM_CONNECTED = 4,
}

export const ERR_TOKEN_EXPIRED_OR_OTHER = 400 //todo map will not recogonize, only use number
export const ERR_TOKEN_500 = 500

export const ERR_JOIN_WRONG_PWD = 2006
export const ERR_JOIN_WRONG_SESSION = 2014
export const ERR_JOIN_INVALID_RID = 2015
export const ERR_WECHAT_LOGIN_INVALID = 1018
export const ERR_SHARE_CODE_EXPIRED = 1022
export const ERR_WECHAT_LOGIN_EXPIRED = 1023

export const ROOM_AGORA_JOIN_NO_PERMISSION = 10012

export const ERR_REQUEST_FAILURE = 1001
export const ERR_BIZ_DISCONNECT = 30001
export const ERR_BIZ_USER_LOST = 30002
export const ROOM_TOKEN_EXPIRED = 2050;
export const ROOM_TOKEN_INVALID = 2051;

export enum CloudRecordingError {
  ERR_ONLY_HOST_CAN_START_RECORDING = 2013,
  ERR_RECORDING_ALREADY_ON = 2036,
  ERR_RECORDING_NOT_RUNNING = 2037,
  ERR_RECORDING_TOO_SHORT = 2038,
  ERR_RECORDING_GENERATE_FILE = 2039,
}

export const alertErrorMap = {
  1: 'PeerMessageFailure',
  2: 'PeerMessageTimeout',
  1001: 'RequestFailure',
  1005: 'UserUpdateFailure',
  1009: 'Portrait update failed, image too large.',
  1018: 'UserNotAgoran',
  2003: 'Internal server error(code: 2003)',
  2005: 'Internal server error(code: 2005)',
  2006: 'Room join failed, incorrect password',
  2007: 'Internal server error(code: 2007)',
  2008: 'You are not host of this room',
  2009: 'Screen share going already',
  2011: 'you need to become host to operate on others',
  2013: 'operation not allowed',
  2015: 'ErrorTryAgain',
  2036: "Recording already on",
  2038: "Recording too short",
  2039: "Generate recording file failure",
  2041: "There is already a meeting assistant in the room",
  2042: "There is no meeting assistant in the room",
  10000: 'Target user not in this room(code: 10000)',
  10001: 'Room not exists(code: 10001), try joining again',
  10002: 'You are not in this room(code: 10002)',
  10003: 'Connecting Server(code: 10003)',
  10012: 'AgoraRoomNoPermission',
  30001: 'Biz disconnected',
  30002: 'BizUserLost'
};

export enum RTMConnectState {
  DISCONNECTED = 1,
  CONNECTING = 2,
  CONNECTED = 3,
  RECONNECTING = 4,
  ABORTED = 5,
}

export enum USER_STATUS_FLAG {
  AUDIO_DISABLE = 0x0001,
  VIDEO_DISABLE = 0x0002,
  SHARE_ON = 0x0004,
  BANED_ON = 0x0008,
  INTERRUPT_ON = 0x0010,
  DUMP_ON = 0x0020,
  CLOUD_RECORDING_ON = 0x0040,
  ADD_WATERMARK = 0x0080
}

export enum UserChangedReason {
  //todo missing REASON_INTERRUPT
  //todo reason change to bit
  REASON_NONE =0x00,
  REASON_AUDIO = 0x01,
  REASON_VIDEO = 0x02,
  REASON_HOST = 0x04,
  REASON_SHARE = 0x08,
  REASON_SPEAKING = 0x10,
  REASON_QUALITY = 0x20,
  REASON_INFO = 0x40,
  REASON_INTERRUPT = 0x80,
  REASON_DUMPING_ISSUE = 0x0100,
  REASON_CLOUD_RECORDING = 0x0200,
  REASON_ENABLE_WATERMARK = 0x0400,
  REASON_ASSISTANT_INFO = 0x0800,
  REASON_USER_LEAVE = 0x01000,
  REASON_BIZ_UPDATE = REASON_INFO | REASON_HOST | REASON_SHARE | REASON_INTERRUPT | REASON_DUMPING_ISSUE | REASON_CLOUD_RECORDING | REASON_ASSISTANT_INFO | REASON_USER_LEAVE,
  REASON_MEDIA_UPDATE = REASON_AUDIO | REASON_VIDEO | REASON_SPEAKING | REASON_QUALITY | REASON_INFO,
}

export const REASON_MEDIA_GROUP_CONCERN = UserChangedReason.REASON_MEDIA_UPDATE | UserChangedReason.REASON_SHARE
export const REASON_ATTENDEE_GROUP_CONCERN = UserChangedReason.REASON_BIZ_UPDATE | UserChangedReason.REASON_MEDIA_UPDATE

export enum RoomState {
  ROOM_STATE_DISCONNECT = 0,
  ROOM_STATE_CONNECTING = 1,
  ROOM_STATE_CONNECTED = 2,
}

export enum SHARE_STATE {
  SHARE_IDLE = 0,
  SHARE_INITIALIZING_1 = 1,
  SHARE_INITIALIZING_2 = 2,
  SHARE_INITIALIZING_3 = 3,
  SHARE_RUNNING = 4,
  SHARE_STOPPING = 5,
}

export const DEEP_LINK_PATH_ROOM = "room"
export const DEEP_LINK_PATH_SECURE_ROOM = "secureroom"
export const DEEP_LINK_PARAM_TYPE = "type"
export const DEEP_LINK_PARAM_CODE = "code"
export const DEEP_LINK_PARAM_RID = "rid"
export const DEEP_LINK_PARAM_PWD = "pwd"
export const DEEP_LINK_SCHEME = "agoraavc"
export const DEEP_LINK_DOMAIN =  process.env.REACT_APP_ENV === 'production' ? "videocall.agora.io/" : ("avc-staging.sh.agoralab.co/" + APP_SUB_PATH)

export enum BI_REPORT_EVENT {
  UPLOAD_LOG = 1,
  UPLOAD_PORTRAIT= 2,
  DOWNLOAD_PORTRAIT = 3,
  REQUEST_HTTP = 4,
  RTM_DO_LOGIN = 5,
  RTM_JOIN_CHANNEL= 6,
  RTM_PEER_TO_PEER = 7,
  RTM_CONNECTING_DURATION = 8,
  RTM_RECONNECTING_DURATION = 9,
  RTC_JOIN_CHANNEL = 10,
  UPLOAD_DEVICE_INFO= 11,
}

export enum ROOM_MODE {
  MODE_NORMAL = 0,
  MODE_AGORA = 1,
}

export enum ROOM_AGORA_TYPE {
  TYPE_PRIVATE = 0,
  TYPE_PUBLIC = 1,
}

export enum UPLOAD_LOG_STATE {
  DEFAULT = 0,
  UPLOADING = 1,
  SUCCESS = 2,
  FAILURE = 3,
}

export enum APPLY_ASSISTANT_LANG {
  EN = 'en',
  CH = 'zh'
}
export const ROOM_MODE_AGORA_START = "AG-"
export const ROOM_MODE_AGORA_START_SHOW = "AG -"
export const ROOM_NAME_MAX_LENGTH = 18
export const ROOM_NAME_MIN_LENGTH = 3
export const ROOM_PWD_MAX_LENGTH = 18
export const NICK_NAME_MAX_LENGTH = 18
export const ATLAS_RECORDING_URL = "https://atlas.agoralab.co/recording/search"

export const hasReason = (reason: number, flag: number) => {
  return (reason & flag) !== 0;
}

export enum ASSISTANT_REASON {
  BY_SELF = 0,
  BY_ATLAS = 1,
  BY_HOST = 2
}

export enum ASSISTANT_STATE {
  ASSIST_NONE = 0,
  ASSIST_DISABLED = 1,
  ASSIST_ENABLED_WITH_ORIGIN_SOUND = 2,
  ASSIST_ENABLED_WITHOUT_ORIGIN_SOUND = 3,
}

export enum VOLUME {
  FULL_VOLUME = 100,
  DECREASE = 10,
  MUTE = 0
}

export enum APPLY_ASSISTANT_RESULT {
  DEFAULT = 0,
  WAIT = 1,
  SUCCESS = 2,
  REFUSE = 3
}

export enum SUBSCRIBE_ASSISTANT_STATUS {
  DEFAULT = 0,
  SUBSCRIBE = 1,
  UNSUBSCRIBE = 2,
}

export enum ENCRYPTION_MODE {
  NONE = 0,
  AES_128_XTS = 1,
  AES_128_ECB = 2,
  AES_256_XTS = 3,
  SM4_128_ECB = 4,
  AES_128_GCM = 5,
  AES_256_GCM = 6,
  AES_128_GCM2 = 7,
  AES_256_GCM2 = 8,
  MODE_END = 9
}

export enum SDK_ENCRYPTION_MODE {
  NONE = "none",
  AES_128_XTS = "aes-128-xts",
  AES_128_ECB = "aes-128-gcm",
  AES_256_XTS = "aes-256-xts",
  SM4_128_ECB = "sm4-128-ecb",
  AES_128_GCM = "aes-128-gcm",
  AES_256_GCM = "aes-256-gcm",
  AES_128_GCM2 = "aes-128-gcm2",
  AES_256_GCM2 = "aes-256-gcm2",
}
