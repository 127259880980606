import { observer } from "mobx-react"
import React from "react"
import { useNotificationStore } from "../../stores/hooks"
import CustomDialog from "./CustomDialog"

const AlertDialog = observer(() => {
  const notification = useNotificationStore()

  const onCancel = () => {
    notification.showAlert = false
  }

  return (
    <CustomDialog
      open={notification.showAlert}
      isErrorAlert={notification.alert?.isErrorAlert}
      title={notification.alert?.title}
      content={notification.alert?.content || ""}
      param={notification.alert?.params}
      onCancel={onCancel}
      onClose={onCancel}
      onOK={onCancel}
      confirmText={notification.alert?.confirmText || ""}
    />
  )
})

export default AlertDialog
