import { createMuiTheme } from "@material-ui/core/styles";
import { colorAgora, colorDefault, colorCommon, color } from "../style/color";
import { exceedSafari } from "../utils/userAgentDetector"
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    customColor: {
      [key: string]: React.CSSProperties['color']
    }
  }
  interface ThemeOptions {
    customColor: {
      [key: string]: React.CSSProperties['color']
    }
  }
}
const commonOverrides = {
  MuiTypography: {
    h1: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.125rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '0.875rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '0.75rem',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    subtitle2: {
      // 聊天内容
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    body1: {
      // 设置页面
      fontSize: '0.75rem',
      fontWeight: 400,
      transform: 'scale(0.83)',
      transformOrigin: '0'
    }
  },
}
const commonPalette = {


}

const commonDrawer = {
  width: '15.5rem',
  height: '22.25rem',
  borderRadius: '0.75rem',
}
const themingDefault = (isDark: boolean) => {
  return createMuiTheme({
    customColor: {
      componentsBackGround: isDark ? colorCommon.colorBlack : colorDefault.colorBackground,
      tabActiveColor: isDark ? colorCommon.colorBlack : colorDefault.colorConfirm,
      boxShadow: isDark ? 'none' : `0rem 0.06rem 0.25rem 0rem ${colorDefault.colorBoxShadow}`,
      tabBackground: isDark ? colorCommon.colorDarkGrey : colorCommon.colorWhite,
      borderColor: isDark ? colorCommon.colorDarkModeBack : colorCommon.colorBorder,
      operationButtonBackground: isDark ? colorCommon.colorTextGrey : colorCommon.colorWhite,
      operationButtonFill: isDark ? colorCommon.colorBlack : colorDefault.colorPrimary,
      timerColor: isDark ? colorCommon.colorTextGrey : colorDefault.colorText,
      settingBackground: isDark ? colorCommon.colorDarkModeBack : colorCommon.colorSetting,
      alertBoxShadow: isDark ? 'none' : `0rem 0.06rem 0.81rem 0rem ${colorCommon.colorAlertBoxShadow}`,
      bubbleColor: isDark ? colorDefault.colorBubbleDark : colorDefault.colorConfirm,
      otherBubbleColor: isDark ? colorCommon.bubbleColor : colorCommon.colorWhite,
      participantsIconColor: isDark ? colorCommon.colorFontGrey : colorDefault.colorPrimary,
      gdprBorderColor: isDark ? colorCommon.colorOutlinedBorderDark : colorCommon.colorBorder,
      badgeBackgroundColor: isDark ? colorCommon.colorWhite : colorDefault.colorPrimary,
      badgeColor: isDark ? colorCommon.colorTitleTextColor : colorCommon.colorWhite,
      tooltipTextColor: isDark ? colorCommon.colorFontGrey : colorCommon.colorTitleTextColor,
      commonModelBackground: isDark ? colorCommon.colorBlack : colorCommon.colorWhite,
      tooltipBoxShadow: isDark ? 'none' : `0rem 0.06rem 0.81rem 0rem ${colorCommon.colorToolTipBoxShadow}`,
      tooltipBorderColor: isDark ? colorCommon.colorBlack : colorCommon.colorToolTipBoxShadow,
      settingItemBackground: isDark ? colorCommon.colorBlack : colorCommon.colorWhite,
      settingTipBackground: isDark ? colorCommon.bubbleColor : colorCommon.colorSettingTipWhite,
      settingTextColor: isDark ? colorCommon.colorOutlinedSecondaryDark : colorCommon.colorTitleTextColor,
      qualityFeedBackLine: isDark ? color.colorBlack : '#f1f1f1',
      selectMenuItemHover: isDark ? colorCommon.colorOutlinedActiveDark : colorCommon.colorMenuItemHoverBlue,
      issueDialogTitle: isDark ? colorCommon.colorDarkGrey : colorCommon.colorWhite,
      issueDialogTitleBorder: isDark ? colorCommon.colorDarkModeBack : colorCommon.colorIssueTitleBorderBlue,
      issueDialogContent: isDark ? colorCommon.colorBlack : colorCommon.colorWhite,
      issueStreamId: isDark ? colorCommon.colorIssueStreamIdDark : colorCommon.colorIssueStreamIdGrey,
      issueButtonSelected: isDark ? colorDefault.colorIssueFeedbackSelectedDark : colorDefault.colorIssueFeedbackSelectedLight,
      issueButtonSelectedText: isDark ? colorDefault.colorIssueFeedbackSelectedTextLight : colorCommon.colorIssueButtonSelectedBlue,
      issueFeedBackInfoText: isDark ? colorCommon.colorIssueStreamIdDark : colorCommon.colorIssueFeedBackInfoGrey,
      issueFeedBackTextArea: isDark ? colorCommon.colorWhite : colorDefault.colorPrimary,
      issueFeedBackResult: isDark ? colorCommon.colorDarkModeBack : colorCommon.colorWhite,
      issueFeedBackDialogText: isDark ? colorCommon.colorIssueFeedBackDialogDarkGrey : colorCommon.colorIssueTipTextGrey,
      issueFeedBackCancelBackground: isDark ? colorCommon.colorTextGrey : colorCommon.colorOutlinedActiveLight,
      downloadTextColor: isDark ? colorCommon.colorDarkPlaceholder : color.colorSecondaryGrey,
      BubbleShadow: isDark ? 'none' : colorDefault.colorBubbleShadow,
      roomHeaderButtonColor: colorCommon.colorOutlinedActiveLight,
      selectedColor: isDark ? colorCommon.colorOutlinedActiveDark : colorCommon.colorMenuItemHoverBlue,
      videoPreviewBackgroundColor: isDark ? colorCommon.bubbleColor : colorCommon.colorSetting,
      occupationsIconColor: isDark ? colorCommon.colorLightGrey : colorCommon.colorWhite,
      settingTabBackgroundColor: isDark ? colorCommon.colorDarkGrey : colorDefault.colorSelectHover,
      homepageBackgroundColor: !isDark ? colorDefault.colorHomepageBackground : colorDefault.colorHomepageBackgroundDark,
      buttonHoverColor: isDark ? colorCommon.colorDarkGrey : colorDefault.colorPrimaryFilter,
      participantsButtonHover: isDark ? colorCommon.colorParticipantsButtonHover : colorDefault.colorParticipantsButtonHover,
      controlAudioActiveBackground: isDark ? colorCommon.colorAudioActiveBackgroundDark : colorDefault.colorAudioActiveBackground,
      controlAudioActiveShade: isDark ? colorCommon.colorFontGrey : colorDefault.colorPrimary,
      voiceVolumeActiveBackground: colorDefault.colorAudioActiveBackground,
    },
    palette: {
      ...commonPalette,
      type: isDark ? 'dark' : 'light',
      primary: {
        main: colorDefault.colorPrimary,
        dark: colorDefault.colorPrimaryLight,
        contrastText: colorCommon.colorWhite,
      },
      secondary: {
        main: colorDefault.colorBackground,
        light: colorDefault.colorBackground,
        contrastText: colorCommon.colorWhite,
        dark: colorCommon.colorBlack
      },
      text: {
        primary: isDark ? colorCommon.colorFontGrey : colorCommon.colorTitleTextColor,
        secondary: colorCommon.colorWhite,
      },
      background: {
        default: isDark ? colorCommon.colorDarkModeBack : colorCommon.colorWhite,
        paper: isDark ? colorCommon.colorDarkModeBack : colorCommon.colorWhite,
      },
      action: {
        active: colorDefault.colorPrimary,
        hover: isDark ? colorCommon.colorFontGrey : colorAgora.colorHover,
        disabledBackground: colorCommon.colorFontGrey,
      },
      info: {
        main: isDark ? colorCommon.colorWhite : colorCommon.colorInputBorderGrey,
      },
      error: {
        main: isDark ? colorCommon.colorLightRed : colorCommon.colorError,
        dark: colorCommon.colorLightRed
      },
      success: {
        main: isDark ? colorCommon.colorGreenLight : colorCommon.colorGreen,
        dark: colorCommon.colorGreenLight
      },
    },
    overrides: {
      MuiPaper: {
        elevation1: {
          ...commonDrawer,
        },
        elevation8: {
          boxShadow: isDark ? 'none' : `0px 1px 13px 0px rgba(67, 92, 115, 0.17)`,
        }
      },
      MuiTypography: {
        ...commonOverrides.MuiTypography,
        subtitle1: {
          fontSize: '0.875rem',
          fontWeight: 400,
          color: isDark ? colorCommon.colorOutlinedSecondaryDark : colorCommon.colorTitleTextColor
        }
      },
      MuiDrawer: {
        paperAnchorDockedRight: {
          transform: 'none',
          transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms'
        }
      },
      MuiSelect: {
        root: {
          display: 'flex',
          alignItems: 'center',
          height: '1.875rem',
          width: '25.25rem',
          padding: 0,
          paddingLeft: '0.8125rem',
          borderRadius: '.5rem',
          fontSize: '.875rem',
          fontWeight: 400,
        },
        selectMenu: {
          height: '1.875rem',
        },
        outlined: {
          paddingRight: '1.1875rem !important'
        },
        icon: {
          top: 0,
          right: 0,
        },
        select: {
          '&:hover': {
            // backgroundColor: isDark ? colorCommon.colorOutlinedActiveDark :  colorCommon.colorMenuItemHoverBlue,
          },
          '&:focus': {
            backgroundColor:'none'
            // backgroundColor: isDark ? colorCommon.colorOutlinedActiveDark :  colorCommon.colorMenuItemHoverBlue,
          },
        },
      },
      MuiIconButton: {
        root: {
          borderRadius: '0.54rem',
          boxShadow: isDark ? 'none' : `0rem 0.06rem 0.25rem 0rem ${colorDefault.colorBoxShadow}`,
          width: exceedSafari(14) ? '' : '2.25rem',
          height: exceedSafari(14) ? '' : '2.25rem',
          padding: '',
          backgroundColor: isDark ? colorCommon.colorDarkGrey : colorCommon.colorWhite,
          '&:hover':{
            backgroundColor: isDark ? colorCommon.colorDarkGrey : colorDefault.colorPrimaryFilter,
          },
        },
        sizeSmall: {
          width: '0.875rem',
          height: exceedSafari(13) ? '' : '0.875rem',
          boxShadow: 'none',
          background: 'none'
        },
        label: {
          width: 'auto'
        },
      },
      MuiButton: {
        label: {
          fontWeight: 600,
          textTransform: 'none',
        },
        outlinedSecondary: {
          height: '1.875rem',
          borderRadius: '0.5rem',
          fontSize: '0.875rem',
          borderColor: isDark ? colorCommon.colorOutlinedBorderDark : colorCommon.colorOutlinedBorderLight,
          color: isDark ? colorCommon.colorOutlinedSecondaryDark : colorCommon.colorOutlinedSecondaryLight,
          '&:hover': {
            borderColor: isDark ? colorCommon.colorOutlinedBorderDark : colorCommon.colorOutlinedBorderHoverLight,
            backgroundColor: isDark ? colorCommon.colorBlack : colorCommon.colorWhite,
          },
          '&:active': {
            borderColor: isDark ? colorCommon.colorOutlinedActiveDark : colorCommon.colorTextGrey,
            backgroundColor: isDark ? colorCommon.colorOutlinedActiveDark : colorCommon.colorOutlinedActiveLight,
          },
          '& > span.MuiButton-label': {
            fontWeight: 400
          }
        },
        contained: {
          boxShadow: 'none',
          color: colorCommon.colorContainedLabelLight,
          backgroundColor: colorCommon.colorOutlinedActiveLight,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: colorCommon.colorContainedBackgroundHoverLight,
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: colorCommon.colorContainedBackgroundActiveLight,
          },
          '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 135, 255, 0.3)'
          }
        },
        containedPrimary: {
          backgroundColor: isDark ? colorDefault.colorPrimaryDark : colorDefault.colorPrimary,
          '&:hover': {
            backgroundColor: isDark ? colorDefault.colorHoverDark : colorDefault.colorHoverLight,
          },
          '&:active': {
            backgroundColor: isDark ? colorDefault.colorActiveDark : colorDefault.colorActiveLight,
          },
        },
        // 开启声网房间
        containedSecondary: {
          backgroundColor: colorAgora.colorPrimary,
          boxShadow: isDark ? 'none' : '0rem 0.125rem 0.5rem 0rem #CBB8FF',
          '&:hover': {
            backgroundColor: isDark ? colorAgora.colorHoverDark : colorAgora.colorHoverLight,
            boxShadow: isDark ? 'none' : '0rem 0.125rem 0.5rem 0rem rgba(97, 68, 178, 0.5)',
          },
          '&:active': {
            backgroundColor: isDark ? colorAgora.colorActiveDark : colorAgora.colorActiveLight,
            boxShadow: isDark ? 'none' : '0rem 0.125rem 0.5rem 0rem rgba(144, 124, 201, 0.5)',
          },
        },
        containedSizeSmall: {
          width: 'auto',
          padding: '.5rem',
          marginRight: '.5rem',
          marginBottom: '1rem',
          background: isDark ? colorCommon.bubbleColor : colorDefault.colorBackground,
          borderRadius: '.25rem',
          color: colorCommon.colorIssueTipTextGrey,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: isDark ? colorCommon.colorDarkGrey : colorCommon.colorContainedBackgroundHoverLight,
          }
        }
      },
      MuiContainer: {
        root: {
          background: colorCommon.colorWhite
        }
      },
      MuiOutlinedInput: {
        root: {
          caretColor: colorDefault.colorPrimary,
          borderColor: colorDefault.colorPrimary,
          borderRadius:'0.5rem',
          '&$focused $notchedOutline': {
            borderColor: colorDefault.colorPrimary,
          },
          '&:hover $notchedOutline': {
            borderColor: colorDefault.colorPrimaryLight,
          },
          '& $notchedOutline': {
            borderColor: colorCommon.colorInputBorderGrey,
          },
          '&$disabled $notchedOutline': {
            color: isDark ? color.colorWhite : colorCommon.colorBlack,
            borderColor: isDark ? colorCommon.colorBlack : colorCommon.colorWhite,
            backgroundColor: isDark ? colorCommon.colorInputDisabledDark : colorCommon.colorInputDisabledLight,
          },
        },
        input: {
          zIndex: 200,
          fontSize: '0.75rem',
          fontWeight: 600,
          '&::placeholder': {
            color: isDark ? colorCommon.colorDarkPlaceholder : color.colorSecondaryGrey,
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: isDark ? colorCommon.colorDarkModeBack : colorCommon.colorWhite,
          color: isDark ? colorCommon.colorWhite : colorCommon.colorBlack,
          boxShadow: '0px 1px 13px 0px #435C732B',
          padding: '0.5rem 0.75rem',
          borderRadius: '0.625rem',
          border: 'none',
        },
        tooltipArrow: {
          color: isDark ? color.colorWhite : colorCommon.colorBlack,
          borderColor: color.colorToolTipBorder,
        },
        arrow: {
          color: isDark ? colorCommon.colorBlack : colorCommon.colorWhite,
          borderColor: `1px solid ${color.colorToolTipBorder}`,
        },
      },
      MuiDialogContent: {
        root: {
          color: isDark ? colorCommon.colorWhite : colorCommon.colorBlack,
        }
      },
      MuiListItem: {
        button: {
          '&:hover': {
            backgroundColor: 'none'
          }
        }
      },
      MuiMenuItem:{
        root:{
          fontSize:'0.875rem'
        }
      }
    }
  });
}


const themingAgora = (isDark: boolean) => createMuiTheme({
  customColor: {
    componentsBackGround: isDark ? colorCommon.colorBlack : colorAgora.colorBackground,
    tabActiveColor: isDark ? colorCommon.colorBlack : colorAgora.colorConfirm,
    boxShadow: isDark ? 'none' : `0rem 0.06rem 0.25rem 0rem ${colorAgora.colorBoxShadow}`,
    tabBackground: isDark ? colorCommon.colorDarkGrey : colorCommon.colorWhite,
    borderColor: isDark ? colorCommon.colorDarkModeBack : colorCommon.colorBorder,
    operationButtonBackground: isDark ? colorCommon.colorTextGrey : colorCommon.colorWhite,
    operationButtonFill: isDark ? colorCommon.colorBlack : colorAgora.colorPrimary,
    timerColor: isDark ? colorCommon.colorTextGrey : colorAgora.colorText,
    settingBackground: isDark ? colorCommon.colorDarkModeBack : colorAgora.colorBackground,
    alertBoxShadow: isDark ? 'none' : `0px 1px 13px 0px rgba(67, 92, 115, 0.17)`,
    bubbleColor: isDark ? colorAgora.colorBubbleDark : colorAgora.colorConfirm,
    otherBubbleColor: isDark ? colorCommon.bubbleColor : colorCommon.colorWhite,
    participantsIconColor: isDark ? colorCommon.colorFontGrey : colorAgora.colorPrimary,
    gdprBorderColor: isDark ? colorCommon.colorOutlinedBorderDark : colorCommon.colorBorder,
    badgeBackgroundColor: isDark ? colorCommon.colorWhite : colorAgora.colorPrimary,
    badgeColor: isDark ? colorCommon.colorTitleTextColor : colorCommon.colorWhite,
    tooltipTextColor: isDark ? colorCommon.colorFontGrey : colorCommon.colorTitleTextColor,
    commonModelBackground: isDark ? colorCommon.colorBlack : colorCommon.colorWhite,
    tooltipBoxShadow: isDark ? 'none' : `0rem 0.06rem 0.81rem 0rem ${colorCommon.colorToolTipBoxShadow}`,
    tooltipBorderColor: isDark ? colorCommon.colorBlack : colorCommon.colorToolTipBoxShadow,
    settingItemBackground: isDark ? colorCommon.colorBlack : colorCommon.colorWhite,
    settingTipBackground: isDark ? colorCommon.bubbleColor : colorCommon.colorSettingTipWhite,
    settingTextColor: isDark ? colorCommon.colorOutlinedSecondaryDark : colorCommon.colorTitleTextColor,
    qualityFeedBackLine: isDark ? color.colorBlack : '#f1f1f1',
    selectMenuItemHover: isDark ? colorCommon.colorOutlinedActiveDark : colorCommon.colorMenuItemHoverBlue,
    issueDialogTitle: isDark ? colorCommon.colorDarkGrey : colorCommon.colorWhite,
    issueDialogTitleBorder: isDark ? colorCommon.colorDarkModeBack : colorCommon.colorIssueTitleBorderBlue,
    issueDialogContent: isDark ? colorCommon.colorBlack : colorCommon.colorWhite,
    issueStreamId: isDark ? colorCommon.colorIssueStreamIdDark : colorCommon.colorIssueStreamIdGrey,
    issueButtonSelected: isDark ? colorAgora.colorIssueFeedbackSelectedDark : colorAgora.colorBackground,
    issueButtonSelectedText: isDark ? colorAgora.colorIssueFeedbackSelectedTextLight : colorCommon.colorIssueButtonSelectedPink,
    issueFeedBackInfoText: isDark ? colorCommon.colorIssueStreamIdDark : colorCommon.colorIssueFeedBackInfoGrey,
    issueFeedBackTextArea: isDark ? colorCommon.colorWhite : colorAgora.colorPrimary,
    issueFeedBackResult: isDark ? colorCommon.colorDarkModeBack : colorCommon.colorWhite,
    issueFeedBackDialogText: isDark ? colorCommon.colorIssueFeedBackDialogDarkGrey : colorCommon.colorIssueTipTextGrey,
    issueFeedBackCancelBackground: isDark ? colorCommon.colorTextGrey : colorAgora.colorBackground,
    downloadTextColor: isDark ? colorCommon.colorDarkPlaceholder : color.colorSecondaryGrey,
    roomHeaderButtonColor: colorAgora.colorBackground,
    BubbleShadow: isDark ? 'none' : colorAgora.colorBubbleShadow,
    selectedColor: isDark ? colorCommon.colorOutlinedActiveDark : colorAgora.colorBackground,
    videoPreviewBackgroundColor: isDark ? colorCommon.bubbleColor : colorAgora.colorBackground,
    occupationsIconColor: isDark ? colorCommon.colorLightGrey : colorCommon.colorWhite,
    settingTabBackgroundColor: isDark ? colorCommon.colorDarkGrey : colorAgora.colorBackground,
    homepageBackgroundColor: !isDark ? colorAgora.colorHomepageBackground : colorAgora.colorHomepageBackgroundDark,
    buttonHoverColor: isDark ? colorCommon.colorDarkGrey : colorAgora.colorPrimaryFilter,
    participantsButtonHover: isDark ? colorCommon.colorParticipantsButtonHover : colorAgora.colorParticipantsButtonHover,
    controlAudioActiveBackground: isDark ? colorCommon.colorAudioActiveBackgroundDark : colorAgora.colorAudioActiveBackground,
    controlAudioActiveShade: isDark ? colorCommon.colorFontGrey : colorAgora.colorPrimary,
    voiceVolumeActiveBackground: colorAgora.colorAudioActiveBackground,
  },
  palette: {
    ...commonPalette,
    type: isDark ? 'dark' : 'light',
    primary: {
      main: colorAgora.colorPrimary,
      dark: colorAgora.colorPrimaryLight,
      contrastText: colorCommon.colorWhite,
      light: colorAgora.colorPrimaryLight
    },
    secondary: {
      main: colorAgora.colorBackground,
      light: colorAgora.colorBackground,
      contrastText: colorCommon.colorWhite,
      dark: colorCommon.colorBlack
    },
    text: {
      primary: isDark ? colorCommon.colorFontGrey : colorCommon.colorTitleTextColor,
      secondary: colorCommon.colorWhite,
    },
    background: {
      default: isDark ? colorCommon.colorDarkModeBack : colorCommon.colorWhite,
      paper: isDark ? colorCommon.colorDarkModeBack : colorCommon.colorWhite,
    },
    info: {
      main: isDark ? colorCommon.colorWhite : colorCommon.colorInputBorderGrey,
    },
    error: {
      main: isDark ? colorCommon.colorLightRed : colorCommon.colorError,
      dark: colorCommon.colorLightRed
    },
    success: {
      main: isDark ? colorCommon.colorGreenLight : colorCommon.colorGreen,
      dark: colorCommon.colorGreenLight
    },
    warning: {
      main: isDark ? colorCommon.colorLightOrange : colorCommon.colorOrange
    }
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        ...commonDrawer,
      },
      elevation8: {
        boxShadow: isDark ? 'none' : `0px 1px 13px 0px rgba(67, 92, 115, 0.17)`,
      }
    },
    MuiTypography: {
      ...commonOverrides.MuiTypography,
      subtitle1: {
        fontSize: '0.875rem',
        fontWeight: 400,
        color: isDark ? colorCommon.colorOutlinedSecondaryDark : colorCommon.colorTitleTextColor,
      }
    },
    MuiDrawer: {
      paperAnchorDockedRight: {
        transform: 'none',
        transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms'
      }
    },
    MuiSelect: {
      root: {
        display: 'flex',
        alignItems: 'center',
        height: '1.875rem',
        width: '25.25rem',
        padding: 0,
        paddingLeft: '0.8125rem',
        borderRadius: '.5rem',
        fontSize: '.875rem',
        fontWeight: 400,
      },
      select: {
        '&:hover': {
          // backgroundColor: isDark ? colorCommon.colorOutlinedActiveDark : colorAgora.colorBackground,
        },
        '&:focus': {
          // backgroundColor: isDark ? colorCommon.colorOutlinedActiveDark : colorAgora.colorBackground,
          backgroundColor:'none'
        },
      },
      selectMenu: {
        height: '1.875rem',
      },
      outlined: {
        paddingRight: '1.1875rem !important'
      },
      icon: {
        top: 0,
        right: 0,
      },
    },
    MuiButton: {
      label: {
        fontWeight: 600,
        textTransform: 'none',
      },
      outlinedSecondary: {
        height: '1.875rem',
        borderRadius: '0.5rem',
        fontSize: '0.875rem',
        borderColor: isDark ? colorCommon.colorOutlinedBorderDark : colorCommon.colorOutlinedBorderLight,
        color: isDark ? colorCommon.colorOutlinedSecondaryDark : colorCommon.colorOutlinedSecondaryLight,
        '&:hover': {
          borderColor: isDark ? colorCommon.colorOutlinedBorderDark : colorCommon.colorOutlinedBorderHoverLight,
          backgroundColor: isDark ? colorCommon.colorBlack : colorCommon.colorWhite,
        },
        '&:active': {
          borderColor: isDark ? colorCommon.colorOutlinedActiveDark : colorCommon.colorTextGrey,
          backgroundColor: isDark ? colorCommon.colorOutlinedActiveDark : colorCommon.colorOutlinedActiveLight,
        },
        '& > span.MuiButton-label': {
          fontWeight: 400
        }
      },
      contained: {
        boxShadow: 'none',
        color: colorCommon.colorContainedLabelLight,
        backgroundColor: colorCommon.colorOutlinedActiveLight,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: colorCommon.colorContainedBackgroundHoverLight,
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: colorCommon.colorContainedBackgroundActiveLight,
        },
        '&.Mui-disabled': {
          backgroundColor: 'rgba(139, 98, 255, 0.3)'
        }
      },
      containedPrimary: {
        backgroundColor: isDark ? colorAgora.colorPrimaryLight : colorAgora.colorPrimary,
        '&:hover': {
          backgroundColor: isDark ? colorAgora.colorHoverDark : colorAgora.colorHoverLight,
          boxShadow: '0rem 0.125rem 0.5rem 0rem rgba(97, 68, 178, 0.5)',
        },
        '&:active': {
          backgroundColor: isDark ? colorAgora.colorActiveDark : colorAgora.colorActiveLight,
          boxShadow: '0rem 0.125rem 0.5rem 0rem rgba(144, 124, 201, 0.5)',
        },
      },
      // 开启普通房间
      containedSecondary: {
        backgroundColor: colorDefault.colorPrimary,
        boxShadow: isDark ? 'none' : '0rem 0.125rem 0.5rem 0rem rgba(0, 135, 255, 0.5)',
        '&:hover': {
          backgroundColor: isDark ? colorDefault.colorHoverDark : colorDefault.colorHoverLight,
          boxShadow: isDark ? 'none' : '0rem 0.125rem 0.5rem 0rem rgba(0, 105, 217, 0.5)',
        },
        '&:active': {
          backgroundColor: isDark ? colorDefault.colorActiveDark : colorDefault.colorActiveLight,
          boxShadow: isDark ? 'none' : '0rem 0.125rem 0.5rem 0rem rgba(0, 98, 204, 0.5)',
        },
        '& > span.MuiButton-label': {
          fontWeight: 400
        },
      },
      // dump
      containedSizeSmall: {
        width: 'auto',
        padding: '.5rem',
        marginRight: '.5rem',
        marginBottom: '1rem',
        background: isDark ? colorCommon.bubbleColor : colorAgora.colorBackground,
        borderRadius: '.25rem',
        color: colorAgora.colorTextContainedSmall,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: isDark ? colorCommon.colorDarkGrey : colorCommon.colorContainedBackgroundHoverLight,
        },
      }
    },
    MuiIconButton: {
      root: {
        borderRadius: '0.54rem',
        boxShadow: isDark ? 'none' : `0rem 0.06rem 0.25rem 0rem ${colorAgora.colorBoxShadow}`,
        width: exceedSafari(14) ? '' : '2.25rem',
        height: exceedSafari(14) ? '' : '2.25rem',
        padding: '',
        backgroundColor: isDark ? colorCommon.colorDarkGrey : colorCommon.colorWhite,
        '&:hover': {
          backgroundColor: isDark ? colorCommon.colorDarkGrey : colorAgora.colorPrimaryFilter,
        }
      },
      sizeSmall: {
        width: '0.875rem',
        height: exceedSafari(13) ? '' : '0.875rem',
        boxShadow: 'none',
        background: 'none'
      },
      label: {
        width: 'auto'
      },
    },
    MuiOutlinedInput: {
      root: {
        caretColor: colorAgora.colorPrimary,
        borderColor: colorAgora.colorPrimary,
        borderRadius:'0.5rem',
        '&$focused $notchedOutline': {
          borderColor: colorAgora.colorPrimary,
        },
        '&:hover $notchedOutline': {
          borderColor: colorAgora.colorPrimaryLight,
        },
        '& $notchedOutline': {
          borderColor: colorCommon.colorInputBorderGrey,
        },
        '&$disabled $notchedOutline': {
          color: isDark ? color.colorWhite : colorCommon.colorBlack,
          borderColor: isDark ? colorCommon.colorBlack : colorCommon.colorWhite,
          backgroundColor: isDark ? colorCommon.colorInputDisabledDark : colorCommon.colorInputDisabledLight,
        },
      },
      input: {
        // improve z-index, avoid input text hide by '&$disabled $notchedOutline'
        zIndex: 200,
        // color: color.colorWhite,
        fontSize: '0.75rem',
        fontWeight: 600,
        '&::placeholder': {
          color: isDark ? colorCommon.colorDarkPlaceholder : color.colorSecondaryGrey,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: isDark ? colorCommon.colorDarkModeBack : colorCommon.colorWhite,
        color: isDark ? colorCommon.colorWhite : colorCommon.colorBlack,
        boxShadow: '0px 1px 13px 0px #435C732B',
        padding: '0.5rem 0.75rem',
        borderRadius: '0.625rem',
        border: 'none',
      },
      tooltipArrow: {
        color: isDark ? color.colorWhite : colorCommon.colorBlack,
        borderColor: color.colorToolTipBorder,
      },
      arrow: {
        color: isDark ? colorCommon.colorBlack : colorCommon.colorWhite,
        borderColor: `1px solid ${color.colorToolTipBorder}`,
      }
    },
    MuiDialogContent: {
      root: {
        color: isDark ? colorCommon.colorWhite : colorCommon.colorBlack,
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: 'none'
        }
      }
    },
    MuiMenuItem:{
      root:{
        fontSize:'0.875rem'
      }
    }
  }
});

export {
  themingDefault,
  themingAgora,
}
