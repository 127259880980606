import { action, computed, observable } from "mobx";
import { BizUser } from "../types/bizUser";
import { hasReason, ROOM_MODE, UserChangedReason } from "../utils/constants";
import { isChinese } from "../utils/helper";
import { log, LOG_MODULE, LOG_TYPE } from "../utils/Log";
import { MediaUser } from "./mediaUser";
import ThirdPartyUser from "./thirdPartyUser";

// value has list priority
export const MATCH_NONE = 0
export const MATCH_NAME_PINYIN = 1
export const MATCH_NAME = 2
export const MATCH_THIRDPARTY_ALIAS_PINYIN = 3
export const MATCH_THIRDPARTY_ALIAS = 4
export const MATCH_THIRDPARTY_NAME_PINYIN = 5
export const MATCH_THIRDPARTY_NAME = 6
export const MATCH_FULL_NAME_PINYIN = 7
export const MATCH_FULL_NAME = 8
export const MATCH_HELP_NAME_PINYIN = 9
export const MATCH_HELP_NAME = 10

interface MatchResult {
  type: number
  start: number
  end: number
}

export enum AttendeePriority {
  ATTENDEE_PRIORITY_NONE = 0,
  ATTENDEE_PRIORITY_AUDIO = 1,
  ATTENDEE_PRIORITY_VIDEO = 2,
  ATTENDEE_PRIORITY_DUMPING = 3,
  ATTENDEE_PRIORITY_ASSISTANT = 4,
  ATTENDEE_PRIORITY_HOST = 5,
  ATTENDEE_PRIORITY_SHARE = 6,
  ATTENDEE_PRIORITY_RECORDING = 7,
  ATTENDEE_PRIORITY_ME = 8,
}

export enum MajorPriority {
  MAJOR_PRIORITY_NONE = 0,
  MAJOR_PRIORITY_ME_DEFAULT = 1,
  MAJOR_PRIORITY_REMOTE_AUDIO = 2,
  MAJOR_PRIORITY_REMOTE_VIDEO = 3,
  MAJOR_PRIORITY_USER_CHOOSE = 4,
  MAJOR_PRIORITY_SCREEN_SHARE = 5,
}

export enum MediaListPriority {
  MEDIA_LIST_PRIORITY_AUDIO = 0,
  MEDIA_LIST_PRIORITY_VIDEO = 1,
  MEDIA_LIST_PRIORITY_SCREEN_SHARE = 2,
  MEDIA_LIST_PRIORITY_SCREEN_OWNER_VIDEO = 3,
  MEDIA_LIST_PRIORITY_ME = 4,
}

export class CommUser {
  @observable
  uid: string = ''
  @observable
  streamId: number = 0

  // Biz User State
  @observable
  name: string = ''
  @observable
  helpName:string = ''
  @observable
  shareId: number = 0
  @observable
  isHost: boolean = false
  @observable
  isAssistant: boolean = false
  @observable
  online: boolean = false
  @observable
  isInterrupt: boolean = false
  @observable
  isCloudRecording: boolean = false
  @observable
  isIssueRecording: boolean = false
  @observable
  portraitId: string = ''
  @observable
  feature: number = 0

  // ThirdParty Info
  @observable
  thirdpartyName: string = ''
  @observable
  thirdpartyAlias: string = ''
  @observable
  thirdpartyDepartment: string = ''
  @observable
  thirdpartySource: number = 0
  @observable
  isLoggedIn: boolean = false
  @observable
  inviteBy: string = ''

  // Media User State
  @observable
  audioState: boolean = false
  @observable
  videoState: boolean = false
  @observable
  signalQuality: number = 0
  @observable
  isSpeaking: boolean = false
  @observable
  isNeedWatermark: boolean = false

  @observable
  shareUser?: CommUser
  @observable
  parentStreamId: number = 0

  @observable
  showMode: 'tourist' | 'member'

  @observable
  isAudioPending: boolean = false
  @observable
  isVideoPending: boolean = false
  @observable
  subscribe :boolean = true

  // Others
  isMe: boolean = false
  audioSeq: number = 0
  videoSeq: number = 0
  audioMuteTs: number = 0
  videoMuteTs: number = 0
  isBizLost: boolean = true
  isRtcLost: boolean = true
  // 区分屏幕共享的开始的触发方式，满足产品需求： 房间内开始屏幕共享时，弹框提醒
  isShareJustStartByBiz = false
  bizShareId: number = 0
  hasBizShareWatermark: boolean = false
  mediaShareId: number = 0
  hasMediaShareWatermark: boolean = false
  userClickChoose: boolean = false
  tx: number = 0
  rx: number = 0
  dumpAudioEffectOnce: boolean = false
  createTimestamp = 0

  constructor(mode: ROOM_MODE, mediaUser?: MediaUser, bizUser?: BizUser) {
    this.showMode = mode === ROOM_MODE.MODE_NORMAL ? 'tourist' : 'member'
    if (mediaUser) {
      this.updateByMediaReason(mediaUser, UserChangedReason.REASON_MEDIA_UPDATE)
    }

    if (bizUser) {
      this.updateByBizReason(bizUser, UserChangedReason.REASON_BIZ_UPDATE)
    }

    this.createTimestamp = (new Date()).valueOf()
  }

  @computed
  public get userName() {
    if (this.showMode === 'tourist') {
      return this.name
    }
    if (this.isLoggedIn && this.thirdpartyName) {
      return this.thirdpartyName
    }
    return this.name
  }

  @computed
  public get userFullName() {
    if (this.showMode === 'tourist') {
      return this.name
    }

    let alias = ''
    if (this.thirdpartyAlias) {
      alias = this.isLoggedIn ? ` (${this.thirdpartyAlias})` : this.thirdpartyAlias
    }

    const fullName = `${this.thirdpartyName}${alias}`
    return fullName.length > 0 ? fullName : this.name
  }

  @computed
  public get showName() {
    if (this.isAssistant) {
      return this.helpName
    }
    return this.userFullName
  }

  @computed
  public get hasWatermark() {
    if (this.showMode === 'tourist') {
      return this.name
    }

    let alias = ''
    if (this.thirdpartyAlias) {
      alias = this.isLoggedIn ? ` (${this.thirdpartyAlias})` : this.thirdpartyAlias
    }

    const fullName = `${this.thirdpartyName}${alias}`
    return fullName.length > 0 ? fullName : this.name
  }

  @action
  public lostRtc() {
    if (this.isMe) {
      return UserChangedReason.REASON_MEDIA_UPDATE
    }
    this.isRtcLost = true
    this.audioState = false
    this.videoState = false
    this.isSpeaking = false
    this.signalQuality = 0
    const changed = this.updateOnlineState()
    return changed ? (UserChangedReason.REASON_MEDIA_UPDATE | UserChangedReason.REASON_USER_LEAVE) : UserChangedReason.REASON_MEDIA_UPDATE ;
  }

  @action
  public lostRtm() {
    this.isBizLost = true
    this.bizShareId = 0
    const changed = this.updateOnlineState()
    return changed ? UserChangedReason.REASON_USER_LEAVE : UserChangedReason.REASON_NONE
  }

  @action
  public setIsMe(isMe: boolean) {
    this.isMe = isMe
  }

  @action
  public updateBasicInfo(streamId: number, uid: string, name: string, audio: boolean, video: boolean, info?: ThirdPartyUser) {
    this.streamId = streamId
    this.uid = uid
    this.name = name
    this.audioState = audio
    this.videoState = video

    if (info) {
      this.isLoggedIn = true
      this.thirdpartyName = info.name
      this.thirdpartyAlias = info.alias
      this.thirdpartyDepartment = info.department
    }

    this.generatePinYin()
  }

  public isMediaActive() {
    return this.audioState || this.videoState
  }

  public isEntityMediaActive(): boolean {
    return this.isMediaActive() || this.shareUser !== undefined || (this.isMe && this.shareId > 0)
  }

  public isAllLost(): boolean {
    return this.isBizLost && this.isRtcLost && (!this.shareUser || this.shareUser.isAllLost()) && !this.isMe && !this.isAssistant
  }

  public isMediaActiveOrJustMuted(): boolean {
    const current = Date.now()
    return this.isMediaActive() || (current - this.audioMuteTs) < 2 * 1000 || (current - this.videoMuteTs) < 2 * 1000
  }

  @computed
  public get isShareOwner() {
    return this.shareId !== 0
  }

  public isShareStream() {
    return this.parentStreamId !== 0
  }

  public isCurrentSharing(): boolean {
    return this.shareId !== 0 && this.shareUser !== undefined
  }
  public updateMediaShareId(shareId: number, hasWatermark: boolean) {
    log(`${this.streamId} updateMediaShareId mediaShareId: ${this.streamId}`)
    this.mediaShareId = shareId
    this.hasMediaShareWatermark = hasWatermark
  }

  @action
  public updateByMediaReason(mediaUser: MediaUser, reason: number): number {
    let changed = 0

    if (hasReason(reason, UserChangedReason.REASON_INFO)) {
      if (this.streamId !== mediaUser.streamId) {
        this.streamId = mediaUser.streamId;
      }

      if ((this.name.length === 0 || this.isBizLost) && mediaUser.name.length !== 0) {
        this.name = mediaUser.name
        changed |= UserChangedReason.REASON_INFO
        this.generatePinYin()
      }

      if (this.parentStreamId !== mediaUser.parentStreamId) {
        this.parentStreamId = mediaUser.parentStreamId
        changed |= UserChangedReason.REASON_SHARE
      }
    }

    if (hasReason(reason, UserChangedReason.REASON_AUDIO)) {
      if (this.audioState !== mediaUser.audioState) {
        if (!mediaUser.audioState) {
          this.audioMuteTs = Date.now()
        }

        this.audioState = mediaUser.audioState
        changed |= UserChangedReason.REASON_AUDIO

        if (this.isAudioPending) {
          this.setAudioPending(false)
        }

        if (!this.audioState && this.isSpeaking) {
          this.isSpeaking = false
          changed |= UserChangedReason.REASON_SPEAKING
        }
      }
    }

    if (hasReason(reason, UserChangedReason.REASON_VIDEO)) {
      if (this.videoState !== mediaUser.videoState) {
        if (!mediaUser.videoState) {
          this.videoMuteTs = Date.now()
        }

        this.videoState = mediaUser.videoState
        changed |= UserChangedReason.REASON_VIDEO

        if (this.isVideoPending) {
          this.setVideoPending(false)
        }
      }
    }

    if (hasReason(reason, UserChangedReason.REASON_SPEAKING)) {
      if (this.isSpeaking !== mediaUser.isSpeaking) {
        this.isSpeaking = mediaUser.isSpeaking
        changed |= UserChangedReason.REASON_SPEAKING
      }
    }

    if (hasReason(reason, UserChangedReason.REASON_QUALITY)) {
      if (this.signalQuality !== mediaUser.signalQuality) {
        this.signalQuality = mediaUser.signalQuality
        changed |= UserChangedReason.REASON_QUALITY
      }
    }

    this.isRtcLost = false
    if (this.updateOnlineState()) {
      changed |= UserChangedReason.REASON_USER_LEAVE;
    }
    return changed
  }

  @action
  public updateByBizReason(bizUser: BizUser, reason: number): number {
    let changed = 0

    if (hasReason(reason, UserChangedReason.REASON_HOST)) {
      if (this.isHost !== bizUser.isHost) {
        this.isHost = bizUser.isHost
        changed |= UserChangedReason.REASON_HOST
      }
    }

    if (hasReason(reason, UserChangedReason.REASON_SHARE)) {
      if (this.bizShareId !== bizUser.shareId) {
        this.bizShareId = bizUser.shareId
        log(`updateByBizReason bizShareId: ${this.bizShareId}`, LOG_TYPE.INFO, LOG_MODULE.COMM)
        if (this.hasBizShareWatermark !== bizUser.hasWatermark()) {
          this.hasBizShareWatermark = bizUser.hasWatermark()
        }
      }

      if (hasReason(changed, UserChangedReason.REASON_SHARE)) {
        if (this.shareId !== 0 && reason === UserChangedReason.REASON_SHARE) {
          this.isShareJustStartByBiz = true
        } else {
          this.isShareJustStartByBiz = false
        }
      }
    }

    if (hasReason(reason, UserChangedReason.REASON_INFO)) {
      if (this.uid !== bizUser.uid) {
        this.uid = bizUser.uid
        changed |= UserChangedReason.REASON_INFO
      }

      if (this.streamId === 0) {
        this.streamId = bizUser.streamId
        changed |= UserChangedReason.REASON_INFO
      }

      if (this.name !== bizUser.name) {
        this.name = bizUser.name
        changed |= UserChangedReason.REASON_INFO
      }

      if (this.portraitId !== bizUser.portraitId) {
        this.portraitId = bizUser.portraitId
        changed |= UserChangedReason.REASON_INFO
      }

      if (this.isLoggedIn !== bizUser.isLoggedIn) {
        this.isLoggedIn = bizUser.isLoggedIn
        changed |= UserChangedReason.REASON_INFO
      }

      if (this.thirdpartyName !== bizUser.thirdpartyName || this.thirdpartyAlias !== bizUser.thirdpartyAlias || this.thirdpartyDepartment !== bizUser.thirdpartyDepartment) {
        // if (!this.isMe) {
        //   this.thirdpartyName = bizUser.thirdpartyName
        // }
        this.thirdpartyName = bizUser.thirdpartyName
        this.thirdpartyAlias = bizUser.thirdpartyAlias
        this.thirdpartyDepartment = bizUser.thirdpartyDepartment
        this.thirdpartySource = bizUser.source
        changed |= UserChangedReason.REASON_INFO
      }

      if (this.inviteBy !== bizUser.inviteBy) {
        this.inviteBy = bizUser.inviteBy
        changed |= UserChangedReason.REASON_INFO
      }

      if (this.feature !== bizUser.feature) {
        this.feature = bizUser.feature
        changed |= UserChangedReason.REASON_INFO
      }
    }

    if (hasReason(reason, UserChangedReason.REASON_INTERRUPT)) {
      if (this.isInterrupt !== bizUser.IsInterrupt()) {
        this.isInterrupt = bizUser.IsInterrupt()
        changed |= UserChangedReason.REASON_INTERRUPT
      }
    }

    if (hasReason(reason, UserChangedReason.REASON_CLOUD_RECORDING)) {
      if (this.isCloudRecording !== bizUser.isCloudRecording()) {
        this.isCloudRecording = bizUser.isCloudRecording()
        changed |= UserChangedReason.REASON_CLOUD_RECORDING
      }
    }

    if (hasReason(reason, UserChangedReason.REASON_DUMPING_ISSUE)) {
      if (this.isIssueRecording !== bizUser.isDumping()) {
        this.isIssueRecording = bizUser.isDumping()
        changed |= UserChangedReason.REASON_DUMPING_ISSUE
      }
    }

    this.isBizLost = false;
    if (this.updateOnlineState()) {
      changed |= UserChangedReason.REASON_USER_LEAVE;
    }

    if (hasReason(changed, UserChangedReason.REASON_INFO)) {
      this.generatePinYin()
    }
    return changed
  }

  private updateOnlineState() {
    const isOnline =  !this.isRtcLost
    if (this.online !== isOnline) {
      this.online = isOnline;
      return true;
    }
    return false
  }

  @action
  public updateInfoByShareOwner(owner: CommUser) {
    if (this.name !== owner.name) {
      this.name = owner.name
    }
    if (this.helpName !== owner.helpName) {
      this.helpName = owner.helpName
    }

    if (this.portraitId !== owner.portraitId) {
      this.portraitId = owner.portraitId
    }

    if (this.isLoggedIn !== owner.isLoggedIn) {
      this.isLoggedIn = owner.isLoggedIn
      this.thirdpartyName = owner.thirdpartyName
      this.thirdpartyAlias = owner.thirdpartyAlias
      this.thirdpartyDepartment = owner.thirdpartyDepartment
      this.thirdpartySource = owner.thirdpartySource
    }

    if (this.inviteBy !== owner.inviteBy) {
      this.inviteBy = owner.inviteBy
    }
  }

  @action
  public updateInfoByShareStream(owner: CommUser) {
    if (this.name !== owner.name) {
      this.name = owner.name
    }
  }

  @action
  public setIsHost(isHost: boolean) {
    this.isHost = isHost
  }

  @action
  public setHelpName(name: string) {
    this.helpName = name
  }

  @action
  public setIsAssistant(isAssistant: boolean) {
    this.isAssistant = isAssistant
  }

  @action updateAssistantInfo(isAssistant: boolean) {
    this.isAssistant = isAssistant
    if (isAssistant) {
      this.setHelpName(isChinese() ? '同声传译' : 'Interpreter')
      this.generatePinYin()
    } else {
      this.setHelpName('')
    }
  }

  @action
  public setOnlineStatus(online: boolean) {
    this.online = online
  }

  @action
  public setAudioPending(pending: boolean) {
    this.isAudioPending = pending
  }

  @action
  public setVideoPending(pending: boolean) {
    this.isVideoPending = pending
  }

  public priorityInAttendees(): number {
    if (this.isMe) {
      return AttendeePriority.ATTENDEE_PRIORITY_ME
    }

    if (this.isCloudRecording) {
      return AttendeePriority.ATTENDEE_PRIORITY_RECORDING
    }

    if (this.shareId !== 0) {
      return AttendeePriority.ATTENDEE_PRIORITY_SHARE
    }

    if (this.isHost) {
      return AttendeePriority.ATTENDEE_PRIORITY_HOST
    }

    if (this.isAssistant) {
      return AttendeePriority.ATTENDEE_PRIORITY_ASSISTANT
    }

    if (this.isIssueRecording) {
      return AttendeePriority.ATTENDEE_PRIORITY_DUMPING
    }

    if (this.videoState) {
      return AttendeePriority.ATTENDEE_PRIORITY_VIDEO
    }

    if (this.audioState) {
      return AttendeePriority.ATTENDEE_PRIORITY_AUDIO
    }

    return AttendeePriority.ATTENDEE_PRIORITY_NONE
  }

  public priorityToMajor(): number {
    if (this.isShareStream()) {
      return MajorPriority.MAJOR_PRIORITY_SCREEN_SHARE
    }
    if (this.userClickChoose && this.isMediaActive()) {
      return MajorPriority.MAJOR_PRIORITY_USER_CHOOSE
    }
    if (this.videoState && !this.isMe) {
      return MajorPriority.MAJOR_PRIORITY_REMOTE_VIDEO
    }
    if (this.audioState && !this.isMe) {
      return MajorPriority.MAJOR_PRIORITY_REMOTE_AUDIO
    }
    if (this.isMe) {
      return MajorPriority.MAJOR_PRIORITY_ME_DEFAULT
    }
    return MajorPriority.MAJOR_PRIORITY_NONE
  }

  public priorityInMediaList(): number {
    if (this.isMe) {
      return MediaListPriority.MEDIA_LIST_PRIORITY_ME
    }

    if (this.isCurrentSharing() && this.videoState) {
      return MediaListPriority.MEDIA_LIST_PRIORITY_SCREEN_OWNER_VIDEO
    }

    if (this.parentStreamId !== 0) {
      return MediaListPriority.MEDIA_LIST_PRIORITY_SCREEN_SHARE
    }

    if (this.videoState) {
      return MediaListPriority.MEDIA_LIST_PRIORITY_VIDEO
    }

    return MediaListPriority.MEDIA_LIST_PRIORITY_AUDIO
  }

  public giveUpMajor() {
    this.userClickChoose = false;
  }

  @action
  public linkShare(share: CommUser) {
    this.shareId = share.streamId
    this.shareUser = share
    share.parentStreamId = this.streamId
    share.isNeedWatermark = share.streamId === this.bizShareId ? this.hasBizShareWatermark : this.hasMediaShareWatermark
    share.isAssistant = this.isAssistant
    share.subscribe = this.subscribe
  }

  @action
  public unlinkShare() {
    // !!! media_share_id_ may update by new share user meta info,
    if (this.shareId === this.mediaShareId) {
      this.updateMediaShareId(0, false)
    }

    this.shareId = 0
    this.shareUser = undefined
    this.isShareJustStartByBiz = false
  }

  public isNameUnknown() {
    return !this.name && !this.thirdpartyName && !this.thirdpartyAlias
  }

  public description(): string {
    return `is me: ${this.isMe} stream id: ${this.streamId} uid: ${this.uid} name: ${this.name} audio: ${this.audioState} video: ${this.videoState} share id: ${this.shareId}`
  }

  private nameLowerCase: string = ''
  private namePinYin: string[] = []
  private namePinYinGroup: string = ''
  private namePinYinInitialPos: number[] = []
  private namePinYinInitialGroup: string = ''
  private thirdPartyNameLowerCase: string = ''
  private thirdPartyNamePinYin: string[] = []
  private thirdPartyNamePinYinGroup: string = ''
  private thirdPartyNamePinYinInitialPos: number[] = []
  private thirdPartyNamePinYinInitialGroup: string = ''
  private thirdPartyAliasLowerCase: string = ''
  private thirdPartyAliasPinYin: string[] = []
  private thirdPartyAliasPinYinGroup: string = ''
  private thirdPartyAliasPinYinInitialPos: number[] = []
  private thirdPartyAliasPinYinInitialGroup: string = ''
  private helpNameLowerCase: string = ''
  private helpNamePinyinGroup: string = ''
  private helpNamePinyin: string[] = []
  private helpNamePinYinInitialGroup: string = ''
  private helpNamePinYinInitialInitialPos: number[] = []

  public matchStart: number = 0
  public matchEnd: number = 0
  public matchType: number = MATCH_NONE

  public generatePinYin() {
    this.nameLowerCase = this.name.toLowerCase()
    if (/.*[\u4e00-\u9fa5]+.*$/.test(this.nameLowerCase)) {
      this.namePinYin = (window as any).getPinyin(this.nameLowerCase, ' ', false).split(" ")
      this.namePinYinGroup = this.namePinYin.join('')
      this.namePinYinInitialGroup = ''
      this.namePinYinInitialPos = []
      this.namePinYin.forEach((item: string, index: number) => {
        if (item.length > 0) {
          this.namePinYinInitialGroup += item[0]
          this.namePinYinInitialPos.push(index)
        }
      })
      this.namePinYinInitialPos.push(this.namePinYin.length)
    }

    if (this.showMode === 'member') {
      this.thirdPartyNameLowerCase = this.thirdpartyName.toLowerCase()
      if (/.*[\u4e00-\u9fa5]+.*$/.test(this.thirdPartyNameLowerCase)) {
        this.thirdPartyNamePinYin = (window as any).getPinyin(this.thirdPartyNameLowerCase, ' ', false).split(" ")
        this.thirdPartyNamePinYinGroup = this.thirdPartyNamePinYin.join('')
        this.thirdPartyNamePinYin.forEach((item: string, index: number) => {
          if (item.length > 0) {
            this.thirdPartyNamePinYinInitialGroup += item[0]
            this.thirdPartyNamePinYinInitialPos.push(index)
          }
        })
        this.thirdPartyNamePinYinInitialPos.push(this.thirdPartyNamePinYin.length)
      }

      this.thirdPartyAliasLowerCase = this.thirdpartyAlias.toLowerCase()
      if (/.*[\u4e00-\u9fa5]+.*$/.test(this.thirdPartyAliasLowerCase)) {
        this.thirdPartyAliasPinYin = (window as any).getPinyin(this.thirdPartyAliasLowerCase, ' ', false).split(" ")
        this.thirdPartyAliasPinYinGroup = this.thirdPartyAliasPinYin.join('')
        this.thirdPartyAliasPinYin.forEach((item: string, index: number) => {
          if (item.length > 0) {
            this.thirdPartyAliasPinYinInitialGroup += item[0]
            this.thirdPartyAliasPinYinInitialPos.push(index)
          }
        })
        this.thirdPartyAliasPinYinInitialPos.push(this.thirdPartyAliasPinYin.length)
      }
      if (this.isAssistant) {
        this.helpNameLowerCase = this.helpName.toLocaleLowerCase()
        if (/.*[\u4e00-\u9fa5]+.*$/.test(this.helpNameLowerCase)) {
          this.helpNamePinyin = (window as any).getPinyin(this.helpNameLowerCase, ' ', false).split(" ")
          this.helpNamePinyinGroup = this.helpNamePinyin.join('')
          this.helpNamePinyin.forEach((item: string, index: number) => {
            if (item.length > 0) {
              this.helpNamePinYinInitialGroup += item[0]
              this.helpNamePinYinInitialInitialPos.push(index)
            }
          })
        }
      }
    }

    // console.log(`generatePinYin name: ${this.name} third: ${this.thirdpartyName} alias: ${this.thirdpartyAlias}`)
    // console.log(`pinyin: ${this.namePinYin} pinyin group: ${this.namePinYinGroup} pinyin initial: ${this.namePinYinInitialGroup}`)
    // console.log(`pinyin: ${this.thirdPartyNamePinYin} pinyin group: ${this.thirdPartyNamePinYinGroup} pinyin initial: ${this.thirdPartyNamePinYinInitialGroup}`)
    // console.log(`pinyin: ${this.thirdPartyAliasPinYin} pinyin group: ${this.thirdPartyAliasPinYinGroup} pinyin initial: ${this.thirdPartyAliasPinYinInitialGroup}`)
  }

  public match(input: string): number {
    this.matchType = MATCH_NONE
    this.matchStart = 0
    this.matchEnd = 0

    const inputLowerCase = input.toLowerCase()
    const checkPinYin = /^[a-z]+$/.test(inputLowerCase)

    let ret: MatchResult
    ret = this.doMatch(inputLowerCase, this.showName.toLocaleLowerCase(), MATCH_FULL_NAME, MATCH_FULL_NAME_PINYIN, false,
      [], '', '', [0])
    if (ret.type > 0) {
      this.matchType = ret.type
      this.matchStart = ret.start
      this.matchEnd = ret.end
      return this.matchType
    }
    if (this.isLoggedIn && this.showMode === 'member') {
      ret = this.doMatch(inputLowerCase, this.thirdPartyNameLowerCase, MATCH_THIRDPARTY_NAME, MATCH_THIRDPARTY_NAME_PINYIN, checkPinYin,
        this.thirdPartyNamePinYin, this.thirdPartyNamePinYinGroup, this.thirdPartyNamePinYinInitialGroup, this.thirdPartyNamePinYinInitialPos)
      if (ret.type > 0) {
        this.matchType = ret.type
        this.matchStart = ret.start
        this.matchEnd = ret.end
        return this.matchType
      }

      ret = this.doMatch(inputLowerCase, this.thirdPartyAliasLowerCase, MATCH_THIRDPARTY_ALIAS, MATCH_THIRDPARTY_ALIAS_PINYIN, checkPinYin,
        this.thirdPartyAliasPinYin, this.thirdPartyAliasPinYinGroup, this.thirdPartyAliasPinYinInitialGroup, this.thirdPartyAliasPinYinInitialPos)
      if (ret.type > 0) {
        this.matchType = ret.type
        this.matchStart = ret.start
        this.matchEnd = ret.end
        return this.matchType
      }
      if (this.isAssistant) {
        ret = this.doMatch(inputLowerCase, this.helpName, MATCH_HELP_NAME, MATCH_HELP_NAME_PINYIN, checkPinYin,
          this.helpNamePinyin, this.helpNamePinyinGroup, this.helpNamePinYinInitialGroup, [])
        if (ret.type > 0) {
          this.matchType = ret.type
          this.matchStart = ret.start
          this.matchEnd = ret.end
          return this.matchType
        }
      }
    }

    if ((!this.isLoggedIn && this.showMode === 'member') || this.showMode === 'tourist') {
      ret = this.doMatch(inputLowerCase, this.nameLowerCase, MATCH_NAME, MATCH_NAME_PINYIN, checkPinYin, this.namePinYin, this.namePinYinGroup, this.namePinYinInitialGroup, this.namePinYinInitialPos)
      if (ret.type > 0) {
        this.matchType = ret.type
        this.matchStart = ret.start
        this.matchEnd = ret.end
        return this.matchType
      }
    }
    return MATCH_NONE
  }

  private doMatch(input: string, content: string, type: number, pinYinType: number, checkPinYin: boolean, pinYin: string[],
    pinGroup: string, pinYinInitialGroup: string, pinYinInitialPos: number[]): MatchResult {
    let start = 0
    let end = 0

    start = content.indexOf(input)
    if (start >= 0) {
      return { type, start, end: start + input.length }
    }

    if (checkPinYin && pinYin.length > 0) {
      // check full spell
      start = pinGroup.indexOf(input)
      end = start + input.length
      let realStart = -1
      let realEnd = -1

      if (start >= 0) {
        let pos = 0
        for (let i = 0; i < pinYin.length; i++) {
          if (pos > start && realStart < 0) {
            // missed real start, should match pinyin anymore, start must match exact initial position
            break;
          }

          if (pos === start) {
            realStart = i
          }

          if (pos >= end && realStart >= 0) {
            realEnd = i
            return { type: pinYinType, start: realStart, end: realEnd }
          }

          pos += pinYin[i].length
        }

        if (realStart >= 0) {
          realEnd = pinYin.length
          return { type: pinYinType, start: realStart, end: realEnd }
        }
      }

      // check initial spell
      start = pinYinInitialGroup.indexOf(input)
      end = start + input.length

      if (start >= 0) {
        return { type: pinYinType, start: pinYinInitialPos[start], end: pinYinInitialPos[end] }
      }
    }

    return { type: MATCH_NONE, start: 0, end: 0 }
  }
}
