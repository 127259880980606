import { Button, Typography, Box } from "@material-ui/core";
import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useStyles } from "./styles"
interface ICustomAlert {
  title?: string | React.ReactDOM | React.ReactNode,
  content: string | React.ReactDOM | React.ReactNode,
  onCancel?: () => void,
  onConfirm?: () => void,
  onClose?: () => void,
  style?: string,
  buttonGroups?: React.ReactDOM | React.ReactNode,
  confirmText?: string,
  cancelText?: string,
  isOpen: boolean,
  isNeedPosition?: boolean,
  isShowCancelButton?: boolean;
  isAlert?: boolean

}
export const CustomAlert = (props: ICustomAlert) => {
  const [t] = useTranslation()
  const onClickCancel = () => {
    setShowAlert(false)
  }
  const className = useStyles()
  const {
    title,
    style = {},
    content,
    buttonGroups,
    isOpen,
    onCancel = onClickCancel,
    onConfirm = onClickCancel,
    confirmText = t('Okay'),
    cancelText = t('Cancel') ,
    isNeedPosition = true,
    isShowCancelButton = true,
    isAlert = false
  } = props;

  const [isShow, setShowAlert] = useState(false)

  useEffect(() => {
    setShowAlert(isOpen)
  }, [isOpen])

  if (!isShow) return null;

  return (
    <Fragment>
      <Box id="CustomAlert" className={`${style} ${isNeedPosition ? className.alertContainer : className.Container} ${isAlert ? className.alertPosition : ''}`}>
        {title ? <Typography variant="h3" style={{ marginBottom: '0.5rem' }}>{title}</Typography> : null}
        <div className={`${className.content} ${isAlert ? className.alertContent : ''} ${title ? className.hasTitleContent : ''}`}>{content}</div>
        { buttonGroups || <div className={className.buttonGroups}>
          {isShowCancelButton && <Button id="custom-alert-cancel" variant="contained" onClick={onCancel} className={className.button}>{cancelText}</Button>}
          <Button id="custom-alert-confirm" variant="contained" onClick={onConfirm} color="primary" className={className.button} >{confirmText}</Button>
        </div>
        }
      </Box>
      {isAlert && <div className={className.mask} />}
    </Fragment>
  )
}
