import { makeStyles, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { NotificationInfo } from "../types/notification";

export interface NotificationProps {
  info: NotificationInfo;
  onExited: () => void;
  className?: string;
  style?: React.CSSProperties;
}

const useStyles = makeStyles({
  filledInfo: {
    background: 'rgba(88,97,102,1)',
    boxShadow: '0rem 0.1rem 0.2rem 0rem rgba(31,56,211,0.08)',
    borderRadius: '5rem',
    opacity: '0.3'
  },
  message: {
    fontSize: '0.6rem',
    padding: 0,
  },
  icon: {
    display: 'none',
  },
  anchorOriginTopCenter: {
    top: '3.6rem'
  },
  action: {
    display: 'none',
  }
})

const Notification = observer((props: NotificationProps) => {
  const [t] = useTranslation()
  const [show, setShow] = useState(true)
  const styles = useStyles()

  const getContent = () => {
    const info = props.info
    if (info.params && info.params.size > 0) {
      return t(info.content, Object.fromEntries(info.params))
    } else {
      return t(info.content)
    }
  }

  return (
    <Snackbar
      className={props.className}
      classes={{ anchorOriginTopCenter: styles.anchorOriginTopCenter }}
      style={props.style}
      open={show}
      key={props.info.content}
      autoHideDuration={props.info.level === "error" ? 3000 : 2000}
      onClose={(e, reason) => reason !== "clickaway" && setShow(false)}
      onExited={() => !show && props.onExited()}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        variant="filled"
        onClose={props.info.level === "error" ? () => setShow(false) : undefined}
        classes={{ filledInfo: styles.filledInfo, message: styles.message, icon: styles.icon, action: styles.action }}
        severity={props.info.level}>
        {getContent()}
      </Alert>
    </Snackbar>
  );
});

export default Notification