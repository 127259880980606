/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.CustomizedData = (function() {
    
        /**
         * Properties of a CustomizedData.
         * @exports ICustomizedData
         * @interface ICustomizedData
         * @property {number|null} [eventType] CustomizedData eventType
         * @property {string|null} [sid] CustomizedData sid
         * @property {string|null} [avcUid] CustomizedData avcUid
         * @property {number|null} [netType] CustomizedData netType
         * @property {boolean|null} [success] CustomizedData success
         * @property {number|null} [elapsed] CustomizedData elapsed
         * @property {string|null} [requestType] CustomizedData requestType
         * @property {number|null} [deviceType] CustomizedData deviceType
         * @property {number|null} [osType] CustomizedData osType
         * @property {string|null} [osVersion] CustomizedData osVersion
         * @property {string|null} [appVersion] CustomizedData appVersion
         * @property {number|Long|null} [lts] CustomizedData lts
         * @property {number|null} [errorCode] CustomizedData errorCode
         * @property {string|null} [reservedValue] CustomizedData reservedValue
         * @property {ICommonIndex|null} [index] CustomizedData index
         * @property {number|null} [elapse] CustomizedData elapse
         * @property {number|null} [ec] CustomizedData ec
         */
    
        /**
         * Constructs a new CustomizedData.
         * @exports CustomizedData
         * @classdesc Represents a CustomizedData.
         * @implements ICustomizedData
         * @constructor
         * @param {ICustomizedData=} [properties] Properties to set
         */
        function CustomizedData(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * CustomizedData eventType.
         * @member {number} eventType
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.eventType = 0;
    
        /**
         * CustomizedData sid.
         * @member {string} sid
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.sid = "";
    
        /**
         * CustomizedData avcUid.
         * @member {string} avcUid
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.avcUid = "";
    
        /**
         * CustomizedData netType.
         * @member {number} netType
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.netType = 0;
    
        /**
         * CustomizedData success.
         * @member {boolean} success
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.success = false;
    
        /**
         * CustomizedData elapsed.
         * @member {number} elapsed
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.elapsed = 0;
    
        /**
         * CustomizedData requestType.
         * @member {string} requestType
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.requestType = "";
    
        /**
         * CustomizedData deviceType.
         * @member {number} deviceType
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.deviceType = 0;
    
        /**
         * CustomizedData osType.
         * @member {number} osType
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.osType = 0;
    
        /**
         * CustomizedData osVersion.
         * @member {string} osVersion
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.osVersion = "";
    
        /**
         * CustomizedData appVersion.
         * @member {string} appVersion
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.appVersion = "";
    
        /**
         * CustomizedData lts.
         * @member {number|Long} lts
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.lts = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
        /**
         * CustomizedData errorCode.
         * @member {number} errorCode
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.errorCode = 0;
    
        /**
         * CustomizedData reservedValue.
         * @member {string} reservedValue
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.reservedValue = "";
    
        /**
         * CustomizedData index.
         * @member {ICommonIndex|null|undefined} index
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.index = null;
    
        /**
         * CustomizedData elapse.
         * @member {number} elapse
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.elapse = 0;
    
        /**
         * CustomizedData ec.
         * @member {number} ec
         * @memberof CustomizedData
         * @instance
         */
        CustomizedData.prototype.ec = 0;
    
        /**
         * Creates a new CustomizedData instance using the specified properties.
         * @function create
         * @memberof CustomizedData
         * @static
         * @param {ICustomizedData=} [properties] Properties to set
         * @returns {CustomizedData} CustomizedData instance
         */
        CustomizedData.create = function create(properties) {
            return new CustomizedData(properties);
        };
    
        /**
         * Encodes the specified CustomizedData message. Does not implicitly {@link CustomizedData.verify|verify} messages.
         * @function encode
         * @memberof CustomizedData
         * @static
         * @param {ICustomizedData} message CustomizedData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomizedData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eventType != null && Object.hasOwnProperty.call(message, "eventType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.eventType);
            if (message.sid != null && Object.hasOwnProperty.call(message, "sid"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.sid);
            if (message.avcUid != null && Object.hasOwnProperty.call(message, "avcUid"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.avcUid);
            if (message.netType != null && Object.hasOwnProperty.call(message, "netType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.netType);
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.success);
            if (message.elapsed != null && Object.hasOwnProperty.call(message, "elapsed"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.elapsed);
            if (message.requestType != null && Object.hasOwnProperty.call(message, "requestType"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.requestType);
            if (message.deviceType != null && Object.hasOwnProperty.call(message, "deviceType"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.deviceType);
            if (message.osType != null && Object.hasOwnProperty.call(message, "osType"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.osType);
            if (message.osVersion != null && Object.hasOwnProperty.call(message, "osVersion"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.osVersion);
            if (message.appVersion != null && Object.hasOwnProperty.call(message, "appVersion"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.appVersion);
            if (message.lts != null && Object.hasOwnProperty.call(message, "lts"))
                writer.uint32(/* id 12, wireType 0 =*/96).int64(message.lts);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.errorCode);
            if (message.reservedValue != null && Object.hasOwnProperty.call(message, "reservedValue"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.reservedValue);
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                $root.CommonIndex.encode(message.index, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.elapse != null && Object.hasOwnProperty.call(message, "elapse"))
                writer.uint32(/* id 16, wireType 0 =*/128).int32(message.elapse);
            if (message.ec != null && Object.hasOwnProperty.call(message, "ec"))
                writer.uint32(/* id 17, wireType 0 =*/136).int32(message.ec);
            return writer;
        };
    
        /**
         * Encodes the specified CustomizedData message, length delimited. Does not implicitly {@link CustomizedData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CustomizedData
         * @static
         * @param {ICustomizedData} message CustomizedData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomizedData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a CustomizedData message from the specified reader or buffer.
         * @function decode
         * @memberof CustomizedData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CustomizedData} CustomizedData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomizedData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CustomizedData();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.eventType = reader.int32();
                    break;
                case 2:
                    message.sid = reader.string();
                    break;
                case 3:
                    message.avcUid = reader.string();
                    break;
                case 4:
                    message.netType = reader.int32();
                    break;
                case 5:
                    message.success = reader.bool();
                    break;
                case 6:
                    message.elapsed = reader.int32();
                    break;
                case 7:
                    message.requestType = reader.string();
                    break;
                case 8:
                    message.deviceType = reader.int32();
                    break;
                case 9:
                    message.osType = reader.int32();
                    break;
                case 10:
                    message.osVersion = reader.string();
                    break;
                case 11:
                    message.appVersion = reader.string();
                    break;
                case 12:
                    message.lts = reader.int64();
                    break;
                case 13:
                    message.errorCode = reader.int32();
                    break;
                case 14:
                    message.reservedValue = reader.string();
                    break;
                case 15:
                    message.index = $root.CommonIndex.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.elapse = reader.int32();
                    break;
                case 17:
                    message.ec = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a CustomizedData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CustomizedData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CustomizedData} CustomizedData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomizedData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a CustomizedData message.
         * @function verify
         * @memberof CustomizedData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CustomizedData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.eventType != null && message.hasOwnProperty("eventType"))
                if (!$util.isInteger(message.eventType))
                    return "eventType: integer expected";
            if (message.sid != null && message.hasOwnProperty("sid"))
                if (!$util.isString(message.sid))
                    return "sid: string expected";
            if (message.avcUid != null && message.hasOwnProperty("avcUid"))
                if (!$util.isString(message.avcUid))
                    return "avcUid: string expected";
            if (message.netType != null && message.hasOwnProperty("netType"))
                if (!$util.isInteger(message.netType))
                    return "netType: integer expected";
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            if (message.elapsed != null && message.hasOwnProperty("elapsed"))
                if (!$util.isInteger(message.elapsed))
                    return "elapsed: integer expected";
            if (message.requestType != null && message.hasOwnProperty("requestType"))
                if (!$util.isString(message.requestType))
                    return "requestType: string expected";
            if (message.deviceType != null && message.hasOwnProperty("deviceType"))
                if (!$util.isInteger(message.deviceType))
                    return "deviceType: integer expected";
            if (message.osType != null && message.hasOwnProperty("osType"))
                if (!$util.isInteger(message.osType))
                    return "osType: integer expected";
            if (message.osVersion != null && message.hasOwnProperty("osVersion"))
                if (!$util.isString(message.osVersion))
                    return "osVersion: string expected";
            if (message.appVersion != null && message.hasOwnProperty("appVersion"))
                if (!$util.isString(message.appVersion))
                    return "appVersion: string expected";
            if (message.lts != null && message.hasOwnProperty("lts"))
                if (!$util.isInteger(message.lts) && !(message.lts && $util.isInteger(message.lts.low) && $util.isInteger(message.lts.high)))
                    return "lts: integer|Long expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isInteger(message.errorCode))
                    return "errorCode: integer expected";
            if (message.reservedValue != null && message.hasOwnProperty("reservedValue"))
                if (!$util.isString(message.reservedValue))
                    return "reservedValue: string expected";
            if (message.index != null && message.hasOwnProperty("index")) {
                var error = $root.CommonIndex.verify(message.index);
                if (error)
                    return "index." + error;
            }
            if (message.elapse != null && message.hasOwnProperty("elapse"))
                if (!$util.isInteger(message.elapse))
                    return "elapse: integer expected";
            if (message.ec != null && message.hasOwnProperty("ec"))
                if (!$util.isInteger(message.ec))
                    return "ec: integer expected";
            return null;
        };
    
        /**
         * Creates a CustomizedData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CustomizedData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CustomizedData} CustomizedData
         */
        CustomizedData.fromObject = function fromObject(object) {
            if (object instanceof $root.CustomizedData)
                return object;
            var message = new $root.CustomizedData();
            if (object.eventType != null)
                message.eventType = object.eventType | 0;
            if (object.sid != null)
                message.sid = String(object.sid);
            if (object.avcUid != null)
                message.avcUid = String(object.avcUid);
            if (object.netType != null)
                message.netType = object.netType | 0;
            if (object.success != null)
                message.success = Boolean(object.success);
            if (object.elapsed != null)
                message.elapsed = object.elapsed | 0;
            if (object.requestType != null)
                message.requestType = String(object.requestType);
            if (object.deviceType != null)
                message.deviceType = object.deviceType | 0;
            if (object.osType != null)
                message.osType = object.osType | 0;
            if (object.osVersion != null)
                message.osVersion = String(object.osVersion);
            if (object.appVersion != null)
                message.appVersion = String(object.appVersion);
            if (object.lts != null)
                if ($util.Long)
                    (message.lts = $util.Long.fromValue(object.lts)).unsigned = false;
                else if (typeof object.lts === "string")
                    message.lts = parseInt(object.lts, 10);
                else if (typeof object.lts === "number")
                    message.lts = object.lts;
                else if (typeof object.lts === "object")
                    message.lts = new $util.LongBits(object.lts.low >>> 0, object.lts.high >>> 0).toNumber();
            if (object.errorCode != null)
                message.errorCode = object.errorCode | 0;
            if (object.reservedValue != null)
                message.reservedValue = String(object.reservedValue);
            if (object.index != null) {
                if (typeof object.index !== "object")
                    throw TypeError(".CustomizedData.index: object expected");
                message.index = $root.CommonIndex.fromObject(object.index);
            }
            if (object.elapse != null)
                message.elapse = object.elapse | 0;
            if (object.ec != null)
                message.ec = object.ec | 0;
            return message;
        };
    
        /**
         * Creates a plain object from a CustomizedData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CustomizedData
         * @static
         * @param {CustomizedData} message CustomizedData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CustomizedData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.eventType = 0;
                object.sid = "";
                object.avcUid = "";
                object.netType = 0;
                object.success = false;
                object.elapsed = 0;
                object.requestType = "";
                object.deviceType = 0;
                object.osType = 0;
                object.osVersion = "";
                object.appVersion = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.lts = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lts = options.longs === String ? "0" : 0;
                object.errorCode = 0;
                object.reservedValue = "";
                object.index = null;
                object.elapse = 0;
                object.ec = 0;
            }
            if (message.eventType != null && message.hasOwnProperty("eventType"))
                object.eventType = message.eventType;
            if (message.sid != null && message.hasOwnProperty("sid"))
                object.sid = message.sid;
            if (message.avcUid != null && message.hasOwnProperty("avcUid"))
                object.avcUid = message.avcUid;
            if (message.netType != null && message.hasOwnProperty("netType"))
                object.netType = message.netType;
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            if (message.elapsed != null && message.hasOwnProperty("elapsed"))
                object.elapsed = message.elapsed;
            if (message.requestType != null && message.hasOwnProperty("requestType"))
                object.requestType = message.requestType;
            if (message.deviceType != null && message.hasOwnProperty("deviceType"))
                object.deviceType = message.deviceType;
            if (message.osType != null && message.hasOwnProperty("osType"))
                object.osType = message.osType;
            if (message.osVersion != null && message.hasOwnProperty("osVersion"))
                object.osVersion = message.osVersion;
            if (message.appVersion != null && message.hasOwnProperty("appVersion"))
                object.appVersion = message.appVersion;
            if (message.lts != null && message.hasOwnProperty("lts"))
                if (typeof message.lts === "number")
                    object.lts = options.longs === String ? String(message.lts) : message.lts;
                else
                    object.lts = options.longs === String ? $util.Long.prototype.toString.call(message.lts) : options.longs === Number ? new $util.LongBits(message.lts.low >>> 0, message.lts.high >>> 0).toNumber() : message.lts;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            if (message.reservedValue != null && message.hasOwnProperty("reservedValue"))
                object.reservedValue = message.reservedValue;
            if (message.index != null && message.hasOwnProperty("index"))
                object.index = $root.CommonIndex.toObject(message.index, options);
            if (message.elapse != null && message.hasOwnProperty("elapse"))
                object.elapse = message.elapse;
            if (message.ec != null && message.hasOwnProperty("ec"))
                object.ec = message.ec;
            return object;
        };
    
        /**
         * Converts this CustomizedData to JSON.
         * @function toJSON
         * @memberof CustomizedData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CustomizedData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return CustomizedData;
    })();
    
    $root.CommonIndex = (function() {
    
        /**
         * Properties of a CommonIndex.
         * @exports ICommonIndex
         * @interface ICommonIndex
         * @property {string|null} [index1] CommonIndex index1
         * @property {string|null} [index2] CommonIndex index2
         * @property {string|null} [index3] CommonIndex index3
         */
    
        /**
         * Constructs a new CommonIndex.
         * @exports CommonIndex
         * @classdesc Represents a CommonIndex.
         * @implements ICommonIndex
         * @constructor
         * @param {ICommonIndex=} [properties] Properties to set
         */
        function CommonIndex(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * CommonIndex index1.
         * @member {string} index1
         * @memberof CommonIndex
         * @instance
         */
        CommonIndex.prototype.index1 = "";
    
        /**
         * CommonIndex index2.
         * @member {string} index2
         * @memberof CommonIndex
         * @instance
         */
        CommonIndex.prototype.index2 = "";
    
        /**
         * CommonIndex index3.
         * @member {string} index3
         * @memberof CommonIndex
         * @instance
         */
        CommonIndex.prototype.index3 = "";
    
        /**
         * Creates a new CommonIndex instance using the specified properties.
         * @function create
         * @memberof CommonIndex
         * @static
         * @param {ICommonIndex=} [properties] Properties to set
         * @returns {CommonIndex} CommonIndex instance
         */
        CommonIndex.create = function create(properties) {
            return new CommonIndex(properties);
        };
    
        /**
         * Encodes the specified CommonIndex message. Does not implicitly {@link CommonIndex.verify|verify} messages.
         * @function encode
         * @memberof CommonIndex
         * @static
         * @param {ICommonIndex} message CommonIndex message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommonIndex.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.index1 != null && Object.hasOwnProperty.call(message, "index1"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.index1);
            if (message.index2 != null && Object.hasOwnProperty.call(message, "index2"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.index2);
            if (message.index3 != null && Object.hasOwnProperty.call(message, "index3"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.index3);
            return writer;
        };
    
        /**
         * Encodes the specified CommonIndex message, length delimited. Does not implicitly {@link CommonIndex.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CommonIndex
         * @static
         * @param {ICommonIndex} message CommonIndex message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommonIndex.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a CommonIndex message from the specified reader or buffer.
         * @function decode
         * @memberof CommonIndex
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CommonIndex} CommonIndex
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommonIndex.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CommonIndex();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.index1 = reader.string();
                    break;
                case 2:
                    message.index2 = reader.string();
                    break;
                case 3:
                    message.index3 = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a CommonIndex message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CommonIndex
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CommonIndex} CommonIndex
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommonIndex.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a CommonIndex message.
         * @function verify
         * @memberof CommonIndex
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CommonIndex.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.index1 != null && message.hasOwnProperty("index1"))
                if (!$util.isString(message.index1))
                    return "index1: string expected";
            if (message.index2 != null && message.hasOwnProperty("index2"))
                if (!$util.isString(message.index2))
                    return "index2: string expected";
            if (message.index3 != null && message.hasOwnProperty("index3"))
                if (!$util.isString(message.index3))
                    return "index3: string expected";
            return null;
        };
    
        /**
         * Creates a CommonIndex message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CommonIndex
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CommonIndex} CommonIndex
         */
        CommonIndex.fromObject = function fromObject(object) {
            if (object instanceof $root.CommonIndex)
                return object;
            var message = new $root.CommonIndex();
            if (object.index1 != null)
                message.index1 = String(object.index1);
            if (object.index2 != null)
                message.index2 = String(object.index2);
            if (object.index3 != null)
                message.index3 = String(object.index3);
            return message;
        };
    
        /**
         * Creates a plain object from a CommonIndex message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CommonIndex
         * @static
         * @param {CommonIndex} message CommonIndex
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommonIndex.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.index1 = "";
                object.index2 = "";
                object.index3 = "";
            }
            if (message.index1 != null && message.hasOwnProperty("index1"))
                object.index1 = message.index1;
            if (message.index2 != null && message.hasOwnProperty("index2"))
                object.index2 = message.index2;
            if (message.index3 != null && message.hasOwnProperty("index3"))
                object.index3 = message.index3;
            return object;
        };
    
        /**
         * Converts this CommonIndex to JSON.
         * @function toJSON
         * @memberof CommonIndex
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommonIndex.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return CommonIndex;
    })();

    return $root;
});
