import { makeStyles, createStyles, Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) => {
  const {
    customColor: {
      componentsBackGround,
      tabActiveColor,
      tabBackground,
      borderColor,
      downloadTextColor,
      participantsButtonHover,
      participantsIconColor,
      controlAudioActiveBackground,
      controlAudioActiveShade,
    },
  } = theme;
  const backgroundColor = componentsBackGround;
  return createStyles({
    participantsDrawer: {
      width: '15.5rem',
      display: 'flex',
      overflow: 'hidden',
    },
    participantsDrawerPaper: {
      backgroundColor,
      width: '15.5rem',
      boxSizing: 'border-box',
      borderRadius: '0.75rem',
      top: '1.25rem',
      left: 'auto',
      height: `calc(100vh - 2.5rem)`,
      border: 'none',
      marginRight: '1.25rem',
      zIndex: 100
    },
    halfScreen: {
      backgroundColor,
      width: '15.5rem',
      boxSizing: 'border-box',
      borderRadius: '0.75rem',
      top: '1.25rem',
      bottom: '0',
      left: 'auto',
      height: `calc(50vh - 1.25rem)`,
      border: 'none',
      marginRight: '1.25rem',
      zIndex: 100

    },

    container: {
      width: '100%',
      overflow: 'hidden',
      height: '100%',

    },
    header: {
      display: 'flex',
      padding: '1.13rem 1rem',
      borderBottom: `1px solid ${borderColor}`,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    close: {
      width: '1.5rem',
      height: '1.5rem',
      borderRadius: '0.23rem',
      backgroundColor:theme.customColor.operationButtonBackground
    },
    iconClose: {
      color: theme.customColor.operationButtonFill,
      fill:theme.customColor.operationButtonFill,
    },
    filterContainer: {
      display: 'flex',
      margin: '.5rem 1rem',
      alignItems: 'center',
      backgroundColor: (tabBackground as string),
      borderRadius: '0.625rem',
      padding: '0.25rem',
      height: '2.25rem',
      "& >div": {
        textAlign: 'center',
        width: '4.97rem',
        fontSize: '.63rem',
        cursor: 'pointer',
        borderRadius: '0.5rem',
        height: '2.1063rem',
        wordBreak: 'keep-all',
        display: 'flex',
        lineHeight: '1.06rem',
        flexDirection: 'column',
        fontWeight: 600,
        flex: 1,
        transform: 'scale(.83)',
        transformOrigin: '0 0',
        padding: '1px 0',
        marginTop: '0.3125rem',
        '&:nth-child(2)': {
          margin: '0.3125rem -0.5625rem 0'
        },
        '& >span': {
          width: '4.97rem',
        }
      }
    },
    title: {
      fontSize: '1.13rem'

    },
    active: {
      backgroundColor: (tabActiveColor as string),
      transition: 'all 300ms cubic-bezier(0.4, 0, 1, 1) 0ms',
      color: theme.palette.primary.main
    },
    participantsTab: {
      '&:hover': {
        color: theme.palette.primary.main
      }
    },
    search: {
      borderTop: `1px solid ${borderColor}`,
      display: 'flex',
      height: '3.25rem',
      padding: '.5rem 1rem',
    },
    searchBox:{
      display: 'flex',
      height: '3.25rem',
      padding: '.5rem 1rem',
      borderBottom:`1px solid ${borderColor}`,
    },
    searchUser: {
      width:'100%',
      backgroundColor: (tabBackground as string),
      borderRadius: '0.625rem',
      paddingLeft: '1rem',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      height: '2.25rem',
      justifyContent: 'space-between',
      borderStyle:'solid',
      borderWidth:'2px',
      borderColor:'transparent'
    },
    IconSearch:{
      // width:'0.8125rem',
      // height:'0.8125rem'
    },
    searchUserName: {
      border: 'none',
      marginLeft: '0.5rem',
      fontSize: '.75rem',
      height: '100%',
      flex: 1,
      padding:'0',
      display: 'flex',
      alignItems: 'center',
      fontsize:'0.75rem',
      '& input':{
        padding:'0',
        height:'1rem',
        '&::placeholder':{
          color:downloadTextColor,
        }
      }
    },
    userList: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      borderTop: `1px solid ${borderColor}`,
      // overflowY: 'scroll',
      overflow: 'hidden',
      position: 'relative',
      height: `calc(100% - 8rem)`,
      margin: '0rem 1rem',
    },
    userListContainer: {
      top:'1rem',
      '&::-webkit-scrollbar': {
        width: '0.3125rem',
        backgroundColor: backgroundColor,
      },
      '&::-webkit-scrollbar-track': {
        width: '5px',
        backgroundColor: backgroundColor,
        margin: '0'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '16px',
        backgroundColor: '#CDD2DCFF',
      }
    },
    listContainer: {
      width:'12.5rem',
      margin:'0rem 1rem 0rem 0.75rem',
      height:'calc(100% - 1rem)'
    },
    userItem: {
      // marginBottom: '0.88rem'
      height: '3.125rem',
      left: '2.5px !important',
    },
    userInfo: {
      display: 'flex',
      alignItems: 'center',
    },
    userName: {
      fontSize: '0.75rem',
      width: '5.75rem',
      flex: 1,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontWeight: 600
    },
    userState: {
      fontSize: '0.75rem',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      transform: 'scale(.66)',
      transformOrigin: '0 0',
      marginTop: '-0.125rem',
      minHeight: '0.875rem',
      '& span': {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '0.29rem',
        whiteSpace: 'nowrap',
        wordBreak: 'keep-all',
        marginRight: '0.625rem',
        padding: '0.125rem 0.1875rem'
      }
    },

    cloudRecording: {
      color: '#FF5D6C',
      borderColor: '#FF5D6C',

    },
    dump: {
      color: '#00D58B',
      borderColor: '#00D58B'
    },
    noLogin: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    screenSharing: {

      color: '#00BFDA',
      borderColor: '#00BFDA'
    },
    delete: {
      height: '1.5rem',
      width:'1.5rem',
      cursor: 'pointer',
      marginRight: '.5rem',
      padding: 0,
      borderRadius:'50%'
    },
    deleteIcon: {

    },
    icons: {
      width: '2.125rem',
      height: '2.125rem',
      textAlign: 'center',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position:'relative',
      marginRight: '2.5px',
      '&:hover':{
        backgroundColor: participantsButtonHover,
        borderRadius:'50%'
      }
    },
    iconHost: {
      width: '2.125rem',
      height: '2.125rem',
      textAlign: 'center',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '2.5px',
    },
    iconButton: {
      fill: participantsIconColor,
    },
    notFound: {
      fontWeight: 600,
      fontSize: '0.75rem',
      textAlign: 'center',
      marginTop:'1rem'
    },
    highlight: {
      color: theme.palette.primary.main
    },
    micBox: {
      position: 'absolute',
      width: '0.3506rem',
      height: '0.5162rem',
      backgroundColor: controlAudioActiveBackground,
      borderRadius: '20px',
      overflow: 'hidden',
      top:'0.6556rem',
      left:'0.9rem'
    },
    micShade: {
      bottom: 0,
      width: '100%',
      height: '50%',
      backgroundColor: controlAudioActiveShade,
      overflow: 'hidden',
      position: 'absolute',
      transition: 'height ease-in-out 1ms',
      zIndex: 1000,
    },
    assistantLeaveBox: {
      width: '11.9rem',
    },
    assistantBox: {
      flex: 1,
      width:'5.75rem'
    },
    assistantNameButton: {
      width: '4.4rem',
      height: '1.4375rem',
      borderRadius: '0.5rem',
    },
    assistantName: {
      color: theme.palette.primary.main,
    },
    assistantButtonGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    assistantLeaveText: {
      display: 'flex',
      alignItems: 'center',
    },
  })
})
