import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { useTranslation } from 'react-i18next'
import { useCommManagerStore } from "../../stores/hooks";
import styles from "./style.module.css";
import { isChinese } from "../../utils/helper"

const AudioRecording = observer(() => {
  const [t] = useTranslation()
  const commManager = useCommManagerStore()

  return (
    <Fragment>
      {
        commManager.isRoomDumpIssue && commManager.roomReady ?
          <div className={styles.recordingContainer}>
            <div className={`${styles.recording} ${commManager.isRoomCloudRecording ? styles.needMargin : null} ${isChinese() ? styles.recordingCn : styles.recordingEn}`}>
              <div className={styles.recordingContent}>
                <div className={styles.circle} />
                <span style={{ marginLeft: '0.5625rem' }} className={`${styles.recordingText}`}>{`${t('RoomDumpIssue')}`}</span>
              </div>
            </div>
          </div> : null
      }
    </Fragment>
  )
})

export default AudioRecording
