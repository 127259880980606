import { Streetview } from "@material-ui/icons";
import { IRemoteAudioTrack, IRemoteVideoTrack } from "agora-rtc-sdk-ng";
import { action, observable, runInAction, computed } from "mobx";
import { MediaUser } from "../../types/mediaUser";
import { Biz, Control } from "../../types/metadata";
import { RoomInfo } from "../../types/roomInfo";
import { QualityState, ASSISTANT_STATE, RTC_QUALITY_TYPE, UserChangedReason, USER_STATUS_FLAG, VOLUME, ENCRYPTION_MODE, SDK_ENCRYPTION_MODE } from "../../utils/constants";
import { log, logException, LOG_MODULE, LOG_TYPE } from "../../utils/Log";
import { CommManagerStore } from "../commManager";
import { getRtcToken } from "../connector/http";
import { NotificationStore } from "../notification";
import ShareManagerStore from "../shareManager";
import { LOCAL_AUDIO_STATE, LOCAL_VIDEO_STATE, RTCConnectState, RtcEngineLayerStore } from "./rtcEngineLayer";
import RtcShareLayer from "./rtcShareLayer";

// tslint:disable-next-line: no-var-requires
const soundEffect = require('../../assets/join_success.mp3')

export const AUDIO_DEFAULT = false
export const VIDEO_DEFAULT = false

enum RTC_CHANNEL_STATE {
  DISCONNECTED = 0,
  CONNECTING_1 = 1,
  CONNECTING_2 = 2,
  CONNECTED = 3,
}

export class RtcCommLayerStore {

  public mediaUsers: Map<number, MediaUser> = new Map()
  public userMe!: MediaUser
  @observable
  private channelName: string = ''
  private selfStreamId!: number
  @observable
  private connState: RTC_CHANNEL_STATE = RTC_CHANNEL_STATE.DISCONNECTED
  private currentJoinId: number = 0

  @observable
  public mineAudioState: boolean = false
  @observable
  public mineVideoState: boolean = false

  private expectAudio = AUDIO_DEFAULT
  private expectVideo = VIDEO_DEFAULT
  private isAudioDetermined: boolean = false
  private isVideoDetermined: boolean = false

  @observable
  public mineNetworkQuality = QualityState.NETWORK_UNKNOWN

  private testVolume: number = 10
  private speakingUsers: number[] = []

  private rtcEngine: RtcEngineLayerStore
  private commManager: CommManagerStore
  private notification: NotificationStore
  private rtcShare: RtcShareLayer

  private joinTimer: number = 0

  private checkSelfMediaDeterminedTimer: number = 0;
  private openSelfMediaDeterminedChoice: boolean = false;
  @observable
  public showSelfMediaNeedDetermineTip: boolean = false
  private rtcSecretKey: string = ''
  private rtcEncryption: boolean = false
  private remotesVolumeState: VOLUME = VOLUME.FULL_VOLUME
  private remotesVolumeStateExemptId: number = 0

  public constructor(rtcEngine: RtcEngineLayerStore, commManager: CommManagerStore, notification: NotificationStore) {
    this.rtcEngine = rtcEngine
    this.commManager = commManager
    this.notification = notification

    this.rtcShare = new RtcShareLayer(this, this.notification)
  }
  public previewMedia(streamId: number, expectAudio: boolean, expectVideo: boolean, name: string) {
    this.setupLocalMediaState(expectAudio, expectVideo)
    this.isAudioDetermined = false
    this.isVideoDetermined = false
  }

  @action
  public setChannelName(channelName: string) {
    this.channelName = channelName
  }

  public async joinChannel(
    joinId: number, channelName: string,
    rtcEncryption: boolean, rtcSecretKey: string,
    streamId: number, expectAudio: boolean,
    expectVideo: boolean, name: string,
    rtcToken: string, encryptionMode: ENCRYPTION_MODE, salt: Uint8Array|undefined
  ) {
    this.setChannelName(channelName)
    this.mediaUsers.clear()
    this.currentJoinId = joinId
    this.rtcSecretKey = rtcSecretKey
    this.rtcEncryption = rtcEncryption
    this.userMe = this.createUser(expectAudio, expectVideo, streamId, true)
    this.userMe.name = name
    this.mediaUsers.set(streamId, this.userMe)
    this.selfStreamId = streamId
    this.roomInfo = undefined
    this.speakingUsers = []

    this.setChannelState(RTC_CHANNEL_STATE.CONNECTING_1)

    let token = rtcToken || ''
    try {
      if (!token) {
        token = await this.getToken(channelName)
      }
    } catch (error) {
      logException(`get rtc token exception code: ${error.code}`, error, LOG_MODULE.RTC)

      if (this.currentJoinId === joinId) {
        this.onChannelJoinFailure('NetworkError')
      }
      return
    }

    if (this.currentJoinId !== joinId) {
      log(`get rtc token over, but join id not match, get: ${joinId}, current: ${this.currentJoinId}`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
      return
    }

    this.setChannelState(RTC_CHANNEL_STATE.CONNECTING_2)

    this.joinTimer = window.setTimeout(this.joinTimeout.bind(this), 20000)

    try {
      log(`joinChannel channelName:${channelName}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      await this.rtcEngine.joinChannel(channelName, rtcEncryption, rtcSecretKey, streamId, token, name, encryptionMode, salt)

      this.onChannelJoinSuccess()
    } catch (error) {
      logException(`rtc join channel exception `, error, LOG_MODULE.RTC)
      if (this.currentJoinId === joinId) {
        this.leaveChannel()// just in case, if joinId not match

        this.onChannelJoinFailure('JoinChannelFailure', error.code)
      } else {
        log(`rtc join channel expection, join id not match, get: ${joinId}, current: ${this.currentJoinId}`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
      }
    }
  }

  private joinTimeout() {
    log(`join channel timeout`, LOG_TYPE.ERROR, LOG_MODULE.RTC)

    this.leaveChannel()

    this.onChannelJoinFailure('JoinChannelTimeout')
  }

  public leaveChannel() {
    log(`leave channel`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    this.currentJoinId = 0

    this.setChannelState(RTC_CHANNEL_STATE.DISCONNECTED)

    this.clearJoinTimeout()

    this.rtcEngine.leaveChannel()

    this.resetMediaDisabledDefault()

    if (this.checkSelfMediaDeterminedTimer > 0) {
      window.clearTimeout(this.checkSelfMediaDeterminedTimer)
      this.checkSelfMediaDeterminedTimer = 0
    }
    this.openSelfMediaDeterminedChoice = false
    this.setShowSelfMediaNeedDetermineTip(false)
  }

  private clearJoinTimeout() {
    if (this.joinTimer > 0) {
      window.clearTimeout(this.joinTimer)
      this.joinTimer = 0
    }
  }

  private setupLocalMediaState(expectedAudio: boolean, expectedVideo: boolean) {
    log(`setup local media state, expect audio: ${expectedAudio}, expect video: ${expectedVideo}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    this.expectAudio = expectedAudio
    this.expectVideo = expectedVideo

    if (expectedVideo) {
      this.startPreview()
    }

    // https://jira.agoralab.co/browse/APP-1010
    // but add condition if because of permission, especially Safari: ask microphone permission everytime enter meeting
    if (expectedAudio) {
      this.rtcEngine.initLocalAudio()
    }

    if (expectedAudio || expectedVideo) {
      this.checkSelfMediaDeterminedTimer = window.setTimeout(() => {
        this.checkSelfMediaDeterminedTimer = 0

        this.openSelfMediaDeterminedChoice = true

        this.delayCheckSelfMediaDeterminedState()
      }, 5 * 1000)
    }
  }

  private allowOpenMic() {
    if (!this.rtcEngine.isMicrophoneListInited) {
      this.rtcEngine.refreshMicrophoneDevices()
    }

    if (this.rtcEngine.isMicrophoneListInited && this.rtcEngine.micDeviceList.length === 0) {
      return false
    }
    // if isDeviceListInited false, allow first, then wait mic devices init, and go through with checkDeviceValid
    return true
  }

  private allowOpenCamera() {
    // 当用户无外接设备时，用户第一次点击视频，报打开摄像头错误，第二次点击视频时，已知无摄像头了，此时报 无外界摄像头错误，所以这里尽早刷新；
    // 老版本 Safari 可能会多次权限提示；
    if (!this.rtcEngine.isCameraListInited) {
      this.rtcEngine.refreshCameraDevices()
    }

    if (this.rtcEngine.isCameraListInited && this.rtcEngine.cameraDeviceList.length === 0) {
      return false
    }
    // if isDeviceListInited false, allow first, then wait camera devices init, and go through with checkDeviceValid
    return true
  }

  public enableLocalAudio(state: boolean) {
    this.isAudioDetermined = true

    log(`user ${state ? 'enable' : 'disable'} local audio`, LOG_TYPE.COMM, LOG_MODULE.UI)

    this.expectAudio = state

    if (state && !this.allowOpenMic()) {
      log(`no mic device, not allow to enable`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
      this.expectAudio = false
      this.notification.addAlert(true, 'NoMicrophoneDevice')
      return
    }

    if (this.isChannelConnected) {
      // user expect audio true when join, when channel connected, user click audio to false, but rtc audio is already false. so just
      this.enableEngineLocalAudio(state)
    } else {
      if (state) {
        this.rtcEngine.initLocalAudio()
      } else {
        this.rtcEngine.disableLocalAudio()
      }
    }

    this.checkAndCancelSelfMediaNeedDetermineTip()
    this.userMe.audioState = state
    // this.commManager.setLocalMediaState(this.userMe, UserChangedReason.REASON_AUDIO)
  }

  public enableLocalVideo(state: boolean) {
    this.isVideoDetermined = true

    log(`user ${state ? 'enable' : 'disable'} local video`, LOG_TYPE.COMM, LOG_MODULE.UI)

    this.expectVideo = state

    if (state && !this.allowOpenCamera()) {
      log(`no camera device, not allow to enable`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
      this.expectVideo = false
      this.notification.addAlert(true, 'NoCameraDevice')
      return
    }

    if (this.isChannelConnected) {
      this.enableEngineLocalVideo(this.expectVideo)
    } else {
      if (this.expectVideo) {
        this.startPreview()
      } else {
        this.stopPreview()
      }
    }
    this.userMe.videoState = state
    this.checkAndCancelSelfMediaNeedDetermineTip()
    // this.commManager.setLocalMediaState(this.userMe, UserChangedReason.REASON_VIDEO)
  }

  private enableEngineLocalAudio(enable: boolean) {
    if (enable) {
      this.rtcEngine.enableLocalAudio()
    } else {
      this.rtcEngine.disableLocalAudio()
    }
  }

  private enableEngineLocalVideo(enable: boolean) {
    if (enable) {
      this.rtcEngine.enableLocalVideo()
    } else {
      this.rtcEngine.disableLocalVideo()
    }
  }

  public onChannelStateChanged(state: RTCConnectState, reason?: string) {
    if (state === RTCConnectState.DISCONNECTED && this.connState === RTC_CHANNEL_STATE.CONNECTED) {
      this.onChannelDisconnected()
    }
  }

  private onChannelJoinSuccess() {
    log(`rtc join channel success, channel name: ${this.channelName}, stream id: ${this.selfStreamId}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

    this.setChannelState(RTC_CHANNEL_STATE.CONNECTED)

    this.rtcEngine.playSoundEffect(soundEffect)

    this.clearJoinTimeout()

    // user has clicked audio button, user click has top priority,
    // ignore room info's audio state. must wait until channel joined, because can not publish stream before join success
    if (this.isAudioDetermined) {
      log(`onChannelJoinSuccess, determine audio to ${this.expectAudio}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      this.enableLocalAudio(this.expectAudio)
    }

    // user has clicked video button, user click has top priority, ignore room info's video state
    if (this.isVideoDetermined) {
      log(`onChannelJoinSuccess, determine video to ${this.expectVideo}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      this.enableLocalVideo(this.expectVideo)// change preview to enable
    }
    this.commManager.onStreamJoinSuccess()

    /*
        in Agora room（rely on RTM connection）,
        user/host setting 'Do not  not Allow participants to turn on the camera when entering room',
        The tool tip is not displayed , but HTML dom is not change.
        So temporarily use the timer to solve the problem
    */

    setTimeout(() => {
      this.adjustLocalMediaStates()
    }, 100)
  }

  private onChannelJoinFailure(msg: string, reason?: string) {
    log(`onChannelJoinFailure ${msg}`, LOG_TYPE.ERROR, LOG_MODULE.RTC)

    this.rtcEngine.clearChannelState()// reset A/V state by setupLocalMediaState

    this.setChannelState(RTC_CHANNEL_STATE.DISCONNECTED)

    this.clearJoinTimeout()

    this.commManager.onStreamJoinFailure(msg)
  }

  public onChannelDisconnected() {
    if (this.connState !== RTC_CHANNEL_STATE.DISCONNECTED) {
      this.leaveChannel()

      this.commManager.onStreamDisconnected()

      this.setChannelState(RTC_CHANNEL_STATE.DISCONNECTED)
    }
  }

  private delayCheckSelfMediaDeterminedState() {
    if (this.mediaUsers.size > 1) {
      setTimeout(() => {
        this.checkSelfMediaDeterminedState()
      }, 2000)// maybe will receive remote datachannel/metadata room info very soon, so skip 2s to avoid dialog.
    }
  }

  private checkSelfMediaDeterminedState() {
    if (!this.isInChannel() || !this.openSelfMediaDeterminedChoice || (this.isAudioDetermined && this.isVideoDetermined)) {
      return
    }

    if (this.mediaUsers.size === 1) {
      return
    }

    if ((!this.isAudioDetermined && this.mineAudioState) || (!this.isVideoDetermined && this.mineVideoState)) {
      log(`checkSelfMediaDeterminedState toast audio/video need to determine`)
      this.setShowSelfMediaNeedDetermineTip(true)
    }
    this.openSelfMediaDeterminedChoice = false
  }

  @action
  public setShowSelfMediaNeedDetermineTip(show: boolean) {
    if (this.showSelfMediaNeedDetermineTip !== show) {
      log(`setShowSelfMediaNeedDetermineTip ${show}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      this.showSelfMediaNeedDetermineTip = show
    }
  }

  public setSelfMediaDetermined() {
    log(`setSelfMediaDetermined current audio: ${this.mineAudioState} video: ${this.mineVideoState}`)

    if (this.mineAudioState) {
      this.enableLocalAudio(true)
    }

    if (this.mineVideoState) {
      this.enableLocalVideo(true)
    }
  }

  public setCancelSelfMediaDetermined() {
    log(`setCancelSelfMediaDetermined current audio: ${this.mineAudioState} video: ${this.mineVideoState}`)
    if (this.mineAudioState) {
      this.enableLocalAudio(false)
    }

    if (this.mineVideoState) {
      this.enableLocalVideo(false)
    }

  }

  public checkAndCancelSelfMediaNeedDetermineTip() {
    if (!this.showSelfMediaNeedDetermineTip || !this.isAudioDetermined || !this.isVideoDetermined) {
      return
    }

    log(`checkAndCancelSelfMediaNeedDetermineTip`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    this.setShowSelfMediaNeedDetermineTip(false)
  }

  @observable
  public mediaAutoMutedTip: boolean = false
  @observable
  public audioAuteMutedTip: boolean = false
  @observable
  public videoAutoMutedTip: boolean = false

  @action
  public resetMediaDisabledDefault() {
    this.mediaAutoMutedTip = false
    this.audioAuteMutedTip = false
    this.videoAutoMutedTip = false
  }

  private adjustLocalMediaStates() {
    log(`adjust local media is channel connected: ${this.isChannelConnected}, audio determined: ${this.isAudioDetermined},
    video determined: ${this.isVideoDetermined}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

    if (!this.isChannelConnected || (this.isAudioDetermined && this.isVideoDetermined)) return

    if (this.roomInfo?.roomAudio === undefined || this.roomInfo?.roomVideo === undefined) {
      log(`room info not valid, abort adjust`, LOG_TYPE.INFO, LOG_MODULE.RTC)
      return
    }

    let toastAudio = false
    let toastVideo = false

    if (!this.isAudioDetermined) {
      const roomAudio = this.roomInfo.roomAudio

      log(`adjust mine audio, expected audio: ${this.expectAudio} room audio: ${roomAudio}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

      if (roomAudio) {
        log(`adjust mine audio, align with expect: ${this.expectAudio}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

        this.enableLocalAudio(this.expectAudio)
      } else {
        if (this.expectAudio) {
          log(`adjust mine audio, align with room: false`, LOG_TYPE.INFO, LOG_MODULE.COMM)

          this.enableLocalAudio(false)

          toastAudio = true
        }
      }

      this.isAudioDetermined = true
    }

    if (!this.isVideoDetermined) {
      const roomVideo = this.roomInfo.roomVideo

      log(`adjust mine video, expected video: ${this.expectVideo} room vieo: ${roomVideo}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

      if (roomVideo) {
        log(`adjust mine video, align with expect: ${this.expectVideo}`, LOG_TYPE.INFO, LOG_MODULE.COMM)

        this.enableLocalVideo(this.expectVideo)// adjust preview to enable
      } else {
        if (this.expectVideo) {
          log(`adjust mine video, align with room: false`, LOG_TYPE.INFO, LOG_MODULE.COMM)

          this.enableLocalVideo(false)

          toastVideo = true
        }
      }

      this.isVideoDetermined = true
    }

    runInAction(() => {
      if (toastAudio && toastVideo) {
        this.mediaAutoMutedTip = true
      } else if (toastAudio) {
        this.audioAuteMutedTip = true
      } else if (toastVideo) {
        this.videoAutoMutedTip = true
      }
    })

    this.checkAndCancelSelfMediaNeedDetermineTip()
  }

  public onStreamUserJoin(streamId: number, audioState: boolean, videoState: boolean, audioTrack?: IRemoteAudioTrack, videoTrack?: IRemoteVideoTrack) {
    if (!this.isInChannel()) return

    log(`onStreamUserJoin stream id: ${streamId} audio: ${audioState} video: ${videoState}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    const user = this.findUser(streamId)
    if (user) {
      log(`user already join ${streamId}`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
      return
    }

    const newUser = this.createUser(AUDIO_DEFAULT, VIDEO_DEFAULT, streamId)
    newUser.updateAudioState(audioState, audioTrack, this.remotesVolumeState)
    newUser.updateVideoState(videoState, videoTrack)

    this.mediaUsers.set(streamId, newUser)

    this.commManager.onStreamUserJoin(newUser)

    this.delayCheckSelfMediaDeterminedState()
  }

  public onStreamUserLeave(streamId: number) {
    if (!this.isInChannel()) return

    log(`onStreamUserLeave stream id: ${streamId}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    const user = this.findUser(streamId)
    if (!user) {
      log(`find no user by ${streamId}`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
      return
    }

    this.removeUser(streamId)

    this.commManager.onStreamUserLeave(user)
  }

  @action
  public onStreamMineAudioState(state: LOCAL_AUDIO_STATE) {
    // outside channel, user may still observe stream audio
    const isEnabled = state === LOCAL_AUDIO_STATE.ENABLE
      || (!this.isAudioDetermined && this.expectAudio && state === LOCAL_AUDIO_STATE.DISABLE)
      || (!this.isChannelConnected && this.expectAudio && state === LOCAL_AUDIO_STATE.DISABLE)

    log(`on stream mine audio state: ${LOCAL_AUDIO_STATE[state]}, audio determined: ${this.isAudioDetermined}, expect audio: ${this.expectAudio}, channel state: ${RTC_CHANNEL_STATE[this.connState]}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    this.mineAudioState = isEnabled

    log(`mine audio state:  ${this.mineAudioState} isIn channel:${this.isInChannel()}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    if (this.isInChannel()) {
      this.onChannelStreamAudioState(this.selfStreamId, this.mineAudioState)
    }
  }

  @action
  public onStreamMineVideoState(state: LOCAL_VIDEO_STATE) {
    // outside channel, user may still observe mineVideoState
    const isEnabled = state >= LOCAL_VIDEO_STATE.PREVIEW

    log(`on stream mine video state: ${LOCAL_VIDEO_STATE[state]}, video determined: ${this.isVideoDetermined}, expect video: ${this.expectVideo}, channel state: ${RTC_CHANNEL_STATE[this.connState]}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    this.mineVideoState = isEnabled

    if (this.isInChannel()) {
      this.onChannelStreamVideoState(this.selfStreamId, this.mineVideoState)
    }
  }

  public onChannelStreamAudioState(streamId: number, enable: boolean, audioTrack?: IRemoteAudioTrack) {
    if (!this.isInChannel()) return

    log(`rtc user audio state changed, stream id: ${streamId}, state: ${enable}, ${audioTrack}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

    const user = this.findUser(streamId)
    if (!user) return

    // sdk reports too many states
    if (user.audioState !== enable || user.audioTrack !== audioTrack) {
      const volume = streamId === this.remotesVolumeStateExemptId ? VOLUME.FULL_VOLUME : this.remotesVolumeState
      user.updateAudioState(enable, audioTrack, volume)

      this.commManager.onStreamUserChanged(user, UserChangedReason.REASON_AUDIO)
    }
  }

  public onChannelStreamVideoState(streamId: number, enable: boolean, videoTrack?: IRemoteVideoTrack) {
    if (!this.isInChannel()) return

    log(`rtc user video state changed, stream id: ${streamId}, state: ${enable}, ${videoTrack}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

    const user = this.findUser(streamId)
    if (!user) return

    // sdk reports too many states
    if (user.videoState !== enable || user.videoTrack !== videoTrack) {
      user.updateVideoState(enable, videoTrack)

      this.commManager.onStreamUserChanged(user, UserChangedReason.REASON_VIDEO)
    }
  }

  public onVolumesIndicate(volumes: any) {
    const newSpeakingUsers: number[] = []

    volumes.forEach((item: any) => {
      if (item.level === 0) {
        return
      }

      const user = this.findUser(item.uid)
      if (!user) {
        return
      }

      const pos = this.speakingUsers.findIndex(streamId => streamId === user.streamId)
      if (pos >= 0) {
        this.speakingUsers.splice(pos, 1)
      }

      if (!user.isAudioRecentTurnOff()) {
        if (!user.audioState) {
          user.updateAudioState(true, undefined, VOLUME.MUTE)
          this.commManager.onStreamUserChanged(user, UserChangedReason.REASON_AUDIO)
        }

        // 1 is a test/feedback value
        if (item.level > 1) {
          if (!user.isSpeaking) {
            user.updateSpeakingState(true)
            this.commManager.onStreamUserChanged(user, UserChangedReason.REASON_SPEAKING)
          }

          newSpeakingUsers.push(user.streamId)
        } else {
          if (user.isSpeaking) {
            user.updateSpeakingState(false)
            this.commManager.onStreamUserChanged(user, UserChangedReason.REASON_SPEAKING)
          }
        }
      }
    });

    // speakingUsers no longer speaks
    this.speakingUsers.forEach((streamId: number) => {
      const user = this.findUser(streamId)
      if (!user) {
        return
      }

      if (user.isSpeaking) {
        user.updateSpeakingState(false)
        this.commManager.onStreamUserChanged(user, UserChangedReason.REASON_SPEAKING)
      }
    });

    this.speakingUsers = newSpeakingUsers
  }

  private usingShareTxQuality = false
  private currentShareTxQuality = RTC_QUALITY_TYPE.QUALITY_UNKNOWN

  public onEvalNetworkQuality(streamId: number, txQuality: RTC_QUALITY_TYPE, rxQuality: RTC_QUALITY_TYPE) {
    const user = streamId === 0 ? this.userMe : this.findUser(streamId)
    if (!user) {
      return
    }

    // 设置 AppBar 上的 本地信号质量
    if (user.isMe) {
      runInAction(() => this.mineNetworkQuality = this.getNetworkQuality(rxQuality))
    }

    // 设置 网络质量提示
    user.txQuality = this.usingShareTxQuality ? this.currentShareTxQuality : txQuality
    user.rxQuality = rxQuality

    this.commManager.onStreamUserChanged(user, UserChangedReason.REASON_QUALITY)

    this.commManager.onStreamNetworkEvaluation(user.streamId, user.txQuality, user.rxQuality)
  }

  public onEvalShareNetworkQuality(txQuality: RTC_QUALITY_TYPE) {
    this.currentShareTxQuality = txQuality
  }

  public getNetworkQuality(quality: RTC_QUALITY_TYPE): number {
    switch (quality) {
      case RTC_QUALITY_TYPE.QUALITY_EXCELLENT:
      case RTC_QUALITY_TYPE.QUALITY_GOOD:
        return QualityState.NETWORK_GOOD
      case RTC_QUALITY_TYPE.QUALITY_POOR:
        return QualityState.NETWORK_SOFT
      case RTC_QUALITY_TYPE.QUALITY_BAD:
      case RTC_QUALITY_TYPE.QUALITY_VBAD:
        return QualityState.NETWORK_POOR
      case RTC_QUALITY_TYPE.QUALITY_DOWN:
        return QualityState.NETWORK_DOWN
      default:
        return QualityState.NETWORK_UNKNOWN
    }
  }

  private findUser(streamId: number) {
    return this.mediaUsers.get(streamId)
  }

  @action
  private removeUser(streamId: number) {
    this.mediaUsers.delete(streamId)
  }

  public getMediaUserName(streamId: number) {
    const user = this.findUser(streamId)
    return user && user.name
  }

  public async setStreamVideoQuality(streamId: number, isHigh: boolean) {
    const user = this.findUser(streamId)
    if (!user || user.isMe) return

    if (user.isRemoteStreamHigh !== isHigh) {
      try {
        await this.rtcEngine.setRemoteStreamType(streamId, isHigh)
        user.isRemoteStreamHigh = isHigh
      } catch (error) {
        // do nothing
      }
    }
  }

  public startPreview() {
    this.expectVideo = true

    this.rtcEngine.startPreview()
  }

  public stopPreview() {
    this.expectVideo = false

    this.rtcEngine.stopPreview()
  }

  public startPlayVideo(streamId: number, isFit: boolean, canvas: HTMLElement) {
    if (streamId === 0 || streamId === this.selfStreamId) {
      this.rtcEngine.startPlayLocalVideo(canvas)
    } else {
      const track = this.findUser(streamId)?.videoTrack

      if (track) {
        track.play(canvas, { fit: isFit ? "cover" : "contain" })
      } else {
        log(`start play video, track undefined stream id: ${streamId}`, LOG_TYPE.ERROR, LOG_MODULE.RTC)
      }
    }
  }

  public stopPlayVideo(streamId: number) {
    if (streamId === 0 || streamId === this.selfStreamId) {
      this.rtcEngine.stopPlayLocalVideo()
    } else {
      const track = this.findUser(streamId)?.videoTrack

      if (track) {
        track.stop()
      }
    }
  }

  public changeEncryption(joinId: number, channelName: string, rtcEncryption: boolean, rtcSecretKey:string, streamId: number, name: string, rtcToken:string,encryptionMode:ENCRYPTION_MODE,salt:Uint8Array|undefined) {
    log(`changeEncryption rtcEncryption: ${rtcEncryption} channelName :${channelName}`, LOG_TYPE.ERROR, LOG_MODULE.RTC)

    const isAudioDetermined = this.isAudioDetermined
    const isVideoDetermined = this.isVideoDetermined
    const mineAudioState = this.mineAudioState
    const mineVideoState = this.mineVideoState

    this.leaveChannel()

    window.setTimeout(() => {
      this.joinChannel(joinId, channelName, rtcEncryption, rtcSecretKey, streamId, mineAudioState, mineVideoState, name, rtcToken, encryptionMode, salt)

      if (isAudioDetermined) {
        this.enableLocalAudio(mineAudioState)
      }
      if (isVideoDetermined) {
        this.enableLocalVideo(mineVideoState)
      }
    })
  }
  private get isChannelConnected() {
    log(`rtc real channel state: ${this.connState === RTC_CHANNEL_STATE.CONNECTED}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
    return this.connState === RTC_CHANNEL_STATE.CONNECTED
  }

  private isInChannel() {
    return this.connState !== RTC_CHANNEL_STATE.DISCONNECTED
  }

  @action
  private setChannelState(state: RTC_CHANNEL_STATE) {
    log(`rtc real channel state: ${RTC_CHANNEL_STATE[state]}`, LOG_TYPE.INFO, LOG_MODULE.RTC)
    this.connState = state
  }

  /************************************** Metadata ****************************************/
  private roomInfo?: Biz
  private recvRoomInfo: boolean = true

  public setRoomInfo(roomInfo: RoomInfo) {
    this.roomInfo = new Biz()
    this.roomInfo.setMediaRoomInfo(roomInfo)
    this.adjustLocalMediaStates()

    this.rtcEngine.setMetadataRoomInfo(this.roomInfo)
  }

  public closeRoomInfoRecv() {
    log(`close room info recv`, LOG_TYPE.INFO, LOG_MODULE.RTC)
    this.recvRoomInfo = false
  }

  public openRoomInfoRecv() {
    log(`open room info recv`, LOG_TYPE.INFO, LOG_MODULE.RTC)
    this.recvRoomInfo = true
  }

  public enableRemoteVideo(streamId: number, seq: number) {
    log('rtc unmute remote video stream', LOG_TYPE.COMM, LOG_MODULE.RTC)
    return this.setMediaControl(false, true, streamId, seq)
  }

  public enableRemoteAudio(streamId: number, seq: number) {
    log('rtc unmute remote audio stream', LOG_TYPE.COMM, LOG_MODULE.RTC)
    return this.setMediaControl(true, true, streamId, seq)
  }

  public disableRemoteVideo(streamId: number, seq: number) {
    log('rtc mute remote video stream', LOG_TYPE.COMM, LOG_MODULE.RTC)
    return this.setMediaControl(false, false, streamId, seq)
  }

  public disableRemoteAudio(streamId: number, seq: number) {
    log('rtc mute remote audio stream', LOG_TYPE.COMM, LOG_MODULE.RTC)
    return this.setMediaControl(true, false, streamId, seq)
  }

  public kickUser(streamId: number, seq: number) {
    if (!this.isMetadataOn() && !this.isDataStreamOn()) return false

    log(`kick user, target stream id: ${streamId}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    const control = new Control()
    control.kickUser(streamId, seq)

    this.rtcEngine.setMediaControl(control)
    return true
  }

  private setMediaControl(isAudio: boolean, enable: boolean, streamId: number, seq: number) {
    if (!this.isMetadataOn() && !this.isDataStreamOn()) return false

    log(`${enable ? 'enable' : 'disable'} remote ${isAudio ? 'audio' : 'video'}, target stream id: ${streamId}`, LOG_TYPE.INFO, LOG_MODULE.RTC)

    const control = new Control()
    control.requestMedia(isAudio, enable, streamId, seq)

    this.rtcEngine.setMediaControl(control)
    return true
  }

  public onStreamMetadata(streamId: number, name: string, parentStreamId: number, roomInfo?: Biz, controlInfo?: Control, status:number = 0) {
    if (!this.isInChannel()) return
    if (name) {
      const user = this.findUser(streamId)
      if (!user) {
        return
      }
      if (user.name !== name || user.status !== status) {
        log(`rtc recv metadata user info, source: ${streamId}, name: ${name}, parent stream id: ${parentStreamId},status:${status}`, LOG_TYPE.COMM, LOG_MODULE.RTC)
        user.name = name
        user.parentStreamId = parentStreamId
        user.status = status
        this.commManager.onStreamUserChanged(user, UserChangedReason.REASON_INFO)
      }

    }

    if (roomInfo && this.recvRoomInfo) {
      this.onMediaRoomInfo(streamId, roomInfo)
    }
    if (controlInfo) {
      this.onMediaControl(streamId, controlInfo)
    }
  }

  public onMediaRoomInfo(source: number, roomInfo: Biz) {
    if (!roomInfo.timestamp || (roomInfo.timestamp <= (this.roomInfo?.timestamp ? this.roomInfo?.timestamp : 0))) {
      return
    }

    log(`rtc recv metadata room info, source: ${source}, room audio: ${roomInfo.roomAudio ? 'true' : 'false'},
    room video: ${roomInfo.roomVideo ? 'true' : 'false'}, host: ${roomInfo.hostUid}, ts: ${roomInfo.timestamp}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

    this.roomInfo = roomInfo
    this.commManager.onStreamRoomInfo(roomInfo)

    this.adjustLocalMediaStates()
  }

  public onMediaControl(sourceStreamId: number, control: Control) {
    if (control.targetStreamId && control.targetStreamId !== this.selfStreamId) {
      return
    }

    const source = this.findUser(sourceStreamId)
    if (!source || !control.sequenceId || control.sequenceId < source.opSeq) return

    log(`rtc recv metadata control info, source: ${sourceStreamId}, target: ${control.targetStreamId},
    type: ${control.requestType}, seq: ${control.sequenceId}`, LOG_TYPE.COMM, LOG_MODULE.RTC)

    source.opSeq = control.sequenceId

    this.commManager.onStreamControl(sourceStreamId, control)
  }

  public isMetadataOn() {
    return this.rtcEngine.isMetadataOn()
  }

  public isDataStreamOn() {
    return this.rtcEngine.isDataStreamOn()
  }

  /************************************** ScreenShare ****************************************/
  private shareManager?: ShareManagerStore

  public async chooseShareWindow(shareManager: ShareManagerStore) {
    this.shareManager = shareManager
    return await this.rtcShare.createShareTrack()
  }

  public cancelShareWindow() {
    this.rtcShare.closeShareTrack()
  }

  public onShareWindowEnd() {
    this.shareManager?.onShareWindowEnd()
  }

  private shareId: number = 0

  public async startScreenShare(seq: number, shareId: number, encryptionMode: ENCRYPTION_MODE, salt: Uint8Array | undefined, mediaToken?: string, isNeedWatermark?: boolean) {
    this.shareId = shareId

    let token = mediaToken !== undefined ? mediaToken : ''
    try {
      if (!token) {
        log(`get share token`, LOG_TYPE.INFO, LOG_MODULE.SHARE)
        token = await this.getToken(this.channelName, shareId)
      }
    } catch (error) {
      logException(`get share rtc token exception`, error, LOG_MODULE.SHARE)

      if (this.shareId === shareId) {
        this.notification.onScreenSharingInterrupted()
      }
      return [seq, false]
    }

    if (this.shareId !== shareId) {
      log(`get rtc token over, share id no match, current: ${this.shareId}, get: ${shareId}`, LOG_TYPE.INFO, LOG_MODULE.SHARE)
      return [seq, false]
    }

    this.rtcEngine.setSelfShareStreamId(shareId)
    const mode = ENCRYPTION_MODE[encryptionMode]
    const sdkEncryptionMode = SDK_ENCRYPTION_MODE[mode]

    try {
      await this.rtcShare.startScreenShare(
        this.rtcEngine.getScreenShareCodec(),
        this.channelName, shareId, token,
        this.selfStreamId, this.userMe.name,
        this.rtcEncryption,
        this.rtcSecretKey,
        sdkEncryptionMode,
        salt,
        isNeedWatermark ? USER_STATUS_FLAG.ADD_WATERMARK | USER_STATUS_FLAG.AUDIO_DISABLE : USER_STATUS_FLAG.AUDIO_DISABLE
        )

      this.usingShareTxQuality = true
      return [seq, true]
    } catch (error) {
      if (this.shareId !== shareId) {
        log(`rtc start share over, share id no match, current: ${this.shareId}, get: ${shareId}`, LOG_TYPE.INFO, LOG_MODULE.SHARE)
      } else {
        this.notification.addAlert(true, "ScreenShareChannelFailure", 'error', `${error.code}`)
      }

      return [seq, false]
    }
  }

  public async stopScreenShare() {
    this.shareId = 0

    try {
      await this.rtcShare.stopScreenShare()
    } catch (error) {
      // do nothing
    } finally {
      this.usingShareTxQuality = false

      this.rtcEngine.setSelfShareStreamId(0)
    }
  }

  public getSelfShareId() {
    return this.shareId
  }

  /************************************** Others ****************************************/

  private createUser(audioState: boolean, videoState: boolean, streamId: number, isMe: boolean = false) {
    const user = new MediaUser({
      streamId,
      audioState,
      videoState,
      isMe
    })
    return user
  }

  public async getToken(channelName: string, streamId?: number) {
    const res = await getRtcToken(channelName, streamId)

    return res.data.token
  }

  public getChannelName() {
    return this.channelName
  }

  public unsubscribeUser(streamId: number) {
    this.rtcEngine.unsubscribeRemoteAudio(streamId)
    this.rtcEngine.unsubscribeRemoteVideo(streamId)
  }

  public subscribeUser(streamId: number) {
    this.rtcEngine.subscribeRemoteAudio(streamId)
    this.rtcEngine.subscribeRemoteVideo(streamId)
  }

  @action
  public setTestVolume(volume: number) {
    this.testVolume = volume
  }

  public lowerRemotesVolumesWithExempt(isDecreaseVolume: boolean, exemptStreamId: number) {
    log(`restoreRemotesVolumes,lowerRemotesVolumesWithExempt`)
    this.remotesVolumeStateExemptId = exemptStreamId
    // this.remotesVolumeState = isDecreaseVolume ? this.testVolume : VOLUME.MUTE
    this.remotesVolumeState = isDecreaseVolume ? VOLUME.DECREASE : VOLUME.MUTE
    this.mediaUsers.forEach((item: MediaUser) => {
      if (item.streamId !== exemptStreamId) {
        item.setAudioVolume(this.remotesVolumeState)
      } else {
        item.setAudioVolume(VOLUME.FULL_VOLUME)
      }
    })
  }

  public restoreRemotesVolumes() {
    log(`restoreRemotesVolumes,`)
    this.remotesVolumeState = VOLUME.FULL_VOLUME
    this.remotesVolumeStateExemptId = 0
    this.mediaUsers.forEach((item: MediaUser) => {
      if (item.audioState) {
        item.setAudioVolume(VOLUME.FULL_VOLUME)
      }
    })

  }
}