import { OP_TYPE } from "./userRequest";
import { APPLY_ASSISTANT_LANG, USER_STATUS_FLAG as STATUS_FLAG } from "../utils/constants"
enum FEATURE_FLAG {
  USER_EX_PLAN = 0x0001,
}

export class BizUser {
  uid: string = ''
  name: string = ''
  portraitId: string = ''
  status: number = 0
  shareId: number = 0
  streamId: number = 0
  feature: number = 0
  isHost: boolean = false
  source = 0
  thirdpartyName: string = ''
  thirdpartyAlias: string = ''
  thirdpartyDepartment: string = ''
  isLoggedIn: boolean = false
  inviteBy: string = ''
  isNeedWatermark: boolean = false

  public getAudioState(): boolean {
    return (this.status & STATUS_FLAG.AUDIO_DISABLE) === 0
  }

  public getVideoState(): boolean {
    return (this.status & STATUS_FLAG.VIDEO_DISABLE) === 0
  }

  public setAudioState(on: boolean) {
    this.status = (this.status & ~STATUS_FLAG.AUDIO_DISABLE) | (on ? 0 : STATUS_FLAG.AUDIO_DISABLE)
  }

  public setVideoState(on: boolean) {
    this.status = (this.status & ~STATUS_FLAG.VIDEO_DISABLE) | (on ? 0 : STATUS_FLAG.VIDEO_DISABLE)
  }

  public setSharingWatermark(on: boolean) {
    const enableWatermarkStatus = STATUS_FLAG.ADD_WATERMARK | STATUS_FLAG.AUDIO_DISABLE
    this.status = (this.status & ~(enableWatermarkStatus)) | (on ? (enableWatermarkStatus) : 0)
  }

  public setShare(on: boolean, shareId: number, isNeedWatermark: boolean) {
    this.status = (this.status & ~STATUS_FLAG.SHARE_ON) | (on ? STATUS_FLAG.SHARE_ON : 0)
    this.setSharingWatermark(on && isNeedWatermark)
    this.shareId = on ? shareId : 0
  }

  public setInterrupt(enable: boolean) {
    this.status = enable ? (this.status | STATUS_FLAG.INTERRUPT_ON) : (this.status & ~STATUS_FLAG.INTERRUPT_ON);
  }

  public IsInterrupt(): boolean {
    return (this.status & STATUS_FLAG.INTERRUPT_ON) !== 0
  }

  public setDumping(on: boolean) {
    this.status = (this.status & ~STATUS_FLAG.DUMP_ON) | (on ? STATUS_FLAG.DUMP_ON : 0)
  }

  public isDumping(): boolean {
    return (this.status & STATUS_FLAG.DUMP_ON) !== 0
  }

  public hasWatermark(): boolean {
    return (this.status & STATUS_FLAG.ADD_WATERMARK) !== 0
  }

  public setCloudRecording(on: boolean) {
    this.status = (this.status & ~STATUS_FLAG.CLOUD_RECORDING_ON) | (on ? STATUS_FLAG.CLOUD_RECORDING_ON : 0)
  }

  public isCloudRecording(): boolean {
    return (this.status & STATUS_FLAG.CLOUD_RECORDING_ON) !== 0
  }

  public setFeatureExPlan(support: boolean) {
    this.feature = (this.feature & ~FEATURE_FLAG.USER_EX_PLAN) | (support ? FEATURE_FLAG.USER_EX_PLAN : 0)
  }

  public description(): string {
    const des = `name: ${this.name} uid: ${this.uid} stream id: ${this.streamId}`
    return des
  }

  public getUserName(showMode: 'tourist' | 'member') {
    if (showMode === 'tourist') {
      return this.name
    } else {
      if (this.isLoggedIn && this.thirdpartyName) {
        return this.thirdpartyName
      } else {
        return this.name
      }
    }
  }

  public getUserFullName(showMode: 'tourist' | 'member') {
    if (showMode === 'tourist') {
      return this.name
    } else {
      let alias = ''
      if (this.thirdpartyAlias) {
        alias = ` (${this.thirdpartyAlias})`
      }

      let fullName = `${this.thirdpartyName}${alias}`
      return fullName.length > 0 ? fullName : this.name
    }
  }
}

export class UserOperationDetail {
  public type: string = ''
  public targets: string[] = []
  public options: object = {}
}

export class UserOperation {
  public seq: number = 0
  public userList: BizUser[] = []
  public operator: string = ''
  public detail?: UserOperationDetail
}

export class UserOperationEx {
  source: string
  target: string
  op: OP_TYPE
  seq: number
  options: object = {}

  public constructor(source: string, target: string, opType: string, seq: number, options: object) {
    this.source = source
    this.target = target
    if (opType === "ban") {
      this.op = OP_TYPE.OP_BAN;
    } else if (opType === "unmuteAudio") {
      this.op = OP_TYPE.OP_ENABLE_AUDIO;
    } else if (opType === "muteAudio") {
      this.op = OP_TYPE.OP_DISABLE_AUDIO;
    } else if (opType === "unmuteVideo") {
      this.op = OP_TYPE.OP_ENABLE_VIDEO;
    } else if (opType === "muteVideo") {
      this.op = OP_TYPE.OP_DISABLE_VIDEO;
    } else if (opType === "share") {
      this.op = OP_TYPE.OP_SHARE;
    } else if (opType === "unshare") {
      this.op = OP_TYPE.OP_UNSHARE;
    } else if (opType === "interrupt") {
      this.op = OP_TYPE.OP_INTERRUPT;
    } else if (opType === "resume") {
      this.op = OP_TYPE.OP_RESUME;
    } else if (opType === "issueReportStart") {
      this.op = OP_TYPE.OP_START_ISSUE_REPORT;
    } else if (opType === "issueReportEnd") {
      this.op = OP_TYPE.OP_END_ISSUE_REPORT;
    } else {
      this.op = OP_TYPE.OP_UNKNOWN;
    }
    this.options = options
    this.seq = seq
  }
}
