import { observer } from "mobx-react";
import { useTheme } from '@material-ui/core/styles';
import React, { ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BigStream from "../../components/BigStream/BigStream";
import Participants from "../../components/Participants/index";
import DrawerChatPanel from "../../components/DrawerChatPanel/index";
import RoomControlView from "../../components/RoomController/RoomControlView";
import RoomHeader from "../../components/RoomHeader/RoomHeader";
import CloudRecording from '../../components/CloudRecording/CloudRecording';
import AudioRecording from '../../components/AudioRecording/AudioRecording';
import UserListView from "../../components/UserList/UserListView";
import { useTranslation } from "react-i18next";
import { useCommManagerStore, useRtmCommLayerStore, useUIStore, useUserStore, useUserManagerStore } from "../../stores/hooks";
import { APP_SUB_PATH, ROOM_MODE, ASSISTANT_STATE } from "../../utils/constants";
import { log, LOG_MODULE, LOG_TYPE } from "../../utils/Log";
import { useStyles } from "./styles";
// import  from '../../components/BigStream/BigStream'
import { ShowNotification } from "../../components/NotificationView/showNotification"
// tslint:disable-next-line: no-var-requires
require('../../assets/js/pinyin_dict_notone.js')
// tslint:disable-next-line: no-var-requires
require('../../assets/js/pinyinUtil.js');

const MeetingPage = observer((props) => {
  const userStore = useUserStore()
  const uiStore = useUIStore()
  const commManager = useCommManagerStore()
  const rtmComm = useRtmCommLayerStore()
  const userManager = useUserManagerStore()
  const theme = useTheme()
  const [t] = useTranslation()
  const [showToggleUserList, setShowToggleUserList] = useState(userManager.mediaList.length !== 0)
  const [showChat, setShowChat] = useState(userStore.preference.chatOpen)
  const [audioVolume, setAudioVolume] = useState(10)
  const [isUserGatheredList, setUserGatheredList] = useState(false)
  const [showParticipants, setShowParticipants] = useState(userStore.preference.isParticipantsOpen)
  const history = useHistory()
  const styles = useStyles()

  const leaveRoom = () => {
    if (commManager.isOutsider) {
      userStore.clearMeetingRecord()
      uiStore.changeModeToNormal()
      props.onChangeMode(false)
    }

    // after using BrowserRouter, no need to trim URL
    history.replace(`${APP_SUB_PATH}/`)
  }

  useEffect(() => {
    if (userManager.mediaList.length === 0) {
      setShowToggleUserList(false)
      return
    }
    if (!isUserGatheredList) {
      setShowToggleUserList(true)
    }
  }, [userManager.mediaList.length, commManager.assistantInfo.assistantName])

  useEffect(() => {
    const meeting = userStore.getMeetingRecord()

    if (!meeting) {
      log(`!!!meeting page find meeting undefined, return to home page`, LOG_TYPE.ERROR, LOG_MODULE.UI)
      commManager.updateNeedReturnHomePage(true)
      return
    }
    commManager.updateWatermarkConfig(userStore.getWaterMarkConfig());
    commManager.joinRoom({
      rid:(meeting as any).rid,
      pwd: (meeting as any).pwd,
      rtcEncryption: userStore.getRtcEncryption(),
      selfUid: userStore.getUid(),
      selfStreamId: userStore.getStreamId(),
      selfName: userStore.getName(),
      selfAudio: userStore.getDefalutAudio(),
      selfVideo: userStore.getDefaultVideo(),
      roomMode: meeting.mode,
      thirdparty: userStore.user.thirdPartyUser,
      channelName:(meeting as any).channelName,
      roomToken:(meeting as any).roomToken,
    })

    return () => {
      // Sometime leaveRoom may cost a lot of time, we need let VideoPlayer stopPlayVideo as soon as possible, so setTimeout, See: APP-2417
      setTimeout(() => {
        log('user hang up meeting', LOG_TYPE.COMM, LOG_MODULE.UI)

        commManager.leaveRoom()

        userStore.saveLastRoomPreference()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (commManager.needReturnHomePage) {
    log('MeetingPage refresh needReturnHomePage true', LOG_TYPE.INFO, LOG_MODULE.UI)

    leaveRoom()
  }

  const handleToggle = () => {
    log(`user ${showToggleUserList ? 'open' : 'close'} Userlist Page`, LOG_TYPE.COMM, LOG_MODULE.UI)
    setUserGatheredList(true)
    setShowToggleUserList(!showToggleUserList)
  }

  const toggleParticipant = () => {
    setShowParticipants(!showParticipants)
    userStore.setParticipantsOpen()
  }
  const toggleChatPanel = () => {
    const open = !showChat
    setShowChat(open)

    if (open) {
      uiStore.showChatFocus = true
    }

    log(`user ${open ? 'close' : 'open'} Chat Page`, LOG_TYPE.COMM, LOG_MODULE.UI)

    rtmComm.readAllChatMessage()

    userStore.setChatPanelOpenState(open)
  }
  const setTargetAudioVolume = (val: any) => {
    setAudioVolume(val)
    commManager.setVolume(val)
  }

  const showVolume = () => {
    switch (commManager.assistantStatus) {
      case ASSISTANT_STATE.ASSIST_NONE: return '没有操作/房间内没有会议助手';
      case ASSISTANT_STATE.ASSIST_DISABLED: return `禁用了会议助手,会议助手音量：0 正常用户：100`;
      case ASSISTANT_STATE.ASSIST_ENABLED_WITHOUT_ORIGIN_SOUND: return `开启了会议助手,会议助手音量：100 正常用户：0`;
      case ASSISTANT_STATE.ASSIST_ENABLED_WITH_ORIGIN_SOUND: return `开启了会议助手,会议助手音量：100 正常用户：10`;
    }
  }

  return (
    <div className={styles.container}>
      <RoomHeader
        handleToggle={handleToggle}
        toggle={showToggleUserList}
        isShowListViewControl={userManager.mediaList.length !== 0}
        style={{ paddingRight: showChat || showParticipants ? '16.25rem' : '0rem' }} />
      <div className={styles.main} style={{ paddingLeft: showToggleUserList ? '9.5rem' : '0rem', paddingRight: showChat || showParticipants ? '16.25rem' : '0rem' }}>
        <CloudRecording />
        <AudioRecording />
        {/* <div className={styles.toggleCount} style={{ left: toggleUserList ? '0' : '-11rem' }} onClick={() => { handleToggle() }}><UserNumbers standalone={true} /></div> */}
        <UserListView toggle={showToggleUserList} />
        <BigStream isPreviewVideo={commManager.roomMode === ROOM_MODE.MODE_AGORA && !commManager.isRtcConnected}/>
        <DrawerChatPanel open={showChat} isParticipantsOnDisplay={showParticipants} toggleChatPanel={toggleChatPanel} />
        <Participants open={showParticipants} IsChatOnDisplay={showChat} toggleParticipant={toggleParticipant} />
       { commManager.isShowAssistantAudioVolume && <div style={{ position: 'fixed', top: '5rem', left: '12.5rem', color: 'green', zIndex: 999 }}>
          {/* <input placeholder="设置远端的播放音量默认是10" value={audioVolume} type="number" onChange={(e) => { setTargetAudioVolume(e.target.value as unknown as number) }} /> */}
          <br />当前状态：{showVolume()}</div>}
      </div>
      <RoomControlView
        toggleChatPanel={toggleChatPanel}
        toggleParticipant={toggleParticipant}
        hangup={leaveRoom}
        isChatActive={showChat}
        isParticipantsActive={showParticipants}
        style={{ paddingRight: showChat || showParticipants ? '16.25rem' : '0rem' }} />
      <ShowNotification />
    </div>
  )
})

export default MeetingPage
